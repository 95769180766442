<template>
    <pendo-table
        v-pendo-loading:feather="isLoading"
        v-stateless-pendo-table-user-settings-watcher="{
            userSettings: topFeaturesListUserSettings
        }"
        :data="topFeatures"
        :columns="columns"
        :max-height="488"
        :default-sort="sort"
        resizable
        condensed
        title="Top Features"
        class="top-features-table"
        @tableSettingsUpdate="handleTableSettingsUpdate">
        <template #headerActions>
            <pendo-button
                data-cy="view-all-features-button"
                theme="app"
                type="link"
                prefix-icon="feature"
                label="View All Features"
                @click="$emit('routeToFeatures')" />
        </template>
        <template #empty>
            <div class="top-features-table--empty">
                <pendo-empty-state
                    :icon="{ 'type': 'feature', 'size': 32, 'stroke-width': 1.5 }"
                    title="Features not Found"
                    description="Try clearing filters. Features without view data will not appear." />
            </div>
        </template>
        <template #app="{ row }">
            <pendo-app-display :apps="row.app" />
        </template>
    </pendo-table>
</template>

<script>
import { PendoEmptyState, PendoButton, PendoTable, PendoLoading, PendoAppDisplay } from '@pendo/components';
import { formatDataByType } from '@/utils/table-formatters';
import compact from 'lodash/compact';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import StatelessPendoTableUserSettingsWatcher from '@/stateless-components/directives/StatelessPendoTableUserSettingsWatcher';

export default {
    name: 'TopFeatures',
    components: {
        PendoButton,
        PendoEmptyState,
        PendoTable,
        PendoAppDisplay
    },
    directives: {
        PendoLoading,
        StatelessPendoTableUserSettingsWatcher
    },
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        topFeatures: {
            type: Array,
            required: true
        },
        showAppUsage: {
            type: Boolean,
            required: true
        },
        activeTimezone: {
            type: String,
            default: ''
        },
        topFeaturesListUserSettings: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            topFeaturesUserSettingsName: 'topFeatures',
            defaultSort: {
                order: 'descending',
                prop: 'numEvents'
            }
        };
    },
    computed: {
        defaultColumns () {
            return compact([
                {
                    prop: 'displayName',
                    label: 'Feature',
                    sortable: true,
                    visible: true,
                    minWidth: 200
                },
                this.showAppUsage && {
                    prop: 'app',
                    label: 'App',
                    sortable: true,
                    visible: true,
                    minWidth: 200,
                    schema: 'string',
                    sortBy: (row) => row.app.displayName
                },
                {
                    prop: 'numEvents',
                    label: 'Number of Clicks',
                    sortable: true,
                    visible: true,
                    minWidth: 150,
                    schema: 'integer',
                    formatter: (row) => formatDataByType('integer', row.numEvents, this.activeTimezone)
                }
            ]);
        },
        columns () {
            return mergeDefaultColumnsWithUserSettings(this.defaultColumns, this.topFeaturesListUserSettings);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.topFeaturesListUserSettings);
        }
    },
    methods: {
        handleTableSettingsUpdate (newSettings) {
            this.$emit('tableSettingsUpdate', this.topFeaturesUserSettingsName, newSettings);
        }
    }
};
</script>

<style lang="scss">
.top-features-table {
    &--empty {
        height: 350px;
    }

    .pendo-table__active-count {
        display: none;
    }

    .pendo-button {
        margin-right: 16px;
    }
}
</style>
