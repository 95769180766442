import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { fetchSharedTokens, updateSharedToken, createSharedToken } from '@/utils/shared-tokens';

const sharedTokens = {
    namespaced: true,
    state: {
        error: {},
        fetching: false,
        map: {}
    },
    mutations: {
        setMap (state, { map }) {
            state.map = map;
        },
        setMapAtKey (state, { id, sharedToken }) {
            Vue.set(state.map, id, sharedToken);
        },
        deleteMapKey (state, { id }) {
            Vue.delete(state.map, id);
        },
        setError (state, { error }) {
            state.error = error;
        },
        setFetching (state, { fetching }) {
            state.fetching = fetching;
        }
    },
    actions: {
        loadAll ({ commit, state }, { kind = null, reFetch = false } = {}) {
            if (!isEmpty(state.map) && !reFetch) return Promise.resolve();

            commit('setFetching', { fetching: true });

            return fetchSharedTokens(kind)
                .then((sharedTokens) => {
                    commit('setFetching', { fetching: false });
                    commit('setMap', { map: keyBy(sharedTokens, 'id') });
                })
                .catch((error) => {
                    commit('setFetching', { fetching: false });
                    commit('setError', { error });
                    throw error;
                });
        },
        create ({ commit }, payload = {}) {
            commit('setFetching', { fetching: true });

            return createSharedToken(payload)
                .then((sharedToken) => {
                    commit('setFetching', { fetching: false });
                    commit('setMapAtKey', { id: sharedToken.id, sharedToken });

                    return sharedToken;
                })
                .catch((error) => {
                    commit('setFetching', { fetching: false });
                    commit('setError', { error });
                    throw error;
                });
        },
        update ({ commit }, payload) {
            commit('setFetching', { fetching: true });

            return updateSharedToken(payload)
                .then((sharedToken) => {
                    commit('setFetching', { fetching: false });
                    commit('setMapAtKey', { id: sharedToken.id, sharedToken });
                })
                .catch((error) => {
                    commit('setFetching', { fetching: false });
                    commit('setError', { error });
                    throw error;
                });
        }
    },
    getters: {
        list: (state) => values(state.map),
        sharedTokenById: (state) => (id) => state.map[id],
        sharedTokensByAppId: (state) => (appId) =>
            filter(values(state.map), (sharedToken) => sharedToken.appId === appId)
    }
};

export default sharedTokens;
