<template>
    <div>
        <page-header
            title="Resource Center"
            only-title
            class="resource-center-header"
            :loading="loading"
            :show-banner="showDisabledAppBanner"
            :specific-app="activeEditingApp">
            <template #top>
                <div
                    v-if="showBackButton"
                    class="resource-center-header--back">
                    <pendo-button
                        theme="app"
                        type="link"
                        size="mini"
                        prefix-icon="chevron-left"
                        label="Back to Resource Center List"
                        @click="onBackToRcListClick" />
                </div>
            </template>
            <template #bottom>
                <pendo-app-display
                    v-if="showAppIcon"
                    class="app-display"
                    :apps="activeEditingApp" />
            </template>
            <template
                v-if="!loading"
                #actions>
                <pendo-button
                    v-if="!!resourceCenter && canDeleteResourceCenter"
                    v-pendo-tooltip.left-start="{ content: 'Delete Resource Center', offset: 5 }"
                    data-cy="delete-resource-center"
                    theme="app"
                    type="secondary"
                    icon="trash-2"
                    @click="onDeleteResourceCenterClick" />
                <div
                    v-if="!!resourceCenter"
                    v-pendo-tooltip="
                        !canPreviewResourceCenter && {
                            arrow: true,
                            multiline: true,
                            content: 'Your Resource Center is empty (no public content) and cannot be previewed.'
                        }
                    ">
                    <pendo-button
                        v-pendo-tooltip.left-start="
                            canPreviewResourceCenter && { content: 'Preview Resource Center', offset: 5 }
                        "
                        :disabled="!canPreviewResourceCenter"
                        data-cy="preview-resource-center"
                        theme="app"
                        type="secondary"
                        icon="eye"
                        @click="onPreviewResourceCenterClick">
                        Preview
                    </pendo-button>
                </div>
                <pendo-button
                    v-if="!!resourceCenter && canUpdatePublic && canPublishResourceCenter"
                    v-pendo-tooltip.left-start="{ content: 'Push Resource Center Updates', offset: 5 }"
                    data-cy="update-resource-center"
                    theme="app"
                    type="primary"
                    icon="upload"
                    class="green-bg"
                    @click="onUpdatePublicClick" />
                <div
                    v-if="!!resourceCenter"
                    data-cy="rc-production-toggle"
                    class="production-toggle">
                    <pendo-multiselect
                        v-pendo-tooltip="disableRCStateTooltip"
                        :disabled="disableRCStateDropdown"
                        :options="enabledStateOptions"
                        :value="enabledState"
                        :popper-options="{ placement: 'bottom-end' }"
                        :allow-empty="false"
                        :min-menu-width="110"
                        value-key="state"
                        @select="onEnabledStateChange">
                        <template #trigger>
                            <pendo-guide-status-trigger
                                type="fill"
                                size="medium" />
                        </template>
                        <template #option="{ option }">
                            <pendo-guide-status-option :option="option" />
                        </template>
                    </pendo-multiselect>
                </div>
                <div v-pendo-tooltip.left-start="disableCreateButton && disableCreateButtonTooltip">
                    <pendo-button
                        v-if="!resourceCenter"
                        data-cy="create-resource-center"
                        theme="app"
                        type="primary"
                        prefix-icon="plus"
                        label="New Resource Center"
                        :disabled="disableCreateButton"
                        @click="createResourceCenter" />
                </div>
                <div
                    v-if="!!resourceCenter && canUpdatePublic && canPublishResourceCenter"
                    class="label-wrapper-update">
                    There are unpublished updates. Use
                    <pendo-icon
                        type="upload"
                        class="label-icon"
                        display="inline" /> to push <br>
                    changes to the public resource center.
                </div>
            </template>
        </page-header>
        <resource-center-enable-modal
            :is-visible="showEnableModal"
            :is-updating="isUpdating"
            @onSave="submitEnable"
            @onCancel="cancelEnable" />
        <resource-center-disable-modal
            :is-visible="showDisableModal"
            @onDisable="submitDisable"
            @onCancel="cancelDisable" />
        <resource-center-delete-modal
            :is-visible="showDeleteModal"
            :is-updating="isUpdating"
            @onDelete="submitDelete"
            @onCancel="cancelDelete" />
        <resource-center-update-public-modal
            :is-visible="showUpdateModal"
            :is-updating="isUpdating"
            @onUpdate="submitUpdate"
            @onCancel="cancelUpdate" />
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import ResourceCenterUpdatePublicModal from './ResourceCenterUpdatePublicModal';
import ResourceCenterDeleteModal from './ResourceCenterDeleteModal.vue';
import ResourceCenterEnableModal from './ResourceCenterEnableModal';
import ResourceCenterDisableModal from './ResourceCenterDisableModal';
import {
    PendoButton,
    PendoTooltip,
    PendoMultiselect,
    PendoGuideStatusTrigger,
    PendoGuideStatusOption,
    PendoIcon,
    PendoAppDisplay
} from '@pendo/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';
import { canDeleteResourceCenter, canPublishResourceCenter, canCreateResourceCenter } from '@/utils/rc-permissions';

const { moduleIds } = BuildingBlockLayouts;

const PRODUCTION_STATES = Object.freeze({
    PRODUCTION_ON: { label: 'Public', state: 'public', class: 'production-enabled' },
    PRODUCTION_OFF: { label: 'Disabled', state: 'disabled', class: 'production-disabled' }
});

const MESSAGE_CONTENT = Object.freeze({
    UNPUBLISHABLE_STATE: 'Please add modules in order to set Resource Center to public',
    NO_PUBLISH_RC_PERMS:
        'You do not have the right permissions to publish this Resource Center. Please reach out to your Admin for the relevant permissions.'
});

export default {
    name: 'ResourceCenterHeader',
    components: {
        PageHeader,
        PendoButton,
        ResourceCenterDeleteModal,
        PendoMultiselect,
        PendoGuideStatusTrigger,
        PendoGuideStatusOption,
        ResourceCenterEnableModal,
        ResourceCenterDisableModal,
        ResourceCenterUpdatePublicModal,
        PendoIcon,
        PendoAppDisplay
    },
    directives: {
        PendoTooltip
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        showBackButton: {
            type: Boolean,
            default: false
        },
        disableCreateButton: {
            type: Boolean,
            default: false
        },
        resourceCenter: {
            type: Object,
            default: null
        },
        showDisabledAppBanner: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            enabledStateOptions: [PRODUCTION_STATES.PRODUCTION_ON, PRODUCTION_STATES.PRODUCTION_OFF],
            showDeleteModal: false,
            showDisableModal: false,
            showEnableModal: false,
            showUpdateModal: false,
            messages: MESSAGE_CONTENT
        };
    },
    computed: {
        ...mapState({
            subId: (state) => get(state, 'subscriptions.activeId'),
            designerUrl: (state) => state.designer.url,
            previewLanguage: (state) => state.guides.previewLanguage,
            error: (state) => state.resourceCenter.error,
            isUpdating: (state) => state.resourceCenter.isUpdating
        }),
        ...mapGetters({
            lastUrlByAppId: 'designer/lastUrlByAppId',
            activeResourceCenter: 'resourceCenter/getActiveResourceCenter',
            isActiveResourceCenterPublishable: 'resourceCenter/isActiveResourceCenterPublishable',
            hasActiveBeenPublishedBefore: 'resourceCenter/hasActiveBeenPublishedBefore',
            getGuideById: 'guides/getGuideById',
            getGuidePreviewConfigById: 'guides/getGuidePreviewConfigById',
            watermark: 'guides/watermark',
            isActiveRCPublic: 'resourceCenter/isActiveRCPublic',
            draftModules: 'resourceCenter/getDraftModules',
            allApps: 'apps/appMapForActiveSubscription',
            listAllApps: 'apps/excludeMobileForActiveSubscription',
            isSubMultiApp: 'subscriptions/usesMultiApp',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription',
            listAllWithRCAccessForActiveSubscription: 'apps/listAllWithRCAccessForActiveSubscription'
        }),
        appId () {
            return get(this.resourceCenter, 'draft.homeView.appId', '');
        },
        launchUrl () {
            return this.lastUrlByAppId(this.appId) || this.designerUrl;
        },
        localizationEnabled () {
            return get(this.activeEditingApp, 'applicationFlags.guideLocalization', false);
        },
        disableCreateButtonTooltip () {
            const extensionMessage = "You've added a Resource Center for all available integrated apps.";
            const permissionMessage = 'You do not have the right permissions to create a Resource Center.';

            const canCreateOneRC = this.listAllWithRCAccessForActiveSubscription.some((app) =>
                canCreateResourceCenter([app])
            );

            return {
                content: this.activeIsTrainingSubscription || !canCreateOneRC ? permissionMessage : extensionMessage,
                arrow: true,
                multiline: true
            };
        },
        draftResourceCenterHomeView () {
            return get(this.activeResourceCenter, 'draft.homeView');
        },
        enabledState () {
            return this.isActiveRCPublic ? PRODUCTION_STATES.PRODUCTION_ON : PRODUCTION_STATES.PRODUCTION_OFF;
        },
        canUpdatePublic () {
            return this.hasActiveBeenPublishedBefore && !isEqual(this.draftState, this.publicState);
        },
        canCreateResourceCenter () {
            return canCreateResourceCenter(this.listAllApps);
        },
        canDeleteResourceCenter () {
            return canDeleteResourceCenter(this.resourceCenter);
        },
        canPublishResourceCenter () {
            return canPublishResourceCenter(this.appId);
        },
        canPreviewResourceCenter () {
            if (!this.resourceCenter) return false;

            if (!this.draftModules.length) return false;

            const modulesHavePublicGuides = this.draftModules.some((singleModule) =>
                get(singleModule, 'children', []).some((guideId) => {
                    const guide = this.getGuideById(guideId);

                    return get(guide, 'state', '') === 'public';
                })
            );

            const hasIframeModule = this.draftModules.some((singleModule) => {
                return get(singleModule, 'attributes.resourceCenter.moduleId') === moduleIds.iframe;
            });

            return modulesHavePublicGuides || hasIframeModule;
        },
        draftState () {
            return this.getStateForComparison('draft');
        },
        publicState () {
            return this.getStateForComparison('public');
        },
        isInUnpublishableState () {
            return !this.isActiveRCPublic && !this.isActiveResourceCenterPublishable;
        },
        disableRCStateDropdown () {
            return this.isInUnpublishableState || !this.canPublishResourceCenter;
        },
        activeEditingApp () {
            return get(this.allApps, this.appId, {});
        },
        showAppIcon () {
            return this.isSubMultiApp && !isEmpty(this.activeEditingApp);
        },
        disableRCStateTooltip () {
            const defaultTooltip = {
                arrow: true,
                multiline: true
            };
            if (this.isInUnpublishableState) {
                defaultTooltip.content = this.messages.UNPUBLISHABLE_STATE;

                return defaultTooltip;
            }
            if (!this.canPublishResourceCenter) {
                defaultTooltip.content = this.messages.NO_PUBLISH_RC_PERMS;

                return defaultTooltip;
            }

            return;
        }
    },
    methods: {
        ...mapActions({
            deleteResourceCenter: 'resourceCenter/delete',
            enableActiveResourceCenter: 'resourceCenter/enableActiveResourceCenter',
            disableActiveResourceCenter: 'resourceCenter/disableActiveResourceCenter',
            promoteResourceCenterToPublic: 'resourceCenter/promoteResourceCenterToPublic',
            fetchAllResourceCenters: 'resourceCenter/fetchAll'
        }),
        getStateForComparison (key) {
            const moduleWhitelist = [
                'lastUpdatedByUser',
                'appId',
                'id',
                'name',
                'emailState',
                'launchMethod',
                'steps',
                'attributes',
                'audienceUiHint',
                'authoredLanguage',
                'recurrence',
                'recurrenceEligibilityWindow',
                'resetAt',
                'isTopLevel',
                'isModule',
                'originId',
                'children',
                'isDisabled'
            ];
            const stepWhitelist = [
                'id',
                'guideId',
                'templateId',
                'type',
                'elementPathRule',
                'contentType',
                'buildingBlocksUrl',
                'domUrl',
                'domJsonpUrl',
                'advanceMethod',
                'attributes'
            ];

            const homeView = get(this, `activeResourceCenter[${key}].homeView`, {});
            const guides = get(this, `activeResourceCenter[${key}].modules`, []);
            const translationStates = get(this, `activeResourceCenter[${key}].translationStates`, {});
            let clonedGuides = cloneDeep(guides);

            clonedGuides = clonedGuides.map((guide) => {
                guide.isDisabled = guide.state === 'disabled';
                guide.steps = (guide.steps || []).map((step) => pick(step, stepWhitelist));

                return pick(guide, moduleWhitelist);
            });

            return {
                homeView: pick(homeView, moduleWhitelist),
                modules: clonedGuides,
                translationStates
            };
        },
        onDeleteResourceCenterClick () {
            this.showDeleteModal = true;
        },
        onPreviewResourceCenterClick () {
            const previewConfig = this.getGuidePreviewConfigById(this.activeResourceCenter.id);

            this.$modal.show('via-modal', {
                title: 'Preview Resource Center',
                component: 'LaunchPreviewModal',
                componentProps: {
                    guide: this.getGuideById(this.activeResourceCenter.id),
                    launchUrl: this.launchUrl,
                    previewConfig: {
                        ...previewConfig,
                        language: this.previewLanguage
                    },
                    isResourceCenter: true
                }
            });
        },
        onUpdatePublicClick () {
            this.showUpdateModal = true;
        },
        async submitDelete () {
            await this.deleteResourceCenter();
            this.fetchAllResourceCenters({ noCache: true });
            this.showDeleteModal = false;

            if (this.error) {
                this.showErrorToast();
            }
        },
        cancelDelete () {
            this.showDeleteModal = false;
        },
        onEnabledStateChange ({ label }) {
            switch (label) {
                case PRODUCTION_STATES.PRODUCTION_ON.label:
                    this.showEnableModal = true;

                    return;
                case PRODUCTION_STATES.PRODUCTION_OFF.label:
                    this.showDisableModal = true;

                    return;
            }
        },
        async submitEnable () {
            if (!this.hasActiveBeenPublishedBefore) {
                const success = await this.submitUpdate(true);
                if (!success) {
                    return;
                }
            }

            this.fetchAllResourceCenters({ noCache: true });
            await this.enableActiveResourceCenter();
            this.showEnableModal = false;

            if (this.error) {
                this.showErrorToast();

                return;
            }

            this.$router.replace({
                name: 'resourceCenter',
                params: {
                    originId: this.activeResourceCenter.id,
                    activeState: 'public'
                }
            });
        },
        createResourceCenter () {
            this.$emit('createResourceCenter');
        },
        async submitDisable () {
            await this.disableActiveResourceCenter();
            this.showDisableModal = false;

            if (this.error) {
                this.showErrorToast();
            }
        },
        async submitUpdate (initialUpdate = false) {
            await this.promoteResourceCenterToPublic();
            this.fetchAllResourceCenters({ noCache: true });
            if (this.error) {
                this.showUpdateModal = false;
                this.showErrorToast();

                return false;
            }

            if (initialUpdate) {
                return true;
            }

            this.showUpdateModal = false;
            this.$router.replace({
                name: 'resourceCenter',
                params: {
                    originId: this.activeResourceCenter.id,
                    activeState: 'public'
                }
            });

            return true;
        },
        showErrorToast () {
            this.$emit('showErrorToast');
        },
        cancelEnable () {
            this.showEnableModal = false;
        },
        cancelDisable () {
            this.showDisableModal = false;
        },
        cancelUpdate () {
            this.showUpdateModal = false;
        },
        onBackToRcListClick () {
            this.$emit('backToRcList');
        }
    }
};
</script>

<style lang="scss" scoped>
.production-toggle {
    margin-left: 8px;
}

.via.pendo-button--primary.green-bg {
    background-color: $green-primary;
}

.label-icon {
    width: 1em;
    display: inline;
}

.label-wrapper-update {
    padding-top: 1em;
    line-height: 14px;
    font-size: 10px;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 5;
}

.app-display {
    color: $gray-primary;
    font-size: 0.875em;
}
</style>
