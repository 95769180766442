<template>
    <div class="track-event-details">
        <page-header
            :only-title="true"
            :loading="loading"
            :title="trackEventName">
            <template #top>
                <div>
                    <router-link to="/analytics/trackevents">
                        <pendo-button
                            theme="app"
                            type="link"
                            size="mini"
                            prefix-icon="chevron-left"
                            label="Back to Track Events List" />
                    </router-link>
                </div>
            </template>
        </page-header>
        <pendo-page-content>
            <global-filters :show-app-picker="false" />
            <div class="track-event-details--grid">
                <div class="track-event-details--row">
                    <event-breakdown-table :track-event="trackEvent" />
                </div>
            </div>
        </pendo-page-content>
    </div>
</template>

<script>
import GlobalFilters from '@/components/filters/GlobalFilters';
import { PendoLoading, PendoPageContent, PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import EventBreakdownTable from './event-breakdown-table';
import { fetchTrackEvent } from '@/utils/track-events';
import { mapActions } from 'vuex';

export default {
    name: 'TrackEventDetails',
    components: {
        GlobalFilters,
        PendoPageContent,
        PageHeader,
        PendoButton,
        EventBreakdownTable
    },
    directives: {
        PendoLoading
    },
    props: {
        trackEventId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            trackEvent: {},
            loading: false
        };
    },
    computed: {
        trackEventName () {
            return this.trackEvent?.name || '';
        }
    },
    async created () {
        try {
            this.loading = true;
            await this.loadMetadata();
            this.trackEvent = await fetchTrackEvent(this.trackEventId);
        } catch (err) {
            this.$router.push({ name: 'trackEventsDashboard' }).catch(() => {});
        } finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadMetadata: 'metadata/loadAll'
        })
    }
};
</script>

<style lang="scss">
.track-event-details {
    .track-event-details--grid {
        display: grid;
        grid-gap: 32px;
    }

    .track-event-details--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));

        &:not(:last-child) {
            min-height: 448px;
        }
    }
}
</style>
