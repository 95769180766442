<template>
    <div class="import-lang">
        <div class="import-lang-text import-export-lang-text">
            <span class="import-export-lang-name">
                {{ language.name }}
                <pendo-icon
                    v-if="languageWarning"
                    v-pendo-tooltip="languageWarning.tooltip"
                    v-bind="languageWarning.icon" />
            </span>
            <span class="import-export-lang-code"> Target Language: {{ language.code }} </span>
        </div>
        <div class="import-actions">
            <pendo-button
                v-if="isTranslated(language.translatedState)"
                theme="app"
                type="link"
                label="Clear"
                size="mini"
                @click="openClearTranslatedStringsModal(language.code, language.name)" />
            <pendo-button
                v-if="canEditTranslationInApp && !isTranslated(language.translatedState)"
                theme="app"
                type="secondary"
                label="Translate"
                size="mini"
                :prefix-icon="canTranslateWithAi ? 'wand' : ''"
                @click="editTranslationInApp(language, 'translate')" />
            <pendo-button
                v-if="canEditTranslationInApp && isTranslated(language.translatedState)"
                theme="app"
                type="secondary"
                label="Edit"
                size="mini"
                prefix-icon="edit-2"
                @click="editTranslationInApp(language, 'edit')" />
            <pendo-button
                class="import-export-button"
                theme="app"
                type="secondary"
                :label="getButtonLabel(language.translatedState)"
                size="mini"
                :prefix-icon="isImportSuccessful ? 'check' : ''"
                :loading="isLoading"
                @click="triggerFileUpload(language.code)" />
            <pendo-button
                v-if="isTranslated(language.translatedState)"
                theme="app"
                type="secondary"
                label="Export"
                size="mini"
                :loading="isExportingXliff"
                @click="exportXliff(language.code)" />
            <input
                :ref="`upload-${language.code}`"
                accept=".xliff, .xlf"
                type="file"
                style="display: none"
                @change="importFile(language)">
        </div>
    </div>
</template>

<script>
import { toRefs } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import escape from 'lodash/escape';
import get from 'lodash/get';
import { PendoButton, PendoNotification, PendoIcon, PendoTooltip } from '@pendo/components';
import {
    GUIDE_TRANSLATION_COMPLETE,
    GUIDE_TRANSLATION_PARTIAL,
    GUIDE_TRANSLATION_UNTRANSLATED,
    XLIFF_IMPORT_ERROR_MESSAGE
} from '@/utils/localization';
import { sendPendoTrackEvent } from '@/utils/utils';
import useXliffDownload from '@/stateless-components/guides/common/useXliffDownload';

export default {
    name: 'LanguageImport',
    components: {
        PendoButton,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        language: {
            type: Object,
            default: () => ({})
        },
        guideId: {
            type: String,
            required: true
        },
        guideName: {
            type: String,
            required: true
        },
        isResourceCenter: {
            type: Boolean,
            default: () => false
        }
    },
    setup (props) {
        const { guideId, guideName } = toRefs(props);
        const { isExportingXliff, exportXliff } = useXliffDownload(guideId, guideName);

        return {
            isExportingXliff,
            exportXliff
        };
    },
    data () {
        return {
            isLoading: false,
            isImportSuccessful: false
        };
    },
    computed: {
        ...mapGetters({
            hasSegmentFlag: 'auth/hasSegmentFlag',
            getFeatureFlag: 'subscriptions/activeSubHasFlag',
            activeIsAEU: 'subscriptions/activeIsTrainingSubscription'
        }),
        ...mapState({
            errorOnResourceCenterUpdate: (state) => state.resourceCenter.error
        }),
        hasLanguageWarning () {
            const { isDeprecated, translatedState } = this.language;

            return isDeprecated || this.showPartialTranslationWarning(translatedState);
        },
        languageWarning () {
            const { isDeprecated, translatedState, code, name } = this.language;
            const icon = {
                center: true,
                inline: true,
                fill: '#dba211',
                size: '18',
                stroke: '#fff',
                type: 'alert-circle'
            };

            if (isDeprecated) {
                return {
                    icon: {
                        ...icon,
                        fill: '#cc3340'
                    },
                    tooltip: {
                        arrow: true,
                        content: `${code} is no longer supported`
                    }
                };
            }

            if (this.showPartialTranslationWarning(translatedState)) {
                const safeLangName = this.$pendo.sanitize(name);

                return {
                    icon,
                    tooltip: {
                        html: true,
                        multiline: true,
                        arrow: true,
                        placement: 'right',
                        theme: 'light',
                        content: `<div>
                            <h4 class="pendo-guide-warning-tooltip-header">Missing ${safeLangName} content</h4>
                            To fully translate the ${this.guideType} make sure all <code>target</code> fields <br>
                            contain translated content. You may need to re-export the <br>
                            default language and re-import the xliff file.
                        </div>`
                    }
                };
            }

            return null;
        },
        guideType () {
            return this.isResourceCenter ? 'resource center' : 'guide';
        },
        canEditTranslationInApp () {
            return !this.isResourceCenter;
        },
        canTranslateWithAi () {
            return this.canEditTranslationInApp && this.getFeatureFlag('enableAILocalization') && !this.activeIsAEU;
        }
    },
    methods: {
        ...mapActions({
            importGuideLocalizationFile: 'guides/importLocalizationFile',
            importResourceCenterLocalizationFile: 'resourceCenter/importLocalizationFile'
        }),
        showPartialTranslationWarning (translatedState) {
            return translatedState === GUIDE_TRANSLATION_PARTIAL;
        },
        isTranslated (translatedState) {
            if (translatedState === GUIDE_TRANSLATION_COMPLETE || translatedState === GUIDE_TRANSLATION_PARTIAL) {
                return true;
            }

            return false;
        },
        getButtonLabel (translatedState) {
            const { isLoading, isImportSuccessful } = this;
            if (isLoading && !isImportSuccessful) return 'Importing...';
            if (!isLoading && isImportSuccessful) return 'Imported!';
            if (translatedState === GUIDE_TRANSLATION_COMPLETE || translatedState === GUIDE_TRANSLATION_PARTIAL) {
                return 'Re-import';
            }

            return 'Import';
        },
        triggerFileUpload (langCode) {
            this.$refs[`upload-${langCode}`].click();
        },
        async importFile (language) {
            const { code, name } = language;
            const file = this.$refs[`upload-${code}`].files[0];

            this.isLoading = true;
            this.isImportSuccessful = false;

            let importLocalizationFile = this.importGuideLocalizationFile;
            if (this.isResourceCenter) {
                importLocalizationFile = this.importResourceCenterLocalizationFile;
            }

            try {
                const activeState = get(this, '$router.currentRoute.params.activeState', 'draft');
                const guide = await importLocalizationFile({ guideId: this.guideId, langCode: code, file });
                const translationState = this.isResourceCenter
                    ? guide[activeState].translationStates[code].state
                    : guide.translationStates[code].state;
                this.handleImportSuccess(translationState, code, name);
                this.clearSelectedFile(code);
            } catch (err) {
                const parsedError = this.isResourceCenter ? this.errorOnResourceCenterUpdate : err;
                const errorCode = get(parsedError, 'response.data.code', 0);
                const errorStatus = get(parsedError, 'response.data.status', 0);
                this.handleImportError(errorCode, errorStatus, code);
            }
        },
        handleImportError (errorCode, errorStatus, code) {
            const useCustomErrorMessage = errorCode === 400 && XLIFF_IMPORT_ERROR_MESSAGE[errorStatus];
            const genericMsg = "We couldn't import the file. Make sure the file is a valid xliff 1.2 file.";

            const message = useCustomErrorMessage
                ? XLIFF_IMPORT_ERROR_MESSAGE[errorStatus].replace('%code', code)
                : genericMsg;
            const notification = {
                type: 'error',
                title: 'Something went wrong',
                message
            };

            this.isLoading = false;
            this.showNotification(notification);
            this.uploadTrackEvent(notification, code);
            this.clearSelectedFile(code);
        },
        handleImportSuccess (translationState, langCode, langName) {
            let notification;
            this.isLoading = false;

            switch (translationState) {
                case GUIDE_TRANSLATION_COMPLETE:
                    notification = {
                        dangerouslyUseHTMLString: true,
                        type: 'success',
                        title: `${escape(langName)} added successfully!`,
                        message:
                            'The translation file was imported and eligible users will now be shown this ' +
                            `${this.guideType} in <em>${escape(langName)}</em>.`
                    };

                    this.isImportSuccessful = true;
                    setTimeout(() => {
                        this.isImportSuccessful = false;
                    }, 5000);

                    break;
                case GUIDE_TRANSLATION_PARTIAL:
                    notification = {
                        dangerouslyUseHTMLString: true,
                        type: 'warning',
                        title: `Missing ${escape(langName)} content`,
                        message:
                            `To fully translate the ${this.guideType}, make sure all <code>target</code> fields ` +
                            'contain translated content. You may need to re-export the ' +
                            'default language and re-import the xliff file.'
                    };

                    break;
                case GUIDE_TRANSLATION_UNTRANSLATED:
                    notification = {
                        dangerouslyUseHTMLString: true,
                        type: 'error',
                        title: `No ${escape(langName)} content present`,
                        message:
                            `The imported file contains no translated content for the ${this.guideType}, make sure all <code>target</code> fields ` +
                            'contain translated content. You must re-import a corrected xliff file ' +
                            'for this language to be translated.'
                    };

                    break;
            }

            this.showNotification(notification);
            this.uploadTrackEvent(notification, langCode);
            this.clearSelectedFile(langCode);
        },
        showNotification (notificationSettings) {
            PendoNotification({
                duration: 12000,
                ...notificationSettings
            });
        },
        clearSelectedFile (langCode) {
            if (!langCode) return;
            const fileInput = this.$refs[`upload-${langCode}`];

            if (fileInput) fileInput.value = '';
        },
        openClearTranslatedStringsModal (langCode, name) {
            this.$emit('openClearStringsModal', true, { langCode, name });
        },
        uploadTrackEvent ({ type, message }, langCode) {
            const trackEventKey = this.isResourceCenter ? 'resourceCenterLanguageImport' : 'guideLanguageImport';
            const properties = {
                status: type,
                guideId: this.guideId,
                message,
                language: langCode
            };
            sendPendoTrackEvent({ trackEventKey, properties });
        },
        editTranslationInApp (language, action) {
            if (this.canTranslateWithAi && action === 'translate') {
                action = 'translate-with-ai';
            }

            this.$emit('editTranslationInApp', {
                language,
                action
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.import-lang {
    display: flex;
    align-items: center;
    width: 100%;
    height: 88px;

    .import-lang-text {
        flex: 1;

        .import-export-lang-name {
            display: inline-flex;
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid $gray-lighter-5;
    }

    .import-actions {
        display: flex;
    }
}
</style>
