import isNil from 'lodash/isNil';

export function formatQuantity (value) {
    if (value === -1) {
        return '--';
    }

    return value;
}

export function formatTime (value) {
    if (value === '--') {
        return '--';
    }
    let minutes = value;
    let hours = Math.floor(value / 60);
    let timeString = `${minutes}m`;

    if (minutes > 60) {
        minutes %= 60;
        timeString = `${hours}h ${minutes}m`;
    }
    if (hours > 24) {
        const days = Math.floor(hours / 24);
        hours %= 24;
        timeString = `${days}d ${hours}h`;
    }

    return timeString;
}

export function formatUtilization (value) {
    if (isNil(value) || value < 0) return '';

    return `${Math.round(value * 100)}%`;
}

export function downloadCSV ({ csvData, filename }) {
    if (window.navigator.msSaveBlob && window.Blob) {
        window.navigator.msSaveBlob(new Blob([csvData], { type: 'text/csv' }), filename);

        return;
    }

    const anchor = document.createElement('a');
    anchor.target = '_blank';
    anchor.download = filename;

    if (window.Blob && window.URL && window.URL.createObjectURL) {
        anchor.href = window.URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
    } else {
        anchor.href = `data:attachment/csv;charset=utf-8,${encodeURIComponent(csvData)}`;
    }

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
}
