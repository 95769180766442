import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { http } from '@pendo/http';

const ADOPT_STUDIO_BUCKET = 'adopt-studio';
const VDS_BUCKET = 'in-app-designer';

export const DESIGNER_LAUNCH_TOKEN_LS_KEY = 'pendo-designer-launch-token';

export const WHITELABEL_SETTINGS_TOKEN_LS_KEY = 'adopt-white-label-token';

export function generateDesignerToken (config) {
    return encodeURIComponent(btoa(JSON.stringify(config)));
}

export function generateAdoptStudioLaunchParams (url) {
    return {
        ...generateDesignerLaunchParams(url, ADOPT_STUDIO_BUCKET),
        DADesigner: true
    };
}

export function generateVDSLaunchParams (url) {
    return generateDesignerLaunchParams(url, VDS_BUCKET);
}

function generateDesignerLaunchParams (url, gcsBucket = ADOPT_STUDIO_BUCKET) {
    if (!url) {
        return { gcsBucket };
    }

    const buildUrl = new URL(`https://${url}`);

    const { pathname, host } = buildUrl;

    const [, gcsBucketOverride, target] = pathname.split('/');

    return {
        gcsBucket: gcsBucketOverride || gcsBucket,
        target,
        host: getFormattedDesignerUrl(host)
    };
}

export function manageMobileDesignerLaunch (stepId, guide, whiteLabelToken) {
    const config = {
        type: 'guide:activation',
        guideId: guide.id,
        stepId: stepId || guide.steps[0],
        host: '',
        target: 'mobile',
        idForApp: guide.appId,
        disableFetchAgentAPIKey: true
    };

    openDesignerHere(config, window.location, undefined, whiteLabelToken);
}

export function openDesignerHere (config, { origin } = {}, win = window, whiteLabelSettings = undefined) {
    const launch = get(win.pendo, 'designerv2.launchInAppDesigner', noop);
    const launchConfig = {
        target: config.target || 'via',
        host: origin,
        // In double agent scenarios, the native agent designer will not launch unless forceNativeDesigner is true.
        // And since the native agent running on the Adopt UI is __always__ going to be used in this function, forceNativeDesigner needs to be true to satisfy double agent scenarios.
        forceNativeDesigner: true
    };
    writeTokenToLocalStorage(
        DESIGNER_LAUNCH_TOKEN_LS_KEY,
        JSON.stringify({
            ...launchConfig,
            ...config
        })
    );
    writeTokenToLocalStorage(WHITELABEL_SETTINGS_TOKEN_LS_KEY, JSON.stringify(whiteLabelSettings));
    launch(launchConfig);
}

export function writeTokenToLocalStorage (key, token) {
    window.localStorage.setItem(key, token);
}

export function updateVisitorMetadata ({ user, values }) {
    const { visitorIds } = user;

    if (!visitorIds || !values) {
        return;
    }

    const payload = visitorIds.map((visitorId) => ({
        visitorId,
        values
    }));

    http.post('/api/s/_SID_/metadata/visitor/pendo/value', payload).catch(noop);
}

export function launchViaWindowOpener (url, token) {
    if (window.designerLaunchListener) {
        window.removeEventListener('message', window.designerLaunchListener);
    }

    window.designerLaunchListener = listener;

    window.addEventListener('message', listener);
    const appWindow = window.open(url, '_blank');

    function listener (msg) {
        if (new URL(msg.origin).hostname !== new URL(url).hostname) return;
        if (msg.data.type === 'pendo::ready') {
            appWindow.postMessage({ type: 'pendo-designer-launch::launch', token }, url);
        } else if (msg.data.type === 'pendo-designer-launch::ready') {
            window.removeEventListener('message', listener);
        }
    }
}

export function getBestDesignerUrl (domainPatterns, designerUrl) {
    if (!isEmpty(designerUrl)) {
        return designerUrl;
    }
    if (domainPatterns.length > 0 && !isEmpty(domainPatterns[0])) {
        return domainPatterns[0];
    }

    return '';
}

export function getFormattedDesignerUrl (url) {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
        return `https://${url}`;
    }

    return url;
}
