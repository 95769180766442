<template>
    <svg
        :x="x"
        :y="y"
        :width="width"
        :height="height"
        viewBox="0 0 28 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.1351 25.8559C20.7625 25.8559 26.1351 20.4833 26.1351 13.8559C26.1351 7.22848 20.7625 1.8559 14.1351 1.8559C7.50765 1.8559 2.13507 7.22848 2.13507 13.8559C2.13507 20.4833 7.50765 25.8559 14.1351 25.8559Z"
            stroke="#8369C1"
            stroke-width="3" />
        <path
            d="M14.1351 25.8559L14.1351 37.8559"
            stroke="#8369C1"
            stroke-width="3"
            stroke-linecap="round" />
        <path
            d="M19.8346 31.693L8.43556 31.693"
            stroke="#8369C1"
            stroke-width="3"
            stroke-linecap="round" />
        <path
            d="M13.3908 8.8559L7.64725 14.5995H13.3908V20.3438L19.1351 14.5995V8.8559H13.3908Z"
            fill="#8369C1" />
    </svg>
</template>

<script>
export default {
    name: 'InternationalWomensDayChevron',
    props: {
        x: {
            type: Number,
            default: undefined
        },
        y: {
            type: Number,
            default: undefined
        },
        width: {
            type: Number,
            default: 32
        },
        height: {
            type: Number,
            default: 32
        }
    }
};
</script>
