<template>
    <div>
        <div
            v-if="isFetching"
            class="fetching-empty-state" />
        <div
            v-else
            class="view--workflows">
            <workflows-details-header
                :show-blade="showBlade"
                @deleteClicked="onDeleteClicked"
                @showBlade="handleShowBladeCloseInsights"
                @hideBlade="showBlade = false" />

            <pendo-page-content tabs>
                <pendo-tabs
                    :key="$route.fullPath"
                    v-model="activeTab"
                    :tabs="tabs"
                    theme="app"
                    class="workflows-tabs">
                    <template #workflowsDetailsHome>
                        <workflows-details-home />
                    </template>
                    <template #journeys>
                        <journeys-page @showBlade="showBlade = true" />
                    </template>
                </pendo-tabs>
            </pendo-page-content>

            <workflows-delete-modal
                :visible="deleteModalVisible"
                :name="workflowName"
                @deleteWorkflow="onDeleteWorkflow"
                @modalClose="onDeleteModalClose" />
        </div>
    </div>
</template>

<script>
import WorkflowsDetailsHeader from '@/components/workflows/WorkflowsDetailsHeader';
import WorkflowsDetailsHome from '@/components/workflows/details/WorkflowsDetailsHome';
import JourneysPage from '@/components/workflows/journeys/JourneysPage';
import WorkflowsDeleteModal from '@/components/workflows/WorkflowsDeleteModal';
import { PendoNotification, PendoPageContent, PendoTabs } from '@pendo/components';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { hasInvalidWorkflowSteps, cleanAndFormatWorkflowSteps } from '@/utils/workflows';

const defaultWorkflowSettings = Object.freeze({
    dataSource: '',
    timePeriod: '',
    totalChartDataSource: '',
    viewBy: {},
    visitorList: {
        order: [],
        sizes: {},
        sort: {},
        visible: {}
    }
});

export default {
    name: 'WorkflowsDetailsView',
    components: {
        WorkflowsDetailsHeader,
        PendoPageContent,
        PendoTabs,
        WorkflowsDetailsHome,
        JourneysPage,
        WorkflowsDeleteModal
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            deleteModalVisible: false,
            newWorkflowSettings: cloneDeep(defaultWorkflowSettings),
            showBlade: false
        };
    },
    computed: {
        ...mapGetters({
            savedWorkflow: 'workflows/savedWorkflow',
            metadataListByKind: 'metadata/metadataListByKind',
            pageList: 'pages/list',
            featureList: 'features/list'
        }),
        ...mapState({
            workflowsSettings: (state) => state.workflows.workflowsSettings,
            isFetching: (state) => state.workflows.isFetching
        }),
        activeTab: {
            get () {
                return this.$route.name;
            },
            set (tab) {
                this.$router.push({ name: tab }).catch(() => {});
            }
        },
        workflowName () {
            return this.savedWorkflow ? this.savedWorkflow.name : 'Your Workflow';
        },
        hasInvalidSavedWorkflowSteps () {
            const workflowSteps = get(this.savedWorkflow, 'workflowSteps', []);

            return hasInvalidWorkflowSteps(workflowSteps, {
                featuresMap: keyBy(this.featureList, 'id'),
                pagesMap: keyBy(this.pageList, 'id')
            });
        },
        tabs () {
            const tabs = [
                {
                    prop: 'workflowsDetailsHome',
                    label: 'Workflow',
                    lazy: false
                }
            ];

            if (!this.hasInvalidSavedWorkflowSteps) {
                tabs.push({
                    prop: 'journeys',
                    label: 'Journeys',
                    lazy: false
                });
            }

            return tabs;
        }
    },
    async created () {
        this.setSavedWorkflowId({ id: this.id });
        const fetchWorkflowsPromise = this.fetchWorkflows();
        if (this.isFetching) {
            await fetchWorkflowsPromise;
        }
        await this.loadMetadata();

        if (this.id !== 'new') {
            if (!this.savedWorkflow) {
                this.$router.replace({
                    name: 'workflowsList'
                });

                PendoNotification({
                    type: 'error',
                    message: 'Workflow does not exist.'
                });

                return;
            }

            // set a new 'invalidCountableId' for existing workflows with steps that have a deleted/inactive/unshared countable
            const workflowSteps = cleanAndFormatWorkflowSteps(this.savedWorkflow.workflowSteps, {
                pagesMap: keyBy(this.pageList, 'id'),
                featuresMap: keyBy(this.featureList, 'id')
            });
            const workflow = { ...cloneDeep(this.savedWorkflow), workflowSteps };
            this.setWorkingWorkflow({ workflow });
        }
    },
    methods: {
        ...mapActions({
            fetchWorkflows: 'workflows/fetch',
            removeWorkflow: 'workflows/remove',
            loadMetadata: 'metadata/loadAll'
        }),
        ...mapMutations({
            setSavedWorkflowId: 'workflows/setSavedWorkflowId',
            setWorkingWorkflow: 'workflows/setWorkingWorkflow',
            setWorkflowsSettings: 'workflows/setWorkflowsSettings',
            hideInsights: 'workflows/hideInsights'
        }),
        onDeleteClicked () {
            this.deleteModalVisible = true;
        },
        onDeleteModalClose () {
            this.deleteModalVisible = false;
        },
        async onDeleteWorkflow () {
            const workflow = cloneDeep(this.savedWorkflow);

            try {
                await this.removeWorkflow({ id: workflow.id });
                PendoNotification({
                    type: 'success',
                    message: `${workflow.name} deleted.`
                });

                this.$router.push({
                    name: 'workflowsList'
                });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    message: `There was an error deleting ${workflow.name}. Please try again.`
                });
            }

            this.deleteModalVisible = false;
        },
        onWorkflowSaved ({ isNew, workflowId }) {
            if (!isNew) return;

            const workflowsSettings = cloneDeep(this.workflowsSettings);
            set(workflowsSettings, `${workflowId}`, this.newWorkflowSettings);
            this.newWorkflowSettings = cloneDeep(defaultWorkflowSettings);
            this.setWorkflowsSettings({ workflowsSettings });
        },
        handleShowBladeCloseInsights () {
            this.showBlade = true;
            this.hideInsights();
        }
    }
};
</script>
<style lang="scss" scoped>
.fetching-empty-state {
    height: 100%;
    width: 100%;
}
</style>
