<template>
    <pendo-localization-settings-page
        :app="app"
        :config-loading="configLoading"
        :options="options"
        :localization-config="localizationConfig"
        :language-mapping="languageMapping"
        :language-table-data="languageTableData"
        :server-error-message="serverErrorMessage"
        @update-language="updateLanguage"
        @update-app-localization="updateAppLocalization"
        @update-app-localization-property="updateAppLocalizationProperty"
        @delete-language="deleteLanguageFromApp" />
</template>

<script>
import { PendoLocalizationSettingsPage } from '@pendo/components';
import isEmpty from 'lodash/isEmpty';
import { mapGetters, mapActions } from 'vuex';
import {
    deleteLanguage,
    sortLanguagesByName,
    formatEnabledLanguages,
    updateConfigProperty,
    updateLanguageMetadataValue
} from '@/utils/localization';
import { GuideLocalization } from '@pendo/services/BuildingBlocks';
import get from 'lodash/get';

export default {
    name: 'LocalizationSettings',
    components: {
        PendoLocalizationSettingsPage
    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            options: [],
            configLoading: true,
            localizationConfig: {},
            languageMapping: GuideLocalization.languageMapping,
            serverErrorMessage: null
        };
    },
    computed: {
        ...mapGetters({
            metadataListByKind: 'metadata/metadataListByKind'
        }),
        languageTableData () {
            if (isEmpty(this.localizationConfig)) return null;

            return sortLanguagesByName(formatEnabledLanguages(this.localizationConfig, true), true);
        },
        localizationSettings () {
            return get(this.app, 'localizationSettings', {});
        }
    },
    async created () {
        await this.loadMetadata();
        await this.fetchAndSetLocalizationConfig();
        this.fetchAndSetMetadataFields();
    },
    methods: {
        ...mapActions({
            loadMetadata: 'metadata/loadAll',
            updateLanguageMetadataValue: 'metadata/update',
            updateAppFlag: 'apps/updateFlag',
            fetchLocalizationSettingsForApp: 'apps/fetchLocalizationSettingsForApp'
        }),
        async fetchAndSetLocalizationConfig (noCache = true) {
            this.configLoading = true;
            await this.fetchLocalizationSettingsForApp({ app: this.app, noCache });
            this.localizationConfig = this.localizationSettings;
            this.configLoading = false;
        },
        fetchAndSetMetadataFields () {
            const metadataFields = this.metadataListByKind('visitor', { groupsToExclude: ['auto', 'pendo'] });
            this.options = this.buildOptions(metadataFields);
        },
        buildOptions (metadataFields) {
            const filteredData = metadataFields.filter((field) => field.Type === 'string');
            const mappedData = filteredData.map((metadata) => {
                const { DisplayName, field, group } = metadata;
                const label = DisplayName || field;
                const id = `${group}.${field}`;
                const icon = {
                    type: 'user'
                };

                return {
                    label,
                    id,
                    icon,
                    group,
                    field
                };
            });

            return mappedData;
        },
        async updateLanguage ({ appId, code, metadataValue }) {
            this.configLoading = true;

            try {
                await updateLanguageMetadataValue(appId, { code, metadataValue });
                await this.fetchAndSetLocalizationConfig();
            } catch (error) {
                this.serverErrorMessage = 'Server error. Please try again.';
            } finally {
                this.configLoading = false;
            }
        },
        async updateAppLocalization ({ app, updates }) {
            this.configLoading = true;
            const { applicationFlags } = updates;

            try {
                await this.updateAppFlag({
                    appId: app.id,
                    flag: 'guideLocalization',
                    enabled: applicationFlags.guideLocalization
                });
            } catch (error) {
                this.serverErrorMessage = 'Server error. Please try again.';
            }

            this.configLoading = false;
        },
        async updateAppLocalizationProperty ({ prop, value }) {
            this.configLoading = true;
            const updatedProperty = { [`${prop}`]: value };
            const props = { ...this.localizationConfig, ...updatedProperty };
            try {
                const newConfig = await updateConfigProperty(this.app.id, props);
                this.localizationConfig = newConfig;
            } catch (error) {
                this.serverErrorMessage = 'Server error. Please try again.';
            }

            this.configLoading = false;
        },
        async deleteLanguageFromApp ({ appId, langCode }) {
            this.configLoading = true;

            try {
                await deleteLanguage(appId, langCode);
                await this.fetchAndSetLocalizationConfig();
            } catch (error) {
                this.serverErrorMessage = 'Server error. Please try again.';
            }
        }
    }
};
</script>
