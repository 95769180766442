import { mapActions, mapGetters, mapState } from 'vuex';
import { getSegmentsUsingSegment } from '@/utils/segments';
import { getGuidesUsingSegment } from '@/utils/guides';

export const segmentDeleteModalMixin = {
    computed: {
        ...mapState({
            guideMap: (state) => state.guides.map,
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup',
            guideList: 'guides/list'
        })
    },
    created () {
        this.fetchSegments();
        this.fetchGuides();
    },
    methods: {
        ...mapActions({
            fetchSegments: 'filters/fetchSegments',
            fetchGuides: 'guides/fetch',
            deleteSegment: 'filters/deleteSegment',
            updateActiveSegmentId: 'filters/updateActiveSegmentId'
        }),
        confirmDeleteSegment ({ id }) {
            if (this.$refs?.segmentChooser) {
                this.$refs.segmentChooser.toggleMenu();
            }

            const guidesUsingSegment = getGuidesUsingSegment(id, this.guideList);
            const segmentsUsingSegment = getSegmentsUsingSegment(id, this.segmentsListByGroup.customList);
            let impactedKind = 'guide';
            let impactedItems = [];
            let title = 'Delete Segment?';

            if (guidesUsingSegment.length) {
                impactedItems = guidesUsingSegment;
                title = 'Guide Dependency Detected';
            } else if (segmentsUsingSegment.length) {
                impactedKind = 'segment';
                impactedItems = segmentsUsingSegment;
                title = 'Segment Dependency Detected';
            }

            this.$modal.show('via-modal', {
                title,
                component: 'ItemDeleteWarning',
                componentProps: {
                    kind: 'segment',
                    impactedKind,
                    impactedItems,
                    preventDeleteWithImpactedItems: true,
                    deleteFn: async () => {
                        this.$modal.hide('via-modal');
                        await this.deleteSegment({ id });
                        // If the active segment is deleted, reset it to everyone
                        if (this.activeSegmentId === id) {
                            this.updateActiveSegmentId({ id: 'everyone' });
                        }
                    }
                }
            });
        }
    }
};
