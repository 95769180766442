<template>
    <create-account
        :is-white-label="isWhiteLabel"
        :is-local="isLocal">
        <template #logo>
            <slot name="logo" />
        </template>
        <template #nightLogo>
            <slot name="nightLogo" />
        </template>
    </create-account>
</template>

<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { CreateAccount } from '@pendo/login';

export default {
    name: 'CompleteUser',
    components: {
        CreateAccount
    },
    computed: {
        ...mapState({
            unauthenticatedTrainingAttributes: (state) => state.subscriptions.unauthenticatedTrainingAttributes
        }),
        isWhiteLabel () {
            return !!get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings');
        },
        isLocal () {
            return window.__via_info__.isLocal;
        }
    }
};
</script>

<style></style>
