import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { JOURNEY_TYPES, INSIGHT_TYPES } from '@/stateless-components/workflows/journeys/utils/constants';

/* Insights should return an object that uses the following structure:
{
    heading: String -- contains one of these values: ['Most Common', 'Fewest Steps', 'Quickest']
    applyToHeader: Boolean -- determines if the insight should point at the header of the column
    title: String  -- is shown at the top of the insight bubble
    key: String -- used in `showGrouping` method in JourneyList component and in InsightBody to determine content
    nodes: Array -- contains the steps that should be highlighted
} */

export const longestTimeBetweenSteps = (steps) => {
    const sortedSteps = cloneDeep(steps);
    sortedSteps.sort((a, b) => {
        // Sort null values to the bottom
        if (a.avgTimeSinceLastStep === null) return 1;
        if (b.avgTimeSinceLastStep === null) return -1;

        // Generic sorting based on avgTimeSinceLastStep
        if (a.avgTimeSinceLastStep === b.avgTimeSinceLastStep) return 0;

        return a.avgTimeSinceLastStep > b.avgTimeSinceLastStep ? -1 : 1;
    });

    const longestStepIndex = steps.findIndex((step) => step.nodeId === sortedSteps[0].nodeId);

    return {
        heading: JOURNEY_TYPES.MOST_COMMON,
        applyToHeader: false,
        title: 'Longest time between steps in the most common journey',
        key: INSIGHT_TYPES.LONGEST_STEPS,
        nodes: steps.slice(longestStepIndex - 1, longestStepIndex + 1)
    };
};

export const repeatedSequences = (steps) => {
    const maxLength = Math.floor(steps.length / 2);

    const mappedSteps = steps.map((step) => {
        const duplicateId = step.duplicateTags ? step.duplicateTags.map((tag) => tag.stepId).join('||') : step.stepId;

        return {
            stepId: duplicateId,
            name: step.name,
            nodeId: step.nodeId
        };
    });

    const startingDuplicateSequenceSets = getDuplicateStepIndexes(mappedSteps, Array(mappedSteps.length).keys()).map(
        (duplicateStepBlock) => {
            return duplicateStepBlock.map((step) => ({ firstStep: step, length: 1 }));
        }
    );

    const duplicateSequenceSets = startingDuplicateSequenceSets
        .flatMap((sequenceSet) => {
            return getDuplicateSequencesFromSet(mappedSteps, sequenceSet, 1, maxLength);
        })
        .filter((set) => set.length > 1)
        .sort((setA, setB) => {
            // sort longest to shortest sequences
            return setB[0].sequenceLength - setA[0].sequenceLength;
        });

    // Remove encompassed child sequences
    // This is done in a for loop instead of a map so that removed non-duplicate sequences do not block even smaller sequences
    for (let setIndex = 0; setIndex < duplicateSequenceSets.length; setIndex++) {
        const set = duplicateSequenceSets[setIndex];
        let filteredSet = set.filter((sequence) => {
            const { firstStep, lastStep } = sequence;
            let sequenceIsEncompassed = false;

            // Only need to work backwards up the chain because a sequence can only be encompassed by a longer sequence
            for (let largerSetIndex = setIndex - 1; largerSetIndex >= 0; largerSetIndex--) {
                const largerSet = duplicateSequenceSets[largerSetIndex];
                const encompassingSequence = largerSet.findIndex((otherSequence) => {
                    return (
                        (otherSequence.firstStep < firstStep && otherSequence.lastStep >= lastStep) ||
                        (otherSequence.firstStep <= firstStep && otherSequence.lastStep > lastStep)
                    );
                });
                if (encompassingSequence > -1) {
                    sequenceIsEncompassed = true;
                    break;
                }
            }

            return !sequenceIsEncompassed;
        });
        if (filteredSet.length === 1) filteredSet = [];
        duplicateSequenceSets[setIndex] = filteredSet;
    }

    const filterEmptySequenceSets = duplicateSequenceSets.filter((set) => {
        return set.length > 1;
    });

    const repeatedSequenceNodes = filterEmptySequenceSets.map((sequenceSet) => {
        return sequenceSet.map((sequence) => {
            return mappedSteps.slice(sequence.firstStep, sequence.lastStep + 1);
        });
    });

    if (repeatedSequenceNodes.length) {
        return repeatedSequenceNodes.map((nodes) => {
            const repeatedSteps = nodes[0].length === 1;

            return {
                heading: JOURNEY_TYPES.MOST_COMMON,
                key: repeatedSteps ? INSIGHT_TYPES.REPEATED_STEPS : INSIGHT_TYPES.REPEATED_SEQUENCES,
                applyToHeader: false,
                title: `Repeated ${repeatedSteps ? 'steps' : 'sequences'} in the most common journey`,
                nodes
            };
        });
    }

    return [];
};

export const quickestVsMostCommon = () => {
    return {
        heading: JOURNEY_TYPES.QUICKEST,
        applyToHeader: true,
        title: 'Quickest versus most common journey',
        key: INSIGHT_TYPES.QUICKEST_V_MOST_COMMON
    };
};

/* Helper functions */

function getDuplicateStepIndexes (allSteps, indexesToCheck) {
    const stepMap = {};

    for (const stepIndex of indexesToCheck) {
        if (stepIndex > allSteps.length - 1) break;
        const { stepId } = allSteps[stepIndex];
        stepMap[stepId] = [...get(stepMap, stepId, []), stepIndex];
    }

    Object.keys(stepMap).forEach((stepId) => {
        if (Object.keys(stepMap[stepId]).length === 1) delete stepMap[stepId];
    });

    return Object.values(stepMap);
}

function getDuplicateSequencesFromSet (allSteps, sequenceSet, currentLength, maxLength) {
    const indexesToCheck = sequenceSet.map((sequence) => sequence.firstStep + currentLength);
    const duplicateSubsequentSteps = getDuplicateStepIndexes(allSteps, indexesToCheck);
    let unfinishedSequences = [];
    if (currentLength < maxLength) {
        const potentialGrownSequenceSets = duplicateSubsequentSteps.map((stepBlock) => {
            return stepBlock.map((duplicateSubsequentStep) => {
                const matchedSequence = sequenceSet.find((sequence) => {
                    const nextPotentialStep = sequence.firstStep + currentLength;

                    // Find the sequences that correspond with duplicate steps identified
                    return nextPotentialStep === duplicateSubsequentStep;
                });

                return { firstStep: matchedSequence.firstStep, sequenceLength: currentLength + 1 };
            });
        });
        const sequencesThatCanGrow = potentialGrownSequenceSets.map((potentialSequenceSet) => {
            return potentialSequenceSet.filter((sequence, index) => {
                const nextPotentialStep = sequence.firstStep + currentLength;
                const nextSequenceInSet = get(potentialSequenceSet, index + 1);

                return !nextSequenceInSet || nextPotentialStep < nextSequenceInSet.firstStep;
            });
        });

        unfinishedSequences = sequencesThatCanGrow.filter((set) => set.length > 1);
    }
    const flatUnfinishedSequences = unfinishedSequences.flat();
    const finishedSequences = sequenceSet
        .filter((sequence) => {
            return (
                flatUnfinishedSequences.findIndex(
                    (unfinishedSequence) => unfinishedSequence.firstStep === sequence.firstStep
                ) === -1
            );
        })
        .map((seq) => {
            return { ...seq, sequenceLength: currentLength, lastStep: seq.firstStep + currentLength - 1 };
        });
    const extendedSequences = unfinishedSequences.flatMap((unfinishedSequenceSet) => {
        return getDuplicateSequencesFromSet(allSteps, unfinishedSequenceSet, currentLength + 1, maxLength);
    });

    return [finishedSequences, ...extendedSequences];
}
