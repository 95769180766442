<template>
    <div class="view--invitation-error">
        <invitation-error />
    </div>
</template>

<script>
import { InvitationError } from '@pendo/login';

export default {
    name: 'InvitationErrorView',
    components: {
        InvitationError
    }
};
</script>
