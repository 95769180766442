<template>
    <div class="workflows-query-steps">
        <pendo-form-item
            :data-cy="`workflows-${stepType}-app-multiselect`"
            :label="`${startCase(stepType)}ing event`"
            :prop="stepType"
            :rules="rules">
            <pendo-multiselect
                class="app"
                :disabled="disabled || isLoading"
                full-width
                :value="appValue"
                :options="getAppsWithAnalytics"
                label-key="displayName"
                placeholder="Search Apps..."
                @select="$emit('appSelected', $event)">
                <template #placeholder>
                    Select an app
                </template>

                <template #option="{ option }">
                    <div class="workflow-app-display-option">
                        <pendo-app-display :apps="option" />
                    </div>
                </template>

                <template #selectedLabel="{ option }">
                    <pendo-app-display :apps="option" />
                </template>
            </pendo-multiselect>
        </pendo-form-item>
        <div :data-cy="`workflows-${stepType}-tag-multiselect`">
            <pendo-multiselect
                ref="tagMultiselect"
                class="countable"
                :disabled="disabled || !appValue || isLoading"
                :max-menu-width="maxMenuWidth"
                :max-menu-height="maxMenuHeight"
                :value="countableValue"
                :options="countableOptions"
                placeholder="Search Pages and Features..."
                searchable
                @select="$emit('countableSelected', $event)"
                @highlighted-option-change="onHighlightedOptionChange"
                @open="onTagMultiselectOpen"
                @close="onTagMultiselectClose"
                @filtered-options-change="onFilteredOptionsChange">
                <template #placeholder>
                    Select a Page or Feature
                </template>

                <template #noData>
                    There are no tagged Pages or Features for this app.
                </template>

                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>

                <template #aside>
                    <slot name="aside" />
                </template>
            </pendo-multiselect>
        </div>
    </div>
</template>
<script>
import { PendoAppDisplay, PendoFormItem, PendoIconOption, PendoMultiselect } from '@pendo/components';
import startCase from 'lodash/startCase';
import {
    maxMenuHeightForMultiselect,
    maxMenuWidthForMultiselect
} from '@/stateless-components/utils/countable-hover-card';

export default {
    name: 'QueryBuilderStep',
    components: {
        PendoAppDisplay,
        PendoFormItem,
        PendoIconOption,
        PendoMultiselect
    },
    props: {
        appValue: {
            type: Object,
            default: null
        },
        countableOptions: {
            type: Array,
            required: true
        },
        countableValue: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        getAppsWithAnalytics: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        rules: {
            type: Array,
            required: true
        },
        stepType: {
            type: String,
            required: true
        }
    },
    emits: [
        'appSelected',
        'countableSelected',
        'highlighted-option-change',
        'tag-multiselect-open',
        'tag-multiselect-close',
        'filtered-options-change'
    ],
    data () {
        return {
            startCase,
            highlightedOptionType: null
        };
    },
    computed: {
        hasAsideSlot () {
            return !!this.$scopedSlots.aside?.();
        },
        maxMenuWidth () {
            return maxMenuWidthForMultiselect(this.hasAsideSlot);
        },
        maxMenuHeight () {
            return maxMenuHeightForMultiselect(this.hasAsideSlot ? this.highlightedOptionType : null);
        }
    },
    methods: {
        onHighlightedOptionChange (option) {
            if (option?.kind) {
                this.highlightedOptionType = option.kind.toLowerCase();
            }

            this.$emit('highlighted-option-change', option);
        },
        onTagMultiselectOpen () {
            this.$emit('tag-multiselect-open', this.$refs.tagMultiselect);
        },
        onTagMultiselectClose () {
            this.highlightedOptionType = null;
            this.$emit('tag-multiselect-close');
        },
        onFilteredOptionsChange ($event) {
            this.$emit('filtered-options-change', $event);
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-query-steps {
    display: flex;

    > * {
        margin-right: 14px;
        min-width: 200px;
        width: 100%;
        max-width: 324px;
    }

    > :last-child {
        padding-top: 24px;
    }
}
</style>
<style lang="scss">
.workflow-app-display-option {
    line-height: 26px;
}
</style>
