<template>
    <div
        v-if="selectedRowCount"
        class="pendo-table__bulk-actions-header">
        <span>
            <pendo-divider
                direction="vertical"
                stroke="#DADCE5"
                height="23px"
                width="26px" />
        </span>
        <div>
            <slot name="actions" />
        </div>
    </div>
</template>

<script>
import { PendoDivider } from '@pendo/components';

export default {
    name: 'PendoBulkActionsHeader',
    components: {
        PendoDivider
    },
    props: {
        selectedRowCount: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="scss">
.pendo-table__bulk-actions-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -8px;
}
</style>
