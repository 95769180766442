import { rowFormatter, getColumnWidth } from '@/utils/table-formatters';
import { mergeDefaultColumnsWithUserSettings } from '@/stateless-components/utils/table-user-settings';

export function visitorListSchemaColumns (schemaList) {
    const analyticsSchemaGroups = ['agent', 'custom', 'auto'];
    const duplicateColumnProps = ['visitor.auto.id', 'visitor.auto.lastvisit'];

    return schemaList.reduce((acc, schema) => {
        if (!analyticsSchemaGroups.includes(schema.group)) return acc;
        if (duplicateColumnProps.includes(schema.field)) return acc;

        const column = {
            prop: schema.field,
            fieldName: schema.field,
            functionName: 'metadataField',
            label: schema.name,
            schema: schema.schema,
            sortable: true
        };

        acc.push(column);

        return acc;
    }, []);
}

export function visitorListDefaultColumns (schemaList, getActiveTimezone) {
    const columns = [
        {
            prop: 'visitorId',
            label: 'Visitor ID',
            fieldName: 'visitor.auto.id',
            functionName: 'visitorIdColumn',
            schema: 'string',
            sortable: true,
            visible: true,
            minWidth: '200'
        },
        {
            prop: 'lastVisit',
            label: 'Last Visit',
            fieldName: 'visitor.auto.lastvisit',
            functionName: 'metadataField',
            schema: 'time',
            sortable: true,
            visible: true
        },
        {
            prop: 'daysActive',
            label: 'Days Active',
            functionName: 'daysActive',
            schema: 'number',
            sortable: true,
            visible: true
        },
        {
            prop: 'eventCount',
            label: 'Events',
            functionName: 'eventCount',
            schema: 'number',
            sortable: true,
            visible: true
        },
        {
            prop: 'lastVisitAppDisplayName',
            label: 'Last Visited App',
            functionName: 'metadataField',
            schema: 'string',
            sortable: true,
            visible: true
        }
    ];

    columns.push(...visitorListSchemaColumns(schemaList));
    columns.forEach((column) => {
        column.formatter = (row) => rowFormatter(row, column, getActiveTimezone);
        column.width = getColumnWidth(column);
    });

    return columns;
}

export function getVisitorListColumns (visitorListUserSettings, schemaList, getActiveTimezone) {
    return mergeDefaultColumnsWithUserSettings(
        visitorListDefaultColumns(schemaList, getActiveTimezone),
        visitorListUserSettings
    );
}
