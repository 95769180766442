<template>
    <div class="difference-value">
        <div
            v-if="isRemoved"
            class="tag-container">
            <slot name="remove-tag" />
        </div>
        <div
            v-else-if="$slots.preview"
            class="preview-container">
            <slot name="preview" />
        </div>
        <guide-audit-localization-details
            v-else-if="isLanguage"
            :languages="difference" />
        <guide-status-changer
            v-else-if="isStatus"
            :guide="difference"
            :disable-status-change="true"
            :should-show-was-scheduled="true" />
        <div
            v-else-if="isEmailConfig"
            class="email-config-section">
            <div>Sender: {{ difference.senderName }}</div>
            <div>Subject: {{ difference.subject }}</div>
            <div>
                Metadata property:
                {{ difference.emailMetadataPropertyName }}
            </div>
        </div>
        <span
            v-else
            class="diff-container">{{ difference }}</span>
    </div>
</template>

<script>
import GuideStatusChanger from '@/components/guides/common/GuideStatusChanger.vue';
import GuideAuditLocalizationDetails from '../GuideAuditLocalizationDetails.vue';
import { isValidStatusValue, isValidEmailConfigValue, isValidLocalizationValue } from '../guide-audit-utils';

export default {
    name: 'Value',
    components: {
        GuideAuditLocalizationDetails,
        GuideStatusChanger
    },
    props: {
        difference: {
            type: [String, Object],
            default: '',
            validator (difference) {
                if (typeof difference === 'string') return true;

                return (
                    isValidStatusValue(difference) ||
                    isValidEmailConfigValue(difference) ||
                    isValidLocalizationValue(difference)
                );
            }
        },
        isStatus: {
            type: Boolean,
            default: false
        },
        isLanguage: {
            type: Boolean,
            default: false
        },
        isEmailConfig: {
            type: Boolean,
            default: false
        },
        isRemoved: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style scoped>
.difference-value,
.difference-value > div {
    height: 100%;
}

.difference-value > .tag-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.difference-value > span {
    display: inline-block;
    white-space: pre;
    line-height: 30px;
}
</style>
