<template>
    <div data-cy="event-breakdown-table">
        <pendo-table
            title="Event breakdown"
            row-key="id"
            resizable
            csv-download
            :height="600"
            :data="epRows"
            :columns="columns"
            :default-sort="defaultSort">
            <template #headerLeft>
                <div class="pendo-table__title-info">
                    <div class="pendo-table__title-text">
                        Event breakdown
                    </div>
                    <div class="pendo-table__title-count">
                        ({{ epCount }})
                    </div>
                    <small class="pendo-table__title-note">
                        {{ tableSubTitle }}
                    </small>
                </div>
            </template>
            <template #status>
                <pendo-status-overlay
                    :state="aggStatus"
                    :options="statusOverlayConfig" />
            </template>
        </pendo-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import { PendoTable, PendoStatusOverlay } from '@pendo/components';
import { MAX_LIMIT, trackEvents } from '@/aggregations/event-breakdown';
import { connectAggregationToState, withComponent, withStore } from '@pendo/agg-connect';
import { LOADING_STATES } from '@pendo/services/Constants';
import get from 'lodash/get';
import { v4 as uuid } from 'uuid';
import { MOBILE_PLATFORMS } from '@/stateless-components/utils/apps';
import { formatValueForSchema } from '@/utils/formatters';
import { shouldDisplayMetadata } from '@/utils/metadata';

export default {
    name: 'EventBreakdownTable',
    components: {
        PendoTable,
        PendoStatusOverlay
    },
    props: {
        trackEvent: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            defaultSort: {
                prop: 'day',
                order: 'descending'
            },
            aggStatus: LOADING_STATES.LOADING,
            epRows: null,
            statusOverlayConfig: {
                errorText: 'Looks like something went wrong.',
                errorSubtext: 'Try reloading the page or contacting support if this issue continues.',
                emptyText: 'There is no Event Property data on this Track Event',
                emptySubtext: `If you've already added an Event Property to this Track Event,
                    it may take up to 60 minutes to collect and appear here.
                    Please check back later.`
            }
        };
    },
    computed: {
        ...mapGetters({
            getAppById: 'apps/appById',
            activeTimeSeries: 'filters/activeTimeSeries',
            getActiveTimezone: 'subscriptions/getTimezone'
        }),
        ...mapState({
            eventMetadataMap: (state) => state.metadata.map.event?.event || {}
        }),
        appId () {
            return this.trackEvent?.appId || '';
        },
        isMobileApp () {
            const app = this.getAppById(this.appId);
            const platform = get(app, 'platform', '');

            return MOBILE_PLATFORMS.includes(platform);
        },
        baseColumns () {
            return [
                {
                    type: 'link',
                    to: (row) => ({ name: 'visitorDetails', params: { visitorId: row.visitorId } }),
                    label: 'Visitor',
                    prop: 'visitorId',
                    sortable: true
                },
                {
                    label: 'Date',
                    prop: 'day',
                    formatter: (row) =>
                        convertToSubscriptionTimezone(this.getActiveTimezone, row.day).format(DATE_FORMAT.short),
                    sortable: true
                },
                {
                    label: 'Total Events',
                    prop: 'numEvents',
                    sortable: true
                }
            ];
        },
        columns () {
            return [...this.baseColumns, ...this.customEventPropertyColumns, ...this.devicePropertyColumns];
        },
        devicePropertyColumns () {
            const columns = [
                {
                    label: this.isMobileApp ? 'Device Model' : 'Browser Name',
                    prop: 'parsedUserAgent.name',
                    sortable: true
                },
                {
                    label: this.isMobileApp ? 'OS Version' : 'Browser Version',
                    prop: 'parsedUserAgent.version',
                    sortable: true
                },
                {
                    label: 'Device Type',
                    prop: 'parsedUserAgent.deviceType',
                    sortable: true
                },
                {
                    label: 'Operating System',
                    prop: 'parsedUserAgent.os',
                    sortable: true
                }
            ];

            if (this.isMobileApp) {
                columns.push(
                    {
                        label: 'App Version',
                        prop: 'parsedUserAgent.appVersion',
                        sortable: true
                    },
                    {
                        label: 'SDK Version',
                        prop: 'parsedUserAgent.sdkVersion',
                        sortable: true
                    }
                );
            }

            return columns;
        },
        devicePropertyNames () {
            return this.devicePropertyColumns.map((column) => column.prop.split('.')[1]);
        },
        customEventPropertyColumns () {
            if (!Object.keys(this.eventMetadataMap).length) {
                return [];
            }

            const getEventMetadata = (eventPropertyName) => get(this, `eventMetadataMap.${eventPropertyName}`);

            return this.eventPropertyNameList
                .filter((eventPropertyName) => {
                    const metadata = getEventMetadata(eventPropertyName);

                    return metadata && !metadata.isDeleted && shouldDisplayMetadata(metadata);
                })
                .map((eventPropertyName) => {
                    const metadata = getEventMetadata(eventPropertyName);

                    return {
                        label: metadata.DisplayName || eventPropertyName,
                        prop: `properties.${eventPropertyName}`,
                        sortable: true,
                        formatter: (row) => {
                            const value = row.properties[eventPropertyName];

                            return this.formatColumn(value, metadata.Type);
                        }
                    };
                });
        },
        timeSeries () {
            return {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
        },
        tableSubTitle () {
            const isAtLimit = this.epCount === MAX_LIMIT;
            const limitWarning = ` (up to ${this.numberAsStringWithCommas(MAX_LIMIT)} values)`;

            return `This table reflects the date range and segment filters above${isAtLimit ? limitWarning : ''}.`;
        },
        epCount () {
            return get(this, 'epRows.length', 0);
        },
        eventPropertyNameList () {
            return this.trackEvent?.eventPropertyNameList || [];
        }
    },
    async created () {
        this.initAggSubscription().subscribe(this.onAggResponse);
    },
    methods: {
        initAggSubscription () {
            return connectAggregationToState(
                trackEvents,
                withComponent({
                    id: 'trackEvent.id',
                    devicePropertyNames: 'devicePropertyNames',
                    timeSeries: 'timeSeries',
                    customEventPropertyNames: 'eventPropertyNameList'
                }),
                withStore({
                    segmentId: (state) => state.filters.activeSegmentId
                })
            )({
                component: this,
                store: this.$store
            });
        },
        onAggResponse ({ status, value }) {
            this.aggStatus = status;
            if (status === LOADING_STATES.RESOLVED) {
                const result = value?.data[value?.periods?.[0]] || [];
                this.epRows = result.map((row) => {
                    return { id: uuid(), ...row };
                });
                if (!this.epCount) {
                    this.aggStatus = LOADING_STATES.EMPTY;
                }
            }
        },
        numberAsStringWithCommas (num) {
            return num.toLocaleString('en-US');
        },
        formatColumn (value, schema) {
            if (value === null || value === undefined) {
                return '--';
            }

            return formatValueForSchema(value, schema);
        }
    }
};
</script>

<style lang="scss">
.pendo-table__title-note {
    font-size: 14px;
    font-style: italic;
}

.pendo-table__title-count {
    font-size: 18px;
}
</style>
