import { buildPollResponseCountSpec } from '@/aggregations/poll-responses';
import { PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/utils/aggregations';

export const PollResponseCounts = (pollId) => ({
    name: 'PollResponseCounts',
    productArea: PRODUCT_AREAS.GUIDES,
    subProductArea: SUB_PRODUCT_AREAS.POLL_RESULTS,
    responseFormat: 'rows',
    build: ({ appIds, timeSeries, guideId, segmentId }) =>
        buildPollResponseCountSpec({ appIds, timeSeries, guideId, pollId, segmentId })
});
