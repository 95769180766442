import get from 'lodash/get';

export function getUiElementType (uiElementId, type) {
    if (uiElementId.includes('pendo-close-guide-')) {
        return 'Close Button';
    }

    if (uiElementId.includes('pendo-list-item-')) {
        return 'Task Item';
    }

    return {
        button: 'Button',
        a: 'Link'
    }[type];
}

export function getUiElementText (dom) {
    const uiElementText = get(dom, 'content', false);
    if (uiElementText) return uiElementText;

    let concatenatedUiText = '';
    if (dom.children) {
        dom.children.forEach((child) => {
            const uiText = getUiElementText(child);
            if (uiText) {
                concatenatedUiText = concatenatedUiText.concat(uiText);
            }
        });
    }

    return concatenatedUiText;
}

export function getCurrentElementData (dom) {
    let elements = [];
    const isCorrectType = dom.type && (dom.type === 'button' || dom.type === 'a');
    const uiElementId = get(dom, 'props.id', false);
    if (isCorrectType && uiElementId && uiElementId !== 'pendo-a11y-list-item-button-block') {
        const uiElementText = getUiElementText(dom);
        const uiElementType = getUiElementType(uiElementId, dom.type);
        let uiElementActions = get(dom, 'actions');
        if (dom.type === 'a' && !uiElementActions) {
            uiElementActions = [{ action: 'openLink' }];
        }

        elements.push({ uiElementId, uiElementText, uiElementType, uiElementActions });
    }

    getCurrentTaskListItemElementData(elements, dom);

    if (dom.children) {
        dom.children.forEach((child) => {
            elements = elements.concat(getCurrentElementData(child));
        });
    }

    return elements;
}

export function getCurrentTaskListItemElementData (elements, dom) {
    const taskListTemplateChildren =
        (get(dom, 'templateName') === 'pendo_task_list' && get(dom, 'templateChildren')) || [];
    if (taskListTemplateChildren.length) {
        const taskListItemTemplateId = get(dom, 'children[0].children[0].props.id');
        taskListTemplateChildren.forEach((child) => {
            elements.push({
                uiElementId: `${taskListItemTemplateId}-${child.id}`,
                uiElementText: child.title,
                uiElementType: getUiElementType(taskListItemTemplateId),
                uiElementActions: [
                    {
                        action: 'showGuide',
                        guideId: child.id
                    }
                ]
            });
        });
    }
}

export function getElementActionColumnWidth (elementsActivity) {
    if (!elementsActivity) return undefined;

    const minWidth = Math.max(
        ...elementsActivity.map(({ uiElementActions }) => {
            const { length } = uiElementActions;
            if (length > 1) return length;

            const isAnyActionLaunchGuide = uiElementActions.some((action) => action.icon === 'guide');

            return isAnyActionLaunchGuide ? 2 : 1;
        })
    );

    // 172 = longest possible label text of `Go to Step (deleted)`
    return minWidth * 172;
}
