import { v4 as uuid } from 'uuid';
import { http } from '@pendo/http';

export async function createMedia (parentId, host, appId, category, file, isMobileApp, secure = false) {
    let ext = '';
    if (isMobileApp && file && file.data && file.data.type === 'image/gif') {
        ext = '.gif';
    }

    const mediaName = `${category}-media-${uuid()}${ext}`;
    const params = {
        parentId,
        bucketInHostname: true,
        private: secure,
        objectName: mediaName
    };

    const createMediaUrl = new URL(`${host}/api/s/_SID_/application/${appId}/media`);
    Object.keys(params).forEach((key) => createMediaUrl.searchParams.append(key, params[key]));
    const { data } = await http.post(createMediaUrl.toString(), {});
    const { formData, url, uploadUrl } = data;

    return {
        uploadUrl,
        downloadUrl: url,
        extraData: formData,
        mediaName
    };
}

export async function upload (file, parentId, host, appId, category, isMobileApp, secure = false) {
    if (file && parentId) {
        const { downloadUrl, uploadUrl, extraData, mediaName } = await createMedia(
            parentId,
            host,
            appId,
            category,
            file,
            isMobileApp,
            secure
        );
        const formData = new FormData();

        Object.keys(extraData).forEach((key) => formData.append(key, extraData[key]));
        formData.append('file', file.data);

        await http.post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: false
        });

        return secure ? mediaName : downloadUrl;
    }
}
