<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.89011 14.35C7.7926 14.7608 7.2078 14.7599 7.11148 14.3489L6.76452 12.8682C6.52803 11.8632 6.016 10.9441 5.28595 10.214C4.5559 9.484 3.63676 8.97196 2.63176 8.73546L1.15837 8.39021C0.746919 8.29379 0.746634 7.70821 1.15799 7.61139L2.63176 7.26454C3.63676 7.02804 4.5559 6.51601 5.28595 5.78596C6.016 5.05591 6.52803 4.13676 6.76452 3.13176L7.10979 1.65836C7.2062 1.24691 7.79179 1.24662 7.8886 1.65798L8.23546 3.13176C8.4693 4.13972 8.98016 5.06227 9.7104 5.79535C10.4406 6.52843 11.3612 7.04287 12.3682 7.28063L13.842 7.62749C14.2534 7.72431 14.2531 8.30989 13.8416 8.4063L12.3682 8.75156C11.3665 8.98784 10.4501 9.49793 9.72142 10.2249C8.99277 10.9518 8.48053 11.867 8.2419 12.8682L7.89011 14.35Z"
            fill="#7423E2" />
        <path
            d="M13.6953 10.825C13.6465 10.6196 13.3541 10.62 13.3059 10.8256L13.2242 11.1744C13.1355 11.5513 12.9435 11.896 12.6697 12.1697C12.396 12.4435 12.0513 12.6355 11.6744 12.7242L11.3292 12.8051C11.1235 12.8533 11.1233 13.1461 11.329 13.1945L11.6744 13.2758C12.0513 13.3645 12.396 13.5565 12.6697 13.8303C12.9435 14.104 13.1355 14.4487 13.2242 14.8256L13.3051 15.1708C13.3533 15.3765 13.6461 15.3767 13.6945 15.171L13.7758 14.8256C13.8635 14.4476 14.0551 14.1016 14.3289 13.8267C14.6027 13.5518 14.948 13.3589 15.3256 13.2698L15.671 13.1885C15.8767 13.1401 15.8765 12.8473 15.6708 12.7991L15.3256 12.7182C14.9499 12.6296 14.6063 12.4383 14.333 12.1657C14.0598 11.8931 13.8677 11.5499 13.7782 11.1744L13.6953 10.825Z"
            fill="#7423E2" />
    </svg>
</template>

<script>
export default {
    name: 'InsightsIcon',
    props: {
        width: {
            type: Number,
            default: 16
        },
        height: {
            type: Number,
            default: 16
        }
    }
};
</script>
