import { operators as o } from '@pendo/aggregations';
import { parseSegmentIdForAggregation, identifiedState, PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/utils/aggregations';
import uniq from 'lodash/uniq';
import { formatStartAndEndStepsForAgg } from './utils';

export const TimeToCompleteChartAgg = {
    name: 'WorkflowTimeToCompleteChart',
    ...getProductAreaAndSubArea(),
    responseFormat: 'rows',
    build: ({ workflow, activeSegmentId, dateRange }) => {
        const aggTimeSeries = {
            period: 'dayRange',
            first: `date("${dateRange.value.start}")`,
            last: `date("${dateRange.value.end}")`
        };
        const { workflowSteps, maxDuration } = workflow;
        const steps = formatStartAndEndStepsForAgg(workflowSteps);
        const appIds = uniq([steps.start.appId, steps.end.appId]);
        const isRecurring = workflow.classification === 'recurring';

        return o.pipeline(
            ...getBasePipeline({ appIds, aggTimeSeries, activeSegmentId, steps, maxDuration, isRecurring }),
            // Smallest increment used by FE is 15 minutes == 900,000 ms
            o.evaluate({ periodsToComplete: 'ceil((funnel.times[1] - funnel.times[0]) / 900000)' }),
            o.group('periodsToComplete', o.groupField('count', o.count(null)))
        );
    }
};

export const TimeToCompleteSummaryAgg = {
    name: 'WorkflowTimeToCompleteSummary',
    ...getProductAreaAndSubArea(),
    responseFormat: 'rows',
    build: ({ workflow, activeSegmentId, dateRange }) => {
        const aggTimeSeries = {
            period: 'dayRange',
            first: `date("${dateRange.value.start}")`,
            last: `date("${dateRange.value.end}")`
        };
        const { workflowSteps, maxDuration } = workflow;
        const steps = formatStartAndEndStepsForAgg(workflowSteps);
        const appIds = uniq([steps.start.appId, steps.end.appId]);
        const isRecurring = workflow.classification === 'recurring';

        return o.pipeline(
            ...getBasePipeline({ appIds, aggTimeSeries, activeSegmentId, steps, maxDuration, isRecurring }),
            o.evaluate({ timeToComplete: '(funnel.times[1] - funnel.times[0])' }),
            o.reduce({
                medianTimeToComplete: o.median('timeToComplete'),
                avgTimeToComplete: o.average('timeToComplete'),
                totalCompletions: o.count(null)
            })
        );
    }
};

function getBasePipeline ({ appIds, aggTimeSeries, activeSegmentId, steps, maxDuration, isRecurring }) {
    return [
        o.sources.singleEvents({ appId: appIds, timeSeries: aggTimeSeries }),
        o.identified(identifiedState(activeSegmentId)),
        o.segment(parseSegmentIdForAggregation(activeSegmentId)),
        o.group('visitorId', o.groupField('funnel', o.funnel([steps.start, steps.end], maxDuration, maxDuration))),
        o.fork(
            o.pipeline(
                o.unwind('funnel', { keepEmpty: true }),
                o.filter('funnel.steps == 2'),
                o.group('visitorId', o.groupField('latestCompletionStartTime', { max: 'funnel.start' }))
            ),
            o.pipeline(o.cat())
        ),
        o.join('visitorId'),
        o.unwind('funnel', { keepEmpty: false }),
        o.filter('funnel.steps == 2'),
        ...(!isRecurring ? [o.filter('funnel.start == latestCompletionStartTime')] : [])
    ];
}

function getProductAreaAndSubArea () {
    return {
        productArea: PRODUCT_AREAS?.ANALYTICS,
        subProductArea: SUB_PRODUCT_AREAS?.WORKFLOWS_JOURNEYS
    };
}
