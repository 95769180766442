<template>
    <div>
        <div class="pendo-modal__body ">
            <div class="content-validation">
                <div class="content-validation--guide-id">
                    <pendo-input
                        :value="guide.id"
                        :disabled="true"
                        label="Guide ID" />
                </div>
                <div class="content-validation--guide-hashes">
                    <pendo-input
                        v-model="contentHashJson"
                        :disabled="true"
                        label="Validation Content"
                        rows="8"
                        type="textarea" />
                </div>
            </div>
        </div>
        <div class="pendo-modal__footer">
            <pendo-button
                type="secondary"
                theme="app"
                label="Close"
                @click.stop="$modal.hide('via-modal')" />
        </div>
    </div>
</template>

<script>
import { PendoButton, PendoInput } from '@pendo/components';
import { getBuildingBlockGuideSignature } from '@/utils/guides';

export default {
    name: 'GuideContentValidation',
    components: {
        PendoButton,
        PendoInput
    },
    props: {
        guide: {
            type: Object,
            required: true
        }
    },
    computed: {
        contentHashJson () {
            return JSON.stringify(getBuildingBlockGuideSignature(this.guide));
        }
    }
};
</script>

<style lang="scss">
.content-validation {
    display: flex;
    flex-flow: column nowrap;

    &--guide-id {
        margin-bottom: 1em;
    }
}
</style>
