<template>
    <div class="pages-dashboard">
        <global-filters>
            <template #additional-filters>
                <div
                    data-cy="pages-dashboard--page-filter-dropdown"
                    class="pages-dashboard--header"
                    :class="{ 'pages-dashboard--header--with-sidebar': isPageDetailsSidebarOpen }">
                    <pendo-multiselect
                        v-model="pageFilterSelection"
                        :options="['All Pages', 'Custom Pages Only']" />
                </div>
            </template>
            <template #right>
                <div class="pages-dashboard--header--right-slot">
                    <search
                        v-model="searchInput"
                        :fullwidth="true"
                        placeholder="Search">
                        <template #suffix>
                            <pendo-icon
                                v-pendo-tooltip="{
                                    content: 'Search pages by keyword or paste a URL',
                                    arrow: true
                                }"
                                stroke="#6A6C74"
                                type="info"
                                size="14" />
                        </template>
                    </search>
                </div>
            </template>
        </global-filters>
        <suggested-page-tags-wrapper
            v-if="showSuggestedPageTags"
            :search-string="normalizedValue"
            class="pages-dashboard--suggested-page-tags" />
        <div class="pages-dashboard--grid">
            <div class="pages-dashboard--row">
                <pages-list
                    :page-filter-selection="pageFilterSelection"
                    :search-string="normalizedValue"
                    class="pages-dashboard--pages-list" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import PagesList from '@/components/analytics/pages/PagesList';
import Search from '@/components/Search';
import GlobalFilters from '@/components/filters/GlobalFilters';
import { PendoMultiselect, PendoIcon, PendoTooltip } from '@pendo/components';
import { normalizeValue } from '@/utils/pages';
import SuggestedPageTagsWrapper from './SuggestedPageTagsWrapper';

export default {
    name: 'PagesDashboard',
    components: {
        PagesList,
        Search,
        GlobalFilters,
        PendoMultiselect,
        PendoIcon,
        SuggestedPageTagsWrapper
    },
    directives: {
        PendoTooltip
    },
    data () {
        return {
            searchInput: '',
            pageFilterSelection: 'All Pages',
            normalizedValue: ''
        };
    },
    computed: {
        ...mapState({
            isPageDetailsSidebarOpen: (state) => state.pages.isPageDetailsSidebarOpen
        }),
        ...mapGetters({
            hasSegmentFlag: 'auth/hasSegmentFlag',
            appsWithSuggestedPages: 'apps/appsWithSuggestedPages',
            activeAppId: 'apps/activeId'
        }),
        showSuggestedPageTags () {
            return !!this.appsWithSuggestedPages.length;
        }
    },
    watch: {
        async searchInput (value) {
            this.normalizedValue = await normalizeValue(value);
        }
    },
    created () {
        this.fetchPages();
        this.fetchPagesWithAnalytics();
        this.fetchWorkflows();
        this.hydrateSuggestedPages();
    },
    methods: {
        ...mapActions({
            fetchPages: 'pages/fetch',
            fetchPagesWithAnalytics: 'pages/fetchWithAnalytics',
            hydrateSuggestedPages: 'pages/hydrate',
            fetchWorkflows: 'workflows/fetch'
        })
    }
};
</script>

<style lang="scss">
.pages-dashboard {
    .pages-dashboard--suggested-page-tags {
        margin-bottom: 1em;
        display: block;
    }

    .pages-dashboard--header {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        font-size: 0.875em;
    }

    .pages-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .pages-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
        min-height: 440px;
    }
}
</style>
