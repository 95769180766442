<template>
    <pendo-modal
        height="auto"
        :visible="visible"
        title="Delete workflow?"
        :message="message"
        @close="onModalClose">
        <template #footer>
            <pendo-button
                :disabled="isRemoving"
                theme="app"
                type="secondary"
                label="Cancel"
                @click="onModalClose" />
            <pendo-button
                :loading="isRemoving"
                :disabled="isRemoving"
                theme="app"
                type="danger"
                label="Delete workflow"
                @click="onWorkflowDelete" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoButton, PendoModal } from '@pendo/components';
import { mapState } from 'vuex';

const DELETE_WORKFLOW_EVENT_NAME = 'deleteWorkflow';
const MODAL_CLOSE_EVENT_NAME = 'modalClose';

export default {
    name: 'WorkflowsDeleteModal',
    components: {
        PendoButton,
        PendoModal
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState({
            isRemoving: (state) => state.workflows.isRemoving
        }),
        message () {
            return `“${this.name}” will no longer be accessible and can't be recovered. This action can't be undone.`;
        }
    },
    methods: {
        onModalClose () {
            this.$emit(MODAL_CLOSE_EVENT_NAME);
        },
        onWorkflowDelete () {
            this.$emit(DELETE_WORKFLOW_EVENT_NAME);
        }
    }
};
</script>

<style lang="scss" scoped></style>
