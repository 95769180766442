export function getPollResponseId (pollResponse, guideId, pollId) {
    return `${guideId}__${pollId}__${pollResponse.visitorId}__${pollResponse.browserTime}`;
}

export function hasResponseAlreadyBecomeFeedbackItem (pollResponseId, alreadyLinkedResponses) {
    return !!alreadyLinkedResponses.get(pollResponseId);
}

export function isSendToFeedbackInProgress (pollResponseId, getInitFeedbackInProgress, creatingFeedbackInProgress) {
    if (getInitFeedbackInProgress) {
        return true;
    }

    return creatingFeedbackInProgress.includes(pollResponseId);
}
