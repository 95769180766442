<template>
    <main class="metadata-list">
        <pendo-table
            v-pendo-loading:feather="loading"
            :data="data"
            :columns="columns"
            :max-height="10000"
            :title="title"
            row-key="name"
            class="metadata-list--table">
            <template
                v-if="canEdit"
                #value="{ row }">
                <pendo-editable-content
                    v-if="isSchemaFloatOrString(row.schema)"
                    class="editable-input"
                    allow-empty
                    :value="convertValueToString(row.value)"
                    :validation-rules="rules[row.schema]"
                    empty-text="Click to Edit"
                    @confirm="updateCustomValue($event, row.field)">
                    <template #append="{ pending, confirm, cancel }">
                        <pendo-button
                            theme="app"
                            icon="check"
                            data-cy="confirm"
                            :loading="pending"
                            type="primary"
                            @click="confirm" />
                        <pendo-button
                            data-cy="cancel"
                            theme="app"
                            icon="x"
                            type="secondary"
                            @click="cancel" />
                    </template>
                </pendo-editable-content>
                <editable-number-input
                    v-if="row.schema === 'integer'"
                    :value="row.value"
                    @confirm="updateCustomValue($event, row.field)" />
                <pendo-checkbox
                    v-if="row.schema === 'boolean'"
                    v-model="row.value"
                    @change="updateCustomValue($event, row.field)" />
                <pendo-date-picker
                    v-if="row.schema === 'time'"
                    v-model="row.value"
                    @select-date="updateCustomValue($event, row.field)" />
                <div
                    v-if="!row.schema"
                    class="metadata-table-cell--text">
                    {{ row.value }}
                    <pendo-icon
                        v-pendo-tooltip="{
                            arrow: true,
                            content: metadataNoTypeHelpText
                        }"
                        fill="#dba211"
                        size="18"
                        stroke="#fff"
                        type="alert-circle" />
                </div>
            </template>
            <template
                v-else-if="isAdmin"
                #value="{ row }">
                <pendo-app-display
                    v-if="row.field === 'lastVisitAppId'"
                    :apps="appById(row.value)" />
                <div
                    v-else
                    class="metadata-table-cell--text">
                    {{ formatRowValue(row) }}
                    <pendo-icon
                        v-if="!row.schema"
                        v-pendo-tooltip="{
                            arrow: true,
                            content: metadataNoTypeHelpText
                        }"
                        fill="#dba211"
                        size="18"
                        stroke="#fff"
                        type="alert-circle" />
                </div>
            </template>
            <template #empty>
                <div class="metadata-list--table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        No data found.
                    </span>
                </div>
            </template>
        </pendo-table>
    </main>
</template>

<script>
import {
    PendoIcon,
    PendoTable,
    PendoLoading,
    PendoEditableContent,
    PendoButton,
    PendoDatePicker,
    PendoCheckbox,
    PendoTooltip,
    PendoAppDisplay
} from '@pendo/components';
import { formatDataByType } from '@/utils/table-formatters';
import { updateVisitorMetadata } from '@/utils/metadata';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { mapGetters } from 'vuex';
import EditableNumberInput from './EditableNumberInput';

export default {
    name: 'MetadataTable',
    components: {
        PendoIcon,
        PendoTable,
        PendoEditableContent,
        PendoButton,
        PendoDatePicker,
        PendoCheckbox,
        EditableNumberInput,
        PendoAppDisplay
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        title: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            default: () => []
        },
        editable: {
            type: Boolean,
            default: false
        },
        visitorId: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            columns: [
                { prop: 'name', label: 'Name' },
                {
                    prop: 'value',
                    label: 'Value',
                    formatter: this.formatRowValue
                }
            ],
            rules: {
                number: [{ pattern: /^-?\d*\.?\d*$/, message: 'Please input a valid number', trigger: ['blur'] }],
                string: [{ type: 'string', message: 'Please input a valid string', trigger: ['blur'] }],
                float: [{ pattern: /^-?\d*\.?\d*$/, message: 'Please input a valid float', trigger: ['blur'] }]
            },
            metadataNoTypeHelpText: 'Metadata type is not set. Visit Metadata Settings to configure.'
        };
    },
    computed: {
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            appById: 'apps/appById',
            getActiveTimezone: 'subscriptions/getTimezone'
        }),
        canEdit () {
            return this.editable && this.isAdmin;
        }
    },
    methods: {
        async updateCustomValue (rawInput, fieldName) {
            // rawInput will be an object format from date picker
            const input = get(rawInput, 'value', rawInput);
            const valueIsString = typeof input === 'string';
            // we want to trim the value for metadata strings
            const trimmedInput = valueIsString ? input.trim() : input;
            const shouldConvertToNull = trimmedInput === undefined || isNaN(trimmedInput) || trimmedInput === '';
            const value = shouldConvertToNull ? null : trimmedInput;

            try {
                await updateVisitorMetadata(this.visitorId, fieldName, value);
            } catch (err) {
                throw err;
            }
        },
        convertValueToString (value) {
            const shouldConvertToString =
                value !== null && value !== undefined && !isNaN(value) && typeof value !== 'string';

            return shouldConvertToString ? value.toString().trim() : value;
        },
        isSchemaFloatOrString (schema) {
            return schema === 'float' || schema === 'number' || schema === 'string';
        },
        formatRowValue (row) {
            return formatDataByType(row.schema, row.value, this.getActiveTimezone);
        }
    }
};
</script>

<style lang="scss">
.metadata-list {
    &--table {
        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }

        .pendo-table__cell {
            .editable-input.is-editing {
                .is-error {
                    height: 60px;
                    margin-top: 10px;
                }
            }

            .metadata-table-cell--text {
                display: flex;
                flex-direction: horizontal;
                align-items: center;
                justify-content: left;
                gap: 4px;
            }
        }
    }
}
</style>
