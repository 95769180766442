import { $can } from '@pendo/permissions';

export const canAppCreateCountable = (appId, countableKind) =>
    $can(appId)
        .entity(countableKind)
        .action('create');

export const canAppEditCountable = (appId, countableKind) =>
    $can(appId)
        .entity(countableKind)
        .action('edit');

export const canAppRemoveCountable = (appId, countableKind) =>
    $can(appId)
        .entity(countableKind)
        .action('remove');

export function canCreateCountable ({ apps, countableKind }) {
    return apps.some((app) => canAppCreateCountable(app.id, countableKind));
}
