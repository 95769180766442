export function maxMenuHeightForMultiselect (type) {
    switch (type) {
        case 'page':
        case 'trackEvent':
            return 292;
        case 'feature':
            return 316;
        default:
            return 302; // default max menu height for pendo-multiselect
    }
}

export function maxMenuWidthForMultiselect (showHoverCard) {
    // 400px is default max menu width for pendo-multiselect
    // 720px is 400px + width of hover card (320px)
    return showHoverCard ? 720 : 400;
}
