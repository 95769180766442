import Vue from 'vue';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { http } from '@pendo/http';
import { generateAppUid } from '@/utils/apps';
import {
    reduceUserSettingsForAppAndSub,
    parseDateRangeUserSetting,
    parseAppIdsFilter,
    parseViewByMetadataField,
    parseValue
} from '@/utils/user-settings';
import { ALL_APPS_AGG_ID } from '@/constants/apps';

export function getInitialState () {
    return {
        error: null,
        userPreferences: {},
        allUserSettingsMap: {}
    };
}

export const state = getInitialState();

export const mutations = {
    setAllUserSettingsMap (state, { allUserSettingsMap }) {
        state.allUserSettingsMap = allUserSettingsMap;
    },
    setUserSetting (state, { userSetting }) {
        Vue.set(state.allUserSettingsMap, `${userSetting.name}`, Object.freeze(userSetting));
    },
    setUserPreferences (state, { userPreferences }) {
        state.userPreferences = userPreferences;
    },
    setError (state, { error }) {
        state.error = error;
    }
};

export const actions = {
    async fetch ({ dispatch, rootState, commit }, { noCache = false } = {}) {
        // dispatched from `apps/updateActive` which requires the sub id to be set
        if (!get(rootState, 'subscriptions.activeId', undefined)) {
            return;
        }

        if (!isEmpty(state.allUserSettingsMap) && !noCache) {
            return;
        }

        const { data: settings } = await http.get('/api/s/_SID_/user/_UID_/setting');
        const { activeAppUid } = rootState.apps;
        const subscriptionId = String(rootState.subscriptions.activeId);

        const userSettingsMap = settings.reduce((acc, setting) => {
            acc[setting.name] = Object.freeze(setting);

            return acc;
        }, {});
        commit('setAllUserSettingsMap', { allUserSettingsMap: userSettingsMap });

        const userSettings = reduceUserSettingsForAppAndSub(activeAppUid, subscriptionId, settings);
        const userPreferences = settings.find((setting) => setting.name === `${subscriptionId}::userPreferences`);
        userSettings.userPreferences = userPreferences ? userPreferences.value : null;
        await dispatch('hydrate', { userSettings });
    },
    async updateUserSettingByName ({ dispatch, rootState, rootGetters }, { settingsName, value }) {
        const namespaceKey = generateSubscriptionTypeNamespacedKey(rootState, rootGetters, settingsName);

        await dispatch('updateUserSetting', { namespaceKey, value });
    },
    async updateAppNamespaceSetting ({ dispatch, rootState, rootGetters }, { name, value, appId }) {
        let appUid = rootState.apps.activeAppUid;

        if (rootGetters['subscriptions/usesMultiApp'] && appId) {
            appUid = generateAppUid(rootState.subscriptions.activeId, appId);
        }

        const namespaceKey = `${appUid}--${name}`;

        await dispatch('updateUserSetting', { namespaceKey, value });
    },
    async updateSubNamespaceSetting ({ dispatch, rootState }, { name, value }) {
        const subscriptionId = rootState.subscriptions.activeId;
        const namespaceKey = `${subscriptionId}--${name}`;

        await dispatch('updateUserSetting', { namespaceKey, value });
    },
    async updateCrossUINamespaceSetting ({ dispatch, rootState }, { name, value }) {
        const subscriptionId = rootState.subscriptions.activeId;
        const namespaceKey = `${subscriptionId}::${name}`;
        await dispatch('updateUserSetting', { namespaceKey, value });
    },
    async updateUserSetting ({ commit, getters }, { namespaceKey, value }) {
        let formattedValue = value;

        if (typeof value === 'object') {
            formattedValue = JSON.stringify(value);
        }

        const userSetting = {
            userId: '{{uid}}',
            name: namespaceKey,
            value: formattedValue.toString()
        };

        const existingValue = getters.getUserSetting(namespaceKey);
        if (isEqual(existingValue, userSetting)) return;

        await http.put(`/api/s/_SID_/user/_UID_/setting/${namespaceKey}`, userSetting);
        commit('setUserSetting', { userSetting });
    },
    async hydrate ({ commit, dispatch, rootGetters }, { userSettings }) {
        const {
            activeSegmentId,
            appUsageListUserSettings,
            hideVisitorsNoEvents,
            lastDesignerUrl,
            workflowsSettings,
            guideActivityTableUserSettings,
            numDaysBeforeLast,
            dateRange,
            appIdsFilter,
            viewByMetadataField,
            isViewingApplicationsByMetadata,
            pageUsageChart,
            pathsListCreatedByFilter,
            guidesListUserSettings,
            applicationSettings,
            licenseUtilizationUserSettings,
            userPreferences
        } = userSettings;
        const designerLastUrlsByApp = Object.keys(applicationSettings).reduce((acc, appId) => {
            acc[appId] = applicationSettings[appId].lastDesignerUrl;

            return acc;
        }, {});

        commit(
            'filters/setDateRange',
            { dateRange: parseDateRangeUserSetting({ numDaysBeforeLast, dateRange }, rootGetters['apps/firstVisit']) },
            { root: true }
        );
        commit('filters/setHideVisitorsNoEvents', { value: hideVisitorsNoEvents === 'true' }, { root: true });
        const currentApps = rootGetters['apps/listAllForActiveSubscription'];
        const allAppsOrUndefined = !appIdsFilter || JSON.parse(appIdsFilter)[0] === ALL_APPS_AGG_ID;
        if (allAppsOrUndefined && currentApps.length === 1) {
            commit('filters/setAppIdsFilter', { appIds: [currentApps[0].id] }, { root: true });
        } else {
            commit('filters/setAppIdsFilter', { appIds: parseAppIdsFilter(appIdsFilter) }, { root: true });
        }

        commit(
            'filters/setViewByMetadataField',
            { metadata: parseViewByMetadataField(viewByMetadataField) },
            { root: true }
        );
        commit(
            'filters/setPivotDataToggle',
            { isViewingApplicationsByMetadata: isViewingApplicationsByMetadata === 'true' },
            { root: true }
        );
        commit('designer/setDesignerUrl', lastDesignerUrl || '', { root: true });
        commit('designer/setLastAppUrlMap', designerLastUrlsByApp, { root: true });
        commit(
            'workflows/setWorkflowsSettings',
            { workflowsSettings: JSON.parse(workflowsSettings || '{}') },
            { root: true }
        );
        commit(
            'guideAnalytics/setGuideActivityTableUserSettings',
            { guideActivityTableUserSettings: JSON.parse(guideActivityTableUserSettings || '{}') },
            { root: true }
        );
        commit('analytics/setPageUsageChart', { pageUsageChart: JSON.parse(pageUsageChart || 'null') }, { root: true });
        commit(
            'reports/setPathsListCreatedByFilter',
            { pathsListCreatedByFilter: pathsListCreatedByFilter || 'anyone' },
            { root: true }
        );
        commit(
            'portfolio/setAppUsageListUserSettings',
            { appUsageListUserSettings: JSON.parse(appUsageListUserSettings || 'null') },
            { root: true }
        );
        commit(
            'guides/setGuidesListUserSettings',
            { guidesListUserSettings: JSON.parse(guidesListUserSettings || 'null') },
            { root: true }
        );
        commit(
            'licenses/setLicenseUtilizationUserSettings',
            { licenseUtilizationUserSettings: JSON.parse(licenseUtilizationUserSettings || 'null') },
            { root: true }
        );
        const canCreatePages = rootGetters['pages/canCreatePage'];
        const isAdmin = rootGetters['auth/isAdmin'];
        const userHasSubsWithSuggestedPages =
            (canCreatePages || isAdmin) && rootGetters['apps/appsWithSuggestedPages'].length > 0;
        const hasSegmentFlag = rootGetters['auth/hasSegmentFlag'];
        const hasFeatureFlag = rootGetters['subscriptions/activeSubHasFlag'];
        const isAEU = rootGetters['subscriptions/activeIsTrainingSubscription'];
        const hasTagInASeparateBrowserWindowSetting =
            (canCreatePages || isAdmin) &&
            !isAEU &&
            hasSegmentFlag('designerSidebarMode') &&
            hasFeatureFlag('enableP2Access');

        const defaultUserPreferences = {
            ...(hasTagInASeparateBrowserWindowSetting && {
                tagInASeparateBrowserWindow: {
                    value: true,
                    disabled: false,
                    displayName: 'Tag in a separate browser window'
                }
            }),
            ...(userHasSubsWithSuggestedPages && {
                suggestedPagesTable: {
                    value: true,
                    disabled: false,
                    subSettings: {
                        notifications: {
                            value: true,
                            disabled: false
                        }
                    }
                }
            })
        };
        const savedUserPreferences = userPreferences ? JSON.parse(userPreferences) : null;
        if (savedUserPreferences) {
            if (!userHasSubsWithSuggestedPages) {
                delete savedUserPreferences.suggestedPagesTable;
            }
            if (!hasTagInASeparateBrowserWindowSetting) {
                delete savedUserPreferences.tagInASeparateBrowserWindow;
            }
        }
        commit('setUserPreferences', { userPreferences: { ...defaultUserPreferences, ...savedUserPreferences } });
        dispatch(
            'filters/updateShowAnonymous',
            { canShowAnonOptions: hasFeatureFlag('showAnonymous') },
            { root: true }
        );
        dispatch('filters/addAnonymousDefaultSegments', null, { root: true });
        await dispatch('filters/updateActiveSegmentId', { id: activeSegmentId || 'everyone' }, { root: true });
    },
    updateUserPreferences ({ commit }, { userPreferences }) {
        commit('setUserPreferences', { userPreferences });
    }
};

export const getters = {
    getUserSetting: (state) => (userSettingKey) => {
        return state.allUserSettingsMap[userSettingKey];
    },
    getTableUserSettingByName: (state, getters, rootState, rootGetters) => (tableSettingName) => {
        // Digital adoption is sub-wide, vs reseller which is app specific
        const userSettingKey = generateSubscriptionTypeNamespacedKey(rootState, rootGetters, tableSettingName);

        return getters.getUserSetting(userSettingKey);
    },
    getTableUserSettingValueByName: (state, getters) => (tableSettingName) => {
        const setting = getters.getTableUserSettingByName(tableSettingName);
        if (!setting) return null;

        return parseValue(setting);
    },
    getCrossUISettingByName: (state, getters, rootState) => (name) => {
        const settingName = `${rootState.subscriptions.activeId}::${name}`;
        const setting = getters.getUserSetting(settingName);

        if (!setting) {
            return null;
        }

        return {
            ...setting,
            value: parseValue(setting)
        };
    },
    getUserPreferences: (state) => {
        return state.userPreferences;
    }
};

function generateSubscriptionTypeNamespacedKey (rootState, rootGetters, settingName) {
    const settingIsAppSpecific = !rootGetters['subscriptions/usesMultiApp'];
    const subId = rootState.subscriptions.activeId;
    const appId = rootGetters['apps/activeId'];

    const namespace = settingIsAppSpecific ? `${subId}:${appId}` : `${subId}`;
    const userSettingKey = `${namespace}--${settingName}`;

    return userSettingKey;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
