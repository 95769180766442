<template>
    <div
        v-if="app"
        class="view--settings">
        <app-settings-header :app="app" />
        <app-settings-content
            :app="app"
            :subscription="activeSub" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import AppSettingsHeader from '@/components/settings/app-settings/AppSettingsHeader';
import AppSettingsContent from '@/components/settings/app-settings/AppSettingsContent';
import { generateAppUid } from '@/utils/apps';

export default {
    name: 'AppSettingsView',
    components: {
        AppSettingsHeader,
        AppSettingsContent
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            activeSub: 'subscriptions/active',
            appList: 'apps/listAll'
        }),
        appId () {
            return generateAppUid(this.activeSub.id, this.route.params.id);
        },
        activeSubAppList () {
            return this.appList.filter((app) => {
                return app.subscriptionId === this.activeSub.id;
            });
        },
        activeSubAppMap () {
            return keyBy(this.activeSubAppList, 'uid');
        },
        app () {
            return get(this.activeSubAppMap, this.appId, null);
        }
    },
    created () {
        if (!this.app || !this.app.id) {
            // if app ID is not recognized
            this.$router.push({ name: 'applications' }).catch(() => {});
        }
    }
};
</script>
