<template>
    <div class="visitor-side-panel">
        <pendo-table
            v-pendo-loading:feather="loading"
            class="visitor-table"
            title="Visitors"
            row-key="visitorId"
            :columns="columns"
            :data="visitors"
            manage-columns
            resizable
            :default-sort="{ prop: 'visitorId', order: 'ascending' }"
            auto-height
            @column-change="changeColumns">
            <template #visitorId="{ row }">
                <pendo-button
                    theme="app"
                    type="link"
                    :label="row.visitorId"
                    @click="$emit('visitor-link-click', row.visitorId)" />
            </template>
        </pendo-table>
        <div class="download-button">
            <pendo-button
                theme="app"
                prefix-icon="download"
                type="secondary"
                label="Download CSV"
                @click="downloadCsv" />
        </div>
        <pendo-csv-modal
            :visible="showCsvModal"
            :status="csvModalStatus"
            :url="csvUrl"
            @close="showHideCsvModal" />
    </div>
</template>

<script>
import { PendoTable, PendoLoading, PendoNotification, PendoButton, PendoCsvModal } from '@pendo/components';
import { formatTime } from '@/utils/license-utilization';
import capitalize from 'lodash/capitalize';

export default {
    name: 'VisitorSidePanel',
    components: {
        PendoTable,
        PendoButton,
        PendoCsvModal
    },
    directives: {
        PendoLoading,
        PendoNotification
    },
    props: {
        metadataField: {
            type: [String, Object],
            default: ''
        },
        metadataFields: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        showCsvModal: {
            type: Boolean,
            default: false
        },
        csvModalStatus: {
            type: String,
            default: 'resolved'
        },
        csvUrl: {
            type: String,
            default: ''
        },
        visitors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            csvColumns: []
        };
    },
    computed: {
        csvColumnsDefault () {
            const defaultColumns = [
                {
                    prop: 'visitorId',
                    label: 'Visitor ID'
                },
                {
                    prop: 'lastVisit',
                    label: 'Last Visit'
                },
                {
                    prop: 'avgTime',
                    label: 'Avg Time'
                },
                {
                    prop: 'daysActive',
                    label: 'Days Active'
                }
            ];

            if (this.metadataField) {
                // Add metadata field that was selected from table and set to visible as column 2
                defaultColumns.splice(1, 0, {
                    prop: this.metadataField.field,
                    label: this.metadataField.displayName
                });
            }

            return defaultColumns;
        },
        columns () {
            const columns = [
                {
                    prop: 'visitorId',
                    label: 'Visitor ID',
                    sortable: true,
                    visible: true,
                    fixed: true,
                    minWidth: 240
                },
                {
                    prop: 'lastVisit',
                    label: 'Last Visit',
                    sortable: true,
                    visible: true,
                    formatter: (row) => this.formatDate(row.lastVisit)
                },
                {
                    prop: 'avgTime',
                    label: 'Avg Time',
                    sortable: true,
                    visible: true,
                    formatter: (row) => formatTime(Math.round(row.avgTime)),
                    width: 105
                },
                {
                    prop: 'daysActive',
                    label: 'Days Active',
                    sortable: true,
                    visible: true,
                    width: 115
                }
            ];
            if (this.metadataField) {
                // Add metadata field that was selected from table and set to visible as column 2
                columns.splice(1, 0, {
                    prop: this.metadataField.field,
                    label: this.metadataField.displayName,
                    sortable: true,
                    visible: true,
                    minWidth: 155
                });
            }

            for (const metadataField of this.metadataFields) {
                // Add all the other metadata fields to be added via manage columns
                columns.push({
                    prop: metadataField.field,
                    label: capitalize(metadataField.DisplayName),
                    sortable: true,
                    visible: false,
                    minWidth: 155
                });
            }

            return columns;
        }
    },
    created () {
        this.$emit('refresh-visitors');
        this.csvColumns = this.defaultCsvColumns();
    },
    methods: {
        async downloadCsv () {
            this.$emit('download-csv', this.csvColumns);
        },
        defaultCsvColumns () {
            const defaultColumns = [
                {
                    prop: 'visitorId',
                    label: 'Visitor ID'
                },
                {
                    prop: 'lastVisit',
                    label: 'Last Visit'
                },
                {
                    prop: 'avgTime',
                    label: 'Avg Time'
                },
                {
                    prop: 'daysActive',
                    label: 'Days Active'
                }
            ];

            if (this.metadataField) {
                defaultColumns.splice(1, 0, {
                    prop: this.metadataField.field,
                    label: this.metadataField.displayName
                });
            }

            return defaultColumns;
        },
        changeColumns ({ columns }) {
            const newColumns = [];
            for (const column of columns) {
                const foundColumn = this.columns.find((col) => col.prop === column);
                if (foundColumn) {
                    newColumns.push(foundColumn);
                }
            }
            this.csvColumns = newColumns;
        },
        showHideCsvModal () {
            this.$emit('show-hide-csv-modal', !this.showCsvModal);
        },
        formatDate (value) {
            return this.$pendo.moment(value).format('MMM D, YYYY');
        }
    }
};
</script>

<style lang="scss">
.visitor-side-panel {
    .visitor-table {
        margin-top: 30px;
    }

    .download-button {
        margin: 20px;
    }
}
</style>
