import store from '@/state/store';
import { map, share } from 'rxjs/operators';
import { LocalStorageDataStream, messaging } from '@pendo/services/Communication';

const { messageChannels, messageType } = messaging;

export function startDesignerBridge () {
    const localStorageDataStream = new LocalStorageDataStream('localStorage', 'pendo', false);
    localStorageDataStream.listen();
    const appEngineMessage$ = localStorageDataStream.getMessagesForChannel$(messageChannels.APPENGINE).pipe(
        map((envelope) => envelope.message),
        share()
    );

    return appEngineMessage$.subscribe((message) => {
        if (message.type === messageType.RELOAD_GUIDE_DETAILS) {
            store.dispatch('guides/refreshGuide', { id: message.guideId });
        }
    });
}
