<template>
    <div class="app-color-picker">
        <pendo-color-picker
            v-model="selectedColor"
            :labels="{ top: 'Assigned Color' }"
            value-key="hex"
            :presets="swatchColors" />
    </div>
</template>

<script>
import { PendoColorPicker } from '@pendo/components';

export default {
    name: 'AppColorPicker',
    components: {
        PendoColorPicker
    },
    props: {
        initialColor: {
            type: String,
            default: ''
        },
        swatchColors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            selectedColor: '#000'
        };
    },
    watch: {
        selectedColor (color) {
            this.$emit('favicon-color-changed', color !== '#000' ? color : null);
        },
        initialColor: {
            handler (newColor) {
                this.selectedColor = newColor || '#000';
            },
            immediate: true
        }
    }
};
</script>
