import { buildPollResponseListSpec } from '@/aggregations/poll-responses';
import { PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/utils/aggregations';

export const PollResponseList = ({ pollId, pollResponseMap }) => ({
    name: 'PollResponseList',
    productArea: PRODUCT_AREAS.GUIDES,
    subProductArea: SUB_PRODUCT_AREAS.POLL_RESULTS,
    responseFormat: 'rows',
    build: ({ appIds, timeSeries, guideId, segmentId }) =>
        buildPollResponseListSpec({ appIds, timeSeries, guideId, pollId, segmentId, pollResponseMap })
});
