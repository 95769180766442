<template>
    <div class="favicon-color-chooser">
        <p class="favicon-color-chooser__section-title">
            Logo and Color
        </p>
        <div class="favicon-color-chooser__row">
            <div class="favicon-color-chooser__favicon-container">
                <pendo-icon
                    v-if="!faviconB64"
                    class="favicon-color-chooser__favicon"
                    type="puzzle-piece"
                    :fill="color"
                    stroke="#000000" />
                <img
                    v-else
                    class="favicon-color-chooser__favicon"
                    :src="faviconB64"
                    alt="favicon">
            </div>
            <div class="favicon-color-chooser__favicon-picker">
                <pendo-input
                    v-model="faviconDomain"
                    class="pendo-add-update-extension-app-modal__favicon-domain"
                    label="Favicon URL"
                    width="medium"
                    :invalid="!isValidDomain(faviconDomain)"
                    @blur="onFaviconChange" />
            </div>
            <app-color-picker
                class="favicon-color-chooser__color-picker"
                :initial-color="color"
                :swatch-colors="swatchColors"
                @favicon-color-changed="onColorChange" />
        </div>
    </div>
</template>
<script>
import { PendoIcon, PendoInput } from '@pendo/components';
import { getFavicon } from '@/utils/favicon';
import AppColorPicker from './AppColorPicker.vue';
import Vibrant from 'node-vibrant';
import uniq from 'lodash/uniq';
import get from 'lodash/get';

export default {
    name: 'FaviconColorChooser',
    components: {
        AppColorPicker,
        PendoIcon,
        PendoInput
    },
    props: {
        faviconUrl: {
            type: String,
            default: null
        },
        app: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            color: '',
            faviconB64: '',
            swatchColors: [],
            faviconDomain: ''
        };
    },
    computed: {
        disableFaviconUrlProp () {
            return this.faviconDomain !== '';
        }
    },
    watch: {
        faviconUrl (newUrl) {
            if (this.disableFaviconUrlProp) {
                return; // do nothing
            }
            this.faviconDomain = newUrl;
            this.onFaviconChange(newUrl);
        },
        color (newColor) {
            this.$emit('favicon-updated', this.faviconB64, newColor);
        }
    },
    async created () {
        if (this.app) {
            this.faviconB64 = get(this.app, 'faviconB64', '');
            this.color = get(this.app, 'color', '');
        }
    },
    methods: {
        async getColors (favicon) {
            if (favicon) {
                await Vibrant.from(favicon).getPalette((err, palette) => {
                    if (palette.Vibrant) {
                        this.color = palette.Vibrant.hex;
                        this.swatchColors = uniq([
                            palette.Vibrant.hex,
                            palette.DarkMuted.hex,
                            palette.DarkVibrant.hex,
                            palette.LightMuted.hex,
                            palette.LightVibrant.hex,
                            palette.Muted.hex
                        ]);
                    }
                });
            } else {
                this.color = '';
                this.swatchColors = [];
            }
        },
        onColorChange (newColor) {
            this.color = newColor;
        },
        async onFaviconChange () {
            if (this.isValidDomain(this.faviconDomain)) {
                this.faviconB64 = await getFavicon(this.faviconDomain);
                await this.getColors(this.faviconB64);
                this.$emit('favicon-updated', this.faviconB64, this.color);
            }
        },
        isValidDomain (domain) {
            if (domain === '') {
                return true;
            }

            // Valid domain must contain only letters, numbers, and hyphens, and must have at least 1 period before a tld.
            // Valid domain may have any number of sequential hyphens, but may not start or end with a hyphen.
            // IP addresses and ports are not supported. Wildcards are not supported.
            const validDomainRegex = new RegExp(
                '^(https?://)?[a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,4}([-a-zA-Z0-9@:%_+.~#?&//=]*)$',
                'gm'
            );

            // Some companies set up dumb intranet hostnames without top-level-domains (TLD).
            // For instance, this might look like: http://top-level/some-app
            // This is absolutely not a valid internet hostname, but DNS can be set up to resolve these so...we gotta support 'em
            const intranetHostnameRegex = new RegExp('^(https?//)?([a-z0-9]+(-+[a-z0-9]+)*)$');

            const test = validDomainRegex.test(domain) || intranetHostnameRegex.test(domain);

            return test;
        }
    }
};
</script>
<style lang="scss" scoped>
.favicon-color-chooser {
    &__favicon-container {
        margin-right: 10px;
        margin-bottom: 5px;
    }

    &__favicon {
        width: 34px;
    }

    &__row {
        display: flex;
        align-items: flex-end;
    }

    &__section-title {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 15px;
    }

    &__color-picker {
        margin-left: 8px;
    }
}
</style>
