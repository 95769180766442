<template>
    <div class="view--path-details">
        <path-details-content :id="id" />
    </div>
</template>

<script>
import PathDetailsContent from '@/components/paths/PathDetailsContent';

export default {
    name: 'PathDetailsView',
    components: {
        PathDetailsContent
    },
    props: {
        id: {
            type: String,
            default: null
        }
    }
};
</script>
