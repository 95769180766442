import Vue from 'vue';
import { http } from '@pendo/http';
import keyBy from 'lodash/keyBy';

const state = {
    licenseCategories: [],
    licenseDataMap: {},
    isFetchingLicenseDataMap: false,
    visitorMetadataField: '',
    licenseUtilizationUserSettings: null,
    activeSubHasAnyLicenses: null
};
const mutations = {
    setIsFetchingLicenseDataMap (state, { isFetchingLicenseDataMap }) {
        state.isFetchingLicenseDataMap = isFetchingLicenseDataMap;
    },
    setLicenseCategories (state, { licenseCategories }) {
        state.licenseCategories = licenseCategories;
    },
    setLicenseDataMap (state, { licenseDataMap }) {
        state.licenseDataMap = licenseDataMap;
    },
    setUpdatedLicense (state, { license }) {
        Vue.set(state.licenseDataMap, license.appId, license);
    },
    setVisitorMetadataField (state, { field }) {
        state.visitorMetadataField = field;
    },
    setLicenseUtilizationUserSettings (state, { licenseUtilizationUserSettings }) {
        state.licenseUtilizationUserSettings = licenseUtilizationUserSettings;
    },
    setActiveSubHasAnyLicenses (state, { activeSubHasAnyLicenses }) {
        state.activeSubHasAnyLicenses = activeSubHasAnyLicenses;
    }
};
const actions = {
    async loadLicenseDataMap ({ commit }) {
        commit('setIsFetchingLicenseDataMap', { isFetchingLicenseDataMap: true });
        const { data: licenseCategories } = await http.get('/api/s/_SID_/licenseinformation/validappcategories');
        const { data: licenses } = await http.get('/api/s/_SID_/licenseinformation');
        commit('setLicenseCategories', { licenseCategories });
        commit('setLicenseDataMap', { licenseDataMap: keyBy(licenses, 'appId') });
        const hasAppsWithLicenseData =
            Object.values(state.licenseDataMap).filter((app) => app.quantity !== null).length > 0;
        commit('setActiveSubHasAnyLicenses', { activeSubHasAnyLicenses: hasAppsWithLicenseData });
        commit('setIsFetchingLicenseDataMap', { isFetchingLicenseDataMap: false });
    },
    async createLicense ({ commit }, { license }) {
        const { data } = await http.post('/api/s/_SID_/licenseinformation', license);
        commit('setUpdatedLicense', { license: data });
    },
    async updateLicense ({ commit }, { license }) {
        const { data } = await http.put(`/api/s/_SID_/licenseinformation/${license.id}`, license);
        commit('setUpdatedLicense', { license: data });
    },
    updateLicenseUtilizationUserSettings ({ commit, dispatch }, { licenseUtilizationUserSettings }) {
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            {
                name: 'licenseUtilizationUserSettings',
                value: JSON.stringify(licenseUtilizationUserSettings)
            },
            {
                root: true
            }
        );
        commit('setLicenseUtilizationUserSettings', { licenseUtilizationUserSettings });
    }
};

const getters = {
    activeSubHasLicenses (state) {
        const apps = Object.values(state.licenseDataMap);

        return apps.filter((app) => app.quantity !== null).length > 0;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
