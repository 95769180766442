<template>
    <pendo-multiselect-filter
        v-model="localSelected"
        :show-all-label="false"
        :show-search="true"
        :options="options"
        :full-width="true"
        :use-data-measure-trigger="false"
        :use-global-filter-style="true"
        :show-selected-values="false"
        :prefix-icon="prefixIcon"
        :entity-name="entityName"
        :search-placeholder="searchPlaceholder"
        @input="updateSelection">
        <template #placeholder="{ placeholder }">
            <pendo-icon-option :option="{ label: placeholder, icon: { type: prefixIcon } }" />
        </template>
        <template #selectedLabel="{ selectedLabel }">
            <pendo-icon-option :option="{ label: selectedLabel, icon: { type: prefixIcon } }" />
        </template>
    </pendo-multiselect-filter>
</template>

<script>
import { PendoIconOption, PendoMultiselectFilter } from '@pendo/components';

export default {
    name: 'MultiselectFilterWithDefaultTrigger',
    components: {
        PendoIconOption,
        PendoMultiselectFilter
    },
    props: {
        options: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => []
        },
        entityName: {
            type: String,
            default: ''
        },
        prefixIcon: {
            type: String,
            default: ''
        },
        searchPlaceholder: {
            type: String,
            default: 'Search'
        }
    },
    data () {
        return {
            localSelected: [...this.selected]
        };
    },
    watch: {
        selected (value) {
            this.localSelected = value;
        }
    },
    methods: {
        updateSelection (selected) {
            this.$emit('selection', selected);
        }
    }
};
</script>

<style lang="scss" scoped>
.pendo-multiselect__label-container {
    margin-left: 8px;
}
</style>
