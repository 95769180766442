<template>
    <div class="metric-comparison">
        <div
            v-pendo-loading="{
                loading,
                skeletonProps: { height: '36', width: '128' }
            }"
            class="comparison completion-rate">
            <pendo-popover
                arrow
                popover-class="baseline-tooltip"
                placement="right">
                <template #default>
                    <div
                        :class="{
                            'metric': true,
                            'no-completions': noCompletions,
                            'no-data': noData,
                            'no-change': completionRateChange.absolute === 0,
                            'trending-up': completionRateChange.absolute > 0,
                            'trending-down': completionRateChange.absolute < 0
                        }">
                        <span v-if="noDataMessage">
                            {{ noDataMessage }}
                        </span>
                        <span v-else-if="completionRateChange.absolute === 0">
                            No change
                        </span>
                        <template v-else>
                            <pendo-icon
                                v-if="completionRateChange.absolute > 0"
                                display="inline-block"
                                type="trending-up"
                                size="15"
                                stroke="#2b5334" />
                            <pendo-icon
                                v-if="completionRateChange.absolute < 0"
                                display="inline-block"
                                type="trending-down"
                                size="15"
                                stroke="#cc3340" />
                            <span class="change">{{ Math.abs(completionRateChange.absolute) }}%</span>
                            <span>{{ completionRateChange.absolute > 0 ? 'higher' : 'lower' }}</span>
                        </template>
                    </div>
                </template>
                <template #popover>
                    <div class="comparison-tooltip">
                        <div class="comparison-tooltip--head">
                            <div class="name">
                                {{ recurring ? 'Workflow completion rate' : 'Visitor completion rate' }}
                            </div>
                        </div>
                        <div class="comparison-tooltip--body">
                            <div class="comparison-tooltip--row">
                                <div class="comparison-tooltip--label">
                                    Baseline
                                </div>
                                <div class="metric">
                                    {{ baselineCompletionRate }}% completion rate
                                </div>
                            </div>
                            <div class="comparison-tooltip--row">
                                <div class="comparison-tooltip--label">
                                    Current
                                </div>
                                <div class="metric">
                                    {{ currentCompletionRate }}% completion rate
                                </div>
                            </div>
                        </div>
                        <div class="comparison-tooltip--footer">
                            <div
                                v-if="completionRateChange.absolute > 0"
                                class="summary">
                                <span class="value">{{ completionRateChange.absolute }}%</span> higher
                                <template v-if="completionRateChange.relative !== Infinity">
                                    ({{ completionRateChange.relative }}% increase)
                                </template>
                            </div>
                            <div
                                v-if="completionRateChange.absolute === 0"
                                class="summary">
                                <span class="value">No Change</span>
                            </div>
                            <div
                                v-if="completionRateChange.absolute < 0"
                                class="summary">
                                <span class="value">{{ Math.abs(completionRateChange.absolute) }}%</span> lower
                                <template v-if="completionRateChange.relative !== Infinity">
                                    ({{ Math.abs(completionRateChange.relative) }}% decrease)
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </pendo-popover>
        </div>
        <div
            v-pendo-loading="{
                loading,
                skeletonProps: { height: '36', width: '128' }
            }"
            class="comparison time-to-complete">
            <pendo-popover
                arrow
                popover-class="baseline-tooltip"
                placement="right">
                <template #default>
                    <div
                        :class="{
                            'metric': true,
                            'no-completions': noCompletions,
                            'no-data': noData,
                            'no-change': timeToCompleteChange.absolute === 0,
                            'trending-up': timeToCompleteChange.absolute < 0,
                            'trending-down': timeToCompleteChange.absolute > 0
                        }">
                        <span v-if="noDataMessage">
                            {{ noDataMessage }}
                        </span>
                        <span v-else-if="timeToCompleteChange.absolute === 0">
                            No change
                        </span>
                        <template v-else>
                            <pendo-icon
                                v-if="timeToCompleteChange.absolute < 0"
                                display="inline-block"
                                type="trending-up"
                                size="15"
                                stroke="#2b5334" />
                            <pendo-icon
                                v-if="timeToCompleteChange.absolute > 0"
                                display="inline-block"
                                type="trending-down"
                                size="15"
                                stroke="#cc3340" />
                            <span class="change">{{ Math.abs(timeToCompleteChange.relative) }}%</span>
                            <span>{{ timeToCompleteChange.absolute > 0 ? 'slower' : 'faster' }}</span>
                        </template>
                    </div>
                </template>
                <template #popover>
                    <div class="comparison-tooltip">
                        <div class="comparison-tooltip--head">
                            <div class="name">
                                Median time to complete
                            </div>
                        </div>
                        <div class="comparison-tooltip--body">
                            <div class="comparison-tooltip--row">
                                <div class="comparison-tooltip--label">
                                    Baseline
                                </div>
                                <div class="metric">
                                    {{ baselineTimeToComplete.pretty }}
                                </div>
                            </div>
                            <div class="comparison-tooltip--row">
                                <div class="comparison-tooltip--label">
                                    Current
                                </div>
                                <div class="metric">
                                    {{ currentTimeToComplete.pretty }}
                                </div>
                            </div>
                        </div>
                        <div class="comparison-tooltip--footer">
                            <div
                                v-if="timeToCompleteChange.absolute > 0"
                                class="summary">
                                {{ timeToCompleteChange.pretty }} more
                                <template v-if="timeToCompleteChange.relative !== Infinity">
                                    (<span class="value">{{ timeToCompleteChange.relative }}%</span> slower)
                                </template>
                            </div>
                            <div
                                v-if="timeToCompleteChange.absolute === 0"
                                class="summary">
                                <span class="value">No Change</span>
                            </div>
                            <div
                                v-if="timeToCompleteChange.absolute < 0"
                                class="summary">
                                {{ timeToCompleteChange.pretty }} less
                                <template v-if="timeToCompleteChange.relative !== Infinity">
                                    (<span class="value">{{ Math.abs(timeToCompleteChange.relative) }}%</span> faster)
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </pendo-popover>
        </div>
        <div
            v-pendo-loading="{
                loading,
                skeletonProps: { height: '36', width: '128' }
            }"
            class="comparison completions">
            <pendo-popover
                arrow
                popover-class="baseline-tooltip"
                placement="left">
                <div
                    :class="{
                        'metric': true,
                        'no-completions': noCompletions,
                        'no-data': noData,
                        'no-change': completionsChange.absolute === 0,
                        'trending-up': completionsChange.absolute > 0,
                        'trending-down': completionsChange.absolute < 0
                    }">
                    <span v-if="noDataMessage">
                        {{ noDataMessage }}
                    </span>
                    <span v-else-if="completionsChange.absolute === 0">
                        No change
                    </span>
                    <template v-else>
                        <pendo-icon
                            v-if="completionsChange.absolute > 0"
                            display="inline-block"
                            type="trending-up"
                            size="15"
                            stroke="#2b5334" />
                        <pendo-icon
                            v-if="completionsChange.absolute < 0"
                            display="inline-block"
                            type="trending-down"
                            size="15"
                            stroke="#cc3340" />
                        <span class="change">{{ Math.abs(completionsChange.relative) }}%</span>
                        <span>{{ completionsChange.absolute > 0 ? 'increase' : 'decrease' }}</span>
                    </template>
                </div>
                <template #popover>
                    <div class="comparison-tooltip">
                        <div class="comparison-tooltip--head">
                            <div class="name">
                                {{ recurring ? 'Completed attempts' : 'Visitor completions' }}
                            </div>
                        </div>
                        <div class="comparison-tooltip--body">
                            <div class="comparison-tooltip--row">
                                <div class="comparison-tooltip--label">
                                    Baseline
                                </div>
                                <div class="metric">
                                    {{ baselineCompletions }} completions
                                </div>
                            </div>
                            <div class="comparison-tooltip--row">
                                <div class="comparison-tooltip--label">
                                    Current
                                </div>
                                <div class="metric">
                                    {{ currentCompletions }} completions
                                </div>
                            </div>
                        </div>
                        <div class="comparison-tooltip--footer">
                            <div
                                v-if="completionsChange.absolute > 0"
                                class="summary">
                                {{ completionsChange.absolute.toLocaleString() }} more
                                <template v-if="completionsChange.relative !== Infinity">
                                    (<span class="value">{{ completionsChange.relative }}%</span> increase)
                                </template>
                            </div>
                            <div
                                v-if="completionsChange.absolute === 0"
                                class="summary">
                                <span class="value">No Change</span>
                            </div>
                            <div
                                v-if="completionsChange.absolute < 0"
                                class="summary">
                                {{ Math.abs(completionsChange.absolute).toLocaleString() }} less
                                <template v-if="completionsChange.relative !== Infinity">
                                    (<span class="value">{{ Math.abs(completionsChange.relative) }}%</span> decrease)
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </pendo-popover>
        </div>
    </div>
</template>

<script>
import { PendoIcon, PendoLoading, PendoPopover } from '@pendo/components';
import { longPrettyTime } from '@/utils/durations';
import get from 'lodash/get';

export default {
    name: 'MetricComparison',
    components: {
        PendoIcon,
        PendoPopover
    },
    directives: {
        PendoLoading
    },
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        recurring: {
            type: Boolean,
            default: false
        },
        baseline: {
            type: Object,
            default: () => {}
        },
        current: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        noDataMessage () {
            // Don't spill outside of loading skeletion
            if (this.loading) return '--';

            if (this.noCompletions) return 'No Baseline Completions';
            if (this.noData) return 'No Baseline Data';

            return '';
        },
        noCompletions () {
            const completions = get(this.baseline, this.recurring ? 'completedAttempts' : 'completedCount', null);

            return completions === 0;
        },
        noData () {
            return this.baseline === null;
        },
        baselineCompletionRate () {
            const numerator = get(this.baseline, this.recurring ? 'completedAttempts' : 'completedCount', 0);
            const denominator = get(this.baseline, this.recurring ? 'totalAttempts' : 'totalVisitors', 0);
            if (denominator === 0) return 0;

            return Math.round((numerator / denominator) * 100);
        },
        currentCompletionRate () {
            const numerator = get(this.current, this.recurring ? 'completedAttempts' : 'completedCount', 0);
            const denominator = get(this.current, this.recurring ? 'totalAttempts' : 'totalVisitors', 0);
            if (denominator === 0) return 0;

            return Math.round((numerator / denominator) * 100);
        },
        completionRateChange () {
            const absolute = this.currentCompletionRate - this.baselineCompletionRate;
            const relative = Math.round((absolute / this.baselineCompletionRate) * 100);

            return { absolute, relative };
        },
        baselineTimeToComplete () {
            const ms = get(this.baseline, 'medianTimeToComplete', 0);
            const pretty = longPrettyTime(ms);

            return { ms, pretty };
        },
        currentTimeToComplete () {
            const ms = get(this.current, 'medianTimeToComplete', 0);
            const pretty = longPrettyTime(ms);

            return { ms, pretty };
        },
        timeToCompleteChange () {
            const absolute = this.currentTimeToComplete.ms - this.baselineTimeToComplete.ms;
            const relative = Math.round((absolute / this.baselineTimeToComplete.ms) * 100);
            const pretty = longPrettyTime(Math.abs(absolute));

            return { absolute, relative, pretty };
        },
        baselineCompletions () {
            const completions = get(this.baseline, this.recurring ? 'completedAttempts' : 'completedCount', 0);

            return completions;
        },
        currentCompletions () {
            const completions = get(this.current, this.recurring ? 'completedAttempts' : 'completedCount', 0);

            return completions;
        },
        completionsChange () {
            const absolute = this.currentCompletions - this.baselineCompletions;
            const relative = Math.round((absolute / this.baselineCompletions) * 100);

            return { absolute, relative };
        }
    }
};
</script>

<style lang="scss">
.baseline-tooltip {
    .pendo-popover__inner {
        padding: 0;
    }
}
</style>

<style lang="scss" scoped>
$green-text: #2b5334;
$green-bg: #e3f7ed;
$red-text: #cc3340;
$red-bg: #fff5f5;
$grey-text: #2a2c35;
$grey-bg: #f4f4f7;
$yellow-text: #876800;
$yellow-bg: #fffaf2;

.metric-comparison {
    position: relative;
    top: -8px;
    display: flex;
    height: 60px;
    justify-content: space-around;

    .comparison {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 33%;

        .metric {
            display: flex;
            align-items: center;
            line-height: 21px;
            border-radius: 4px;
            padding: 8px;
            cursor: pointer;

            &.trending-up {
                background: $green-bg;
                color: $green-text;
            }

            &.trending-down {
                background: $red-bg;
                color: $red-text;
            }

            &.no-data,
            &.no-change {
                color: $grey-text;
                background: $grey-bg;
            }

            &.no-completions {
                background: $yellow-bg;
                color: $yellow-text;
            }

            .change {
                font-weight: 600;
                margin: 0 3px 0 5px;
            }
        }

        &:nth-of-type(2) {
            border-left: 1px solid #dadce5;
            border-right: 1px solid #dadce5;
        }
    }

    ::v-deep .pendo-loading-overlay {
        display: flex;
        justify-content: center;
    }
}

.comparison-tooltip {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    min-width: 275px;

    .comparison-tooltip--head {
        padding: 16px 24px;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid #c4c4c4;
    }

    .value {
        font-weight: 600;
    }

    .comparison-tooltip--body {
        display: flex;
        padding: 16px 24px 8px;
        width: 100%;
        flex-direction: column;
        align-items: center;

        .comparison-tooltip--row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            font-size: 13px;
            margin-bottom: 4px;
        }
    }

    .comparison-tooltip--footer {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: flex-end;
        font-size: 13px;
        margin: 0;

        &::before {
            display: block;
            position: absolute;
            left: 24px;
            right: 24px;
            content: '';
            border-top: 1px dashed #dadce5;
        }

        .summary {
            margin-top: 8px;
            margin-bottom: 16px;
            margin-right: 24px;
        }
    }
}
</style>
