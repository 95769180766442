<template>
    <pendo-modal
        class="path-share-modal"
        height="auto"
        esc-to-close
        :visible="visible"
        title="Share Path"
        @close="closeModal">
        <template slot="body">
            <path-share-toggle
                v-model="shared"
                :disabled="!canShare" />
        </template>
        <template slot="footer">
            <pendo-button
                theme="app"
                type="secondary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                theme="app"
                label="Save"
                :disabled="!canShare"
                @click="updateReport" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoButton, PendoModal } from '@pendo/components';
import PathShareToggle from '@/components/paths/PathShareToggle';
import { mapActions } from 'vuex';

export default {
    name: 'PathShareModal',
    components: {
        PendoButton,
        PendoModal,
        PathShareToggle
    },
    props: {
        report: {
            type: Object,
            default: () => ({})
        },
        visible: {
            type: Boolean,
            default: false
        },
        canShare: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            shared: false
        };
    },
    watch: {
        report: {
            immediate: true,
            handler () {
                this.shared = this.report.shared;
            }
        }
    },
    methods: {
        ...mapActions('reports', ['update']),
        closeModal ($event) {
            this.shared = this.report.shared;
            this.$emit('close', $event);
        },
        async updateReport () {
            const report = {
                ...this.report,
                shared: this.shared
            };

            await this.update({ report });
            this.closeModal();
        }
    }
};
</script>
