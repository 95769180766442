<template>
    <main class="portfolio-app-usage-chart">
        <pendo-card
            v-pendo-loading:feather="showLoadingState"
            body-min-height="464px">
            <template #title>
                <pendo-multiselect
                    v-model="selectedField"
                    :allow-empty="false"
                    :options="selectedFieldOptions">
                    <template #trigger>
                        <pendo-data-source-trigger />
                    </template>
                </pendo-multiselect>
            </template>
            <template #headerRight>
                <div
                    v-if="showXAxisSortDropdown"
                    class="portfolio-app-usage-chart--table-header-right">
                    <pendo-multiselect
                        v-model="selectedSortOption"
                        :allow-empty="false"
                        :options="sortOptions">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
            </template>

            <template #body>
                <portfolio-action-menu
                    v-show="actionMenuVisible"
                    :actions="actions"
                    :mouse-position="mousePosition" />
                <pendo-button
                    v-show="isZoomed"
                    theme="app"
                    class="back-button"
                    type="secondary"
                    prefix-icon="arrow-left"
                    label="Back"
                    @click="zoomOut" />
                <pendo-empty-state
                    v-if="showEmptyState"
                    :icon="{ 'type': 'bar-chart', 'size': 32, 'stroke-width': 1.5 }"
                    :title="emptyChartTitle"
                    :description="emptyChartDescription"
                    class="portfolio-app-usage-chart__empty" />
                <div
                    v-show="showChart"
                    ref="app-usage"
                    class="portfolio-app-usage-chart--chart" />
            </template>
        </pendo-card>
    </main>
</template>

<script>
import { reusableTooltipFormatter, dataLabelFormatter, tooltipPointFormatter } from '@/utils/highcharts';
import {
    PendoCard,
    PendoDataSourceTrigger,
    PendoLoading,
    PendoMultiselect,
    PendoEmptyState,
    PendoButton
} from '@pendo/components';
import PortfolioActionMenu from './PortfolioActionMenu.vue';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { prettyTime } from '@pendo/services/Formatters';
import { pluralize } from '@/utils/formatters';

export default {
    name: 'PortfolioAppUsageChart',
    components: {
        PortfolioActionMenu,
        PendoCard,
        PendoButton,
        PendoDataSourceTrigger,
        PendoMultiselect,
        PendoEmptyState
    },
    directives: {
        PendoLoading
    },
    props: {
        isFetchingChart: {
            type: Boolean,
            default: false
        },
        appMap: {
            type: Object,
            default: () => ({})
        },
        dateRange: {
            type: Object,
            default: () => ({ count: 'weekly' })
        },
        // This prop indicates how data is pivoted: apps as the x-axis with metadata field values as the stacked-columns (true) OR
        // metadata field values as the x-axis with applications as the stacked columns (false, isViewingMetadataByApplications would be the inverse name).
        isViewingApplicationsByMetadata: {
            type: Boolean,
            default: false
        },
        appUsageList: {
            type: Array,
            default: () => []
        },
        chartColors: {
            type: Array,
            default: () => [
                '#229CA8',
                '#FF4876',
                '#8DDDB6',
                '#0B9AD3',
                '#A973F2',
                '#EFBE53',
                '#07699B',
                '#65D036',
                '#E14CD3',
                '#FF9237'
            ]
        }
    },
    data () {
        const selectedFieldOptions = [
            {
                id: 'averageDailyTimeOnApp',
                label: 'Average Daily Time on Application (minutes)'
            },
            {
                id: 'summedMinutes',
                label: 'Time Spent on App (minutes)'
            },
            {
                id: 'visitors',
                label: 'Visitors'
            }
        ];

        const sortOptions = [
            {
                id: 'alphabeticalAscending',
                label: 'Alphabetical A → Z'
            },
            {
                id: 'alphabeticalDescending',
                label: 'Alphabetical Z → A'
            },
            {
                id: 'mostActive',
                label: 'Most → Least Active'
            },
            {
                id: 'leastActive',
                label: 'Least → Most Active'
            }
        ];

        return {
            chart: null,
            selectedField: selectedFieldOptions[0],
            selectedFieldOptions,
            selectedSortOption: sortOptions[0],
            sortOptions,
            appUsageMap: {},
            appsSortedList: [],
            metadataFieldValuesUsageMap: {},
            metadataFieldValuesSortedList: [],
            emptyChartTitle: 'Data not Found',
            emptyChartDescription: 'Try changing filters or selecting different applications',
            actionMenuVisible: false,
            applicationName: 'Application',
            activeSeries: {
                index: null,
                appId: null,
                appName: 'Application',
                metadataFieldName: null
            },
            isZoomed: false,
            mousePosition: {
                x: 300,
                y: 300
            },
            loading: false
        };
    },
    computed: {
        showLoadingState () {
            return this.loading || this.isFetchingChart;
        },
        showEmptyState () {
            return !this.showLoadingState && this.isChartEmpty;
        },
        showChart () {
            return !this.showEmptyState && !this.showLoadingState;
        },
        showDrillDownAction () {
            return !this.isZoomed && this.yValuesSortedList && this.yValuesSortedList.length > 1;
        },
        actions () {
            let truncatedAppName = this.activeSeries.appName;

            if (this.activeSeries.appName.length > 20) {
                truncatedAppName = `${this.activeSeries.appName.substring(0, 20)}...`;
            }

            return [
                ...(this.showDrillDownAction ? [{ icon: 'zoom-in', name: 'Drill Down', callback: this.zoomIn }] : []),
                { icon: 'box', name: `View ${truncatedAppName} Usage`, callback: this.viewApplicationUsage }
            ];
        },
        usageList () {
            const appUsageList = Object.values(this.appUsageMap);
            const metadataFieldValuesUsageList = Object.values(this.metadataFieldValuesUsageMap);

            /*
            usageList is effectively returning what is displayed on the x-axis.
            When viewing applications by metadata, the x-axis shows the apps and the stacked columns show the metadata field values.
            When viewing metadata field values by applications, the x-axis shows the metadata field values and the stacked columns show the apps.
            */
            return this.isViewingApplicationsByMetadata ? appUsageList : metadataFieldValuesUsageList;
        },
        sortedUsageList () {
            let sortedUsageList;
            switch (this.selectedSortOption.id) {
                case 'alphabeticalAscending':
                    sortedUsageList = orderBy(this.usageList, [({ name }) => name.toLowerCase()], 'asc');
                    break;
                case 'alphabeticalDescending':
                    sortedUsageList = orderBy(this.usageList, [({ name }) => name.toLowerCase()], 'desc');
                    break;
                case 'mostActive':
                    if (this.isZoomed) {
                        if (this.isViewingApplicationsByMetadata) {
                            // Sort based on Meta Field values
                            sortedUsageList = orderBy(
                                this.usageList,
                                [
                                    ({ yValues }) =>
                                        get(yValues[this.activeSeries.metadataFieldName], this.selectedField.id, 0)
                                ],
                                'desc'
                            );
                        } else {
                            // Sort based on Application values
                            sortedUsageList = orderBy(
                                this.usageList,
                                [({ yValues }) => get(yValues[this.activeSeries.appId], this.selectedField.id, 0)],
                                'desc'
                            );
                        }
                    } else {
                        // Sort based on totals
                        sortedUsageList = orderBy(
                            this.usageList,
                            [({ yTotals }) => yTotals[this.selectedField.id]],
                            'desc'
                        );
                    }
                    break;
                case 'leastActive':
                    if (this.isZoomed) {
                        if (this.isViewingApplicationsByMetadata) {
                            // Sort based on Meta Field values
                            sortedUsageList = orderBy(
                                this.usageList,
                                [
                                    ({ yValues }) =>
                                        get(yValues[this.activeSeries.metadataFieldName], this.selectedField.id, 0)
                                ],
                                'asc'
                            );
                        } else {
                            // Sort based on Application values
                            sortedUsageList = orderBy(
                                this.usageList,
                                [({ yValues }) => get(yValues[this.activeSeries.appId], this.selectedField.id, 0)],
                                'asc'
                            );
                        }
                    } else {
                        // Sort based on totals
                        sortedUsageList = orderBy(
                            this.usageList,
                            [({ yTotals }) => yTotals[this.selectedField.id]],
                            'asc'
                        );
                    }
                    break;
            }

            return sortedUsageList;
        },
        // returns the labels for the x-axis
        chartCategories () {
            return this.sortedUsageList.map((app) => app.name);
        },
        showXAxisSortDropdown () {
            return this.usageList.length > 1;
        },
        yValuesSortedList () {
            /*
            yValuesSortedList determines the order of the stacked columns and alphabetical is the current default order.
            When viewing applications by metadata, apps run along the x-axis so the stacked columns show metadata field values
            WHen viewing metadata by applications, metadata field values run along the x-axis so the stacked columns show the apps.
            Highcharts references the stacked column values as `y values`
            */

            // appsSortedList is the a misnomer: it's a list of the metadata field values when apps are on the x-axis
            // metadataFieldValuesSortedList is a list of the apps when metadata field values are on the x-axis
            return this.isViewingApplicationsByMetadata ? this.appsSortedList : this.metadataFieldValuesSortedList;
        },
        chartData () {
            const { label } = this.dateRange;
            /*
            For stacked columns, highcharts expects an array the size of the number of stacked columns with each column element
            containing a data array with the y-values for all the x-axis categories.
            For example, when viewing applications by email (metadata), there will be an array of length equal to the number of
            email addresses and each array element will have a data array with the y-values (avg daily time on app, total minutes, number of visitors, etc.,) for all the apps.
            */

            // this.yValuesSortedList and yValueIndex dictate the order of the stacked columns
            const chartData = this.yValuesSortedList.map((yValue, yValueIndex) => {
                const data = this.sortedUsageList.map(({ yValues, name }) => {
                    return {
                        y: get(yValues[yValue.id], this.selectedField.id, 0),
                        dateRangeLabel: label,
                        name,
                        yValue: yValue.name,
                        selectedField: this.selectedField.id
                    };
                });

                let color = this.chartColors[this.yValuesSortedList.indexOf(yValue) % this.chartColors.length];
                if (typeof yValue.id === 'number') {
                    color = get(this.appMap, `${yValue.id}.color`, color);
                }

                return {
                    id: yValue.id,
                    name: yValue.name,
                    data, // this is an array of y-values for each category along the x-axis
                    index: yValueIndex,
                    color,
                    cursor: 'pointer',
                    stickyTracking: false,
                    borderWidth: 1,
                    pointPadding: 0,
                    groupPadding: 0
                };
            });

            return chartData;
        },
        isChartEmpty () {
            if (!this.chartData.length) return true;

            return false;
        },
        chartScrollablePlotArea () {
            return 250 + this.sortedUsageList.length * 135;
        },
        chartYAxisTitle () {
            const { id } = this.selectedField;
            const { label } = this.selectedFieldOptions.find((option) => option.id === id);

            return label;
        },
        chartConfig () {
            // these are declared to workaround scoping issues with Highcharts
            const { disableTooltips, setActiveSeries, setActionMenuPosition, showActionMenu } = this;

            return {
                chart: {
                    height: 464,
                    scrollablePlotArea: {
                        minWidth: this.chartScrollablePlotArea
                    },
                    spacingBottom: 15,
                    type: 'column',
                    animation: {
                        duration: 100
                    }
                },
                credits: {
                    enabled: false
                },
                colors: this.chartColors,
                exporting: {
                    enabled: false
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    margin: 10
                },
                plotOptions: {
                    column: {
                        pointWidth: 48,
                        stacking: 'normal',
                        borderWidth: 3,
                        dataLabels: {
                            enabled: false,
                            formatter: dataLabelFormatter,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 0
                            }
                        },
                        events: {
                            click (event) {
                                event.stopPropagation();
                                disableTooltips();
                                setActionMenuPosition(event);
                                setActiveSeries(event);
                                showActionMenu();
                                this.update({
                                    dataLabels: {
                                        enabled: false
                                    }
                                });
                            },
                            mouseOver () {
                                this.update({
                                    dataLabels: {
                                        enabled: true
                                    }
                                });
                            },
                            mouseOut () {
                                this.update({
                                    dataLabels: {
                                        enabled: false
                                    }
                                });
                            }
                        }
                    },
                    series: {
                        animation: {
                            duration: 200
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series: this.chartData,
                title: {
                    text: null
                },
                tooltip: {
                    useHTML: true,
                    borderColor: '#DADCE5',
                    headerFormat: '<span class="header" style="color: {point.color}">\u2B24 {series.name}</span><br>',
                    pointFormatter: tooltipPointFormatter,
                    padding: 0,
                    shadow: {
                        color: 'rgba(0, 0, 0, 0.17)',
                        width: 8
                    },
                    formatter () {
                        const { y, selectedField, series, color } = this.point;
                        const timeInMs = y * 60 * 1000;
                        const formattedTimeValue = prettyTime(timeInMs);

                        const options = {
                            classes: ['portfolio-app-usage-chart--tooltip'],
                            color,
                            headerTitle: series.name,
                            headerDescription: this.key,
                            bodyMetric: '',
                            bodyDescription: '',
                            showFooter: true
                        };

                        switch (selectedField) {
                            case 'averageDailyTimeOnApp':
                                options.bodyMetric = formattedTimeValue;
                                options.bodyDescription = 'Avg. Daily Time Spent(min)';
                                break;
                            case 'summedMinutes':
                                options.bodyMetric = formattedTimeValue;
                                options.bodyDescription = 'Time Spent';
                                break;
                            case 'visitors':
                            default:
                                options.bodyMetric = y;
                                options.bodyDescription = pluralize('Visitor', +y);
                        }

                        return reusableTooltipFormatter(options);
                    }
                },
                xAxis: {
                    categories: this.chartCategories,
                    crosshair: {
                        color: 'rgba(0,0,0,0)'
                    },
                    labels: {
                        y: 20,
                        style: {
                            color: '#2A2C35',
                            fontSize: '12px'
                        }
                    },
                    maxPadding: 0
                },
                yAxis: {
                    labels: {
                        align: 'right',
                        style: {
                            color: '#6A6C75',
                            fontSize: '12px'
                        },
                        x: -6,
                        y: 4
                    },
                    maxPadding: 0,
                    min: 0,
                    title: {
                        style: {
                            color: '#000000',
                            fontSize: '14px'
                        },
                        text: this.chartYAxisTitle
                    }
                }
            };
        }
    },
    watch: {
        selectedField () {
            this.updateChartAxis();
        },
        selectedSortOption () {
            this.updateChartAxis();
        },
        isViewingApplicationsByMetadata () {
            this.isZoomed = false;
            this.updateChartAxis();
        },
        appUsageList (updatedAppUsageList) {
            this.formatChartData(updatedAppUsageList);
        }
    },
    created () {
        this.refreshChart();

        document.addEventListener('click', this.hideActionMenu);
        document.addEventListener('scroll', this.hideActionMenu);
    },
    mounted () {
        this.formatChartData(this.appUsageList);
    },
    destroyed () {
        document.removeEventListener('click', this.hideActionMenu);
    },
    methods: {
        refreshChart () {
            this.isZoomed = false;
            this.$emit('fetchAppUsageChart');
        },
        disableTooltips () {
            this.chart.update({
                tooltip: {
                    enabled: false
                }
            });
        },
        setActionMenuPosition (event) {
            // This element only exists if the chart has more items
            // on the x-axis than can be displayed without scrolling
            const chartScrollable = document.querySelector('.highcharts-scrolling');
            let scroll = 0;
            if (chartScrollable) {
                chartScrollable.addEventListener('scroll', this.hideActionMenu);
                scroll = chartScrollable.scrollLeft;
            }

            this.mousePosition.x = event.chartX - scroll;
            this.mousePosition.y = event.chartY;
        },
        setActiveSeries (event) {
            const activeSeries = {
                index: event.point.series.index
            };

            if (this.isViewingApplicationsByMetadata) {
                activeSeries.appName = event.point.name;
                activeSeries.metadataFieldName = event.point.series.name;
                activeSeries.appId = this.sortedUsageList[event.point.index].id;
            } else {
                activeSeries.metadataFieldName = event.point.name;
                activeSeries.appName = event.point.series.name;
                activeSeries.appId = event.point.series.options.id;
            }

            this.activeSeries = activeSeries;
        },
        showActionMenu () {
            this.actionMenuVisible = true;
        },
        hideActionMenu () {
            this.actionMenuVisible = false;
            if (this.chart) {
                this.chart.update({
                    tooltip: {
                        enabled: true
                    }
                });
            }
        },
        updateChartAxis () {
            this.chart.update(
                {
                    chart: {
                        scrollablePlotArea: {
                            minWidth: this.chartScrollablePlotArea
                        }
                    },
                    series: this.chartData,
                    xAxis: {
                        categories: this.chartCategories
                    },
                    yAxis: {
                        title: {
                            text: this.chartYAxisTitle
                        }
                    }
                },
                true,
                true
                // ^^^ oneToOne highcharts parameter; the chart will not pivot correctly without this set
                // https://api.highcharts.com/class-reference/Highcharts.Chart#update
            );
        },
        zoomIn () {
            this.loading = true;
            this.isZoomed = true;
            for (const series of this.chart.series) {
                if (this.activeSeries.index !== series.index) {
                    series.hide();
                }
            }
            this.updateChartAxis();
            this.loading = false;
        },
        zoomOut () {
            this.loading = true;
            this.isZoomed = false;
            for (const series of this.chart.series) {
                series.show();
            }
            this.updateChartAxis();
            this.loading = false;
        },
        viewApplicationUsage () {
            for (const [appId, app] of Object.entries(this.appMap)) {
                if (this.activeSeries.appId === app.id) {
                    this.$emit('updateAppFilter', { appIds: [parseInt(appId)], isFetchingFromChartClick: true });
                    this.$emit('viewApplicationUsage');
                }
            }
        },
        createUsageMap (usageList, { xValue, xName, yValue, yName }) {
            const uniqueYValues = new Map();

            const usageMap = usageList.reduce((map, app) => {
                uniqueYValues.set(app[yValue], { id: app[yValue], name: app[yName] });
                /**
                 * usageMap end state when 'isViewingApplicationsByMetadata' is true (i.e., when apps are on the x-axis and xValue = 'id', xName = 'name', yValue = 'groupValue' and yName = 'groupValue')
                 * {
                 *      gmailAppId: {
                 *          id: 123456,
                 *          name: 'Gmail',
                 *          yValues: {
                 *              'Product': {
                 *                  id: 123456,
                 *                  name: 'Gmail',
                 *                  groupValue: 'Product',
                 *                  averageDailyTimeOnApp: 66,
                 *                  visitors: 44,
                 *                  summedMinutes: 22
                 *               },
                 *              'Other metadata field value': {}
                 *          },
                 *          yTotals: {
                 *              averageDailyTimeOnApp: 'sum of averageDailyTimeOnApp across all yValues'
                 *              visitors: 'sum of visitors across all yValues'
                 *              summedMinutes: 'sum of summedMinutes across all yValues'
                 *          }
                 *      },
                 *      anotherAppId: {}
                 * }
                 */

                if (!map[app[xValue]]) {
                    map[app[xValue]] = {
                        id: app[xValue],
                        name: app[xName],
                        yValues: {},
                        yTotals: {}
                    };
                }

                map[app[xValue]].yValues[app[yValue]] = map[app[xValue]].yValues[app[yValue]] || {};
                map[app[xValue]].yValues[app[yValue]] = {
                    ...app
                };

                // collect y-axis totals per app, for sorting least/most
                this.selectedFieldOptions.forEach(({ id }) => {
                    if (!map[app[xValue]].yTotals[id]) map[app[xValue]].yTotals[id] = 0;
                    const appValue = app[id] || 0;
                    map[app[xValue]].yTotals[id] += appValue;
                });

                return map;
            }, {});

            // sort to display the stacked columns and stacked column legend in alphabetical order
            const yValuesSortedList = [...uniqueYValues.values()].sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            });

            return { usageMap, yValuesSortedList };
        },
        /*
        Generates the chart data when viewing applications by metadata (i.e., isViewingApplicationsByMetadata is true)
        and when viewing metadata by applications (i.e., isViewingApplicationsByMetadata is false) so that the pivoting is solely done in the frontend.
        */
        formatChartData (usageList = []) {
            const appUsageWithDisplayNames = usageList.map((app) => {
                return {
                    name: get(this.appMap, `${app.id}.displayName`, ''),
                    ...app
                };
            });

            // isViewingApplicationsByMetadata is true
            const { usageMap, yValuesSortedList } = this.createUsageMap(appUsageWithDisplayNames, {
                xValue: 'id',
                xName: 'name',
                yValue: 'groupValue',
                yName: 'groupValue'
            });
            this.appUsageMap = usageMap;
            this.appsSortedList = yValuesSortedList;

            // isViewingApplicationsByMetadata is false (isViewingMetadataByApplications would be the inverse name);
            const {
                usageMap: metadataFieldValuesUsageMap,
                yValuesSortedList: metadataFieldValuesSortedList
            } = this.createUsageMap(appUsageWithDisplayNames, {
                xValue: 'groupValue',
                xName: 'groupValue',
                yValue: 'id',
                yName: 'name'
            });
            this.metadataFieldValuesUsageMap = metadataFieldValuesUsageMap;
            this.metadataFieldValuesSortedList = metadataFieldValuesSortedList;
            if (this.$refs['app-usage']) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['app-usage'], this.chartConfig);
            }
        }
    }
};
</script>

<style lang="scss">
.portfolio-app-usage-chart {
    // To make the chart use up the entire card body.
    .pendo-card__body {
        padding: 0;
        overflow: hidden;
    }

    // To make the y-axis not show up if the chart is scrollable and the loading indicator is up.
    .pendo-loading-overlay {
        z-index: 2;
    }

    .pendo-multiselect__value {
        overflow: unset;
    }

    .back-button {
        position: absolute;
        top: 60px;
        left: 60px;
        z-index: 1;
    }

    &--tooltip {
        min-width: 250px;
        font-size: 14px;
        color: $gray-primary;
        font-family: 'inter', sans-serif;

        .chart-tooltip {
            &-header {
                border-bottom: 1px solid $gray-lighter-5;
                display: flex;
                flex-direction: column;
                padding: 16px 0 18px 0;

                &--title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 20px;

                    & .header-title-text {
                        font-weight: 600;
                        margin-left: 7px;
                        overflow: hidden;
                        max-width: 20ch;
                        text-overflow: ellipsis;
                    }
                }

                &--description {
                    margin-left: 37px;
                    font-size: 12px;
                    overflow: hidden;
                    max-width: 20ch;
                    text-overflow: ellipsis;
                }
            }

            &-body {
                margin-left: 37px;
                padding: 16px 0;

                &--metric {
                    font-size: 22px;
                    font-weight: 600;
                }
            }
        }
    }
}

.portfolio-app-usage-list {
    &--table-header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }
}
</style>
