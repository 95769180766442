/* eslint-disable no-empty-pattern */
import {
    isAdminByFlag,
    isSuperByRole,
    isOpsByUserFlag,
    setNullApplicationsForExtAppsToEmptyArray,
    isReadOnly
} from '@/utils/auth';
import router from '@/router';
import { updateAdoptV2UserState } from '@/utils/user-settings';
import get from 'lodash/get';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { http } from '@pendo/http';
import isUndefined from 'lodash/isUndefined';
import { initPermissions } from '@pendo/permissions';

export const USER_LOAD_STATUS = {
    NOT_STARTED: 0,
    PENDING: 1,
    COMPLETE: 2
};

export function getInitialState () {
    return {
        user: {},
        userLoadStatus: USER_LOAD_STATUS.NOT_STARTED,
        segmentFlags: [],
        segmentFlagsLoadedOrTimedOut: false,
        authenticated: false,
        hasLoginError: false,
        hasUpdateSettingsError: false
    };
}

export const state = getInitialState();

export const mutations = {
    setUserLoadStatus (state, { status }) {
        state.userLoadStatus = status;
    },
    setAuthenticated (state, { status }) {
        state.authenticated = status;
    },
    setUser (state, { user }) {
        state.user = user;
    },
    setUserSegmentFlags (state, { flags = [] }) {
        state.segmentFlags = flags;
    },
    setUserSegmentFlagsLoadedOrTimedOut (state, { loadedOrTimedOut }) {
        state.segmentFlagsLoadedOrTimedOut = loadedOrTimedOut;
    },
    setLoginError (state, { hasLoginError }) {
        state.hasLoginError = hasLoginError;
    },
    setUpdateSettingsError (state, { hasUpdateSettingsError }) {
        state.hasUpdateSettingsError = hasUpdateSettingsError;
    },
    reset (state) {
        Object.assign(state, {
            ...getInitialState(),
            segmentFlags: state.segmentFlags
        });
    }
};

export const actions = {
    async init ({ dispatch }) {
        await dispatch('getUser');
    },
    async login ({ commit, dispatch }, { email, password, postLogin = false, data = {} } = {}) {
        // Make sure we remove pre-existing localStorage sub/account/app ids prior to login
        window.localStorage.clear();

        commit('setUserLoadStatus', { status: USER_LOAD_STATUS.PENDING });

        try {
            commit('router/setShowFullscreenLoading', { shouldShow: true }, { root: true });
            if (!postLogin) {
                data = await _login(email, password);
            }
            const { subscriptions, subscriptionId, ...user } = data;
            // The condition below causes us to request user data again when we have logged in a v2 user
            if (isUndefined(subscriptions) && !isUndefined(subscriptionId)) {
                return dispatch('getUser', { isAdoptV2: true, isLogin: true });
            }
            commit('setUser', { user });
            commit('setAuthenticated', { status: true });
            commit('router/setShowFullscreenLoading', { shouldShow: false }, { root: true });
            await dispatch('hydrate', { subscriptions });

            // router uses userLoadStatus to determine if it can continue resolving the route
            // it should always be set AFTER hydration occurs, as those values are used to determine next route
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            commit('router/setShowFullscreenLoading', { shouldShow: false }, { root: true });
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
            commit('setAuthenticated', { status: false });
            commit('setLoginError', { hasLoginError: true });
        }
    },
    async logout ({ commit }, { postLogout = true } = {}) {
        window.localStorage.clear();

        if (postLogout) {
            await http.post('/logout');
        }

        // temp fix for now. https://pendo-io.atlassian.net/browse/APP-76945 will be the permanent fix
        if (!window.__pendoPermissions__) {
            // If not doing automtion testing, reload the page and send the user back to the login route
            return router.go({ name: 'login' });
        }

        const modulesToResetOnLogout = [
            'analytics',
            'apps',
            'designer',
            'filters',
            'resourceCenter',
            'guides',
            'guideAnalytics',
            'subscriptions',
            'users',
            'pages',
            'themes',
            'trackEvents',
            'layouts',
            'features',
            'reports',
            'workflows'
        ];

        modulesToResetOnLogout.forEach((moduleName) => {
            commit(`${moduleName}/reset`, null, {
                root: true
            });
        });

        // temp fix for now. https://pendo-io.atlassian.net/browse/APP-76945 will be the permanent fix
        const accessControl = {
            applicationAccessControl: [],
            subscriptionAccessControl: [],
            organizationAccessControl: []
        };
        window.__pendoPermissions__.$$overridePermissionsForTesting(accessControl);

        commit('reset');
    },
    async getUser ({ commit, dispatch, rootGetters }, { isAdoptV2, isLogin = false } = {}) {
        commit('setUserLoadStatus', { status: USER_LOAD_STATUS.PENDING });

        try {
            commit('router/setShowFullscreenLoading', { shouldShow: true }, { root: true });

            const { subscriptions: subs, ...user } = await _getUser(isAdoptV2);
            commit('apps/setUpdatingApp', { updating: false }, { root: true });

            // Digital adoption subs that have no extension applications return null instead of []
            const subscriptions = setNullApplicationsForExtAppsToEmptyArray(subs);
            commit('setUser', { user });
            commit('setAuthenticated', { status: true });

            const { activeSubId, applicationAccessControl = {}, subscriptionAccessControl = {} } = user;

            // Initialize Permissions
            initPermissions({ applicationAccessControl, subscriptionAccessControl });

            await dispatch('hydrate', { subscriptions, activeSubId });

            // temp fix until all subs are using multiapp view
            // remove as part of work in https://pendo-io.atlassian.net/browse/APP-77562
            if (!rootGetters['subscriptions/usesMultiApp'] && !isEmpty(subscriptions)) {
                await dispatch('workflows/fetch', {}, { root: true });
            }

            // router uses userLoadStatus to determine if it can continue resolving the route
            // it should always be set AFTER hydration occurs, as those values are used to determine next route
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
            commit('setAuthenticated', { status: false });
            commit('apps/setUpdatingApp', { updating: false }, { root: true });
            if (isLogin) {
                commit('setLoginError', { hasLoginError: true });
            }
        }

        commit('router/setShowFullscreenLoading', { shouldShow: false }, { root: true });

        if (window.Cypress) {
            window.authInitReady = true;
        }
    },
    async hydrate ({ commit, dispatch, rootGetters }, { subscriptions, activeSubId }) {
        if (isEmpty(subscriptions)) {
            return;
        }

        if (activeSubId === '') {
            try {
                activeSubId = subscriptions[0].id;
                const permissions = await updateAdoptV2UserState(activeSubId);
                const { applicationAccessControl, subscriptionAccessControl, flags } = permissions;
                const userWithUpdatedPermissions = {
                    ...state.user,
                    activeSubId,
                    applicationAccessControl,
                    subscriptionAccessControl,
                    flags
                };
                commit('setUser', { user: userWithUpdatedPermissions });
            } catch (error) {
                commit('subscriptions/setUpdateError', { message: error.message }, { root: true });
            }
        }

        await dispatch('subscriptions/hydrate', { subscriptions }, { root: true });

        const isAdoptV2 = rootGetters['subscriptions/subscriptionByIdUsesV2Adopt'](activeSubId);

        if (isAdoptV2) {
            const accountList = rootGetters['subscriptions/getAccountList']({ subscriptionId: activeSubId });
            const { id: accountId } = first(accountList);
            await dispatch(
                'subscriptions/updateActive',
                { subscriptionId: parseInt(activeSubId), accountId },
                { root: true }
            );
        }

        const isEmptyStateDigitalAdoption = rootGetters['subscriptions/isEmptyStateDigitalAdoption'];
        if (!isEmptyStateDigitalAdoption) {
            await dispatch('apps/hydrate', { subscriptions, activeSubId }, { root: true });
        }
    },
    async changePassword ({}, { oldPass, newPass, isAdoptV2Sub }) {
        await (isAdoptV2Sub ? _changeV2SubPassword(oldPass, newPass) : _changePassword(oldPass, newPass));
    },
    async updateEmail ({ commit, state }, { email }) {
        commit('setUpdateSettingsError', { hasUpdateSettingsError: false });
        try {
            const updatedUser = await _updateEmail({ id: state.user.id, email });
            commit('setUser', { user: updatedUser });
        } catch (error) {
            commit('setUpdateSettingsError', { hasUpdateSettingsError: true });
        }
    },
    async updateFirstName ({ commit, state, rootGetters, rootState }, { firstname }) {
        commit('setUpdateSettingsError', { hasUpdateSettingsError: false });
        try {
            const user = await _updateName(
                { id: state.user.id, firstname },
                rootGetters['subscriptions/activeUsesV2Adopt']
            );
            commit('setUser', { user });
            const listUser = Object.assign({}, rootState.users.map[user.id], { first: firstname });
            commit('users/setUpdate', { user: listUser }, { root: true });
        } catch (error) {
            commit('setUpdateSettingsError', { hasUpdateSettingsError: true });
        }
    },
    async updateLastName ({ commit, state, rootGetters, rootState }, { lastname }) {
        commit('setUpdateSettingsError', { hasUpdateSettingsError: false });
        try {
            const user = await _updateName(
                { id: state.user.id, lastname },
                rootGetters['subscriptions/activeUsesV2Adopt']
            );
            commit('setUser', { user });
            const listUser = Object.assign({}, rootState.users.map[user.id], { last: lastname });
            commit('users/setUpdate', { user: listUser }, { root: true });
        } catch (error) {
            commit('setUpdateSettingsError', { hasUpdateSettingsError: true });
        }
    },
    updateSegmentFlags ({ commit }, { flags }) {
        commit('setUserSegmentFlags', { flags });
        commit('setUserSegmentFlagsLoadedOrTimedOut', { loadedOrTimedOut: true });
    }
};

export const getters = {
    isAuthenticated (state) {
        return !isEmpty(state.user) && state.authenticated;
    },
    isLoading (state) {
        return state.userLoadStatus === USER_LOAD_STATUS.PENDING;
    },
    isLoaded (state) {
        return state.userLoadStatus === USER_LOAD_STATUS.COMPLETE;
    },
    areSegmentFlagsLoaded (state) {
        return state.segmentFlagsLoadedOrTimedOut;
    },
    isAdmin (state, getters, rootState, rootGetters) {
        if (rootGetters['subscriptions/activeUsesV2Adopt']) {
            return isAdminByFlag(state.user);
        }
        const activeAccountId = get(rootState, 'subscriptions.activeAccountId', null);

        const subscriptionAccounts = get(rootGetters['subscriptions/active'], 'accounts');

        if (!subscriptionAccounts) return false;

        // We do this instead of get, as the active account id may contain periods or other object-like
        // syntax, which `get` will interpret incorrectly
        if (!subscriptionAccounts[activeAccountId]) return false;

        return subscriptionAccounts[activeAccountId].isAdmin;
    },
    // state.user needs to be updated to include v2 user objects for this to work
    isSuper (state) {
        return isSuperByRole(state.user);
    },
    // state.user needs to be updated to include v2 user objects for this to work
    isOps (state) {
        return isOpsByUserFlag(state.user);
    },
    isPendoUser (state) {
        return state.user.email.includes('@pendo.io');
    },
    isImpersonating (state) {
        return state.user.isImpersonating;
    },
    hasSegmentFlag: (state) => (flag) => {
        return state.segmentFlags.includes(flag);
    },
    isReadOnly (state) {
        return isReadOnly(state.user);
    }
};

// helper functions
function _getUser (isAdoptV2 = false) {
    return http.get('/user/me', { headers: { 'x-adopt-v2': isAdoptV2 } }).then((res) => {
        const { headers } = res;
        if (!headers['content-type'].includes('application/json')) {
            throw new Error('/user/me failed to retrieve json');
        }

        return res.data;
    });
}

function _login (username, password) {
    return http.post('/login', { username, password }).then((res) => res.data);
}

function _updateEmail (settings) {
    return http.put('/api/user/_UID_/', settings).then((res) => res.data);
}

function _updateName (settings, isAdoptV2 = false) {
    if (!isAdoptV2) {
        return http.put('/api/user/_UID_/', settings).then((res) => res.data);
    }

    const params = {
        id: settings.id,
        ...(settings.firstname && { first: settings.firstname }),
        ...(settings.lastname && { last: settings.lastname })
    };

    return http.put('/api/s/_SID_/user/_UID_/', params).then(() => _getUser());
}

function _changePassword (oldPass, newPass) {
    return http.put('/api/user/_UID_/password', { oldPass, newPass });
}

function _changeV2SubPassword (oldPass, newPass) {
    return http.put('/api/s/_SID_/user/_UID_/password', { old: oldPass, new: newPass }).then((res) => res.data);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
