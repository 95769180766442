/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';
import { identifiedState, parseSegmentIdForAggregation, PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/utils/aggregations';

export async function getTrackEventList ({ appId, timeSeries, segmentId, signal }) {
    const spec = buildTrackEventListSpec({ appId, timeSeries, segmentId });

    return request(spec, { signal });
}

export function buildTrackEventListSpec ({ appId, timeSeries, segmentId }) {
    const { operators: o, common: c, generators: g } = Agg2;

    const params = {
        blacklist: 'apply',
        groupIds: null
    };

    return o.aggregation(
        {
            name: 'track-event-list',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.TRACK_EVENT_LIST_DETAILS
        },
        o.pipeline(
            o.sources.trackTypes({ appId }),
            o.evaluate({ trackTypeId: 'id' }),
            g.groupColumn({
                primaryKeys: ['trackTypeId'],
                type: 'trackType',
                groupIds: params.groupIds
            }),
            g.appColumn({ primaryKeys: ['appId'] }),
            o.filterGroups(params.groupIds),
            o.merge(
                ['trackTypeId'],
                o.mappings({
                    numVisitors: 'numVisitors',
                    numEvents: 'numEvents'
                }),
                o.pipeline(
                    o.sources.trackEvents({
                        timeSeries,
                        blacklist: params.blacklist,
                        appId
                    }),
                    o.identified(identifiedState(segmentId)),
                    o.segment(parseSegmentIdForAggregation(segmentId)),
                    o.group(
                        'trackTypeId',
                        ...(c
                            ?.groupFields({
                                numVisitors: o.count('visitorId'),
                                numEvents: o.sum('numEvents')
                            })
                            ?.filter((gf) => gf !== undefined) || {})
                    )
                )
            ),
            o.evaluate({
                numVisitors: 'if(isNil(numVisitors), 0, numVisitors)',
                numEvents: 'if(isNil(numEvents), 0, numEvents)'
            })
        )
    );
}
