<template>
    <div
        v-pendo-affix="{
            fixedStyles: {
                'left': 0,
                'right': 0,
                'padding': '0 0 0 80px',
                'z-index': 4
            }
        }"
        class="journeys-filter-bar">
        <pendo-filter-bar>
            <div class="journeys-filter-bar--top">
                <div class="journeys-filter-bar--top-filters">
                    <div class="journeys-filter-bar--top-filters-item">
                        <segment-chooser
                            full-width
                            class="journeys-filter-bar--segment-chooser"
                            data-cy="journeys-segment-filter"
                            :guide-targeting="false"
                            :disabled="false"
                            :value="activeSegmentId"
                            @input="onSegmentUpdated" />
                    </div>

                    <div class="journeys-filter-bar--top-filters-item">
                        <date-range-picker
                            full-width
                            class="journeys-filter-bar--date-range-picker"
                            data-cy="journeys-date-range-filter"
                            :first-visit="firstVisit"
                            :range-limiter="90"
                            :value="dateRange"
                            @change="onDateRangeUpdated" />
                    </div>
                </div>
            </div>
            <template #bottom>
                <div
                    v-if="!hasJourneyData"
                    class="journeys-filter-bar--bottom">
                    <pendo-multiselect
                        id="must-include-select"
                        v-model="includedStepsList"
                        multiple
                        :searchable="false"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :min-menu-width="300"
                        :max-menu-height="368"
                        :options="formattedPageFeatureOptions"
                        group-options-key="contents"
                        group-label-key="appName"
                        value-key="id">
                        <template #header="{ updateInputValue, inputValue }">
                            <div class="must-include-search-container">
                                <pendo-multiselect-input
                                    class="must-include-search"
                                    :input-value="inputValue"
                                    :is-search-icon-visible="true"
                                    computed-placeholder="Search Pages & Features..."
                                    :update-input-value="updateInputValue" />
                            </div>
                        </template>
                        <template
                            v-if="includedStepsList.length"
                            #beforeList>
                            <div class="selected-header">
                                <p class="must-include-header">
                                    Must include ({{ includedStepsList.length }})
                                </p>
                                <pendo-button
                                    type="link"
                                    label="Clear"
                                    size="mini"
                                    @click="clearIncludeSteps" />
                            </div>
                            <div class="selected-options">
                                <!-- Selected Options -->
                                <pendo-checkbox-option
                                    v-for="(option, idx) in includedStepsList"
                                    :key="idx"
                                    :option="option"
                                    @change="removeIncludedStep(option)" />
                            </div>
                            <pendo-divider height="12px" />
                        </template>
                        <template #trigger="{ toggleMenu, isOpen }">
                            <div
                                :class="[
                                    'must-include-trigger',
                                    isOpen ? 'must-include-open' : '',
                                    loading ? 'disabled-dropdown' : ''
                                ]"
                                @click="!loading ? toggleMenu() : null">
                                <pendo-icon
                                    class="must-star-icon"
                                    size="14"
                                    fill="white"
                                    :stroke="loading ? '#6A6C75' : '#2A2C35'"
                                    type="check-circle-2" />
                                <p class="must-include-text">
                                    Must include ({{ includedStepsList.length }})
                                </p>
                                <pendo-icon
                                    v-if="!isOpen"
                                    size="9"
                                    type="chevron-down" />
                                <pendo-icon
                                    v-if="isOpen"
                                    size="9"
                                    type="chevron-up" />
                            </div>
                        </template>
                        <template #option="{ option }">
                            <!-- Available Options -->
                            <pendo-checkbox-option :option="option" />
                        </template>
                    </pendo-multiselect>
                    <p class="filtered-content-dialogue">
                        {{ filteredPercentage }}
                    </p>
                </div>
            </template>
        </pendo-filter-bar>
    </div>
</template>

<script>
import {
    PendoAffix,
    PendoFilterBar,
    PendoMultiselect,
    PendoDivider,
    PendoCheckboxOption,
    PendoButton,
    PendoMultiselectInput,
    PendoIcon
} from '@pendo/components';
import DateRangePicker from '@/stateless-components/common/DateRangePicker';
import SegmentChooser from '@/components/segments/SegmentChooser';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';

export default {
    name: 'JourneysFilterBar',
    components: {
        DateRangePicker,
        PendoFilterBar,
        SegmentChooser,
        PendoMultiselect,
        PendoMultiselectInput,
        PendoIcon,
        PendoDivider,
        PendoButton,
        PendoCheckboxOption
    },
    directives: {
        PendoAffix
    },
    props: {
        filteredPercentage: {
            type: String,
            default: ''
        },
        appIds: {
            type: Array,
            default: () => []
        },
        hasJourneyData: {
            type: Boolean,
            default: false
        },
        terminalNodes: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            dateRange: (state) => state.filters.dateRange,
            includedSteps: (state) => state.workflows.includedSteps,
            workingWorkflow: (state) => state.workflows.workingWorkflow
        }),
        ...mapGetters({
            firstVisit: 'apps/firstVisit',
            pages: 'pages/list',
            features: 'features/list',
            appById: 'apps/appById',
            savedWorkflow: 'workflows/savedWorkflow'
        }),
        formattedPageFeatureOptions () {
            const appFeatureIds = this.appIds.map((appId) => {
                return {
                    id: appId,
                    appName: `${get(this.appById(appId), 'displayName')} Features`,
                    contents: this.features
                        .filter((feature) => feature.appId === appId && !this.isTerminalId(feature.id))
                        .map((feature) => {
                            return {
                                id: feature.id,
                                label: feature.displayName,
                                type: 'featureId'
                            };
                        })
                };
            });
            const appPageIds = this.appIds.map((appId) => {
                return {
                    id: appId,
                    appName: `${get(this.appById(appId), 'displayName')} Pages`,
                    contents: this.pages
                        .filter((page) => page.appId === appId && !this.isTerminalId(page.id))
                        .map((page) => {
                            return {
                                id: page.id,
                                label: page.displayName,
                                type: 'pageId'
                            };
                        })
                };
            });

            return appFeatureIds.concat(appPageIds).sort((a, b) => {
                if (a.appName < b.appName) {
                    return -1;
                }
                if (a.appName > b.appName) {
                    return 1;
                }

                return 0;
            });
        },
        includedStepsList: {
            get () {
                return Object.values(this.includedSteps);
            },
            set (steps) {
                const updatedSteps = steps.reduce((acc, step) => {
                    acc[step.id] = step;

                    return acc;
                }, {});
                this.setIncludedSteps({ updatedSteps });
            }
        }
    },
    methods: {
        ...mapActions({
            updateActiveSegmentId: 'filters/updateActiveSegmentId',
            updateDateRange: 'filters/updateDateRange',
            updateGlobalFilterDateRange: 'filters/updateDateRange',
            updateIncludedSteps: 'workflows/updateIncludedSteps',
            toggleIncludedStep: 'workflows/toggleIncludedStep'
        }),
        ...mapMutations({
            setIncludedSteps: 'workflows/setIncludedSteps'
        }),
        onDateRangeUpdated (event) {
            this.updateDateRange(event);
        },
        onSegmentUpdated (event) {
            this.updateActiveSegmentId(event);
        },
        clearIncludeSteps () {
            this.setIncludedSteps({ updatedSteps: {} });
        },
        isTerminalId (id) {
            return id === this.terminalNodes[0].countableId || id === this.terminalNodes[1].countableId;
        },
        removeIncludedStep (step) {
            // This is only called to remove a step from a previosly selected `Must include`
            this.toggleIncludedStep({ id: step.id });
        }
    }
};
</script>

<style lang="scss" scoped>
.journeys-filter-bar {
    z-index: 2;

    .pendo-filter-bar {
        padding: 0;
    }

    &.is-sticky .pendo-filter-bar {
        box-shadow: 0px 2px 6px $modal-box-shadow;
        border-radius: 0;
        border: 0;
    }

    &--top {
        align-items: center;
        display: flex;
        padding-left: 16px;
        width: 100%;

        &-filters {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            flex: 1 1 auto;

            &-item {
                margin-right: 1em;
                font-size: 0.875em;
                line-height: 1.25em;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &--bottom {
        min-height: 50px;
        max-height: 50px;
        display: flex;
        justify-content: space-between;
    }

    .must-include-trigger {
        display: flex;
        align-items: center;
        padding-top: 16px;
        margin-left: 20px;
        cursor: pointer;

        .must-include-text {
            margin-top: 0px;
            margin-left: 5px;
            margin-right: 5px;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .disabled-dropdown {
        color: $gray-lighter-2;
        cursor: default;
    }

    .must-include-open {
        border-bottom: 1px solid #016479;
    }
}

.selected-header {
    display: flex;
    align-items: center;
    padding-left: 16px;

    .must-include-header {
        margin-top: 0;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: $gray-lighter-2;
        text-transform: uppercase;
        letter-spacing: 1.5px;
    }
}

.selected-options {
    padding-left: 16px;
}

.filtered-content-dialogue {
    margin-right: 17px;
    margin-top: 16px;
    font-size: 14px;
}

.must-include-search {
    padding: 0 8px;
    height: 34px;
    border-bottom: 1px solid $gray-lighter-6;
    border-radius: 3px;
    justify-content: start;

    ::v-deep .pendo-multiselect__search-icon-wrapper {
        color: $gray-lighter-4;
    }
}
</style>
