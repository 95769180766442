<template>
    <div>
        <div :class="{ 'app-card-overlay': isOverlayActive }" />
        <div :class="{ 'active-card': isOverlayActive }">
            <slot name="card" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppCardOverlay',
    props: {
        isEditing: {
            type: Boolean,
            required: true
        },
        confirmationModalActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isOverlayActive () {
            return this.isEditing && !this.confirmationModalActive;
        }
    }
};
</script>

<style scoped lang="scss">
.app-card-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(white, 0.5);
    z-index: 1000;
    transition: all 150ms ease-in 0ms;
}

.active-card > * {
    z-index: 1001;
    position: relative;
    transition: all 150ms ease-in 0ms;
}
</style>
