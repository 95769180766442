<template>
    <div class="target-selectors">
        <div class="subscription-selector">
            <pendo-multiselect
                v-model="targetSubscription"
                full-width
                :labels="{ top: `Copy ${hasMultipleGuides ? 'Guides' : 'Guide'} to Subscription` }"
                :options="eligibleSubscriptions"
                placeholder="-- Choose Subscription --"
                label-key="displayName"
                @input="fetchApplications(targetSubscription)">
                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
            </pendo-multiselect>
        </div>
        <div class="application-selector">
            <pendo-multiselect
                v-model="targetApplication"
                full-width
                :loading="isLoadingApplications"
                :labels="{ top: 'to Application' }"
                :options="eligibleApplications"
                :disabled="!targetSubscription"
                placeholder="-- Choose Application --"
                label-key="displayName">
                <template #selectedLabel="{ option }">
                    <pendo-app-display :apps="option" />
                </template>
                <template #option="{ option }">
                    <pendo-app-display :apps="option" />
                </template>
            </pendo-multiselect>
        </div>
    </div>
</template>

<script>
import { PendoAppDisplay, PendoIconOption, PendoMultiselect } from '@pendo/components';

export default {
    name: 'SelectTarget',
    components: {
        PendoAppDisplay,
        PendoIconOption,
        PendoMultiselect
    },
    props: {
        priorSelectedTarget: {
            type: Object,
            default: undefined
        },
        hasMultipleGuides: {
            type: Boolean,
            default: true
        },
        activeIsDigitalAdoption: {
            type: Boolean,
            default: false
        },
        activeIsTrainingSubscription: {
            type: Boolean,
            default: false
        },
        eligibleSubscriptions: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            applications: [],
            targetSubscription: null,
            targetApplication: null,
            isLoadingApplications: false
        };
    },
    computed: {
        eligibleApplications () {
            const validApplications = this.applications.reduce((total, app) => {
                if (app.applicationFlags.disabled) return total;
                if (this.activeIsDigitalAdoption && app.platform !== 'extension') return total;
                if (this.activeIsTrainingSubscription && app.platform !== 'web') return total;
                total.push(app);

                return total;
            }, []);

            return validApplications;
        },
        disableApplicationDropdown () {
            return (this.targetSubscription && this.eligibleApplications.length === 1) || !this.targetSubscription;
        },
        targetsSelected () {
            return this.targetSubscription && this.targetApplication;
        }
    },
    watch: {
        eligibleApplications (validApplications) {
            if (validApplications.length === 1) this.targetApplication = validApplications[0];
        },
        targetsSelected (newState, oldState) {
            if (newState === oldState) return;
            const payload = {
                targetSub: this.targetSubscription,
                targetApp: this.targetApplication
            };
            this.$emit('targetsSelected', payload);
        }
    },
    created () {
        if (this.priorSelectedTarget) {
            this.targetSubscription = this.priorSelectedTarget.targetSub;
            this.fetchApplications(this.targetSubscription, false);
            this.targetApplication = this.priorSelectedTarget.targetApp;
        }
    },
    methods: {
        fetchApplications (sub, changed = true) {
            this.targetApplication = null;
            this.applications = [];

            if (!sub) return;

            this.isLoadingApplications = true;
            this.applications = this.eligibleSubscriptions.find(
                (subscription) => subscription.id === sub.id
            ).applications;
            this.isLoadingApplications = false;

            if (changed) this.$emit('targetSubChanged');
        },
        reset () {
            this.targetSubscription = null;
            this.targetApplication = null;
        }
    }
};
</script>

<style scoped lang="scss">
.target-selectors {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.copy-modal-title {
    min-width: 95px;
}

.modal-description {
    display: table;

    p {
        margin-top: 0;
    }

    span {
        display: table-cell;
        vertical-align: middle;
        white-space: nowrap;
    }
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
