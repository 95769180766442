<template>
    <accept-invite
        v-if="!isLoading"
        :brand-name="brandName">
        <template #logo>
            <slot name="logo" />
        </template>
    </accept-invite>
    <div v-else />
</template>
<script>
import { AcceptInvite } from '@pendo/login';
import { mapGetters, mapState } from 'vuex';
import get from 'lodash/get';

export default {
    components: {
        AcceptInvite
    },
    computed: {
        ...mapGetters({
            isLoading: 'auth/isLoading'
        }),
        ...mapState({
            unauthenticatedTrainingAttributes: (state) => state.subscriptions.unauthenticatedTrainingAttributes
        }),
        brandName () {
            return get(this.unauthenticatedTrainingAttributes, 'productName');
        }
    }
};
</script>
