<template>
    <pendo-drawer
        v-bind="{ size, title, visible }"
        close-on-mask-click
        @opened="bladeOpen = true"
        @close="onClose">
        <div
            v-if="loading"
            class="multiselect-blade__loading-state">
            <pendo-loading-indicator
                size="large"
                text="Loading..." />
        </div>
        <div
            v-if="!loading"
            class="multiselect-blade__body">
            <div class="multiselect-blade__options-list-controls">
                <div class="multiselect-blade__selection-top-filters">
                    <slot name="top-filters" />
                </div>
                <search
                    v-model="search"
                    class="multiselect-blade__search"
                    full-width
                    placeholder="Search applications, pages, or features" />
                <div class="multiselect-blade__selection-filters">
                    <slot name="filters" />
                </div>
                <div class="multiselect-blade__selection-controls">
                    <div class="selection-count">
                        <strong>{{ selectionCountLabel }}</strong> Selected
                    </div>
                    <pendo-button
                        theme="app"
                        type="link"
                        :disabled="!hasVisibleSelection"
                        label="Clear Selection"
                        @click="clearSelections" />
                    <pendo-button
                        theme="app"
                        type="secondary"
                        :disabled="hasAllSelected"
                        label="Select All"
                        @click="selectAll" />
                </div>
                <div class="multiselect-blade__notification">
                    <slot name="notification" />
                </div>
            </div>
            <div
                v-if="showEmptyState"
                class="multiselect-blade__empty-state">
                <p class="header">
                    {{ emptyStateStrings.header }}
                </p>
                <p>{{ emptyStateStrings.noSearchResultsMsg }}</p>
                <p>{{ emptyStateStrings.suggestion }}</p>
            </div>
            <recycle-scroller
                class="multiselect-blade__options-list"
                :items="listData"
                :buffer="200">
                <template #before>
                    <div
                        v-for="item in workflowStepsData"
                        :key="item.id">
                        <multiselect-blade-option
                            :icon="item.kind"
                            :type="item.type"
                            :label="item.name"
                            :sublabel="createOptionSublabel(item)"
                            :indeterminate="isOptionIndeterminate(item)"
                            :value="true"
                            :disabled="true"
                            :collapsed="collapsedData.groups[item.id]"
                            @groupCollapse="onGroupCollapse($event, item, true)" />
                    </div>
                </template>
                <template #default="{ item }">
                    <multiselect-blade-option
                        :icon="item.kind"
                        :type="item.type"
                        :label="item.name"
                        :item="item"
                        :sublabel="createOptionSublabel(item)"
                        :color="item.color"
                        :indeterminate="isOptionIndeterminate(item)"
                        :value="isOptionSelected(item)"
                        :disabled="isOptionDisabled(item)"
                        :collapsed="collapsedData.groups[item.id]"
                        @change="onOptionChange(item)"
                        @groupCollapse="onGroupCollapse($event, item)" />
                </template>
            </recycle-scroller>
        </div>
        <template #footer>
            <div class="multiselect-blade__footer">
                <pendo-button
                    theme="app"
                    type="secondary"
                    label="Cancel"
                    @click="onClose" />
                <pendo-button
                    theme="app"
                    type="primary"
                    label="Save"
                    :disabled="applyDisabled"
                    @click="onApplyClick" />
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { PendoButton, PendoDrawer, PendoLoadingIndicator } from '@pendo/components';
import { RecycleScroller } from 'vue-virtual-scroller';
import lowerCase from 'lodash/lowerCase';
import Search from '@/components/Search';
import MultiselectBladeOption from './multiselect-blade-option';
import {
    addOptionMetadata,
    addGroupMetadata,
    addDivider,
    filterGroups,
    filterOptions,
    flattenOptions,
    getEmptyStateStrings,
    sortData
} from './multiselect-blade.utils';

export default {
    name: 'MultiselectBlade',
    components: {
        PendoButton,
        PendoDrawer,
        PendoLoadingIndicator,
        Search,
        MultiselectBladeOption,
        RecycleScroller
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Array,
            default: () => []
        },
        filters: {
            type: Array,
            default: () => []
        },
        workflowSteps: {
            type: Array,
            default: () => []
        },
        max: {
            type: Number,
            default: Infinity
        },
        size: {
            type: String,
            default: 'medium'
        },
        title: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Option'
        },
        loading: {
            type: Boolean,
            default: false
        },
        applyDisabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            search: '',
            collapsedData: { options: {}, groups: {}, isCoreEventsCollapsed: false }
        };
    },
    computed: {
        ...mapGetters({
            getAppById: 'apps/appById'
        }),
        hasGroups () {
            return this.options.some((option) => option.targets);
        },
        hasVisibleSelection () {
            return this.visibleOptions.some(({ id }) => this.value[id]);
        },
        hasAllSelected () {
            if (this.maxSelected) return true;

            return this.visibleOptions.every(({ id }) => this.value[id]);
        },
        visibleOptions () {
            const optionMap = new Map();

            // Build a mapping of option.id => option for every item being shown
            // with the current search string while ignoring collapse states.
            this.listData.forEach((opt) => {
                if (opt.targets) {
                    const targetOptions = addOptionMetadata(opt.targets);
                    targetOptions.forEach((targetOption) => {
                        optionMap.set(targetOption.id, targetOption);
                    });
                }
                if (opt.type === 'option') {
                    optionMap.set(opt.id, opt);
                }
            });

            return Array.from(optionMap.values());
        },
        listData () {
            let options;

            if (this.hasGroups) {
                options = filterGroups(this.options, this.search, this.filters).sort(sortData('section'));
                options.forEach((group) => group.targets.sort(sortData('name')));
                options = flattenOptions(options);
                options = options.filter((option) => !this.collapsedData.options[option.id]);
            } else {
                options = filterOptions(this.options, this.search, this.filters).sort(sortData('name'));
                options = addOptionMetadata(options);
            }

            return options;
        },
        selectionCount () {
            return Object.values(this.value).filter(Boolean).length;
        },
        selectionCountLabel () {
            // Add 2 for the start and end page/feature
            return new Intl.NumberFormat().format(this.selectionCount + 2);
        },
        workflowStepsData () {
            if (this.workflowSteps.length) {
                const stepsGroup = {
                    id: 'workflowSteps',
                    name: 'workflowSteps',
                    section: 'Start and End Steps',
                    color: null,
                    targets: [...this.workflowSteps]
                };

                let [steps] = filterGroups([stepsGroup], this.search, this.filters);
                if (steps) {
                    steps = addGroupMetadata(steps, this.collapsedData.isCoreEventsCollapsed);
                    if (this.listData.length) {
                        steps = addDivider(steps);
                    }
                }

                return steps || [];
            }

            return [];
        },
        maxSelected () {
            return this.selectionCount >= this.max;
        },
        lowerCaseLabel () {
            return `${lowerCase(this.label)}s`;
        },
        noSearchResults () {
            return this.search.length && this.listData.length === 0 && this.coreEventsData.length === 0;
        },
        showEmptyState () {
            return (!this.listData.length && !this.workflowSteps.length) || this.noSearchResults;
        },
        emptyStateStrings () {
            return getEmptyStateStrings(!this.options.length, this.noSearchResults, this.lowerCaseLabel);
        }
    },
    watch: {
        filters (newVal, oldVal) {
            // prevent clearing input when blade is initially opened
            if (this.bladeOpen && newVal.length !== oldVal.length) this.$emit('input', []);
        },
        options () {
            this.collapsedData = { options: {}, groups: {}, isCoreEventsCollapsed: false };
        }
    },
    methods: {
        getIcon (item) {
            return item.kind;
        },
        createOptionSublabel (item) {
            if (item.type === 'option' && item.appId) {
                return this.getAppById(item.appId).displayName;
            }

            return '';
        },
        onOptionChange (option) {
            this.$emit('changeApplyDisabled', false);

            if (!this.isOptionDisabled(option)) {
                if (option.type === 'group') {
                    this.selectGroup(option);
                } else {
                    this.selectSingle(option);
                }
            }
        },
        onApplyClick () {
            this.reset();
            this.$emit('apply');
        },
        onClose () {
            this.reset();
            this.$emit('close');
        },
        reset () {
            this.search = '';
            this.$emit('changeApplyDisabled', true);
        },
        getGroupStatus (group) {
            if (group.targets.every(({ id }) => this.value[id])) {
                return { checked: true, indeterminate: false };
            }

            if (group.targets.some(({ id }) => this.value[id])) {
                return { checked: false, indeterminate: true };
            }

            return { checked: false, indeterminate: false };
        },
        isOptionSelected (option) {
            if (option.type === 'group') {
                return this.getGroupStatus(option).checked;
            }

            return !!this.value[option.id];
        },
        isOptionDisabled (option) {
            const basicCheck = !this.isOptionSelected(option) && this.maxSelected;

            return option.type === 'group' ? !this.getGroupStatus(option).indeterminate && basicCheck : basicCheck;
        },
        isOptionIndeterminate (option) {
            return option.type === 'group' && this.getGroupStatus(option).indeterminate;
        },
        clearSelections () {
            this.$emit('changeApplyDisabled', false);
            // We can't just emit "[]" because only selected items from the
            // results of the current search filter should be cleared.
            const idsToRemove = Object.fromEntries(this.visibleOptions.map(({ id }) => [id, false]));
            const newValue = Object.assign({}, this.value, idsToRemove);

            this.$emit('input', newValue);
        },
        selectAll () {
            this.$emit('changeApplyDisabled', false);
            if (this.maxSelected) return;

            // Keep any selections that are currently hidden by the search filter.
            const newValue = Object.assign({}, this.value);
            let selectionsRemaining = this.max - this.selectionCount;

            // Add each visible unselected item to the selection list until
            // we've hit the selection maximum.
            for (let i = 0; i < this.visibleOptions.length; i++) {
                const option = this.visibleOptions[i];

                if (!this.value[option.id]) {
                    newValue[option.id] = option;
                    selectionsRemaining--;
                }

                if (selectionsRemaining === 0) break;
            }

            this.$emit('input', newValue);
        },
        selectSingle (option) {
            if (this.isOptionSelected(option)) {
                this.removeSelection(option);
            } else if (!this.maxSelected) {
                this.$emit('input', Object.assign({}, this.value, { [option.id]: option }));
            }
        },
        selectGroup (group) {
            const allInGroupSelected = this.getGroupStatus(group).checked;
            if (allInGroupSelected || this.maxSelected) {
                if (group.targets.length > 1) {
                    this.removeGroupSelection(group);
                } else {
                    this.removeSelection(group.targets[0]);
                }
            } else {
                this.addGroupSelection(group);
            }
        },
        addGroupSelection (group) {
            const optionsToChange = {};
            let numSelectedItems = this.selectionCount;
            group.targets.forEach((option) => {
                if (numSelectedItems >= this.max) return;
                if (!this.isOptionSelected(option)) {
                    optionsToChange[option.id] = option;
                    numSelectedItems++;
                }
            });

            this.$emit('input', Object.assign({}, this.value, optionsToChange));
        },
        removeSelection (optionToRemove) {
            const newValue = Object.assign({}, this.value, { [optionToRemove.id]: false });

            this.$emit('input', newValue);
        },
        removeGroupSelection (groupToRemove) {
            const idsToRemove = Object.fromEntries(groupToRemove.targets.map(({ id }) => [id, false]));
            const newValue = Object.assign({}, this.value, idsToRemove);

            this.$emit('input', newValue);
        },
        onGroupCollapse (collapsed, group, isCoreEvents) {
            this.collapsedData.groups[group.id] = collapsed;
            if (isCoreEvents) {
                this.collapsedData.isCoreEventsCollapsed = collapsed;
            } else {
                group.targets.forEach((target) => {
                    this.$set(this.collapsedData.options, target.id, collapsed);
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~vue-virtual-scroller/dist/vue-virtual-scroller.css';

.multiselect-blade {
    &__body {
        grid-area: body;
        display: grid;
        grid-template-rows: min-content 1fr;
        overflow: hidden;
        border-bottom: 1px solid $gray-lighter-5;
    }

    &__options-list-controls {
        padding: 0 24px 16px;
        border-bottom: 1px solid $gray-lighter-5;
    }

    &__selection-top-filters {
        margin-bottom: 12px;
    }

    &__selection-filters {
        margin-top: 12px;
        display: flex;
        gap: 9px;
    }

    &__selection-controls {
        margin-top: 12px;
        display: flex;
        align-items: center;

        .selection-count {
            font-size: 14px;
            flex-grow: 1;
        }
    }

    &__loading-state {
        grid-area: body;
        display: grid;
        align-content: center;
        justify-content: center;
    }

    &__empty-state {
        padding: 16px 24px;

        p {
            margin-bottom: 0;
        }

        .header {
            font-weight: 700;
        }
    }

    &__footer {
        display: flex;
        justify-content: right;
    }
}
</style>
