// the vue single file component webpack code gets very confused with extra <script> tags
// if they exist inside a vue component file.
export const pendoIdentifySnippet = `<script>
window.__identifyPendoVisitor({
    visitor: {
        id: "VISITOR-UNIQUE-ID"     // Provide a unique ID for each visitor here
        // role: ""                 // Optional

        // You can provide any other visitor metadata you want as well.
    }
});
</script>`;
