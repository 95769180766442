import { http } from '@pendo/http';

export async function fetchTrackEvent (trackEventId) {
    return http
        .get(`/api/s/_SID_/tracktype/${trackEventId}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err.response;
        });
}

export function fetchAllTrackEventsForSubscription () {
    return http.get('/api/s/_SID_/tracktype?expand=*').then((res) => res.data);
}
