<template>
    <svg
        :x="x"
        :y="y"
        :width="width"
        :height="height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img">
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="32"
            height="32">
            <path
                d="M16 0L0 16H16V32L32 16V0H16Z"
                fill="black" />
        </mask>
        <g mask="url(#mask0)">
            <rect
                x="-12.5"
                y="28.7046"
                width="69.1438"
                height="7.65228"
                transform="rotate(-45 -12.5 28.7046)"
                fill="#DB1111" />
            <rect
                x="-1.67804"
                y="39.5265"
                width="69.1438"
                height="7.65228"
                transform="rotate(-45 -1.67804 39.5265)"
                fill="#00955B" />
            <rect
                x="-7.08899"
                y="34.1156"
                width="69.1438"
                height="7.65228"
                transform="rotate(-45 -7.08899 34.1156)"
                fill="#EFBE53" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'BlackHistoryMonthChevron',
    props: {
        x: {
            type: Number,
            default: undefined
        },
        y: {
            type: Number,
            default: undefined
        },
        width: {
            type: Number,
            default: 32
        },
        height: {
            type: Number,
            default: 32
        }
    }
};
</script>
