<template>
    <div class="mappings-table">
        <pendo-modal
            height="auto"
            max-height="85%"
            append-to-body
            :show-close="false"
            :visible.sync="showRemoveSchemaModal"
            :confirm-button-config="{ type: 'danger', label: 'Confirm', loading: removingSchema }"
            title="Remove Metadata?"
            type="confirmation"
            @confirm="onConfirmRemoveSchema"
            @cancel="onCancelRemoveSchema">
            <template v-if="showRemoveSchemaModal">
                <div class="remove-modal__description">
                    Are you sure you want to remove this data mapping? It will no longer be available within the Pendo
                    UI.
                </div>
                <div class="remove-modal__schema-label">
                    Removing:
                </div>
                <div class="remove-modal__schema-name">
                    {{ pendingRemoveSchema.DisplayName || pendingRemoveSchema.field }}
                </div>
            </template>
        </pendo-modal>
        <pendo-table
            :data="filteredSchemas"
            :columns="columns"
            row-key="uniqueKey"
            :auto-height="true"
            title="Metadata"
            :loading="loading"
            class="data-mappings-table">
            <template #group="{ row }">
                <pendo-tag
                    :label="row.groupDisplayName"
                    class="metadata-group-tag"
                    :class="`metadata-group-tag--${row.group}`"
                    :color="getTagHexColor(row.group)"
                    size="small" />
            </template>
            <template #field="{ row }">
                <div class="metadata-name">
                    <pendo-editable-content
                        v-if="!row.default && !row.custom && !isDynamicGroup(row)"
                        :value="row.DisplayName || row.field"
                        :input-props="{ size: 'small' }"
                        :before-exit="onDisplayNameChange.bind(null, row.field)"
                        empty-text="Add Custom Field"
                        @enterEditMode="currentlyEditingSchema[row.field] = row">
                        <template #append="{ confirm, cancel, pending }">
                            <pendo-button
                                :loading="pending"
                                theme="app"
                                icon="check"
                                size="small"
                                type="primary"
                                @click="confirm" />
                            <pendo-button
                                theme="app"
                                size="small"
                                icon="x"
                                type="secondary"
                                @click="cancel" />
                        </template>
                    </pendo-editable-content>
                    <pendo-input
                        v-if="row.custom"
                        v-model="custom.DisplayName"
                        size="small"
                        width="174px"
                        placeholder="Add Custom Field" />
                    <div
                        v-if="row.default || isDynamicGroup(row)"
                        class="metadata-name--default">
                        {{ row.field }}
                    </div>
                </div>
            </template>
            <template #type="{ row }">
                <div class="metadata-type">
                    <template v-if="!row.default && !isDynamicGroup(row)">
                        <pendo-multiselect
                            trigger-size="small"
                            :value="row.Type"
                            value-key="value"
                            :min-trigger-width="120"
                            :min-menu-width="140"
                            :options="row.group === 'custom' ? customTypes : types"
                            :loading="updatingMap[row.field]"
                            @select="onTypeChange($event, row)" />
                        <pendo-icon
                            v-if="row.Type.value === 'time'"
                            v-pendo-tooltip="{
                                arrow: true,
                                content: dateHelpText,
                                trigger: 'click',
                                placement: 'right',
                                html: true
                            }"
                            class="date-help-icon"
                            type="info"
                            size="14" />
                        <pendo-multiselect
                            v-if="row.Type.value === 'list'"
                            trigger-size="small"
                            :value="row.ElementType"
                            value-key="value"
                            placeholder="Select Type"
                            :min-trigger-width="120"
                            :min-menu-width="140"
                            :options="dateFormats"
                            :loading="updatingMap[row.field]"
                            @select="onElementTypeChange($event, row)" />
                    </template>
                    <div v-if="row.default || isDynamicGroup(row)">
                        {{ row.Type.label }}
                    </div>
                </div>
            </template>
            <template #csvKey="{ row }">
                <div class="csv-key">
                    <code v-if="row.csvKey">
                        {{ row.csvKey }}
                    </code>
                    <div
                        v-if="!row.csvKey"
                        class="no-key">
                        Field cannot be updated with csv upload
                    </div>
                </div>
            </template>
            <template #actions="{ row }">
                <pendo-button
                    v-if="row.custom"
                    size="small"
                    theme="app"
                    prefix-icon="plus"
                    :disabled="disableAdd"
                    :style="{ minWidth: 0 }"
                    label="Add"
                    :loading="isAddingCustomField"
                    @click="onAddCustomSchema" />
                <pendo-button
                    v-if="!row.default && !row.custom && !isDynamicGroup(row)"
                    size="small"
                    theme="app"
                    label="Remove"
                    @click="onRemoveSchema(row)" />
            </template>
            <template #empty>
                <div class="data-mappings-table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        No data found.
                    </span>
                </div>
            </template>
        </pendo-table>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ALL_TYPES, METADATA_GROUP_COLORS } from '@/utils/metadata.js';
import get from 'lodash/get';
import some from 'lodash/some';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';

import {
    PendoMultiselect,
    PendoIcon,
    PendoButton,
    PendoTable,
    PendoLoading,
    PendoInput,
    PendoModal,
    PendoTag,
    PendoEditableContent,
    PendoTooltip
} from '@pendo/components';
import { formatSampleMetadata } from '@/utils/mappings-table.js';

const DATE_HELP_TEXT = 'The date format is `ISO8601 W3C`. <br/> ex: 2006-01-02T15:04:05.999-05:00';

const DEFAULT_GROUPS = ['agent', 'custom'];

export default {
    name: 'MappingsTable',
    components: {
        PendoMultiselect,
        PendoIcon,
        PendoButton,
        PendoTable,
        PendoInput,
        PendoModal,
        PendoEditableContent,
        PendoTag
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        kind: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            // The property 'field' is required for the row to render but must be guaranteed unique
            // and not collide with any possible customer field name so its a generated uuid
            custom: {
                kind: this.kind,
                DisplayName: null,
                csvKey: '--',
                group: 'custom',
                groupDisplayName: 'Custom',
                field: '53f63e5b-8c46-403b-8005-b36fd05feb73',
                uniqueKey: '53f63e5b-8c46-403b-8005-b36fd05feb73custom',
                Type: {
                    label: 'Number (int)',
                    value: 'integer'
                },
                custom: true
            },
            currentlyEditingSchema: {},
            customTypes: ALL_TYPES.filter((type) => type.value !== 'list'),
            dateFormats: [
                {
                    label: 'Text',
                    value: 'string'
                },
                {
                    label: 'Number (int)',
                    value: 'integer'
                },
                {
                    label: 'Number (float)',
                    value: 'float'
                }
            ],
            dateHelpText: DATE_HELP_TEXT,
            elementFormats: [
                { value: 'w3c_iso8601', label: 'ISO8601 W3C, ex: 2006-01-02T15:04:05.999-05:00' },
                { value: 'seconds', label: '1136232245 (s)' },
                { value: 'milliseconds', label: '1136232245426 (ms)' },
                { value: 'format:Mon Jan 02 2006', label: 'Mon Jan 02 2006' },
                { value: 'format:01/02/2006 03:04pm', label: '01/02/2006 03:04pm' },
                { value: 'format:Mon Jan 02 2006 15:04:05 MST-0700', label: 'Mon Jan 02 2006 15:04:05 MST-0700' },
                { value: 'format:2006-01-02T15:04:05-0700', label: '2006-01-02T15:04:05-0700' },
                { value: 'format:2006-01-02T15:04:05-07:00', label: '2006-01-02T15:04:05-07:00' },
                { value: 'format:2006-01-02T15:04:05.999-07:00', label: '2006-01-02T15:04:05.999-0700' },
                { value: 'format:2006-01-02T15:04:05Z07:00', label: '2006-01-02T15:04:05Z07:00' },
                { value: 'format:2006-01-02T15:04:05.99Z', label: '2006-01-02T15:04:05.99Z' },
                { value: 'format:2006-01-02T15:04:05', label: '2006-01-02T15:04:05' },
                { value: 'format:2006-01-02 15:04:05 -0700', label: '2006-01-02 15:04:05 -0700' },
                { value: 'format:2006-01-02 15:04:05 MST', label: '2006-01-02 15:04:05 MST' },
                { value: 'format:2006-01-02 15:04:05', label: '2006-01-02 15:04:05' }
            ],
            isAddingCustomField: false,
            pendingRemoveSchema: null,
            pendingViaSchema: null,
            removeMetadataHelpDoc:
                'https://adoptpartners.pendo.io/hc/en-us/articles/13386180311067-Why-is-the-Remove-button-disabled-on-certain-metadata-fields-in-Pendo-Engage',
            showRemoveSchemaModal: false,
            types: ALL_TYPES,
            updatingMap: {}
        };
    },
    computed: {
        ...mapState({
            removingSchema: (state) => state.metadata.removing,
            schemas: (state) => state.metadata.map
        }),
        columns () {
            return [
                {
                    prop: 'group',
                    label: 'Group',
                    width: 200
                },
                {
                    prop: 'field',
                    label: 'Name',
                    width: 280,
                    showOverflowTooltip: false
                },
                {
                    prop: 'type',
                    label: 'Type',
                    width: 280,
                    showOverflowTooltip: false
                },
                {
                    prop: 'sample',
                    label: 'Sample'
                },
                {
                    prop: 'csvKey',
                    label: 'CSV Upload Column Key',
                    width: 280
                },
                {
                    type: 'actions',
                    width: 120
                }
            ];
        },
        disableAdd () {
            return !this.custom.DisplayName;
        },
        filteredSchemas () {
            const defaultSchemas = [
                {
                    kind: this.kind,
                    group: 'agent',
                    groupDisplayName: 'Agent',
                    groupTagColor: '#6a6c75',
                    Type: 'string',
                    field: `${this.kind} ID`,
                    csvKey: `${this.kind}Id`,
                    actions: 'required',
                    default: true
                }
            ];

            if (this.kind === 'visitor') {
                defaultSchemas.push({
                    kind: this.kind,
                    group: 'agent',
                    groupDisplayName: 'Agent',
                    field: 'Sample Group',
                    Type: 'integer',
                    actions: 'automatic',
                    default: true
                });
            }

            return [...defaultSchemas, ...this.schemasForKind]
                .map((field) => {
                    const isTime = field.Type === 'time';
                    const isList = field.Type === 'list';
                    const uniqueKey = field.field + field.group;

                    const schemaType = find(this.types, ['value', field.Type]);
                    const schema = {
                        ...field,
                        uniqueKey,
                        sample: formatSampleMetadata(field.sample),
                        Type: { ...schemaType }
                    };

                    if (isTime) {
                        schema.ElementFormat = find(this.elementFormats, ['value', field.ElementFormat]);
                    }

                    if (isList) {
                        schema.ElementType = find(this.dateFormats, ['value', field.ElementType]);
                    }

                    if (field.group === 'custom') {
                        const csvKey = schema.csvKey || field.key.split('.').pop();
                        schema.csvKey = csvKey;
                    }

                    return schema;
                })
                .concat(this.custom);
        },
        groupsByKind () {
            return get(this, `schemas[${this.kind}]`, []);
        },
        allowedGroups () {
            const blockList = ['auto', 'pendo'];
            const allowedGroups = Object.keys(this.groupsByKind).filter((group) => {
                return !blockList.includes(group);
            });

            return allowedGroups;
        },
        schemasForKind () {
            return Object.keys(this.groupsByKind)
                .filter((group) => this.allowedGroups.includes(group))
                .reduce((schemas, group) => {
                    Object.keys(this.groupsByKind[group]).forEach((field) => {
                        let transformedGroupDisplayName = group;
                        if (group.includes('pendo_')) {
                            transformedGroupDisplayName = `${group.split('_')[1]} integration`;
                        }
                        schemas.push({
                            ...this.groupsByKind[group][field],
                            group,
                            field,
                            kind: this.kind,
                            groupDisplayName: transformedGroupDisplayName,
                            key: `${this.kind}.${group}.${field}`
                        });
                    });

                    return schemas;
                }, []);
        }
    },
    methods: {
        ...mapActions({
            removeSchema: 'metadata/remove',
            updateSchema: 'metadata/update'
        }),
        flattenSchema (schema) {
            if (typeof schema.Type === 'object') {
                schema.Type = schema.Type.value;
            }

            if (typeof schema.ElementType === 'object') {
                schema.ElementType = schema.ElementType.value;
            }

            if (typeof schema.ElementFormat === 'object') {
                schema.ElementFormat = schema.ElementFormat.value;
            }

            return schema;
        },
        isDynamicGroup (row) {
            return !DEFAULT_GROUPS.includes(row.group);
        },
        onAddCustomSchema () {
            const schema = cloneDeep(this.custom);

            if (!schema.DisplayName) {
                return;
            }
            this.isAddingCustomField = true;
            schema.field = schema.DisplayName.replace(/[^a-zA-Z0-9_]/g, '');
            schema.groupDisplayName = 'custom';
            if (!schema.field) {
                // Ended up with an empty field name somehow
                schema.field = 'custom';
            }

            if (some(this.schemasForKind, { field: schema.field })) {
                // A field with the requested name already exists, so add some random garbage to the name
                schema.field += `_${new Date().getTime()}`;
            }

            if (schema.Type !== 'list') {
                delete schema.ElementType;
            }
            schema.uniqueKey = schema.field + schema.group;
            // Go add it to the backend...
            this.updateSchema({ schema: this.flattenSchema(schema) }).then(() => {
                this.isAddingCustomField = false;
                this.resetCustom();
            });
        },
        onDisplayNameChange (key, value) {
            return new Promise((resolve) => {
                const schema = {
                    ...this.currentlyEditingSchema[key],
                    DisplayName: value
                };
                this.updateSchema({ schema: this.flattenSchema(schema) }).then(() => resolve());
            });
        },
        onElementFormatChange ({ value }, schema) {
            if (schema.custom) {
                this.custom.ElementFormat = value;

                return;
            }

            this.update({ ...schema, ElementFormat: value });
        },
        onElementTypeChange ({ value }, schema) {
            if (schema.custom) {
                this.custom.ElementType = value;

                return;
            }
            this.update({ ...schema, ElementType: value });
        },
        onTypeChange ({ value, label }, schema) {
            if (value === 'time' && !schema.ElementFormat) {
                // set default value here that user can change later
                const defaultValue = {
                    value: 'w3c_iso8601',
                    label: 'ISO8601 W3C, ex: 2006-01-02T15:04:05.999-05:00'
                };
                schema.ElementFormat = { ...defaultValue };
                this.custom.ElementFormat = { ...defaultValue };
            }
            if (schema.custom) {
                this.custom.Type = {
                    value,
                    label
                };
                schema.Type = {
                    value,
                    label
                };

                return;
            }

            this.update({ ...schema, Type: value });
        },
        onRemoveSchema (schema) {
            this.pendingRemoveSchema = schema;
            this.showRemoveSchemaModal = true;
        },
        onCancelRemoveSchema () {
            this.showRemoveSchemaModal = false;
            this.pendingRemoveSchema = null;
        },
        onConfirmRemoveSchema () {
            const schema = this.pendingRemoveSchema;

            this.removeSchema({ schema }).then(() => {
                this.showRemoveSchemaModal = false;
                this.pendingRemoveSchema = null;
            });
        },
        resetCustom () {
            // The property 'field' is required for the row to render but must be guaranteed unique
            // and not collide with any possible customer field name so its a generated uuid
            this.custom = {
                kind: this.kind,
                DisplayName: null,
                csvKey: '--',
                group: 'custom',
                groupDisplayName: 'Custom',
                field: '53f63e5b-8c46-403b-8005-b36fd05feb73',
                uniqueKey: '53f63e5b-8c46-403b-8005-b36fd05feb73custom',
                Type: {
                    label: 'Number (int)',
                    value: 'integer'
                },
                custom: true
            };
        },
        update (schema) {
            this.updatingMap = {
                ...this.updatingMap,
                [schema.field]: true
            };

            if (schema.type === 'time' && schema.ElementFormat === '') {
                schema.ElementFormat = 'w3c_iso8601';
            }

            this.updateSchema({ schema: this.flattenSchema(schema) }).then(() => {
                this.updatingMap = {
                    ...this.updatingMap,
                    [schema.field]: false
                };
            });
        },
        isDateFormatSet (row) {
            return get(row, 'ElementFormat.label');
        },
        getTagHexColor (tagType) {
            return get(METADATA_GROUP_COLORS, tagType, '#babcc5');
        }
    }
};
</script>

<style lang="scss">
.data-mappings-table {
    .metadata-name {
        &--default {
            text-transform: capitalize;
        }
    }

    .pendo-editable-content {
        display: inline;
    }

    .metadata-type {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        align-items: center;
    }

    .csv-key {
        .no-key {
            color: $gray-lighter-3;
        }
    }
}

.date-help-icon {
    width: 14px;
}

.disable-via-popover-wrapper,
.remove-metadata-popover-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12px;
}

.disable-via-popover-wrapper {
    max-width: 140px;
}

.remove-metadata-popover-wrapper {
    max-width: 200px;
}

.v-popover.inline-popover {
    display: inline-block;
    vertical-align: middle;
}

.via-enabled-modal__description,
.remove-modal__description {
    margin-bottom: 16px;
}

.via-enabled-modal__schema-label,
.remove-modal__schema-label {
    font-weight: 700;
}
</style>
