<template>
    <div
        :class="{ loading }"
        class="via-rings">
        <span class="via-rings--outer-ring">
            <span class="via-rings--inner-ring">
                <span class="via-rings--icon">
                    <pendo-icon :type="icon" />
                </span>
            </span>
        </span>
    </div>
</template>

<script>
import { PendoIcon } from '@pendo/components';

export default {
    name: 'ViaRings',
    components: {
        PendoIcon
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'map'
        }
    }
};
</script>

<style lang="scss">
.via-rings {
    @mixin ring-shadow($spread, $opacity) {
        box-shadow: 0 0 0 $spread rgba(255, 255, 255, $opacity);
    }

    @keyframes vibrate-inner-shadow {
        0%,
        100% {
            @include ring-shadow(2px, 0.26);
        }

        50% {
            @include ring-shadow(4px, 0.61);
        }
    }

    @keyframes vibrate-outer-shadow {
        0%,
        100% {
            @include ring-shadow(2px, 0.1);
        }

        50% {
            @include ring-shadow(4px, 0.41);
        }
    }

    @keyframes vibrate-opacity {
        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: 0.6;
        }
    }

    .via-rings--outer-ring,
    .via-rings--inner-ring,
    .via-rings--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 2;
    }

    .via-rings--inner-ring {
        width: 160px;
        height: 160px;
        @include ring-shadow(3px, 0.26);
    }

    .via-rings--outer-ring {
        width: 200px;
        height: 200px;
        @include ring-shadow(3px, 0.1);
    }

    .via-rings--icon {
        height: 120px;
        width: 120px;
        background-color: $white;
        border: 1px solid $white;
        box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.17);

        svg {
            height: 52px;
            width: 52px;
            stroke-width: 1px;
        }
    }

    &.loading {
        .via-rings--outer-ring {
            animation: vibrate-outer-shadow 2.5s infinite;
        }

        .via-rings--inner-ring {
            animation: vibrate-inner-shadow 2.55s infinite 0.1s;
        }

        .via-rings--icon {
            animation: vibrate-opacity 2.6s infinite 0.2s;
        }
    }
}
</style>
