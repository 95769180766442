import * as Agg2 from '@pendo/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    identifiedState,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/utils/aggregations';

export async function getFeatureUsageOverTime ({ featureId, appId, timeSeries, metric, segmentId, signal }) {
    const spec = buildFeatureUsageOverTimeSpec({ featureId, appId, timeSeries, metric, segmentId });
    const { messages } = await request(spec, { rowsOnly: false, signal });

    return messages
        .map(({ time, rows }) => ({
            featureId,
            timestamp: time,
            value: rows[0].value
        }))
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
}

export function buildFeatureUsageOverTimeSpec ({ featureId, appId, timeSeries, metric, segmentId }) {
    const { operators: o } = Agg2;

    const getAggregatorFromMetric = (metric) => {
        switch (metric) {
            case 'view':
                return o.sum('numEvents');
            case 'visitor':
                return o.count('visitorId');
        }

        return o.count(null);
    };

    return o.aggregation(
        {
            name: 'feature-usage-over-time-adopt',
            productArea: PRODUCT_AREAS?.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS?.APPLICATION_USAGE
        },
        o.pipeline(
            o.sources.featureEvents({ featureId, appId: [appId], timeSeries }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.reduce({
                value: getAggregatorFromMetric(metric)
            })
        )
    );
}
