<template>
    <pendo-card>
        <template #body>
            <div class="empty-state">
                <div class="empty-state__body">
                    <img
                        class="empty-state__body--image"
                        src="@/img/empty-states/license-utilization-empty-state.svg"
                        alt="action icon">
                    <div class="empty-state__body--text">
                        <div class="empty-state__body--text__header">
                            {{ emptyStateHeader }}
                        </div>
                        <div class="empty-state__body--text__description">
                            {{ emptyStateDescription }}
                        </div>
                    </div>
                    <pendo-button
                        v-if="isAdmin"
                        label="Add License Info"
                        theme="app"
                        @click="$router.push({ name: licenseSettingsRoute })" />
                </div>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoButton } from '@pendo/components';

export default {
    name: 'EmptyState',
    components: {
        PendoCard,
        PendoButton
    },
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        },
        licenseSettingsRoute: {
            type: String,
            default: 'manageLicenses'
        }
    },
    data () {
        return {
            emptyStateHeader: 'No Licenses Found'
        };
    },
    computed: {
        emptyStateDescription () {
            if (this.isAdmin) {
                return 'To view license utilization metrics first add license information in Settings.';
            }

            return 'Contact your Adminstrator to add license information.';
        }
    }
};
</script>

<style lang="scss" scoped>
.empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 515px;

    ::v-deep &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 447px;

        &--image {
            width: 210px;
            height: 190px;
        }

        &--text {
            margin-bottom: 14px;
            text-align: center;

            &__header {
                font-size: 22px;
                line-height: 37px;
                font-weight: 700;
            }

            &__description {
                font-size: 16px;
                line-height: 27px;
                font-weight: 400;
            }
        }
    }
}
</style>
