<template>
    <page-header title="Settings">
        <template #actions>
            <div>
                <pendo-button
                    v-if="route.name === 'manageUsers'"
                    data-cy="invite-user"
                    theme="app"
                    type="primary"
                    prefix-icon="plus"
                    :label="requireSSO ? 'Add User' : 'Invite User'"
                    @click="openInviteUserModal" />
                <add-app-modal-wrapper
                    v-if="!isEmptyStateDigitalAdoption"
                    :show-add-app-button="showAddAppButton" />
            </div>
        </template>
    </page-header>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import AddAppModalWrapper from './app-settings/AddAppModalWrapper.vue';

export default {
    name: 'SettingsHeader',
    components: {
        PageHeader,
        PendoButton,
        AddAppModalWrapper
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption',
            requireSSO: 'subscriptions/requireSSO'
        }),
        showAddAppButton () {
            return (
                this.activeIsDigitalAdoption && this.route.name === 'applications' && !this.isEmptyStateDigitalAdoption
            );
        }
    },
    methods: {
        ...mapMutations({
            setUserOverlayVisible: 'users/setUserOverlayVisible'
        }),
        openInviteUserModal () {
            this.setUserOverlayVisible({ isVisible: true });
        }
    }
};
</script>
