<template>
    <div class="paths-dashboard">
        <div>
            <paths-header />
        </div>
        <div class="paths-dashboard--grid">
            <div class="paths-dashboard--row">
                <paths-content />
            </div>
        </div>
    </div>
</template>

<script>
import PathsContent from '@/components/paths/list/PathsContent';
import PathsHeader from '@/components/paths/list/PathsHeader';

export default {
    name: 'PathsDashboard',
    components: {
        PathsContent,
        PathsHeader
    }
};
</script>

<style lang="scss" scoped>
.paths-dashboard {
    .paths-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .paths-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
    }
}
</style>
