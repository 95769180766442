<template>
    <div>
        <div
            data-cy="all-languages"
            class="all-languages">
            <span
                v-for="(language, index) in sortLanguagesByName(languages.all)"
                :key="language.code">
                {{ index === languages.all.length - 1 ? language.name : `${language.name}, ` }}
            </span>
        </div>
        <div v-if="languages.added">
            <div
                v-for="language in sortLanguagesByName(languages.added)"
                :key="language.code"
                class="tagged-language">
                <pendo-tag
                    label="Added"
                    color="#009855" />
                {{ language.name }}
            </div>
        </div>
        <div v-if="languages.removed">
            <div
                v-for="language in sortLanguagesByName(languages.removed)"
                :key="language.code"
                class="tagged-language">
                <pendo-tag
                    label="Removed"
                    color="#BD0000" />
                {{ language.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { PendoTag } from '@pendo/components';
import { sortLanguagesByName } from '@/utils/localization';

export default {
    name: 'GuideAuditLocalizationDetails',
    components: {
        PendoTag
    },
    props: {
        languages: {
            type: Object,
            required: true
        }
    },
    methods: {
        sortLanguagesByName
    }
};
</script>

<style scoped>
.tagged-language {
    margin-top: 5px;
}

.all-languages {
    line-height: 30px;
}
</style>
