<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="app"
            class="app-settings-tabs">
            <template #appDetails>
                <app-details
                    :app="app"
                    :subscription="subscription" />
            </template>
            <template #rawEvents>
                <raw-events :app="app" />
            </template>
            <template #agentSettings>
                <environment-settings :app="app" />
            </template>
            <template #localizationSettings>
                <localization-settings :app="app" />
            </template>
            <template #installSettings>
                <install-settings
                    :app="app"
                    :subscription="subscription" />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { PendoPageContent, PendoTabs } from '@pendo/components';
import AppDetails from '@/components/settings/app-settings/AppDetails';
import EnvironmentSettings from '@/components/settings/env-settings/EnvironmentSettings';
import RawEvents from '@/components/settings/RawEvents';
import LocalizationSettings from './LocalizationSettings.vue';
import InstallSettings from './InstallSettings.vue';
import get from 'lodash/get';

export default {
    name: 'AppSettingsContent',
    components: {
        PendoPageContent,
        PendoTabs,
        AppDetails,
        RawEvents,
        LocalizationSettings,
        InstallSettings,
        EnvironmentSettings
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        subscription: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            activeHasSnippetWebApps: 'subscriptions/activeHasSnippetWebApps'
        }),
        activeTab: {
            get () {
                return this.route.name || 'appDetails';
            },
            set (tab) {
                this.$router.push({ name: tab }).catch(() => {});
            }
        },
        tabs () {
            const tabs = [
                {
                    prop: 'appDetails',
                    label: 'App Details',
                    lazy: true
                },
                {
                    prop: 'rawEvents',
                    label: 'Raw Events',
                    lazy: true
                },
                {
                    prop: 'localizationSettings',
                    label: 'Localization Settings',
                    lazy: true
                }
            ];

            const appPlatform = get(this.app, 'platform', '');
            if (this.activeHasSnippetWebApps && appPlatform === 'web') {
                const agentSettingsTab = {
                    prop: 'agentSettings',
                    label: 'Agent Settings',
                    lazy: true
                };
                tabs.splice(1, 0, agentSettingsTab);
                const installSettings = {
                    prop: 'installSettings',
                    label: 'Install Settings',
                    lazy: true
                };
                tabs.splice(3, 0, installSettings);
            }

            return tabs;
        }
    }
};
</script>
