<template>
    <page-header
        only-title
        :title="reportTitle"
        :loading="loading"
        :editable="editable"
        :validation-rules="reportNameRules"
        class="path-details__header"
        :before-exit="onNameChange"
        @confirm="onNameChange">
        <template #top>
            <div class="path-details-header__back-to-list">
                <router-link to="/paths">
                    <pendo-button
                        theme="app"
                        type="link"
                        size="mini"
                        prefix-icon="chevron-left"
                        label="Back to Paths List" />
                </router-link>
            </div>
        </template>
        <template #actions>
            <div
                v-if="isSavedReport"
                class="path-details-header__actions">
                <path-delete-action
                    v-if="canDelete"
                    :report="report" />
                <path-duplicate-action
                    :can-share="canShare"
                    :report="report" />
                <path-share-action
                    v-if="canShare || isOwnReport"
                    :report="report"
                    :can-share="canShare" />
            </div>
            <pendo-button
                v-if="isSavedReport"
                theme="app"
                type="primary"
                label="Save Changes"
                :disabled="!updatable"
                @click="$emit('update')" />
            <path-save-action
                v-if="!isSavedReport"
                :can-share="canShare"
                :disabled="!valid"
                @save="onSave" />
        </template>
    </page-header>
</template>

<script>
import { mapActions } from 'vuex';
import get from 'lodash/get';
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import PathSaveAction from '@/components/paths/PathSaveAction';
import PathDuplicateAction from '@/components/paths/PathDuplicateAction';
import PathShareAction from '@/components/paths/PathShareAction';
import PathDeleteAction from '@/components/paths/PathDeleteAction';

export default {
    name: 'PathDetailsHeader',
    components: {
        PageHeader,
        PendoButton,
        PathSaveAction,
        PathDuplicateAction,
        PathShareAction,
        PathDeleteAction
    },
    props: {
        report: {
            type: Object,
            default: undefined
        },
        valid: {
            type: Boolean,
            default: false
        },
        changed: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canShare: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isSavingName: false,
            reportNameRules: [
                {
                    max: 100,
                    trigger: ['blur', 'change']
                }
            ]
        };
    },
    computed: {
        isSavedReport () {
            return !!get(this.report, 'id');
        },
        updatable () {
            return this.canEdit && this.valid && this.changed;
        },
        reportTitle () {
            return get(this.report, 'name', 'New Path');
        }
    },
    methods: {
        ...mapActions({
            update: 'reports/update'
        }),
        async onNameChange (name) {
            if (name === this.report.name) {
                return;
            }

            await this.update({ report: { ...this.report, name } });
        },
        onSave (config) {
            this.$emit('save', config);
        }
    }
};
</script>
<style lang="scss" scoped>
.path-details-header__actions {
    display: grid;
    grid-gap: 8px;
    grid-auto-flow: column;
    margin-right: 8px;
}
</style>
