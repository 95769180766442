<template>
    <div class="view--workflows-list">
        <workflows-list-header />
        <workflows-list-content />
    </div>
</template>

<script>
import WorkflowsListContent from '@/components/workflows/list/WorkflowsListContent.vue';
import WorkflowsListHeader from '@/components/workflows/list/WorkflowsListHeader.vue';

export default {
    name: 'WorkflowsListView',
    components: {
        WorkflowsListContent,
        WorkflowsListHeader
    }
};
</script>
