<template>
    <div class="app-list">
        <template v-if="isEmptyStateDigitalAdoption">
            <empty-state />
        </template>
        <pendo-table
            v-else
            class="app-list-table"
            title="Applications"
            :data="sortedApps"
            :columns="columns"
            :filters="filters"
            auto-height>
            <template #headerActions>
                <search
                    v-model="searchInput"
                    placeholder="Search" />
            </template>
            <template #displayName="{ row }">
                <router-link
                    :to="`/settings/app/${row.id}/details`"
                    class="app-list-table--link">
                    <pendo-app-display :apps="row" />
                </router-link>
            </template>
            <template #label="{ row }">
                <pendo-tag-group>
                    <pendo-tag
                        v-for="tag in row.tags"
                        :key="tag.label"
                        v-bind="tag" />
                </pendo-tag-group>
            </template>
        </pendo-table>
    </div>
</template>

<script>
import { PendoTable, PendoAppDisplay, PendoTagGroup, PendoTag } from '@pendo/components';
import EmptyState from '@/components/settings/EmptyState';
import Search from '@/components/Search';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import { mapGetters } from 'vuex';

export default {
    name: 'AppList',
    components: {
        EmptyState,
        PendoTable,
        Search,
        PendoAppDisplay,
        PendoTagGroup,
        PendoTag
    },
    data () {
        return {
            searchInput: ''
        };
    },
    computed: {
        ...mapGetters({
            applications: 'apps/listAllForActiveSubscription',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption'
        }),
        sortedApps () {
            return sortBy(this.applicationsWithTags, [({ displayName }) => displayName.toLowerCase()]);
        },
        applicationsWithTags () {
            return this.applications.map((app) => ({ ...app, tags: this.getAppTags(app) }));
        },
        filters () {
            return [
                {
                    prop: ['displayName'],
                    value: this.searchInput
                }
            ];
        },
        columns () {
            const defaultColumns = [
                {
                    label: 'Name',
                    prop: 'displayName',
                    sortable: true,
                    visible: true
                },
                {
                    label: '',
                    prop: 'label',
                    visible: true,
                    width: 250
                }
            ];

            return defaultColumns;
        }
    },
    methods: {
        getAppTags (app) {
            const tags = [];

            const integrationTag = {
                label: app.integrated ? 'Integrated' : 'Not Integrated',
                type: app.integrated ? 'success' : 'info',
                size: 'small'
            };
            tags.push(integrationTag);

            const disabledTag = {
                label: 'Disabled',
                type: 'warning',
                size: 'small'
            };
            const extensionAppIsDisabled =
                app.platform === 'extension' && get(app, 'applicationFlags.disableExtensionInjection');
            const webAppIsDisabled = app.platform === 'web' && get(app, 'applicationFlags.disablePendo');
            if (extensionAppIsDisabled || webAppIsDisabled) {
                tags.push(disabledTag);
            }

            return tags;
        }
    }
};
</script>

<style lang="scss" scoped>
.pendo-tag-group {
    display: flex;
    justify-content: flex-end;
}
</style>
