import get from 'lodash/get';
import isObject from 'lodash/isObject';

export class TableUserSettings {
    constructor (componentInstance, bindings) {
        this.componentInstance = componentInstance;

        this.componentInstance.pendoTableUserSettingsInstance = this;
        this.updateTableSettings(bindings);
    }

    updateTableSettings (bindings) {
        const { userSettings } = bindings.value;

        this.currentUserSettings = userSettings;
    }

    getCurrentSettings () {
        return this.currentUserSettings;
    }

    async onSortChange ($event) {
        const { order, prop } = $event;

        const newSortSetting = {
            defaultSort: { order, prop }
        };

        await this.updateSavedTableSettings(newSortSetting);
    }

    async onColumnResize ($event) {
        const { column: prop, width } = $event;

        const savedSettings = this.getCurrentSettings();
        const columnSizes = get(savedSettings, 'columnSizes', {});

        const newColumnSizes = {
            ...columnSizes,
            [prop]: width
        };

        const partialSetting = { columnSizes: newColumnSizes };

        await this.updateSavedTableSettings(partialSetting);
    }

    async onColumnChange ($event) {
        const { columns } = $event;
        // Some settings save *visibleColumns* – an array of columns props
        // Others save *columns* – an array of column objects (particularly when product usage columns are included)
        const key = isObject(columns[0]) ? 'columns' : 'visibleColumns';
        const partialSetting = { [key]: columns };

        await this.updateSavedTableSettings(partialSetting);
    }

    generateSaveableUserSetting (partialSetting) {
        const savedUserSettings = this.getCurrentSettings();
        const newUserSetting = {
            ...savedUserSettings,
            ...partialSetting
        };

        return newUserSetting;
    }

    async updateSavedTableSettings (newPartialSettings) {
        const newSettings = this.generateSaveableUserSetting(newPartialSettings);
        this.componentInstance.$emit('tableSettingsUpdate', newSettings);
    }
}

export default {
    name: 'StatelessPendoTableUserSettingsWatcher',
    bind (el, binding, vnode) {
        const userSettingsInstance = new TableUserSettings(vnode.componentInstance, binding);

        vnode.componentInstance.$on('column-resize', userSettingsInstance.onColumnResize.bind(userSettingsInstance));
        vnode.componentInstance.$on('column-change', userSettingsInstance.onColumnChange.bind(userSettingsInstance));
        vnode.componentInstance.$on('sort-change', userSettingsInstance.onSortChange.bind(userSettingsInstance));
    },
    update (el, binding, vnode) {
        vnode.componentInstance.pendoTableUserSettingsInstance.updateTableSettings(binding);
    }
};
