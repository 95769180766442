<template>
    <div class="subscription-settings">
        <general-settings :subscription="subscription" />
        <extension-settings
            :subscription="subscription"
            :saving="!!savingSubSettings"
            @flag-change="handleUpdateSubscriptionFlag"
            @update-extension-attributes="handleUpdateExtensionAttributes" />
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoNotification } from '@pendo/components';
import { mapGetters, mapActions } from 'vuex';

import GeneralSettings from '@/components/settings/GeneralSettings';
import ExtensionSettings from '@/stateless-components/settings/ExtensionSettings';

export default {
    name: 'SubscriptionSettings',
    components: {
        GeneralSettings,
        ExtensionSettings
    },
    data () {
        return {
            savingSubSettings: false
        };
    },
    computed: {
        ...mapGetters({
            subscription: 'subscriptions/active',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption'
        })
    },
    methods: {
        ...mapActions({
            updateSubscriptionFlag: 'subscriptions/updateSubscriptionFlag',
            updateExtensionAttributes: 'subscriptions/updateExtensionAttributes'
        }),
        async handleUpdateSubscriptionFlag ({ flag, enabled }) {
            this.savingSubSettings = true;
            await this.updateSubscriptionFlag({ flag, enabled });
            this.savingSubSettings = false;
        },
        async handleUpdateExtensionAttributes ({ extensionAttributes }) {
            this.savingSubSettings = true;
            try {
                await this.updateExtensionAttributes({ extensionAttributes });
            } catch (err) {
                if (get(err, 'response.status') === 422) {
                    PendoNotification({
                        type: 'error',
                        title: 'Invalid Form Field!',
                        message: err.response.data,
                        duration: 7000
                    });
                } else {
                    PendoNotification({
                        type: 'error',
                        title: 'Unknown Error!',
                        message: err,
                        duration: 7000
                    });
                }

                // eslint-disable-next-line no-console
                console.error(err);
            }
            this.savingSubSettings = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.subscription-settings > * {
    &:not(:first-child) {
        margin-top: 32px;
    }
}
</style>
