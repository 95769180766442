<template>
    <pendo-card>
        <div
            class="path-list-empty-state--container"
            data-cy="path-list-empty-state--data">
            <div class="paths-list-empty-state">
                <img
                    class="path-list-empty-state--image"
                    src="@/img/empty-states/path-list-empty-state.svg">
                <p class="path-list-empty-state--title">
                    Create your first Path
                </p>
                <p class="path-list-empty-state--body">
                    Discover how users are navigating your apps by
                    <a
                        target="_blank"
                        href="https://adoptsupport.pendo.io/hc/en-us/articles/4408364979483-Paths-Overview">creating a new Path</a>
                </p>
            </div>
        </div>
    </pendo-card>
</template>
<script>
import { PendoCard } from '@pendo/components';

export default {
    name: 'PathListEmptyState',
    components: {
        PendoCard
    }
};
</script>

<style lang="scss" scoped>
.path-list-empty-state {
    &--container {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        margin: 56px auto;
        text-align: center;
        width: 300px;
    }

    &--image {
        margin: 0 25px;
        width: 250px;
    }

    &--title {
        font-size: 24px;
        color: $black;
        font-weight: 700;
        line-height: 120%;
        margin: 24px 0 12px;
    }

    &--body {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
    }
}
</style>
