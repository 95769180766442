<template>
    <div class="guide-alerts-page">
        <global-filters :show-segment-picker="false">
            <template #expansion>
                <pendo-guide-alerts-filter
                    :filter-config="filterConfig"
                    @update-user-settings="updateUserSettings"
                    @on-update-filters="onUpdateFilters" />
            </template>
        </global-filters>
        <pendo-guide-alerts-page
            ref="guideAlerts"
            :top-stats-config="topStatsConfig"
            :table-config="tableConfig"
            :error-data="errorData"
            :has-admin-access="isAdmin"
            :segments-map="segmentsMap"
            :guide-alerts-agg-status="guideAlertsAggStatus"
            :guide-seen-timeout="guideSeenTimeout"
            :is-saving-guide-seen-timeout="updatingApp"
            :active-user="user"
            :note-action="noteAction"
            :note-error="noteError"
            :guide-visitor-agg-status="guideVisitorAggStatus"
            :csv-agg-state="csvAggState"
            :impacted-users="impactedUsers"
            :filters="filters"
            product-type="adopt"
            @update-guide-seen-timeout="updateGuideSeenTimeout"
            @step-link-click="onStepLinkClick"
            @visitor-link-click="onVisitorLinkClick"
            @update-display-alert="handleUpdateAlert"
            @create-display-alert="handleCreateAlert"
            @update-user-settings="updateUserSettings"
            @table-data-change="tableDataChange"
            @fetch-impacted-users="createVisitorIdErrorsAgg$"
            @create-note="createNote"
            @remove-note="removeNote"
            @update-note="updateNote"
            @fetch-notes="fetchNotes" />
    </div>
</template>

<script>
import { PendoGuideAlertsPage, PendoNotification, PendoGuideAlertsFilter } from '@pendo/components';
import { connectAggregationToState, withComponent, connectAggregationToObservable } from '@pendo/agg-connect';
import { of } from 'rxjs';
import { GuideErrorsByStepAggregation, GuideErrorsVisitorIdsByStepAggregation } from '@pendo/services/GuideAlerts';
import { LOADING_STATES } from '@pendo/services/Constants';
import GlobalFilters from '@/components/filters/GlobalFilters';
import { mapGetters, mapState, mapActions } from 'vuex';
import {
    fetchDisplayAlerts,
    updateDisplayAlert,
    createDisplayAlert,
    fetchNotes,
    createNote,
    deleteNote,
    updateNote
} from '@/utils/guide-alerts';
import { getTimeSeries, iterativeToAggregativePeriod } from '@/utils/time-series';
import moment from '@/utils/moment';
import { launchMethodToString } from '@/utils/guides';
import { canAppCreateGuide, canAppEditGuide } from '@/utils/guide-permissions';
import { canCreateNote, canEditNote, canRemoveNote } from '@/utils/note-permissions';
import { encodeIdForUri } from '@/utils/utils';

export default {
    name: 'GuideAlertsWrapper',
    components: {
        PendoGuideAlertsPage,
        PendoGuideAlertsFilter,
        GlobalFilters
    },
    directives: {
        PendoNotification
    },
    data () {
        return {
            errorMap: {},
            blacklist: 'apply',
            displayAlerts: [],
            guideAlertsAggStatus: LOADING_STATES.LOADING,
            isSavingNote: false,
            noteAction: {},
            noteError: {},
            impactedUsers: [],
            csvAggState: {
                status: LOADING_STATES.RESOLVED,
                error: '',
                url: ''
            },
            guideVisitorAggStatus: LOADING_STATES.LOADING,
            guideVisitors: {},
            filters: []
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            dateRange: (state) => state.filters.dateRange,
            segmentsMap: (state) => state.filters.segmentsMap,
            updatingApp: (state) => state.apps.updatingApp,
            user: (state) => state.auth.user
        }),
        ...mapGetters({
            getCrossUISettingByName: 'userSettings/getCrossUISettingByName',
            getAppById: 'apps/appById',
            isAdmin: 'auth/isAdmin',
            guideSeenTimeout: 'apps/getGuideSeenTimeout'
        }),
        selectedAppIds () {
            return this.appIdsFilter;
        },
        timeSeries () {
            const getTimeSeriesData = getTimeSeries(this.dateRange);

            return {
                count: getTimeSeriesData.count,
                period: iterativeToAggregativePeriod(getTimeSeriesData.period),
                first: moment(this.dateRange.value.start).valueOf()
            };
        },
        tableConfig () {
            return {
                guideErrorsColumns: this.getCrossUISettingByName('guideErrorsColumns'),
                guideErrorsWidths: this.getCrossUISettingByName('guideErrorsWidths'),
                tableToggles: this.getCrossUISettingByName('displayAlertTableToggles')
            };
        },
        topStatsConfig () {
            return this.getCrossUISettingByName('displayAlertTopStats') || {};
        },
        filterConfig () {
            return this.getCrossUISettingByName('displayAlertExpansionFilters');
        },
        errorData () {
            return Object.values(this.errorMap);
        }
    },
    async created () {
        try {
            const alerts = await this.fetchDisplayAlerts();
            const alertsMap = alerts.reduce((map, alert) => {
                const { guideId, guideStepId, type } = alert.guideDisplayAlertSettings;
                map[`${guideId}|${guideStepId}|${type}`] = alert;

                return map;
            }, {});

            this.displayAlerts = alertsMap;
            this.setupGuideErrorsStream();
        } catch (error) {
            this.guideAlertsAggStatus = LOADING_STATES.REJECTED;
        }
    },
    methods: {
        ...mapActions({
            updateUserSettingByName: 'userSettings/updateUserSettingByName',
            updateCrossUINamespaceSetting: 'userSettings/updateCrossUINamespaceSetting',
            updateAttributesOnAllApps: 'apps/updateAttributesOnAllApps'
        }),
        createVisitorIdErrorsAgg$ (guideVisitors) {
            const sourcesOfState = {
                component: this,
                store: this.$store
            };
            this.guideVisitors = guideVisitors;

            return connectAggregationToState(
                GuideErrorsVisitorIdsByStepAggregation,
                withComponent({
                    blacklist: 'blacklist',
                    guideId: 'guideVisitors.guideId',
                    guideStepId: 'guideVisitors.guideStepId',
                    guideSeenReason: 'guideVisitors.guideShownReason',
                    timeSeries: 'timeSeries'
                })
            )(sourcesOfState).subscribe(({ status, value }) => {
                const { LOADING, RESOLVED, REJECTED, EMPTY } = LOADING_STATES;
                this.guideVisitorAggStatus = status;
                switch (status) {
                    case REJECTED:
                    case LOADING:
                        this.impactedUsers = [];
                        break;
                    case RESOLVED: {
                        const rows = value.data[value.periods[0]] || [];
                        this.impactedUsers = rows || [];
                        this.guideAlertsAggStatus = rows.length ? RESOLVED : EMPTY;
                        break;
                    }
                }
            });
        },
        downloadCsv () {
            const aggParams = {
                columns: [
                    {
                        type: 'link',
                        prop: 'visitorId',
                        label: 'Visitor ID',
                        sortable: true
                    }
                ],
                blacklist: this.blacklist,
                timeSeries: this.timeSeries,
                guideId: this.guideVisitors.guideId,
                guideStepId: this.guideVisitors.guideStepId,
                guideSeenReason: this.guideVisitors.guideShownReason,
                isCsv: true
            };
            const csv$ = connectAggregationToObservable(GuideErrorsVisitorIdsByStepAggregation, of(aggParams));
            csv$.subscribe(({ status, value, error }) => {
                this.csvAggState = {
                    status,
                    error,
                    url: value
                };
            });
        },
        setupGuideErrorsStream () {
            const sourcesOfState = {
                component: this,
                store: this.$store
            };
            const guideErrorsAgg$ = connectAggregationToState(
                GuideErrorsByStepAggregation,
                withComponent({
                    appId: 'selectedAppIds',
                    timeSeries: 'timeSeries',
                    blacklist: 'blacklist'
                })
            )(sourcesOfState);

            guideErrorsAgg$.subscribe(({ status, value, error }) => {
                const { LOADING, RESOLVED, REJECTED, EMPTY } = LOADING_STATES;
                switch (status) {
                    case LOADING:
                        this.guideAlertsAggStatus = LOADING;
                        break;
                    case RESOLVED: {
                        this.processErrors(value);
                        this.guideAlertsAggStatus = this.errorData.length === 0 ? EMPTY : RESOLVED;
                        break;
                    }
                    case REJECTED: {
                        this.guideAlertsAggStatus = REJECTED;
                        throw Error(error);
                    }
                }
            });
        },
        async fetchDisplayAlerts () {
            return fetchDisplayAlerts();
        },
        updateDisplayAlert (alertId, alert) {
            return updateDisplayAlert(alertId, alert);
        },
        async handleUpdateAlert ({ alert, activeAlert, isVisible }) {
            await this.updateDisplayAlert(activeAlert.alertId, alert)
                .then(({ data }) => {
                    activeAlert.lastUpdatedByUser = data.lastUpdatedByUser.username;
                    activeAlert.lastUpdatedAt = data.lastUpdatedAt;
                    this.updateTableRow(activeAlert, isVisible);
                    this.updateErrorMap(activeAlert);
                })
                .catch(() => {
                    this.informError(isVisible);
                });
        },
        async handleCreateAlert ({ alert, activeAlert, isVisible = false }) {
            await createDisplayAlert(alert)
                .then(({ data }) => {
                    activeAlert.alertId = data.rootVersionId;
                    activeAlert.hasAlertCreated = true;
                    activeAlert.lastUpdatedByUser = data.lastUpdatedByUser.username;
                    activeAlert.lastUpdatedAt = data.lastUpdatedAt;
                    this.updateTableRow(activeAlert, isVisible);
                    this.updateErrorMap(activeAlert);
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        return this.handleUpdateAlert(alert);
                    }
                    this.informError(isVisible);
                });
        },
        processErrors (errors) {
            this.errorMap = {};
            errors.forEach((er) => {
                const displayAlert = this.displayAlerts[`${er.guideId}|${er.impactedStepId}|${er.displayAlertType}`];
                er.app = this.getAppById(er.appId);
                er.launchMethodText = launchMethodToString(er.launchMethod);
                er.canChangeAlert = canAppCreateGuide(er.appId) || canAppEditGuide(er.appId);
                er.canCreateNote = canCreateNote(er.appId);
                er.canEditNote = canEditNote(er.appId);
                er.canRemoveNote = canRemoveNote(er.appId);
                er.childKey = `${er.impactedStepId}:${er.displayAlertType}`;
                er.hasAlertCreated = !!displayAlert;
                er.notes = [];
                if (displayAlert) {
                    const { status, rootVersionId, muted, lastUpdatedByUser, lastUpdatedAt } = displayAlert;
                    fetchNotes(rootVersionId).then((notes) => {
                        er.notes = notes;
                    });
                    er = {
                        ...er,
                        alertStatus: status,
                        alertId: rootVersionId,
                        muted,
                        lastUpdatedByUser: lastUpdatedByUser.username,
                        lastUpdatedAt
                    };
                    if (er.canChangeAlert && er.lastOccurrence > displayAlert.lastUpdatedAt) {
                        if (er.alertStatus === 'Resolved') {
                            er.alertStatus = 'NotReviewed';
                        }
                        const alert = {
                            status: er.alertStatus,
                            muted: er.muted,
                            appId: er.appId,
                            guideId: er.guideId,
                            guideStepId: er.impactedStepId,
                            guideDisplayAlertType: er.displayAlertType
                        };
                        this.updateDisplayAlert(er.alertId, alert);
                    }

                    return this.updateErrorMap(er);
                }
                er.alertId = undefined;
                er.alertStatus = 'NotReviewed';
                er.muted = false;

                this.updateErrorMap(er);
            });
        },
        onStepLinkClick (guideId) {
            this.$router.push(`/guides/${guideId}/settings`);
        },
        onVisitorLinkClick (visitorId) {
            const { href } = this.$router.resolve({
                name: 'visitorDetails',
                params: { visitorId: encodeIdForUri(visitorId) }
            });
            window.open(href, '_blank');
        },
        async updateUserSettings (settings) {
            await this.updateCrossUINamespaceSetting(settings);
        },
        updateTableRow (alert, closeModal) {
            this.$refs.guideAlerts.updateTableRow(alert, closeModal);
        },
        informError (closeModal) {
            this.$refs.guideAlerts.informError(closeModal);
        },
        tableDataChange ({ data }) {
            if (this.guideAlertsAggStatus !== LOADING_STATES.LOADING) {
                this.guideAlertsAggStatus = data.length ? LOADING_STATES.RESOLVED : LOADING_STATES.EMPTY;
            }
        },
        updateErrorMap (updatedAlert) {
            this.$set(this.errorMap, updatedAlert.childKey, updatedAlert);
        },
        async updateGuideSeenTimeout (timeout) {
            try {
                await this.updateAttributesOnAllApps({
                    updates: { guideSeenTimeoutLength: timeout }
                });
                this.$refs.guideAlerts.closeAlertSettings();
                PendoNotification({
                    type: 'success',
                    title: 'Timeout Threshold Updated',
                    message: 'The new threshold will determine any new Display Errors going forward.',
                    duration: 5000
                });
            } catch (error) {
                this.$refs.guideAlerts.closeAlertSettings();
                PendoNotification({
                    type: 'error',
                    title: 'Update Alert Settings Failed',
                    message: error,
                    duration: 5000
                });
            }
        },
        updateNoteAction (action, value) {
            this.$set(this.noteAction, action, value);
        },
        async createNote ({ alert, text }) {
            try {
                this.noteError.create = false;
                this.updateNoteAction('create', true);

                // This is for the rare case where the alert is pulled from the agg but it hasn't been created as a display alert
                if (!alert.alertId) {
                    const { alertStatus, muted, appId, guideId, impactedStepId, displayAlertType } = alert;
                    const displayAlert = {
                        status: alertStatus,
                        muted,
                        appId,
                        guideId,
                        guideStepId: impactedStepId,
                        guideDisplayAlertType: displayAlertType
                    };
                    await createDisplayAlert(displayAlert).then(({ data }) => {
                        alert.alertId = data.rootVersionId;
                        alert.hasAlertCreated = true;
                        this.updateErrorMap(alert);
                    });
                }

                await createNote(alert.alertId, text);
                await this.refreshNotesList({ alert });
            } catch (error) {
                this.noteError.create = true;
            } finally {
                this.updateNoteAction('create', false);
            }
        },
        async updateNote ({ alert, text, noteId }) {
            try {
                this.noteError.update = false;
                this.updateNoteAction('update', true);
                await updateNote(alert.alertId, noteId, text);
                await this.refreshNotesList({ alert });
            } catch (error) {
                this.noteError.update = true;
            } finally {
                this.updateNoteAction('update', false);
            }
        },
        async removeNote ({ alert, noteId }) {
            try {
                this.noteError.remove = false;
                this.updateNoteAction('remove', true);
                await deleteNote(alert.alertId, noteId);
                await this.refreshNotesList({ alert });
            } catch (error) {
                this.noteError.remove = true;
            } finally {
                this.updateNoteAction('remove', false);
            }
        },
        async fetchNotes ({ alert }) {
            try {
                this.noteError.fetch = false;
                this.updateNoteAction('fetch', true);
                await this.refreshNotesList({ alert });
            } catch (error) {
                this.noteError.fetch = true;
            } finally {
                this.updateNoteAction('fetch', false);
            }
        },
        async refreshNotesList ({ alert }) {
            await fetchNotes(alert.alertId).then((notes) => {
                alert.notes = notes;
            });
            // This is needed to keep the errorData in sync when the table is grouped/ungrouped
            this.updateErrorMap(alert);
        },
        onUpdateFilters (filters) {
            this.filters = filters;
        }
    }
};
</script>
<style lang="scss" scoped>
.guide-alerts-filter-bar,
::v-deep .filters {
    width: 100%;
}
</style>
