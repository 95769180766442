<script>
import { CHEVRONS, activeChevronMixin } from './ActiveChevronMixin';

export default {
    name: 'Chevron',
    components: {
        ...CHEVRONS
    },
    mixins: [activeChevronMixin],
    render (createElement) {
        return createElement(this.activeChevron);
    }
};
</script>
