<template>
    <no-active-subs
        product-type="adopt"
        @logout="logout"
        @navigateToProduct="navigateToEngage">
        <template #logo>
            <slot name="logo" />
        </template>
    </no-active-subs>
</template>

<script>
import { NoActiveSubs } from '@pendo/login';
import { mapActions } from 'vuex';

export default {
    name: 'NoActiveSubscriptions',
    components: {
        NoActiveSubs
    },
    methods: {
        ...mapActions({
            _logout: 'auth/logout'
        }),
        async logout () {
            try {
                await this._logout();
                this.$router.push({ name: 'login', query: this.route.query }).catch(() => {});
            } catch (err) {
                window.location.reload();
            }
        },
        navigateToEngage () {
            const { env } = window.__via_info__;
            if (env === 'prod') {
                window.location.href = 'https://app.pendo.io';
            } else if (env === 'prod-eu') {
                window.location.href = 'https://app.eu.pendo.io';
            } else if (env === 'prod-us1') {
                window.location.href = 'https://us1.app.pendo.io';
            } else if (env === 'prod-jp') {
                window.location.href = 'https://app.jpn.pendo.io';
            } else if (env === 'prod-hsbc') {
                window.location.href = 'https://app.hsbc.pendo.io';
            } else {
                window.location.href = `https://app.pendo-${env}.pendo-dev.com`;
            }
        }
    }
};
</script>
