import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import store from '@/state/store';
import { http, initializeHttpWrapper as initialize } from '@pendo/http';

const ALLOWED_ROUTES_WHEN_LOGGED_OUT = ['/user/me', '/login', '/api/register', '/logout'];
const getSubscriptionId = () => {
    return get(store, 'state.subscriptions.activeId', null);
};
const getSubscriptionName = () => {
    const subId = getSubscriptionId();

    return get(store, `state.subscriptions.map.${subId}.name`, null);
};
const getUserId = () => get(store, 'state.auth.user.id', null);
const logout = () => store.dispatch('auth/logout', { postLogout: false });

export function interceptRequest (config) {
    const configClone = cloneDeep(config);

    if (configClone.url.includes('/api/signup/user/resetpassword/')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    if (configClone.url.includes('/api/signup/user/create/')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    if (configClone.url.includes('/api/signup/user/pwresetrequest')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    if (configClone.url.includes('/api/signup/invitation/confirm/')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    if (configClone.url.includes('/login/resolveauth?username=')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    if (store.getters['subscriptions/activeUsesV2Adopt']) {
        configClone.headers['x-adopt-v2'] = true;
    }

    return configClone;
}

export function interceptError (error) {
    const { response, config } = error;

    if (!response) {
        return Promise.reject(error);
    }

    const isUnauthorized = response.status === 401;
    const isRouteToRetryWithV2Header = config.url.includes('/user/me') || config.url.includes('/logout');
    const tryAdoptV2 = isRouteToRetryWithV2Header && isUnauthorized && !get(config.headers, 'x-adopt-v2');
    if (tryAdoptV2) {
        const configClone = cloneDeep(config);
        configClone.headers['x-adopt-v2'] = true;

        return http(configClone);
    }

    return error;
}

/* Set up config and hooks for using @pendo/http wrapper:
    import {http} from '@pendo/http';
    http.get('https://app.pendo.io/api/_SID_/user/_UID_');
*/
export function initializeHttpWrapper () {
    initialize({
        forceOverride: true, // avoid race conditions from UI composites
        retryCount: 3,
        retryDelay: 500,
        allowedRoutes: ALLOWED_ROUTES_WHEN_LOGGED_OUT,
        logout,
        getUserId,
        getSubscriptionId,
        getSubscriptionName,
        interceptRequestExtensionHandler: interceptRequest,
        interceptErrorExtensionHandler: interceptError
    });
}

export function getSessionObject (domain) {
    return http(`${domain}/api/v1/sessions/me`, { method: 'GET', credentials: 'include' }).then((res) => res.data);
}
