<template>
    <div class="guide-details-poll-responses">
        <global-filters :show-app-picker="false" />
        <div class="guide-details-polls--content">
            <poll-responses-card
                :polls="polls"
                :focused-poll-id="focusedPollId"
                :guide="guide"
                :apps="apps"
                :visitor-counts-aggs="visitorCountsAggs"
                :response-counts-aggs="responseCountsAggs"
                :response-lists-aggs="responseListsAggs"
                :metadata="metadata"
                :timezone="timezone"
                :table-user-settings="pollResponseListUserSettings"
                @focusedPollIdNotFound="onFocusedPollIdNotFound"
                @tableSettingsUpdate="tableSettingsUpdate"
                @pollResponseMapSet="createPollResponseListsAgg$" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { connectAggregationToState, withStore, withComponent } from '@pendo/agg-connect';
import { GuideStepVisitorCount } from '@/aggregations/guide-step-visitor-count';
import { PollResponseCounts } from '@/aggregations/poll-response-counts';
import { PollResponseList } from '@/aggregations/poll-response-list';
import GlobalFilters from '@/components/filters/GlobalFilters';
import PollResponsesCard from '@/stateless-components/guides/polls/PollResponsesCard';
import get from 'lodash/get';

export default {
    name: 'GuideDetailsPollResponses',
    components: {
        GlobalFilters,
        PollResponsesCard
    },
    data () {
        return {
            visitorCountsAggs: null,
            responseCountsAggs: null,
            responseListsAggs: null,
            userSettingsName: 'pollResponses'
        };
    },
    computed: {
        ...mapGetters({
            guide: 'guides/active',
            polls: 'guides/activePolls',
            getAppById: 'apps/appById',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName',
            schemaList: 'filters/schemaList',
            segment: 'filters/activeSegment',
            timeSeries: 'filters/activeTimeSeries',
            timezone: 'subscriptions/getTimezone'
        }),
        appIds () {
            return get(this.guide, 'appIds', [this.guide.appId]);
        },
        apps () {
            return this.appIds.map((id) => this.getAppById(id));
        },
        focusedPollId () {
            return get(this.$route, 'query.pollId');
        },
        pollResponseListUserSettings () {
            return this.getTableUserSettingValueByName(this.userSettingsName);
        },
        metadata () {
            const analyticsMetadataGroups = ['agent', 'custom', 'auto'];
            const duplicateColumnProps = ['visitor.auto.id', 'visitor.auto.lastvisit'];

            return this.schemaList.filter((schema) => {
                return analyticsMetadataGroups.includes(schema.group) && !duplicateColumnProps.includes(schema.field);
            });
        }
    },
    created () {
        this.createGuideStepVisitorCountsAgg$();
        this.createPollResponseCountsAgg$();
    },
    methods: {
        ...mapActions({
            updateUserSettingByName: 'userSettings/updateUserSettingByName'
        }),
        onFocusedPollIdNotFound () {
            const query = Object.assign({}, this.$route.query);
            delete query.pollId;
            this.$router.replace({ query });
        },
        tableSettingsUpdate (newUserSettings) {
            this.updateUserSettingByName({
                settingsName: this.userSettingsName,
                value: newUserSettings
            });
        },
        createGuideStepVisitorCountsAgg$ () {
            this.visitorCountsAggs = this.guide.steps.reduce((map, step) => {
                const { pollIds } = step;

                if (pollIds?.length) {
                    map[step.id] = connectAggregationToState(
                        GuideStepVisitorCount(step.id),
                        withComponent({
                            appIds: 'appIds'
                        }),
                        withStore({
                            guideId: (state) => state.guides.activeId,
                            segmentId: (state) => state.filters.activeSegmentId,
                            timeSeries: (state, getters) => getters['filters/activeTimeSeries']
                        })
                    )({ component: this, store: this.$store });
                }

                return map;
            }, {});
        },
        createPollResponseCountsAgg$ () {
            this.responseCountsAggs = this.polls.reduce((map, poll) => {
                const { id: pollId } = poll;
                map[pollId] = connectAggregationToState(
                    PollResponseCounts(pollId),
                    withComponent({
                        appIds: 'appIds'
                    }),
                    withStore({
                        guideId: (state) => state.guides.activeId,
                        segmentId: (state) => state.filters.activeSegmentId,
                        timeSeries: (state, getters) => getters['filters/activeTimeSeries']
                    })
                )({ component: this, store: this.$store });

                return map;
            }, {});
        },
        createPollResponseListsAgg$ (pollResponseMap) {
            this.responseListsAggs = this.polls.reduce((map, poll) => {
                const { id: pollId, attributes: { type: pollType } = {} } = poll;
                const args = { pollId };
                if (pollType !== 'FreeForm' && pollType !== 'NumberScale') {
                    args.pollResponseMap = pollResponseMap[pollId];
                }

                map[pollId] = connectAggregationToState(
                    PollResponseList(args),
                    withComponent({
                        appIds: 'appIds'
                    }),
                    withStore({
                        guideId: (state) => state.guides.activeId,
                        segmentId: (state) => state.filters.activeSegmentId,
                        timeSeries: (state, getters) => getters['filters/activeTimeSeries']
                    })
                )({ component: this, store: this.$store });

                return map;
            }, {});
        }
    }
};
</script>
<style lang="scss">
.guide-details-polls--content {
    display: grid;
    grid-gap: 32px;
}
</style>
