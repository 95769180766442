<template>
    <pendo-tag
        :label="activeDateRangeLabel"
        type="filter" />
</template>

<script>
import { PendoTag } from '@pendo/components';

export default {
    name: 'ActiveDateRangeTag',
    components: {
        PendoTag
    },
    props: {
        activeDateRange: {
            type: Object,
            default: null
        }
    },
    computed: {
        activeDateRangeLabel () {
            return this.activeDateRange?.label;
        }
    }
};
</script>
