<template>
    <div class="view--guide-details">
        <template>
            <visitor-details-header :visitor-id="decodedVisitorId" />
            <visitor-details-content :visitor-id="decodedVisitorId" />
        </template>
    </div>
</template>

<script>
import { PendoLoading } from '@pendo/components';
import VisitorDetailsHeader from '@/components/analytics/visitors/VisitorDetailsHeader';
import VisitorDetailsContent from '@/components/analytics/visitors/VisitorDetailsContent';

export default {
    name: 'VisitorDetailsView',
    components: {
        VisitorDetailsHeader,
        VisitorDetailsContent
    },
    directives: {
        PendoLoading
    },
    props: {
        visitorId: {
            type: String,
            required: true
        }
    },
    computed: {
        decodedVisitorId () {
            return decodeURIComponent(this.visitorId);
        }
    }
};
</script>
