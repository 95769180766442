<template>
    <pendo-editable-content
        v-model="localValue"
        allow-empty
        type="number"
        :empty-text="emptyText"
        @enterEditMode="handleEnterEditMode"
        @confirm="onConfirm">
        <template
            slot="append"
            slot-scope="{ pending, confirm, cancel }">
            <pendo-button
                theme="app"
                icon="check"
                data-cy="confirm"
                :loading="pending"
                type="primary"
                @click="confirm" />
            <pendo-button
                data-cy="cancel"
                theme="app"
                icon="x"
                type="secondary"
                @click="cancel" />
        </template>
    </pendo-editable-content>
</template>

<script>
import { PendoEditableContent, PendoButton } from '@pendo/components';

export default {
    name: 'EditableNumberInput',
    components: {
        PendoEditableContent,
        PendoButton
    },
    props: {
        value: {
            type: [Number, String],
            default: null
        }
    },
    data () {
        return {
            localValue: null
        };
    },
    computed: {
        emptyText () {
            return this.localValue === 0 ? '0' : 'Click to Edit';
        }
    },
    created () {
        this.localValue = this.value;
    },
    methods: {
        onConfirm (value) {
            this.$emit('confirm', value);
        },
        handleEnterEditMode () {
            this.localValue = this.localValue || 0;
        }
    }
};
</script>
