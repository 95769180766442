<template>
    <div class="step-display">
        <span
            v-if="index || index === 0"
            class="step-display__number"> Step {{ index + 1 }} </span>
        <span
            v-if="name"
            class="step-display__name">{{ name }}</span>
        <pendo-app-display
            v-if="app"
            class="step-display__app"
            :apps="app" />
    </div>
</template>

<script>
import { PendoAppDisplay } from '@pendo/components';

export default {
    name: 'StepDisplay',
    components: {
        PendoAppDisplay
    },
    props: {
        index: {
            type: Number,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        app: {
            type: Object,
            default: null
        }
    }
};
</script>

<style lang="scss">
.step-display {
    display: flex;
    align-items: center;

    > * {
        white-space: nowrap;

        &:not(:first-child):before {
            content: ' – ';
            white-space: pre;
        }
    }
}
</style>
