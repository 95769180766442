<template>
    <div>
        <pendo-card
            class="guide-content"
            body-min-height="332px">
            <template #header>
                <h4 class="localization-header-title">
                    Content
                </h4>
                <language-preview-dropdown
                    v-if="isLocalizationEnabled && guide.id && !isCrossAppGuide"
                    :translation-states="guide.translationStates"
                    :authored-language="guide.authoredLanguage"
                    :is-loading="isLoadingGuidePreview"
                    :override-value="previewLanguage"
                    @languageSelected="handleLanguageSelection" />
                <div class="guide-content--actions">
                    <pendo-button
                        v-if="isCrossAppGuide"
                        type="link"
                        label="Edit"
                        data-cy="cross-app-edit-button"
                        prefix-icon="edit-2"
                        :disabled="!guideContentLoaded || !steps"
                        @click="openCrossAppGuideEditDrawer" />
                    <div
                        v-if="isCrossAppGuide"
                        class="divider" />
                    <pendo-button
                        type="link"
                        label="Download PDF"
                        prefix-icon="download"
                        :disabled="!(steps && steps.length)"
                        @click="downloadPDF" />
                    <div
                        v-if="!isMobileGuide"
                        class="divider" />
                    <preview-split-button
                        v-if="!isMobileGuide"
                        :disabled="!(steps && steps.length)"
                        @open-settings="showPreviewSettingsModal"
                        @preview="launchPreview" />
                    <div
                        v-if="canEditGuide"
                        class="divider" />
                    <div v-pendo-tooltip="getTooltipConfig">
                        <pendo-button
                            v-if="canEditGuide"
                            :disabled="disableManageInApp"
                            type="link"
                            label="Manage in my app"
                            prefix-icon="external-link"
                            @click="manageGuide()" />
                    </div>
                </div>
            </template>
            <template #body>
                <section
                    v-if="guide.id && guideContentLoaded"
                    class="guide-content--preview">
                    <pendo-empty-state
                        v-if="!steps.length"
                        description="Looks like this Guide doesn’t have any steps saved">
                        <template #icon>
                            <guide-preview-empty-state />
                        </template>
                        <template #actions>
                            <div v-pendo-tooltip="getTooltipConfig">
                                <pendo-button
                                    v-if="canEditGuide"
                                    :disabled="disableManageInApp"
                                    theme="app"
                                    type="secondary"
                                    label="Add step"
                                    prefix-icon="plus"
                                    @click="manageGuide()" />
                            </div>
                        </template>
                    </pendo-empty-state>
                    <step-preview-carousel
                        ref="stepPreviewCarousel"
                        :steps="steps"
                        :guide="guide"
                        :guides="guideList"
                        :watermark="watermark"
                        :resource-centers="draftResourceCenterList"
                        :is-mobile="isMobileGuide"
                        :has-guide-localization="isLocalizationEnabled"
                        :show-app="isCrossAppGuide"
                        :preview-language="previewLanguage">
                        <template #overlay="slotProps">
                            <aside
                                v-if="canEditGuide"
                                class="slide-overlay step-overlay">
                                <div v-pendo-tooltip="getTooltipConfig">
                                    <pendo-button
                                        :disabled="disableManageInApp"
                                        type="primary"
                                        theme="app"
                                        label="Manage In App"
                                        data-cy="edit-step-button"
                                        @click="manageGuide(slotProps.step)" />
                                </div>
                            </aside>
                        </template>
                    </step-preview-carousel>
                </section>
            </template>
        </pendo-card>
        <cross-app-guide-drawer
            v-if="guideContentLoaded"
            :visible="showCrossAppGuideDrawer"
            :guide-to-edit="guide"
            :all-guides="guideList"
            :apps-map="appsMap"
            :app-urls-map="appUrlsMap"
            :support-article-url="crossAppSupportArticleUrl"
            :error-on-update="errorOnUpdateCrossAppGuide"
            :error-on-update-guide-statuses="errorOnUpdateGuideStatusesForCrossApp"
            @update="updateCrossAppGuide"
            @close="closeCrossAppGuideEditDrawer" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import {
    PendoButton,
    PendoCard,
    PendoLoading,
    PendoNotification,
    PendoEmptyState,
    PendoTooltip
} from '@pendo/components';
import PreviewSplitButton from '@/stateless-components/common/PreviewSplitButton';
import GuidePreviewEmptyState from '@/stateless-components/svgs/GuidePreviewEmptyState';
import StepPreviewCarousel from '@/stateless-components/guides/common/StepPreviewCarousel';
import LanguagePreviewDropdown from './localization/LanguagePreviewDropdown';
import { getTranslatedGuideSteps, isTranslated } from '@/utils/localization.js';
import {
    getBuildingBlocks,
    updateCrossAppGuide,
    updateGuideStatusesForCrossApp
} from '@/stateless-components/utils/guides';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import { GuideToPDF } from '@pendo/services/BuildingBlocks';
import { exportPDF } from '@/utils/pdfExport.js';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import { addAppToEntityList } from '@/utils/apps';
import CrossAppGuideDrawer from '@/stateless-components/guides/cross-app/CrossAppGuideDrawer';
import { CROSS_APP_SUPPORT_ARTICLE_URL } from '@/constants/urls';
import { canEditGuide, canPublishGuide } from '@/utils/guide-permissions';
import { isMobile } from '@/stateless-components/utils/apps';
import { launchPreview } from '@/stateless-components/utils/preview';

export default {
    name: 'GuideDetailsContentPreview',
    components: {
        GuidePreviewEmptyState,
        PendoButton,
        PendoCard,
        PendoEmptyState,
        StepPreviewCarousel,
        LanguagePreviewDropdown,
        CrossAppGuideDrawer,
        PreviewSplitButton
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        isLocalizationEnabled: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            isLoadingGuidePreview: false,
            guideContentLoaded: false,
            translatedSteps: null,
            showCrossAppGuideDrawer: false,
            crossAppSupportArticleUrl: CROSS_APP_SUPPORT_ARTICLE_URL,
            errorOnUpdateGuideStatusesForCrossApp: null,
            errorOnUpdateCrossAppGuide: null,
            translatedContent: {}
        };
    },
    computed: {
        ...mapState({
            subId: (state) => get(state, 'subscriptions.activeId'),
            appUrlsMap: (state) => state.designer.lastAppUrlMap,
            designerUrl: (state) => state.designer.url,
            canPreviewPublicGuide: (state) => state.guides.canPreviewPublicGuide,
            canPreviewNonPublicGuide: (state) => state.guides.canPreviewNonPublicGuide,
            previewLanguage: (state) => state.guides.previewLanguage
        }),
        ...mapGetters({
            apps: 'apps/appMapForActiveSubscription',
            getAppFromGuide: 'apps/appFromGuide',
            lastUrlByAppId: 'designer/lastUrlByAppId',
            watermark: 'guides/watermark',
            guide: 'guides/active',
            guideList: 'guides/list',
            activeGuidePreviewConfig: 'guides/activeGuidePreviewConfig',
            draftResourceCenterList: 'resourceCenter/draftResourceCenterList',
            productName: 'subscriptions/productName',
            appWhiteLabelSettings: 'apps/whiteLabelSettings',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt'
        }),
        appsMap () {
            return keyBy(this.apps, 'id');
        },
        launchUrl () {
            return this.lastUrlByAppId(this.guide.appId) || this.designerUrl;
        },
        guideApp () {
            return this.getAppFromGuide(this.guide);
        },
        canPreview () {
            if (!get(this.steps, 'length')) return false;

            return this.guide.state === 'public' ? this.canPreviewPublicGuide : this.canPreviewNonPublicGuide;
        },
        hasTranslations () {
            return values(this.guide.translationStates).some(({ state }) => isTranslated(state));
        },
        steps () {
            return this.translatedSteps || addAppToEntityList(this.guide.steps, this.apps);
        },
        isCrossAppGuide () {
            return isCrossApp(this.guide);
        },
        canChangeStatus () {
            return (
                canPublishGuide(this.guide) || canEditGuide({ guide: this.guide, field: 'status' }, { target: 'ANY' })
            );
        },
        disableManageInApp () {
            return !this.canEditGuideContent || this.disablePublicGuideEdit;
        },
        canEditGuide () {
            return this.canEditGuideContent || this.canEditGuideIfNotPublic;
        },
        canEditGuideContent () {
            return canEditGuide({ guide: this.guide, field: 'content' });
        },
        canEditGuideIfNotPublic () {
            return (
                this.guide.state === 'public' &&
                canEditGuide({ guide: this.guide, field: 'content' }, { source: 'draft', target: 'draft' })
            );
        },
        disablePublicGuideEdit () {
            return !this.canEditGuideContent && this.guide.state === 'public';
        },
        getTooltipConfig () {
            const changeStatus = this.canChangeStatus ? 'Please change the guide status or' : 'Please';

            if (this.disablePublicGuideEdit) {
                return {
                    arrow: true,
                    multiline: true,
                    trigger: 'hover',
                    content: `You do not have the right permissions to edit PUBLIC guides. ${changeStatus} reach out to your Admin for the relevant permissions.`
                };
            }

            return '';
        },
        isMobileGuide () {
            return isMobile(this.guideApp);
        }
    },
    watch: {
        guide: {
            async handler () {
                if (this.guide.id) {
                    // await this to ensure `unwatch` is defined by the time we get there
                    await this.loadGuideContent();
                    this.guideContentLoaded = true;
                }
            },
            immediate: true
        }
    },
    created () {
        this.setPreviewLanguage({ previewLanguage: this.guide.authoredLanguage });
    },
    methods: {
        ...mapActions({
            fetchGuideContent: 'guides/getBuildingBlocks',
            updateGuide: 'guides/update',
            updatePreviewLanguage: 'guides/updatePreviewLanguage'
        }),
        ...mapMutations({
            setPreviewLanguage: 'guides/setPreviewLanguage',
            setUpdate: 'guides/setUpdate'
        }),
        async updateCrossAppGuide ({ guide, rows, urls, prevUrls }) {
            try {
                await updateGuideStatusesForCrossApp(rows, this.setUpdate);
            } catch (err) {
                this.errorOnUpdateGuideStatusesForCrossApp = err;
            }

            try {
                await updateCrossAppGuide({ guide, rows, urls, prevUrls });
                await this.updateGuide({ guide, setActive: true });
                this.closeCrossAppGuideEditDrawer();
            } catch (err) {
                this.errorOnUpdateCrossAppGuide = err;
            }
        },
        stepDomainPatterns (step) {
            if (!step.app) return null;

            const stepDomains =
                step.app.extensionDomainPatterns && step.app.extensionDomainPatterns.length > 0
                    ? [...step.app.extensionDomainPatterns]
                    : [step.app.extensionDomainPatterns];

            return stepDomains;
        },
        async launchPreview () {
            if (this.launchUrl.trim().length <= 0) {
                this.showPreviewSettingsModal();

                return;
            }

            await launchPreview(this.launchUrl, this.activeGuidePreviewConfig).catch((err) => {
                PendoNotification({
                    duration: 5000,
                    type: 'error',
                    title: 'Something went wrong',
                    message: `There was a problem launching this guide preview: ${err.message}.`
                });
            });
        },
        showPreviewSettingsModal () {
            if (!this.canPreview) return;

            this.$modal.show('via-modal', {
                title: 'Preview options',
                component: 'LaunchPreviewModal',
                componentProps: {
                    guide: this.guide,
                    launchUrl: this.launchUrl,
                    showAdvancedSettings: true,
                    previewConfig: {
                        ...this.activeGuidePreviewConfig,
                        language: this.previewLanguage
                    }
                }
            });
        },
        manageGuide (step) {
            if (!step && this.guide.steps.length > 0) {
                return this.$emit('handleManageGuide', {
                    type: 'guide',
                    step: this.guide.steps[0]
                });
            }

            return this.$emit('handleManageGuide', {
                type: 'guide',
                step
            });
        },
        loadGuideContent () {
            const promises = [];
            promises.push(this.fetchGuideContent({ guide: this.guide }));
            if (this.watermark) {
                promises.push(this.fetchGuideContent({ guide: this.watermark }));
            }

            return Promise.all(promises);
        },
        async handleLanguageSelection (selectedLanguage, selectedLabel) {
            if (this.previewLanguage === selectedLanguage) return;

            let previewLanguage = selectedLanguage;
            const previousLanguage = this.previewLanguage;
            this.translatedSteps = null;
            this.isLoadingGuidePreview = true;

            if (selectedLanguage !== this.guide.authoredLanguage) {
                try {
                    this.translatedSteps = await this.getTranslatedSteps(this.guide.id, selectedLanguage);
                } catch (err) {
                    PendoNotification({
                        duration: 5000,
                        type: 'error',
                        title: 'Something went wrong',
                        message: `There was a problem retrieving translated content for ${selectedLabel}.`
                    });
                    previewLanguage = previousLanguage;
                }
            }

            this.setPreviewLanguage({ previewLanguage });
            this.isLoadingGuidePreview = false;
        },
        async getTranslatedSteps (guideId, selectedLanguage) {
            if (!guideId) throw new Error('guideId is required');
            if (!selectedLanguage) throw new Error('selectedLanguage is required');

            const stepsTranslations = await getTranslatedGuideSteps(guideId, selectedLanguage);
            const stepsContent = stepsTranslations.map(async (step) => {
                const stepContent = await getBuildingBlocks(step);

                return Object.assign(step, stepContent);
            });

            return Promise.all(stepsContent);
        },
        downloadPDF () {
            const preparedDOM = GuideToPDF.guideToPDF(
                this.$refs.stepPreviewCarousel.getGuidePreview(),
                get(this.guide, 'name')
            );
            exportPDF(preparedDOM, get(this.guide, 'name'));
        },
        openCrossAppGuideEditDrawer () {
            this.showCrossAppGuideDrawer = true;
        },
        closeCrossAppGuideEditDrawer () {
            this.showCrossAppGuideDrawer = false;
        }
    }
};
</script>

<style lang="scss">
.localization-header-title {
    font-weight: 500;
}

.guide-content {
    &--preview {
        position: relative;
        @include carousel;
    }

    &--actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .divider {
            height: 22px;
            width: 1px;
            margin-left: 10px;
            margin-right: 10px;
            background-color: $gray-lighter-5;
        }
    }
}
</style>
