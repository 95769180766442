var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-cy":"resource-center-module-chooser"}},[_c('pendo-drawer',{attrs:{"title":"Resource Center","size":"fullscreen","visible":_vm.isVisible,"class-name":"resource-center-module-chooser"},on:{"close":_vm.closeAndCancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"resource-center-module-chooser__footer"},[_c('pendo-button',{staticClass:"resource-center-module-chooser-cancel-button",attrs:{"data-cy":"rc-module-chooser-cancel-button","label":"Cancel","theme":"app","type":"secondary"},on:{"click":_vm.closeAndCancel}}),_c('pendo-button',{staticClass:"resource-center-add-module-button",attrs:{"data-cy":"rc-add-modules-button","disabled":_vm.disableSubmitButton,"loading":_vm.isAddingModules,"label":_vm.submitButtonLabel,"prefix-icon":"plus","theme":"app","type":"primary"},on:{"click":_vm.submitModal}})],1)]},proxy:true}])},[(_vm.isVisible)?_c('div',{staticClass:"resource-center-module-chooser__body"},[(_vm.usesMultiApp && _vm.isCreateMode)?_c('pendo-filter-bar',{staticClass:"resource-center-module-chooser__filter-bar"},[_c('pendo-form',{attrs:{"call-reset-fields":_vm.callResetFields,"call-validate":_vm.callValidate,"model":_vm.form},on:{"formValidated":_vm.handleValidForm,"fieldsReset":_vm.handleResetFields}},[_c('div',{staticClass:"resource-center-module-chooser__app-selector"},[_c('pendo-form-item',{staticClass:"app-selector-form-item",attrs:{"rules":[
                                {
                                    required: true,
                                    message: 'No selection made',
                                    trigger: 'blur'
                                }
                            ],"prop":"selectedApp"}},[_c('pendo-multiselect',{attrs:{"data-cy":"rc-select-application-modules","value":_vm.form.selectedApp,"allow-empty":false,"labels":{ left: 'Application' },"options":_vm.formattedAppOptions,"label-key":"displayName","placeholder":"- None Selected -"},on:{"select":_vm.onAppSelection},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('pendo-app-display',{attrs:{"apps":option}})]}},{key:"selectedLabel",fn:function({ option }){return [_c('pendo-app-display',{attrs:{"apps":option}})]}}],null,false,3832800421)})],1)],1)])],1):_vm._e(),_c('pendo-card',{staticClass:"resource-center-chooser-grid",attrs:{"title":"Modules"}},_vm._l((_vm.modules),function(mod){return _c('resource-center-module',{key:mod.name,staticClass:"module",attrs:{"disable-button":mod.disabled && 'Agent Version 2.18.5 is Required',"module-type":mod.type,"module-name":mod.name,"module-description":mod.description,"module-icon":mod.icon,"selected":mod.selected,"prevent-code-injection":_vm.preventCodeInjection},on:{"onToggle":function($event){return _vm.toggleSelectedModule(mod)}}})}),1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }