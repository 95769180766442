<template>
    <pendo-modal
        :show-close="true"
        :visible="isVisible"
        :confirm-button-config="CONFIG"
        :esc-to-close="true"
        :click-to-close="true"
        class="public-enable-rc-modal"
        width="400px"
        :title="MESSAGE_CONTENT.TITLE"
        :message="MESSAGE_CONTENT.MESSAGE"
        type="confirmation"
        @close="$emit('onCancel')"
        @cancel="$emit('onCancel')"
        @confirm="!isUpdating && $emit('onSave')" />
</template>

<script>
import { PendoModal } from '@pendo/components';

const CONFIG = Object.freeze({
    type: 'primary',
    label: 'Turn On',
    theme: 'app'
});

const MESSAGE_CONTENT = Object.freeze({
    TITLE: 'Set Resource Center to Public?',
    MESSAGE: 'By confirming this action, your Resource Center will be visible to all eligible end users.'
});

export default {
    name: 'ResourceCenterEnablePublicModal',
    components: {
        PendoModal
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        isUpdating: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            MESSAGE_CONTENT,
            CONFIG
        };
    }
};
</script>
