import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import min from 'lodash/min';
import some from 'lodash/some';
import Vue from 'vue';
import { flattenTrainingAttrs } from '@/utils/utils';
import { http } from '@pendo/http';
import { LOCALSTORAGE_KEYS } from '@/constants/localstorage-keys';
import { currentJsonWebTokenIds } from '@/utils/user-settings';
import { fetchAppLocalizationData } from '@/utils/localization';
import { generateAppUid, updateApp, updateAppFlag, updateAppProperty, fetchApp, setUniqueAppIds } from '@/utils/apps';
import { getDisplayName } from '@pendo/services/Apps';
import { canAppCreateCountable } from '@/utils/countable-permissions';
import { MOBILE_PLATFORMS } from '@/stateless-components/utils/apps';
import { SdkTools } from '@pendo/services/BuildingBlocks';
const { isMobileCodelessApp } = SdkTools;

const { localStorage } = window;

const MODULES_THAT_SHOULD_RESET_ON_APP_CHANGE = [
    'analytics',
    'designer',
    'features',
    'filters',
    'guideAnalytics',
    'guides',
    'resourceCenter',
    'integrationKeys',
    'pages',
    'themes',
    'trackEvents',
    'layouts',
    'reports',
    'users'
];

const DEFAULT_TIMEOUT = 10000;

export const state = getInitialState();

export function getInitialState () {
    return {
        activeAppUid: null,
        map: {},
        firstVisitMap: {},
        updatingActive: false,
        updatingFlag: null,
        updatingApp: false
    };
}

export const mutations = {
    // since app IDs are not unique, state.activeAppUid == app.uid == `subid:appid`
    setActiveAppUid (state, { uid }) {
        state.activeAppUid = uid;
        localStorage.setItem(LOCALSTORAGE_KEYS.appId, uid);
    },
    setMap (state, { map }) {
        state.map = map;
    },
    setFirstVisitMap (state, { firstVisitMap }) {
        state.firstVisitMap = firstVisitMap;
    },
    setUpdatingActive (state, { updating }) {
        state.updatingActive = updating;
    },
    setUpdatingFlag (state, { flag }) {
        state.updatingFlag = flag;
    },
    setUpdatingApp (state, { updating }) {
        state.updatingApp = updating;
    },
    setApp (state, { id, app }) {
        state.map[id] = app;
    },
    setAppLocalizationSettings (state, { appUid, localizationSettings }) {
        Vue.set(state.map[appUid], 'localizationSettings', localizationSettings);
    },
    reset (state) {
        Object.assign(state, getInitialState());
    },
    setAppProperty (state, { id, propertyName, propertyValue }) {
        Vue.set(state.map[id], propertyName, propertyValue);
    },
    setFlag (state, { appUid, flag, enabled }) {
        Vue.set(state.map[appUid].applicationFlags, flag, enabled);
    }
};

export const actions = {
    async hydrate ({ dispatch, commit, getters, state }, { subscriptions, activeSubId }) {
        const apps = subscriptions
            .reduce((appList, sub) => {
                const flatApps = flattenTrainingAttrs(sub.applications, {
                    subscriptionId: sub.id
                });
                const withUniqueIds = setUniqueAppIds(flatApps);

                return [...withUniqueIds, ...appList];
            }, [])
            .filter((app) => !!app.displayName);

        commit('setMap', { map: keyBy(apps, 'uid') });
        const { appId: activeAppId, accountId: activeAccountId } = currentJsonWebTokenIds();

        let nextApp;
        if (activeAppId && (!activeSubId || activeAppId.includes(activeSubId))) {
            nextApp = state.map[activeAppId];
        } else {
            const activeAppWithMatchingSubId = Object.keys(state.map).find((key) => key.includes(activeSubId));
            nextApp = state.map[activeAppWithMatchingSubId];
        }

        if (!nextApp) {
            [nextApp] = getters.listAll;
        }

        await dispatch('updateActive', { app: nextApp, accountId: activeAccountId });
    },
    async fetchLocalizationSettingsForApp ({ getters, commit }, { app, noCache }) {
        if (getters.localizationSettings && !noCache) return;

        const localizationSettings = await fetchAppLocalizationData(app.id);
        commit('setAppLocalizationSettings', {
            appUid: app.uid,
            localizationSettings
        });
    },
    async updateActive ({ commit, dispatch, rootState, state, rootGetters }, { app, uid, accountId, validateRoute }) {
        const uniqueId = uid || app.uid;

        if (state.activeAppUid === uniqueId && rootState.subscriptions.activeAccountId === accountId) {
            return;
        }

        commit('setUpdatingActive', { updating: true });

        const { subscriptionId } = state.map[uniqueId];

        if (rootState.subscriptions.activeAccountId !== accountId) {
            await dispatch('subscriptions/updateActive', { subscriptionId, accountId }, { root: true });
        }

        // Only reset app data if changing from one app to another
        if (state.activeAppUid) {
            dispatch('resetAppData');
        }

        commit('analytics/reset', null, { root: true });
        commit('setActiveAppUid', { uid: uniqueId });
        try {
            const activeAppId = rootGetters['apps/activeId'];
            await Promise.all([
                dispatch('userSettings/fetch', { noCache: true }, { root: true }),
                dispatch('fetchFirstVisit'),
                dispatch('guides/updateGuidePreviewable', { appId: activeAppId }, { root: true })
            ]);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error loading app settings', err);

            return;
        }

        const newAppContext = { subscriptionId, appId: app.id, accountId, validateRoute };
        dispatch('router/changeAppContext', newAppContext, { root: true });
        commit('setUpdatingActive', { updating: false });
    },
    async updateApp ({ dispatch, commit }, { app, updates }) {
        commit('setUpdatingApp', { updating: true });
        await updateApp({
            id: app.id,
            ...updates
        });
        await dispatch('auth/getUser', {}, { root: true });
        await dispatch('userSettings/fetch', { noCache: true }, { root: true });
    },
    async updateAttributesOnAllApps ({ commit, getters }, { updates }) {
        try {
            commit('setUpdatingApp', { updating: true });
            const appList = getters.listAllForActiveSubscription;
            const updateAppRequests = appList.map((app) => {
                const updatedApp = {
                    ...app,
                    ...updates
                };

                return updateApp({
                    id: updatedApp.id,
                    ...updates
                }).then(() => {
                    const uid = generateAppUid(updatedApp.subscriptionId, updatedApp.id);
                    commit('setApp', { id: uid, app: updatedApp });
                });
            });
            await Promise.all(updateAppRequests);
            commit('setUpdatingApp', { updating: false });
        } catch (error) {
            commit('setUpdatingApp', { updating: false });
            throw error;
        }
    },
    async updateFlag ({ commit }, { appId, flag, enabled }) {
        commit('setUpdatingFlag', { flag });
        try {
            const updatedApp = await updateAppFlag(appId, flag, enabled);
            const uid = generateAppUid(updatedApp.subscriptionId, updatedApp.id);
            await commit('setFlag', { appUid: uid, flag, enabled });
        } catch (error) {
            commit('setUpdatingFlag', { flag: null });
            throw error;
        }
        commit('setUpdatingFlag', { flag: null });
    },
    resetAppData ({ commit }) {
        MODULES_THAT_SHOULD_RESET_ON_APP_CHANGE.forEach((moduleName) => {
            commit(`${moduleName}/reset`, null, { root: true });
        });
    },
    async fetchFirstVisit ({ commit, getters, rootState }) {
        const activeSubId = get(rootState, 'subscriptions.activeId', null);
        if (!activeSubId) {
            return;
        }

        const appList = getters.listAllForActiveSubscription;
        const firstVisitMap = appList.reduce((map, app) => {
            const uid = generateAppUid(activeSubId, app.id);
            map[uid] = get(app, 'firstEventTime');

            return map;
        }, {});
        commit('setFirstVisitMap', { firstVisitMap });
    },
    async createApp ({ dispatch }, { appJson }) {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            await http.post('/api/s/_SID_/application', appJson, config);
        } catch (error) {
            throw error.response;
        }
        await dispatch('auth/init', {}, { root: true });
        await dispatch('userSettings/fetch', { noCache: true }, { root: true });
    },
    async updateExtensionDomainPatterns ({ commit }, { propertyValue, id }) {
        try {
            const propertyName = 'extensionDomainPatterns';
            const updatedApp = await updateAppProperty(id, propertyName, propertyValue);
            const uid = generateAppUid(updatedApp.subscriptionId, updatedApp.id);
            commit('setAppProperty', { id: uid, propertyName, propertyValue: updatedApp[propertyName] });
        } catch (err) {
            throw err.response;
        }
    },
    async loadOne ({ commit, state }, { reFetch = false, id } = {}) {
        if (state.map[id] && !reFetch) {
            return;
        }

        try {
            const app = await fetchApp(id);
            commit('setApp', { app });
        } catch (error) {
            commit('setError', { error });
        }
    }
};

export const getters = {
    active (state) {
        return state.map[state.activeAppUid] || {};
    },
    activeId (state) {
        return get(state.map[state.activeAppUid], 'id');
    },
    activePlatform (state) {
        return get(state.map[state.activeAppUid], 'platform');
    },
    getGuideSeenTimeout (state, getters) {
        return get(getters, 'active.guideSeenTimeoutLength', DEFAULT_TIMEOUT);
    },
    appById: (state, getters) => {
        return (id) => get(getters.appMapForActiveSubscription, id, null);
    },
    listAll (state) {
        return Object.values(state.map).sort((a, b) =>
            (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        );
    },
    listAllForActiveSubscription (state, getters, rootState, rootGetters) {
        const isActiveDigitalAdoption = rootGetters['subscriptions/activeIsDigitalAdoption'];
        const doesActiveHaveSnippetWebApps = rootGetters['subscriptions/activeHasSnippetWebApps'];

        return getters.listAll
            .filter((app) => app.subscriptionId === rootState.subscriptions.activeId)
            .filter(({ platform }) => {
                if (isActiveDigitalAdoption) {
                    if (doesActiveHaveSnippetWebApps) {
                        return ['web', 'extension'].includes(platform);
                    }

                    return platform === 'extension';
                }

                if (rootGetters['auth/hasSegmentFlag']('adoptMobile')) {
                    return true;
                }

                return ![...MOBILE_PLATFORMS].includes(platform);
            });
    },
    excludeMobileForActiveSubscription (state, getters, rootState, rootGetters) {
        const allApps = getters.listAllForActiveSubscription;

        if (rootGetters['auth/hasSegmentFlag']('adoptMobile')) {
            return allApps.filter((app) => ![...MOBILE_PLATFORMS].includes(app.platform));
        }

        // This return assumes mobile apps were already filtered out by listAllForActiveSubscription if adoptMobile was false.
        return allApps;
    },
    isCodelessReactAppById: (state, getters) => (appId) => {
        const app = getters.appById(appId);
        const framework = get(app, 'framework');
        if (framework !== 'react') {
            return false;
        }
        const flavour = app.mobileFrameworkFlavour;
        const reactNative = get(flavour, 'reactNative');
        const navigation = get(flavour, 'navigation');
        const navigationVersion = get(flavour, 'navigationVersion');
        const type = get(flavour, 'type');

        if (type) {
            return type === 'ReactNativeNavigation' || type === 'ReactNavigation';
        }

        return (
            reactNative === 'gte-0.6' &&
            ((navigation === 'rn' && navigationVersion === 'gte-5') ||
                (navigation === 'rnn' && navigationVersion === 'gte-6'))
        );
    },

    isRestrictedMobileApp: (state, getters, rootState, rootGetters) => (appId) => {
        const app = getters.appById(appId);
        if (rootGetters['auth/hasSegmentFlag']('flutterCodeless')) {
            return !isMobileCodelessApp(app);
        }

        const framework = get(app, 'framework');

        return framework === 'flutter' || (framework === 'react' && !getters.isCodelessReactAppById(appId));
    },
    listAllWithAnalyticsAccess (state, getters, rootState, rootGetters) {
        const aeuAttributes = rootGetters['subscriptions/aeuAttributes'];

        if (rootGetters['subscriptions/activeIsTrainingSubscription']) {
            return aeuAttributes.analyticsAccess ? getters.listAllForActiveSubscription : [];
        }

        return getters.listAllForActiveSubscription;
    },
    listAllWithRCAccessForActiveSubscription (state, getters, rootState, rootGetters) {
        const aeuAttributes = rootGetters['subscriptions/aeuAttributes'];

        if (rootGetters['subscriptions/activeIsTrainingSubscription']) {
            return aeuAttributes.resourceCenterAccess ? getters.excludeMobileForActiveSubscription : [];
        }

        return getters.excludeMobileForActiveSubscription;
    },
    appMapForActiveSubscription (state, getters) {
        return keyBy(getters.listAllForActiveSubscription, 'id');
    },
    listAllWithAccounts (state, getters) {
        const apps = getters.listAll;

        return apps.reduce((accumulator, current) => {
            if (get(current, 'applicationFlags.disabled', false)) return accumulator;
            if (!(current.displayName in accumulator)) accumulator[current.displayName] = [];
            accumulator[current.displayName].push(current);

            return accumulator;
        }, {});
    },
    getList (state, getters) {
        return (subId) => getters.listAll.filter((app) => app.subscriptionId === subId);
    },
    getListActiveApps (state, getters) {
        const { activeAppUid } = state;

        return getters.listAll.filter((app) => app.uid.split(':')[0] === activeAppUid.split(':')[0]);
    },
    firstVisit (state, getters, rootState, rootGetters) {
        const { activeAppUid, firstVisitMap } = state;
        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        let firstVisit;
        if (usesMultiApp) {
            const mapArr = Object.values(firstVisitMap).filter((visit) => visit);
            const minValue = mapArr.length ? min(mapArr) : 0;
            const today = new Date();
            const defaultFirstVisit = today.setFullYear(today.getFullYear() - 1);

            return minValue || defaultFirstVisit;
        }
        if (activeAppUid && firstVisitMap[activeAppUid]) {
            firstVisit = firstVisitMap[activeAppUid];
        } else {
            const today = new Date();
            firstVisit = today.setFullYear(today.getFullYear() - 1);
        }

        return firstVisit;
    },
    whiteLabelSettings (state, getters) {
        return get(getters.active, 'trainingAttributes.whiteLabelSettings', {});
    },
    localizationSettings (state, getters) {
        return get(getters.active, 'localizationSettings');
    },
    activeAppLocalizationEnabled (state, getters) {
        return get(getters.active, 'applicationFlags.guideLocalization', false);
    },
    activeHasApiAccess (state, getters, rootState, rootGetters) {
        const activeUsesV2Adopt = rootGetters['subscriptions/activeUsesV2Adopt'];

        return activeUsesV2Adopt
            ? get(getters.active, 'applicationFlags.apiAccess', false)
            : get(rootGetters['subscriptions/activeAccount'], 'apiAccess', false);
    },
    activeAppIsPlatformType: (state, getters) => (platform) => {
        return getters.activePlatform === platform;
    },
    hasAppOfPlatformType: (state, getters) => (extensionPlatformType) => {
        if (extensionPlatformType === 'other') {
            return false;
        }

        return some(getters.getListActiveApps, { extensionPlatformType });
    },
    appFromGuide: (state, getters) => (guide) => {
        const appMap = getters.appMapForActiveSubscription;

        return get(appMap, guide.appId, {});
    },
    appsFromGuide: (state, getters) => (guide) => {
        const appMap = getters.appMapForActiveSubscription;

        return guide.appIds.map((id) => get(appMap, id, {}));
    },
    appFromResourceCenter: (state, getters) => (resourceCenter) => {
        const appMap = getters.appMapForActiveSubscription;
        const appId = get(resourceCenter, 'draft.homeView.appId', '');

        return get(appMap, appId, {});
    },
    appNameFromId: (state, getters) => (id) => {
        const appMap = getters.appMapForActiveSubscription;
        const app = get(appMap, id, {});

        return getDisplayName(app);
    },
    showAppUsage (state, getters, rootState, rootGetters) {
        const activeHasAnalytics = rootGetters['subscriptions/activeHasAnalytics'];

        return activeHasAnalytics;
    },
    appsWithSuggestedPages (state, getters) {
        const applications = getters.listAllForActiveSubscription;

        return applications.filter(
            (app) => app.applicationFlags.suggestPageTags && canAppCreateCountable(app.id, 'page')
        );
    },
    appsWithPageBasedEventProperties (state, getters) {
        const applications = getters.listAllForActiveSubscription;

        return applications.filter(
            (app) => app.applicationFlags.pageBasedEventProperties && canAppCreateCountable(app.id, 'page')
        );
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
