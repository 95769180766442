<template>
    <div class="workflows-query-options">
        <pendo-form-item data-cy="workflows-recurring-multiselect">
            <pendo-multiselect
                :disabled="disabled || isLoading"
                full-width
                class="recurring"
                :options="recurringOptions"
                :value="selectedRecurringOption"
                @select="$emit('recurringSelected', $event)">
                <template #option="{ option }">
                    <pendo-icon-option :option="option" />
                </template>

                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
                <template #topLabel>
                    Is this a recurring process?
                </template>
            </pendo-multiselect>
        </pendo-form-item>

        <pendo-form-item
            v-if="!isReadOnly"
            data-cy="workflows-visibility-multiselect"
            label="Visibility"
            prop="subscriptionSharing.permission">
            <pendo-multiselect
                class="visibility"
                :disabled="disabled || isLoading || !canEditVisibility"
                full-width
                :value="selectedVisibilityOption"
                :options="visibilityOptions"
                @select="$emit('visibilitySelected', $event)">
                <template #option="{ option }">
                    <pendo-icon-option :option="option" />
                </template>

                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
            </pendo-multiselect>
        </pendo-form-item>
    </div>
</template>
<script>
import { PendoFormItem, PendoIconOption, PendoMultiselect } from '@pendo/components';

export default {
    name: 'QueryBuilderOptions',
    components: {
        PendoFormItem,
        PendoIconOption,
        PendoMultiselect
    },
    props: {
        canEditVisibility: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        isReadOnly: {
            type: Boolean,
            required: true
        },
        recurringOptions: {
            type: Array,
            required: true
        },
        selectedRecurringOption: {
            type: Object,
            required: true
        },
        selectedVisibilityOption: {
            type: Object,
            required: true
        },
        visibilityOptions: {
            type: Array,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-query-options {
    display: flex;

    > * {
        margin-right: 14px;
        min-width: 200px;
        width: 100%;
        max-width: 324px;
    }
}
</style>
