<template>
    <div>
        <pendo-drawer
            class-name="page-details-sidebar"
            :visible="visible"
            size="360px"
            push=".pendo-page"
            @close="closeSidebar">
            <template #header>
                <div class="page-details-sidebar--header">
                    <div class="page-details-sidebar--header-top">
                        <editable-text-field
                            v-if="canEditPageType && mode === 'view'"
                            v-model="localPage.name"
                            class="page-details-sidebar--header-top-editable-content"
                            multiline
                            fit-content
                            :autofocus="false"
                            @blur="updateDisplayName(localPage.name)" />
                        <span
                            v-if="!canEditPageType || mode === 'edit'"
                            class="page-details-sidebar--header-top-display-name">
                            {{ localPageDisplayName }}
                        </span>
                        <pendo-button
                            class="page-details-sidebar--header-top-close-button"
                            theme="app"
                            type="tertiary"
                            icon="x"
                            icon-size="16"
                            @click="closeSidebar" />
                    </div>
                    <div class="page-details-sidebar--header-bottom">
                        <pendo-tag
                            :class="[isInactivePage ? 'pendo-tag--inactive' : '']"
                            :label="`${pageType} Page`"
                            type="filter"
                            round />
                        <div class="page-details-sidebar--header-bottom-actions">
                            <div
                                v-if="canEditPage && mode === 'view'"
                                v-pendo-tooltip="{
                                    content: !canEditPageType && editTooltip,
                                    arrow: true,
                                    placement: 'bottom',
                                    multiline: true
                                }">
                                <pendo-button
                                    icon="edit"
                                    type="tertiary"
                                    :disabled="!canEditPageType"
                                    @click="mode = 'edit'" />
                            </div>
                            <div
                                v-if="canDeletePage"
                                v-pendo-tooltip="{
                                    content: !canDeletePageType && deleteTooltip,
                                    arrow: true,
                                    placement: 'bottom',
                                    multiline: true
                                }"
                                class="page-details-sidebar--header-delete">
                                <pendo-button
                                    icon="trash-2"
                                    type="tertiary"
                                    icon-size="16"
                                    :disabled="!canDeletePageType"
                                    @click="$emit('delete-page', localPage)" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #body>
                <div class="page-details-sidebar--details">
                    <div class="page-details-sidebar--detail">
                        <div class="page-details-sidebar--detail-name">
                            Application
                        </div>
                        <pendo-app-display :apps="app" />
                    </div>
                    <!-- ######################    VIEW INCLUDE RULES -->
                    <div v-if="mode === 'view'">
                        <div
                            v-if="!isMobileApp"
                            class="page-details-sidebar--detail">
                            <div class="page-details-sidebar--detail-name">
                                Include Rules
                            </div>
                            <div class="page-details-sidebar--rules">
                                <div
                                    v-for="(rule, key) in localPage.rules"
                                    :key="key"
                                    class="page-details-sidebar--rule-item">
                                    <div class="page-details-sidebar--rule">
                                        {{ rule.rule }}
                                    </div>
                                    <div v-if="canEditPageType">
                                        <pendo-button
                                            type="link"
                                            prefix-icon="check-circle"
                                            label="Test Rule"
                                            class="page-details-sidebar--test-rule"
                                            @click="testRule({ rule: rule.rule, key, type: 'include', item: rule })" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ######################    VIEW EXCLUDE RULES -->
                        <div
                            v-if="!isMobileApp && hasExcludeRules"
                            class="page-details-sidebar--detail">
                            <div class="page-details-sidebar--detail-name">
                                Exclude Rules
                            </div>
                            <div class="page-details-sidebar--rules">
                                <div
                                    v-for="(rule, key) in localPage.excludeRules"
                                    :key="key"
                                    class="page-details-sidebar--rule-item">
                                    <div class="page-details-sidebar--rule">
                                        {{ rule.rule }}
                                    </div>
                                    <div v-if="canEditPageType">
                                        <pendo-button
                                            type="link"
                                            prefix-icon="check-circle"
                                            label="Test Rule"
                                            class="page-details-sidebar--test-rule"
                                            @click="testRule({ rule: rule.rule, key, type: 'exclude', item: rule })" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="mode === 'edit'">
                        <pendo-form
                            ref="form"
                            v-model="isFormValid"
                            :model="editForm">
                            <!-- ######################    EDIT INCLUDE RULES -->
                            <div class="page-details-sidebar--detail">
                                <repeating-component
                                    :repeating-data="editForm.rules"
                                    unit="include rule"
                                    @addItem="addRule('include')"
                                    @removeItem="removeRule('include', $event)">
                                    <template #header>
                                        <div class="page-details-sidebar--detail-name">
                                            Include Rules
                                        </div>
                                    </template>
                                    <template #default="{ item }">
                                        <pendo-form-item
                                            class="page-details-sidebar--edit-form-input"
                                            prop="rules"
                                            :rules="[
                                                {
                                                    required: true,
                                                    trigger: ['blur', 'change'],
                                                    validator: includeRuleValidator(item.rule)
                                                }
                                            ]">
                                            <pendo-input
                                                :value="item.rule"
                                                :autofocus="true"
                                                type="textarea"
                                                :rows="1"
                                                class="page-edit-form-rule-input"
                                                @input="updateRule(item, $event)">
                                                <template #suffix>
                                                    <pendo-button
                                                        type="link"
                                                        prefix-icon="check-circle"
                                                        label="Test Rule"
                                                        class="page-details-sidebar--test-rule"
                                                        @click="
                                                            testRule({
                                                                rule: item.rule,
                                                                key: item.repeatingKey,
                                                                item,
                                                                type: 'include'
                                                            })
                                                        " />
                                                </template>
                                            </pendo-input>
                                        </pendo-form-item>
                                    </template>
                                </repeating-component>
                            </div>
                            <!-- ######################    EDIT EXCLUDE RULES -->
                            <div class="page-details-sidebar--detail">
                                <repeating-component
                                    allow-empty
                                    :repeating-data="editForm.excludeRules"
                                    unit="exclude rule"
                                    @addItem="addRule('exclude')"
                                    @removeItem="removeRule('exclude', $event)">
                                    <template #header>
                                        <div class="page-details-sidebar--detail-name">
                                            Exclude Rules
                                        </div>
                                    </template>
                                    <template #default="{ item }">
                                        <pendo-form-item
                                            class="page-details-sidebar--edit-form-input"
                                            prop="excludeRules"
                                            :rules="[
                                                {
                                                    required: true,
                                                    trigger: ['blur', 'change'],
                                                    validator: excludeRuleValidator(item.rule)
                                                }
                                            ]">
                                            <pendo-input
                                                :value="item.rule"
                                                :autofocus="true"
                                                type="textarea"
                                                :rows="1"
                                                class="page-edit-form-rule-input"
                                                @input="updateRule(item, $event)">
                                                <template #suffix>
                                                    <pendo-button
                                                        type="link"
                                                        prefix-icon="check-circle"
                                                        label="Test Rule"
                                                        class="page-details-sidebar--test-rule"
                                                        @click="
                                                            testRule({
                                                                rule: item.rule,
                                                                key: item.repeatingKey,
                                                                item,
                                                                type: 'exclude'
                                                            })
                                                        " />
                                                </template>
                                            </pendo-input>
                                        </pendo-form-item>
                                    </template>
                                </repeating-component>
                            </div>
                        </pendo-form>
                    </div>
                    <div class="page-details-sidebar--detail">
                        <div class="page-details-sidebar--detail-name">
                            Created By
                        </div>
                        <div>
                            <div>{{ localPage.createdByUser.first }} {{ localPage.createdByUser.last }}</div>
                            <div v-if="isCustomPage">
                                ({{ localPage.createdByUser.username }})
                            </div>
                            <div class="page-details-sidebar--detail-time">
                                {{ formattedTime(page.createdAt) }}
                            </div>
                        </div>
                    </div>
                    <div class="page-details-sidebar--detail">
                        <div class="page-details-sidebar--detail-name">
                            Last Updated By
                        </div>
                        <div>
                            <div>{{ localPage.lastUpdatedByUser.first }} {{ localPage.lastUpdatedByUser.last }}</div>
                            <div v-if="isCustomPage">
                                ({{ localPage.lastUpdatedByUser.username }})
                            </div>
                            <div class="page-details-sidebar--detail-time">
                                {{ formattedTime(localPage.lastUpdatedAt) }}
                            </div>
                        </div>
                    </div>
                </div>
                <mobile-screenshot
                    v-if="isMobileApp"
                    :page="page"
                    :subscription="activeSub" />
            </template>
            <template #footer>
                <div v-if="mode === 'edit'">
                    <pendo-grid
                        :columns="['max-content', 'max-content']"
                        justify-content="end"
                        gap="8"
                        class="page-details-sidebar--footer">
                        <pendo-button
                            label="Cancel"
                            theme="app"
                            type="tertiary"
                            class="cancel-button"
                            @click="resetPageEditAndView" />
                        <pendo-button
                            label="Save"
                            :disabled="isSaveDisabled"
                            :loading="isSaving"
                            theme="app"
                            type="primary"
                            class="save-button"
                            @click="onSaveClick" />
                    </pendo-grid>
                </div>
            </template>
        </pendo-drawer>
        <page-rule-tester
            :visible="ruleTesterVisible"
            :client="httpClient"
            :rule-obj="{ rule: ruleTesterConfig.rule, type: ruleTesterConfig.type }"
            :page-rules="{
                includeRules: includeRules,
                excludeRules: excludeRules
            }"
            :label="`${mode === 'edit' ? 'Update Rule' : 'Save Rule'}`"
            @close="closeTestModal" />
    </div>
</template>

<script>
import {
    PendoAppDisplay,
    PendoButton,
    PendoDrawer,
    PendoTag,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoGrid,
    PendoTooltip
} from '@pendo/components';
import { canAppEditCountable, canAppRemoveCountable } from '@/utils/countable-permissions';
import {
    removeRepeatingKeys,
    RepeatingComponent,
    PageRuleTester,
    countableType,
    isCustomCountable,
    isInactiveCountable,
    canEditCountable,
    canDeleteCountable
} from '@pendo/tagging';
import { convertToSubscriptionTimezone } from '@/utils/moment.js';
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { http } from '@pendo/http';
import EditableTextField from '@/components/common/EditableTextField.vue';
import { v4 as uuid } from 'uuid';
import { MOBILE_PLATFORMS } from '@/stateless-components/utils/apps';
import MobileScreenshot from '@/components/analytics/mobile-screenshot.vue';

export default {
    name: 'PageDetailsSidebar',
    components: {
        PendoDrawer,
        PendoButton,
        PendoTag,
        PendoAppDisplay,
        EditableTextField,
        RepeatingComponent,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoGrid,
        PageRuleTester,
        MobileScreenshot
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        pageId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            localPage: {},
            hasChanges: false,
            isSaving: false,
            isFormValid: false,
            mode: 'view',
            editForm: {
                rules: [],
                excludeRules: []
            },
            ruleTesterVisible: false,
            ruleTesterConfig: {
                rule: '',
                item: {},
                key: null,
                type: ''
            }
        };
    },
    computed: {
        ...mapGetters({
            getAppById: 'apps/appById',
            getPageById: 'pages/pageById',
            isAdoptEndUser: 'subscriptions/activeIsTrainingSubscription',
            getActiveTimezone: 'subscriptions/getTimezone',
            activeSub: 'subscriptions/active'
        }),
        httpClient () {
            return { http };
        },
        app () {
            return this.getAppById(this.localPage.appId);
        },
        isMobileApp () {
            const platform = get(this.app, 'platform', '');

            return MOBILE_PLATFORMS.includes(platform);
        },
        page () {
            return this.getPageById(this.pageId);
        },
        includeRules () {
            return this.localPage?.rules || [];
        },
        excludeRules () {
            return this.localPage?.excludeRules || [];
        },
        hasExcludeRules () {
            return !!this.localPage.excludeRules || get(this.locaPage, 'excludeRules.length', 0) > 0;
        },
        canEditPage () {
            return canAppEditCountable(this.localPage.appId, 'page');
        },
        canEditPageType () {
            return this.canEditPage && canEditCountable(this.localPage, this.isAdoptEndUser);
        },
        canDeletePage () {
            return canAppRemoveCountable(this.localPage.appId, 'page');
        },
        canDeletePageType () {
            return this.canDeletePage && canDeleteCountable(this.localPage, this.isAdoptEndUser);
        },
        pageType () {
            return countableType(this.localPage);
        },
        isCustomPage () {
            return isCustomCountable(this.localPage);
        },
        isInactivePage () {
            return isInactiveCountable(this.localPage);
        },
        isSaveDisabled () {
            return !this.isFormValid || this.editForm.rules.some((rule) => !rule.rule);
        },
        editTooltip () {
            switch (this.pageType) {
                case 'Inherited':
                    return 'Inherited Pages cannot be edited';
                case 'Inactive':
                    return 'Inactive Pages cannot be edited';
                default:
                    return null;
            }
        },
        deleteTooltip () {
            switch (this.pageType) {
                case 'Inherited':
                    return 'Inherited Pages cannot be deleted';
                case 'Inactive':
                    return this.isAdoptEndUser ? 'Inactive Pages cannot be deleted' : null;
                default:
                    return null;
            }
        },
        localPageDisplayName () {
            const nameKey = this.isInactivePage ? 'name' : 'displayName';

            return this.localPage[nameKey];
        }
    },
    watch: {
        page: {
            handler () {
                this.resetPageEditAndView();
            },
            immediate: true
        }
    },
    created () {
        this.$watch(
            (vm) => [vm.editForm.rules, vm.editForm.excludeRules],
            () => {
                this.$refs.form?.validateField(['rules', 'excludeRules']);
            },
            { deep: true }
        );
    },
    beforeDestroy () {
        this.$emit('close-sidebar');
    },
    methods: {
        ...mapActions({
            updateCustomPage: 'pages/updateCustomPage'
        }),
        closeSidebar () {
            this.$emit('close-sidebar');
        },
        formattedTime (unixTime) {
            return convertToSubscriptionTimezone(this.getActiveTimezone, unixTime).format('MMM D, YYYY h:mm A z');
        },
        async updateDisplayName (newDisplayName) {
            if (this.page.name === newDisplayName) {
                return;
            }
            if (!newDisplayName) {
                this.localPage.name = this.page.name;

                return;
            }

            const customPage = {
                ...this.localPage,
                pageId: this.localPage.id,
                name: newDisplayName
            };
            this.isSaving = true;
            await this.updateCustomPage({ customPage });
            this.isSaving = false;
        },
        includeRuleValidator (includeRule) {
            return (rule, value, callback) => {
                includeRule = includeRule || '';
                const splitRule = includeRule.split('/');
                const hasMultipleParams = splitRule.filter((path) => path === '*parameter*').length > 1;
                if (!includeRule.startsWith('//')) {
                    callback(new Error('Page rules must begin with a leading //'));

                    return;
                }
                if (hasMultipleParams) {
                    callback(new Error('Page rules cannot contain more than one *parameter*'));

                    return;
                }
                const excludeRules = (this.editForm.excludeRules || []).map((item) => item.rule);
                if (excludeRules.includes(includeRule)) {
                    callback(new Error('Exclude Rules cannot match Include Rules'));

                    return;
                }
                callback();
            };
        },
        excludeRuleValidator (excludeRule) {
            return (rule, value, callback) => {
                excludeRule = excludeRule || '';
                const splitRule = excludeRule.split('/');
                const hasMultipleParams = splitRule.filter((path) => path === '*parameter*').length > 1;
                if (!excludeRule.startsWith('//')) {
                    callback(new Error('Page rules must begin with a leading //'));

                    return;
                }
                if (hasMultipleParams) {
                    callback(new Error('Page rules cannot contain more than one *parameter*'));

                    return;
                }
                const includeRules = (this.editForm.rules || []).map((item) => item.rule);
                if (includeRules.includes(excludeRule)) {
                    callback(new Error('Exclude Rules cannot match Include Rules'));

                    return;
                }
                callback();
            };
        },
        getRulesForType (type) {
            return {
                include: this.editForm.rules,
                exclude: this.editForm.excludeRules
            }[type];
        },
        addRule (type) {
            const ruleArray = this.getRulesForType(type);

            ruleArray.push({ rule: '', parsedRule: '', repeatingKey: uuid() });
        },
        removeRule (type, index) {
            const ruleArray = this.getRulesForType(type);

            ruleArray.splice(index, 1);
        },
        updateRule (item, $event) {
            item.rule = $event.trim();
        },
        resetPageEditAndView () {
            this.mode = 'view';
            this.localPage = cloneDeep(this.page);
            let { rules = [], excludeRules = [] } = this.localPage;
            rules = rules.map((rule) => ({ ...rule, repeatingKey: uuid() }));
            excludeRules = excludeRules.map((rule) => ({ ...rule, repeatingKey: uuid() }));
            this.editForm = { rules, excludeRules };
        },
        async onSaveClick () {
            if (!this.isFormValid) {
                return;
            }
            this.isSaving = true;
            const { rules, excludeRules } = this.editForm;
            const transformedRules = removeRepeatingKeys(rules);
            const transformedExcludeRules = removeRepeatingKeys(excludeRules || []);
            // save changes to local page to prevent UI flashing on update
            this.localPage = { ...this.localPage, rules: transformedRules, excludeRules: transformedExcludeRules };

            const customPage = {
                ...this.localPage,
                pageId: this.localPage.id,
                rules: transformedRules,
                excludeRules: transformedExcludeRules.length > 0 ? transformedExcludeRules : undefined
            };

            await this.updateCustomPage({ customPage });
            this.isSaving = false;
            this.mode = 'view';
        },
        testRule (config) {
            this.ruleTesterConfig = config;
            this.ruleTesterVisible = true;
        },
        async closeTestModal (rule) {
            this.ruleTesterVisible = false;
            if (this.mode === 'view') {
                const { rules, excludeRules } = { ...this.localPage };
                let changed = false;
                if (this.ruleTesterConfig.type === 'include') {
                    changed = rules[this.ruleTesterConfig.key].rule !== rule;
                    rules[this.ruleTesterConfig.key].rule = rule;
                } else {
                    changed = excludeRules[this.ruleTesterConfig.key].rule !== rule;
                    excludeRules[this.ruleTesterConfig.key].rule = rule;
                }
                const customPage = {
                    ...this.localPage,
                    rules,
                    excludeRules,
                    pageId: this.localPage.id
                };
                if (changed) {
                    this.isSaving = true;
                    await this.updateCustomPage({ customPage });
                    this.isSaving = false;
                }
            } else {
                this.ruleTesterConfig.item.rule = rule;
            }
        }
    }
};
</script>

<style lang="scss">
.page-details-sidebar {
    .pendo-drawer__header {
        padding-left: 22px;
        padding-right: 22px;
    }

    .pendo-drawer__content {
        grid-template-rows: [header] minmax(68px, max-content) [body] auto [footer] minmax(68px, max-content) !important; /* stylelint-disable-line */
    }

    .pendo-drawer__footer {
        padding: 16px;
        align-items: center;
        border-top: 1px solid $gray-lighter-5;
        width: 100%;
        background-color: $white;
        border-radius: 0px 0px 2px 2px;
    }

    .pendo-drawer__footer:empty {
        border-top: 0px solid;
    }

    &--header {
        max-width: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        gap: 14px;

        &-top {
            display: flex;
            margin-top: 14px;
            overflow: inherit;
            justify-content: space-between;

            .page-details-sidebar--header-top-editable-content {
                display: flex;
                overflow: inherit;
                margin-top: 5px;
                width: 275px;
            }

            &-display-name {
                overflow: inherit;
                font-size: 22px;
                font-weight: 600;
                margin-top: 5px;
            }

            &-editable-content {
                font-size: 22px;
                font-weight: 600;
            }
        }

        &-bottom {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .pendo-tag {
                margin-top: 5px;
            }

            &-actions {
                display: flex;
                gap: 8px;
            }

            .pendo-tag--inactive {
                border-color: $color-red-70;
            }
        }
    }

    &--details {
        padding: 20px;
        border-top: 1px solid $gray-lighter-6;
    }

    &--detail {
        margin-bottom: 35px;
        font-size: 14px;
    }

    &--detail-name {
        font-weight: 600;
        margin-bottom: 5px;
    }

    &--rules {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    &--rule {
        word-break: break-all;
        overflow-wrap: break-word;
    }

    &--test-rule {
        color: $teal-lighter-1;
        text-decoration: none;
    }

    &--rule-item {
        padding: 11px;
        background-color: $color-gray-30;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    &--detail-time {
        margin-top: 5px;
        color: $gray-lighter-2;
    }

    &--edit-form-input {
        width: 100%;
    }
}
</style>
