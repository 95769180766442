// This file contains code lifted from https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// This allows us to swallow navigation related errors, which you can read more about here: https://v3.router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures
// Navigation errors are almost always uselsss for our current set of concerns, and are most often triggered by route guards
// redirecting users away from pages they don't have access to. Because we don't tell users _why_ they're being redirected, there isn't
// much reason to surface these errors in the console uncaught, as that causes more QE confusion than anything

// Modify the prototype of VueRouter _prior_ to injection into the vue instance via Vue.use
export function augmentVueRouterPrototypeToSwallowNavigationErrors (VueRouter) {
    VueRouter.prototype.push = generateNewRouterHandler(VueRouter, VueRouter.prototype.push);
    VueRouter.prototype.replace = generateNewRouterHandler(VueRouter, VueRouter.prototype.replace);
}

// Currently used to replace `push` and `replace` with the `swallowRedirectError` fn, which
// catches and swallows `isNavigationFailure` error messages. All other errors and functionality
// are preserved from the original method
export function generateNewRouterHandler (VueRouter, originalMethod) {
    return function swallowRedirectError (location, onResolve, onReject) {
        if (onResolve || onReject) {
            return originalMethod.call(this, location, onResolve, onReject);
        }

        return originalMethod.call(this, location).catch((err) => {
            if (VueRouter.isNavigationFailure(err)) {
                return err;
            }

            return Promise.reject(err);
        });
    };
}
