<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="app"
            class="guides-tabs">
            <template #guideList>
                <guide-list-table
                    type="organization"
                    @showGuideCreateChange="$emit('showGuideCreateChange', $event)" />
            </template>
            <template #guideAlerts>
                <guide-alerts-wrapper />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { PendoPageContent, PendoTabs } from '@pendo/components';
import GuideListTable from './GuideListTable';
import GuideAlertsWrapper from './GuideAlertsWrapper';
import { mapState } from 'vuex';

export default {
    name: 'GuideListContent',
    components: {
        GuideListTable,
        PendoPageContent,
        PendoTabs,
        GuideAlertsWrapper
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        activeTab: {
            get () {
                return this.route.name || 'guideList';
            },
            set (tab) {
                this.$router.push({ name: tab }).catch(() => {});
            }
        },
        tabs () {
            return [
                {
                    prop: 'guideList',
                    label: 'Overview',
                    lazy: true
                },
                {
                    prop: 'guideAlerts',
                    label: 'Guide Alerts',
                    lazy: true
                }
            ];
        }
    }
};
</script>
