<template>
    <csv-upload
        title="Upload License CSV"
        is-icon
        hide-file-format-note
        upload-type="licenses"
        :hide-cancel-on-success="true"
        :hide-cancel-on-warning="true"
        cancel-text="Close"
        :has-error="hasError"
        :has-warning="hasWarning"
        :has-exclude-header-check="false"
        @refresh="refreshLicenses"
        @error="onError"
        @warning="onWarning"
        @reset="onReset">
        <template #description>
            <p>Add or edit license information with a CSV upload.</p>
            <ul class="step-list">
                <li class="step-list__step">
                    <p class="step-list__number">
                        Step 1:
                    </p>
                    <p class="step-list__description">
                        Start with
                        <a
                            href="#"
                            @click.prevent="downloadTemplate">this template file. </a>
                        It includes all of your applications and latest license values. File must be new line delimited.
                        <a
                            href="https://adoptsupport.pendo.io/hc/en-us/articles/8358072483483"
                            target="_blank">More file format details.
                        </a>
                    </p>
                </li>
                <li class="step-list__step">
                    <p class="step-list__number">
                        Step 2:
                    </p>
                    <p class="step-list__description">
                        Update the template file with the latest license information. Empty cells will be ignored. Be
                        sure not to edit Application ID.
                    </p>
                </li>
                <li class="step-list__step">
                    <p class="step-list__number">
                        Step 3:
                    </p>
                    <p class="step-list__description">
                        Choose your CSV file and upload.
                    </p>
                </li>
            </ul>
        </template>
        <template #error-message>
            <div class="error-message">
                <div v-if="invalidHeaderError">
                    <strong>{{ errorMessage }}</strong>
                </div>
                <div v-else-if="tooManyHeadersError">
                    <strong>Too many license information fields</strong>
                </div>
                <div v-else-if="noLicenseRecordsError">
                    <strong>No license records in file</strong>
                </div>
                <div v-else>
                    <div class="capitalize">
                        <strong>{{ errorMessage }}</strong>
                    </div>
                </div>
            </div>
        </template>
        <template #warning-message>
            <div
                v-if="someLicensesNotCreatedOrUpdated"
                class="warning-message">
                <strong>Some licenses did not create or update successfully ({{ successCount }}/{{ totalCount }})</strong>
                <ul
                    v-for="(warning, idx) in warningMessages"
                    :key="idx">
                    <li>{{ warning }}</li>
                </ul>
            </div>
        </template>
    </csv-upload>
</template>

<script>
import CsvUpload from '@/components/segments/builder/rule-components/CsvUpload';
import { downloadCSV } from '@/utils/license-utilization';
import isNumber from 'lodash/isNumber';

export default {
    name: 'LicenseCsvUpload',
    components: {
        CsvUpload
    },
    props: {
        templateData: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            errorMessage: '',
            invalidHeaderError: false,
            tooManyHeadersError: false,
            noLicenseRecordsError: false,
            hasError: false,
            totalCount: 0,
            successCount: 0,
            someLicensesNotCreatedOrUpdated: true,
            hasWarning: false,
            warningMessages: [],
            templateColumnHeaders: ['App Name', 'App Id', 'Licenses']
        };
    },
    methods: {
        downloadTemplate () {
            const mappedData = this.templateData.map((app) => [`"${app.name}"`, app.appId, app.quantity]);
            const header = this.templateColumnHeaders.join(',');
            const filename = `app-licenses-${new Date().getTime()}.csv`;
            const csvNewLine = '\r\n';
            const csvDelimiter = ',';

            let csvData = '';
            for (const row of mappedData) {
                csvData += row.join(csvDelimiter) + csvNewLine;
            }
            csvData = `\ufeff${header}${csvNewLine}${csvData}`;
            downloadCSV({ csvData, filename });
        },
        onError (error) {
            switch (error.status) {
                case 400:
                    this.hasError = true;
                    this.errorMessage = error.error;

                    if (error.error.includes('Missing required header fields')) {
                        this.invalidHeaderError = true;
                        break;
                    }

                    if (error.error.includes('may not contain more than')) {
                        this.tooManyHeadersError = true;
                        break;
                    }

                    if (error.error.includes('must contain at least one record')) {
                        this.noLicenseRecordsError = true;
                        break;
                    }

                    break;
                default:
                    this.hasError = true;
                    this.errorMessage = error.error;
                    break;
            }
        },
        onWarning (message) {
            if (isNumber(message.warning) && message.warning > 0) {
                this.hasWarning = true;
                this.someLicensesNotCreatedOrUpdated = true;
                this.successCount = message.success;
                this.totalCount = message.total;
                this.warningMessages = message.warnings;
            }
        },
        refreshLicenses () {
            this.$emit('uploaded');
        },
        onReset () {
            this.errorMessage = '';
            this.invalidHeaderError = false;
            this.tooManyHeadersError = false;
            this.noLicenseRecordsError = false;
            this.hasError = false;
            this.totalCount = 0;
            this.successCount = 0;
            this.someLicensesNotCreatedOrUpdated = false;
            this.hasWarning = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.step-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;

    &__step {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    &__number {
        margin: 0;
        white-space: nowrap;
        font-weight: 600;
    }

    &__description {
        margin: 0;
        margin-left: 10px;
    }
}

.error-message {
    margin-top: 5px;
}

.capitalize::first-letter {
    text-transform: capitalize;
}

.warning-message ul {
    padding: 0 0 0 10px;
}
</style>
