import get from 'lodash/get';
import store from '@/state/store';

export class TableUserSettings {
    constructor (settingsName) {
        this.settingsName = settingsName;
    }

    async onSortChange ($event) {
        const { order, prop } = $event;

        const newSortSetting = {
            defaultSort: { order, prop }
        };

        await this.updateSavedTableSettings(newSortSetting);
    }

    async onColumnResize ($event) {
        const { column: prop, width } = $event;

        const savedSettings = this.getSavedUserSettings();
        const columnSizes = get(savedSettings, 'columnSizes', {});

        columnSizes[prop] = width;

        const partialSetting = { columnSizes };

        await this.updateSavedTableSettings(partialSetting);
    }

    async onColumnChange ($event) {
        const { columns: visibleColumns } = $event;

        const partialSetting = { visibleColumns };

        await this.updateSavedTableSettings(partialSetting);
    }

    getSavedUserSettings () {
        const savedSettings = store.getters['userSettings/getTableUserSettingValueByName'](this.settingsName);
        if (!savedSettings) return null;

        return savedSettings;
    }

    generateSaveableUserSetting (partialSetting) {
        const savedUserSettings = this.getSavedUserSettings();
        const newUserSetting = {
            ...savedUserSettings,
            ...partialSetting
        };

        return newUserSetting;
    }

    async updateSavedTableSettings (newPartialSettings) {
        const newSettings = this.generateSaveableUserSetting(newPartialSettings);
        await store.dispatch('userSettings/updateUserSettingByName', {
            settingsName: this.settingsName,
            value: newSettings
        });
    }
}

export default {
    name: 'PendoTableUserSettingsWatcher',
    bind (el, binding, vnode) {
        const { settingsName } = binding.value;

        if (!settingsName) throw new Error('Must provide a valid `settingsName` to enable the user settings watcher.');

        const userSettingsInstance = new TableUserSettings(settingsName);

        vnode.componentInstance.$on('column-resize', userSettingsInstance.onColumnResize.bind(userSettingsInstance));
        vnode.componentInstance.$on('column-change', userSettingsInstance.onColumnChange.bind(userSettingsInstance));
        vnode.componentInstance.$on('sort-change', userSettingsInstance.onSortChange.bind(userSettingsInstance));
    }
};
