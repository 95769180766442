const PROD_API_KEY = '7de2c6a3-6665-430f-52c6-bd6c043db205';
const TEST_API_KEY = 'fa96db3f-7fa1-4936-4818-61063c36a040';
const DEV_API_KEY = '474d9a85-2646-48f6-4b07-5d75357d8221';
const ATLAS_API_KEY = 'ba070a14-8710-40b6-731d-3ad15f4f99a7';
const CALYPSO_API_KEY = '9735214f-f4eb-4573-75bb-e5a8ba5144d4';

const PROD_HOST = 'content.pendo-internal.pendo.io';
const TEST_HOST = 'content.product.pendo-test.pendosandbox.com';
const DEV_HOST = 'pendo-dev-static.storage.googleapis.com';
const ATLAS_HOST = 'pendo-atlas-static.storage.googleapis.com';
const CALYPSO_HOST = 'pendo-calypso-static.storage.googleapis.com';

const partnerAnalyticsAllowedEnvironments = ['prod', 'test', 'dev', 'atlas'];

function getEnvConfigOptions (env = '') {
    let host;
    let apiKey;
    const allowPartnerAnalyticsForwarding = partnerAnalyticsAllowedEnvironments.includes(env);

    switch (env) {
        case 'prod':
        case 'prod-us1':
        case 'prod-jp':
        case 'prod-hsbc':
        case 'prod-eu':
            host = PROD_HOST;
            apiKey = PROD_API_KEY;
            break;
        case 'atlas':
            host = ATLAS_HOST;
            apiKey = ATLAS_API_KEY;
            break;
        case 'calypso':
            host = CALYPSO_HOST;
            apiKey = CALYPSO_API_KEY;
            break;
        case 'dap':
            host = 'pendo-dap-static.storage.googleapis.com';
            apiKey = '595b8779-623a-4372-52a9-7a919ec80a56';
            break;
        case 'helix':
            host = 'pendo-helix-static.storage.googleapis.com';
            apiKey = '4299a4ca-2625-40eb-5b9d-ff4cb0d1901e';
            break;
        case 'magic':
        case 'test':
            host = TEST_HOST;
            apiKey = TEST_API_KEY;
            break;
        case 'dev':
        case 'eu-dev':
        default:
            host = DEV_HOST;
            apiKey = DEV_API_KEY;
            break;
    }

    return { host, apiKey, allowPartnerAnalyticsForwarding };
}

function setupPendoSnippetStateless (env = '', store, isLocal) {
    const { host, apiKey, allowPartnerAnalyticsForwarding } = getEnvConfigOptions(env);

    const src = `https://${host}/agent/static/${apiKey}/pendo.js`;

    const segmentFlagTimeoutGuard = window.setTimeout(() => {
        // eslint-disable-next-line no-console
        console.error('Segment flag request took too long, initializing segment flags to []');
        store.dispatch('auth/updateSegmentFlags', {
            flags: []
        });
    }, 5000);

    const cookieSettings = {};
    if (!isLocal) {
        if (env.includes('prod')) {
            cookieSettings.cookieDomain = '.pendo.io';
        } else {
            cookieSettings.cookieDomain = '.pendo-dev.com';
        }
    }

    // eslint-disable-next-line camelcase
    window.pendo_options = {
        apiKey,
        allowPartnerAnalyticsForwarding,
        usePendoAgentAPI: true,
        events: {
            guidesLoaded () {
                window.clearTimeout(segmentFlagTimeoutGuard);

                return store.dispatch('auth/updateSegmentFlags', {
                    flags: window.pendo.segmentFlags
                });
            },
            guidesFailed () {
                window.clearTimeout(segmentFlagTimeoutGuard);

                return store.dispatch('auth/updateSegmentFlags', {
                    flags: []
                });
            }
        },
        ...cookieSettings
    };

    /* eslint-disable */
    // prettier-ignore
    (function (p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w) {
            (function (m) {
                o[m] = o[m] || function () {
                    o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
        }
        y = e.createElement(n);
        y.async = !0;
        y.src = src;
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
    /* eslint-enable */

    return true;
}

export {
    PROD_HOST,
    TEST_HOST,
    DEV_HOST,
    ATLAS_HOST,
    CALYPSO_HOST,
    PROD_API_KEY,
    TEST_API_KEY,
    DEV_API_KEY,
    ATLAS_API_KEY,
    CALYPSO_API_KEY,
    getEnvConfigOptions,
    setupPendoSnippetStateless
};
