<template>
    <pendo-filter-bar
        ref="globalFilter"
        v-pendo-affix="{
            fixedStyles: {
                left: 0,
                right: 0,
                padding: '0 32px 0 120px'
            }
        }"
        class="global-filters"
        :class="shouldShowSmallStyles">
        <div class="global-filters--inner global-filters--top">
            <slot name="left" />
            <div class="filters">
                <div
                    v-if="showSegmentPicker"
                    class="filters--item">
                    <segment-chooser
                        :value="activeSegmentId"
                        :disabled="updatingActiveApp"
                        :guide-targeting="false"
                        @input="updateActiveSegmentId" />
                </div>
                <div
                    v-if="showDatePicker"
                    class="filters--item">
                    <date-range-picker
                        :value="dateRange"
                        :first-visit="firstVisit"
                        @change="updateDateRange" />
                </div>
                <div
                    v-if="usesMultiApp && showAppPicker"
                    class="filters--item">
                    <app-filter :show-mobile-apps="showMobileApps" />
                </div>
                <div class="filters--item additional-filters-slot">
                    <slot name="additional-filters" />
                </div>
            </div>
            <div class="filters--item right-slot">
                <slot name="right" />
            </div>
        </div>
        <template #bottom>
            <div
                v-if="!!$slots.expansion"
                class="global-filters--inner global-filters--bottom">
                <div class="filters">
                    <slot name="expansion" />
                </div>
            </div>
        </template>
    </pendo-filter-bar>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { PendoFilterBar, PendoAffix } from '@pendo/components';
import SegmentChooser from '@/components/segments/SegmentChooser';
import DateRangePicker from '@/stateless-components/common/DateRangePicker';
import AppFilter from '@/components/filters/AppFilter';

export default {
    name: 'GlobalFilters',
    components: {
        DateRangePicker,
        AppFilter,
        PendoFilterBar,
        SegmentChooser
    },
    directives: {
        PendoAffix
    },
    props: {
        showSegmentPicker: {
            type: Boolean,
            default: true
        },
        showDatePicker: {
            type: Boolean,
            default: true
        },
        showAppPicker: {
            type: Boolean,
            default: true
        },
        showMobileApps: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            isSticky: false
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            updatingActiveApp: (state) => state.apps.updatingActive,
            dateRange: (state) => state.filters.dateRange,
            isPageDetailsSidebarOpen: (state) => state.pages.isPageDetailsSidebarOpen,
            isFeatureDetailsSidebarOpen: (state) => state.features.isFeatureDetailsSidebarOpen
        }),
        ...mapGetters({
            firstVisit: 'apps/firstVisit',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        shouldShowSmallStyles () {
            if (this.isCountableDetailsSidebarOpen && this.isSticky) {
                return 'global-filters__small is-sticky';
            }

            if (this.isSticky) {
                return 'is-sticky';
            }

            return '';
        },
        isCountableDetailsSidebarOpen () {
            return this.isPageDetailsSidebarOpen || this.isFeatureDetailsSidebarOpen;
        }
    },
    mounted () {
        this.observer = new MutationObserver((mutations) => {
            for (const mtx of mutations) {
                const newValue = mtx.target.getAttribute(mtx.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, mtx.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.globalFilter.$el, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['class']
        });
    },
    beforeDestroy () {
        this.observer.disconnect();
    },
    methods: {
        ...mapActions({
            updateActiveSegmentId: 'filters/updateActiveSegmentId',
            updateDateRange: 'filters/updateDateRange'
        }),
        onClassChange (classAttrValue) {
            const classList = classAttrValue.split(' ');
            this.isSticky = classList.includes('is-sticky');
        }
    }
};
</script>

<style lang="scss">
.global-filters__small {
    // Yeah. This sucks. - ZL
    /* 100% of width, 360px = the width of the sidebar component
    81px = the width of the adopt left nav
    120px = the padding added to the pendo-affix directive
    -32 = the padding added to the other side of the pendo-affix directive. */
    width: calc(((100% - 360px) - 81px) + (120px - 32px));
}

.global-filters {
    .right-slot {
        display: flex;
        gap: 12px;
        max-width: 50%;
        flex-wrap: wrap;
        justify-content: flex-end;
        min-width: 300px;
    }

    &--top {
        margin-bottom: 16px;
        margin-top: 16px;
    }

    &--inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &--bottom {
        padding: 12px 0;
    }

    .filters {
        display: flex;
        flex-flow: row wrap;
        gap: 12px;

        &--label,
        &--item {
            margin-right: 4px;
            font-size: 0.875em;
            line-height: 1.25em;
        }

        &--item {
            &:last-of-type {
                margin-right: unset;
            }
        }

        .date-filter {
            &--primary-text {
                margin-right: 0.5em;
            }

            &--secondary-text {
                color: $gray-lighter-3;
                font-size: 0.875em;
            }

            &--selected-label {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                .pendo-icon {
                    margin-right: 0.5em;
                }
            }
        }
    }
}
</style>
