<template>
    <pendo-card title="Activation">
        <template
            v-if="canEdit"
            #headerRight>
            <pendo-button
                v-if="!isEditing"
                type="link"
                theme="app"
                data-cy="edit-activation-button"
                class="manage-in-app--button"
                label="Manage in my app"
                prefix-icon="external-link"
                @click="activeIsDigitalAdoption ? editAutomaticActivation(true) : editGuideActivation()" />
            <pendo-button
                v-if="activeIsDigitalAdoption && isEditing"
                data-cy="edit-automatic-launch-cancel-action"
                type="tertiary"
                theme="app"
                label="Cancel"
                @click="editAutomaticActivation(false)" />
            <pendo-button
                v-if="activeIsDigitalAdoption && isEditing"
                data-cy="toggle-automatic-launch-save-action"
                theme="app"
                type="primary"
                size="mini"
                label="Save"
                :disabled="!userHasChanges"
                @click="updateLaunchMethod" />
        </template>
        <template #body>
            <div
                v-if="isEditing"
                class="toggle-automatic-activation-slider">
                <div class="activation-text">
                    <div
                        class="activation-text--label"
                        data-cy="automatic-activation-toggle">
                        Automatic Activation
                        <pendo-toggle
                            :value="automaticActivationToggle"
                            @change="automaticActivationToggle = $event" />
                    </div>

                    This guide appears one time automatically, until dismissed or advanced.
                </div>
            </div>

            <div
                v-if="!isMobileTooltip || hasMobileTooltipActivation"
                class="launch-method">
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap.appLaunch"
                    class="launch-method--item"
                    data-cy="app-launch-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="smartphone"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">App Launch</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap.track"
                    class="launch-method--item"
                    data-cy="track-event-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="zap"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Track Event</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap['page-auto']"
                    class="launch-method--item"
                    data-cy="automatic-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="arrow-right-circle"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Automatic</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap.auto"
                    class="launch-method--item"
                    data-cy="automatic-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="half-loader"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Automatic</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap.badge"
                    class="launch-method--item"
                    data-cy="badge-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="info"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Badge</span>
                </div>
                <div
                    v-if="activeIsDigitalAdoption && activeLaunchMethodsMap.confirmation"
                    class="launch-method--item"
                    data-cy="confirmation-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="confirmation"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Confirmation</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap.feature"
                    class="launch-method--item"
                    data-cy="feature-click-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="cursor"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Feature Click</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap.page"
                    class="launch-method--item"
                    data-cy="page-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="file"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Page</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && activeLaunchMethodsMap.launcher"
                    class="launch-method--item"
                    data-cy="resource-center-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="adopt-resource-center"
                        stroke-width="0"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Resource Center</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.apiReseller && targetElement"
                    class="launch-method--item"
                    data-cy="target-element-activation-icon">
                    <pendo-icon
                        :size="30"
                        type="target-element"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Target Element</span>
                </div>
                <div
                    v-if="activeLaunchMethodsMap.apiReseller"
                    class="launch-method--item"
                    data-cy="programmatic-activation-icon">
                    <activation-api class="launch-method--item--icon" />
                    <span class="launch-method--item--text">{{ apiResellerActivation }}</span>
                </div>
            </div>
            <div
                v-if="isMobileTooltip && !hasMobileTooltipActivation"
                class="card-body">
                <pendo-alert
                    type="warning"
                    class="tooltip-warning-alert">
                    <span>
                        To publish your tooltip guide, please select a page to place it on. To select a page, go to
                        ‘manage in my app’ (or
                        <a
                            class="warning-message-action"
                            @click="editGuideActivation">click here</a>)
                    </span>
                </pendo-alert>
            </div>
        </template>
        <template
            #footer
            v-if="!isEditing">
            <div class="activation-text">
                <div class="activation-text--label">
                    How does this guide activate?
                </div>
                {{ launchText }}
            </div>
            <div
                v-if="pageLocation && !trackEventName"
                class="page-location">
                <div class="page-location--label">
                    Page Location
                </div>
                {{ pageLocation }}
            </div>
            <div
                v-if="targetElement && !featureName"
                class="target-element">
                <div class="target-element--label">
                    Element
                </div>
                {{ targetElement }}
            </div>
            <div
                v-if="featureName"
                class="target-feature">
                <div class="target-feature--label">
                    Feature
                </div>
                {{ featureName }}
            </div>
            <div
                v-if="elementContainsRuleText"
                class="element-contains-rule">
                <div class="target-feature--label">
                    Element Contains Rule
                </div>
                {{ elementContainsRuleText }}
            </div>
            <div
                v-if="trackEventName"
                class="page-location">
                <div class="page-location--label">
                    Track Event
                </div>
                {{ trackEventName }}
            </div>
            <div
                v-if="trackEventPropertyMessage"
                class="page-location">
                <div class="page-location--label">
                    Track Event properties
                </div>
                {{ trackEventPropertyMessage }}
            </div>
        </template>
    </pendo-card>
</template>

<script>
import get from 'lodash/get';
import { PendoCard, PendoButton, PendoAlert, PendoIcon, PendoToggle } from '@pendo/components';
import ActivationApi from '@/stateless-components/icons/ActivationApi';
import { isConfirmationGuide, LAUNCH_METHODS, MATCH_TYPES_TO_TEXT_MAP } from '@/stateless-components/utils/guides';
import { propName } from '@/utils/utils';

export default {
    name: 'ActivationCard',
    components: {
        PendoCard,
        PendoButton,
        PendoAlert,
        PendoIcon,
        ActivationApi,
        PendoToggle
    },
    props: {
        guide: {
            type: Object,
            default: () => ({})
        },
        featureName: {
            type: String,
            default: ''
        },
        pageLocation: {
            type: String,
            default: ''
        },
        activeIsDigitalAdoption: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        isMobileGuide: {
            type: Boolean,
            default: false
        },
        isMobileTooltip: {
            type: Boolean,
            default: false
        },
        hasMobileTooltipActivation: {
            type: Boolean,
            default: false
        },
        trackEventName: {
            type: String,
            default: ''
        },
        trackEventPropertyMessage: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            automaticActivationToggle: false
        };
    },
    computed: {
        targetElement () {
            return get(this.guide, 'steps[0].elementPathRule', null);
        },
        launchMethod () {
            if (!this.guide.launchMethod) {
                return this.isMobileGuide ? [LAUNCH_METHODS.appLaunch] : [LAUNCH_METHODS.auto];
            }

            // currently we support only one type of mobile tooltip activation
            if (this.isMobileTooltip && this.hasMobileTooltipActivation) {
                return [LAUNCH_METHODS['page-auto']];
            }

            return this.guide.launchMethod.split('-').map((type) => {
                if (type === 'badge' && isConfirmationGuide(this.guide)) {
                    type = 'confirmation';
                } else if (type === 'auto' && this.isMobileGuide) {
                    type = 'appLaunch';
                }

                return LAUNCH_METHODS[type];
            });
        },
        apiResellerActivation () {
            return this.isMobileGuide ? 'None' : 'Programmatically';
        },
        launchText () {
            if (this.isMobileTooltip && !this.hasMobileTooltipActivation) {
                return '---';
            }

            if (this.guide.launchMethod !== 'api') {
                return 'This guide appears '.concat(this.launchMethod.map((method) => method.text).join('; and '));
            }
            if (this.activeIsDigitalAdoption) {
                return LAUNCH_METHODS.apiDA.text;
            }

            if (this.isMobileGuide) {
                return LAUNCH_METHODS.apiReseller.mobileText;
            }

            return LAUNCH_METHODS.apiReseller.text;
        },
        activeLaunchMethodsMap () {
            if (!this.launchMethod) {
                return this.isMobileGuide ? { appLaunch: true } : { auto: true };
            }

            if (this.guide.launchMethod === 'api') {
                return this.activeIsDigitalAdoption ? { apiDA: true } : { apiReseller: true };
            }

            return this.launchMethod.reduce((map, method) => {
                if (method === LAUNCH_METHODS.auto && this.isMobileGuide) {
                    map[propName(LAUNCH_METHODS, LAUNCH_METHODS.appLaunch)] = true;

                    return map;
                }
                map[propName(LAUNCH_METHODS, method)] = true;

                return map;
            }, {});
        },
        automaticActivation () {
            return get(this.activeLaunchMethodsMap, 'auto', false);
        },
        userHasChanges () {
            return this.automaticActivationToggle !== this.automaticActivation;
        },
        elementContainsRuleText () {
            let elementContainsRuleText = '';
            const elementContainsRule = get(this.guide, 'steps[0].elementContainsRulesList[0]', null);
            if (elementContainsRule) {
                const elementContainsRuleDataType = elementContainsRule.dataType === 'string' ? 'Text' : 'Number';
                const elementContainsRuleMatchType = MATCH_TYPES_TO_TEXT_MAP[elementContainsRule.matchType];
                elementContainsRuleText = `${elementContainsRuleDataType} ${elementContainsRuleMatchType} ${elementContainsRule.matchValue}`;
            }

            return elementContainsRuleText;
        }
    },
    methods: {
        editGuideActivation () {
            const [step] = this.guide.steps;
            const type = 'guide:activation';

            return this.$emit('handleManageGuide', {
                type,
                step
            });
        },
        editAutomaticActivation (isEditing) {
            this.automaticActivationToggle = this.automaticActivation;
            this.$emit('updateIsEditing', isEditing);
        },
        updateLaunchMethod () {
            this.$emit('updateLaunchMethod', this.automaticActivationToggle);
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep .pendo-card__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.launch-method {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;

    &--item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        &.disabled {
            opacity: 0.4;
        }

        &--text {
            text-transform: uppercase;
            font-size: 0.875em;
            margin-top: 1em;
        }
    }
}

.warning-message-action {
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
}

.activation-text,
.page-location,
.target-element,
.target-feature,
.element-contains-rule,
.throttle-override {
    color: $gray-lighter-2;
    font-weight: 400;
    line-height: 1.5em;
    padding-bottom: 0.5em;

    &--label {
        font-weight: 700;
        color: $gray-primary;
        display: flex;
        justify-content: space-between;
    }
}
</style>
