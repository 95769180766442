<template>
    <pendo-page-content>
        <paths-list />
    </pendo-page-content>
</template>

<script>
import { PendoPageContent } from '@pendo/components';
import PathsList from '@/components/paths/list/PathsList';

export default {
    name: 'PathsContent',
    components: {
        PendoPageContent,
        PathsList
    },
    data () {
        return {
            activeTab: 'pathsDashboard',
            tabs: [
                {
                    prop: 'pathsDashboard',
                    label: 'Paths'
                }
            ]
        };
    }
};
</script>
