<template>
    <pendo-card title="Scheduling">
        <template
            v-if="canEdit"
            #headerRight>
            <pendo-button
                v-if="!isEditing"
                type="link"
                prefix-icon="edit-2"
                label="Edit"
                @click="toggleEditing(true)" />
            <pendo-button
                v-if="isEditing"
                theme="app"
                type="tertiary"
                label="Cancel"
                @click="toggleEditing(false)" />
            <pendo-button
                v-if="isEditing"
                :disabled="!isDateValid"
                theme="app"
                type="primary"
                size="mini"
                label="Save"
                @click="updateGuide" />
        </template>
        <template #body>
            <div
                v-if="!isEditing"
                class="schedule-info">
                <div class="schedule-info--date">
                    {{ primaryDate }}
                </div>
                <div
                    v-if="primaryTime"
                    class="schedule-info--time">
                    {{ primaryTime }}
                </div>
                <div
                    v-if="scheduleText"
                    class="schedule-info--text">
                    {{ scheduleText }}
                </div>
            </div>
            <div
                v-if="isEditing"
                class="schedule-edit">
                <div
                    v-if="!isDateValid"
                    class="schedule-edit-row">
                    <pendo-alert
                        :center="true"
                        title="Start date must come before expiration date."
                        type="error" />
                </div>
                <div class="schedule-edit--row">
                    <div class="schedule-edit--row--body">
                        <pendo-radio-group
                            :value="selectedFrequency"
                            :labels="{ top: 'Delivery Frequency' }"
                            class="schedule-edit--frequency-chooser"
                            direction="vertical"
                            @change="toggleFrequency">
                            <pendo-radio
                                v-for="option in frequencyOptions"
                                :key="option.value"
                                v-bind="option" />
                        </pendo-radio-group>
                        <div
                            v-if="selectedFrequency === 'customInterval'"
                            class="frequency--interval">
                            <pendo-input-number
                                :value="frequencyCount"
                                :always-show-arrows="true"
                                :max="365"
                                width="80px"
                                class="frequency--interval--count"
                                @change="setFrequency" />
                            <span>Day(s)</span>
                        </div>
                        <span
                            v-if="selectedFrequency === 'oneTimeOnly'"
                            class="frequency--onetime-warning">
                            This guide will show until the user dismisses the guide.
                        </span>
                    </div>
                </div>
                <div class="schedule-edit--row">
                    <div
                        class="schedule-edit--row--body schedule-edit--date-time"
                        data-cy="edit-showsafter">
                        <pendo-multiselect
                            :value="startDateTime"
                            :options="[
                                {
                                    label: 'Immediately',
                                    value: false
                                },
                                {
                                    label: 'Start On',
                                    value: true
                                }
                            ]"
                            :popper-options="{ class: 'schedule-edit--start-panel' }"
                            full-width
                            value-key="value"
                            class="schedule-edit--start"
                            @select="toggleShowsAfterUpdated">
                            <template #topLabel>
                                Start Date / Time
                                <pendo-icon
                                    v-pendo-tooltip="{ arrow: true, content: tooltipText, multiline: true }"
                                    size="14"
                                    display="inline"
                                    type="info" />
                            </template>
                        </pendo-multiselect>
                        <pendo-date-picker
                            :value="showsAfterDate"
                            :disabled="!hasShowsAfter"
                            is-required
                            min-trigger-width="100%"
                            placeholder="--"
                            class="schedule-edit--date"
                            @change="setShowsAfterDate" />
                        <pendo-time-picker
                            :value="showsAfterTime"
                            :disabled="!hasShowsAfter"
                            placeholder="--"
                            class="schedule-edit--time"
                            @change="setShowsAfterTime" />
                    </div>
                </div>
                <div class="schedule-edit--row">
                    <div
                        class="schedule-edit--row--body schedule-edit--date-time"
                        data-cy="edit-expiresat">
                        <pendo-multiselect
                            :labels="{ top: 'Expiration Date / Time' }"
                            :value="expirationDateTime"
                            :options="[
                                {
                                    label: 'Never',
                                    value: false
                                },
                                {
                                    label: 'Expire On',
                                    value: true
                                }
                            ]"
                            :popper-options="{ class: 'schedule-edit--expire-panel' }"
                            full-width
                            value-key="value"
                            class="schedule-edit--expire"
                            @select="toggleExpiresAfterUpdated" />
                        <pendo-date-picker
                            :value="expiresAfterDate"
                            :disabled="!hasExpiresAfter"
                            is-required
                            min-trigger-width="100%"
                            placeholder="--"
                            class="schedule-edit--date"
                            @change="setExpiresAfterDate" />
                        <pendo-time-picker
                            :value="expiresAfterTime"
                            :disabled="!hasExpiresAfter"
                            placeholder="--"
                            class="schedule-edit--time"
                            @change="setExpiresAfterTime" />
                    </div>
                </div>
            </div>
        </template>
        <template
            #footer
            v-if="!isEditing">
            <div class="schedule-footer">
                <div class="delivery-frequency">
                    <div class="delivery-frequency--label">
                        Delivery Frequency
                    </div>
                    {{ fullFrequency }}
                </div>
                <div class="start-date">
                    <div class="start-date--label">
                        Start Date / Time
                    </div>
                    {{ fullStart }}
                </div>
                <div class="expiration-date">
                    <div class="expiration-date--label">
                        Expiration Date / Time
                    </div>
                    {{ fullExpire }}
                </div>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import {
    PendoAlert,
    PendoButton,
    PendoCard,
    PendoDatePicker,
    PendoIcon,
    PendoInputNumber,
    PendoRadioGroup,
    PendoRadio,
    PendoTimePicker,
    PendoMultiselect,
    PendoLoading,
    PendoTooltip
} from '@pendo/components';
import moment, { convertToSubscriptionTimezone, splitTimeParts, DATE_FORMAT } from '@/utils/moment';

const ONE_DAY = 1000 * 60 * 60 * 24;

export default {
    name: 'GuideSchedule',
    components: {
        PendoAlert,
        PendoButton,
        PendoCard,
        PendoDatePicker,
        PendoIcon,
        PendoInputNumber,
        PendoRadioGroup,
        PendoRadio,
        PendoTimePicker,
        PendoMultiselect
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        activeTimezone: {
            type: String,
            required: true
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        updating: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isEditing: false,
            hasExpiresAfter: false,
            hasShowsAfter: false,
            frequencyOptions: [
                {
                    value: 'oneTimeOnly',
                    label: 'One Time Only'
                },
                {
                    value: 'customInterval',
                    label: 'Custom Interval'
                }
            ],
            showsAfterDate: this.guide.showsAfter,
            expiresAfterDate: this.guide.expiresAfter,
            recurrence: this.guide.recurrence
        };
    },
    computed: {
        startDateTime () {
            if (this.hasShowsAfter) {
                return {
                    label: 'Start On',
                    value: true
                };
            }

            return {
                label: 'Immediately',
                value: false
            };
        },
        expirationDateTime () {
            if (this.hasExpiresAfter) {
                return {
                    label: 'Expire On',
                    value: true
                };
            }

            return {
                label: 'Never',
                value: false
            };
        },
        primaryDate () {
            if (!this.primaryDisplayProp) {
                return '---';
            }

            return convertToSubscriptionTimezone(this.activeTimezone, this.guide[this.primaryDisplayProp]).format(
                DATE_FORMAT.short
            );
        },
        primaryTime () {
            if (!this.primaryDisplayProp) {
                return '';
            }

            return convertToSubscriptionTimezone(this.activeTimezone, this.guide[this.primaryDisplayProp]).format(
                DATE_FORMAT.time
            );
        },
        scheduleText () {
            let text;

            switch (this.primaryDisplayProp) {
                case 'showsAfter':
                    text = 'start date';
                    break;
                case 'expiresAfter':
                    text = 'expiration date';
                    break;
                default:
                    text = '';
            }

            return text;
        },
        primaryDisplayProp () {
            const { expiresAfter, showsAfter } = this.guide;
            const hasExpDate = !!expiresAfter;
            const hasStartDate = !!showsAfter;
            let type;

            if (hasExpDate && hasStartDate) {
                const hasStarted = showsAfter < Date.now();
                if (hasStarted) {
                    type = 'expiresAfter';
                } else {
                    type = 'showsAfter';
                }
            } else if (hasExpDate) {
                type = 'expiresAfter';
            } else if (hasStartDate) {
                type = 'showsAfter';
            } else {
                type = '';
            }

            return type;
        },
        fullStart () {
            return this.formatFullDate(this.guide.showsAfter) || 'Immediately';
        },
        fullExpire () {
            return this.formatFullDate(this.guide.expiresAfter) || 'Never';
        },
        fullFrequency () {
            if (this.selectedFrequency === 'oneTimeOnly') {
                return 'One Time Only';
            }

            return `Every ${this.frequencyCount} Day(s)`;
        },
        isDateValid () {
            const { hasExpiresAfter, hasShowsAfter } = this;

            return !hasExpiresAfter || !hasShowsAfter || this.showsAfterDate < this.expiresAfterDate;
        },
        selectedFrequency () {
            // guide.recurrence == 0, undefined, or null means `oneTimeOnly`
            return this.recurrence ? 'customInterval' : 'oneTimeOnly';
        },
        tooltipText () {
            let text;

            if (this.guide.state === 'public') {
                text = 'The Guide is currently PUBLIC. Changing these settings will impact guide delivery.';
            } else {
                text = 'This guide will appear for users as soon as it is set to PUBLIC status.';
            }

            return text;
        },
        frequencyCount () {
            return this.recurrence / ONE_DAY;
        },
        showsAfterTime () {
            if (!this.showsAfterDate) {
                return null;
            }

            return moment(this.showsAfterDate)
                .tz(moment.tz.guess())
                .format(DATE_FORMAT.time);
        },
        expiresAfterTime () {
            if (!this.expiresAfterDate) {
                return null;
            }

            return moment(this.expiresAfterDate)
                .tz(moment.tz.guess())
                .format(DATE_FORMAT.time);
        }
    },
    watch: {
        updating (newVal, oldVal) {
            if (oldVal === true && newVal === false) {
                this.toggleEditing(false);
            }
        }
    },
    methods: {
        formatFullDate (timestamp) {
            if (!timestamp) {
                return '';
            }

            return convertToSubscriptionTimezone(this.activeTimezone, timestamp).format(DATE_FORMAT.full);
        },
        toggleEditing (isEditing) {
            if (isEditing) {
                this.hasExpiresAfter = !!this.expiresAfterDate;
                this.hasShowsAfter = !!this.showsAfterDate;
            }
            this.isEditing = isEditing;
        },
        updateGuide () {
            const props = {
                expiresAfter: this.expiresAfterDate,
                showsAfter: this.showsAfterDate,
                recurrence: this.recurrence || 0
            };
            const isGuideScheduled = this.guide.showsAfter && this.guide.showsAfter > Date.now();

            // if a guide is scheduled to display in the future but the start date is removed
            // set the guide to `draft` to prevent it from becoming public immediately
            if (isGuideScheduled && !props.showsAfter) {
                props.state = 'draft';
            }

            this.$emit('submit', {
                props,
                guideId: this.guide.id
            });
        },
        toggleExpiresAfterUpdated ({ value }) {
            this.hasExpiresAfter = value;
            if (!value) {
                this.expiresAfterDate = null;
            } else {
                const add14days = Date.now() + 14 * ONE_DAY;
                this.expiresAfterDate = add14days;
            }
        },
        toggleShowsAfterUpdated ({ value }) {
            this.hasShowsAfter = value;
            if (!value) {
                this.showsAfterDate = null;
            } else {
                this.showsAfterDate = Date.now();
            }
        },
        mergeDateAndTime (date, time) {
            const { hours, minutes } = splitTimeParts(time);
            let newDate = date;

            if (!(date instanceof Date)) {
                newDate = new Date(date);
            }

            newDate.setHours(hours);
            newDate.setMinutes(minutes);

            return newDate.valueOf();
        },
        toggleFrequency (val) {
            const value = val === 'oneTimeOnly' ? 0 : this.recurrence || ONE_DAY;

            this.recurrence = value;
        },
        setFrequency (days) {
            this.recurrence = days * ONE_DAY;
        },
        setShowsAfterDate (date) {
            if (!date) {
                this.showsAfterDate = null;

                return;
            }
            const next = this.mergeDateAndTime(date, this.showsAfterTime);
            this.showsAfterDate = next;
        },
        setShowsAfterTime (time) {
            if (!time) {
                this.showsAfterDate = null;

                return;
            }
            const next = this.mergeDateAndTime(this.showsAfterDate, time);
            this.showsAfterDate = next;
        },
        setExpiresAfterDate (date) {
            if (!date) {
                this.expiresAfterDate = null;

                return;
            }
            const next = this.mergeDateAndTime(date, this.expiresAfterTime);
            this.expiresAfterDate = next;
        },
        setExpiresAfterTime (time) {
            if (!time) {
                this.expiresAfterDate = null;

                return;
            }
            const next = this.mergeDateAndTime(this.expiresAfterDate, time);
            this.expiresAfterDate = next;
        }
    }
};
</script>

<style scoped lang="scss">
.pendo-card__body {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
}

.pendo-card__footer {
    max-height: 155px;
}

.schedule-info {
    display: grid;
    justify-items: center;
    align-self: center;
    grid-gap: 0.5rem;

    &--date {
        font-size: 2em;
        line-height: 1em;
    }

    &--time,
    &--text {
        line-height: 1em;
        color: $gray-lighter-2;
    }

    &--time {
        font-size: 1.25em;
    }

    &--text {
        font-size: 0.875em;
        text-transform: uppercase;
    }
}

.schedule-edit {
    display: grid;
    grid-gap: 0.5em;
    grid-template-rows: max-content;

    &--row {
        margin-bottom: 1em;

        &--header {
            margin-bottom: 0.5em;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .pendo-icon {
                margin-left: 0.5em;

                > svg {
                    stroke: $gray-lighter-4;
                }
            }
        }

        &--body {
            display: grid;
        }
    }

    &--date-time {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        align-items: end;
        grid-gap: 8px;
    }

    &--date > div > span {
        width: 100%;
    }

    .shows-after-toggle,
    .expires-after-toggle {
        position: relative;
        justify-content: flex-start;

        .icon-suffix {
            position: absolute;
            right: 8px;
        }
    }

    &--frequency-chooser {
        margin-bottom: 1em;
    }

    .frequency--interval,
    .frequency--onetime-warning {
        margin-bottom: 1em;
    }

    .frequency--onetime-warning {
        margin-bottom: 1em;
        color: $gray-lighter-3;
        font-size: 0.875em;
        line-height: 1.25em;
    }

    .frequency--interval {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 0;

        &--count {
            margin-right: 0.5em;
        }
    }

    @media (max-width: 600px) {
        &--toggle,
        &--date,
        &--time {
            width: 100%;

            &:last-of-type {
                margin-bottom: 0;
            }

            button,
            .pendo-date-picker,
            .pendo-time-picker {
                width: 100%;
            }
        }
    }
}

.schedule-footer {
    .delivery-frequency,
    .start-date,
    .expiration-date {
        color: $gray-lighter-2;
        line-height: 1.5em;
        padding-bottom: 0.5em;

        &--label {
            font-weight: 700;
            color: $gray-primary;
        }
    }
}
</style>

<style lang="scss">
.schedule-edit--start {
    .pendo-multiselect__label--top {
        grid-auto-flow: column;
        grid-template-columns: repeat(2, max-content);
        grid-gap: 8px;
    }
}
</style>
