import uniq from 'lodash/uniq';
import { operators as o } from '@pendo/aggregations';
import { buildGroupValueGrouping, formatStartAndEndStepsForAgg } from '@/aggregations/utils';
import { identifiedState, parseSegmentIdForAggregation, PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/utils/aggregations';

export default {
    name: 'WorkflowVisitorsTable',
    productArea: PRODUCT_AREAS?.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS?.WORKFLOWS_JOURNEYS,
    responseFormat: 'rows',
    build: ({ workflow, segmentId, dateRange, metadataOption }) => {
        return workflowVisitorsTableAgg(workflow, segmentId, dateRange, metadataOption);
    }
};

function workflowVisitorsTableAgg (
    { classification, maxDuration, workflowSteps },
    segmentId,
    dateRange,
    metadataOption
) {
    if (!metadataOption) throw new Error('No metadata option provided');
    const { kind, group, field } = metadataOption;
    const groupValue = `${kind}.${group}.${field}`;

    const isRecurring = classification === 'recurring';
    const aggTimeSeries = {
        period: 'dayRange',
        first: `date("${dateRange.value.start}")`,
        last: `date("${dateRange.value.end}")`
    };

    const steps = formatStartAndEndStepsForAgg(workflowSteps);

    const appIds = uniq([steps.start.appId, steps.end.appId]);

    const groupValueGroupingFields = [
        {
            completedAttempts: {
                countIf: {
                    count: null,
                    if: 'funnel.steps == 2'
                }
            }
        },
        {
            totalVisitors: {
                count: 'visitorId'
            }
        },
        {
            completedVisitors: {
                countIf: {
                    count: 'visitorId',
                    if: 'maxStep == 2'
                }
            }
        },
        {
            incompleteVisitors: {
                countIf: {
                    count: 'visitorId',
                    if: 'maxStep == 1'
                }
            }
        },
        {
            notStartedVisitors: {
                countIf: {
                    count: 'visitorId',
                    if: 'isNull(maxStep)'
                }
            }
        },
        {
            totalTimeToCompletion: {
                sum: 'if(funnel.steps == 2, funnel.times[1]-funnel.times[0], 0)'
            }
        },
        {
            nonRecurringTotalTimeToCompletion: {
                sum: 'if(funnel.start == latestCompletionStartTime, funnel.times[1]-funnel.times[0], 0)'
            }
        }
    ];

    if (isRecurring) {
        groupValueGroupingFields.unshift({
            incompleteAttempts: {
                countIf: {
                    count: null,
                    if: 'funnel.steps == 1'
                }
            }
        });
    }

    return o.pipeline(
        o.sources.singleEvents({ appId: appIds, timeSeries: aggTimeSeries }),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        o.group('visitorId', o.groupField('funnel', o.funnel([steps.start, steps.end], maxDuration, maxDuration))),
        o.fork(
            o.pipeline(o.unwind('funnel'), o.group('visitorId', o.groupField('maxStep', { max: 'funnel.steps' }))),
            o.pipeline(o.cat())
        ),
        o.join('visitorId'),
        o.fork(
            o.pipeline(
                o.unwind('funnel', { keepEmpty: true }),
                o.filter('funnel.steps == 2'),
                o.group('visitorId', o.groupField('latestCompletionStartTime', { max: 'funnel.start' }))
            ),
            o.pipeline(o.cat())
        ),
        o.join('visitorId'),
        o.bulkExpand('visitor', 'visitor', 'visitorId'),
        o.unwind('funnel', { keepEmpty: true }),
        o.evalExpression({
            groupValue: `if(isNull(${groupValue}), "(Not Specified)", ${groupValue})`
        }),
        o.evalExpression({
            groupValue: 'if(isNumber(groupValue), toString(groupValue), groupValue)'
        }),
        o.evalExpression({
            groupValue: 'if(groupValue == true, "true", if(groupValue == false, "false", groupValue))'
        }),
        ...buildGroupValueGrouping({
            columns: ['groupValue'],
            fields: groupValueGroupingFields
        }),
        o.evalExpression({
            ...(isRecurring
                ? {
                    totalAttempts: 'incompleteAttempts + completedAttempts',
                    avgNumberCompleteAttempts: 'if(completedVisitors > 0, completedAttempts / completedVisitors)',
                    avgTimeToCompletion: 'if(completedAttempts > 0, totalTimeToCompletion / completedAttempts)'
                }
                : {
                    avgTimeToCompletion:
                          'if(completedAttempts > 0, nonRecurringTotalTimeToCompletion / completedVisitors)'
                })
        }),
        o.select({
            ...(isRecurring
                ? {
                    avgNumberCompleteAttempts: 'avgNumberCompleteAttempts',
                    avgNumberIncompleteAttempts: 'if(incompleteVisitors > 0, incompleteAttempts/incompleteVisitors)',
                    totalAttempts: 'totalAttempts',
                    incompleteAttempts: 'incompleteAttempts',
                    completedAttempts: 'completedAttempts'
                }
                : {}),
            avgTimeToCompletion: 'avgTimeToCompletion',
            notStartedVisitors: 'notStartedVisitors',
            completeVisitors: 'completedVisitors',
            incompleteVisitors: 'incompleteVisitors',
            totalVisitors: 'totalVisitors',
            metadata: 'groupValue'
        })
    );
}
