<template>
    <div
        :id="drawerId"
        class="pendo-dropdown-button-split">
        <pendo-multiselect
            :disabled="disabled"
            :min-menu-width="100"
            :options="options"
            @select="select">
            <template #trigger>
                <pendo-split-button-trigger
                    type="link"
                    theme="app"
                    label="Preview"
                    prefix-icon="play-circle"
                    @click="preview" />
            </template>
        </pendo-multiselect>
    </div>
</template>

<script>
import { PendoMultiselect, PendoSplitButtonTrigger } from '@pendo/components';

export default {
    name: 'DropdownButtonSplit',
    components: {
        PendoMultiselect,
        PendoSplitButtonTrigger
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            preview_setting_option_id: `options-${this._uid}`
        };
    },
    computed: {
        options () {
            return [
                {
                    id: this.preview_setting_option_id,
                    label: 'Preview options',
                    icon: {
                        type: 'chevron-down'
                    }
                }
            ];
        },
        drawerId () {
            return `drawer-${this._uid}`;
        }
    },
    methods: {
        preview () {
            this.$emit('preview');
        },
        select ({ id }) {
            if (id !== this.preview_setting_option_id) return;
            this.openSettings();
        },
        openSettings () {
            this.$emit('open-settings');
        }
    }
};
</script>

<style>
.dropdown-button-split__drawer {
    position: absolute;
}
</style>
