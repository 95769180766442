<template>
    <div class="visitor-details">
        <pendo-page-content tabs>
            <pendo-tabs
                :tabs="tabs"
                :value="activeTab"
                theme="app"
                class="visitor-list-tabs">
                <template #visitorDetails>
                    <metadata-table
                        class="visitor-details--table"
                        :loading="isFetchingVisitor"
                        title="Auto"
                        :data="autoTableData" />
                    <metadata-table
                        class="visitor-details--table"
                        :loading="isFetchingVisitor"
                        title="Agent"
                        :data="agentTableData" />
                    <metadata-table
                        class="visitor-details--table"
                        :loading="isFetchingVisitor"
                        title="Custom"
                        :visitor-id="visitorId"
                        :data="customTableData"
                        editable />
                </template>
            </pendo-tabs>
        </pendo-page-content>
    </div>
</template>

<script>
import { PendoTabs, PendoPageContent } from '@pendo/components';
import MetadataTable from './MetadataTable';
import { fetchVisitorMetadata, defaultAutoMetadataFieldsList } from '@/utils/metadata';
import { isCancel } from 'axios';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { augmentSingleVisitorObjectWithLastActiveApp } from '@/utils/metadata';

export default {
    name: 'VisitorDetailsContent',
    components: {
        MetadataTable,
        PendoPageContent,
        PendoTabs
    },
    props: {
        visitorId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            activeTab: 'visitorDetails',
            tabs: [
                {
                    prop: 'visitorDetails',
                    label: 'Visitor Details'
                }
            ],
            isFetchingVisitor: false,
            agentTableData: [],
            customTableData: [],
            autoTableData: [],
            currentVisitor: {}
        };
    },
    computed: {
        ...mapGetters({
            schemaList: 'filters/schemaList',
            appMapForActiveSubscription: 'apps/appMapForActiveSubscription'
        })
    },
    async created () {
        await this.fetchVisitorAndSetTableData();
    },
    methods: {
        getSchemaByGroup (groupName) {
            return this.schemaList.filter((schema) => schema.group === groupName);
        },
        getMetadataFieldsList (type) {
            switch (type) {
                case 'custom':
                    return this.getSchemaByGroup('custom');
                case 'agent':
                    return this.getSchemaByGroup('agent').filter((object) =>
                        get(this.currentVisitor, `${object.type}`)
                    );
                case 'auto':
                    return defaultAutoMetadataFieldsList.filter((object) => get(this.currentVisitor, `${object.type}`));
            }

            return [];
        },
        async fetchVisitorAndSetTableData () {
            this.isFetchingVisitor = true;
            try {
                this.currentVisitor = await fetchVisitorMetadata(this.visitorId);
                augmentSingleVisitorObjectWithLastActiveApp(this.currentVisitor, this.appMapForActiveSubscription);
                this.agentTableData = this.getTableData('agent');
                this.customTableData = this.getTableData('custom');
                this.autoTableData = this.getTableData('auto');
                this.isFetchingVisitor = false;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                if (!isCancel(err)) {
                    this.isFetchingVisitor = false;
                }
                this.$router.push({ name: 'visitorsDashboard' }).catch(() => {});
            }
        },
        getTableData (type) {
            const result = [];
            const metadataFieldsList = this.getMetadataFieldsList(type);
            const typePrefix = `visitor.${type}.`;

            metadataFieldsList.forEach((singleMetadata) => {
                const fieldName = singleMetadata.field.split(typePrefix)[1];
                const { name, schema } = singleMetadata;
                const singleMetadataObject = {
                    schema,
                    name,
                    value: get(this.currentVisitor, `${singleMetadata.type}`, null),
                    field: fieldName
                };
                result.push(singleMetadataObject);
            });

            return result;
        }
    }
};
</script>
<style lang="scss">
.visitor-details {
    .visitor-details--table {
        &:not(:first-child) {
            margin-top: 32px;
        }
    }
}
</style>
