import { http } from '@pendo/http';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import semver from 'semver';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

export function environmentBucket (useCdn = false, appEnvId = window.__via_info__.env) {
    let env;
    switch (appEnvId) {
        case 'local':
            env = 'devserver';
            break;
        case 'prod-eu':
            env = 'eu';
            break;
        case 'prod-us1':
            env = 'us1';
            break;
        case 'prod':
            env = 'io';
            break;
        case 'prod-jp':
            env = 'jp-prod';
            break;
        case 'prod-hsbc':
            env = 'hsbc';
            break;
        // all test/team env fallthrough here
        default:
            env = appEnvId;
            break;
    }

    if (useCdn) {
        switch (env) {
            case 'eu':
                return 'cdn.eu.pendo.io';
            case 'io':
                return 'cdn.pendo.io';
            case 'us1':
                return 'us1.cdn.pendo.io';
            case 'jp-prod':
                return 'cdn.jpn.pendo.io';
            case 'hsbc':
                return 'cdn.hsbc.pendo.io';
            case 'test':
                return 'staging-cdn.pendo-dev.com';
            case 'eu-dev':
                return `cdn.pendo-${env}.pendo-dev.com`;
        }
    }

    return `pendo-${env}-static.storage.googleapis.com`;
}

export function contentHost (app) {
    if (app?.applicationFlags.cname && app?.contentCname) {
        return app.contentCname;
    }

    return environmentBucket(true);
}

export function supportsJWTIfEnabled (version, app) {
    const isSecureSnippetEnabled = get(app.applicationFlags, 'enableSignedMetadata', false);
    if (isSecureSnippetEnabled) {
        return semver.satisfies(version, '>=2.56.0');
    }

    return true;
}

export function supportsLightning (version) {
    return semver.satisfies(version, '>=2.11.3');
}

export function formatLabel (activeTimezone, version, versionNum, timestamp, isLatest) {
    const releasedDate = `${convertToSubscriptionTimezone(activeTimezone, timestamp).format(DATE_FORMAT.short)}`;

    return [
        version.deprecated ? '!! ' : '',
        `Version ${versionNum} `,
        `released ${releasedDate}`,
        isLatest ? ' (Latest Stable)' : '',
        version.deprecated ? ' (deprecated)' : ''
    ].join('');
}

export async function getAgentVersions (envBucket) {
    const { data: agentVersions } = await http.get(`https://${envBucket}/agent/releases/versions.json`, {
        withCredentials: false
    });
    agentVersions.latest = cloneDeep(agentVersions[agentVersions.latest]);
    agentVersions.stable = cloneDeep(agentVersions[agentVersions.stable]);
    agentVersions.beta = cloneDeep(agentVersions[agentVersions.beta]);

    return agentVersions;
}

export function getEnvSettings ({ agent, config, activeTimezone }) {
    const agentSupportsLightning = supportsLightning(agent.agentVersion);
    const { agentVersion, agentType } = agent;
    const agentReleaseDate = convertToSubscriptionTimezone(activeTimezone, agent.agentReleasedAt).format(
        DATE_FORMAT.short
    );
    const description = config.name;
    const agentPolicy = getAgentPolicy(config);

    return {
        agentSupportsLightning,
        agentVersion,
        agentType,
        agentReleaseDate,
        description,
        agentPolicy
    };
}

export function getAgentPolicy ({ agentUpdatePolicy }) {
    return ['stable', 'beta'].includes(agentUpdatePolicy) ? agentUpdatePolicy : 'manual';
}

export async function fetchEnv ({ env = 'production', appId }) {
    const response = await http.get(`/api/s/_SID_/environment/${env}?appId=${appId}`);

    return response.data;
}

export async function patchEnv ({ appId, description, agentType, agentUpdatePolicy }) {
    return http.patch(`/api/s/_SID_/environment/${description}/config?appId=${appId}`, {
        agentType,
        agentUpdatePolicy,
        description: `${capitalize(description)} environment`
    });
}

export async function checkStagingServer () {
    const response = await http.get('/api/s/_SID_/staging/status');

    return response.data.hasStagingServers;
}
