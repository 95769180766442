import { operators as o } from '@pendo/aggregations';
import { parseSegmentIdForAggregation, identifiedState, PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/utils/aggregations';
import lowerCase from 'lodash/lowerCase';

export default {
    name: 'CompletedJourneys',
    productArea: PRODUCT_AREAS?.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS?.WORKFLOWS_JOURNEYS,
    responseFormat: 'rows',
    build: ({
        workflowStart,
        workflowEnd,
        pageIds,
        featureIds,
        appIds,
        timeSeries,
        segmentId,
        maxDuration = 86400
    }) => {
        const startNode = createCountable(workflowStart.countableKind, workflowStart.countableId);
        const endNode = createCountable(workflowEnd.countableKind, workflowEnd.countableId);
        const pageCountables = pageIds.map((pageId) => createCountable('Page', pageId));
        const featureCountables = featureIds.map((featureId) => createCountable('Feature', featureId));

        return o.pipeline(
            o.sources.singleEvents({
                appId: appIds,
                timeSeries: { ...timeSeries, period: 'dayRange' },
                eventClass: ['ui']
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(
                ['visitorId'],
                o.groupField('path', {
                    path: {
                        ...startNode,
                        maxInterval: maxDuration,
                        followAcrossSessions: false,
                        predecessors: false,
                        omitPages: false,
                        features: true,
                        trackEvents: false,
                        collapseDups: true,
                        maxLength: 20,
                        skipUnknownEvent: true,
                        items: [...pageCountables, ...featureCountables, endNode]
                    }
                })
            ),
            o.unwind('path', { index: 'pathIndex' }),
            o.unwind('path', { index: 'stepIndex' }),
            o.evaluate({
                items: 'path.items',
                timeItemsVisited: 'path.time'
            }),
            o.unwind('items', { index: 'itemIndex' }),
            o.filter(createCountablesFilter(workflowStart, workflowEnd, pageIds, featureIds)),
            o.evalExpression({
                endStepLocation: createCountableFilterString(workflowEnd.countableId, workflowEnd.countableKind)
            }),
            o.sort(['pathIndex', 'stepIndex', 'itemIndex']),
            o.filter('!isNil(items)'),
            o.group(
                ['visitorId', 'pathIndex'],
                o.groupField('items', { list: 'items' }),
                o.groupField('time', { list: 'timeItemsVisited' }),
                o.groupField('endStepLocation', { list: 'endStepLocation' })
            ),
            o.filter('contains(endStepLocation, true)'),
            o.unwind('endStepLocation', { index: 'endStepLocationIndex' }),
            o.filter('endStepLocation'),
            o.unwind('items', { index: 'itemIndex' }),
            o.unwind('time', { index: 'timeIndex' }),
            o.filter('timeIndex == itemIndex'),
            o.group(
                ['visitorId', 'pathIndex'],
                o.groupField('minEndStepIndex', { min: 'endStepLocationIndex' }),
                o.groupField('items', { list: 'items' }),
                o.groupField('time', { list: 'time' })
            ),
            o.unwind('items', { index: 'itemIndex' }),
            o.unwind('time', { index: 'timeIndex' }),
            o.filter('timeIndex == itemIndex'),
            o.filter('timeIndex <= minEndStepIndex'),
            o.group(
                ['visitorId', 'pathIndex'],
                o.groupField('itemCount', { count: null }),
                o.groupField('items', { list: 'items' }),
                o.groupField('time', { list: 'time' }),
                o.groupField('startTime', { min: 'time' }),
                o.groupField('endTime', { max: 'time' })
            ),
            o.filter(`(endTime - startTime) < ${maxDuration * 1000}`),
            o.group(
                ['items'],
                o.groupField('journeyCount', { count: null }),
                o.groupField('averageTime', { listAverage: 'time' }),
                o.groupField('numSteps', { first: 'itemCount' })
            ),
            o.select({
                journey: 'items',
                averageStepTimestamps: 'averageTime',
                numAttempts: 'journeyCount',
                numSteps: 'numSteps'
            })
        );
    }
};

function createCountable (countableKind, countableId) {
    if (lowerCase(countableKind) === 'page') return { pageId: countableId };
    if (lowerCase(countableKind) === 'feature') return { featureId: countableId };

    return {};
}

function createCountablesFilter (start, end, pageIds = [], featureIds = []) {
    const countablesArray = [
        createCountableFilterString(start.countableId, start.countableKind),
        createCountableFilterString(end.countableId, end.countableKind),
        ...pageIds.map((pageId) => createCountableFilterString(pageId, 'Page')),
        ...featureIds.map((featureId) => createCountableFilterString(featureId, 'Feature'))
    ];

    return countablesArray.join(' || ');
}

function createCountableFilterString (id, kind) {
    if (lowerCase(kind) === 'page') return `items.pageId == "${id}"`;
    if (lowerCase(kind) === 'feature') return `items.featureId == "${id}"`;

    return '';
}
