import Color from 'color';

export const accessibleColorMap = (color) => {
    let luminosity;
    let tempColor = color;
    if (Color(color).isDark()) {
        luminosity = 1;
        do {
            tempColor = Color(color).lightness(luminosity);
            luminosity += 1;
        } while (Color(tempColor).isDark());
    }
    const light = Color(tempColor)
        .lightness(90)
        .hex();
    const medium = Color(tempColor)
        .lightness(85)
        .hex();
    const dark = Color(tempColor)
        .lightness(40)
        .hex();

    return {
        color,
        light,
        medium,
        dark
    };
};
