import { buildGuideStepVisitorSpec } from '@/aggregations/guide-views';
import { PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/utils/aggregations';

export const GuideStepVisitorCount = (guideStepId) => ({
    name: 'GuideStepVisitorCount',
    productArea: PRODUCT_AREAS.GUIDES,
    subProductArea: SUB_PRODUCT_AREAS.POLL_RESULTS,
    responseFormat: 'rows',
    build: ({ appIds, guideId, timeSeries, segmentId }) =>
        buildGuideStepVisitorSpec({ appIds, timeSeries, guideId, guideStepId, segmentId })
});
