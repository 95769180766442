import Vue from 'vue';
import Vuex from 'vuex';
import { pendolytics } from './plugins/pendo.js';
import { theme } from './plugins/theme.js';

import modules from './modules';

Vue.use(Vuex);

function getStore () {
    return new Vuex.Store({
        modules,
        plugins: [pendolytics, theme],
        strict: process.env.NODE_ENV !== 'production'
    });
}

const store = getStore();

export default store;

// Used in Cypress Component Testing
export { getStore };
