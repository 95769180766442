export const PROGRESS_COLORS = {
    DEFAULT: {
        fill: '#EAECF1',
        stroke: '#EAECF1'
    },
    PROGRESS: {
        fill: '#128297',
        stroke: '#128297'
    },
    SUCCESS: {
        fill: '#00C583',
        stroke: '#00C583'
    },
    WARNING: {
        fill: '#DBA211',
        stroke: '#DBA211'
    },
    ERROR: {
        fill: '#DB1111',
        stroke: '#6A0000'
    }
};
