<script>
import { mapState } from 'vuex';
import { getSessionObject } from '@/utils/http';

export default {
    name: 'InitiateLogin',
    computed: {
        ...mapState({
            route: (state) => state.route
        })
    },
    async created () {
        try {
            const sessionToken = await getSessionObject(this.route.query.iss);
            if (sessionToken && sessionToken.login) {
                const encodedParam = encodeURIComponent(this.route.query.iss);
                window.location.assign(`/login/saml/idp/${sessionToken.login}?issuer=${encodedParam}`);
            } else {
                this.$router.push({ name: 'login' }).catch(() => {});
            }
        } catch (err) {
            this.$router.push({ name: 'login' }).catch(() => {});
        }
    }
};
</script>
