import differenceBy from 'lodash/differenceBy';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';

export function isProductResourceColumn (column) {
    return ['page', 'feature', 'poll', 'guide'].includes(column?.type);
}

export function mergeDefaultSortWithUserSettings (defaultSort, savedSettings) {
    if (!savedSettings) return defaultSort;
    if (!savedSettings.defaultSort) return defaultSort;

    const savedSort = savedSettings.defaultSort;

    return Object.assign({}, defaultSort, savedSort);
}

export function mergeDefaultColumnsWithUserSettings (defaultColumnsSchema, savedSettings) {
    if (!savedSettings) return defaultColumnsSchema;

    const { columns: savedColumns = [], visibleColumns = [], columnSizes = {} } = savedSettings;
    const hasSavedColumnSettings = savedColumns.concat(visibleColumns).concat(Object.keys(columnSizes)).length;

    if (!hasSavedColumnSettings) return defaultColumnsSchema;

    const defaultColumns = defaultColumnsSchema.map((column) => cloneDeep(column));

    const defaultColumnsMap = defaultColumnsSchema.reduce((map, column) => {
        map[column.prop] = cloneDeep(column);

        return map;
    }, {});

    const modifiedColumnsFromSettings = (columns, column) => {
        if (!column) return columns;

        column.visible = true;

        columns.push(column);

        return columns;
    };
    // If there are saved column objects, we should use those columns to include any product usage columns
    let columnsOrderedByVisibility = savedColumns.reduce((acc, savedColumn) => {
        const column =
            savedColumn.fact || isProductResourceColumn(savedColumn)
                ? cloneDeep(savedColumn)
                : defaultColumnsMap[savedColumn.prop] || defaultColumnsMap[savedColumn.field];

        return modifiedColumnsFromSettings(acc, column);
    }, []);

    if (!columnsOrderedByVisibility.length) {
        columnsOrderedByVisibility = visibleColumns.reduce((acc, visibleColumnProp) => {
            const column = defaultColumnsMap[visibleColumnProp];

            return modifiedColumnsFromSettings(acc, column);
        }, []);
    }

    const columnsNotInUserSettings = differenceBy(defaultColumns, columnsOrderedByVisibility, 'prop');

    if (visibleColumns.length) {
        // Only set visibility on the rest of the columns if we have a known set of visible columns
        columnsNotInUserSettings.forEach((column) => {
            // "actions" columns can never be managed, and will never be recorded as "visible".
            // We'll let this fall through to whatever the component sets the column visibility to
            if (column.type === 'actions') return;

            column.visible = false;
        });
    }

    const mergedColumns = [...columnsOrderedByVisibility, ...columnsNotInUserSettings];

    mergedColumns.forEach((column) => {
        if (!columnSizes[column.prop]) return;

        column.width = columnSizes[column.prop];
    });

    return mergedColumns;
}

export function mergeDefaultFiltersWithUserSettings (defaultFilterSchema, savedSettings) {
    if (!savedSettings) return defaultFilterSchema;

    const filtersMap = keyBy(defaultFilterSchema, 'type');

    savedSettings.forEach((savedFilter) => {
        const savedFilterByType = filtersMap[savedFilter.type];
        if (!savedFilterByType) return;
        Object.assign(savedFilterByType, { value: savedFilter.value });
    });

    return Object.values(filtersMap);
}
