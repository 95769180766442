<template>
    <pendo-card title="Segment">
        <template
            v-if="canEditGuideSegment"
            #headerRight>
            <pendo-button
                v-if="!isLocalModuleDisabled && !isEditing"
                data-cy="segment-card-edit-action"
                type="link"
                prefix-icon="edit-2"
                label="Edit"
                @click="toggleEditing(true)" />
            <pendo-button
                v-if="isEditing"
                data-cy="segment-card-cancel-action"
                theme="app"
                type="tertiary"
                label="Cancel"
                @click="toggleEditing(false)" />
            <pendo-button
                v-if="isEditing"
                data-cy="segment-card-save-action"
                theme="app"
                type="primary"
                size="mini"
                label="Save"
                :disabled="!userHasChanges"
                @click="confirmRcModuleSegmentBeforeSave ? toggleModal(true) : patchGuideSegment()" />
        </template>
        <template #body>
            <div
                v-if="isEditing"
                class="edit-segment">
                <div class="edit-segment--visitors">
                    {{ visitorCount }} {{ eligibleVisitorsLabel }}
                    <pendo-icon
                        v-pendo-tooltip="tooltipOptions"
                        type="alert-circle"
                        size="14" />
                </div>
                <segment-chooser
                    data-cy="segment-card-segment-chooser"
                    :disabled="false"
                    :value="segmentId"
                    :full-width="true"
                    :guide-targeting="true"
                    @input="onSegmentUpdate" />
                <segment-summary
                    v-if="segment"
                    data-cy="segment-card-segment-summary"
                    :show-name="false"
                    :segment="segment"
                    :guides-map="guideMap"
                    :pages-map="pageMap"
                    :features-map="featureMap"
                    :segments-map="segmentsMap"
                    :workflows-map="workflowMap"
                    :apps-map="appsMap" />
            </div>
            <div
                v-else
                class="visitor-count">
                <div class="visitor-count--total">
                    {{ segment ? visitorCount : '...' }}
                </div>
                <div class="visitor-count--label">
                    <div>{{ eligibleVisitorsLabel.toUpperCase() }}</div>
                    <div><b>(as of today)</b></div>
                </div>
            </div>
            <pendo-modal
                :visible="showConfirmModal"
                type="confirmation"
                title="Update Segment?"
                message="Updating this segment will update your segment for all versions of the Resource Center."
                :confirm-button-config="{ type: 'primary', theme: 'app', label: 'Save Segment' }"
                :cancel-button-config="{ type: 'secondary', theme: 'app', label: 'Cancel' }"
                @cancel="toggleModal(false)"
                @confirm="patchGuideSegment" />
        </template>
        <template
            v-if="!isEditing"
            #footer>
            <segment-summary
                data-cy="segment-card-segment-summary"
                :segment="segment"
                :guides-map="guideMap"
                :pages-map="pageMap"
                :features-map="featureMap"
                :segments-map="segmentsMap"
                :workflows-map="workflowMap"
                :apps-map="appsMap" />
            <div class="footer-message">
                <slot name="footer-message" />
            </div>
        </template>
    </pendo-card>
</template>

<script>
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { mapActions, mapState, mapGetters } from 'vuex';
import {
    PendoCard,
    PendoButton,
    PendoLoading,
    PendoIcon,
    PendoTooltip,
    PendoModal,
    PendoNotification
} from '@pendo/components';
import SegmentChooser from '@/components/segments/SegmentChooser';
import { SegmentSummary } from '@pendo/segment-builder';
import { getVisitorTotal } from '@/aggregations/visitor-counts';
import { canEditGuide } from '@/utils/guide-permissions';
import { canEditResourceCenter } from '@/utils/rc-permissions';
import { isEveryoneSegment } from '@/utils/segments';

export default {
    name: 'GuideDetailsSegment',
    components: {
        PendoCard,
        PendoButton,
        PendoIcon,
        SegmentChooser,
        SegmentSummary,
        PendoModal
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        isLocalModuleDisabled: {
            type: Boolean,
            default: false
        },
        confirmRcModuleSegmentBeforeSave: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isEditing: false,
            segmentVisitorCount: 300,
            isFetchingVisitorCount: false,
            userHasChanges: false,
            showConfirmModal: false,
            newSegmentId: null
        };
    },
    computed: {
        ...mapState({
            segmentsMap: (state) => state.filters.segmentsMap,
            guideMap: (state) => state.guides.map,
            pageMap: (state) => state.pages.map,
            featureMap: (state) => state.features.map,
            errorOnUpdate: (state) => state.resourceCenter.error,
            guideId: (state) => state.guides.activeId,
            workflowMap: (state) => state.workflows.map
        }),
        ...mapGetters({
            apps: 'apps/listAllForActiveSubscription',
            guide: 'guides/active',
            activeResourceCenter: 'resourceCenter/getActiveResourceCenter'
        }),
        appsMap () {
            // apps module map does not follow same id convention
            return keyBy(this.apps, 'id');
        },
        segmentId () {
            return this.newSegmentId || get(this, 'guide.audienceUiHint.filters[0].segmentId', null);
        },
        visitorCount () {
            if (this.isFetchingVisitorCount) {
                return '...';
            }

            return this.segmentVisitorCount;
        },
        tooltipOptions () {
            const tooltipText =
                'Users who have already dismissed the guide will not see it again. This will impact guide metrics moving forward.';

            return {
                arrow: true,
                multiline: true,
                placement: 'left',
                content: tooltipText
            };
        },
        segment () {
            const segmentId = isEveryoneSegment(this.segmentId) ? 'everyone' : this.segmentId;

            return get(this.segmentsMap, segmentId);
        },
        isEveryoneSegment () {
            return isEveryoneSegment(this.segmentId);
        },
        eligibleVisitorsLabel () {
            return `Eligible Visitor${this.visitorCount === 1 ? '' : 's'}`;
        },
        canEditGuideSegment () {
            if (this.activeResourceCenter) {
                const appId = get(this.activeResourceCenter, 'draft.homeView.appId');

                return canEditResourceCenter({ appId, field: 'segment' });
            }

            return canEditGuide({ guide: this.guide, field: 'segment' });
        }
    },
    watch: {
        guide: {
            immediate: true,
            async handler () {
                await this.updateVisitorCount();
            }
        }
    },
    created () {
        this.fetchWorkflows();
    },
    methods: {
        ...mapActions({
            patch: 'guides/patch',
            updateModuleSegment: 'resourceCenter/updateModuleSegment',
            fetchWorkflows: 'workflows/fetch'
        }),
        toggleEditing (isEditing) {
            this.newSegmentId = null;
            this.isEditing = isEditing;
            this.$emit('is-editing-segment', isEditing);
            if (!isEditing) {
                this.userHasChanges = false;
                this.updateVisitorCount();
            }
        },
        async updateVisitorCount () {
            this.isFetchingVisitorCount = true;
            const segmentId = this.newSegmentId || this.segmentId;
            this.segmentVisitorCount = await getVisitorTotal({ segmentId });
            this.isFetchingVisitorCount = false;
        },
        async patchGuideSegment () {
            const previousSegment = get(this, 'guide.audienceUiHint.filters[0].segmentId', null);
            const payload = this.buildPayload();
            await this.patch(payload);
            if (this.errorOnUpdate !== null) {
                this.showErrorToast();

                return;
            }
            if (!this.confirmRcModuleSegmentBeforeSave) this.toggleEditing(false);

            if (this.confirmRcModuleSegmentBeforeSave) this.editModuleSegment(payload, previousSegment);
        },
        buildPayload () {
            const filters = this.segment ? [{ segmentId: this.segmentId, kind: 'visitor' }] : [];
            const audience = [
                {
                    source: {
                        visitors: null
                    }
                },
                {
                    eval: { accountId: 'metadata.auto.accountids' }
                },
                {
                    unwind: {
                        field: 'accountId',
                        keepEmpty: true
                    }
                },
                {
                    select: { visitorId: 'visitorId' }
                }
            ];
            if (!this.isEveryoneSegment && this.segment) {
                audience.splice(3, 0, {
                    segment: { id: this.segmentId }
                });
            }

            return {
                guideId: this.guideId,
                props: {
                    audienceUiHint: { filters },
                    audience
                }
            };
        },
        onSegmentUpdate ({ id }) {
            this.userHasChanges = true;
            this.newSegmentId = id;
            this.updateVisitorCount();
        },
        toggleModal (value) {
            this.showConfirmModal = value;
        },
        async editModuleSegment (payload, previousSegment) {
            const activeResourceCenterId = get(this.activeResourceCenter, 'id', null);
            await this.updateModuleSegment({ homeViewId: activeResourceCenterId, payload });

            if (this.errorOnUpdate !== null) {
                const payload = this.buildPayload();
                payload.props.audienceUiHint.filters[0].segmentId = previousSegment;
                payload.props.audience[3].segment.id = previousSegment;
                await this.patch(payload);
                this.showErrorToast();

                return;
            }
            this.toggleEditing(false);
            this.toggleModal(false);
            this.$emit('segment-update');
        },
        showErrorToast () {
            this.toggleEditing(false);
            this.toggleModal(false);

            PendoNotification({
                type: 'error',
                title: 'Something went wrong!',
                message: 'Action not available. Please try again later.',
                duration: 5000
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.guide-details--segment {
    .visitor-count {
        display: grid;
        grid-gap: 9px;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        font-size: 0.75rem;
        color: $gray-lighter-3;
        text-align: center;

        &--total {
            font-size: 1.5rem;
            color: $gray-primary;
        }
    }

    .edit-segment {
        &--visitors {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            align-items: center;
            grid-gap: 5px;
            padding-bottom: 8px;
        }

        .segment-summary {
            padding-top: 20px;
            margin-top: 15px;
            border-top: 1px solid $gray-lighter-5;
            max-height: 285px;
            overflow: auto;
        }
    }

    .segment-summary {
        line-height: 1.5em;

        &--label {
            font-weight: bold;
        }
    }
}
</style>
