import Vue from 'vue';
import VueRouter from 'vue-router';
import isUndefined from 'lodash/isUndefined';

// store
import store from '@/state/store';
import routes from '@/router/routes';
import { augmentVueRouterPrototypeToSwallowNavigationErrors } from './swallow-navigation-errors';

augmentVueRouterPrototypeToSwallowNavigationErrors(VueRouter);

Vue.use(VueRouter);

function isAllowedEmptyStateRoutes (routeName) {
    const allowedEmptyStateRoutes = {
        forgotPassword: true,
        completeUser: true,
        setPassword: true,
        resetPassword: true,
        inactive: true,
        settings: true,
        userProfile: true,
        manageTeam: true,
        manageUsers: true,
        integrations: true,
        manageMetadata: true,
        subscriptionSettings: true,
        applications: true,
        confirmation: true,
        notFound: true,
        invitationError: true,
        SubscriptionList: true
    };

    return allowedEmptyStateRoutes[routeName];
}

function getRouter () {
    const router = new VueRouter({
        routes,
        mode: 'history',
        scrollBehavior (to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }

            // eslint-disable-next-line id-length
            return { x: 0, y: 0 };
        }
    });

    // Handle all auth-related guards and redirects
    router.beforeEach((to, from, next) => {
        if (store.getters['auth/isLoaded']) {
            return authRelatedRedirects();
        }

        // Wait for user/me to return (or fail) so we know if the user is logged in
        const clearIsLoadedWatcher = store.watch(
            () => {
                return store.getters['auth/isLoaded'];
            },
            (doneLoading) => {
                if (!doneLoading) return;

                clearIsLoadedWatcher();
                authRelatedRedirects();
            }
        );

        function authRelatedRedirects () {
            const authRequiredRoute = to.matched.some((route) => route.meta.authRequired);

            if (!store.getters['auth/isAuthenticated'] && authRequiredRoute) {
                return next({ name: 'login', query: to.query });
            }

            return next();
        }
    });

    // Auth checks are handled above. Now focus on waiting for the UI to be functional (segment flags load)
    // and then do post-authentication guards
    router.beforeEach((to, from, next) => {
        if (store.getters['auth/areSegmentFlagsLoaded']) {
            return segmentFlagsLoadedCheck();
        }

        const clearSegmentFlagWatcher = store.watch(
            () => store.getters['auth/areSegmentFlagsLoaded'],
            (loadingComplete) => {
                if (!loadingComplete) return;

                clearSegmentFlagWatcher();
                segmentFlagsLoadedCheck();
            }
        );

        // user should be loaded by now, so we can safely check whether they're authenticated
        function segmentFlagsLoadedCheck () {
            const isEmptyStateDigitalAdoption = store.getters['subscriptions/isEmptyStateDigitalAdoption'];

            if (isEmptyStateDigitalAdoption && !isAllowedEmptyStateRoutes(to.name)) {
                return next({
                    name: 'subscriptionSettings',
                    params: to.params,
                    query: to.query
                });
            }

            if (isUndefined(to.query.version) && !isUndefined(from.query.version)) {
                return next({
                    name: to.name,
                    params: to.params,
                    query: { ...to.query, version: from.query.version }
                });
            }

            return next();
        }
    });

    router.afterEach(() => {
        store.dispatch('router/formatDocumentTitleByRoute', null, { root: true });
    });

    return router;
}

const router = getRouter();

export default router;

// Used in Cypress Component Testing
export { getRouter };
