import * as Agg2 from '@pendo/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    identifiedState,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/utils/aggregations';
import { requestVisitorOrAccountIdCsv } from './utils';

export function getTaskListItemClicks ({ appIds, guideId, stepId, timeSeries, segmentId, childGuideId }) {
    const spec = buildTaskListItemClicksSpec({ appIds, guideId, timeSeries, segmentId, stepId, childGuideId });

    return request(spec);
}

function commonTaskListAggOps ({ appIds, guideId, stepId, timeSeries, segmentId, childGuideId }) {
    const { operators: o } = Agg2;

    return [
        o.sources.guideEvents({ appId: appIds, guideId, stepId, timeSeries }),
        o.filter('eventSubType(type) == "guideActivity"'),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        { unmarshal: { action: 'uiElementActions' } },
        o.unwind('action'),
        o.filter('action.action == "showGuide"'),
        o.filter(`action.guideId == "${childGuideId}"`)
    ];
}

function visitorAccountAggOps ({ kind }) {
    const { operators: o } = Agg2;
    const idKey = `${kind}.id`;
    const clicksKey = `${kind}.clicks`;

    return o.pipeline(
        o.group(`${kind}Id`, o.groupField('numClicks', o.count(null))),
        o.sort(['-numClicks', `${kind}Id`]),
        o.filter(`!isNull(${kind}Id) && ${kind}Id!=""`),
        o.evalExpression({
            [idKey]: `${kind}Id`,
            [clicksKey]: 'numClicks'
        }),
        o.reduce({
            [`${kind}s`]: o.list(kind)
        })
    );
}

export function buildTaskListItemClicksSpec ({ appIds, guideId, timeSeries, segmentId, stepId, childGuideId }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'task-list-item-clicks',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            ...commonTaskListAggOps({ appIds, guideId, stepId, timeSeries, segmentId, childGuideId }),
            o.fork(visitorAccountAggOps({ kind: 'visitor' }), visitorAccountAggOps({ kind: 'account' })),
            o.join()
        )
    );
}

export function getTaskListItemClicksCsv ({ appIds, guideId, stepId, timeSeries, segmentId, childGuideId, kind }) {
    const spec = buildTaskListItemClicksCsvSpec({ appIds, guideId, timeSeries, segmentId, stepId, childGuideId, kind });

    return requestVisitorOrAccountIdCsv(spec, kind, { shouldIncludeClicks: true });
}

export function buildTaskListItemClicksCsvSpec ({ appIds, guideId, stepId, timeSeries, segmentId, childGuideId, kind }) {
    const { operators: o } = Agg2;
    const visitor = {
        id: 'visitorId',
        clicks: 'numClicks'
    };
    const account = {
        id: 'if(isNull(accountId)||accountId=="", "(none)", accountId)',
        clicks: 'numClicks'
    };

    const select = kind === 'visitor' ? visitor : account;

    return o.aggregation(
        {
            name: 'task-list-item-clicks-csv',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            ...commonTaskListAggOps({ appIds, guideId, stepId, timeSeries, segmentId, childGuideId }),
            o.group(`${kind}Id`, o.groupField('numClicks', o.count(null))),
            o.sort(['-numClicks', `${kind}Id`]),
            o.select(select)
        )
    );
}
