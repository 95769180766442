<template>
    <pendo-card
        title="General"
        class="general"
        body-min-height="0px">
        <div class="container">
            <div class="row">
                <div class="cell left">
                    Display Name
                </div>
                <div class="cell">
                    <pendo-editable-content
                        :value="displayName"
                        @confirm="onDisplayNameChange">
                        <template #append="{ pending, confirm, cancel }">
                            <pendo-button
                                theme="app"
                                icon="check"
                                data-cy="confirm"
                                :loading="pending"
                                type="primary"
                                @click="confirm" />
                            <pendo-button
                                data-cy="cancel"
                                theme="app"
                                icon="x"
                                type="secondary"
                                @click="cancel" />
                        </template>
                    </pendo-editable-content>
                </div>
            </div>
            <div class="row">
                <div class="cell left">
                    Time Zone
                </div>
                <div class="cell">
                    <pendo-multiselect
                        trigger-size="small"
                        :value="timeZone"
                        :options="timeZones"
                        placeholder="Not Set"
                        @select="onTimeZoneSelect" />
                </div>
            </div>
        </div>
        <pendo-modal
            :visible.sync="promptTimeZoneChangeConfirmation"
            type="confirmation"
            title="Change Time Zone?"
            :message="timeZoneConfirmationMessage"
            @cancel="promptTimeZoneChangeConfirmation = false"
            @confirm="confirmChangeTimeZone" />
    </pendo-card>
</template>

<script>
import { TIME_ZONES } from '@/constants/time-zones';
import { PendoButton, PendoEditableContent, PendoMultiselect, PendoModal, PendoCard } from '@pendo/components';
import { mapActions } from 'vuex';

export default {
    name: 'GeneralSettings',
    components: {
        PendoModal,
        PendoEditableContent,
        PendoButton,
        PendoMultiselect,
        PendoCard
    },
    props: {
        subscription: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            timeZones: TIME_ZONES,
            promptTimeZoneChangeConfirmation: false,
            pendingTimeZone: null,
            displayName: '',
            timeZone: ''
        };
    },
    computed: {
        timeZoneConfirmationMessage () {
            return `Are you sure you want to change the time zone for \
            ${this.displayName}? All data will need to be reprocessed.`;
        }
    },
    watch: {
        'subscription.displayName': {
            handler (val) {
                this.displayName = val;
            },
            immediate: true
        },
        'subscription.timezone': {
            handler (val) {
                this.timeZone = val;
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions({
            updateSubscriptionProperty: 'subscriptions/updateProperty'
        }),
        confirmChangeTimeZone () {
            this.updateSubscriptionProperty({
                property: 'timezone',
                value: this.pendingTimeZone
            });
            this.pendingTimeZone = null;
            this.promptTimeZoneChangeConfirmation = false;
        },
        onDisplayNameChange (value) {
            this.updateSubscriptionProperty({ property: 'displayName', value });
        },
        onTimeZoneSelect (timezone) {
            this.pendingTimeZone = timezone;
            this.promptTimeZoneChangeConfirmation = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.pendo-card__body {
    min-height: 0px;
}

.row {
    width: 100%;
    display: flex;
    align-items: center;
}

.cell {
    flex: 1;
    margin: 8px;
}

.left {
    max-width: 450px;
}
</style>
