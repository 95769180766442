<template>
    <div class="manage-metadata">
        <mappings-table
            kind="visitor"
            :loading="isLoading" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import MappingsTable from './MappingsTable.vue';

export default {
    name: 'ManageMetadata',
    components: {
        MappingsTable
    },
    data () {
        return {
            isLoading: false
        };
    },
    async created () {
        this.isLoading = true;
        await this.loadAll();
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            loadAll: 'metadata/loadAll'
        })
    }
};
</script>
