<template>
    <div>
        <pendo-card
            class="translation-unit-card"
            body-min-height="34px">
            <div class="translation-unit-card__prepend">
                <pendo-icon
                    :size="16"
                    stroke="#2A2C35"
                    type="list-checks" />
            </div>
            <div class="translation-unit-card__body">
                <div class="item-header">
                    Task List Item
                </div>
                <text-localizer
                    v-for="(block, index) in transUnitGroup.blocks"
                    :key="block.id"
                    :block-index="index"
                    :trans-unit-map="transUnitMap"
                    :trans-unit-id="block.id"
                    :trans-unit-group="transUnitGroup"
                    :trans-unit-heights="transUnitHeights"
                    :initial-trans-unit-values="initialTransUnitValues"
                    :warnings="warnings"
                    :disabled="disabled"
                    :value-key="valueKey"
                    @changeHeight="$emit('changeHeight', $event)"
                    @change="$emit('change', $event)">
                    <template
                        v-if="isSubtitleBlock(block.id)"
                        #helper-text>
                        <div>
                            {{ helperText }}
                        </div>
                    </template>
                </text-localizer>
            </div>
        </pendo-card>
    </div>
</template>

<script>
import { PendoCard, PendoIcon } from '@pendo/components';
import TextLocalizer from './TextLocalizer';

export default {
    name: 'TemplateChildLocalizer',
    components: {
        PendoCard,
        PendoIcon,
        TextLocalizer
    },
    props: {
        transUnitGroup: {
            type: Object,
            required: true
        },
        transUnitMap: {
            type: Object,
            required: true
        },
        transUnitHeights: {
            type: Object,
            default: () => ({})
        },
        initialTransUnitValues: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        warnings: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['change', 'changeHeight'],
    computed: {
        helperText () {
            return "Available template variables: '<%= currentStep %>' and '<%= totalSteps %>'";
        }
    },
    methods: {
        isSubtitleBlock (blockId) {
            const [, label] = blockId.split('|');

            return label === 'subtitle';
        }
    }
};
</script>

<style lang="scss" scoped>
.translation-unit-card {
    ::v-deep .pendo-card__body {
        display: flex;
    }

    &__prepend {
        border-right: 1px solid $color-gray-40;
        padding: 8px 0;
        width: 33px;
        display: flex;
        justify-content: center;
        background-color: $color-gray-10;
    }

    &__body {
        padding: 8px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .item-header {
            font-weight: 600;
        }
    }
}
</style>
