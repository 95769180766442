<template>
    <div class="path-save-action">
        <pendo-button
            theme="app"
            type="primary"
            :disabled="disabled"
            :label="buttonLabel"
            :prefix-icon="buttonPrefixIcon"
            @click="openModal" />
        <pendo-modal
            class="save-path-modal"
            height="auto"
            :esc-to-close="true"
            :visible="isModalVisible"
            title="Save Path"
            @close="closeModal">
            <template #body>
                <pendo-form :model="modalConfig">
                    <pendo-form-item
                        prop="name"
                        label="Path Name">
                        <pendo-input
                            ref="reportName"
                            v-model="modalConfig.name"
                            placeholder="Please enter a name for this report" />
                    </pendo-form-item>
                    <pendo-form-item prop="visibility">
                        <pendo-radio-group
                            v-model="modalConfig.visibility"
                            :disabled="!canShare"
                            label="Visibility"
                            button>
                            <pendo-radio-button
                                v-for="option in visibilityList"
                                :key="option.value"
                                v-bind="option" />
                        </pendo-radio-group>
                        <div class="shared-explanation">
                            <span
                                v-show="modalConfig.visibility">Everyone at <strong>{{ subscription.name }}</strong> can view and edit this
                                report.</span>
                            <span v-show="!modalConfig.visibility">Only you can view and edit this report.</span>
                        </div>
                    </pendo-form-item>
                </pendo-form>
            </template>
            <template #footer>
                <div class="save-path-modal__footer">
                    <pendo-button
                        theme="app"
                        type="secondary"
                        label="Cancel"
                        @click="closeModal" />
                    <pendo-button
                        theme="app"
                        label="Save Path"
                        :loading="savingStatus"
                        :disabled="modalConfig.name === ''"
                        @click="submitForm" />
                </div>
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import {
    PendoRadioGroup,
    PendoButton,
    PendoRadioButton,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoModal
} from '@pendo/components';
import { mapGetters } from 'vuex';

export default {
    name: 'PathSaveAction',
    components: {
        PendoRadioGroup,
        PendoRadioButton,
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoModal
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        canShare: {
            type: Boolean,
            default: false
        },
        buttonPrefixIcon: {
            type: String,
            default: undefined
        },
        buttonLabel: {
            type: String,
            default: 'Save'
        }
    },
    data () {
        return {
            isModalVisible: false,
            modalConfig: {
                name: '',
                visibility: false
            },
            savingStatus: false,
            visibilityList: [
                {
                    disabled: false,
                    label: 'Only Me',
                    value: false
                },
                {
                    disabled: false,
                    label: 'Everyone',
                    value: true
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            subscription: 'subscriptions/active'
        })
    },
    methods: {
        closeModal () {
            this.savingStatus = false;
            this.isModalVisible = false;
            this.modalConfig = {
                name: '',
                visibility: false
            };
        },
        async openModal () {
            this.isModalVisible = true;
            await this.$nextTick();
            this.$refs.reportName.focus();
        },
        submitForm () {
            this.savingStatus = true;
            this.$emit('save', this.modalConfig);
        }
    }
};
</script>
<style lang="scss">
.save-path-modal__footer {
    display: flex;
    justify-content: flex-end;
}
</style>
