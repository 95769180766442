<template>
    <div>
        <pendo-card
            :data-cy="isResourceCenter ? 'resource-center-localization-settings' : 'guide-localization-settings'"
            class="guide-settings--localization"
            title="Localization">
            <template
                v-if="canEditGuideLocalization"
                #headerRight>
                <pendo-button
                    v-if="!isEditing && isEditable"
                    theme="app"
                    type="link"
                    label="Edit"
                    size="mini"
                    prefix-icon="edit-2"
                    @click="isEditing = true" />
                <pendo-button
                    v-if="isEditing"
                    theme="app"
                    type="primary"
                    label="Done"
                    size="mini"
                    @click="isEditing = false" />
            </template>
            <template #body>
                <div
                    v-if="!isEditing"
                    class="localization-info">
                    <div class="localization-info--translated-languages-count">
                        <div v-if="languagesInitialized">
                            {{ translatedLanguages.length }} of
                            {{ translatedLanguages.length + untranslatedLanguages.length }}
                        </div>
                        <div v-else>
                            -- of --
                        </div>
                    </div>
                    <div class="localization-info--translated-languages-label">
                        Languages Translated
                    </div>
                </div>
                <div
                    v-if="isEditing"
                    class="localization-edit">
                    <language-export
                        :authored-language="authoredLanguage"
                        :guide="guide"
                        :guide-name="guideName"
                        :languages="enabledLanguages" />
                    <pendo-collapse
                        :value="activePanel"
                        :panels="panels"
                        theme="p2-light"
                        accordion>
                        <template #translated_languages>
                            <div>
                                <language-list
                                    :guide-id="guide.id"
                                    :guide-name="guideName"
                                    :is-resource-center="isResourceCenter"
                                    :languages="translatedWithoutAuthored"
                                    empty-state-heading="No languages have been translated yet."
                                    empty-state-subtext="Import languages in the untranslated section."
                                    :is-showing-translated-languages="true"
                                    @editTranslationInApp="editTranslationInApp" />
                            </div>
                        </template>
                        <template #untranslated_languages>
                            <div>
                                <language-list
                                    :app-id="app.id"
                                    :guide-id="guide.id"
                                    :guide-name="guideName"
                                    :is-resource-center="isResourceCenter"
                                    :languages="untranslatedLanguages"
                                    :all-languages-translated="allLanguagesTranslated"
                                    :empty-state-heading="getUntranslatedEmptyStateText()"
                                    @editTranslationInApp="editTranslationInApp" />
                            </div>
                        </template>
                    </pendo-collapse>
                </div>
            </template>
            <template #footer>
                <div
                    v-if="!isEditing"
                    class="localization-footer">
                    <div class="translated-languages">
                        <div class="translated-languages--label">
                            Translated Languages:
                        </div>
                        <span
                            v-for="(translatedLanguage, index) of translatedLanguages"
                            :key="translatedLanguage.name">
                            {{ translatedLanguage.name }}
                            <span
                                v-if="index !== translatedLanguages.length - 1 && translatedLanguages.length > 1">,</span>
                        </span>
                    </div>
                    <div class="untranslated-languages">
                        <div class="untranslated-languages--label">
                            Untranslated Languages:
                        </div>
                        <span
                            v-for="(untranslatedLanguage, index) of untranslatedLanguages"
                            :key="untranslatedLanguage.name">
                            {{ untranslatedLanguage.name
                            }}<span
                                v-if="index !== untranslatedLanguages.length - 1 && untranslatedLanguages.length > 1">,</span>
                        </span>
                    </div>
                </div>
            </template>
        </pendo-card>
        <edit-translation-drawer
            :visible="isEditingTranslations"
            :target-language="editingTranslationLanguage"
            :translated-content="translatedContent"
            :authored-language="authoredLanguage"
            :platform="app.platform"
            :guide="guide"
            :guide-translations="guideTranslations"
            :guide-translation-alert="guideTranslationAlert"
            :guide-translation-action="guideTranslationAction"
            :valid-child-guides-list="validChildGuidesList"
            :loading="isFetchingTranslationData"
            :saving="isUpdatingGuide"
            @close="onCloseTranslationDrawer"
            @save="saveTranslation" />
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { formatLanguages, isTranslated, getTranslatedGuideSteps } from '@/utils/localization';
import { STATES } from '@/utils/resource-center';
import { PendoCard, PendoButton, PendoCollapse, PendoNotification } from '@pendo/components';
import LanguageExport from '@/components/guides/settings/localization/LanguageExport.vue';
import LanguageList from '@/components/guides/settings/localization/LanguageList.vue';
import EditTranslationDrawer from '@/stateless-components/guides/settings/EditTranslationDrawer.vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { canEditGuide } from '@/utils/guide-permissions';
import { canEditResourceCenter } from '@/utils/rc-permissions';
import { fetchGuideTranslations, createGuideTranslations } from '@/state/modules/guides.module';
import { getStepsWithBuildingBlocks } from '@/stateless-components/utils/guides';
import { TaskList } from '@pendo/services/TaskList';

export default {
    name: 'DetailsLocalization',
    components: {
        LanguageExport,
        LanguageList,
        PendoCard,
        PendoButton,
        PendoCollapse,
        EditTranslationDrawer
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        isResourceCenter: {
            type: Boolean,
            default: false
        },
        activeState: {
            type: String,
            default: 'draft'
        }
    },
    data () {
        return {
            isFetchingTranslations: false,
            isFetchingBuildingBlocks: false,
            isEditing: false,
            enabledLanguages: [],
            STATES,
            guideTranslations: [],
            translatedContent: [],
            guideTranslationAlert: null,
            guideTranslationAction: null,
            panels: [
                {
                    name: 'translated_languages',
                    title: 'Translated'
                },
                {
                    name: 'untranslated_languages',
                    title: 'Untranslated'
                }
            ],
            languagesInitialized: false,
            isEditingTranslations: false,
            editingTranslationLanguage: null
        };
    },
    computed: {
        ...mapState({
            isUpdatingGuide: (state) => state.guides.isUpdating
        }),
        ...mapGetters({
            getAppFromGuide: 'apps/appFromGuide',
            getAppFromResourceCenter: 'apps/appFromResourceCenter',
            guidesList: 'guides/list'
        }),
        app () {
            return this.isResourceCenter ? this.getAppFromResourceCenter(this.guide) : this.getAppFromGuide(this.guide);
        },
        localizationSettings () {
            return get(this.app, 'localizationSettings', {});
        },
        appId () {
            return get(this.app, 'id', '');
        },
        guideName () {
            if (this.isResourceCenter) {
                return `${this.app.displayName}-${this.guide.draft.homeView.name}`;
            }

            return this.guide.name;
        },
        isFetchingTranslationData () {
            return this.isFetchingBuildingBlocks || this.isFetchingTranslations;
        },
        isEditable () {
            return !this.isResourceCenter || this.activeState === STATES.DRAFT;
        },
        authoredLanguage () {
            const languageCode = this.isResourceCenter
                ? get(this.guide, 'draft.homeView.authoredLanguage', '')
                : get(this.guide, 'authoredLanguage', '');
            const authoredLanguage =
                this.enabledLanguages && this.enabledLanguages.find((language) => language.code === languageCode);

            return authoredLanguage || this.defaultLanguage;
        },
        defaultLanguage () {
            return this.enabledLanguages && this.enabledLanguages.find((language) => language.isDefaultLanguage);
        },
        activePanel () {
            if (this.translatedWithoutAuthored.length > 0) return 'translated_languages';
            if (this.untranslatedLanguages.length > 0 || this.enabledLanguages.length === 1) {
                return 'untranslated_languages';
            }

            return '';
        },
        translationStates () {
            return this.isResourceCenter
                ? get(this, `guide[${this.activeState}].translationStates`, [])
                : get(this, 'guide.translationStates', []);
        },
        translatedLanguages () {
            const languages = this.enabledLanguages.filter(
                (language) => isTranslated(language.translatedState) || language.code === this.authoredLanguage.code
            );

            return languages;
        },
        translatedWithoutAuthored () {
            return this.translatedLanguages.filter((language) => language.code !== this.authoredLanguage.code);
        },
        untranslatedLanguages () {
            const languages = this.enabledLanguages.filter(
                (language) => !isTranslated(language.translatedState) && language.code !== this.authoredLanguage.code
            );

            return languages;
        },
        allLanguagesTranslated () {
            return this.translatedWithoutAuthored.length > 0 && this.untranslatedLanguages.length === 0;
        },
        canEditGuideLocalization () {
            if (this.isResourceCenter) {
                return canEditResourceCenter({ appId: this.appId, field: 'localization' });
            }

            return canEditGuide({ guide: this.guide, field: 'localization' });
        },
        validChildGuidesList () {
            return TaskList.getAllValidTemplateChildrenForGuide(this.guide, this.guidesList, {
                returnGuideObject: true
            });
        }
    },
    watch: {
        guide () {
            this.fetchLanguages(false);
        }
    },
    created () {
        this.fetchLanguages(true);
    },
    methods: {
        ...mapActions({
            importLocalization: 'guides/importLocalization',
            fetchLocalizationSettingsForApp: 'apps/fetchLocalizationSettingsForApp'
        }),
        async fetchLanguages (noCache) {
            if (isEmpty(this.app)) return;
            await this.fetchLocalizationSettingsForApp({ app: this.app, noCache });
            const enabledLanguages = formatLanguages(this.localizationSettings, this.translationStates);
            this.sortLanguages(enabledLanguages);
        },
        async fetchTranslationContent ({ code: langCode, translatedState }) {
            if (isTranslated(translatedState)) {
                const translatedSteps = await getTranslatedGuideSteps(this.guide.id, langCode);

                return getStepsWithBuildingBlocks({ steps: translatedSteps });
            }

            // Fallback to the content for the authored language if we don't have translated content
            // for the language.
            return cloneDeep(this.guide.steps);
        },
        async fetchTranslationStrings (targetLanguage, action) {
            const { id: guideId } = this.guide;
            const { code: langCode, name: langName } = targetLanguage;

            if (action === 'translate-with-ai') {
                let translations;

                try {
                    translations = await createGuideTranslations(guideId, langCode);
                } catch (err) {
                    translations = await fetchGuideTranslations(guideId, langCode);
                    this.guideTranslationAlert = {
                        type: 'error',
                        description: `Unable to retrieve ${langName} translations. You need to manually translate the text or import an XLIFF file.`
                    };
                }

                return translations;
            }

            return fetchGuideTranslations(guideId, langCode);
        },
        async fetchTranslations (targetLanguage, action) {
            this.isFetchingTranslations = true;

            const { code: langCode } = targetLanguage;

            try {
                const [guideTranslations, translatedContent] = await Promise.all([
                    this.fetchTranslationStrings(targetLanguage, action),
                    this.fetchTranslationContent(targetLanguage)
                ]);

                this.guideTranslations = guideTranslations;
                this.translatedContent = translatedContent;
            } catch (err) {
                this.guideTranslationAlert = {
                    type: 'error',
                    description: `Unable to retrieve ${langCode} translations`
                };
            } finally {
                this.isFetchingTranslations = false;
            }
        },
        sortLanguages (enabledLanguagesArray) {
            this.enabledLanguages = [];

            enabledLanguagesArray.forEach((enabledLanguage) => {
                const { name, isDefaultLanguage, translatedState, code, lastUpdated, isDeprecated } = enabledLanguage;

                this.enabledLanguages.push({
                    name,
                    isDefaultLanguage,
                    translatedState,
                    code,
                    lastUpdated,
                    isDeprecated
                });
            });
            this.languagesInitialized = true;
        },
        getUntranslatedEmptyStateText () {
            return this.allLanguagesTranslated
                ? 'All enabled languages have been translated.'
                : 'No languages have been enabled.';
        },
        editTranslationInApp ({ language, action }) {
            this.editingTranslationLanguage = language;
            this.guideTranslationAction = action;
            this.isEditingTranslations = true;

            this.fetchTranslations(language, action);
        },
        onCloseTranslationDrawer () {
            this.isEditingTranslations = false;
            this.guideTranslationAlert = null;
            this.guideTranslations = [];
        },
        showTranslationSuccessNotification (action, targetLang) {
            let title = `${targetLang} added successfully!`;
            let message = `${targetLang} is now available to your end users`;

            if (action === 'edit') {
                title = `${targetLang} updated successfully!`;
                message = `Updated ${targetLang} translations are now available to your end users`;
            }

            PendoNotification({
                type: 'success',
                title,
                message,
                duration: 5000
            });
        },
        async saveTranslation ({ action, guideId, language, translations }) {
            const { name: langName, code: langCode } = language;

            try {
                await this.importLocalization({ guideId, langCode, translations });

                this.isEditingTranslations = false;
                this.showTranslationSuccessNotification(action, langName);
            } catch (err) {
                this.guideTranslationAlert = {
                    type: 'error',
                    description: 'Unable to save guide translations'
                };
            }
        }
    }
};
</script>

<style lang="scss">
.guide-settings--localization {
    .pendo-card__body {
        display: grid;
        grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
        overflow-y: auto;
        padding: 0;

        > .localization-info {
            display: grid;
            justify-items: center;
            align-self: center;
            grid-gap: 8px;
            padding: 16px;
        }
    }

    .localization-info {
        &--translated-languages-count {
            font-size: 28px;
            line-height: 28px;
            font-weight: 300;
        }

        &--translated-languages-label {
            font-size: 12px;
            line-height: 14px;
            color: $gray-lighter-2;
            text-transform: uppercase;
        }
    }

    .localization-edit {
        display: grid;
        grid-template-rows: max-content;
    }

    .localization-footer {
        .translated-languages,
        .untranslated-languages {
            color: $gray-lighter-2;
            line-height: 21px;
            padding-bottom: 8px;

            &--label {
                font-weight: 700;
                color: $gray-primary;
            }
        }
    }
}
</style>
