import { http } from '@pendo/http';

export function fetchSharedTokens (kind = null) {
    const urlKind = kind !== null ? `/${kind}` : '';

    return http.get(`/api/s/_SID_/sharedtoken${urlKind}?expand=*`).then((res) => res.data);
}

export function updateSharedToken (sharedToken) {
    return http.put(`/api/s/_SID_/sharedtoken/${sharedToken.id}`, sharedToken).then((res) => res.data);
}

export function createSharedToken (newSharedToken) {
    return http.post('/api/s/_SID_/sharedtoken', newSharedToken).then((res) => res.data);
}
