<template>
    <div class="export-lang">
        <span class="export-lang-text import-export-lang-text">
            <span class="authored-lang-text">
                Authored language
            </span>
            <span class="import-export-lang-name">
                {{ authoredLanguage.name }}
                <edit-authored-language
                    :languages="languages"
                    :authored-language="authoredLanguage"
                    :guide="guide" />
            </span>
            <span class="import-export-lang-code"> Source Language: {{ authoredLanguage.code }} </span>
        </span>

        <span class="export-action">
            <pendo-button
                class="import-export-button"
                theme="app"
                type="secondary"
                size="mini"
                label="Export"
                :loading="isExportingXliff"
                @click="exportXliff(authoredLanguage.code)" />
        </span>
    </div>
</template>

<script>
import { ref } from 'vue';
import { PendoButton } from '@pendo/components';
import EditAuthoredLanguage from '@/components/guides/settings/localization/EditAuthoredLanguage.vue';
import useXliffDownload from '@/stateless-components/guides/common/useXliffDownload';

export default {
    name: 'LanguageExport',
    components: {
        EditAuthoredLanguage,
        PendoButton
    },
    props: {
        authoredLanguage: {
            type: Object,
            required: true
        },
        guide: {
            type: Object,
            required: true
        },
        guideName: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },
    setup (props) {
        const guideId = ref(props.guide.id);
        const guideName = ref(props.guideName);
        const authoredLanguage = ref(props.authoredLanguage.code);
        const { isExportingXliff, exportXliff } = useXliffDownload(guideId, guideName, authoredLanguage);

        return {
            isExportingXliff,
            exportXliff
        };
    }
};
</script>

<style lang="scss">
.export-lang {
    border-bottom: 1px solid $gray-lighter-5;
    padding: 16px;
    display: flex;
    align-items: flex-end;
}

.export-action {
    flex: 1;
    text-align: end;
}

.authored-lang-text {
    font-size: 12px;
    height: 14px;
    line-height: 1.17;
    font-weight: 600;
    color: $gray-lighter-3;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
}
</style>
