export const maxDurationOptions = [
    {
        id: 'hours',
        label: 'hours',
        options: [
            {
                label: '1 hour',
                id: 3600,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '3 hours',
                id: 10800,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '8 hours',
                id: 28800,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '12 hours',
                id: 43200,
                icon: {
                    type: 'clock'
                }
            }
        ]
    },
    {
        id: 'days',
        label: 'days',
        options: [
            {
                label: '1 day',
                id: 86400,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '7 days',
                id: 604800,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '14 days',
                id: 1209600,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '30 days',
                id: 2592000,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '60 days',
                id: 5184000,
                icon: {
                    type: 'clock'
                }
            },
            {
                label: '90 days',
                id: 7776000,
                icon: {
                    type: 'clock'
                }
            }
        ]
    }
];

export function hasInvalidWorkflowSteps (workflowSteps, { featuresMap = {}, pagesMap = {} } = {}) {
    return workflowSteps.some((step) => {
        if (step.countableKind === 'Page') {
            return !pagesMap[step.countableId];
        }

        return !featuresMap[step.countableId];
    });
}

export function cleanAndFormatWorkflowSteps (workflowSteps, { featuresMap = {}, pagesMap = {} } = {}) {
    return workflowSteps.map((step) => {
        let existingEntity;

        if (step.countableKind === 'Page') {
            existingEntity = pagesMap[step.countableId];
        } else if (step.countableKind === 'Feature') {
            existingEntity = featuresMap[step.countableId];
        }

        const formattedStep = Object.assign({}, step);

        if (!existingEntity) {
            formattedStep.invalidCountableId = step.countableId;
            formattedStep.countableId = null;
        } else {
            formattedStep.appId = existingEntity.appId;
        }

        return formattedStep;
    });
}
