<template>
    <pendo-multiselect
        class="target-multiselect-filter"
        :value="value"
        :popper-options="popperOptions"
        :options="options"
        :placeholder="placeholder"
        label-key="label"
        :append-to-body="false"
        :searchable="false"
        :close-on-select="false"
        :show-selected-values="false"
        :min-trigger-width="178"
        :max-trigger-width="178"
        multiple
        @input="handleSelection($event)">
        <template #selectedLabel="{ option }">
            {{ option.map((opt) => opt.label).join(', ') }}
        </template>
        <template #header>
            <pendo-button
                v-if="value && value.length"
                class="options-header-action"
                type="link"
                @click="value = []">
                Clear All
            </pendo-button>
            <pendo-button
                v-if="value && value.length === 0"
                class="options-header-action"
                type="link"
                @click="value = options">
                Select All
            </pendo-button>
        </template>
        <template #option="{ option }">
            <pendo-checkbox-option :option="option" />
        </template>
    </pendo-multiselect>
</template>

<script>
import { PendoMultiselect, PendoButton, PendoCheckboxOption } from '@pendo/components';

export default {
    name: 'EventTypeFilter',
    components: {
        PendoMultiselect,
        PendoButton,
        PendoCheckboxOption
    },
    data () {
        const options = [
            {
                label: 'Pages',
                name: 'pages',
                id: 'pages',
                kind: 'Page',
                icon: { type: 'file' }
            },
            {
                label: 'Features',
                name: 'features',
                id: 'features',
                kind: 'Feature',
                icon: { type: 'mouse-pointer' }
            }
        ];

        return {
            options,
            value: [],
            placeholder: 'Select Event Types'
        };
    },

    computed: {
        popperOptions () {
            return {
                modifiers: {
                    preventOverflow: {
                        boundariesElement: 'window'
                    }
                }
            };
        }
    },
    watch: {
        value () {
            this.$emit('select', this.value);
        }
    },
    created () {
        this.value = this.options;
    },
    methods: {
        handleSelection (value) {
            this.value = value;
        }
    }
};
</script>

<style lang="scss" scoped>
.target-multiselect-filter {
    .options-header-action {
        margin: 0 16px;
    }

    ::v-deep .pendo-multiselect__content-wrapper {
        padding-top: 0;
    }
}
</style>
