var render = function render(){var _vm=this,_c=_vm._self._c;return _c('pendo-filter-bar',{directives:[{name:"pendo-affix",rawName:"v-pendo-affix",value:({
        fixedStyles: {
            left: 0,
            right: 0,
            padding: '0 32px 0 120px'
        }
    }),expression:"{\n        fixedStyles: {\n            left: 0,\n            right: 0,\n            padding: '0 32px 0 120px'\n        }\n    }"}],ref:"globalFilter",staticClass:"global-filters",class:_vm.shouldShowSmallStyles,scopedSlots:_vm._u([{key:"bottom",fn:function(){return [(!!_vm.$slots.expansion)?_c('div',{staticClass:"global-filters--inner global-filters--bottom"},[_c('div',{staticClass:"filters"},[_vm._t("expansion")],2)]):_vm._e()]},proxy:true}],null,true)},[_c('div',{staticClass:"global-filters--inner global-filters--top"},[_vm._t("left"),_c('div',{staticClass:"filters"},[(_vm.showSegmentPicker)?_c('div',{staticClass:"filters--item"},[_c('segment-chooser',{attrs:{"value":_vm.activeSegmentId,"disabled":_vm.updatingActiveApp,"guide-targeting":false},on:{"input":_vm.updateActiveSegmentId}})],1):_vm._e(),(_vm.showDatePicker)?_c('div',{staticClass:"filters--item"},[_c('date-range-picker',{attrs:{"value":_vm.dateRange,"first-visit":_vm.firstVisit},on:{"change":_vm.updateDateRange}})],1):_vm._e(),(_vm.usesMultiApp && _vm.showAppPicker)?_c('div',{staticClass:"filters--item"},[_c('app-filter',{attrs:{"show-mobile-apps":_vm.showMobileApps}})],1):_vm._e(),_c('div',{staticClass:"filters--item additional-filters-slot"},[_vm._t("additional-filters")],2)]),_c('div',{staticClass:"filters--item right-slot"},[_vm._t("right")],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }