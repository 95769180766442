<template>
    <div class="item-delete-warning">
        <div class="pendo-modal__body">
            <span v-if="!impactedItems.length">
                You won't be able to recover it later. Are you sure you want to delete this {{ kind }}?
            </span>
            <div v-if="impactedItems.length">
                <span> We have detected the following {{ impactedKind }}(s) rely on this {{ kind }}. </span>
                <ul class="item-delete-warning--item-list">
                    <li
                        v-for="item in impactedItems"
                        :key="item.id">
                        {{ item.name }}
                    </li>
                </ul>
                <p v-if="preventDeleteWithImpactedItems">
                    If you wish to delete this {{ kind }}, please remove the {{ kind }} from the listed
                    {{ impactedKind }}(s) first.
                </p>
            </div>
        </div>
        <div class="pendo-modal__footer">
            <pendo-button
                type="secondary"
                theme="app"
                label="Cancel"
                @click.stop="$modal.hide('via-modal')" />
            <pendo-button
                :label="`Delete ${kind}`"
                :class="`item-delete--${kind}`"
                :disabled="preventDeleteWithImpactedItems && !!impactedItems.length"
                type="danger"
                theme="app"
                @click.stop="deleteFn(impactedItems.length)" />
        </div>
    </div>
</template>

<script>
import { PendoButton } from '@pendo/components';

export default {
    name: 'ItemDeleteWarning',
    components: {
        PendoButton
    },
    props: {
        deleteFn: {
            type: Function,
            required: true
        },
        impactedItems: {
            type: Array,
            default: () => []
        },
        kind: {
            type: String,
            required: true
        },
        impactedKind: {
            type: String,
            required: true
        },
        preventDeleteWithImpactedItems: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss">
.item-delete-warning {
    &--item-list {
        padding: 0;

        li {
            list-style-type: none;
            font-weight: bold;
        }
    }
}
</style>
