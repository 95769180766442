<template>
    <main class="visitor-list">
        <pendo-table
            v-pendo-loading:feather="isFetchingVisitorList"
            v-pendo-table-user-settings-watcher="{
                settingsName: visitorListUserSettingsName
            }"
            title="Visitors"
            row-key="visitorId"
            class="visitor-list--table"
            :data="visitorList"
            :filters="filters"
            :columns="columns"
            :default-sort="sort"
            :max-height="600"
            :csv-config="csvDownloadConfig"
            manage-columns
            csv-download
            resizable
            @column-change="changeColumns">
            <template
                v-if="canUpload"
                #headerActions>
                <metadata-csv-upload @uploaded="fetchVisitorList" />
            </template>
            <template #visitorId="{ row }">
                <router-link :to="{ name: 'visitorDetails', params: { visitorId: encodeIdForUri(row.visitorId) } }">
                    {{ row.visitorId }}
                </router-link>
            </template>
            <template #empty>
                <div class="visitor-list--table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        No data found.
                    </span>
                </div>
            </template>
            <template #lastVisitAppDisplayName="{ row }">
                <pendo-app-display :apps="row.lastVisitAppId ? appById(row.lastVisitAppId) : []" />
            </template>
        </pendo-table>
    </main>
</template>

<script>
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { isCancel } from 'axios';
import { mapActions, mapGetters, mapState } from 'vuex';

import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getVisitorList } from '@/aggregations/visitor-list';
import { PendoIcon, PendoTable, PendoLoading, PendoAppDisplay } from '@pendo/components';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import { encodeIdForUri } from '@/utils/utils';
import MetadataCsvUpload from '@/stateless-components/analytics/MetadataCsvUpload.vue';
import { mergeDefaultSortWithUserSettings } from '@/stateless-components/utils/table-user-settings';
import { getVisitorListColumns } from '@/stateless-components/utils/analytics-visitor-list';
import PendoTableUserSettingsWatcher from '@/directives/PendoTableUserSettingsWatcher';

export default {
    name: 'AnalyticsVisitorList',
    components: {
        PendoIcon,
        PendoTable,
        MetadataCsvUpload,
        PendoAppDisplay
    },
    directives: {
        PendoLoading,
        PendoTableUserSettingsWatcher
    },
    props: {
        searchString: {
            type: String,
            default: ''
        },
        filterNoEvents: {
            type: Boolean,
            default: true
        },
        canUpload: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            visitorListUserSettingsName: 'visitorList',
            visitorList: [],
            unsubscribeFilterBarListener: null,
            isFetchingVisitorList: false,
            aggCancel: null,
            defaultSort: {
                prop: 'visitorId',
                order: 'ascending'
            },
            isColumnChooserVisible: false,
            dropdownOptions: [
                {
                    label: 'Manage Columns',
                    action: 'edit'
                }
            ]
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            appIdsFilter: (state) => state.filters.appIdsFilter
        }),
        ...mapGetters({
            activeAppId: 'apps/activeId',
            activeTimeSeries: 'filters/activeTimeSeries',
            appsForAppIdFilter: 'filters/appsForAppIdFilter',
            schemaList: 'filters/schemaList',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName',
            appById: 'apps/appById',
            getActiveTimezone: 'subscriptions/getTimezone'
        }),
        visitorListUserSettings () {
            return this.getTableUserSettingValueByName(this.visitorListUserSettingsName);
        },
        searchableColumns () {
            const columns = this.visitorListUserSettings?.visibleColumns || this.columns.map((column) => column.prop);

            return columns.filter((columnName) => {
                return columnName !== 'daysActive' && columnName !== 'eventCount';
            });
        },
        filters () {
            return [
                {
                    prop: this.searchableColumns,
                    value: this.searchString
                }
            ];
        },
        columns () {
            return getVisitorListColumns(this.visitorListUserSettings, this.schemaList, this.getActiveTimezone);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.visitorListUserSettings);
        },
        csvDownloadConfig () {
            const visibleColumns = this.columns.filter((col) => col.visible);
            const formattedColumns = visibleColumns.map((column) => {
                const singleColumn = {
                    ...column,
                    label: column.label,
                    formatter: (row) => this.formatDateForDownload(row, column)
                };

                return singleColumn;
            });

            return { columns: formattedColumns };
        }
    },
    watch: {
        async filterNoEvents (newVal, oldVal) {
            if (newVal !== oldVal) await this.fetchVisitorList();
        }
    },
    async created () {
        await this.fetchVisitorList();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, async () => {
            await this.fetchVisitorList();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapActions({
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        encodeIdForUri,
        openColumnChooser () {
            this.isColumnChooserVisible = true;
        },
        changeColumns ($event) {
            const { columns } = $event;
            const currentlyVisibleColumnNames = this.columns.map((col) => col.prop).sort();
            const newlyVisiblyColumnNames = columns.slice().sort();
            const newColumnsAdded = !isEqual(currentlyVisibleColumnNames, newlyVisiblyColumnNames);

            if (!newColumnsAdded) return;

            this.fetchVisitorList({ noCache: true });
        },
        async fetchVisitorList () {
            this.isFetchingVisitorList = true;

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            try {
                const timeSeries = {
                    ...this.activeTimeSeries,
                    period: 'dayRange'
                };

                const appId = this.appIdsFilter;
                const appList = this.appsForAppIdFilter;

                const visitorList = await getVisitorList({
                    timeSeries,
                    segmentId: this.activeSegmentId,
                    appId,
                    appList,
                    columns: this.columns,
                    signal: this.aggCancel.signal,
                    filterNoEvents: this.filterNoEvents,
                    addLastVisitByApp: true
                });

                this.isFetchingVisitorList = false;

                if (visitorList) {
                    const appFilterMap = keyBy(this.appsForAppIdFilter, 'id');
                    this.visitorList = visitorList.map((row) => {
                        const noAppDisplayName = !row.lastVisitAppId || !appFilterMap[row.lastVisitAppId];
                        if (noAppDisplayName) {
                            row.lastVisitAppDisplayName = '';

                            return row;
                        }

                        row.lastVisitAppDisplayName = appFilterMap[row.lastVisitAppId].displayName;

                        return row;
                    });
                }
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetchingVisitorList = false;
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
            }
        },
        formatDateForDownload (row, column) {
            const columnVal = get(row, column.prop);
            const schema = get(column, 'schema');
            if (schema === 'time') {
                const dateValue =
                    columnVal &&
                    convertToSubscriptionTimezone(this.getActiveTimezone, columnVal).format(DATE_FORMAT.isoDateTime);

                return dateValue;
            }

            return columnVal;
        }
    }
};
</script>

<style lang="scss">
.visitor-list {
    &--table {
        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }
    }

    .pendo-table__header {
        .pendo-table__column {
            text-transform: capitalize;
        }
    }
}
</style>
