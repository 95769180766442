<template>
    <pendo-card
        class="extension-settings"
        body-min-height="0"
        title="Extension settings">
        <pendo-collapse v-model="activePanels">
            <pendo-collapse-item
                name="extension-install-collapse"
                title="Install instructions"
                type="details">
                <extension-install-snippet :subscription="subscription" />
            </pendo-collapse-item>
            <pendo-collapse-item
                name="advanced-settings-collapse"
                title="Advanced settings"
                class="advanced-settings-collapse"
                type="details">
                <browser-ingest-settings
                    :subscription="subscription"
                    :saving-subscription-settings="saving"
                    @updateSubscriptionFlag="emitFlagChange"
                    @update-extension-attributes="emitExtensionAttributesChange" />
            </pendo-collapse-item>
        </pendo-collapse>
    </pendo-card>
</template>
<script>
import { PendoCard, PendoCollapse, PendoCollapseItem } from '@pendo/components';
import ExtensionInstallSnippet from '@/stateless-components/settings/ExtensionInstallSnippet';
import BrowserIngestSettings from '@/stateless-components/settings/BrowserIngestSettings';

export default {
    name: 'ExtensionSettings',
    components: {
        PendoCard,
        PendoCollapse,
        PendoCollapseItem,
        ExtensionInstallSnippet,
        BrowserIngestSettings
    },
    props: {
        subscription: {
            type: Object,
            required: true
        },
        saving: {
            type: Boolean,
            required: true
        }
    },
    emits: ['extension-attributes-change', 'flag-change'],
    data () {
        return {
            activePanels: ['extension-install-collapse', 'advanced-settings-collapse']
        };
    },
    methods: {
        emitFlagChange ($event) {
            this.$emit('flag-change', $event);
        },
        emitExtensionAttributesChange ($event) {
            this.$emit('update-extension-attributes', $event);
        }
    }
};
</script>
<style scoped lang="scss">
.extension-settings {
    ::v-deep .pendo-card__body {
        padding: 0px;
    }
}

::v-deep .pendo-collapse-item__content {
    padding-bottom: 0px;
}

.advanced-settings-collapse {
    ::v-deep .browser-ingest-row {
        font-size: 14px;
    }
}
</style>
