<template>
    <main class="features-list">
        <pendo-table
            v-pendo-loading:feather="isFetchingFeatureList"
            v-pendo-table-user-settings-watcher="{
                settingsName: featuresListUserSettingsName
            }"
            :data="formattedFeaturesList"
            :filters="filters"
            :columns="columns"
            :default-sort="sort"
            :cell-class-name="determineCellClasses"
            :auto-height="true"
            :auto-height-offset="96"
            csv-download
            title="Features"
            resizable
            manage-columns
            row-key="id"
            :row-class-name="getRowClassName"
            class="features-list--table">
            <template #headerActions>
                <div class="no-data-toggle">
                    <pendo-multiselect
                        v-if="!showNoFeaturesCTA"
                        v-model="featureDataFilter"
                        :options="featureDataFilterOptions"
                        :allow-empty="false"
                        preselect-first />
                </div>
            </template>
            <template #displayName="{ row }">
                <div
                    data-cy="features-list--table--display-name"
                    class="features-list--table--display-name"
                    @click="openDetailsPanel(row)">
                    <span class="features-list--table-name-link">{{ row.displayName }}</span>
                </div>
            </template>
            <template
                #appDisplayName="
                    {
                        row
                    }">
                <pendo-app-display :apps="row.app" />
            </template>
            <template #allRules="{ row }">
                <countable-table-rules
                    :rules="row.rules"
                    :is-expanded="!!expandedRules[row.id]"
                    @expanded="onRulesExpanded(row, $event)" />
            </template>
            <template #empty>
                <div
                    v-if="showNoFeaturesCTA"
                    class="features-list--table--empty__cta">
                    <pendo-empty-state title="No Features Tagged">
                        <template #icon>
                            <img
                                class="empty-state-img"
                                src="@/img/empty-states/feature-list-empty-state.svg"
                                alt="Empty State">
                        </template>
                        <template #description>
                            <div>
                                Feature analytics tells you what users are interacting with while using an application.
                            </div>
                            <pendo-button
                                v-if="canCreateFeature({ filtered: true })"
                                class="designer-launcher-cta"
                                theme="app"
                                type="secondary"
                                prefix-icon="external-link"
                                label="Tag First Feature"
                                @click="showFeatureModal" />
                        </template>
                    </pendo-empty-state>
                </div>
                <div
                    v-else
                    class="features-list--table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        No data found.
                    </span>
                </div>
            </template>
        </pendo-table>
        <delete-custom-entity-modal
            :visible="isDeleteCustomEntityModalVisible"
            :entity="currentlyDeletingRow"
            type="feature"
            @close="closeDeleteCustomEntityModal($event)" />
        <feature-details-sidebar
            v-if="sidebarVisible"
            :visible="sidebarVisible"
            :feature-id="currentlyViewingRow.id"
            @delete-feature="openDeleteCustomEntityModal(currentlyViewingRow)"
            @close-sidebar="closeDetailsPanel" />
    </main>
</template>

<script>
import FeatureDetailsSidebar from '@/components/analytics/features/FeatureDetailsSidebar.vue';
import DeleteCustomEntityModal from '@/components/common/DeleteCustomEntityModal.vue';
import PendoTableUserSettingsWatcher from '@/directives/PendoTableUserSettingsWatcher';
import { filterEntitiesByAppIds } from '@/utils/apps';
import {
    mergeDefaultColumnsWithUserSettings,
    mergeDefaultSortWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import {
    PendoAppDisplay,
    PendoButton,
    PendoEmptyState,
    PendoIcon,
    PendoLoading,
    PendoMultiselect,
    PendoTable,
    PendoNotification
} from '@pendo/components';
import { CountableTableRules, countableType } from '@pendo/tagging';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
    name: 'FeaturesList',
    components: {
        PendoButton,
        PendoIcon,
        PendoTable,
        DeleteCustomEntityModal,
        PendoEmptyState,
        PendoMultiselect,
        PendoAppDisplay,
        CountableTableRules,
        FeatureDetailsSidebar
    },
    directives: {
        PendoLoading,
        PendoTableUserSettingsWatcher
    },
    props: {
        searchString: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            currentlyEditingRow: {},
            currentlyViewingRow: {},
            isDeleteCustomEntityModalVisible: false,
            currentlyDeletingRow: {},
            featuresListUserSettingsName: 'featuresList',
            defaultSort: {
                prop: 'numEvents',
                order: 'descending'
            },
            featureDataFilterOptions: ['All', 'With Data', 'Without Data'],
            featureDataFilter: null,
            expandedRules: {}
        };
    },
    computed: {
        ...mapGetters({
            featuresList: 'features/listWithAnalytics',
            activeApps: 'apps/getListActiveApps',
            allFeaturesListWithAnalytics: 'features/listAllWithAnalytics',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription',
            filteredApps: 'filters/appsForAppIdFilter',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            pageById: 'pages/pageById',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName',
            canCreateFeature: 'features/canCreateFeature'
        }),
        ...mapState({
            isFetchingFeatureList: (state) => state.features.isFetchingWithAnalytics,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            featuresError: (state) => state.features.error,
            activeSubHasAnyFeatures: (state) => state.features.activeSubHasAnyFeatures
        }),
        features () {
            const features = this.allFeaturesListWithAnalytics;

            return filterEntitiesByAppIds(features, this.appIdsFilter).filter((feature) => {
                const hasData = feature.numEvents || feature.numVisitors;
                if (this.featureDataFilter === 'With Data') return hasData;
                if (this.featureDataFilter === 'Without Data') return !hasData;

                return true;
            });
        },
        formattedFeaturesList () {
            return this.features.map((feature) => {
                // Prevent vuex mutation errors
                const newFeature = { ...feature };
                newFeature.type = countableType(feature);
                newFeature.allRules = JSON.stringify(feature.elementPathRules);
                newFeature.searchRules = (feature.elementPathRules || []).join(' ');
                newFeature.rules = (feature.elementPathRules || []).map((rule) => ({ rule }));
                newFeature.description = get(feature, 'description', '---');

                if (feature.pageId && this.pageById(feature.pageId)) {
                    newFeature.pageName = this.pageById(feature.pageId).displayName;
                } else {
                    newFeature.pageName = 'All Pages';
                }

                newFeature.appDisplayName = get(feature, 'app.displayName', '');

                return newFeature;
            });
        },
        filters () {
            return [
                {
                    prop: ['displayName', 'description', 'appDisplayName', 'searchRules', 'type', 'pageName'],
                    value: this.searchString
                }
            ];
        },
        savedTableUserSettings () {
            return this.getTableUserSettingValueByName(this.featuresListUserSettingsName);
        },
        defaultColumns () {
            const defaultColumns = [
                {
                    sortable: true,
                    prop: 'displayName',
                    label: 'Feature Name',
                    visible: true,
                    width: 288
                },
                {
                    sortable: true,
                    prop: 'type',
                    visible: true,
                    width: 120,
                    label: 'Type'
                }
            ];

            defaultColumns.push(
                {
                    sortable: true,
                    prop: 'appDisplayName',
                    visible: true,
                    label: 'Application'
                },
                {
                    sortable: true,
                    prop: 'numVisitors',
                    visible: true,
                    width: 200,
                    label: 'Number of Visitors'
                },
                {
                    sortable: true,
                    prop: 'numEvents',
                    visible: true,
                    width: 200,
                    label: 'Number of Clicks'
                }
            );

            if (this.activeIsTrainingSubscription) {
                defaultColumns.push({
                    sortable: false,
                    prop: 'description',
                    label: 'Description',
                    visible: true,
                    showOverflow: false,
                    minWidth: 300
                });
            }

            defaultColumns.push(
                {
                    sortable: true,
                    prop: 'pageName',
                    visible: true,
                    label: 'Page Name',
                    minWidth: 288
                },
                {
                    sortable: true,
                    prop: 'allRules',
                    visible: true,
                    width: 220,
                    label: 'Rules'
                }
            );

            return defaultColumns;
        },
        columns () {
            return mergeDefaultColumnsWithUserSettings(this.defaultColumns, this.savedTableUserSettings);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.savedTableUserSettings);
        },
        appsWithoutFeatures () {
            let apps = [];
            if (this.isAppIdsFilterInUse) {
                const appsWithTaggedFeatures = this.features.map((features) => {
                    return features.app.id;
                });

                apps = this.filteredApps.filter(
                    (app) => !appsWithTaggedFeatures.find((featureAppId) => featureAppId === app.id)
                );
            }

            return apps;
        },
        showNoFeaturesCTA () {
            return (
                (!this.activeSubHasAnyFeatures || this.appsWithoutFeatures.length === 1) && this.activeIsDigitalAdoption
            );
        },
        sidebarVisible () {
            return !isEmpty(this.currentlyViewingRow);
        }
    },
    watch: {
        features: {
            handler (newValue) {
                const { id } = this.$route.query;
                if (!id || newValue.length === 0) return;

                const openFeature = this.features.find((feature) => feature.id === id);
                if (openFeature) {
                    this.currentlyViewingRow = openFeature;
                    this.setFeatureDetailsSidebarOpen({ isFeatureDetailsSidebarOpen: true });
                } else {
                    this.$router.push({ path: 'features', query: {} });
                    PendoNotification({
                        type: 'error',
                        title: 'Unable to view feature',
                        message: 'The feature was not found.',
                        duration: 5000
                    });
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            setFeatureDetailsSidebarOpen: 'features/setFeatureDetailsSidebarOpen'
        }),
        filterByFeatureRules (row) {
            return get(row, 'elementPathRules', []).some((featureRule) => featureRule.includes(this.searchString));
        },
        determineCellClasses ({ column }) {
            return `${column.columnKey}-cell`;
        },
        openDetailsPanel (row) {
            this.setFeatureDetailsSidebarOpen({ isFeatureDetailsSidebarOpen: true });
            this.currentlyViewingRow = row;
            this.$router.push({ path: 'features', query: { id: row.id } });
        },
        closeDetailsPanel () {
            this.setFeatureDetailsSidebarOpen({ isFeatureDetailsSidebarOpen: false });
            this.currentlyViewingRow = {};
            this.$router.push({ query: {} });
        },
        openDeleteCustomEntityModal (row) {
            this.currentlyDeletingRow = row;
            this.isDeleteCustomEntityModalVisible = true;
        },
        popDeleteNotification ({ name = '' }) {
            PendoNotification({
                type: 'success',
                title: 'Deleted Feature Successfully',
                message: `The Feature ${name} was successfully deleted.`,
                duration: 2000,
                showClose: false
            });
        },
        closeDeleteCustomEntityModal ({ didDelete = false }) {
            if (didDelete) {
                this.closeDetailsPanel(); // close the sidebar after deletion
                this.popDeleteNotification(this.currentlyDeletingRow);
            }
            this.currentlyDeletingRow = {};
            this.isDeleteCustomEntityModalVisible = false;
        },
        getRowClassName ({ row }) {
            return this.currentlyViewingRow.id === row.id ? 'selected' : '';
        },
        showFeatureModal () {
            this.$modal.show('via-modal', {
                title: "Enter your app's URL",
                component: 'LaunchDesignerModal',
                componentProps: {
                    type: 'feature'
                }
            });
        },
        onRulesExpanded (row, expanded) {
            this.$set(this.expandedRules, row.id, expanded);
        }
    }
};
</script>

<style lang="scss">
.features-list--table--display-name:hover .features-list--table-name-link,
.features-list--table--display-name.hover .features-list--table-name-link {
    text-decoration: underline;
    color: $teal-darker-1;
}

.features-list {
    &--table {
        .selected {
            background-color: $color-blue-5;
        }

        .visibility-note {
            font-size: 0.875rem;
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
        }

        .no-data-toggle {
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: right;
            flex-grow: 1;
        }

        &--display-name {
            display: flex;
            align-items: baseline;
            cursor: pointer;
        }

        &-name-link {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $teal-primary;
            font-weight: 600;
        }

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }

            &__cta {
                height: 400px;

                .designer-launcher-cta {
                    margin: 18px 0;
                }
            }
        }

        .pendo-table__title-actions {
            grid-template-columns: auto 36px;
            flex-grow: 1;
        }

        .pendo-table__cell {
            word-break: normal;
        }

        .description-cell {
            color: $gray-lighter-2;
        }
    }
}
</style>
