<template>
    <svg
        :x="x"
        :y="y"
        :width="width"
        :height="height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img">
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="32"
            height="32">
            <path
                d="M16 0H32V16L16 32V16H0Z"
                fill="black" />
        </mask>
        <g mask="url(#mask0)">
            <path
                d="M16 0H32V32H0V16Z"
                fill="#D6226A" />
            <path
                d="M21 0H32V32H0V21Z"
                fill="#FF7107" />
            <path
                d="M26 0H32V32H0V26Z"
                fill="#F2C80F" />
            <path
                d="M32 0V32H0Z"
                fill="#00C583" />
            <path
                d="M32 6V32H6Z"
                fill="#119AD3" />
            <path
                d="M32 11V32H11Z"
                fill="#8369C1" />

            <path
                d="M0 0H32V32H24.5V7.5H0Z"
                fill="#E3E3E3" />
            <path
                d="M0 0H32V32H26V6H0Z"
                fill="#EE93B8" />
            <path
                d="M0 0H32V32H27.5V4.5H0Z"
                fill="#22C4D6" />
            <path
                d="M0 0H32V32H29V3H0Z"
                fill="#815832" />
            <path
                d="M0 0H32V32H30.5V1.5H0Z"
                fill="#1C1C1C" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'PrideMonthChevron',
    props: {
        x: {
            type: Number,
            default: undefined
        },
        y: {
            type: Number,
            default: undefined
        },
        width: {
            type: Number,
            default: 32
        },
        height: {
            type: Number,
            default: 32
        }
    }
};
</script>
