<template>
    <div class="track-event-dashboard">
        <global-filters>
            <template #right>
                <search
                    v-model="searchInput"
                    placeholder="Search" />
            </template>
        </global-filters>
        <div class="track-event-dashboard--grid">
            <div class="track-event-dashboard--row">
                <analytics-track-event-list
                    :search-string="searchInput"
                    class="track-event-dashboard--visitor-list" />
            </div>
        </div>
    </div>
</template>

<script>
import AnalyticsTrackEventList from './AnalyticsTrackEventList.vue';
import GlobalFilters from '@/components/filters/GlobalFilters';
import Search from '@/components/Search';
import { mapActions } from 'vuex';

export default {
    name: 'TrackEventsDashboard',
    components: {
        AnalyticsTrackEventList,
        GlobalFilters,
        Search
    },
    data () {
        return {
            searchInput: ''
        };
    },
    async created () {
        await this.fetchTrackEvents();
    },
    methods: {
        ...mapActions({
            fetchTrackEvents: 'trackEvents/fetchWithAnalytics'
        })
    }
};
</script>

<style lang="scss">
.track-event-dashboard {
    .track-event-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .track-event-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));

        &:not(:last-child) {
            min-height: 448px;
        }

        &.half-size-row {
            min-height: 224px;
        }
    }
}
</style>
