<template>
    <pendo-app
        id="app"
        :sidebar-width="sidebarWidth"
        topbar-height="0px"
        background-color="#F8F8F9">
        <div v-if="!hideNavbar">
            <left-navbar />
        </div>
        <pendo-page>
            <router-view
                id="view"
                :key="globalRouteKey" />
            <router-view name="footer" />
        </pendo-page>
        <via-modal />
        <div
            v-if="updatingActiveApp || fullscreenLoading"
            class="full-app-loading">
            <via-rings :loading="true" />
        </div>
    </pendo-app>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { PendoApp, PendoPage } from '@pendo/components';
import ViaModal from '@/components/ViaModal';
import ViaRings from '@/components/ViaRings';
import LeftNavbar from '@/components/LeftNavbar';
import get from 'lodash/get';
import store from '@/state/store';

export default {
    name: 'ViaUI',
    components: {
        PendoApp,
        PendoPage,
        ViaModal,
        ViaRings,
        LeftNavbar
    },
    computed: {
        ...mapState({
            updatingActiveApp: (state) => state.apps.updatingActive,
            fullscreenLoading: (state) => state.router.showFullscreenLoading,
            globalRouteKey: (state) => state.router.globalRouteKey
        }),
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            isLoaded: 'auth/isLoaded',
            authRouteGuardLoadingComplete: 'auth/authRouteGuardLoadingComplete',
            areSegmentFlagsLoaded: 'auth/areSegmentFlagsLoaded',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        hideNavbar () {
            if (get(this.$route.meta, 'hideNavbar', false)) return true;

            return !this.isAuthenticated;
        },
        sidebarWidth () {
            if (!this.hideNavbar) {
                return '72px;';
            }

            return 0;
        }
    },
    created () {
        this.setupSuggestedPageTableWatchers();

        const clearWatcher = this.$watch(
            () => {
                return this.isLoaded && this.areSegmentFlagsLoaded;
            },
            (isDoneLoading) => {
                if (!isDoneLoading) return;

                this.setShowFullscreenLoading({ shouldShow: false });
                clearWatcher();
            }
        );
    },
    methods: {
        ...mapMutations({
            setShowFullscreenLoading: 'router/setShowFullscreenLoading'
        }),
        setupSuggestedPageTableWatchers () {
            // these states are directly tied to suggested page notifications
            // to keep them relevant we need them to run in a long lived Vue component
            store.watch(
                (state) => {
                    return state.pages.suggestedPageRulesHandledList;
                },
                function () {
                    store.dispatch('pages/createSuggestedPageRules');
                }
            );
            store.watch(
                (state) => {
                    return state.pages.subscriptionPageRules;
                },
                function () {
                    store.dispatch('pages/createSuggestedPageRules');
                }
            );
        }
    }
};
</script>

<style lang="scss">
@import 'styles/index';

.full-app-loading {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); // stylelint-disable-line sh-waqar/declaration-use-variable
}
</style>
