<template>
    <svg
        width="51"
        height="51"
        viewBox="0 0 51 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M41.3926 33.5253L27.3213 9.4186C26.5075 8.02445 24.493 8.02445 23.6792 9.4186L9.60785 33.5253C8.78733 34.931 9.80124 36.6968 11.4289 36.6968H39.5716C41.1992 36.6968 42.2131 34.931 41.3926 33.5253Z"
            fill="#8B4BEF" />
        <path
            d="M26.6899 18.0186H24.1862L24.3932 27.3734H26.4764L26.6899 18.0186ZM25.4348 31.4104C26.1982 31.4104 26.8516 30.7764 26.8581 29.9871C26.8516 29.2108 26.1982 28.5767 25.4348 28.5767C24.6455 28.5767 24.0051 29.2108 24.0115 29.9871C24.0051 30.7764 24.6455 31.4104 25.4348 31.4104Z"
            fill="#DCC9FA" />
        <circle
            cx="25.5002"
            cy="25.5556"
            r="22.9553"
            stroke="#060119"
            stroke-width="1.40571"
            stroke-miterlimit="1.41421"
            stroke-dasharray="5.31 5.31" />
    </svg>
</template>

<script>
export default {
    name: 'GuideAuditRevisionListEmptyState'
};
</script>
