<template>
    <div class="path-duplicate-action">
        <pendo-icon-button
            tooltip="Copy"
            icon="copy"
            @click="duplicateReport" />
    </div>
</template>

<script>
import { PendoIconButton } from '@pendo/components';
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'PathDuplicateAction',
    components: {
        PendoIconButton
    },
    props: {
        report: {
            type: Object,
            required: true
        },
        canShare: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('reports', ['activeReport'])
    },
    methods: {
        ...mapActions('reports', ['create']),
        async duplicateReport () {
            const newReport = cloneDeep(this.report);
            delete newReport.id;
            newReport.name = `Copy Of ${newReport.name}`;

            if (!this.canShare) {
                newReport.shared = false;
            }

            await this.create({ report: newReport });

            this.$router
                .push({
                    params: {
                        id: this.activeReport.id
                    }
                })
                .catch(() => {});
        }
    }
};
</script>
