import upperFirst from 'lodash/upperFirst';

export const MOBILE_PLATFORMS = ['ios', 'android'];
const IOS_PLATFORM = 'ios';
const ANDROID_PLATFORM = 'android';
const WEB_PLATFORM = 'web';
const EXTENSION_PLATFORM = 'extension';

const IOS_PLATFORM_LABEL = 'iOS';
const ANDROID_PLATFORM_LABEL = 'Android';
const WEB_PLATFORM_LABEL = 'Web';
const EXTENSION_PLATFORM_LABEL = 'Extension';
const UNKNOWN_PLATFORM_LABEL = 'Unknown';

const platformLabels = {
    [IOS_PLATFORM]: IOS_PLATFORM_LABEL,
    [ANDROID_PLATFORM]: ANDROID_PLATFORM_LABEL
};

export function getFrameworkLabel (framework) {
    if (framework === 'react') {
        return 'React Native';
    }

    if (framework === 'xamarinForms') {
        return 'Xamarin Forms 5+';
    }

    return upperFirst(framework);
}

export function getPlatformLabel (app) {
    if (!app || !app.platform) {
        return UNKNOWN_PLATFORM_LABEL;
    }

    const { platform } = app;
    if (platform === WEB_PLATFORM) {
        return WEB_PLATFORM_LABEL;
    }
    if (platform === EXTENSION_PLATFORM) {
        return EXTENSION_PLATFORM_LABEL;
    }

    if (platform === IOS_PLATFORM || platform === ANDROID_PLATFORM) {
        const platformLabel = platformLabels[app.platform];

        if (app.framework) {
            const framework = getFrameworkLabel(app.framework);

            return `${framework} ${platformLabel}`.trim();
        }

        return platformLabel;
    }

    return UNKNOWN_PLATFORM_LABEL;
}

export function isMobile (app) {
    return app ? MOBILE_PLATFORMS.includes(app.platform) : false;
}
