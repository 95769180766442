import { http } from '@pendo/http';
import values from 'lodash/values';

export function getInitialState () {
    return {
        loading: false,
        layouts: null
    };
}

export const state = getInitialState();

export const mutations = {
    setLoading (state, { loading }) {
        state.loading = loading;
    },
    setLayouts (state, { layouts }) {
        state.layouts = layouts;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    async fetchLayouts ({ commit, state }) {
        if (state.layouts) return;

        commit('setLoading', { loading: true });

        const layoutsResponse = await http.get('/api/s/_SID_/layout');
        const layouts = layoutsResponse.data;

        commit('setLayouts', { layouts });
        commit('setLoading', { loading: false });
    }
};

export const getters = {
    list: (state) => (state.layouts ? values(state.layouts) : 'nothing'),
    mobileLayouts: (state) => {
        return state.layouts ? state.layouts.filter(isMobile) : [];
    },
    webLayouts: (state) => (state.layouts ? state.layouts.filter(isWeb) : []),
    webLayoutsWithoutCodeBlocks: (state, getters) => {
        const web = getters.webLayouts;

        return web ? web.filter((layout) => !hasCustomCode(layout)) : [];
    },
    mobileLayoutsWithoutCodeBlocks: (state, getters) => {
        return getters.mobileLayouts.filter((layout) => !hasCustomCode(layout));
    }
};

export function hasCustomCode ({ attributes }) {
    const hasCustomHtml = attributes && attributes.content;
    const hasCustomCss = attributes && attributes.contentCSS;
    const hasCustomJs = attributes && attributes.contentJs;

    return hasCustomHtml || hasCustomCss || hasCustomJs;
}

export function isWeb ({ supportedPlatforms }) {
    return !supportedPlatforms || supportedPlatforms.web;
}

export function isMobile ({ supportedPlatforms }) {
    return supportedPlatforms.mobile;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
