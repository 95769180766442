<template>
    <div class="workflow-over-time-card">
        <pendo-card>
            <template #title>
                <div
                    v-if="!isRecurring"
                    class="workflow-over-time-card--chart-label">
                    Cumulative progress
                </div>
                <div
                    v-if="isRecurring"
                    class="workflow-over-time-card--dropdown"
                    data-cy="over-time-card-data-source-dropdown">
                    <pendo-multiselect
                        :allow-empty="false"
                        :min-menu-width="180"
                        :disabled="isEmpty"
                        :options="dataSourceOptions"
                        :value="dataSourceOption"
                        @select="onUpdateDataSource($event.id)">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
                by
                <div
                    class="workflow-over-time-card--dropdown"
                    data-cy="over-time-card-time-period-dropdown">
                    <pendo-multiselect
                        :allow-empty="false"
                        :min-menu-width="80"
                        :disabled="isEmpty"
                        :options="timePeriodOptions"
                        :value="selectedTimePeriodOption"
                        @select="onUpdateTimePeriod($event.id)">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
            </template>

            <template #headerRight>
                <div data-cy="over-time-card-guide-dropdown">
                    <pendo-multiselect
                        :disabled="isGuideDropdownDisabled"
                        label-key="name"
                        :loading="areGuidesLoading"
                        :options="guideOptions"
                        placeholder="Select a guide to view impact"
                        :value="selectedGuideOption"
                        @select="onGuideSelected($event.id)">
                        <template #option="{ option }">
                            <pendo-icon-option
                                v-if="option.id !== null"
                                :option="option" />
                            <div
                                v-if="option.id === null"
                                class="workflow-over-time-card--none-dropdown-option">
                                {{ option.name }}
                            </div>
                        </template>

                        <template #selectedLabel="{ selectedLabel }">
                            <div class="segment-filter--label">
                                <pendo-icon
                                    type="guide"
                                    size="14" />
                                <span>
                                    {{ selectedLabel }}
                                </span>
                            </div>
                        </template>
                    </pendo-multiselect>
                </div>
            </template>

            <template #body>
                <pendo-loading-indicator
                    v-if="loading"
                    class="workflow-over-time-card--loading"
                    type="material"
                    size="large"
                    :spinner-props="{
                        background: '#ffffff'
                    }" />
                <div
                    v-if="isGuideDetailsVisible"
                    class="workflow-over-time-card--guide-details"
                    data-cy="over-time-card-guide-details">
                    <span>
                        {{ guideSegmentLabel }}
                    </span>
                    <pendo-icon
                        size="16"
                        type="user" />
                    <span class="workflow-over-time-card--guide-detail-divider">|</span>
                    <span>
                        {{ guidePublishedLabel }}
                    </span>
                    <div class="workflow-over-time-card--guide-identifier">
                        ★
                    </div>
                </div>

                <div
                    class="workflow-over-time-card--container"
                    data-cy="over-time-card-chart">
                    <div
                        v-if="isEmpty"
                        class="workflow-over-time-card--empty-state">
                        <p class="workflow-over-time-card--empty-state-text">
                            No data found
                        </p>
                    </div>
                    <div
                        v-else
                        ref="over-time-chart"
                        class="workflow-over-time-card--chart" />
                </div>
            </template>
        </pendo-card>
    </div>
</template>

<script>
import get from 'lodash/get';

import {
    PendoCard,
    PendoDataSourceTrigger,
    PendoIcon,
    PendoIconOption,
    PendoMultiselect,
    PendoLoadingIndicator
} from '@pendo/components';

import { CHART_BASE } from '@/utils/highcharts';
import { reusableTooltipFormatter } from '@/utils/highcharts';
import { convertPeriodStringToDateMathString } from '@/utils/time-series';
import { pluralize } from '@/utils/formatters';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';

export default {
    name: 'WorkflowOverTimeCard',
    components: {
        PendoCard,
        PendoDataSourceTrigger,
        PendoIcon,
        PendoIconOption,
        PendoMultiselect,
        PendoLoadingIndicator
    },
    props: {
        savedWorkflowSettings: {
            type: Object,
            default: null
        },
        dateRange: {
            type: Object,
            required: true
        },
        subscriptionUtcOffset: {
            type: Number,
            required: true
        },
        guideDropdownOptions: {
            type: Array,
            required: true
        },
        areGuidesLoading: {
            type: Boolean,
            default: false
        },
        selectedTimePeriodOption: {
            type: Object,
            default: null
        },
        timePeriodOptions: {
            type: Array,
            default: null
        },
        overTimeAggData: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        isRecurring: {
            type: Boolean,
            default: false
        },
        isReportWorkflow: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            chart: null,
            chartColors: ['#1967D2', '#FF4994'],
            dataSourceOptions: [
                {
                    id: 'workflow-attempts',
                    label: 'Workflow attempts'
                },
                {
                    id: 'visitor-completion',
                    label: 'Visitor completion'
                }
            ]
        };
    },
    computed: {
        isEmpty () {
            if (this.loading) return false;

            const sortedData = get(
                this.overTimeAggData,
                `sortedData${this.isRecurringReport ? this.formattedSelectedTimePeriodOption : ''}`,
                []
            );
            const overTimeEmpty = sortedData.every((row) => {
                const rowData = row[1];
                // Check to make sure that we have rows, and that the response is not just filled with zeroes
                if (rowData.length === 0) return true;

                return rowData.every((childRow) => {
                    return Object.values(childRow).reduce((a, b) => a + b, 0) === 0;
                });
            });

            return overTimeEmpty;
        },
        chartConfig () {
            const chartConfig = {
                ...CHART_BASE,
                chart: {
                    height: 515,
                    type: 'column'
                },
                colors: this.chartColors,
                exporting: {
                    enabled: false
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    margin: 10,
                    itemStyle: {
                        color: '#000',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: '400',
                        textOverflow: 'ellipsis'
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    },
                    series: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series: this.chartData,
                time: {
                    timezoneOffset: this.subscriptionUtcOffset
                },
                title: {
                    text: null
                },
                tooltip: {
                    borderColor: '#DADCE5',
                    padding: 0,
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14
                    },
                    shadow: {
                        color: 'rgba(0, 0, 0, 0.17)',
                        width: 8
                    },
                    useHTML: true
                },
                xAxis: {
                    categories: this.periods,
                    crosshair: {
                        color: 'rgba(0,0,0,0)'
                    },
                    labels: {
                        y: 20,
                        formatter: ({ value }) => {
                            switch (this.selectedTimePeriodOption.id) {
                                case 'hourly':
                                    return this.$pendo.moment(value).format('h:mm a');
                                case 'daily':
                                    return this.$pendo.moment(value).format('MMM D');
                                case 'weekly':
                                    return this.$pendo.moment(value).format('MMM D');
                                case 'monthly':
                                    return this.$pendo.moment(value).format('MMM Y');
                            }
                        },
                        style: {
                            color: '#6A6C75',
                            fontSize: '12px'
                        }
                    },
                    maxPadding: 0,
                    plotBands: this.chartXAxisPlotBands,
                    plotLines: this.chartXAxisPlotLines,
                    title: {
                        style: {
                            color: '#1A1C25',
                            fontSize: '12px'
                        },
                        text: startCase(get(this.selectedTimePeriodOption, 'label', ''))
                    },
                    type: 'datetime'
                },
                yAxis: {
                    labels: {
                        align: 'right',
                        style: {
                            color: '#6A6C75',
                            fontSize: '12px'
                        },
                        x: -6,
                        y: 4
                    },
                    maxPadding: 0,
                    min: 0,
                    title: {
                        style: {
                            color: '#1A1C25',
                            fontSize: '12px'
                        },
                        text: this.chartYAxisTitle
                    }
                }
            };

            function tooltipFormatter () {
                const { series, point } = this;
                const { y, dateRangeLabel, valueSubLabel, dataSource } = point;
                const dataSourceIsAttempts = dataSource === 'workflow-attempts';
                const color = point.color || series.color;

                const options = {
                    classes: ['workflow-over-time-card--tooltip'],
                    color,
                    headerTitle: point.name || series.name,
                    headerDescription: dateRangeLabel
                };

                options.bodyMetric = `${y.toLocaleString()} <span class="small">${pluralize(valueSubLabel, y)}</span>`;
                options.bodyDescription = `<span class="small">${Math.floor(point.percentage)}% of total ${
                    dataSourceIsAttempts ? 'attempts' : 'visitors'
                }</span>`;
                if (point.isRecurring) {
                    options.bodyDescription += `<br><span class="small">${point.averageNumberOfAttemptsPerVisitor} average attempts per visitor</span>`;
                }

                return reusableTooltipFormatter(options);
            }

            chartConfig.tooltip.formatter = tooltipFormatter;

            return chartConfig;
        },
        chartData () {
            return [
                {
                    name: 'Incomplete',
                    color: '#1967D2',
                    data: this.getChartData('Incomplete'),
                    legendIndex: 1
                },
                {
                    name: 'Complete',
                    color: '#FF4994',
                    data: this.getChartData('Complete'),
                    legendIndex: 0
                }
            ];
        },
        chartXAxisPlotBands () {
            // This is a hacky method used to give the plot line a border. The combination of zIndex, borderWidth, and
            //  translating the plot band over in CSS makes this possible. The reason we couldn't just add a stroke or
            //  border to the plotLine is because it uses stroke for the line itself and adding border to the outside
            //  element has padding.

            if (this.guidePublishedIndex === -1) return [];

            return [
                {
                    borderColor: '#FFF',
                    borderWidth: 2,
                    className: 'workflow-over-time-card--guide-line-border',
                    color: '#FFF',
                    from: this.guidePublishedIndex,
                    to: this.guidePublishedIndex,
                    zIndex: 5
                }
            ];
        },
        chartXAxisPlotLines () {
            if (this.guidePublishedIndex === -1) return [];

            return [
                {
                    align: 'left',
                    color: '#4A4C55',
                    label: {
                        useHTML: true,
                        rotation: 0,
                        text:
                            '<div style="background:#4A4C55;border-radius:4px;color:#FFF;font-size:12px;font-weight:600;height:22px;left:-31px;line-height:22px;position:relative;text-align:center;width:22px">★</div>'
                    },
                    value: this.guidePublishedIndex,
                    width: 1,
                    zIndex: 6
                }
            ];
        },
        chartYAxisTitle () {
            if (!this.isRecurring) return 'Visitors';

            if (this.dataSourceOption.id === 'workflow-attempts') {
                return 'Number of workflow attempts';
            }

            return 'Number of Visitors';
        },
        dataSourceOption () {
            const setting = get(this.savedWorkflowSettings, 'dataSource', null);

            return this.dataSourceOptions.find((option) => option.id === setting) || this.dataSourceOptions[0];
        },
        guideOptions () {
            const defaultOptions = [
                {
                    id: null,
                    name: 'None'
                }
            ];

            return defaultOptions.concat(this.guideDropdownOptions);
        },
        guidePublishedIndex () {
            if (!this.selectedGuideOption) return -1;

            const dateMathString = convertPeriodStringToDateMathString(this.selectedTimePeriodOption.id);

            return this.periods.findIndex((period, periodIndex) => {
                let periodEnd;

                if (periodIndex + 1 < this.periods.length) {
                    periodEnd = this.periods[periodIndex + 1];
                } else {
                    const periodStart = this.$pendo.moment(period);
                    periodEnd = periodStart.add(1, dateMathString).unix() * 1000;
                }

                const guideWasPublishedAfterPeriodStart = this.selectedGuideOption.publishedAt >= period;
                const guideWasPublishedBeforePeriodEnd = this.selectedGuideOption.publishedAt < periodEnd;
                const guideWasPublishedInsidePeriod =
                    guideWasPublishedAfterPeriodStart && guideWasPublishedBeforePeriodEnd;

                return guideWasPublishedInsidePeriod;
            });
        },
        guidePublishedLabel () {
            if (!this.selectedGuideOption) return '';

            return `Guide Published: ${this.$pendo.moment(this.selectedGuideOption.publishedAt).format('MMM D, YYYY')}`;
        },
        guideSegmentLabel () {
            if (!this.selectedGuideOption) return '';

            return this.selectedGuideOption.segmentName;
        },
        isGuideDetailsVisible () {
            return !this.areGuidesLoading && !this.loading && this.selectedGuideOption;
        },
        isGuideDropdownDisabled () {
            return this.areGuidesLoading || this.guideDropdownOptions.length === 0 || this.isEmpty;
        },
        periods () {
            if (this.isRecurring) {
                if (this.isReportWorkflow) {
                    return this.overTimeAggData[`periods${this.formattedSelectedTimePeriodOption}`];
                }

                return this.overTimeAggData.periods;
            }

            return this.calculatePeriods(this.dateRange, this.selectedTimePeriodOption.id);
        },
        selectedGuideId () {
            return get(this.savedWorkflowSettings, 'guideMonitorOverTimeChart', null);
        },
        selectedGuideOption () {
            if (this.selectedGuideId === null) return null;

            return this.guideOptions.find((guide) => guide.id === this.selectedGuideId);
        },
        formattedSelectedTimePeriodOption () {
            if (!this.isReportWorkflow) return '';

            return capitalize(this.selectedTimePeriodOption.id);
        },
        isRecurringReport () {
            return this.isReportWorkflow && this.isRecurring;
        }
    },
    watch: {
        overTimeAggData (data) {
            if (!data) return;

            this.chart = this.$pendo.highcharts.chart(this.$refs['over-time-chart'], this.chartConfig);
        },
        selectedTimePeriodOption () {
            this.refreshChart();
        },
        selectedGuideId () {
            this.refreshChart();
        },
        dataSourceOption () {
            this.refreshChart();
        }
    },
    mounted () {
        if (!this.overTimeAggData || !this.isReportWorkflow) return;

        this.chart = this.$pendo.highcharts.chart(this.$refs['over-time-chart'], this.chartConfig);
    },
    beforeDestroy () {
        if (!this.chart) return;

        this.chart.destroy();
    },
    methods: {
        getStartOrEndOfPeriod (option, date, period) {
            const parsedPeriod = period === 'daily' ? 'day' : period.substring(0, period.length - 2);

            return option === 'start'
                ? this.$pendo.moment(date).startOf(parsedPeriod)
                : this.$pendo.moment(date).endOf(parsedPeriod);
        },
        calculatePeriods (dateRange, period) {
            const { start, end } = dateRange;
            const endDate = period === 'hourly' ? this.$pendo.moment(end).add(1, 'days') : this.$pendo.moment(end); // add a day to the end if we use hourly bc agg logic

            const compareDate = this.getStartOrEndOfPeriod('start', start, period);
            const dateMathString = convertPeriodStringToDateMathString(period);

            const periods = [start];

            while (compareDate.isSameOrBefore(endDate)) {
                compareDate.add(1, dateMathString);

                const startOfPeriod = this.$pendo.moment(compareDate);
                const endOfPeriod = this.getStartOrEndOfPeriod('end', compareDate, period);

                const periodIsOutsideDateRange = startOfPeriod.isAfter(endDate) || endOfPeriod.isAfter(endDate);

                if (periodIsOutsideDateRange) break;

                periods.push(compareDate.unix() * 1000);
            }

            return periods;
        },
        getChartData (name) {
            const { isRecurring } = this;
            const dataSource = this.dataSourceOption.id;
            const sortedData = get(
                this.overTimeAggData,
                `sortedData${this.isRecurringReport ? this.formattedSelectedTimePeriodOption : ''}`,
                []
            );

            if (!sortedData.length) return [];

            if (isRecurring) {
                return this.formatRecurringChartData(sortedData, name, dataSource, this.dateRange);
            }

            return this.formatNonRecurringChartData(
                sortedData,
                this.periods,
                name,
                this.dateRange,
                this.selectedTimePeriodOption.id
            );
        },
        formatRecurringChartData (sortedData, name, dataSource, dateRange) {
            return sortedData.reduce((acc, data) => {
                const row = get(data, '[1][0]', {});
                const period = parseInt(get(data, '[0]', 0));
                const dateRangeLabel = this.getDateRangeLabel(period, dateRange.start, dateRange.end);
                const valueSubLabel = dataSource === 'workflow-attempts' ? 'attempts' : 'visitors';
                const isAttempts = dataSource === 'workflow-attempts';
                const attempts = name === 'Incomplete' ? row.totalIncompleteAttempts : row.totalCompleteAttempts;
                const visitors = name === 'Incomplete' ? row.totalIncompleteVisitors : row.totalCompleteVisitors;

                let averageNumberOfAttemptsPerVisitor = 0;

                if (visitors === 0) {
                    averageNumberOfAttemptsPerVisitor = attempts;
                } else if (attempts !== 0 && visitors !== 0) {
                    averageNumberOfAttemptsPerVisitor = Math.round(attempts / visitors);
                }

                acc.push({
                    averageNumberOfAttemptsPerVisitor,
                    dateRangeLabel,
                    period,
                    dataSource,
                    valueSubLabel,
                    isRecurring: true,
                    y: isAttempts ? attempts : visitors
                });

                return acc;
            }, []);
        },
        formatNonRecurringChartData (sortedData, periods, name, dateRange, timePeriod) {
            return periods.reduce((acc, period, i) => {
                const valueSubLabel = 'visitors';
                const count =
                    name === 'Incomplete'
                        ? `incompleteCount${this.formattedSelectedTimePeriodOption}`
                        : `completeCount${this.formattedSelectedTimePeriodOption}`;
                const dateRangeLabel = this.getDateRangeLabel(period, dateRange.start, dateRange.end);
                const rows = get(sortedData, '[0][1]');
                let foundRow = false;
                switch (timePeriod) {
                    case 'weekly':
                        foundRow = rows.find(
                            (row) =>
                                this.$pendo.moment(this.isReportWorkflow ? row.periodWeekly : row.period).week() ===
                                this.$pendo.moment(period).week()
                        );
                        break;
                    case 'monthly':
                        foundRow = rows.find(
                            (row) =>
                                this.$pendo.moment(this.isReportWorkflow ? row.periodMonthly : row.period).month() ===
                                this.$pendo.moment(period).month()
                        );
                        break;

                    default:
                        foundRow = rows.find(
                            (row) =>
                                (this.isReportWorkflow
                                    ? row[`period${this.formattedSelectedTimePeriodOption}`]
                                    : row.period) === period
                        );
                        break;
                }

                if (foundRow) {
                    const y = get(foundRow, count, 0);
                    acc.push({
                        period,
                        dateRangeLabel,
                        isRecurring: false,
                        valueSubLabel,
                        y: i === 0 ? y : acc[i - 1].y + y
                    });
                } else {
                    // need to zero it out, bc there is no data for it.
                    acc.push({
                        period,
                        dateRangeLabel,
                        isRecurring: false,
                        valueSubLabel,
                        y: i === 0 ? 0 : acc[i - 1].y
                    });
                }

                return acc;
            }, []);
        },
        getDateRangeLabel (currPeriod, startDate, endDate) {
            const { id: timePeriod } = this.selectedTimePeriodOption;
            const startPeriod = this.$pendo.moment(startDate).isAfter(this.$pendo.moment(currPeriod))
                ? this.$pendo.moment(currPeriod)
                : this.$pendo.moment(startDate);
            const endPeriod = this.$pendo.moment(currPeriod).isBefore(this.$pendo.moment(startDate))
                ? this.$pendo.moment(startDate)
                : this.$pendo.moment(currPeriod);
            const isSameDay = startPeriod.get('day') === endPeriod.get('day');
            const isSameMonth = startPeriod.get('month') === endPeriod.get('month');

            switch (timePeriod) {
                case 'hourly':
                    if (startPeriod.isSame(endPeriod)) {
                        return `${this.$pendo.moment(startPeriod).format('MMM D h:mm a')}`;
                    }
                    if (this.isRecurring) return `${this.$pendo.moment(currPeriod).format('MMM D h:mm a')}`;

                    return isSameDay
                        ? `${startPeriod.format('MMM D h:mm a')} to ${endPeriod.format('h:mm a')}`
                        : `${startPeriod.format('MMM D h:mm a')} to ${endPeriod.format('MMM D h:mm a')}`;
                case 'daily':
                    if (startPeriod.isSame(endPeriod)) return `${this.$pendo.moment(startPeriod).format('MMM D')}`;
                    if (this.isRecurring) return `${this.$pendo.moment(currPeriod).format('MMM D')}`;

                    return isSameMonth
                        ? `${startPeriod.format('MMM D')} to ${endPeriod.format('D')}`
                        : `${startPeriod.format('MMM D')} to ${endPeriod.format('MMM D')}`;
                case 'weekly':
                    if (startPeriod.isSame(endPeriod)) return `${this.$pendo.moment(startPeriod).format('MMM D')}`;
                    if (this.isRecurring) {
                        const endRecurringPeriod = this.$pendo
                            .moment(currPeriod)
                            .add(1, 'week')
                            .isSameOrAfter(this.$pendo.moment(endDate))
                            ? this.$pendo.moment(endDate)
                            : this.$pendo.moment(currPeriod).add(1, 'week');
                        const recurringEndFormat =
                            this.$pendo.moment(currPeriod).month() === endRecurringPeriod.month()
                                ? endRecurringPeriod.format('D')
                                : endRecurringPeriod.format('MMM D');

                        return this.$pendo.moment(currPeriod).isSame(endRecurringPeriod)
                            ? this.$pendo.moment(currPeriod).format('MMM D')
                            : `${this.$pendo.moment(currPeriod).format('MMM D')} to ${recurringEndFormat}`;
                    }

                    return isSameMonth
                        ? `${startPeriod.format('MMM D')} to ${endPeriod.format('D')}`
                        : `${startPeriod.format('MMM D')} to ${endPeriod.format('MMM D')}`;
                case 'monthly':
                    if (startPeriod.month() === endPeriod.month()) {
                        return `${this.$pendo.moment(startPeriod).format('MMM Y')}`;
                    }
                    if (this.isRecurring) return `${this.$pendo.moment(currPeriod).format('MMM Y')}`;

                    return `${startPeriod.format('MMM Y')} to ${endPeriod.format('MMM Y')}`;
            }
        },
        onGuideSelected (guideId) {
            this.$emit('guideSelected', guideId);
            if (!this.isReportWorkflow) this.refreshChart();
        },
        onUpdateDataSource (value) {
            this.$emit('updateDataSource', value);
            if (!this.isReportWorkflow) this.refreshChart();
        },
        onUpdateTimePeriod (value) {
            this.$emit('updateTimePeriod', value);
            if (!this.isReportWorkflow) this.refreshChart();
        },
        refreshChart () {
            if (!this.chart) return;

            if (this.isReportWorkflow) {
                this.chart.update(this.chartConfig);

                return;
            }

            // Push the chart update to the bottom of the execution stack so our props update first
            this.$nextTick(() => {
                this.chart.update(this.chartConfig);
            });
        }
    }
};
</script>

<style lang="scss">
$annotation-background-color: #4a4c55;

.workflow-over-time-card {
    .pendo-card__body {
        padding: 0;
        position: relative;
    }

    &--loading {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    &--chart-label {
        font-size: 18px;
        display: inline;
    }

    &--dropdown {
        display: inline;
    }

    &--empty-state {
        align-items: center;
        display: flex;
        height: 305px;
        width: 100%;

        &-text {
            margin: 0;
            text-align: center;
            width: 100%;
        }
    }

    &--none-dropdown-option {
        color: $gray-lighter-3;
        line-height: 26px;
    }

    &--guide-details {
        align-items: center;
        background: $gray-lighter-8;
        color: $gray-primary;
        display: flex;
        flex-direction: row-reverse;
        padding: 12px 16px;

        i {
            margin-right: 4px;
        }
    }

    &--guide-identifier {
        background: $annotation-background-color;
        border-radius: 4px;
        color: $white;
        font-size: 12px;
        font-weight: 600;
        height: 22px;
        line-height: 22px;
        margin-right: 8px;
        text-align: center;
        width: 22px;
    }

    &--guide-detail-divider {
        margin: 0 8px;
    }

    &--guide-line-border {
        transform: translateX(-0.5px);
    }

    &--container {
        padding: 16px;
    }

    &--tooltip {
        min-width: 200px;
        font-size: 14px;
        color: $gray-primary;

        .chart-tooltip {
            &-header {
                border-bottom: 1px solid $gray-lighter-5;
                display: flex;
                flex-direction: column;
                padding: 16px 0;

                &--title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 20px;

                    & .header-title-text {
                        font-weight: 600;
                        margin-left: 5px;
                        overflow: hidden;
                        max-width: 20ch;
                        text-overflow: ellipsis;
                    }
                }

                &--description {
                    display: block;
                    font-size: 12px;
                    overflow: hidden;
                    max-width: 30ch;
                    padding-left: 35px;
                    text-align: left;
                    text-overflow: ellipsis;
                }
            }

            &-body {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                font-weight: bold;
                padding: 16px 35px;

                .small {
                    font-size: 14px;
                    font-weight: normal;
                }

                &--metric {
                    font-size: 22px;
                    padding-bottom: 14px;
                }
            }
        }
    }
}
</style>
