<template>
    <div :style="cssVars">
        <hr
            v-if="type === 'divider'"
            class="multiselect-blade-option__group-divider">
        <div
            v-if="type === 'group'"
            class="multiselect-blade-option__group-row"
            :class="{ 'is-selected': value || indeterminate }">
            <span class="multiselect-blade-option__group-collapse-icon">
                <pendo-icon
                    :type="expandIcon"
                    size="16"
                    @click="onExpandedToggle" />
            </span>
            <span
                class="multiselect-blade-option__group-label multiselect-blade-option__row"
                :class="{ 'is-disabled': disabled }"
                @click="handleToggle"
                @mouseenter="onOptionMouseenter"
                @mouseleave="onOptionMouseleave">
                <pendo-checkbox
                    v-bind="{ value, disabled, indeterminate }"
                    @change="handleToggle" />
                <div v-if="item">
                    <pendo-app-display :apps="item" />
                </div>
                <div v-else>
                    <span
                        v-pendo-tooltip="{
                            content: label,
                            multiline: true,
                            trigger: 'manual',
                            offset: 0,
                            show: showOverflowTooltip
                        }"
                        class="multiselect-blade-option__group-name">
                        {{ label }}
                    </span>
                </div>
            </span>
        </div>
        <div
            v-if="type === 'option'"
            class="multiselect-blade-option__row multiselect-blade-option__option-row"
            data-cy="blade-option"
            :class="{
                'is-selected': value,
                'is-disabled': disabled
            }"
            @click="handleToggle"
            @mouseenter="onOptionMouseenter"
            @mouseleave="onOptionMouseleave">
            <pendo-checkbox
                v-bind="{ value, disabled }"
                @change="handleToggle" />
            <div
                v-pendo-tooltip="{
                    content: `${label}\n\n${sublabel}`,
                    multiline: true,
                    trigger: 'manual',
                    offset: 0,
                    show: showOverflowTooltip
                }"
                class="multiselect-blade-option-label">
                <pendo-icon
                    :type="optionIcon"
                    :size="14" />
                <div class="multiselect-blade-option-label-content">
                    <div class="multiselect-blade-option-label-text">
                        {{ label || '(No name)' }}
                    </div>
                    <div class="multiselect-blade-option-label-subtext">
                        {{ sublabel }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PendoCheckbox, PendoTooltip, PendoIcon, PendoAppDisplay } from '@pendo/components';
import {
    elementHasOverflow,
    OPTION_HEIGHT,
    GROUP_OPTION_HEIGHT,
    SPACING_HEIGHT,
    DIVIDER_LINE_HEIGHT
} from './multiselect-blade.utils';
import debounce from 'lodash/debounce';
import lowerFirst from 'lodash/lowerFirst';

export default {
    name: 'MultiselectBladeOption',
    components: {
        PendoCheckbox,
        PendoIcon,
        PendoAppDisplay
    },
    directives: {
        PendoTooltip
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        sublabel: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        indeterminate: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'option'
        },
        icon: {
            type: String,
            default: ''
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            showOverflowTooltip: false,
            tooltipTimeout: null,
            cssVars: {
                '--option-height': `${OPTION_HEIGHT}px`,
                '--group-height': `${GROUP_OPTION_HEIGHT}px`,
                '--spacing-height': `${SPACING_HEIGHT}px`,
                '--divider-line-height': `${DIVIDER_LINE_HEIGHT}px`
            }
        };
    },
    computed: {
        optionIcon () {
            return lowerFirst(this.icon);
        },
        expandIcon () {
            return this.collapsed ? 'chevron-down' : 'chevron-up';
        }
    },
    methods: {
        handleToggle: debounce(
            function debounceEmit () {
                this.$emit('change');
            },
            5,
            { leading: true, trailing: false }
        ),
        onOptionMouseenter () {
            const labelNodes = this.$el.querySelectorAll(
                this.type === 'group'
                    ? '.multiselect-blade-option__group-name'
                    : '.multiselect-blade-option-label-text, multiselect-blade-option-label-subtext'
            );
            const showOverflowTooltip = Array.from(labelNodes).some((node) => elementHasOverflow(node));

            if (showOverflowTooltip) {
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = setTimeout(() => {
                    this.showOverflowTooltip = true;
                }, 400);
            }
        },
        onOptionMouseleave () {
            clearTimeout(this.tooltipTimeout);
            this.showOverflowTooltip = false;
        },
        onExpandedToggle () {
            this.$emit('groupCollapse', !this.collapsed);
        }
    }
};
</script>

<style lang="scss" scoped>
.multiselect-blade-option {
    &-label-text {
        font-size: 14px;
        line-height: 20px;
    }

    &-label-subtext {
        font-size: 12px;
        line-height: 17px;
    }

    &__group-divider {
        margin: 0;
        margin-top: var(--spacing-height);
        border-top: var(--divider-line-height) solid $gray-lighter-5;
    }

    &__group-row {
        height: var(--group-height);
        align-items: center;
        margin-top: var(--spacing-height);
        display: flex;

        &.is-selected {
            background-color: $gray-lighter-6;
        }
    }

    &__group-label {
        padding-left: 8px;
        flex: 1 1 0;
        overflow-x: auto;
        align-items: center;
    }

    &__group-collapse-icon {
        padding-left: 8px;
        cursor: pointer;
    }

    &__group-color {
        min-width: 12px;
    }

    &__group-name {
        font-size: 14px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $gray-lighter-2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__group-swatch {
        padding-right: 8px;
    }

    &__option-row {
        padding-left: 32px;
        align-items: center;

        &:hover {
            background-color: $gray-lighter-7;
        }

        &.is-selected {
            background-color: $gray-lighter-6;
        }

        .multiselect-blade-option-label {
            display: flex;
            align-items: center;
            flex-grow: 1;
            min-width: 0;
        }

        .multiselect-blade-option-label-content {
            display: flex;
            flex-direction: column;
            min-width: 0;
            margin-left: 8px;

            .multiselect-blade-option-label-text,
            .multiselect-blade-option-label-subtext {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .multiselect-blade-option-label-subtext {
                font-size: 12px;
                color: $gray-lighter-2;
            }
        }
    }

    &__row {
        height: var(--option-height);
        padding-right: 16px;
        display: flex;
        cursor: pointer;

        .pendo-checkbox {
            margin-right: 8px;

            ::v-deep .el-checkbox {
                &__label {
                    display: none;
                }
                margin: 0;
                display: flex;
            }
        }

        &.is-disabled {
            cursor: not-allowed;
        }
    }
}
</style>
