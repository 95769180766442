import { $can } from '@pendo/permissions';
import { canAppCreateCountable } from './countable-permissions';
import get from 'lodash/get';

export const canCreateFromScratch = (appId) =>
    $can(appId)
        .entity('guide')
        .action('create')
        .source('scratch')
        .target('draft');

export const canCreateFromLayout = (appId) =>
    $can(appId)
        .entity('guide')
        .action('create')
        .source('layout')
        .target('draft');

export function canCreateGuide (apps) {
    return apps.some((app) => canCreateFromScratch(app.id));
}

export function canEditGuide ({ guide, field }, { source, target } = {}) {
    const canEdit = (appId) =>
        $can(appId)
            .entity('guide', { entity: guide, field })
            .action('edit')
            .source(source || guide.state)
            .target(target || guide.state);

    const appIds = get(guide, 'appIds', false);

    return appIds ? appIds.every((appId) => canEdit(appId)) : false;
}

export function canDeleteGuide (guide) {
    const canDelete = (appId) =>
        $can(appId)
            .entity('guide', { entity: guide })
            .action('remove')
            .source(guide.state)
            .target(guide.state);

    const appIds = get(guide, 'appIds', false);

    return appIds ? appIds.every((appId) => canDelete(appId)) : false;
}

export function canPublishGuide (guide) {
    const canPublish = (appId) =>
        $can(appId)
            .entity('guide', { entity: guide })
            .action('publish')
            .source('*')
            .target('*');

    const appIds = get(guide, 'appIds', false);

    return appIds ? appIds.every((appId) => canPublish(appId)) : false;
}

export const canAppCreateGuide = (appId) =>
    $can(appId)
        .entity('guide')
        .action('create')
        .source('ANY')
        .target('draft');

export const canAppEditGuide = (appId) =>
    $can(appId)
        .entity('guide')
        .action('edit')
        .source('ANY')
        .target('ANY');

export function canCopyGuide ({ appId }) {
    return (
        canAppCreateGuide(appId) &&
        canAppEditGuide(appId) &&
        canAppCreateCountable(appId, 'page') &&
        canAppCreateCountable(appId, 'feature')
    );
}
