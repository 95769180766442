<template>
    <div data-cy="resource-center-module-chooser">
        <pendo-drawer
            title="Resource Center"
            size="fullscreen"
            :visible="isVisible"
            class-name="resource-center-module-chooser"
            @close="closeAndCancel">
            <div
                v-if="isVisible"
                class="resource-center-module-chooser__body">
                <pendo-filter-bar
                    v-if="usesMultiApp && isCreateMode"
                    class="resource-center-module-chooser__filter-bar">
                    <pendo-form
                        :call-reset-fields="callResetFields"
                        :call-validate="callValidate"
                        :model="form"
                        @formValidated="handleValidForm"
                        @fieldsReset="handleResetFields">
                        <div class="resource-center-module-chooser__app-selector">
                            <pendo-form-item
                                class="app-selector-form-item"
                                :rules="[
                                    {
                                        required: true,
                                        message: 'No selection made',
                                        trigger: 'blur'
                                    }
                                ]"
                                prop="selectedApp">
                                <pendo-multiselect
                                    data-cy="rc-select-application-modules"
                                    :value="form.selectedApp"
                                    :allow-empty="false"
                                    :labels="{ left: 'Application' }"
                                    :options="formattedAppOptions"
                                    label-key="displayName"
                                    placeholder="- None Selected -"
                                    @select="onAppSelection">
                                    <template #option="{ option }">
                                        <pendo-app-display :apps="option" />
                                    </template>
                                    <template #selectedLabel="{ option }">
                                        <pendo-app-display :apps="option" />
                                    </template>
                                </pendo-multiselect>
                            </pendo-form-item>
                        </div>
                    </pendo-form>
                </pendo-filter-bar>
                <pendo-card
                    title="Modules"
                    class="resource-center-chooser-grid">
                    <resource-center-module
                        v-for="mod in modules"
                        :key="mod.name"
                        class="module"
                        :disable-button="mod.disabled && 'Agent Version 2.18.5 is Required'"
                        :module-type="mod.type"
                        :module-name="mod.name"
                        :module-description="mod.description"
                        :module-icon="mod.icon"
                        :selected="mod.selected"
                        :prevent-code-injection="preventCodeInjection"
                        @onToggle="toggleSelectedModule(mod)" />
                </pendo-card>
            </div>
            <template #footer>
                <div class="resource-center-module-chooser__footer">
                    <pendo-button
                        class="resource-center-module-chooser-cancel-button"
                        data-cy="rc-module-chooser-cancel-button"
                        label="Cancel"
                        theme="app"
                        type="secondary"
                        @click="closeAndCancel" />
                    <pendo-button
                        class="resource-center-add-module-button"
                        data-cy="rc-add-modules-button"
                        :disabled="disableSubmitButton"
                        :loading="isAddingModules"
                        :label="submitButtonLabel"
                        prefix-icon="plus"
                        theme="app"
                        type="primary"
                        @click="submitModal" />
                </div>
            </template>
        </pendo-drawer>
    </div>
</template>

<script>
import {
    PendoButton,
    PendoCard,
    PendoDrawer,
    PendoForm,
    PendoFormItem,
    PendoFilterBar,
    PendoMultiselect,
    PendoAppDisplay
} from '@pendo/components';
import ResourceCenterModule from '@/components/resource-center/module-chooser/ResourceCenterModule';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { meetsMinimumAgentVersion } from '@/utils/apps';
import { canCreateResourceCenter } from '@/utils/rc-permissions';

const { moduleIds } = BuildingBlockLayouts;

export default {
    name: 'ResourceCenterModuleChooser',
    components: {
        ResourceCenterModule,
        PendoButton,
        PendoCard,
        PendoDrawer,
        PendoForm,
        PendoFormItem,
        PendoFilterBar,
        PendoMultiselect,
        PendoAppDisplay
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        isAddingModules: {
            type: Boolean,
            default: false
        },
        isCreateMode: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            callResetFields: false,
            callValidate: false,
            form: {
                selectedApp: null
            },
            oldMainViewMinHeight: null,
            modules: [
                {
                    name: 'Guide List',
                    description: 'PRODUCT WALKTHROUGHS',
                    icon: 'list',
                    type: moduleIds.guideList,
                    selected: false
                },
                {
                    name: 'Onboarding',
                    description: 'ONBOARDING GUIDES',
                    icon: 'check-circle',
                    type: moduleIds.onboarding,
                    selected: false
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            activeApp: 'apps/active',
            usesMultiApp: 'subscriptions/usesMultiApp',
            listAllWithRCAccessForActiveSubscription: 'apps/listAllWithRCAccessForActiveSubscription',
            draftResourceCenterMap: 'resourceCenter/draftResourceCenterMap'
        }),
        noModulesSelected () {
            const defaultModulesSelected = this.modules.find((module) => module.selected);

            return !defaultModulesSelected;
        },
        preventCodeInjection () {
            return get(this.activeApp, 'applicationFlags.preventCodeInjection', false);
        },
        isAdoptIframeRCModuleEligible () {
            return meetsMinimumAgentVersion('2.115.0', this.activeApp.id);
        },
        formattedAppOptions () {
            if (!this.usesMultiApp) return null;

            return this.listAllWithRCAccessForActiveSubscription
                .filter((app) => canCreateResourceCenter([app]))
                .map((app) => {
                    const rcExistsForApp = !isUndefined(this.draftResourceCenterMap[app.id]);

                    return {
                        ...app,
                        disabled: rcExistsForApp
                    };
                });
        },
        selectedAppId () {
            return get(this.form.selectedApp, 'id', '');
        },
        submitButtonLabel () {
            if (this.isCreateMode) return 'Create';
            if (this.selectedModuleTypes.length < 2) return 'Add Module';

            return 'Add Modules';
        },
        selectedModuleTypes () {
            return this.modules.filter((module) => module.selected).map((module) => module.type);
        },
        disableSubmitButton () {
            if (this.isCreateMode) {
                return this.noModulesSelected || !this.selectedAppId;
            }

            return this.noModulesSelected;
        }
    },
    watch: {
        isVisible (value) {
            if (value) {
                this.modules.forEach((module) => {
                    module.selected = false;
                });
                this.oldMainViewMinHeight = document.getElementById('app').style.minHeight;
                document.getElementById('app').style.minHeight = 'auto';
            } else {
                document.getElementById('app').style.minHeight = this.oldMainViewMinHeight;
                this.form.selectedApp = null;
            }
        }
    },
    created () {
        if (this.isAdoptIframeRCModuleEligible) {
            this.modules.push({
                name: 'External Website',
                description: 'CUSTOM IFRAME',
                icon: 'globe',
                type: moduleIds.iframe,
                selected: false
            });
        }
    },
    methods: {
        closeAndCancel () {
            this.$emit('onCancel');
        },
        submitModal () {
            if (this.isCreateMode) {
                this.$emit('createWithModules', {
                    appId: this.selectedAppId,
                    modules: this.selectedModuleTypes
                });

                return;
            }

            this.$emit('addModules', {
                modules: this.selectedModuleTypes
            });
        },
        toggleSelectedModule (module) {
            module.selected = !module.selected;
        },
        onAppSelection (app) {
            this.form.selectedApp = app;
        },
        handleValidForm () {
            return;
        },
        handleResetFields () {
            return;
        }
    }
};
</script>

<style scoped lang="scss">
.resource-center-module-chooser {
    .resource-center-module-chooser__body {
        padding: 24px;
        grid-area: body;
        overflow-y: auto;
        display: grid;
        grid-gap: 24px;
        grid-auto-rows: max-content;

        > .pendo-card {
            min-width: 310px;

            &:last-child {
                margin-bottom: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }

        .resource-center-chooser-grid {
            ::v-deep .pendo-card__body {
                display: grid;
                justify-content: start;
                padding: 24px;
                grid-template-columns: repeat(auto-fit, 246px);
                grid-gap: 32px;
                margin: 0 auto;
            }
        }

        .already-exists-error {
            margin-top: -50px;
        }
    }

    .resource-center-module-chooser__footer {
        justify-self: end;
    }

    .resource-center-module-chooser__app-selector {
        .app-selector-form-item {
            margin-bottom: 0;

            ::v-deep .pendo-form-item__content {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            ::v-deep .pendo-form-item__error {
                padding-left: 8px;
                margin-top: 0px;
                height: 36px;
            }
        }
    }
}
</style>
