<template>
    <div class="visitors-dashboard">
        <global-filters>
            <template #right>
                <search
                    v-model="searchInput"
                    placeholder="Search" />
            </template>
            <template #expansion>
                <visitors-no-events-filter
                    :value="hideVisitorsNoEvents"
                    @change="toggleNoEventsFilter" />
            </template>
        </global-filters>
        <div class="visitors-dashboard--grid">
            <div class="visitors-dashboard--row">
                <analytics-visitor-list
                    :can-upload="canUpload"
                    :filter-no-events="hideVisitorsNoEvents"
                    :search-string="searchInput"
                    class="visitors-dashboard--visitor-list" />
            </div>
        </div>
    </div>
</template>

<script>
import AnalyticsVisitorList from '@/components/analytics/AnalyticsVisitorList';
import VisitorsNoEventsFilter from '@/components/analytics/visitors/VisitorsNoEventsFilter';
import GlobalFilters from '@/components/filters/GlobalFilters';
import { PendoLoading } from '@pendo/components';
import Search from '@/components/Search';
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
    name: 'VisitorsDashboard',
    components: {
        AnalyticsVisitorList,
        GlobalFilters,
        Search,
        VisitorsNoEventsFilter
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            searchInput: ''
        };
    },
    computed: {
        ...mapState({
            hideVisitorsNoEvents: (state) => state.filters.hideVisitorsNoEvents
        }),
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        canUpload () {
            return this.isAdmin && this.activeIsDigitalAdoption;
        }
    },
    methods: {
        ...mapActions({
            updateHideVisitorsNoEvents: 'filters/updateHideVisitorsNoEvents'
        }),
        toggleNoEventsFilter () {
            this.updateHideVisitorsNoEvents({ value: !this.hideVisitorsNoEvents });
        }
    }
};
</script>

<style lang="scss">
.visitors-dashboard {
    .visitors-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .visitors-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));

        &:not(:last-child) {
            min-height: 448px;
        }

        &.half-size-row {
            min-height: 224px;
        }
    }
}
</style>
