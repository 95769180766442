<template>
    <div class="workflows-empty-state">
        <pendo-card v-if="noMetadata">
            <div
                class="workflows-empty-state--container"
                data-cy="workflows-empty-state--metadata">
                <img
                    class="workflows-empty-state--image"
                    alt="No Metadata"
                    src="@/img/empty-states/visitor-metadata-empty-state.svg">

                <h3 class="workflows-empty-state--title">
                    Metadata Field Not Found
                </h3>

                <p class="workflows-empty-state--body">
                    Go to the
                    <router-link to="/analytics/visitors">
                        Visitors tab
                    </router-link>
                    and upload a CSV with your custom metadata fields and their values.
                    <br>
                </p>

                <pendo-button
                    theme="app"
                    type="secondary"
                    label="Learn more"
                    class="workflows-empty-state--button"
                    @click="onLearnMoreClicked" />
            </div>
        </pendo-card>

        <pendo-card v-else-if="noData">
            <div
                class="workflows-empty-state--container"
                data-cy="workflows-empty-state--data">
                <img
                    class="workflows-empty-state--image"
                    alt="No Data Available"
                    src="@/img/empty-states/workflows-details-empty-state.png">

                <h3 class="workflows-empty-state--title">
                    No data available
                </h3>

                <p class="workflows-empty-state--body">
                    Consider adjusting your query or returning later as usage occurs.
                </p>
            </div>
        </pendo-card>
    </div>
</template>

<script>
import { PendoCard, PendoButton } from '@pendo/components';

export default {
    name: 'WorkflowEmptyState',
    components: {
        PendoCard,
        PendoButton
    },
    props: {
        noMetadata: {
            type: Boolean,
            default: false
        },
        noData: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onLearnMoreClicked () {
            window.open(
                'https://adoptsupport.pendo.io/hc/en-us/articles/4406529611035-Metadata-Overview#AddingCustomMetadataFields',
                '_blank',
                'noopener'
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-empty-state {
    &--container {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 56px auto;
    }

    &--image {
        margin: 0 25px;
        width: 250px;
    }

    &--title {
        font-size: 22px;
        font-weight: 600;
        line-height: 120%;
        margin: 24px 0 12px;
    }

    &--body {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
    }

    &--button {
        align-self: center;
        margin-top: 10px;
        width: 50%;
    }
}
</style>
