<template>
    <div>
        <pendo-drawer
            :visible="visible"
            size="fullscreen"
            title="Edit translation"
            class-name="translation-drawer"
            @close="onClose"
            @open="onOpen">
            <template #body>
                <div class="translation-drawer__body">
                    <div class="preview-section">
                        <pendo-loading-indicator
                            :loading="isLoadingPreview"
                            size="large" />
                        <guide-step-preview
                            v-if="!isLoadingPreview && hasPreviewContent"
                            :key="stepPreviewId"
                            class="translation-preview"
                            :dom="step.dom"
                            :guides="previewGuides"
                            :step="step"
                            :resource-centers="[]"
                            :preview-language="targetLanguage.code"
                            :has-guide-localization="true" />
                    </div>
                    <div class="translation-section">
                        <pendo-card
                            header-min-height="48px"
                            body-min-height="0"
                            shadow="always">
                            <template #header>
                                <div class="translation-section__header">
                                    <span class="translation-section__title">{{ guide.name }}</span>
                                    <div class="translation-section__header-actions">
                                        <span>Translation for</span>
                                        <pendo-multiselect
                                            class="translation-section__step-selector"
                                            :allow-empty="false"
                                            :options="stepOptions"
                                            :value="activeStep"
                                            @input="setSelectedStep">
                                            <template #trigger>
                                                <pendo-data-measure-trigger />
                                            </template>
                                        </pendo-multiselect>
                                        <span>{{ stepsLengthLabelSuffix }}</span>
                                        <translation-warning-icon
                                            v-if="translationWarningsTooltip"
                                            v-bind="translationWarningsTooltip" />
                                    </div>
                                </div>
                            </template>
                            <div class="translation-section__alerts">
                                <pendo-alert
                                    v-if="drawerAlert"
                                    v-bind="drawerAlert" />
                            </div>
                            <div class="translation-section__body">
                                <div class="source-language">
                                    <div
                                        class="language-header"
                                        data-cy="source-language-header">
                                        <div class="language-header-content">
                                            <div class="language-header-title">
                                                Authored Language
                                            </div>
                                            <div class="language-header-subtitle">
                                                {{ authoredLanguage.name }} ({{ authoredLanguage.code }})
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="loading"
                                        class="language-loading"
                                        data-cy="source-language-loader">
                                        <pendo-loading-indicator
                                            type="skeleton"
                                            :skeleton-props="{ height: 21, width: '50%' }" />
                                        <pendo-loading-indicator
                                            type="skeleton"
                                            :skeleton-props="{ height: 21 }" />
                                        <pendo-loading-indicator
                                            type="skeleton"
                                            :skeleton-props="{ height: 21 }" />
                                    </div>
                                    <div
                                        v-if="!loading"
                                        class="language-values"
                                        data-cy="source-language-values">
                                        <component
                                            :is="getTranslationInput(transUnitGroup)"
                                            v-for="[id, transUnitGroup] in translationUnitsByType"
                                            :key="id + transUnitGroup.stepId"
                                            class="translation-unit"
                                            :trans-unit-group="transUnitGroup"
                                            :trans-unit-map="transUnitValuesForActiveStep"
                                            :trans-unit-heights="transUnitHeightsForActiveStep"
                                            value-key="source"
                                            :disabled="true"
                                            :is-progress-bar-text-block="
                                                isProgressBarTextBlock(stepContentMap[stepId], id)
                                            "
                                            :is-fallback-block="isFallbackBlock(stepContentMap[stepId], id)"
                                            @changeHeight="onTranslationInputHeightChange" />
                                    </div>
                                </div>
                                <pendo-divider
                                    class="translation-divider"
                                    stroke="#dadce5"
                                    direction="vertical"
                                    width="1px"
                                    height="auto" />
                                <div class="target-language">
                                    <div
                                        class="language-header"
                                        data-cy="target-language-header">
                                        <div class="language-header-content">
                                            <div class="language-header-title">
                                                Language
                                            </div>
                                            <div class="language-header-subtitle">
                                                {{ targetLanguage.name }} ({{ targetLanguage.code }})
                                            </div>
                                        </div>
                                        <div class="language-header-actions">
                                            <pendo-button
                                                type="link"
                                                label="Clear translations"
                                                :disabled="isClearDisabled"
                                                @click="clearTranslations" />
                                        </div>
                                    </div>
                                    <div
                                        v-if="loading"
                                        class="language-loading"
                                        data-cy="target-language-loader">
                                        <pendo-loading-indicator
                                            type="skeleton"
                                            :skeleton-props="{ height: 21, width: '50%' }" />
                                        <pendo-loading-indicator
                                            type="skeleton"
                                            :skeleton-props="{ height: 21 }" />
                                        <pendo-loading-indicator
                                            type="skeleton"
                                            :skeleton-props="{ height: 21 }" />
                                    </div>
                                    <div
                                        v-if="!loading"
                                        class="language-values"
                                        data-cy="target-language-values">
                                        <component
                                            :is="getTranslationInput(transUnitGroup)"
                                            v-for="[id, transUnitGroup] in translationUnitsByType"
                                            :key="id + transUnitGroup.stepId"
                                            class="translation-unit"
                                            :trans-unit-group="transUnitGroup"
                                            :trans-unit-map="transUnitValuesForActiveStep"
                                            :trans-unit-heights="transUnitHeightsForActiveStep"
                                            :initial-trans-unit-values="translationUnitsForActiveStep"
                                            :warnings="translationWarningsForActiveStep"
                                            :disabled="saving"
                                            value-key="target"
                                            :is-progress-bar-text-block="
                                                isProgressBarTextBlock(stepContentMap[stepId], id)
                                            "
                                            :is-fallback-block="isFallbackBlock(stepContentMap[stepId], id)"
                                            @change="onTranslationInput"
                                            @changeHeight="onTranslationInputHeightChange" />
                                    </div>
                                </div>
                            </div>
                        </pendo-card>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="translation-drawer__footer">
                    <pendo-button
                        label="Cancel"
                        theme="app"
                        type="secondary"
                        @click="$emit('close')" />
                    <pendo-button
                        v-pendo-tooltip="submitDisabledReason"
                        data-cy="action-save-translations"
                        label="Save translation"
                        theme="app"
                        type="primary"
                        :loading="saving"
                        :disabled="isSubmitDisabled"
                        @click="onSave" />
                </div>
            </template>
        </pendo-drawer>
    </div>
</template>

<script>
import {
    PendoAlert,
    PendoTooltip,
    PendoDrawer,
    PendoButton,
    PendoCard,
    PendoMultiselect,
    PendoLoadingIndicator,
    PendoDivider,
    PendoDataMeasureTrigger
} from '@pendo/components';

import GuideStepPreview from '@/components/guides/settings/GuideStepPreview';

import {
    BuildingBlock,
    BuildingBlockPropertiesService,
    BuildingBlockLayouts,
    BuildingBlockRichTextConverter
} from '@pendo/services/BuildingBlocks';
import { TaskList } from '@pendo/services/TaskList';
import { isTranslated } from '@/utils/localization';
import ElementLocalizer from './translation-elements/ElementLocalizer';
import TextLocalizer from './translation-elements/TextLocalizer';
import PollLocalizer from './translation-elements/PollLocalizer';
import TemplateChildLocalizer from './translation-elements/TemplateChildLocalizer';
import TranslationWarningIcon, {
    TRANSLATION_MISSING_WARNING,
    TRANSLATION_SYNC_WARNING
    } from './translation-elements/TranslationWarningIcon';
import { applyXliffTags, applyTemplateTags } from './../../utils/localization';
import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';
import head from 'lodash/head';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';

const TRANS_UNIT_TYPES = {
    POLL: 'poll',
    TEMPLATE_CHILDREN: 'templateChildren'
};

const { TEMPLATE_NAMES, isProgressBarTextBlock, isFallbackBlock } = TaskList;

const initialData = () => ({
    loadingPreview: false,
    hasChanged: false,
    guideTranslationsByStep: {},
    transUnitValuesByStep: {},
    transUnitHeightsByStep: {},
    guideStepsPreviewContent: [],
    previewVersion: 1,
    activeStep: null,
    isProgressBarTextBlock,
    isFallbackBlock
});

export default {
    name: 'EditTranslationDrawer',
    components: {
        GuideStepPreview,
        TranslationWarningIcon,
        ElementLocalizer,
        TextLocalizer,
        PollLocalizer,
        TemplateChildLocalizer,
        PendoAlert,
        PendoDrawer,
        PendoButton,
        PendoCard,
        PendoMultiselect,
        PendoLoadingIndicator,
        PendoDivider,
        PendoDataMeasureTrigger
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        guide: {
            type: Object,
            default: () => ({})
        },
        guideTranslations: {
            type: Array,
            required: true
        },
        guideTranslationAlert: {
            type: Object,
            default: null
        },
        guideTranslationAction: {
            type: String,
            default: 'edit',
            validator (value) {
                return ['translate', 'translate-with-ai', 'edit'].includes(value);
            }
        },
        platform: {
            type: String,
            default: 'web'
        },
        translatedContent: {
            type: Array,
            required: true
        },
        targetLanguage: {
            type: Object,
            default: () => ({})
        },
        authoredLanguage: {
            type: Object,
            default: () => ({})
        },
        validChildGuidesList: {
            type: Array,
            default: () => []
        }
    },
    emits: ['save', 'close'],
    data () {
        return initialData();
    },
    computed: {
        drawerAlert () {
            if (!isEmpty(this.guideTranslationAlert)) {
                return this.guideTranslationAlert;
            }

            if (this.guideTranslationAction === 'translate-with-ai') {
                return {
                    type: 'warning',
                    inline: true,
                    title: 'Disclaimer',
                    description:
                        'Translations are generated by Pendo AI and are not guaranteed to be accurate. Please carefully review and validate before publishing guides.'
                };
            }

            return null;
        },
        isLoadingPreview () {
            return this.loading || this.loadingPreview;
        },
        isTranslated () {
            return isTranslated(this.targetLanguage?.translatedState);
        },
        isSubmitDisabled () {
            if (this.isTranslated) {
                return !(this.hasChanged && this.hasValues);
            }

            return !this.hasValues;
        },
        isClearDisabled () {
            return !this.translationUnitsForActiveStep.some(({ transUnitId }) => {
                return !!this.transUnitValuesForActiveStep[transUnitId]?.target;
            });
        },
        hasValues () {
            return this.translationUnitsForActiveStep.some(({ transUnitId }) => {
                return !!this.transUnitValuesForActiveStep[transUnitId]?.target;
            });
        },
        hasPreviewContent () {
            return !!this.step?.dom;
        },
        submitDisabledReason () {
            if (this.isSubmitDisabled) {
                if (!this.hasValues) {
                    return {
                        content: 'Please provide at least one translation for this guide'
                    };
                }

                if (!this.hasChanged) {
                    return {
                        content: 'Please make a change to update translations for this guide'
                    };
                }
            }

            return {};
        },
        stepId () {
            return this.activeStep?.id;
        },
        step () {
            return this.guideStepsPreviewContent.find(({ id }) => id === this.stepId);
        },
        stepPreviewId () {
            return `${this.step.id}_v${this.previewVersion}`;
        },
        stepOptions () {
            if (!get(this.guide, 'steps.length')) {
                return [];
            }

            return this.guide.steps.map((step, idx) => ({
                id: step.id,
                label: `Step ${idx + 1}`
            }));
        },
        stepsLengthLabelSuffix () {
            return `of ${get(this.guide, 'steps.length', 0)}`;
        },
        stepContentMap () {
            return this.buildContentMap(this.guide.steps);
        },
        stepPreviewContentMap () {
            return this.buildContentMap(this.guideStepsPreviewContent);
        },
        translationWarningsTooltip () {
            const { steps } = this.guide;
            const stepsWithWarnings = Object.keys(this.translationWarnings || {});

            const stepListItems = stepsWithWarnings.reduce((listItems, stepId) => {
                const idx = steps.findIndex(({ id }) => id === stepId);

                if (idx >= 0) {
                    listItems.push(`<li>Step ${idx + 1}</li>`);
                }

                return listItems;
            }, []);

            if (stepListItems.length) {
                const title = 'Changes have been made to this<br> guide on the following';
                const pluralizedStep = stepListItems.length === 1 ? 'step:' : 'steps:';

                return {
                    title: `${title} ${pluralizedStep}`,
                    content: `<ul>${stepListItems.join('')}</ul>`,
                    options: {
                        placement: 'left',
                        multiline: false
                    }
                };
            }

            return null;
        },
        translationWarningsForActiveStep () {
            return this.translationWarnings[this.stepId];
        },
        translationUnitsForActiveStep () {
            return this.guideTranslations.filter(({ stepId }) => stepId === this.stepId);
        },
        transUnitValuesForActiveStep () {
            if (!this.stepId) return [];

            return this.transUnitValuesByStep[this.stepId];
        },
        transUnitHeightsForActiveStep () {
            if (!this.stepId) return {};

            return this.transUnitHeightsByStep[this.stepId] || {};
        },
        translationUnitsByType () {
            const guidePollMap = keyBy(this.guide?.polls, 'id');
            const transUnitMap = new Map();
            const transUnitGroups = new Map();

            // Group all trans-units by ID. Some building blocks have multiple translatable strings
            // associated with them (e.g. buttons with aria labels) which are flattened in the xliff
            // by appending the string type to the ID.
            // Example: 920bc4e5-9dab-4540-bfd8-818ba611beee|ariaLabel
            //
            // This will group on everything left of the "|" so that all trans-units associated with
            // the building block end up in the same grouping.
            this.translationUnitsForActiveStep.forEach(({ stepId, transUnitId }) => {
                const [translationId] = transUnitId.split('|');
                const isTaskListItem = this.childGuideExists(translationId);
                const stepBlocks = this.stepContentMap[stepId];
                const transUnitBuildingBlock = this.findBuildingBlockByTranslationId(
                    stepBlocks,
                    translationId,
                    isTaskListItem
                );

                if (!transUnitBuildingBlock) return;

                const pollId = transUnitBuildingBlock?.web?.pollId;
                const groupById = translationId;
                const transUnit = {
                    id: transUnitId,
                    buildingBlock: transUnitBuildingBlock
                };

                if (transUnitMap.has(groupById)) {
                    transUnitMap.get(groupById).blocks.push(transUnit);
                } else {
                    const isTaskListTypeBlock =
                        get(transUnitBuildingBlock, 'templateName', '') === TEMPLATE_NAMES.TASK_LIST;
                    transUnitMap.set(groupById, {
                        id: groupById,
                        stepId,
                        pollId,
                        type: isTaskListTypeBlock ? TRANS_UNIT_TYPES.TEMPLATE_CHILDREN : transUnitBuildingBlock.widget,
                        blocks: [transUnit]
                    });
                }
            });

            // Group all trans-unit groupings by their poll ID if they belong to one.
            transUnitMap.forEach((value, key) => {
                const { pollId } = value;

                if (pollId) {
                    if (transUnitGroups.has(pollId)) {
                        transUnitGroups.get(pollId).blocks.push(value);
                    } else {
                        transUnitGroups.set(pollId, {
                            id: pollId,
                            type: TRANS_UNIT_TYPES.POLL,
                            pollType: get(guidePollMap, `${pollId}.attributes.type`),
                            blocks: [value]
                        });
                    }
                } else {
                    transUnitGroups.set(key, value);
                }
            });

            return Array.from(transUnitGroups.entries());
        },
        translationWarnings () {
            if (!this.isTranslated) return {};

            return this.guideTranslations.reduce((warnings, transUnit) => {
                const { sourceModified, target, transUnitId, stepId } = transUnit;

                if (sourceModified) {
                    set(warnings, `${stepId}.${transUnitId}`, TRANSLATION_SYNC_WARNING);
                } else if (!target) {
                    set(warnings, `${stepId}.${transUnitId}`, TRANSLATION_MISSING_WARNING);
                }

                return warnings;
            }, {});
        },
        previewGuides () {
            const childGuideIds = TaskList.getTaskListGuideIdsFromBuildingBlocks(
                this.stepPreviewContentMap[this.stepId]
            );
            const childGuides = childGuideIds.reduce((acc, id) => {
                const guide = this.childGuidesMap[id];
                if (guide) acc.push(guide);

                return acc;
            }, []);

            return [this.guide, ...childGuides];
        },
        childGuidesMap () {
            return keyBy(this.validChildGuidesList, 'id');
        }
    },
    watch: {
        guideTranslations: {
            handler (translations) {
                this.transUnitValuesByStep = translations.reduce((translationsIdsByStep, translation) => {
                    const { source, target, transUnitId, stepId } = translation;
                    if (translationsIdsByStep[stepId]) {
                        translationsIdsByStep[stepId][transUnitId] = { source, target };

                        return translationsIdsByStep;
                    }
                    const transUnitValue = { [transUnitId]: { source, target } };
                    translationsIdsByStep[stepId] = transUnitValue;

                    return translationsIdsByStep;
                }, {});
            },
            immediate: true
        },
        translatedContent: {
            handler (translatedContent) {
                this.guideStepsPreviewContent = cloneDeep(translatedContent);
                this.translationUnitsForActiveStep.forEach(({ stepId, transUnitId, target }) => {
                    if (target) {
                        this.updatePreview(stepId, transUnitId, target, false);
                    }
                });
            },
            immediate: true
        }
    },
    created () {
        this.debouncedUpdatePreviewDom = debounce(this.updatePreviewDom, 500);
    },
    methods: {
        onSave () {
            const translations = this.guideTranslations.map((transUnit) => {
                const { transUnitId, stepId } = transUnit;
                const target = get(this.transUnitValuesByStep, `${stepId}.${transUnitId}.target`, '');
                const targetValue = applyXliffTags(target);

                return {
                    ...transUnit,
                    target: targetValue
                };
            });

            this.$emit('save', {
                action: this.guideTranslationAction,
                guideId: this.guide.id,
                language: this.targetLanguage,
                translations
            });
        },
        onOpen () {
            this.activeStep = head(this.stepOptions);
        },
        onClose () {
            this.resetState();
            this.$emit('close');
        },
        onTranslationInput (event) {
            const { transUnitId, value } = event;
            if (
                has(this.transUnitValuesForActiveStep, transUnitId) &&
                this.transUnitValuesForActiveStep[transUnitId].target !== value
            ) {
                this.hasChanged = true;
                this.transUnitValuesForActiveStep[transUnitId].target = value;
                this.updatePreview(this.stepId, transUnitId, value);
            }
        },
        onTranslationInputHeightChange (event) {
            const { transUnitId, height, type } = event;
            let { minHeight } = this.transUnitHeightsForActiveStep[transUnitId] || {};

            // Set the minimum height to the largest of the two inputs for a given translation unit
            if (type === 'mount') {
                minHeight = Math.max(minHeight || 0, height);
            }

            const heightByTransUnitId = {
                ...this.transUnitHeightsForActiveStep,
                [transUnitId]: { minHeight, height }
            };

            this.$set(this.transUnitHeightsByStep, this.stepId, heightByTransUnitId);
        },
        buildContentMap (steps) {
            return steps.reduce((stepMap, step) => {
                const { id, buildingBlocks } = step;

                try {
                    stepMap[id] = JSON.parse(buildingBlocks);
                } catch (err) {
                    stepMap[id] = {};
                }

                return stepMap;
            }, {});
        },
        resetState () {
            Object.entries(initialData()).forEach(([key, value]) => {
                this[key] = value;
            });
        },
        findBuildingBlockByTranslationId (stepBlocks, translationId, isTaskLstItem) {
            if (isTaskLstItem) {
                return TaskList.getTaskListBlock(stepBlocks);
            }

            return BuildingBlock.findBlock(stepBlocks, (block) => {
                // All building blocks use `translationId` now but we fallback to the previously
                // used `domId` for backward compatability (see APP-52026).
                return block?.web?.translationId === translationId || block?.web?.domId === translationId;
            });
        },
        getPropertyByBlock ({ widget }) {
            switch (widget) {
                case BuildingBlockLayouts.widgetIds.textFieldBlock:
                    return 'hint';
                case BuildingBlockLayouts.widgetIds.imageBlock:
                    return 'src';
                case BuildingBlockLayouts.widgetIds.videoBlock:
                    return 'video_url';
                default:
                    return 'text';
            }
        },
        updateBlockValue (buildingBlock, value, fullTranslationId, stepId) {
            const { widget } = buildingBlock;

            if (
                BuildingBlockLayouts.widgetIds.textViewBlock === widget &&
                this.isNotTemplatedString(fullTranslationId, stepId)
            ) {
                buildingBlock.views = [
                    BuildingBlockRichTextConverter.parseMarkdownToBuildingBlocks(value, buildingBlock)
                ];
            } else if (
                BuildingBlockLayouts.widgetIds.radioButtonBlock === widget &&
                isEmpty(buildingBlock.views) &&
                buildingBlock.web.description
            ) {
                // The label values for number scale polls are a special case where the translation
                // is stored in this description property.
                for (const property of buildingBlock.web.description) {
                    if (property.name === 'text') {
                        property.value = value;

                        break;
                    }
                }
            } else if (BuildingBlockLayouts.widgetIds.taskListBlock === widget) {
                const [guideId, label] = fullTranslationId.split('|');
                const child = buildingBlock.templateChildren.find(({ id }) => id === guideId);
                child[label] = value;
            } else {
                BuildingBlockPropertiesService.mutatePropInBlock({
                    block: buildingBlock,
                    prop: this.getPropertyByBlock(buildingBlock),
                    value,
                    platform: this.platform
                });
            }
        },
        updatePreviewDom (stepId, translationId, value, label) {
            const stepIdx = this.guideStepsPreviewContent.findIndex(({ id }) => id === stepId);
            const step = this.guideStepsPreviewContent[stepIdx];
            const stepBlocks = this.stepPreviewContentMap[stepId];
            const isTaskListItem = this.childGuideExists(translationId);
            const buildingBlock = this.findBuildingBlockByTranslationId(stepBlocks, translationId, isTaskListItem);

            if (!buildingBlock) return;

            value = applyTemplateTags(value);

            const blockTranslationId = isTaskListItem ? `${translationId}|${label}` : translationId;
            this.updateBlockValue(buildingBlock, value, blockTranslationId, stepId);

            this.$set(this.guideStepsPreviewContent, stepIdx, {
                ...step,
                buildingBlocks: JSON.stringify(stepBlocks),
                dom: JSON.stringify(BuildingBlock.buildingBlocksToDom(stepBlocks))
            });
            this.previewVersion++;
            this.loadingPreview = false;
        },
        updatePreview (stepId, transUnitId, value, debounce = true) {
            const [translationId, label = ''] = transUnitId.split('|');

            if (!this.hasPreviewContent) return;

            // Skip preview updates for changes to aria labels, image alt text, and video titles
            // since they are not visible in the preview.
            if (label.startsWith('ariaLabel')) return;
            if (['videoTitle', 'alt', 'url'].includes(label)) return;

            if (debounce) {
                this.loadingPreview = true;
                this.debouncedUpdatePreviewDom(stepId, translationId, value, label);
            } else {
                this.updatePreviewDom(stepId, translationId, value, label);
            }
        },
        clearTranslations () {
            this.translationUnitsForActiveStep.forEach(({ transUnitId }) => {
                if (this.transUnitValuesForActiveStep[transUnitId]) {
                    this.transUnitValuesForActiveStep[transUnitId].target = '';
                    this.hasChanged = true;
                }
            });
        },
        getTranslationInput ({ type: transUnitType } = {}) {
            switch (transUnitType) {
                case TRANS_UNIT_TYPES.POLL:
                    return 'PollLocalizer';
                case TRANS_UNIT_TYPES.TEMPLATE_CHILDREN:
                    return 'TemplateChildLocalizer';
                case BuildingBlockLayouts.widgetIds.textViewBlock:
                    return 'TextLocalizer';
                case BuildingBlockLayouts.widgetIds.closeButtonBlock:
                case BuildingBlockLayouts.widgetIds.buttonBlock:
                case BuildingBlockLayouts.widgetIds.imageBlock:
                case BuildingBlockLayouts.widgetIds.videoBlock:
                    return 'ElementLocalizer';
                default:
                    return 'TextLocalizer';
            }
        },
        setSelectedStep (step) {
            this.activeStep = step;
        },
        childGuideExists (guideId) {
            return !!this.childGuidesMap[guideId];
        },
        isNotTemplatedString (translationId, stepId) {
            const stepBlocks = this.stepContentMap[stepId];
            const isProgressBar = isProgressBarTextBlock(stepBlocks, translationId);
            const isFallback = isFallbackBlock(stepBlocks, translationId);

            return !isProgressBar && !isFallback;
        }
    }
};
</script>

<style scoped lang="scss">
.translation-drawer {
    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__body {
        height: 100%;

        .preview-section {
            height: 33%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .translation-preview {
            flex: 1;
            height: 250px;
            display: flex;
            justify-content: center;
            margin-bottom: 0;
        }

        .translation-section {
            height: 67%;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                flex-grow: 1;
                padding: 0 32px;
            }

            &__title {
                font-weight: 600;
                font-size: 18px;
            }

            &__header-actions {
                font-weight: 600;
                display: flex;
                align-items: center;
                gap: 6px;
            }

            &__alerts {
                padding: 16px 48px;
            }

            &__body {
                display: flex;
                justify-content: flex-start;

                .source-language {
                    padding: 24px 48px 24px 48px;
                    flex: 1 1 50%;
                }

                .target-language {
                    padding: 24px 48px 24px 48px;
                    flex: 1 1 50%;
                }

                .translation-divider {
                    flex: 1 1 auto;
                }

                .language-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &-title {
                        font-weight: 600;
                    }

                    &-subtitle {
                        margin-top: 10px;
                    }
                }

                .language-loading {
                    display: grid;
                    gap: 16px;
                    margin-top: 24px;
                }

                .language-values {
                    margin-top: 24px;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
            }
        }

        ::v-deep .pendo-card {
            height: 100%;

            &__body {
                height: calc(100% - 48px); // pendo card header height is 48px
                padding: 0px;
                overflow-y: auto;
            }
        }
    }
}
</style>
<style>
.pendo-drawer__body {
    padding: 0px !important; /* stylelint-disable-line */
}
</style>
