import * as Agg2 from '@pendo/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    identifiedState,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/utils/aggregations';
import { getTimeSeries } from '@/utils/time-series';
import isArray from 'lodash/isArray';

export async function getVisitorsOverTime ({ appId, segmentId, dateRange, signal }) {
    const timeSeries = getTimeSeries(dateRange);
    const spec = buildVisitorsOverTimeSpec({ appId, timeSeries, segmentId });
    const { messages } = await request(spec, { rowsOnly: false, signal });

    return messages
        .map(({ time, rows }) => ({
            timestamp: time,
            visitorCount: rows[0].count
        }))
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
}

export function buildVisitorsOverTimeSpec ({ appId, timeSeries, segmentId }) {
    const { operators: o } = Agg2;

    const appIdParam = isArray(appId) ? appId : [appId];

    return o.aggregation(
        {
            name: 'visitors-over-time-adopt',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.APPLICATION_USAGE
        },
        o.pipeline(
            o.sources.events({ timeSeries, appId: appIdParam }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.reduce({
                count: o.count('visitorId')
            })
        )
    );
}
