<template>
    <main class="license-usage-chart">
        <pendo-card
            v-pendo-loading:feather="loading"
            body-min-height="464px">
            <template #title>
                <span>License Utilization by </span>
                <pendo-multiselect
                    :value="selectedPeriod"
                    class="multiselect"
                    :allow-empty="false"
                    :options="periodOptions"
                    @select="changeSelectedPeriod($event)">
                    <template #trigger>
                        <pendo-data-source-trigger />
                    </template>
                </pendo-multiselect>
            </template>
            <template #headerRight>
                <span
                    v-if="showNoLicenseDataMessage"
                    class="visibility-note">
                    Only applications with license data will appear below.
                </span>
            </template>
            <template #body>
                <pendo-empty-state
                    v-if="chartEmpty && !loading"
                    :icon="{ 'type': 'bar-chart', 'size': 32, 'stroke-width': 1.5 }"
                    :title="emptyChartTitle"
                    :description="emptyChartDescription"
                    class="license-usage-chart__empty" />
                <div
                    v-else
                    ref="license-usage"
                    class="license-usage-chart--chart" />
            </template>
        </pendo-card>
    </main>
</template>

<script>
import { LINE_CHART_BASE, chartColors, reusableTooltipFormatter } from '@/utils/highcharts';
import { PendoCard, PendoDataSourceTrigger, PendoLoading, PendoMultiselect, PendoEmptyState } from '@pendo/components';
import isEqual from 'lodash/isEqual';

export default {
    name: 'LicenseUsageChart',
    components: {
        PendoCard,
        PendoDataSourceTrigger,
        PendoMultiselect,
        PendoEmptyState
    },
    directives: {
        PendoLoading
    },
    props: {
        licenseUsageChartAggData: {
            type: Array,
            required: true
        },
        dateRange: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        appsForAppIdFilter: {
            type: Array,
            required: true
        },
        appMapForActiveSubscription: {
            type: Object,
            required: true
        },
        selectedPeriod: {
            type: Object,
            default: () => ({ id: 'daily', label: 'Day' })
        },
        periodOptions: {
            type: Array,
            required: true
        },
        isOneUI: {
            type: Boolean,
            default: false
        },
        engageChartColors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            chart: null,
            emptyChartTitle: 'Data not Found',
            emptyChartDescription: 'Try changing filters or selecting different applications',
            showDataMessage: true
        };
    },
    computed: {
        showNoLicenseDataMessage () {
            return this.chartData.length !== this.appsForAppIdFilter.length && this.appsForAppIdFilter.length !== 1;
        },
        appIds () {
            return Object.keys(this.appMapForActiveSubscription);
        },
        chartData () {
            const appsList = {};

            for (const row of this.licenseUsageChartAggData) {
                for (const app of row) {
                    if (app.licenses > 0) {
                        appsList[app.appId] = appsList[app.appId] || {
                            id: app.appId,
                            name: app.name,
                            licenses: app.licenses,
                            data: [],
                            showInLegend: true,
                            color: this.platformChartColors[
                                this.appIds.indexOf(app.appId.toString()) % this.platformChartColors.length
                            ]
                        };

                        appsList[app.appId].data.push({
                            visitors: app.numVisitors,
                            licenses: app.licenses,
                            y: app.licenseUsage,
                            x: app.timestamp
                        });
                    }
                }
            }

            return Object.values(appsList);
        },
        chartConfig () {
            const { dateFormatter } = this;
            const config = { ...LINE_CHART_BASE };
            config.series = this.chartData;
            config.tooltip = {
                useHTML: true,
                borderColor: '#DADCE5',
                padding: 0,
                shadow: {
                    color: 'rgba(0, 0, 0, 0.17)',
                    width: 8
                },
                formatter () {
                    const { y, x, series, color } = this.point;
                    const date = dateFormatter(x);
                    const options = {
                        classes: ['license-usage-chart--tooltip'],
                        color,
                        headerTitle: series.name,
                        headerDescription: date
                    };

                    options.bodyMetric = `${Math.round(y * 10) / 10}%`;
                    options.bodyDescription = `${this.point.options.visitors} visitors out of ${this.point.options.licenses} <br/> active licenses`;

                    return reusableTooltipFormatter(options);
                }
            };
            config.chart.height = 500;
            config.chart.zoomType = 'none';
            config.plotOptions = {
                line: {
                    softThreshold: false
                },
                series: {
                    marker: {
                        enabledThreshold: 100000,
                        symbol: 'circle'
                    }
                }
            };
            config.yAxis[0].labels = {
                format: '{text}%',
                align: 'center',
                x: -10,
                y: 5
            };
            config.yAxis[0].title = {
                text: 'License Utilization (%)',
                margin: 20
            };
            config.xAxis[0].startOnTick = !this.isOneUI;
            config.xAxis[0].maxPadding = 0.025;
            config.xAxis[0].labels = {
                align: 'center'
            };
            if (this.dateRange.count < 15 && this.selectedPeriod.id !== 'hourly') {
                config.xAxis[0].labels = {
                    align: 'left',
                    reserveSpace: true
                };
                if (this.dateRange.count === 7) {
                    config.xAxis[0].labels.x = 28;
                }
            }

            return config;
        },
        noData () {
            if (this.loading) return false;
            if (!this.timeToCompleteAggData) return true;

            return this.timeToCompleteAggData.length === 0;
        },
        chartEmpty () {
            if (this.loading) return false;
            if (!this.licenseUsageChartAggData) return true;

            return !this.chartData.length;
        },
        platformChartColors () {
            if (this.engageChartColors.length) {
                return this.engageChartColors;
            }

            return chartColors;
        }
    },
    watch: {
        chartData (newData, oldData) {
            if (!isEqual(newData, oldData)) {
                this.refreshChart();
            }
        },
        licenseUsageChartAggData (newData, oldData) {
            if (!isEqual(newData, oldData)) {
                this.refreshChart();
            }
        }
    },
    mounted () {
        if (this.licenseUsageChartAggData) {
            this.refreshChart();
        }
    },
    methods: {
        refreshChart () {
            if (this.loading || this.chartEmpty) return;

            if (this.$refs['license-usage']) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['license-usage'], this.chartConfig);
            }
        },
        changeSelectedPeriod (selectedPeriod) {
            this.$emit('periodChange', selectedPeriod);
        },

        dateFormatter (time) {
            let end;
            let start = new Date(time);
            switch (this.selectedPeriod.id) {
                case 'daily':
                    end = new Date(this.$pendo.moment(start).add(1, 'days'));
                    start = this.$pendo.moment(start).format('MMM D');
                    end = this.$pendo.moment(end).format('MMM D');
                    break;
                case 'weekly':
                    end = new Date(this.$pendo.moment(start).add(7, 'days'));
                    start = this.$pendo.moment(start).format('MMM D');
                    end = this.$pendo.moment(end).format('MMM D');
                    break;
                case 'monthly':
                    end = new Date(this.$pendo.moment(start).add(1, 'month'));
                    start = this.$pendo.moment(start).format('MMM');
                    end = this.$pendo.moment(end).format('MMM');
                    break;
                default:
                    end = new Date(this.$pendo.moment(start).add(1, 'hour'));
                    start = this.$pendo.moment(start).format('h:mm a');
                    end = this.$pendo.moment(end).format('h:mm a');
            }

            return `${start} - ${end}`;
        }
    }
};
</script>

<style lang="scss">
.license-usage-chart {
    .pendo-card__body {
        padding: 10px;
        overflow: auto;
    }

    .pendo-loading-overlay {
        z-index: 2;
    }

    .pendo-multiselect__value {
        overflow: unset;
    }

    .multiselect {
        padding-top: 1px;
    }

    .visibility-note {
        font-size: 14px;
        padding-left: 10px;
        color: $color-gray-70;
    }

    &--tooltip {
        min-width: 250px;
        font-size: 14px;
        color: $gray-primary;
        font-family: 'inter', sans-serif;

        .chart-tooltip {
            &-header {
                border-bottom: 1px solid $gray-lighter-5;
                display: flex;
                flex-direction: column;
                padding: 16px 0 18px 0;

                &--title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 20px;

                    & .header-title-text {
                        font-weight: 600;
                        margin-left: 7px;
                        overflow: hidden;
                        max-width: 20ch;
                        text-overflow: ellipsis;
                    }
                }

                &--description {
                    margin-left: 37px;
                    font-size: 12px;
                    overflow: hidden;
                    max-width: 20ch;
                    text-overflow: ellipsis;
                }
            }

            &-body {
                margin-left: 37px;
                padding: 16px 0;

                &--metric {
                    font-size: 22px;
                    font-weight: 600;
                }
            }
        }
    }
}
</style>
