<template>
    <pendo-radio-group
        v-model="selected"
        class="build-with-ai-toggle"
        data-cy="build-with-ai-toggle"
        :button="true"
        direction="horizontal"
        @change="$emit('toggle', $event)">
        <pendo-radio-button
            v-for="option in options"
            :key="option.id"
            :class="`build-with-ai-toggle--radio-option ${option.id}-option`"
            v-bind="option">
            <div class="build-with-ai-toggle--radio-option-label">
                <pendo-tag
                    v-if="option.value"
                    color="#a004ff"
                    label="new" /> {{ option.label }}
            </div>
        </pendo-radio-button>
    </pendo-radio-group>
</template>

<script>
import { PendoRadioGroup, PendoRadioButton, PendoTag } from '@pendo/components';

export default {
    name: 'BuildWithAIToggle',
    components: {
        PendoRadioGroup,
        PendoRadioButton,
        PendoTag
    },
    props: {
        disableAIOption: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            selected: false,
            options: [
                {
                    value: false,
                    id: 'manual',
                    label: 'Start from scratch'
                },
                {
                    value: true,
                    id: 'ai',
                    disabled: this.disableAIOption,
                    label: 'Build with Pendo AI'
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.build-with-ai-toggle {
    width: 100%;

    .build-with-ai-toggle--radio-option-label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}
</style>
