<template>
    <pendo-card v-if="loading">
        <div class="workflows-loading-indicator">
            <pendo-loading-indicator
                class="workflows-loading-indicator--indicator"
                loading
                size="large" />
            <span
                class="workflows-loading-indicator--text">Data for this report is running. This could take a few minutes...</span>
        </div>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoLoadingIndicator } from '@pendo/components';

export default {
    name: 'WorkflowsLoadingIndicator',
    components: {
        PendoCard,
        PendoLoadingIndicator
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-loading-indicator {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 50px;

    &--indicator {
        margin: 50px;
    }

    &--text {
        line-height: 2rem;
        margin: 2rem 0;
    }
}
</style>
