<template>
    <expired-link>
        <template #logo>
            <slot name="logo" />
        </template>
    </expired-link>
</template>

<script>
import { ExpiredLink } from '@pendo/login';

export default {
    name: 'ExpiredLinkPage',
    components: {
        ExpiredLink
    }
};
</script>
