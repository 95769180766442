<template>
    <page-header
        :only-title="true"
        :title="visitorId"
        class="visitor-details-header">
        <template #top>
            <div class="visitor-details-header--back">
                <router-link to="/analytics/visitors">
                    <pendo-button
                        theme="app"
                        type="link"
                        size="mini"
                        prefix-icon="chevron-left"
                        label="Back to Visitors List" />
                </router-link>
            </div>
        </template>
    </page-header>
</template>

<script>
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';

export default {
    name: 'VisitorDetailsHeader',
    components: {
        PageHeader,
        PendoButton
    },
    props: {
        visitorId: {
            type: String,
            required: true
        }
    }
};
</script>
