<template>
    <div class="utilization-percent-bar">
        <div class="horizontal-bar">
            <div
                :class="[fillClass]"
                :style="{ width: `${fillWidth}%` }" />
            <div
                class="empty-space"
                :style="{ width: `${emptySpaceWidth}%` }" />
        </div>
        <div
            v-if="decimalPercentValue >= 0"
            class="text-percent">
            {{ formattedPercentValue }}%
        </div>
        <div
            v-else
            class="no-licenses-warning">
            <pendo-icon
                v-pendo-tooltip="{
                    content: 'Cannot calculate License Utilization for 0 licenses'
                }"
                type="alert-triangle"
                size="17"
                stroke="#cc3340" />
        </div>
    </div>
</template>

<script>
import { PendoIcon, PendoTooltip } from '@pendo/components';
import isNaN from 'lodash/isNaN';

export default {
    name: 'UtilizationPercentBar',
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        decimalPercentValue: {
            type: Number,
            default: () => 0
        }
    },
    computed: {
        formattedPercentValue () {
            return Math.round(this.decimalPercentValue * 100);
        },
        emptySpaceWidth () {
            return 100 - this.fillWidth;
        },
        fillWidth () {
            if (isNaN(this.decimalPercentValue)) return 100;

            return Math.min(this.formattedPercentValue, 100);
        },
        fillClass () {
            return this.formattedPercentValue >= 100 || isNaN(this.decimalPercentValue) ? 'fill-alert' : 'fill-normal';
        }
    }
};
</script>

<style lang="scss">
.utilization-percent-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 260px;

    .horizontal-bar {
        display: flex;
        height: 10px;
        width: 160px;

        .fill-alert {
            background: $red-error;
        }

        .fill-normal {
            background: $teal-primary;
        }

        .empty-space {
            background: #e9e9e9;
        }
    }

    .text-percent,
    .no-licenses-warning {
        width: 40px;
    }
}
</style>
