<template>
    <div class="guide-activity-table">
        <pendo-guide-activity-table
            :columns="columns"
            :guide-activity-agg-status="guideActivityAggStatus"
            :activity-data="activityData"
            :selected-activity-type="selectedActivityType"
            :features-list="featuresList"
            :pages-list="pagesList"
            :guides-list="guidesList"
            :metadata-list="filteredSchemaList"
            :saving-column-chooser="savingColumnChooser"
            :loading-options="loadingOptions"
            :show-activity-filter="showActivityFilter"
            :selected-activity-filter="selectedActivityFilter"
            show-csv-download-button
            @manageColumns="showManageColumns = true"
            @sortChange="onSortChange"
            @confirmColumnsChange="onConfirmColumnsChange"
            @activityTypeSelect="onActivityTypeSelect"
            @activityFilterSelect="onActivityFilterSelect"
            @downloadCsv="onDownloadCsv" />
        <pendo-csv-modal
            :visible="showCsvModal"
            :status="csvModalStatus"
            :url="csvUrl"
            @close="showCsvModal = false" />
    </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { PendoGuideActivityTable, PendoCsvModal } from '@pendo/components';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getGuideActivityTableData, getGuideActivityTableCsv } from '@/aggregations/guide-activity-table';
import {
    getTableColumns,
    getSelectedFields,
    getAddedColumnsForAggregation,
    getJoinKeysForAggregation,
    processActivityByEventResponse,
    processActivityByVisitorResponse
} from '@/utils/guide-activity-table';
import last from 'lodash/last';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { isCrossApp } from '@pendo/services/CrossAppGuides';

const defaultSortConfig = {
    prop: 'visitorId',
    order: 'none'
};

export default {
    name: 'GuideActivityTable',
    components: {
        PendoGuideActivityTable,
        PendoCsvModal
    },
    data () {
        return {
            selectedActivityType: {
                id: 'byVisitor',
                label: 'By Visitor'
            },
            selectedActivityFilter: {
                id: 'everyone',
                label: 'Everyone'
            },
            activityData: [],
            guideActivityAggStatus: 'resolved',
            showCsvModal: false,
            csvModalStatus: 'resolved',
            csvUrl: '',
            showManageColumns: false,
            savingColumnChooser: false,
            unsubscribeFilterBarListener: null
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            activityTableUserSettings: (state) => state.guideAnalytics.guideActivityTableUserSettings
        }),
        ...mapGetters({
            guide: 'guides/active',
            activeSegment: 'filters/activeSegment',
            activeTimeSeries: 'filters/activeTimeSeries',
            featuresList: 'features/list',
            pagesList: 'pages/list',
            guidesList: 'guides/list',
            schemaList: 'filters/schemaList'
        }),
        filteredSchemaList () {
            return this.schemaList.filter((item) => {
                return !['application', 'visitor_pendo_designerenabled', 'visitor_pendo_donotprocess'].includes(
                    item.id
                );
            });
        },
        appIds () {
            return get(this.guide, 'appIds', '');
        },
        loadingOptions () {
            return {
                loadingType: 'feather',
                loadingSpinnerSize: 24
            };
        },
        showActivityFilter () {
            return this.selectedActivityType.id === 'byVisitor';
        },
        sortConfig () {
            return get(this.activityTableUserSettings, `${this.selectedActivityType.id}.sort`, defaultSortConfig);
        },
        sortValue () {
            const { prop, order } = this.sortConfig;

            if (order === 'none') {
                return null;
            }

            if (order === 'descending') {
                return `-${prop}`;
            }

            return prop;
        },
        userColumns () {
            return get(this.activityTableUserSettings, `${this.selectedActivityType.id}.columns`, []);
        },
        columns () {
            const type = this.selectedActivityType.id;

            const columns = getTableColumns({ guide: this.guide, columns: this.userColumns, type });

            return columns;
        },
        addedColumns () {
            return getAddedColumnsForAggregation(this.columns);
        },
        joinKeys () {
            return getJoinKeysForAggregation(this.columns);
        },
        aggTimeSeries () {
            return {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
        },
        isCrossApp () {
            return isCrossApp(this.guide);
        }
    },
    async created () {
        await this.refreshTable();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, async () => {
            await this.refreshTable();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapMutations({
            setGuideActivityTableColumns: 'guideAnalytics/setGuideActivityTableColumns',
            setGuideActivityTableSort: 'guideAnalytics/setGuideActivityTableSort'
        }),
        ...mapActions({
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        onSortChange (sortConfig) {
            this.setGuideActivityTableSort({ type: this.selectedActivityType.id, guideActivityTableSort: sortConfig });
            const type = this.selectedActivityType.id;
            const userSettings = cloneDeep(this.activityTableUserSettings);
            set(userSettings, `${type}.sort`, sortConfig);
            this.updateUserSetting({
                name: 'guideActivityTableUserSettings',
                value: JSON.stringify(userSettings)
            });
            this.refreshTable();
        },
        onActivityTypeSelect (type) {
            this.selectedActivityType = type;
            this.refreshTable();
        },
        onActivityFilterSelect (filter) {
            this.selectedActivityFilter = filter;
            this.refreshTable();
        },
        async refreshTable () {
            const guideSteps = get(this.guide, 'steps', []);
            if (!guideSteps.length) return;

            this.guideActivityAggStatus = 'loading';
            const type = this.selectedActivityType.id;

            const aggParams = this.getAggParams({ type, isCsv: false });
            try {
                const activityResponse = await getGuideActivityTableData(type, aggParams);
                let activityData = [];
                switch (type) {
                    case 'byEvent':
                        activityData = processActivityByEventResponse(activityResponse, this.guide);
                        break;
                    case 'byVisitor':
                    default:
                        activityData = processActivityByVisitorResponse(activityResponse, this.guide);
                }
                this.guideActivityAggStatus = activityData.length ? 'resolved' : 'empty';
                this.activityData = activityData;
            } catch (error) {
                this.guideActivityAggStatus = 'rejected';
                throw error;
            }
        },
        async onDownloadCsv () {
            this.csvModalStatus = 'loading';
            this.showCsvModal = true;
            const type = this.selectedActivityType.id;

            const aggParams = this.getAggParams({ type, isCsv: true });
            try {
                const {
                    data: { url }
                } = await getGuideActivityTableCsv(type, aggParams);
                this.csvUrl = url;
                // the guide activity csv comes back a little too quickly vs whatever is happening in gcs
                // Using a timeout is definitely not the right solution, but is much quicker to fix in the short term
                // We should definitely figure out why clicking download immediately after this finishes produces a 404
                setTimeout(() => {
                    this.csvModalStatus = 'resolved';
                }, 3000);
            } catch (error) {
                this.csvModalStatus = 'rejected';
                throw error;
            }
        },
        async onConfirmColumnsChange (formattedColumnsForTable) {
            const guideActivityTableColumns = formattedColumnsForTable;
            const type = this.selectedActivityType.id;
            this.savingColumnChooser = true;
            try {
                const userSettings = cloneDeep(this.activityTableUserSettings);
                set(userSettings, `${type}.columns`, guideActivityTableColumns);
                await this.updateUserSetting({
                    name: 'guideActivityTableUserSettings',
                    value: JSON.stringify(userSettings)
                });
                this.savingColumnChooser = false;
                this.setGuideActivityTableColumns({ type: this.selectedActivityType.id, guideActivityTableColumns });
                await this.refreshTable();
            } finally {
                this.savingColumnChooser = false;
            }
        },
        getAggParams ({ type = 'byVisitor', isCsv = false } = {}) {
            return {
                columns: this.columns
                    .filter((column) => column.type !== 'tree')
                    .map((column) => {
                        const { href, formatter, ...rest } = column;

                        return { ...rest };
                    }),
                appId: this.appIds,
                timeSeries: this.aggTimeSeries,
                segmentId: this.activeSegmentId,
                guideId: this.guide.id,
                guideStepId: this.guide.steps[0].id,
                lastStepId: last(this.guide.steps).id,
                sortValue: this.sortValue,
                isCsv,
                isWalkthrough: this.guide.isMultiStep,
                addedColumns: this.addedColumns,
                keys: this.joinKeys,
                selectedFields: getSelectedFields({
                    columns: this.userColumns,
                    type,
                    isCsv,
                    isCrossApp: this.isCrossApp
                }),
                activityFilterId: this.selectedActivityFilter.id,
                includeMultiApp: this.isCrossApp
            };
        }
    }
};
</script>
<style lang="scss" scoped>
::v-deep td.pendo-table__column:first-of-type {
    .pendo-table__cell:empty::after {
        content: '';
    }
}
</style>
