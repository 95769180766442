<template>
    <svg
        class="bracket-svg"
        width="10"
        :height="height"
        viewBox="0 0 11 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="marginStyles">
        <path
            d="M1 0V19H11"
            stroke="#BABCC5" />
    </svg>
</template>

<script>
export default {
    name: 'Bracket',
    props: {
        marginRight: {
            default: '5px',
            type: String
        },
        height: {
            default: '19px',
            type: String
        }
    },
    data () {
        return {
            marginStyles: `margin: 9px ${this.marginRight} 0px 0px;`
        };
    }
};
</script>

<style>
.launch-bracket {
    margin-top: 9px;
    margin-right: 5px;
    width: 10px;
    height: 19px;
}
</style>
