<template>
    <div
        class="portfolio-action-menu"
        :style="{ left: mousePosition.x + 'px', top: mousePosition.y + 'px' }">
        <div class="actions">
            <div
                v-for="(action, index) in actions"
                :key="index"
                class="action"
                @click="action.callback">
                <pendo-icon
                    :type="action.icon"
                    class="icon"
                    stroke="#2A2C35"
                    size="14" />
                <p class="name">
                    {{ action.name }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { PendoIcon } from '@pendo/components';

export default {
    name: 'PortfolioActionMenu',
    components: {
        PendoIcon
    },
    props: {
        actions: {
            type: Array,
            required: true,
            validator: (actions) => {
                // Array of objects with the following structure:
                // { icon: 'pendo-icon-name', name: ' Displayed Action Name', callback: () => {} }
                const hasRequiredProps = (action) => action.icon && action.name && action.callback;

                return actions.every(hasRequiredProps);
            }
        },
        mousePosition: {
            type: Object,
            required: true,
            validator: (obj) => obj.x && obj.y
        }
    }
};
</script>

<style lang="scss" scoped>
.portfolio-action-menu {
    position: absolute;
    z-index: 2;
    background: $white;
    border: 1px solid $gray-lighter-5;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.17);
    border-radius: 3px;
    transform: translate(-50%, -50%);
}

.actions {
    margin: 0;
    padding: 5px;
}

.action {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 8px;
    margin: 0;
    font-weight: 500;
    color: $gray-primary;

    &:hover {
        cursor: pointer;
        background: $gray-lighter-7;
    }

    .name {
        margin: 0;
        padding: 0 5px;
    }
}
</style>
