<template>
    <div>
        <pendo-button
            v-if="showAddAppButton"
            data-cy="add-extension-app"
            theme="app"
            type="primary"
            prefix-icon="plus"
            label="Add Application"
            @click="openAddAppModal" />
        <pendo-button
            v-if="editFavicon"
            data-cy="add-extension-app"
            theme="app"
            type="link"
            label="Edit"
            @click="openAddAppModal" />
        <pendo-add-update-extension-app-modal
            :visible="showAddAppModal"
            theme="app"
            :create-mode="!editFavicon"
            :edit-favicon="editFavicon"
            :extension-platform-type-options="extensionPlatformTypeOptions"
            :saving="saving"
            :submission-error="submissionError"
            :is-for-customer="false"
            @create="createApplication"
            @domain-changed="onDomainChanged"
            @update-favicon="onUpdateFavicon"
            @close="closeAddAppModal">
            <favicon-color-chooser
                :favicon-url="faviconUrl"
                :app="app"
                @favicon-updated="setFavicon"
                @favicon-input-change="onDomainChanged" />
        </pendo-add-update-extension-app-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { PendoButton, PendoAddUpdateExtensionAppModal } from '@pendo/components';
import get from 'lodash/get';
import {
    fetchExtensionPlatformTypes,
    formatDisableExtensionTextCaptureConsentOptions,
    formatHtmlAttributesConsentOptions,
    formatPageEPConsentOptions,
    formatSiblingSelectorsConsentOptions
} from '@/utils/apps';
import FaviconColorChooser from './FavIconColorChooser.vue';

export default {
    name: 'AddAppModalWrapper',
    components: {
        PendoButton,
        PendoAddUpdateExtensionAppModal,
        FaviconColorChooser
    },
    props: {
        app: {
            type: Object,
            default: null
        },
        showAddAppButton: {
            type: Boolean,
            default: false
        },
        editFavicon: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showAddAppModal: false,
            extensionPlatformTypeOptions: [],
            isLoadingPlatformExtensionTypes: false,
            submissionError: null,
            saving: false,
            faviconUrl: null,
            faviconB64: null,
            color: null
        };
    },
    computed: {
        ...mapGetters({
            hasAppOfPlatformType: 'apps/hasAppOfPlatformType',
            subscription: 'subscriptions/active'
        })
    },
    async created () {
        this.isLoadingPlatformExtensionTypes = true;
        await this.loadExtensionPlatformTypeOptions();
        this.isLoadingPlatformExtensionTypes = false;
        this.faviconB64 = get(this.app, 'faviconB64', null);
        this.color = get(this.app, 'color', null);
    },
    methods: {
        ...mapActions({
            createApp: 'apps/createApp',
            updateApp: 'apps/updateApp'
        }),
        openAddAppModal () {
            this.showAddAppModal = true;
        },
        closeAddAppModal () {
            this.faviconUrl = '';
            this.showAddAppModal = false;
        },
        async loadExtensionPlatformTypeOptions () {
            const isDAParentSub = get(this.subscription, 'autoSharePageFeature', false);
            const options = await fetchExtensionPlatformTypes();

            const platformTypeOptions = options
                .filter((platform) => {
                    if (isDAParentSub) {
                        return platform.type !== 'other';
                    }

                    return platform.state === 'configured';
                })
                .map((platform) => {
                    const disabled = isDAParentSub
                        ? ['configured', 'invalid'].includes(platform.state)
                        : this.hasAppOfPlatformType(platform.type);

                    return {
                        disabled,
                        label: platform.displayName,
                        consentOptions: {
                            // keep this order alphabetized by label
                            ...formatHtmlAttributesConsentOptions(platform),
                            ...formatPageEPConsentOptions(platform),
                            ...formatSiblingSelectorsConsentOptions(platform),
                            ...formatDisableExtensionTextCaptureConsentOptions(platform)
                        },
                        ...platform
                    };
                });
            const allAvailablePlatformsConfigured = platformTypeOptions.every((option) => option.disabled);

            if (isDAParentSub && allAvailablePlatformsConfigured) {
                return;
            }

            this.extensionPlatformTypeOptions = platformTypeOptions;
        },
        async createApplication (appJson) {
            this.submissionError = null;
            const { faviconB64, color } = this;
            if (faviconB64 !== '' && faviconB64 !== null) {
                appJson.faviconB64 = faviconB64;
            }
            if (color !== '' && color !== null) {
                appJson.color = color;
            }
            try {
                this.saving = true;
                await this.createApp({ appJson });
                this.saving = false;
                this.closeAddAppModal();
            } catch (error) {
                this.submissionError = {};
                this.submissionError.errorMessage = get(
                    error,
                    'data.error',
                    'Unable to create application at this time.'
                );
                this.submissionError.duplicatePatternErrors = get(error, 'data.duplicates', null);
                this.submissionError.invalidPatternErrors = get(error, 'data.invalidPatterns', null);
                this.saving = false;
            }
        },
        setFavicon (favicon, color) {
            this.faviconB64 = favicon;
            this.color = color;
        },
        async onUpdateFavicon () {
            this.submissionError = null;
            this.saving = true;
            const updates = {};
            const { faviconB64, color } = this;
            if (faviconB64 !== '' && faviconB64 !== null) {
                updates.faviconB64 = faviconB64;
            }
            if (color !== '' && color !== null) {
                updates.color = color;
            }
            await this.updateApp({
                app: this.app,
                updates
            });
            this.closeAddAppModal();
            this.saving = false;
        },
        async onDomainChanged (domain) {
            this.faviconUrl = domain;
        }
    }
};
</script>
