import Vue from 'vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { http } from '@pendo/http';
import { shouldDisplayMetadata } from '@/utils/metadata';

export default {
    namespaced: true,
    state: {
        map: {},
        error: {},
        fetching: false,
        updating: false,
        removing: false
    },
    mutations: {
        setMap (state, { map }) {
            state.map = map;
        },
        setUpdate (state, { kind, group, field, schema }) {
            if (!get(state.map[kind], group)) {
                Vue.set(state.map[kind], group, {});
            }

            Vue.set(state.map[kind][group], field, schema);
        },
        setDelete (state, { kind, group, field }) {
            Vue.delete(state.map[kind][group], field);
        },
        setFetching (state, { fetching }) {
            state.fetching = fetching;
        },
        setUpdating (state, { updating }) {
            state.updating = updating;
        },
        setRemoving (state, { removing }) {
            state.removing = removing;
        }
    },
    actions: {
        async loadAll ({ commit, state }, { reFetch = false } = {}) {
            if (!isEmpty(state.map) && !reFetch) return Promise.resolve();

            commit('setFetching', { fetching: true });

            try {
                const { data: visitorData } = await http.get('/api/s/_SID_/metadata/visitor/schema');
                const { data: eventData } = await http.get('/api/s/_SID_/metadata/event/schema');
                const map = { visitor: visitorData, event: eventData };

                commit('setFetching', { fetching: false });
                commit('setMap', { map });
            } catch (error) {
                commit('setFetching', { fetching: false });
                throw error;
            }
        },
        update ({ commit, dispatch }, { schema = {} }) {
            const { kind, group, field, ...data } = schema;

            commit('setUpdating', { updating: true });

            return http
                .put(`/api/s/_SID_/metadata/${kind}/${group}/schema/${field}`, { ...data, name: `${kind}-metadata` })
                .then(({ data }) => {
                    commit('setUpdating', { updating: false });
                    commit('setUpdate', { kind, group, field, schema: data });
                    dispatch('filters/fetchVisitorSchema', { forceRefresh: true }, { root: true });
                })
                .catch((error) => {
                    commit('setUpdating', { updating: false });
                    throw error;
                });
        },
        remove ({ commit, dispatch }, { schema = {} }) {
            const { kind, group, field } = schema;

            commit('setRemoving', { removing: true });

            return http
                .delete(`/api/s/_SID_/metadata/${kind}/${group}/schema/${field}`)
                .then(({ data }) => {
                    commit('setRemoving', { removing: false });
                    commit('setDelete', { kind, group, field, schema: data });
                    dispatch('filters/fetchVisitorSchema', { forceRefresh: true }, { root: true });
                })
                .catch((error) => {
                    commit('setRemoving', { removing: false });
                    throw error;
                });
        }
    },
    getters: {
        metadataListByKind: (state) => (kind, options = { groupsToExclude: [] }) => {
            return iterateThroughMetadata(get(state.map, kind, []), kind).filter(({ group }) => {
                const { groupsToExclude } = options;
                if (groupsToExclude && !isEmpty(groupsToExclude)) {
                    return !groupsToExclude.includes(group);
                }

                return true;
            });
        },
        visitorMetadataOptions: (state, getters) => {
            const visitorMetadata = getters.metadataListByKind('visitor', { groupsToExclude: ['auto', 'pendo'] });
            const availableVisitorMetadata = visitorMetadata.filter((singleField) =>
                shouldDisplayMetadata(singleField)
            );
            const visitorMetaDataWithViewByDisplayName = availableVisitorMetadata.map(({ DisplayName, ...rest }) => ({
                ...rest,
                displayName: DisplayName
            }));

            return sortBy(visitorMetaDataWithViewByDisplayName, [({ displayName }) => displayName.toLowerCase()]);
        }
    }
};

function iterateThroughMetadata (metadata, kind) {
    if (!metadata) return [];

    return Object.keys(metadata).reduce((schemas, group) => {
        Object.keys(metadata[group]).forEach((field) => {
            const displayName = metadata[group][field].DisplayName;
            schemas.push({
                ...metadata[group][field],
                group,
                field,
                kind,
                DisplayName: displayName && displayName !== '' ? displayName : field,
                groupDisplayName: group === 'segmentio' ? 'Segment.io' : group
            });
        });

        return schemas;
    }, []);
}
