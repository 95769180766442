<template>
    <div class="module-info-container">
        <div class="module-info-name">
            {{ moduleInfo.internalName }}
        </div>
        <div
            v-if="canViewModuleInfoType"
            class="module-info-type">
            <strong>Module Type:</strong> {{ moduleInfo.type }}
        </div>
        <div class="module-info-status">
            {{ moduleInfo.status }}
        </div>
    </div>
</template>
<script>
import get from 'lodash/get';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';
import { getModuleURLParam } from '@/utils/resource-center';

const oneModuleWarning =
    'This view will not be visible to end users because your Resource Center only contains one module.';
const noContentWarning = 'This module will not be visible to end users because it does not contain any content.';

export default {
    name: 'ResourceCenterPreviewInfo',
    props: {
        activeState: {
            type: String,
            required: true
        },
        module: {
            type: Object,
            required: true
        },
        numModules: {
            type: Number,
            required: true
        }
    },
    computed: {
        moduleHasGuides () {
            return get(this.module, 'attributes.resourceCenter.children.length');
        },
        homeViewStatus () {
            switch (this.numModules) {
                case 0:
                    return noContentWarning;
                case 1:
                    return oneModuleWarning;
                default:
                    return null;
            }
        },
        moduleInfo () {
            const { homeView, guideList, onboarding, iframe } = BuildingBlockLayouts.moduleIds;
            const { name: internalName, attributes } = this.module;
            switch (attributes.resourceCenter.moduleId) {
                case homeView:
                    return {
                        internalName: 'Home',
                        type: 'Home View',
                        status: this.homeViewStatus
                    };
                case guideList:
                    return {
                        internalName,
                        type: 'Guide List Module',
                        status: !this.moduleHasGuides ? noContentWarning : null
                    };
                case onboarding:
                    return {
                        internalName,
                        type: 'Onboarding Module',
                        status: !this.moduleHasGuides ? noContentWarning : null
                    };
                case iframe:
                    return {
                        internalName,
                        type: 'External Website Module'
                    };
                default:
                    return {
                        internalName,
                        type: 'Unknown',
                        status: null
                    };
            }
        },
        canViewModuleInfoType () {
            const { type } = this.moduleInfo;
            if (type === 'Home View' || type === 'Unknown') return false;

            return true;
        },
        environmentLabel () {
            if (this.activeState === 'public') return 'production';

            return 'draft';
        }
    },
    methods: {
        editModule () {
            const { moduleId } = get(this.module, 'attributes.resourceCenter');
            const moduleRouteParam = getModuleURLParam(moduleId);

            this.$router
                .push({ name: 'editModule', params: { moduleRouteParam, moduleId: this.module.id } })
                .catch(() => {});
        }
    }
};
</script>
<style scoped lang="scss">
.module-info-container,
.module-info-name {
    text-align: center;
}

.module-info-name {
    font-size: 1rem;
    color: $gray-primary;
}

.module-info-container {
    font-size: 0.75rem;
    color: $gray-lighter-2;
}

.module-info-container {
    display: flex;
    flex-direction: column;
    height: 48px;
    margin-top: 20px;

    .module-info-name {
        margin-bottom: 5px;
        line-height: 1;
    }

    .module-info-status,
    .module-info-type,
    .module-info-name {
        width: 350px;
        margin-left: auto;
        margin-right: auto;

        span {
            display: inline-block;
            vertical-align: bottom;
        }
    }
}
</style>
