<template>
    <pendo-tag
        :prefix-icon="appPrefixIcon"
        :data-cy="app.displayName"
        type="filter">
        <pendo-app-display :apps="app" />
    </pendo-tag>
</template>

<script>
import { PendoTag, PendoAppDisplay } from '@pendo/components';
import { getAppChartColor } from '@/utils/apps';

export default {
    name: 'AppIconTag',
    components: {
        PendoTag,
        PendoAppDisplay
    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    computed: {
        appPrefixIcon () {
            const color = getAppChartColor(this.app);

            return {
                type: 'circle',
                stroke: color,
                fill: color,
                size: 12
            };
        }
    }
};
</script>
