import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment-timezone';
import { GuideToPDF } from '@pendo/services/BuildingBlocks';

export function exportPDF (preparedDOM, guideName) {
    const date = moment().format('YYYYMMDD');
    const pdfFileName = `${guideName}-${date}`;
    const pdfFullPageHeightIn = 11;
    const pdfFullPageWidthIn = 8.5;
    const margin = 0.5;
    const doc = new jsPDF('p', 'in', 'letter', true); // eslint-disable-line new-cap
    document.body.appendChild(preparedDOM);
    html2canvas(preparedDOM, {
        useCORS: true,
        scale: 1
    }).then((canvas) => {
        // PDF Render Logic reworked from eKoopman's html2pdf library implementation
        // Establish Page Dimensions and number of pages need
        GuideToPDF.renderPDFContent({
            canvas,
            pdfFullPageHeightIn,
            pdfFullPageWidthIn,
            margin,
            pdfFileName,
            pdfDoc: doc
        });
    });
    document.body.removeChild(preparedDOM);
}
