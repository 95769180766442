import { http } from '@pendo/http';

export async function getFavicon (domain) {
    if (domain) {
        try {
            const { data } = await http.post('/api/s/_SID_/helper/favicon', {
                domain,
                size: 32
            });

            return data.faviconB64;
        } catch (error) {
            return null;
        }
    }
}
