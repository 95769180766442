var render = function render(){var _vm=this,_c=_vm._self._c;return _c('pendo-table',{attrs:{"title":"Suggested Steps","data":_vm.localSteps,"draggable":"","columns":_vm.columns,"max-height":600,"loading":_vm.isLoading || _vm.isRegeneratingStep},on:{"table-row-drag-stop":_vm.reorder},scopedSlots:_vm._u([{key:"stepType",fn:function({ rowIndex, row }){return [_c('div',{staticClass:"column-wrap",attrs:{"pendo-draggable-ignore":""}},[_c('pendo-multiselect',{staticClass:"suggested-steps-table--step-type-value",attrs:{"value":_vm.layoutOptions.find((option) => option.id == row.stepType),"options":_vm.layoutOptions},on:{"select":(value) => {
                        _vm.updateStepLayout(rowIndex, value);
                    }},scopedSlots:_vm._u([{key:"selectedLabel",fn:function({ option }){return [_c('pendo-icon-option',{attrs:{"option":option}})]}},{key:"option",fn:function({ option }){return [_c('pendo-icon-option',{attrs:{"option":option}})]}}],null,true)})],1)]}},{key:"stepTextContent",fn:function({ rowIndex, row }){return [_c('div',{staticClass:"column-wrap",attrs:{"pendo-draggable-ignore":""}},[_c('pendo-editable-content',{attrs:{"auto-width":"","value":row.stepTextContent,"exit-event":"blur"},on:{"input":function($event){return _vm.debouncedUpdateStepContent(rowIndex, $event)}}})],1)]}},{key:"actions",fn:function({ row }){return [_c('div',{staticClass:"column-wrap",attrs:{"pendo-draggable-ignore":""}},[_c('pendo-actions-cell',{attrs:{"pendo-draggable-ignore":"","row":row,"actions":[
                    {
                        type: 'regenerate',
                        icon: 'wand',
                        tooltip: 'Regenerate with currently selected tone'
                    },
                    {
                        type: 'delete',
                        icon: 'trash-2',
                        tooltip: 'Delete'
                    }
                ]},on:{"regenerate":_vm.regenerateStep,"delete":_vm.deleteStep}})],1)]}},{key:"append",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"suggested-steps-table--row-entry-index pendo-table__cell pendo-table__column",style:({ width: `${_vm.stepNumberColumnWidth}px` })},[_vm._v(" "+_vm._s(_vm.localSteps.length + 1)+" ")]),_c('td'),_c('td',{staticClass:"pendo-table__cell pendo-table__column"},[_c('pendo-editable-content',{staticClass:"suggested-steps-table--new-step-content",attrs:{"pendo-draggable-ignore":"","auto-width":"","exit-event":"blur","empty-text":"Enter text to add another step"},on:{"confirm":_vm.addNewStep},model:{value:(_vm.newStepTextContent),callback:function ($$v) {_vm.newStepTextContent=$$v},expression:"newStepTextContent"}})],1)])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }