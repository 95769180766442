<template>
    <pendo-icon
        v-pendo-tooltip="tooltip"
        class="translation-warning-icon"
        type="alert-circle-solid"
        stroke="#fff"
        fill="#DBA211"
        :size="16" />
</template>

<script>
import { PendoTooltip, PendoIcon } from '@pendo/components';

export const TRANSLATION_MISSING_WARNING = 'translation-missing';

export const TRANSLATION_SYNC_WARNING = 'translation-sync';

export default {
    name: 'TranslationWarningIcon',
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        warning: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        tooltipContent () {
            switch (this.warning) {
                case TRANSLATION_MISSING_WARNING:
                    return {
                        title: 'Missing translation',
                        content: "This block was added in the designer and hasn't been translated yet."
                    };
                case TRANSLATION_SYNC_WARNING:
                    return {
                        title: 'Translation out of sync',
                        content:
                            'The text in this block was edited in the designer after translation and needs a review of the translation.'
                    };
                default:
                    return {
                        title: this.title,
                        content: this.content
                    };
            }
        },
        tooltip () {
            const { title, content } = this.tooltipContent;

            if (!(title && content)) return {};

            const tooltipContent = `<div class="translation-warning-tooltip-content">
                <div class="tooltip-title">${this.$pendo.sanitize(title)}</div>
                <div class="tooltip-body">${this.$pendo.sanitize(content)}</div>
            </div>`;

            return {
                html: true,
                content: tooltipContent,
                arrow: true,
                multiline: true,
                placement: 'right',
                theme: 'light',
                classes: 'translation-warning-tooltip',
                ...this.options
            };
        }
    }
};
</script>

<style lang="scss">
.translation-warning-tooltip {
    .translation-warning-tooltip-content {
        .tooltip-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        .tooltip-body {
            ul {
                padding: 0 12px;
                margin: 0;
            }
        }
    }
}
</style>
