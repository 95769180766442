import {
    USER_ROLES,
    SUBSCRIPTION_ADMIN_ROLE,
    SUPER_ROLE,
    OPS_ROLE,
    SUPPLEMENTAL_ROLES
} from '@/constants/roles-and-permissions';
import { http } from '@pendo/http';
import get from 'lodash/get';

export const inviteConfirmationErrors = Object.freeze({
    INVALID: 'invalid invitation data',
    EXPIRED: 'invitation expired',
    KEY_ISSUE: 'invalid invitation key'
});

export function isReadOnly (user) {
    return get(user, 'flags.readOnly', false);
}

export function isAdminBySubscriptionRole (user) {
    return user.subscriptionRoles && user.subscriptionRoles.includes(SUBSCRIPTION_ADMIN_ROLE);
}

export function isAdminByFlag (user) {
    return get(user, 'flags.admin', false);
}

export function isSuperBySubscriptionRole (user) {
    return user.subscriptionRoles && user.subscriptionRoles.includes(SUPER_ROLE);
}

export function isSuperByRole (user) {
    return user.role === USER_ROLES.SUPER;
}

export function isOpsByUserFlag (user) {
    return isSuperByRole(user) && get(user, 'userflags.opsFlag', false);
}

export function isOpsBySubscriptionRole (user) {
    return user.subscriptionRoles && user.subscriptionRoles.includes(OPS_ROLE);
}

// Helper function determines if a role is managed through the api
// ASSUME yes unless the role is one of the ones we list above
export function isRoleManagedByAPI (role) {
    return !SUPPLEMENTAL_ROLES.includes(role);
}

export function setNullApplicationsForExtAppsToEmptyArray (subscriptions) {
    return subscriptions.map((sub) => ({
        ...sub,
        applications: sub.applications ? sub.applications : []
    }));
}

export function confirmInvitation (token) {
    return http.get(`/api/signup/invitation/confirm/${token}`);
}

export function getInvitationInfo (inviteKey) {
    return http.get(`/api/signup/invitation/query/${inviteKey}`);
}
