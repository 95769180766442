<template>
    <div class="body">
        <template v-if="insight.key === INSIGHT_TYPES.LONGEST_STEPS">
            <div>
                Visitors <b>spent the most time navigating between these two steps</b> in the most common journey.
            </div>
            <br>
            <div>Not what you expected?</div>
            <ol>
                <li>
                    <b>Are important steps missing from this journey?</b> Tag additional Pages or Features and add them
                    to this journey.
                </li>
                <li><b>Are there bottlenecks?</b> Revisit the process in {{ appNames }}.</li>
                <li><b>Need feedback on how to improve the process?</b> Create a guide to get feedback.</li>
            </ol>
        </template>
        <template v-if="insight.key === INSIGHT_TYPES.REPEATED_SEQUENCES">
            <div>
                Visitors <b>completed this sequence of steps {{ repeatedSequencePhrase }}</b> in the most common
                journey.
            </div>
            <br>
            <div><b>Want to understand why?</b> Create a guide to get feedback.</div>
        </template>
        <template v-if="insight.key === INSIGHT_TYPES.REPEATED_STEPS">
            <div>
                Visitors
                <b>navigated to {{ repeatedStepsPhrase.stepName }} {{ repeatedStepsPhrase.duplicateCount }}</b> in the
                most common journey as steps {{ repeatedStepsPhrase.indexList }}.
            </div>
            <br>
            <div><b>Want to understand why?</b> Create a guide to get feedback.</div>
        </template>
        <template v-if="insight.key === INSIGHT_TYPES.QUICKEST_V_MOST_COMMON">
            <div>
                The quickest journey is <b>{{ percentDiffPhrase }}</b> the most common journey.
            </div>
            <br>
            <div><b>Want everyone to follow this faster journey?</b> Create a walkthrough guide on these steps.</div>
        </template>
    </div>
</template>

<script>
import uniq from 'lodash/uniq';
import { INSIGHT_TYPES } from '@/stateless-components/workflows/journeys/utils/constants';

export default {
    name: 'InsightBody',
    props: {
        insight: {
            type: Object,
            required: true
        },
        steps: {
            type: Array,
            required: true
        },
        journeyList: {
            type: Array,
            default: () => []
        },
        avgTimes: {
            type: Object,
            default: () => ({
                mostCommon: 0,
                quickest: 1
            })
        }
    },
    data () {
        return {
            INSIGHT_TYPES
        };
    },
    computed: {
        appNames () {
            const appNames = uniq(this.steps.map((step) => step.appName));

            if (appNames.length > 1) {
                return appNames.join(' and ');
            }

            return appNames[0];
        },
        repeatedSequencePhrase () {
            const count = this.insight.nodes.length;
            // By definition a repeated sequence must be completed at least twice, so we don't need to handle 1x = 'once'
            if (count === 2) return 'twice';

            return `${count} times`;
        },
        repeatedStepsPhrase () {
            if (this.insight.key !== INSIGHT_TYPES.REPEATED_STEPS) return;

            const stepName = this.steps[0].name;

            // Handles textual list generation, including oxford comma
            const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
            const indexList = listFormatter.format(
                this.steps.map((step) => {
                    const idx = this.journeyList.findIndex((item) => item.nodeId === step.nodeId);

                    return idx.toString();
                })
            );

            return {
                stepName,
                indexList,
                duplicateCount: this.repeatedSequencePhrase
            };
        },
        percentDiffPhrase () {
            const diff = this.avgTimes.mostCommon - this.avgTimes.quickest;

            const percent = Math.round((diff / this.avgTimes.quickest) * 100);

            return `${percent.toLocaleString()}% faster than`;
        }
    }
};
</script>

<style lang="scss" scoped>
ol {
    padding-left: 15px;

    li:nth-of-type(1),
    li:nth-of-type(2) {
        margin-bottom: 4px;
    }
}
</style>
