import moment from '@/utils/moment';

export function buildBreakdownDateMap (messages, period) {
    return messages.reduce((map, message) => {
        let name = '';
        if (period === 'hourly') {
            name = moment(message.time).format('MMM D h:mm a');
        } else if (period === 'daily') {
            name = moment(message.time).format('MMM D');
        } else if (period === 'weekly') {
            name = `${moment(message.time).format('MMM D')} - ${moment(message.time)
                .add(6, 'days')
                .format('MMM D')}`;
        } else {
            name = moment(message.time).format('MMM');
        }

        map[String(message.time)] = {
            id: String(message.time),
            name,
            value: message.time
        };

        return map;
    }, {});
}
