<template>
    <pendo-form
        ref="changePasswordModalForm"
        :call-validate="callValidate"
        :call-reset-fields="callResetFields"
        :model="form"
        class="change-password-modal"
        @fieldsReset="handleFieldsReset"
        @invalidForm="handleInvalidForm"
        @formValidated="handleValidForm">
        <div class="pendo-modal__body">
            <div class="password-helper">
                Passwords cannot be the same as your username, must be at least 12 characters long, and meet a minimum
                of "good" strength.
            </div>
            <pendo-form-item
                :rules="rules.oldPass"
                label="Current Password"
                prop="oldPass">
                <pendo-input-password
                    v-model="form.oldPass"
                    @focus="removeValidationError" />
            </pendo-form-item>
            <pendo-form-item
                :rules="rules.newPass"
                label="New Password"
                prop="newPass">
                <pendo-input-password
                    v-model="form.newPass"
                    show-password-strength
                    @score="handlePasswordStrengthChange" />
            </pendo-form-item>
            <pendo-form-item
                :rules="rules.confirmNewPass"
                label="Confirm New Password"
                prop="confirmNewPass">
                <pendo-input-password
                    v-model="form.confirmNewPass"
                    @focus="removeValidationError"
                    @keyup.enter.native="validateForm" />
            </pendo-form-item>
        </div>
        <div class="pendo-modal__footer">
            <pendo-button
                theme="app"
                type="tertiary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                :disabled="disableSubmit"
                theme="app"
                type="primary"
                label="Change Password"
                @click="validateForm" />
        </div>
    </pendo-form>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { PendoButton, PendoForm, PendoFormItem, PendoInputPassword, PendoNotification } from '@pendo/components';

export default {
    name: 'SettingsChangePasswordModal',
    components: {
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInputPassword
    },
    data () {
        return {
            callResetFields: false,
            callValidate: false,
            form: {
                oldPass: '',
                newPass: '',
                confirmNewPass: ''
            },
            passwordStrength: null,
            lastFailedPasswordLength: null, // used for validation checking
            rules: {
                oldPass: [],
                newPass: [],
                confirmNewPass: [
                    { validator: this.validatePasswordMatch, trigger: ['blur', 'change'] },
                    { validator: this.validateMinLength, trigger: ['blur', 'change'] },
                    { validator: this.validateUpdatePassword, trigger: ['blur', 'change'] },
                    { validator: this.validateNotEmail, trigger: ['blur', 'change'] }
                ]
            }
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user
        }),
        ...mapGetters({
            isAdoptV2Sub: 'subscriptions/activeUsesV2Adopt'
        }),
        validPassword () {
            const length = this.form.newPass.length > 11;
            const strength = this.passwordStrength > 2;
            const notEmail = this.form.newPass !== this.user.email;

            return length && strength && notEmail;
        },
        newPasswordsMatch () {
            return this.form.newPass === this.form.confirmNewPass;
        },
        disableSubmit () {
            if (this.form.oldPass === this.form.newPass) {
                return true;
            }

            return !this.form.oldPass || !this.validPassword || !this.newPasswordsMatch;
        }
    },
    methods: {
        ...mapActions({
            _changePassword: 'auth/changePassword',
            logout: 'auth/logout'
        }),
        handlePasswordStrengthChange (score) {
            this.passwordStrength = score;
        },
        async changePassword ({ oldPass, newPass }) {
            try {
                await this._changePassword({ oldPass, newPass, isAdoptV2Sub: this.isAdoptV2Sub });
                PendoNotification({
                    type: 'success',
                    message: 'Your password has been successfully updated.'
                });

                this.closeModal();
                await this.logout({ postLogout: false });
                this.$router.push('/login').catch(() => {});
            } catch (err) {
                this.rules.oldPass.push({ validator: this.validateFailedPasswordChange, trigger: 'change' });
                this.lastFailedPasswordLength = oldPass.length;
                this.validateForm();
            }
        },
        closeModal () {
            this.$modal.hide('via-modal');
            this.callResetFields = true;
        },
        handleValidForm () {
            this.callValidate = false;
            if (this.disableSubmit) return;

            this.changePassword(this.form);
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        handleFieldsReset () {
            this.callResetFields = false;
            this.lastFailedPasswordLength = null;
        },
        validateForm () {
            this.callValidate = true;
        },
        validateFailedPasswordChange (rule, value, callback) {
            if (value.length === this.lastFailedPasswordLength) {
                callback(new Error('Incorrect Password. Please try again.'));
            } else {
                this.lastFailedPasswordLength = null;
                callback();
            }
        },
        validateMinLength (rule, value, callback) {
            if (value.length < 12) {
                callback(new Error('Password must be at least 12 characters'));
            } else {
                callback();
            }
        },
        validateNotEmail (rule, value, callback) {
            if (value === this.user.email) {
                callback(new Error('Password cannot be your username'));
            } else {
                callback();
            }
        },
        validatePasswordMatch (rule, value, callback) {
            if (value !== this.form.newPass) {
                callback(new Error('Passwords must match'));
            } else {
                callback();
            }
        },
        validateUpdatePassword (rule, value, callback) {
            if (value === this.form.oldPass) {
                callback(new Error('Password cannot be your old password'));
            } else {
                callback();
            }
        },
        removeValidationError () {
            this.$refs.changePasswordModalForm.resetValidation();
        }
    }
};
</script>
<style lang="scss">
.change-password-modal {
    .password-helper {
        margin-bottom: 16px;
    }
}
</style>
