<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="app"
            class="analytics-tabs">
            <template #label="{ tab }">
                <div class="labelSlot">
                    {{ tab.label }}
                    <pendo-notification-count
                        v-if="showNotificationCount(tab.label)"
                        class="notificationCounter"
                        :count="suggestedPageRules.length"
                        :size="18"
                        :show-count="true" />
                </div>
            </template>
            <template #analyticsPortfolio>
                <portfolio-dashboard />
            </template>
            <template #licensesDashboard>
                <licenses-dashboard />
            </template>
            <template #analyticsDashboard>
                <analytics-dashboard />
            </template>
            <template #pagesDashboard>
                <pages-dashboard />
            </template>
            <template #featuresDashboard>
                <features-dashboard />
            </template>
            <template #trackEventsDashboard>
                <track-events-dashboard />
            </template>
            <template #visitorsDashboard>
                <visitors-dashboard />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PendoTabs, PendoPageContent, PendoNotificationCount } from '@pendo/components';
import PortfolioDashboard from '@/components/analytics/portfolio/PortfolioDashboard';
import LicensesDashboard from '@/components/analytics/licenses/LicensesDashboard';
import AnalyticsDashboard from '@/components/analytics/AnalyticsDashboard';
import PagesDashboard from '@/components/analytics/pages/PagesDashboard';
import VisitorsDashboard from '@/components/analytics/visitors/VisitorsDashboard';
import FeaturesDashboard from '@/components/analytics/features/FeaturesDashboard';
import TrackEventsDashboard from '@/components/analytics/trackEvents/TrackEventsDashboard';
import get from 'lodash/get';

export default {
    name: 'AnalyticsContent',
    components: {
        LicensesDashboard,
        AnalyticsDashboard,
        FeaturesDashboard,
        PagesDashboard,
        PendoPageContent,
        PendoNotificationCount,
        PendoTabs,
        PortfolioDashboard,
        VisitorsDashboard,
        TrackEventsDashboard
    },
    data () {
        return {
            adoptTrackEventFlag: false,
            tabsList: [
                {
                    prop: 'analyticsPortfolio',
                    label: 'Portfolio Overview',
                    lazy: true
                },
                {
                    prop: 'analyticsDashboard',
                    label: this.showAppUsage ? 'Application Usage' : 'Dashboard',
                    lazy: true
                },
                this.activeIsDigitalAdoption && {
                    prop: 'licensesDashboard',
                    label: 'License Utilization',
                    lazy: true
                },
                {
                    prop: 'pagesDashboard',
                    label: 'Pages',
                    lazy: true
                },
                {
                    prop: 'featuresDashboard',
                    label: 'Features',
                    lazy: true
                },
                {
                    prop: 'visitorsDashboard',
                    label: 'Visitors',
                    lazy: true
                }
            ]
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route,
            suggestedPageRules: (state) => state.pages.suggestedPageRules,
            userPreferences: (state) => state.userSettings.userPreferences
        }),
        ...mapGetters({
            hasSegmentFlag: 'auth/hasSegmentFlag',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            showAppUsage: 'apps/showAppUsage'
        }),
        activeTab: {
            get () {
                const defaultRoute = this.showPortfolioTab ? 'analyticsPortfolio' : 'analyticsDashboard';

                return this.route.name || defaultRoute;
            },
            set (tab) {
                this.$router.push({ name: tab }).catch(() => {});
            }
        },
        showSuggestedPagesTableNotifications () {
            return get(this.userPreferences, 'suggestedPagesTable.subSettings.notifications.value', false);
        },
        tabs () {
            return this.tabsList.filter(Boolean);
        }
    },
    created () {
        this.adoptTrackEventFlag = this.hasSegmentFlag('adoptTrackEvent');
        if (!this.activeIsDigitalAdoption && this.adoptTrackEventFlag) {
            const trackEventTab = {
                prop: 'trackEventsDashboard',
                label: 'Track Events',
                lazy: true
            };
            this.tabsList.splice(this.tabsList.length - 1, 0, trackEventTab);
        }
    },
    methods: {
        showNotificationCount (tabLabel) {
            return (
                tabLabel === 'Pages' && this.suggestedPageRules.length > 0 && this.showSuggestedPagesTableNotifications
            );
        }
    }
};
</script>
<style scoped lang="scss">
.labelSlot {
    display: flex;
    flex-direction: row;
}

.notificationCounter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-left: 5px;
}
</style>
