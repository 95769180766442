import Vue from 'vue';
import {
    createDraftResourceCenter,
    getResourceCenterByAppId,
    getResourceCenterGuideStepContentForAllStates,
    deleteResourceCenter,
    disableProductionResourceCenter,
    promoteResourceCenter,
    saveModuleGuideList,
    updateModuleTitle,
    updateModuleSubtitle,
    synchronizeModulesWithHomeView,
    updateModule,
    updateHomeviewTemplateChildren,
    updateModuleSegment,
    STATES,
    updateResourceCenterAuthoredLanguage,
    getAppIdFromOriginId
} from '@/utils/resource-center';
import { updateModuleIframeSrc } from '@/stateless-components/utils/resource-center';
import { fetchAndMigrateGuide } from '@/utils/guides';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import has from 'lodash/has';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import keyBy from 'lodash/keyBy';
import { createModule, addToResourceCenter } from '@/utils/create-resource-center-module';
import { BuildingBlock, BuildingBlockEditing } from '@pendo/services/BuildingBlocks';
import { propName } from '@/utils/utils';
import { LAUNCH_METHODS } from '@/stateless-components/utils/guides';
import { importResourceCenterLocalizationFile, clearTranslatedStrings } from '@/utils/localization';

export function getInitialState () {
    return {
        error: null,
        migrateError: null,
        active: null,
        activeEditingAppId: null,
        isFetching: false,
        isUpdating: false,
        isAddingModules: false,
        isDeletingModule: false,
        list: []
    };
}

export const state = getInitialState();

export const mutations = {
    setActive (state, { resourceCenter }) {
        state.active = resourceCenter;
    },
    setActiveEditingAppId (state, { activeEditingAppId }) {
        state.activeEditingAppId = activeEditingAppId;
    },
    setResourceCenterList (state, { resourceCenterList }) {
        state.list = resourceCenterList;
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setError (state, { error }) {
        state.error = error;
    },
    setMigrateError (state, { migrateError }) {
        state.migrateError = migrateError;
    },
    setUpdating (state, { isUpdating }) {
        state.isUpdating = isUpdating;
    },
    setDeletingModule (state, { isDeletingModule }) {
        state.isDeletingModule = isDeletingModule;
    },
    setAddingModules (state, { isAddingModules }) {
        state.isAddingModules = isAddingModules;
    },
    setDraftModule (state, { updatedModule }) {
        const index = state.active.draft.modules.findIndex((module) => module.id === updatedModule.id);
        Vue.set(state.active.draft.modules, index, updatedModule);
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    async create ({ commit, dispatch, rootGetters }, { appId } = {}) {
        commit('setError', { error: null });
        commit('setUpdating', { isUpdating: true });
        const createAppId = appId || rootGetters['apps/activeId'];
        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        if (usesMultiApp) commit('setActiveEditingAppId', { activeEditingAppId: createAppId });
        const isTraining = rootGetters['subscriptions/activeIsTrainingSubscription'];
        const { platform } = rootGetters['apps/appById'](createAppId);
        const isExtensionApp = platform === 'extension';

        await dispatch('themes/fetch', { selectedAppId: createAppId }, { root: true });
        const activeTheme = rootGetters['themes/getThemeByAppId'](createAppId);
        const badgeAndFrameColor = get(activeTheme, 'buildingBlocks.primaryButton.background', '#2A2C35');

        const draftOptions = {
            appId: createAppId,
            isTraining,
            launchMethod: isExtensionApp
                ? propName(LAUNCH_METHODS, LAUNCH_METHODS.extensionIcon)
                : propName(LAUNCH_METHODS, LAUNCH_METHODS.badge),
            badgeAndFrameColor
        };

        try {
            const guide = await createDraftResourceCenter(draftOptions);
            await dispatch('guides/update', { guide }, { root: true });
            // would not typically try a GET immediately after the previous PUT
            // however, the guide object returned by the PUT call is not the RC object we need
            // this will not be so dangerous once we implement adding modules on create
            await dispatch('get');
        } catch (error) {
            commit('setError', { error });
        }
        commit('setUpdating', { isUpdating: false });
    },
    async delete ({ commit, dispatch, state }) {
        if (!state.active) return;
        const children = get(state, 'active.draft.homeView.children', []);
        const ids = [...children, state.active.id];
        commit('setError', { error: null });
        commit('setUpdating', { isUpdating: true });

        try {
            await deleteResourceCenter(state.active.id);
            dispatch('guides/removeGuidesFromMap', { guideIds: ids }, { root: true });
            dispatch('fetchAll');
            commit('setActive', { resourceCenter: null });
            commit('setUpdating', { isUpdating: false });
        } catch (error) {
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error });
        }
    },
    async get ({ commit, rootGetters, state }) {
        commit('setError', { error: null });
        commit('setFetching', { isFetching: true });

        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        const activeApp = usesMultiApp ? state.activeEditingAppId : rootGetters['apps/activeId'];
        let resourceCenter;

        if (!activeApp) resourceCenter = null;
        else resourceCenter = (await getResourceCenterByAppId(activeApp)) || null;

        if (resourceCenter) {
            const [draftContent, publicContent] = await getResourceCenterGuideStepContentForAllStates(resourceCenter);
            resourceCenter.draft = draftContent;
            resourceCenter.public = publicContent;
        }
        commit('setActive', { resourceCenter });
        commit('setFetching', { isFetching: false });
    },
    async fetchAll ({ commit, rootGetters, state }, { noCache = false } = {}) {
        if (!isEmpty(state.list) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });
        const appsWithResourceCenterAccess = rootGetters['apps/listAllWithRCAccessForActiveSubscription'];
        const resourceCenters = await Promise.all(
            appsWithResourceCenterAccess.map(async (app) => {
                return (await getResourceCenterByAppId(app.id)) || null;
            })
        );
        const activeResourceCenters = resourceCenters.filter((RC) => {
            return RC !== null;
        });

        commit('setResourceCenterList', { resourceCenterList: activeResourceCenters });
        commit('setFetching', { isFetching: false });
    },
    async updateActiveEditingAppByOriginId ({ commit, dispatch, state }, { originId }) {
        await dispatch('fetchAll');
        const activeEditingAppId = getAppIdFromOriginId(originId, state.list);
        commit('setActiveEditingAppId', { activeEditingAppId });
    },
    async toggleActiveResourceCenterState ({ commit, getters, dispatch }, { isDisabled }) {
        commit('setUpdating', { isUpdating: true });

        try {
            await disableProductionResourceCenter(getters.getActiveResourceCenter.id, isDisabled);
            dispatch('get');
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error: null });
        } catch (error) {
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error });
        }
    },
    async enableActiveResourceCenter ({ dispatch }) {
        await dispatch('toggleActiveResourceCenterState', { isDisabled: false });
    },
    async disableActiveResourceCenter ({ dispatch }) {
        await dispatch('toggleActiveResourceCenterState', { isDisabled: true });
    },
    async promoteResourceCenter ({ commit, getters, dispatch }, { state }) {
        commit('setUpdating', { isUpdating: true });

        try {
            await promoteResourceCenter(getters.getActiveResourceCenter.id, state);
            await dispatch('get');
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error: null });
        } catch (error) {
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error });
        }
    },
    async promoteResourceCenterToPublic ({ dispatch }) {
        await dispatch('fetchAndMigrateResourceCenter');
        await dispatch('promoteResourceCenter', { state: STATES.PUBLIC });
    },
    async promoteResourceCenterToDraft ({ dispatch }) {
        await dispatch('promoteResourceCenter', { state: STATES.DRAFT });
    },
    async createResourceCenterModules ({ commit, dispatch, state }, { modulesToAdd, resourceCenterId }) {
        commit('setError', { error: null });
        const draftHomeView = state.active.draft.homeView;
        let updateResourceCenter;

        try {
            commit('setAddingModules', { isAddingModules: true });
            const newModules = await Promise.all(
                modulesToAdd.map((moduleToAdd) => createModule(draftHomeView, moduleToAdd))
            );

            for (const newModule of newModules) {
                const { module, moduleConfig } = newModule;
                // eslint-disable-next-line no-await-in-loop
                updateResourceCenter = await addToResourceCenter(module, moduleConfig, resourceCenterId);
                commit('guides/addGuideToMap', { guide: module }, { root: true });
            }

            commit('setAddingModules', { isAddingModules: false });
            await dispatch('get');
            commit('guides/addGuideToMap', { guide: updateResourceCenter.draft.homeView }, { root: true });
        } catch (error) {
            commit('setAddingModules', { isAddingModules: false });
            commit('setError', { error });
        }
    },
    async saveModuleGuideList ({ commit }, { homeViewId, moduleId, guideList }) {
        commit('setError', { error: null });
        commit('setFetching', { isFetching: true });

        try {
            const resourceCenter = await saveModuleGuideList(homeViewId, moduleId, guideList);
            await commit('setActive', { resourceCenter });
            commit('setFetching', { isFetching: false });
        } catch (error) {
            commit('setFetching', { isFetching: false });
            commit('setError', { error });
        }
    },
    async importLocalizationFile ({ commit, getters, dispatch }, { langCode, file }) {
        commit('setUpdating', { isUpdating: true });
        const resourceCenter = getters.getActiveResourceCenter.draft.homeView;
        const updatedModule = cloneDeep(resourceCenter);
        let importState;

        try {
            importState = await importResourceCenterLocalizationFile(updatedModule.id, langCode, file);
            const translationStates = { [langCode]: importState };
            updatedModule.translationStates = defaultsDeep(translationStates, updatedModule.translationStates);

            await dispatch('updateModule', { updatedModule });
            commit('setUpdating', { isUpdating: false });

            return importState;
        } catch (error) {
            commit('setUpdating', { isUpdating: false });
            commit('setError', {
                error: {
                    response: {
                        data: error.response.data
                    }
                }
            });
        }
    },
    async disableDraftModule ({ getters, commit, dispatch, rootGetters }, { moduleGuideToDisable }) {
        commit('setDeletingModule', { isDeletingModule: true });
        const guideId = moduleGuideToDisable.id;
        if (!getters.hasActiveBeenPublishedBefore) {
            const guideToDelete = rootGetters['guides/getGuideById'](guideId);

            return dispatch('guides/delete', { guide: guideToDelete }, { root: true })
                .then(() => dispatch('guides/fetch', {}, { root: true }))
                .then(() => dispatch('get'))
                .finally(() => commit('setDeletingModule', { isDeletingModule: false }));
        }

        return dispatch('guides/patch', { guideId, props: { state: 'disabled' } }, { root: true })
            .then(() => {
                const updatedModule = rootGetters['guides/getGuideById'](guideId);
                commit('setDraftModule', { updatedModule });
            })
            .finally(() => commit('setDeletingModule', { isDeletingModule: false }));
    },
    async updateModuleTitle ({ commit, dispatch }, { module, name }) {
        commit('setError', { error: null });
        commit('setUpdating', { isUpdating: true });

        try {
            const updatedModule = updateModuleTitle(module, name);
            await dispatch('updateModule', { updatedModule });
            commit('setUpdating', { isUpdating: false });
        } catch (error) {
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error });
        }
    },
    async updateModuleSubtitle ({ commit, dispatch }, { homeview, module, subtitle }) {
        commit('setError', { error: null });
        commit('setUpdating', { isUpdating: true });

        try {
            const updatedModule = updateModuleSubtitle(homeview, module, subtitle);
            await dispatch('updateModule', { updatedModule });
            commit('setUpdating', { isUpdating: false });
        } catch (error) {
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error });
        }
    },
    async updateModuleIframeSrc ({ commit, dispatch }, { module, src }) {
        commit('setError', { error: null });
        commit('setUpdating', { isUpdating: true });

        try {
            const updatedModule = updateModuleIframeSrc(module, src);
            await dispatch('updateModule', { updatedModule });
            commit('setUpdating', { isUpdating: false });
        } catch (error) {
            commit('setUpdating', { isUpdating: false });
            commit('setError', { error });
        }
    },
    async updateResourceCenterAuthoredLanguage ({ commit, dispatch }, { homeViewId, authoredLanguage }) {
        commit('setError', { error: null });
        commit('setFetching', { fetching: true });

        let resourceCenter;
        try {
            resourceCenter = await updateResourceCenterAuthoredLanguage(homeViewId, authoredLanguage);
        } catch (error) {
            commit('setFetching', { fetching: false });
            commit('setError', { error });

            return;
        }
        const { homeView } = resourceCenter.draft;
        const { modules } = resourceCenter.draft;
        commit('setFetching', { fetching: false });
        commit('guides/addGuideToMap', { guide: homeView }, { root: true });

        modules.forEach((module) => {
            commit('guides/addGuideToMap', { guide: module }, { root: true });
        });

        await dispatch('get');
    },
    async clearResourceCenterTranslationStrings ({ dispatch, commit, getters }, { langCode }) {
        commit('setError', { error: null });
        commit('setFetching', { fetching: true });
        const { id: homeViewId } = getters.getActiveResourceCenter.draft.homeView;

        try {
            await clearTranslatedStrings(homeViewId, langCode);
        } catch (error) {
            commit('setFetching', { fetching: false });
            commit('setError', { error });

            return;
        }

        await dispatch('get');
    },
    async updateModule ({ dispatch, getters }, { updatedModule }) {
        const updatedModules = getters.getDraftHomeViewAndModules.map((module) =>
            (module.id !== updatedModule.id ? module : { ...updatedModule, state: STATES.DRAFT })
        );

        await dispatch('saveLocalResourceCenter', { updatedModules });
        await dispatch('fetchAndMigrateResourceCenter');
    },
    async updateModules ({ commit, dispatch }, { updatedModules }) {
        commit('setUpdating', { isUpdating: true });
        await dispatch('saveLocalResourceCenter', { updatedModules });
        await dispatch('fetchAndMigrateResourceCenter');
        commit('setUpdating', { isUpdating: false });
    },
    async saveLocalResourceCenter ({ commit, dispatch, rootGetters }, { updatedModules }) {
        const errTemplate = { error: true, message: 'Guide must have at least one step' };
        const errors = updatedModules.reduce(
            (acc, module) => (module.steps.length ? acc : acc.concat(errTemplate)),
            []
        );

        if (errors.length) {
            commit('setError', { error: errors[0] });

            return;
        }

        const updatedModulesClone = cloneDeep(updatedModules);
        let newHomeView = remove(updatedModulesClone, (module) => module.isTopLevel)[0];
        const disabledRcModuleIds = rootGetters['guides/list'].reduce((idList, guide) => {
            if (has(guide, 'attributes.resourceCenter') && guide.state === 'disabled') idList.push(guide.id);

            return idList;
        }, []);

        newHomeView.attributes.resourceCenter.children = [
            ...newHomeView.attributes.resourceCenter.children,
            ...disabledRcModuleIds
        ];

        newHomeView = updateHomeviewTemplateChildren(newHomeView, updatedModulesClone);

        let parsingIssue = false;
        const finalResourceCenterModules = [newHomeView, ...updatedModulesClone].map((module) => {
            synchronizeModulesWithHomeView(newHomeView, updatedModulesClone);
            const clonedModule = cloneDeep(module);
            let { buildingBlocks: bb } = clonedModule.steps[0];

            try {
                bb = JSON.parse(bb);
            } catch (error) {
                if (!has(bb, 'id')) {
                    parsingIssue = error;

                    return;
                }
            }

            const bbDom = BuildingBlock.buildingBlocksToDom(bb);
            BuildingBlockEditing.removeEditingIdsFromDom(bbDom);

            clonedModule.steps[0].buildingBlocks = JSON.stringify(bb);
            clonedModule.steps[0].dom = JSON.stringify(bbDom);

            return clonedModule;
        });

        if (parsingIssue) {
            commit('setError', { error: parsingIssue });

            return;
        }

        const moduleSavePromises = finalResourceCenterModules.map(async (guide) => {
            await updateModule(newHomeView.id, guide);
            await dispatch('guides/update', { guide }, { root: true });
        });

        try {
            await Promise.all(moduleSavePromises);
            await dispatch('get');
        } catch (err) {
            commit('setError', { error: err });
        }
    },
    async fetchAndMigrateResourceCenter ({ commit, getters, dispatch }) {
        commit('setMigrateError', { migrateError: null });
        commit('setUpdating', { isUpdating: true });

        try {
            const resourceCenterGuidePromises = getters.getDraftHomeViewAndModules.map((draftModule) =>
                fetchAndMigrateGuide(draftModule.id)
            );
            const migratedGuides = await Promise.all(resourceCenterGuidePromises);

            const resourceCenter = reduce(
                migratedGuides,
                (acc, guide) => {
                    if (guide.attributes.resourceCenter.isTopLevel) {
                        return {
                            ...acc,
                            homeView: guide
                        };
                    }

                    acc.modules.push(guide);

                    return acc;
                },
                { homeView: {}, modules: [] }
            );

            const orderedLocalModules = resourceCenter.homeView.attributes.resourceCenter.children.map((childId) =>
                resourceCenter.modules.find((module) => module.id === childId)
            );

            const filteredOrderedLocalModules = orderedLocalModules.filter((module) => !!module);
            const localModules = [
                { ...resourceCenter.homeView, hasResourceCenterContent: true },
                ...filteredOrderedLocalModules
            ];

            await dispatch('saveLocalResourceCenter', { updatedModules: localModules });

            commit('setUpdating', { isUpdating: false });
            commit('setMigrateError', { migrateError: null });
        } catch (migrateError) {
            commit('setUpdating', { isUpdating: false });
            commit('setMigrateError', { migrateError });
        }
    },
    async updateModuleSegment ({ commit, dispatch }, { homeViewId, payload }) {
        commit('setError', { error: null });
        commit('setFetching', { isFetching: true });

        try {
            await updateModuleSegment(homeViewId, payload);
            await dispatch('get');
            commit('setFetching', { isFetching: false });
        } catch (error) {
            commit('setFetching', { isFetching: false });
            commit('setError', { error });
        }
    }
};

export const getters = {
    getActiveResourceCenter: (state) => state.active,
    getPublicHomeViewAndModules: (state, getters) =>
        (getters.hasActiveBeenPublishedBefore
            ? [{ ...state.active.public.homeView, hasResourceCenterContent: true }, ...state.active.public.modules]
            : []),
    getDraftHomeViewAndModules: (state, getters) => [
        { ...state.active.draft.homeView, hasResourceCenterContent: true },
        ...getters.getDraftModules
    ],
    getLatestHomeviewAndModules: (state, getters) =>
        (getters.hasActiveBeenPublishedBefore ? getters.getPublicHomeViewAndModules : getters.getDraftHomeViewAndModules),
    isActiveResourceCenterPublishable: (state, getters) => getters.getDraftModules.length > 0,
    hasActiveBeenPublishedBefore: (state) => !isNil(state.active.public),
    getChildGuides: (state, getters, rootState) => ({ parentGuide }) => {
        return parentGuide.attributes.resourceCenter.children.reduce((acc, guideId) => {
            const { state: parentState } = parentGuide;
            const rc = state.active[parentState];
            if (!rc) return acc;
            const module = rc.modules.find((module) => module.id === guideId);
            const { steps: activeSteps } = module || { steps: [] };
            const guide = rootState.guides.map[guideId];
            if (!guide) return acc;

            const steps = guide.steps.map((step, i) => ({ ...activeSteps[i], ...step }));

            return acc.concat({ ...guide, steps });
        }, []);
    },
    isActiveRCPublic: (state) => !get(state, 'active.public.isDisabled', true),
    getPublicModules: (state) => get(state, 'active.public.modules', []),
    getDraftModules: (state) => get(state, 'active.draft.modules', []),
    draftResourceCenterList: (state) => {
        return get(state, 'list', []).map((rc) => get(rc, 'draft', {}));
    },
    draftResourceCenterMap: (state, getters) => {
        return keyBy(getters.draftResourceCenterList, 'homeView.appId');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
