<template>
    <div>
        <div
            v-if="loading"
            class="loading">
            <pendo-loading-indicator size="large" />
        </div>
        <div
            v-if="!loading"
            class="license-dashboard">
            <template v-if="activeSubHasAnyLicenses">
                <global-filters />
                <div class="license-dashboard--grid">
                    <div class="license-dashboard--row">
                        <license-usage-chart
                            :license-usage-chart-agg-data="licenseUsageChartAggData"
                            :date-range="dateRange"
                            :apps-for-app-id-filter="appsForAppIdFilter"
                            :app-map-for-active-subscription="appMapForActiveSubscription"
                            :loading="licenseUsageChartAggLoading"
                            :period-options="periodOptions"
                            :selected-period="selectedPeriod"
                            @periodChange="changeSelectedPeriod($event)" />
                    </div>
                    <div class="license-dashboard--row">
                        <license-usage-table />
                    </div>
                </div>
            </template>
            <empty-state
                v-else
                :is-admin="isAdmin" />
        </div>
    </div>
</template>
<script>
import EmptyState from '@/stateless-components/analytics/licenses/EmptyState';
import GlobalFilters from '@/components/filters/GlobalFilters';
import LicenseUsageTable from './LicenseUsageTable';
import { mapActions, mapState, mapGetters } from 'vuex';
import LicenseUsageChart from '@/stateless-components/analytics/licenses/LicenseUsageChart';
import { PendoLoadingIndicator } from '@pendo/components';
import { isCancel } from 'axios';
import { getLicenseTimeSeries } from '@/aggregations/license-utilization';
import get from 'lodash/get';
import { getTimeSeriesByPeriod, validPeriodsForCount } from '@/utils/time-series';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';

export default {
    name: 'LicensesDashboard',
    components: {
        EmptyState,
        GlobalFilters,
        LicenseUsageTable,
        LicenseUsageChart,
        PendoLoadingIndicator
    },
    data () {
        return {
            aggCancel: null,
            selectedPeriod: null,
            licenseUsageChartAggData: [],
            licenseUsageChartAggLoading: false
        };
    },
    computed: {
        ...mapGetters({
            appMapForActiveSubscription: 'apps/appMapForActiveSubscription',
            appsForAppIdFilter: 'filters/appsForAppIdFilter',
            isAdmin: 'auth/isAdmin'
        }),
        ...mapState({
            activeSubHasAnyLicenses: (state) => state.licenses.activeSubHasAnyLicenses,
            loading: (state) => state.licenses.isFetchingLicenseDataMap,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            dateRange: (state) => state.filters.dateRange,
            licensesMap: (state) => state.licenses.licenseDataMap
        }),
        periodOptions () {
            const periods = validPeriodsForCount(this.dateRange.count);

            return periods.map((id) => {
                let label;
                switch (id) {
                    case 'hourly':
                        label = 'Hour';
                        break;
                    case 'daily':
                        label = 'Day';
                        break;
                    case 'weekly':
                        label = 'Week';
                        break;
                    case 'monthly':
                        label = 'Month';
                        break;
                }

                return {
                    label,
                    id
                };
            });
        }
    },
    async created () {
        await this.changeSelectedPeriod();
        await this.loadLicenseDataMap();
        await this.loadMetadata();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.changeSelectedPeriod(this.selectedPeriod);
        });
        await this.fetchLicenseUsageChartAggData();
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapActions({
            loadLicenseDataMap: 'licenses/loadLicenseDataMap',
            loadMetadata: 'metadata/loadAll'
        }),
        async changeSelectedPeriod (selectedPeriod) {
            const selectedPeriodId = get(selectedPeriod, 'id');
            const validPeriodOption = this.periodOptions.find(
                (selectedPeriod) => selectedPeriod.id === selectedPeriodId
            );
            const timePeriod = validPeriodOption || this.periodOptions[this.periodOptions.length - 1];

            this.selectedPeriod = timePeriod;
            this.fetchLicenseUsageChartAggData();
        },
        async fetchLicenseUsageChartAggData () {
            this.licenseUsageChartAggLoading = true;
            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();
            const period = get(this.selectedPeriod, 'id');

            try {
                const { activeSegmentId: segmentId, appIdsFilter: appId, dateRange } = this;
                const appUsage = await getLicenseTimeSeries({
                    appId,
                    timeSeries: getTimeSeriesByPeriod(period, dateRange),
                    segmentId,
                    signal: this.aggCancel.signal
                });

                this.licenseUsageChartAggData = appUsage.map((data) => {
                    this.appsForAppIdFilter.forEach((app) => {
                        const exists = data.rows.find((a) => a.appId === app.id);
                        if (!exists) {
                            data.rows.push({
                                appId: app.id,
                                numVisitors: 0
                            });
                        }
                    });
                    data.rows.map((app) => {
                        app.licenses = get(this, `licensesMap.${app.appId}.quantity`);
                        app.licenseUsage = (app.numVisitors / app.licenses) * 100;
                        app.name = get(this.appMapForActiveSubscription, `${app.appId}.displayName`, '');
                        app.timestamp = data.timestamp;
                        app.color = get(this.appMapForActiveSubscription, `${app.appId}.color`, null);

                        return app;
                    });

                    return data.rows;
                });
                if (this.licenseUsageChartAggData.length) {
                    this.licenseUsageChartAggLoading = false;
                }
            } catch (err) {
                if (!isCancel(err)) {
                    this.loading = false;
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.license-dashboard {
    height: auto;
    overflow: auto;

    .license-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .license-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
    }
}

.filters {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 10px;

    &--label,
    &--item {
        margin-right: 16px;
        font-size: 14px;
        line-height: 20px;

        &:last-of-type {
            margin-right: unset;
        }
    }
}

.loading {
    width: 100vw;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
