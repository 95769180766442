import { normalizeUrl } from '@/utils/utils';
import { isValidUrl } from '@pendo/services/Formatters';
import { SuggestedPagesLoadEventsAggregation } from '@pendo/tagging';
import { PRODUCT_AREAS, request, SUB_PRODUCT_AREAS } from '@/utils/aggregations';
import { http } from '@pendo/http';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import capitalize from 'lodash/capitalize';

export function filterFnPageByUrlMatch (page, searchString) {
    return page.rules.find((rule) => {
        return RegExp(rule.parsedRule).test(searchString);
    });
}

export function filterPagesByNameOrUrl (pages, searchString) {
    const formattedSearchString = searchString.toLowerCase();

    if (isValidUrl(searchString)) {
        return pages.filter((page) => filterFnPageByUrlMatch(page, formattedSearchString));
    }

    return pages.filter((page) => {
        return page.displayName.toLowerCase().includes(formattedSearchString);
    });
}

export async function normalizeValue (value) {
    value = value.trim();
    if (!isValidUrl(value)) {
        return value;
    }

    return normalizeUrl(value);
}

export function convertSubscriptionPageRules (subscriptionPageRules) {
    const convertedArray = subscriptionPageRules.map(({ appId, uniqueRules }) => {
        const convertedUniqueRules = uniqueRules.map((rule) =>
            rule
                .replace(/\*parameter\*$/, '**')
                .replace(/\*parameter\*\?/, '**?')
                .replace(/\*parameter\*/, '*')
        );

        return {
            appId,
            uniqueRules: convertedUniqueRules
        };
    });

    return convertedArray;
}

export async function fetchPageLoadEvents (aggParams) {
    const spec = {
        name: 'page-load-events',
        productArea: PRODUCT_AREAS.TAGGING_AND_SETUP,
        subProductArea: SUB_PRODUCT_AREAS.SUGGESTED_PAGES,
        pipeline: SuggestedPagesLoadEventsAggregation.build(aggParams)
    };

    return request(spec);
}

export async function fetchSubscriptionPageRules () {
    return http.get('/api/s/_SID_/page/uniqueRules').then((res) => res.data);
}

export function fetchSuggestedPageRulesHandledList () {
    return http.get('/api/s/_SID_/suggestedruleselection').then((res) => res.data);
}

export function generateSuggestedPageRules (event) {
    let rule;
    let name;
    let length;
    let sampleCount = 5;

    switch (event.suggestedRuleType) {
        case 'fragment':
            rule = `//*/**#${get(event, 'parseUrl.fragment', '')}`;
            name = capitalize(get(event, 'parseUrl.fragment', ''));
            break;
        case 'pathFragments':
            length = pathFragmentLength(get(event, 'samples[0]', ''));
            // using array constructor + join to get correct number of */ in rule
            rule = `//*/**#!${new Array(event.itemIndex + 1).join('*/')}${get(event, 'parseUrl.pathFragments', '')}${
                event.itemIndex < length - 1 ? '/**' : ''
            }`;
            name = capitalize(get(event, 'parseUrl.pathFragments', ''));
            sampleCount = event.itemIndex === length - 1 ? 1 : 5;
            break;
        case 'paths':
            length = pathLength(get(event, 'samples[0]', ''));
            // using array constructor + join to get correct number of /* in rule
            rule = `//*${new Array(event.itemIndex + 1).join('/*')}/${event.parseUrl.path}${
                event.itemIndex < length - 1 ? '/**' : ''
            }`;
            // if the path is the last and we are collecting document_title we should use that for name
            name =
                event.itemIndex === length - 1 && !isNil(event.document_title)
                    ? get(event, 'document_title')
                    : capitalize(get(event, 'parseUrl.path'));
            sampleCount = event.itemIndex === length - 1 ? 1 : 5;
            break;
        case 'queryFragments':
            // fieldFragmentVals are decoded so we need to encode again for the rule
            rule = `//*/**#!/**?${event.fieldFragmentKeys}=${encodeURIComponent(event.fieldFragmentVals)}`;
            name = event.fieldFragmentVals || event.fieldFragmentKeys;
            break;
        case 'queryParameters':
            // fieldVals are decoded so we need to encode again for the rule
            rule = `//*/**?${event.fieldKeys}=${encodeURIComponent(event.fieldVals)}`;
            name = event.fieldVals || event.fieldKeys;
            break;
        default:
            rule = '';
            name = '';
            break;
    }

    return {
        rule,
        name,
        sampleCount
    };
}

export function pathFragmentLength (sample) {
    const regexPathFragment = /^.*#!(.*$)/;
    const pathFragmentURL = sample.match(regexPathFragment)[1];
    const length = pathFragmentURL.split('/').length - 1;

    return length;
}

export function pathLength (sample) {
    const path = sample.split('//')[1].split('/');
    const length = path.length - 1;

    return length;
}
