<template>
    <pendo-form :model="workingWorkflow">
        <div class="workflows-query-container">
            <query-builder-step
                v-for="stepType of stepTypes"
                :key="stepType"
                :app-value="getSelectedApp(stepType)"
                :countable-options="getDropdownOptions(stepType)"
                :countable-value="getSelectedCountable(stepType)"
                :get-apps-with-analytics="getAppsWithAnalytics"
                :is-loading="isLoading"
                :rules="rules[stepType]"
                :step-type="stepType"
                @appSelected="onAppSelected(stepType, $event)"
                @countableSelected="onCountableSelected(stepType, $event.id, $event.kind)" />

            <query-builder-options
                :can-edit-visibility="canEditVisibility"
                :is-loading="isLoading"
                :is-read-only="isReadOnly"
                :recurring-options="recurringOptions"
                :selected-recurring-option="selectedRecurringOption"
                :selected-visibility-option="selectedVisibilityOption"
                :visibility-options="visibilityOptions"
                @recurringSelected="onFieldUpdated('classification', $event.id)"
                @visibilitySelected="onFieldUpdated('subscriptionSharing.permission', $event.id)" />

            <query-builder-advanced-options
                :is-loading="isLoading"
                :max-duration-options="maxDurationOptions"
                :max-duration-tooltip-text="maxDurationTooltipText"
                :selected-max-duration-option="selectedMaxDurationOption"
                @durationSelected="
                    onFieldUpdated('maxDuration', $event.id);
                    onFieldUpdated('maxInterval', $event.id);
                " />
        </div>

        <hr
            v-if="!isReadOnly"
            class="workflows-query-divider">

        <div class="workflows-query-actions">
            <pendo-button
                theme="app"
                type="tertiary"
                label="Cancel"
                @click="onCancel" />
            <pendo-button
                v-if="!isReadOnly"
                data-cy="workflows-details-save-button"
                :disabled="saveDisabled"
                label="Save"
                theme="app"
                type="primary"
                @click="onSaveClicked" />
        </div>
    </pendo-form>
</template>

<script>
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { maxDurationOptions } from '@/utils/workflows';
import { PendoButton, PendoForm, PendoTooltip } from '@pendo/components';
import QueryBuilderAdvancedOptions from '@/stateless-components/workflows/details/query-builder/QueryBuilderAdvancedOptions.vue';
import QueryBuilderOptions from '@/stateless-components/workflows/details/query-builder/QueryBuilderOptions';
import QueryBuilderStep from '@/stateless-components/workflows/details/query-builder/QueryBuilderStep';

const SAVE_CLICKED_EVENT_NAME = 'saveClicked';

export default {
    name: 'WorkflowsQueryBuilder',
    components: {
        PendoButton,
        PendoForm,
        QueryBuilderStep,
        QueryBuilderOptions,
        QueryBuilderAdvancedOptions
    },
    directives: {
        PendoTooltip
    },
    data () {
        return {
            appSelected: {
                end: null,
                start: null
            },
            maxDurationOptions,
            maxDurationTooltipText:
                'The workflow is considered incomplete if a visitor doesn’t finish both the starting and ending events within this time period.',
            recurringOptions: [
                {
                    label: 'Yes',
                    id: 'recurring',
                    icon: {
                        type: 'repeat'
                    }
                },
                {
                    label: 'No',
                    id: 'nonrecurring',
                    icon: {
                        type: 'no-repeat'
                    }
                }
            ],
            rules: {
                end: [
                    {
                        required: true,
                        message: 'Workflow End is required',
                        trigger: ['change']
                    }
                ],
                start: [
                    {
                        required: true,
                        message: 'Workflow Start is required',
                        trigger: ['change']
                    }
                ]
            },
            stepTypes: ['start', 'end'],
            visibilityOptions: [
                {
                    icon: {
                        type: 'user'
                    },
                    id: 'restricted',
                    label: 'Only Me'
                },
                {
                    icon: {
                        type: 'users'
                    },
                    id: 'edit',
                    label: 'Everyone'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            features: 'features/list',
            getAppById: 'apps/appById',
            getAppsWithAnalytics: 'apps/listAllWithAnalyticsAccess',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            isLoading: 'workflows/isLoading',
            isReadOnly: 'auth/isReadOnly',
            pages: 'pages/list',
            savedWorkflow: 'workflows/savedWorkflow'
        }),
        ...mapState({
            activeUserId: (state) => state.auth.user.id,
            featuresMap: (state) => state.features.map,
            pagesMap: (state) => state.pages.map,
            workingWorkflow: (state) => state.workflows.workingWorkflow
        }),
        canEditVisibility () {
            return get(this.workingWorkflow, 'createdByUser.id', this.activeUserId) === this.activeUserId;
        },
        saveDisabled () {
            return this.isLoading || !this.workingWorkflowValid || !this.workingWorkflowHasChanged;
        },
        selectedMaxDurationOption () {
            const existingWorkflowDuration = this.workingWorkflow.maxDuration;

            const duration = this.maxDurationOptions.flatMap((opt) => opt.options);

            return duration.find((option) => option.id === existingWorkflowDuration);
        },
        selectedVisibilityOption () {
            const permission = get(this.workingWorkflow.subscriptionSharing, 'permission', null);
            if (!permission) return this.visibilityOptions[1];

            return this.visibilityOptions.find((option) => option.id === permission);
        },
        selectedRecurringOption () {
            const classification = get(this.workingWorkflow, 'classification', null);
            if (!classification) return this.recurringOptions[1];

            return this.recurringOptions.find((option) => option.id === classification);
        },
        workingWorkflowValid () {
            return !!this.workingWorkflowSteps.start && !!this.workingWorkflowSteps.end;
        },
        workingWorkflowHasChanged () {
            const { savedWorkflow, workingWorkflow } = this;

            if (!savedWorkflow || !workingWorkflow) {
                return true;
            }
            const hasClassificationChanged = !isEqual(savedWorkflow.classification, workingWorkflow.classification);
            const hasMaxDurationChanged = !isEqual(savedWorkflow.maxDuration, workingWorkflow.maxDuration);

            const savedWorkflowSteps = get(savedWorkflow, 'workflowSteps', []).reduce(
                (result, step) => {
                    result[step.type] = step.countableId;

                    return result;
                },
                {
                    start: null,
                    end: null
                }
            );

            const hasEndStepChanged = !isEqual(savedWorkflowSteps.end, this.workingWorkflowSteps.end);
            const hasStartStepChanged = !isEqual(savedWorkflowSteps.start, this.workingWorkflowSteps.start);

            const hasVisibilityChanged = !isEqual(
                get(savedWorkflow.subscriptionSharing, 'permission'),
                get(workingWorkflow.subscriptionSharing, 'permission')
            );

            return (
                hasClassificationChanged ||
                hasMaxDurationChanged ||
                hasEndStepChanged ||
                hasStartStepChanged ||
                hasVisibilityChanged
            );
        },
        workingWorkflowSteps () {
            return get(this.workingWorkflow, 'workflowSteps', []).reduce(
                (result, step) => {
                    result[step.type] = step.countableId;

                    return result;
                },
                {
                    start: null,
                    end: null
                }
            );
        }
    },
    methods: {
        ...mapMutations({
            setWorkingWorkflow: 'workflows/setWorkingWorkflow',
            setWorkingWorkflowField: 'workflows/setWorkingWorkflowField'
        }),
        getDropdownGroup (kind, countables, disabledCountable) {
            const label = `${capitalize(kind)}s`;
            const id = label.toLowerCase();

            return {
                id,
                label,
                options: countables.map((countable) => {
                    return {
                        appId: countable.appId,
                        disabled: countable.id === disabledCountable,
                        icon: {
                            type: kind
                        },
                        id: countable.id,
                        kind: capitalize(kind),
                        label: countable.displayName || countable.name
                    };
                })
            };
        },
        getDropdownOptions (field) {
            const app = this.getSelectedApp(field);
            if (!app) return [];

            const options = [];
            const oppositeField = field === 'start' ? 'end' : 'start';
            const disabledCountable = get(
                this.workingWorkflow.workflowSteps.find((step) => step.type === oppositeField),
                'countableId',
                null
            );

            const filteredPages = this.pages.filter((page) => page.appId === app.id);
            const filteredFeatures = this.features.filter((feature) => feature.appId === app.id);

            if (filteredPages.length !== 0) {
                options.push(this.getDropdownGroup('page', filteredPages, disabledCountable));
            }

            if (filteredFeatures.length !== 0) {
                options.push(this.getDropdownGroup('feature', filteredFeatures, disabledCountable));
            }

            return options;
        },
        getSelectedApp (field) {
            if (this.appSelected[field]) return this.appSelected[field];
            const { workflowSteps } = this.workingWorkflow;
            if (!workflowSteps) return null;

            const step = workflowSteps.find((step) => step.type === field);
            if (!step) return null;

            const countableMap = this[`${step.countableKind.toLowerCase()}sMap`];
            // 'invalidCountableId' is set in WorkflowsDetails
            const countable = countableMap[step.countableId] || countableMap[step.invalidCountableId];

            if (!countable) return null;

            return this.getAppsWithAnalytics.find((app) => app.id === countable.appId);
        },
        getSelectedCountable (field) {
            const { workflowSteps } = this.workingWorkflow;
            const step = workflowSteps.find((step) => step.type === field);

            const countableId = get(step, 'countableId', null);
            if (!countableId) return null;

            const countableKind = get(step, 'countableKind', null);
            if (!countableKind) return null;

            const group = this.getDropdownOptions(field).find(
                (group) => group.id === `${countableKind.toLowerCase()}s`
            );
            if (!group) return null;

            const matchedOption = group.options.find((option) => option.id === countableId);
            if (matchedOption) return matchedOption;

            return null;
        },
        onAppSelected (field, app) {
            this.appSelected[field] = app;
            const workflowSteps = cloneDeep(this.workingWorkflow.workflowSteps);

            const stepIndex = workflowSteps.findIndex((step) => step.type === field);
            if (stepIndex !== -1) {
                workflowSteps.splice(stepIndex, 1);
            }
            this.setWorkingWorkflowField({ field: 'workflowSteps', value: workflowSteps });
        },
        onSaveClicked () {
            this.$emit(SAVE_CLICKED_EVENT_NAME);
        },
        onCancel () {
            const workflow = cloneDeep(this.savedWorkflow);
            if (workflow) this.setWorkingWorkflow({ workflow });
            this.$emit('cancel');
        },
        onCountableSelected (field, countableId, countableKind) {
            this.appSelected[field] = null;
            const workflowSteps = cloneDeep(this.workingWorkflow.workflowSteps);
            const stepIndex = workflowSteps.findIndex((step) => step.type === field);
            if (stepIndex === -1) {
                workflowSteps.push({
                    countableId,
                    countableKind,
                    type: field
                });
            } else {
                workflowSteps[stepIndex].countableId = countableId;
                workflowSteps[stepIndex].countableKind = countableKind;
            }
            this.setWorkingWorkflowField({ field: 'workflowSteps', value: workflowSteps });
        },
        onFieldUpdated (field, value) {
            this.setWorkingWorkflowField({ field, value });
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-query {
    &-container {
        display: flex;
        flex-direction: column;
        padding: 10px 9px;

        > *:not(:last-child) {
            margin-bottom: 14px;
        }
    }

    &-divider {
        border: 1px solid #dadce5;
        margin: 9px 0 24px;
    }

    &-actions {
        display: flex;
        justify-content: flex-end;

        > :first-child {
            margin-right: 8px;
        }
    }
}
</style>
