var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('pendo-multiselect',{ref:"segmentChooser",staticClass:"segment-filter",attrs:{"value":_vm.activeSegment,"options":_vm.segmentsList,"disabled":_vm.disabled,"full-width":_vm.fullWidth,"max-menu-height":370,"label-key":"name"},on:{"select":_vm.onSegmentChange},scopedSlots:_vm._u([{key:"selectedLabel",fn:function({ selectedLabel }){return [_c('div',{staticClass:"segment-filter--label"},[_c('pendo-icon',{attrs:{"type":"user","size":"14"}}),_c('span',[_vm._v(" "+_vm._s(selectedLabel)+" ")])],1)]}},{key:"optionGroup",fn:function({ option }){return [_c('span',{staticClass:"segment-filter--group-label"},[_vm._v(" "+_vm._s(option.label)+" ")])]}},{key:"option",fn:function({ option }){return [_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                    arrow: true,
                    placement: 'top',
                    hideOnTargetClick: false,
                    content: _vm.getTooltipContent(option)
                }),expression:"{\n                    arrow: true,\n                    placement: 'top',\n                    hideOnTargetClick: false,\n                    content: getTooltipContent(option)\n                }"}],staticClass:"segment-filter--option",class:{
                    'segment-filter--broken-segment': option.broken,
                    'segment-filter--disabled-segment': option.disabledWithReason,
                    'custom-segment': !_vm.immutableSegmentIdsMap[option.id]
                }},[_c('span',{staticClass:"segment-filter--option--name"},[_vm._v(" "+_vm._s(option.name)+" ")]),(!_vm.immutableSegmentIdsMap[option.id])?_c('span',{staticClass:"segment-filter--option--actions"},[_c('pendo-icon-button',{staticClass:"segment-actions--edit",attrs:{"icon":"edit-2","disabled":!!option.createdByApi,"tooltip":option.createdByApi ? 'You must use the API to edit API-created segments' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.editSegment({ id: option.id })}}}),_c('pendo-button',{staticClass:"segment-actions--delete",class:{ 'active-segment--delete': _vm.activeSegment.id === option.id },attrs:{"icon":"trash-2","size":"mini","type":"tertiary","theme":"app"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteSegment({ id: option.id })}}})],1):_vm._e()])]}},{key:"footer",fn:function(){return [_c('footer',{staticClass:"segment-filter--footer",attrs:{"data-cy":"segment-filter--toggle"},on:{"click":_vm.openSegmentBuilder}},[_c('pendo-icon',{attrs:{"inline":true,"size":"14","type":"plus"}}),_c('span',{staticClass:"segment-filter--footer--toggle-text"},[_vm._v(" Custom Segment ")])],1)]},proxy:true}])}),(_vm.showSegmentBuilder)?_c('app-segment-builder',{attrs:{"editing-segment":_vm.editingSegment,"limit-for-guide-usage":_vm.guideTargeting},on:{"close":_vm.closeSegmentBuilder,"save":_vm.onSegmentChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }