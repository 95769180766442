import highcharts from 'highcharts';
import moment from 'moment-timezone';
import { CHART_BASE } from '@/utils/highcharts';
import sanitizeHTML from 'sanitize-html';

export const $pendo = { moment, highcharts, sanitize: sanitizeHTML };

const pendoGlobalsPlugin = {
    install: (Vue) => {
        Vue.prototype.$pendo = $pendo;
        Vue.prototype.$pendo.highcharts.setOptions(CHART_BASE);
    }
};

export default pendoGlobalsPlugin;
