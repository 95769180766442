/*
 * This file will move to shared-services to avoid duplicating code in
 * appengine and via-ui repos. Will move after pendo-io/shared-services#804 merges. APP-50803.
 */
import { formatValueForSchema } from '@/utils/formatters';
import { v4 as uuid } from 'uuid';
import clamp from 'lodash/clamp';
import has from 'lodash/has';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import { encodeIdForUri } from '@/utils/utils';
import store from '@/state/store';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
/* eslint-disable id-length */

export function applySchema (name, { schema, pollId, polls }) {
    const goFormat = {
        date: '2006-01-02',
        time: '2006-01-02 15:04:05'
    };
    let transformedSchema = '';

    switch (schema.toLowerCase()) {
        case 'date':
        case 'time':
            transformedSchema = {
                [name]: `if(${name}==0||isNull(${name}),"",formatTime("${goFormat[schema]}",${name}))`
            };
            break;
        case 'prettytime':
            transformedSchema = { [name]: `if(${name}==0,"--",${name}/1000)` };
            break;
        case 'number':
            transformedSchema = { [name]: `if(isNil(${name}), 0, ${name})` };
            break;
        case 'poll': {
            if (polls && pollId) {
                const poll = find(polls, ['id', pollId]);
                const idResponses = poll && poll.idResponses;
                if (idResponses) {
                    const PLACEHOLDER = '{ELSE}';
                    let idResponseMap = PLACEHOLDER;
                    forEach(idResponses, (idResponse, id) => {
                        idResponseMap = idResponseMap.replace(
                            PLACEHOLDER,
                            `if(${name}=="${id}","${idResponse}",${PLACEHOLDER})`
                        );
                    });

                    idResponseMap = idResponseMap.replace(PLACEHOLDER, name);
                    transformedSchema = { [name]: idResponseMap };
                    break;
                }
            }
        }
        // eslint-disable-line no-fallthrough
        default:
            transformedSchema = name;
            break;
    }

    return transformedSchema;
}

export function getSelectedFields ({ columns = [], type, isCsv = false, isCrossApp = false }) {
    const columnKeys = columns.map((column) => {
        if (isCsv || column.schema === 'number') {
            return applySchema(column.prop, column);
        }

        return column.id;
    });
    const browserTime = isCsv ? applySchema('browserTime', { schema: 'time' }) : 'browserTime';
    const appFields = isCrossApp ? ['stepApp'] : [];

    if (type === 'byVisitor') {
        const totalDuration = isCsv ? applySchema('totalDuration', { schema: 'prettyTime' }) : 'totalDuration';

        return [browserTime, 'numSeen', 's', 'stepStatus', 'stepsSeen', totalDuration, 'visitorId', ...columnKeys];
    }

    return [
        browserTime,
        'guideId',
        'guideStepId',
        'stepIndex',
        'stepStatus',
        'type',
        'visitorId',
        ...appFields,
        ...columnKeys
    ];
}

export function getJoinKeysForAggregation (columns) {
    const addedColumns = getAddedColumnsForAggregation(columns);
    const keys = addedColumns.map((column) => `${column.kind}Id`).concat('visitorId');

    return [...new Set([...keys])];
}

export function getAddedColumnsForAggregation (columns) {
    return columns
        .filter((column) => column.prop && !column.immutable)
        .map((column) => {
            // connect.js throws error if formatter is passed here
            const { poll, formatter, ...rest } = column;

            let functionName = 'metadataField';
            if (column.fact) {
                functionName = ['daysActive', 'eventCount', 'eventTime', 'guide'].includes(column.type)
                    ? column.fact
                    : `${column.type}_${column.fact}`;
            }

            if (poll) {
                functionName = 'pollResponse';
                rest.idResponses = poll.idResponses;
                rest.type = poll.attributes.type;
                rest.numericResponses = poll.numericResponses;
            }

            return {
                ...rest,
                fieldName: column.field,
                primaryKeys: ['visitorId'],
                columnLabel: column.label,
                columnName: column.prop,
                functionName
            };
        });
}

export function getTableColumns ({ guide, columns = [], type }) {
    const baseColumns = type === 'byVisitor' ? baseByVisitorColumns(guide) : baseByEventColumns(guide);
    const userColumns = columns.map((column) => column && generateColumnConfig(column));

    return [...baseColumns, ...userColumns];
}

export function generateColumnConfig (column) {
    return {
        ...column,
        prop: column.prop,
        label: column.label,
        sortable: true,
        allowReorder: true,
        width: column.schema === 'time' ? 260 : undefined,
        formatter: (row) => {
            if (!has(row, column.prop)) return null;

            return formatValueForSchema(row[column.prop], column.schema);
        }
    };
}

export function baseByVisitorColumns ({ isMultiApp, isMultiStep, steps }) {
    const progressWidth = isMultiApp ? clamp(steps.length * 30 + 150, 180, 300) : clamp(steps.length * 30, 160, 300);

    return [
        {
            type: 'tree',
            fixed: true,
            allowReorder: false
        },
        {
            prop: 'visitorId',
            fixed: true,
            width: 256,
            type: 'link',
            href: (row) => `/analytics/visitors/${encodeURIComponent(encodeIdForUri(row.visitorId))}`,
            target: '_blank',
            label: 'Visitor ID',
            immutable: true,
            sortable: 'custom'
        },
        !isMultiStep && {
            prop: 'numSeen',
            width: 172,
            label: 'Number of Views',
            immutable: true,
            schema: 'number',
            sortable: 'custom',
            formatter: (row) => formatValueForSchema(row.numSeen, 'number')
        },
        isMultiStep && {
            prop: 'stepsSeen',
            schema: 'progress',
            width: progressWidth,
            label: 'Progress',
            immutable: true,
            sortable: 'custom'
        },
        {
            prop: 'totalDuration',
            label: 'Time on Guide',
            csvLabel: 'Time on Guide (s)',
            schema: 'prettyTime',
            width: 148,
            immutable: true,
            sortable: 'custom',
            formatter: (row) => {
                const SECOND = 1000;
                const MINUTE = 60 * SECOND;
                const { totalDuration } = row;
                if (totalDuration > SECOND && totalDuration < MINUTE) {
                    return `${(totalDuration / SECOND).toFixed(1)}s`;
                }

                return formatValueForSchema(totalDuration, 'prettyTime');
            }
        },
        {
            prop: 'stepStatus',
            label: 'Status',
            csvLabel: 'Last Action Status',
            schema: 'string',
            immutable: true,
            width: 180,
            sortable: 'custom',
            formatter: (row) => row.stepStatus || '--'
        },
        {
            prop: 'browserTime',
            label: 'Date',
            csvLabel: 'Last Action Date',
            schema: 'time',
            minWidth: 260,
            immutable: true,
            sortable: 'custom',
            formatter: (row) => formatValueForSchema(row.browserTime, 'time')
        }
    ].filter(Boolean);
}

export function baseByEventColumns ({ isMultiStep, isMultiApp }) {
    return [
        {
            prop: 'visitorId',
            fixed: true,
            width: 256,
            type: 'link',
            // The following URL is different in Adopt vs Engage, which should be considered when porting to shared-services
            href: (row) => `/analytics/visitors/${encodeURIComponent(encodeIdForUri(row.visitorId))}`,
            target: '_blank',
            label: 'Visitor ID',
            schema: 'string',
            sortable: 'custom',
            immutable: true
        },
        isMultiApp && {
            prop: 'stepApp',
            label: 'Application',
            width: 180,
            sortable: true,
            immutable: true
        },
        isMultiStep && {
            prop: 'stepIndex',
            label: 'Step',
            formatter: (row) => getStepNumber(row.stepIndex),
            immutable: true,
            schema: 'string',
            width: 160,
            sortable: 'custom'
        },
        {
            prop: 'stepStatus',
            label: 'Status',
            sortable: 'custom',
            schema: 'string',
            immutable: true,
            width: 180,
            formatter: (row) => row.stepStatus || '--'
        },
        {
            prop: 'browserTime',
            label: 'Date',
            schema: 'time',
            minWidth: 260,
            sortable: 'custom',
            immutable: true,
            formatter: (row) => formatValueForSchema(row.browserTime, 'time')
        }
    ].filter(Boolean);
}

export function processActivityByEventResponse (rows, guide) {
    return rows.map((row) => {
        row.id = uuid();
        row.height = 56;

        const step = guide.steps[row.stepIndex - 1];
        row.stepApp = store.getters['apps/appById'](step.appIds[0]);

        return row;
    });
}

export function processActivityByVisitorResponse (rows, guide) {
    let stepApps;
    let stepAppNames;
    if (isCrossApp(guide)) {
        stepApps = guide.steps.map((step) => store.getters['apps/appById'](step.appIds[0]));
        stepAppNames = guide.steps.map((step) => store.getters['apps/appNameFromId'](step.appIds[0]));
    }

    return rows.map((row) => {
        row.s = row.s || [];
        row.s = row.s.map((seq) => (has(seq, 'AggregationRow') ? seq.AggregationRow : seq));
        row.id = uuid();
        row.height = 56;

        row.s = row.s.map((event) => {
            event.id = uuid();
            delete event.visitorId;
            delete event.s;
            if (event.type === 'guideSeen') {
                event.numSeen = 1;
            }
            event.totalDuration = event.duration;
            event.height = 40;

            if (event.appId) {
                event.stepApp = stepApps.find((app) => app.id === event.appId);
            }

            return event;
        });

        if (isCrossApp(guide)) {
            row.stepAppNames = stepAppNames;
        }

        if (guide.isMultiStep) {
            row.totalSteps = guide.steps.length;
        }

        return row;
    });
}

function getStepNumber (stepNumber) {
    if (stepNumber) {
        return `Step ${stepNumber}`;
    }

    return 'Deleted Step';
}
