<template>
    <div class="no-path-container">
        <div class="message-header">
            This path doesn't exist.
        </div>
        <div class="message-subheader">
            It may have been deleted, or only the owner has changed the path visibility.
        </div>
        <div>
            <pendo-button
                theme="app"
                type="primary"
                @click="goToPathsList">
                Go to Paths
            </pendo-button>
        </div>
    </div>
</template>

<script>
import { PendoButton } from '@pendo/components';

export default {
    name: 'PathNotExist',
    components: {
        PendoButton
    },
    methods: {
        goToPathsList () {
            this.$router
                .push({
                    name: 'pathsDashboard'
                })
                .catch(() => {});
        }
    }
};
</script>

<style scoped lang="scss">
.no-path-container {
    text-align: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    .message-header {
        font-size: 24px;
        color: $gray-primary;
    }

    .message-subheader {
        color: $gray-lighter-2;
        margin-bottom: 21px;
    }
}
</style>
