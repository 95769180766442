<template>
    <login
        :loading-app="loadingApp"
        product-type="Adopt"
        :is-white-label="isWhiteLabel"
        @on-user-authenticated="postLogin">
        <template #logo>
            <slot name="logo" />
        </template>
        <template #nightLogo>
            <slot name="nightLogo" />
        </template>
    </login>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Login } from '@pendo/login';
import get from 'lodash/get';

export default {
    name: 'LoginForm',
    components: {
        Login
    },
    data () {
        return {
            loadingApp: false
        };
    },
    computed: {
        ...mapState({
            unauthenticatedTrainingAttributes: (state) => state.subscriptions.unauthenticatedTrainingAttributes,
            route: (state) => state.route
        }),
        isWhiteLabel () {
            return !!get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings');
        }
    },
    methods: {
        ...mapActions({
            _login: 'auth/login'
        }),
        async postLogin (data) {
            this.loadingApp = true;
            await this._login({ postLogin: true, data });
            this.$router.push(this.route.query.after || { name: 'root' }).catch(() => {});
        }
    }
};
</script>
