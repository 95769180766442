import { http } from '@pendo/http';
import { updateAdoptV2UserState } from '@/utils/user-settings';

// can be removed from AddUpdateExtensionAppModal once https://pendo-io.atlassian.net/browse/APP-50592 is completed
export function fetchFullSubscription () {
    return http.get('/api/s/_SID_/subscription').then((res) => res.data);
}

export function fetchAllSubscriptions () {
    return http.get('/api/pendozer/subscription/experiment').then((res) => res.data);
}

export function recordRaw () {
    return http.post('/api/s/_SID_/subscription/recordraw', {}).then((res) => res.data);
}

export function update (subscription) {
    return http.put('/api/s/_SID_/subscription', subscription).then((res) => res.data);
}

export function _getUnauthenticatedTrainingAttributes () {
    return http.get('/whitelabelsettings').then((res) => res.data);
}

export function updateSubscriptionFlag (flag, enabled) {
    return http.put(`/api/s/_SID_/subscription/flag/${flag}`, { set: enabled }).then((res) => res.data);
}

export function updateSubscriptionExtensionAttributes (extensionAttributes) {
    return http.put('/api/s/_SID_/subscription/extensionAttributes', extensionAttributes).then((res) => res.data);
}

export async function impersonateSub (id) {
    await updateAdoptV2UserState(id);
    window.location.reload();
}
