<template>
    <div class="path-share-action">
        <pendo-icon-button
            tooltip="Share"
            icon="share-2"
            @click="showShareModal = true" />
        <path-share-modal
            :report="report"
            :visible="showShareModal"
            :can-share="canShare"
            @close="showShareModal = false" />
    </div>
</template>

<script>
import { PendoIconButton } from '@pendo/components';
import PathShareModal from '@/components/paths/PathShareModal';

export default {
    name: 'PathShareAction',
    components: {
        PendoIconButton,
        PathShareModal
    },
    props: {
        report: {
            type: Object,
            required: true
        },
        canShare: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showShareModal: false
        };
    }
};
</script>
