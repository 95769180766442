import get from 'lodash/get';
import intersection from 'lodash/intersection';
import cloneDeep from 'lodash/cloneDeep';
import { getDisplayName } from '@pendo/services/Apps';
import { PENDO_APP_NAMES, DATA_COLLECTION_CONSENT_OPTIONS } from '@pendo/services/Constants';
import { http } from '@pendo/http';
import store from '@/state/store';
import { filterIsAllApps } from '@/utils/filters';
import { crossAppChartColors } from '@/utils/highcharts';
import lowerCase from 'lodash/lowerCase';

export const PROPERTY_ENDPOINT_MAP = {
    extensionDomainPatterns: 'extensionDomainPatterns'
};

export const FILTER_TYPES = Object.freeze({
    APP_ID: 'appId',
    APP_IDS: 'appIds'
});

export async function meetsMinimumAgentVersion (version, appId) {
    const isDigitalAdoptionSub = store.getters['subscriptions/activeIsDigitalAdoption'];

    // Probably not the optimal solution, but DAP subs don't manage an agent, and are generally on latest
    if (isDigitalAdoptionSub) return true;

    const agentVersionsMap = await http
        .get('/api/s/_SID_/environment/versioncheck', {
            params: {
                v: version // eslint-disable-line id-length
            }
        })
        .then((res) => res.data);
    const { production } = get(agentVersionsMap, appId, false);

    return production;
}

export function generateAppUid (subId, appId) {
    return `${subId}:${appId}`;
}

export function getEndpointForProperty (propertyName) {
    return PROPERTY_ENDPOINT_MAP[propertyName];
}

export function updateAppProperty (id, propertyName, propertyValue) {
    const endpoint = getEndpointForProperty(propertyName);
    const formattedPropertyValue = formatValueForWebRequest(propertyName, propertyValue);

    return http.put(`/api/s/_SID_/application/${id}/${endpoint}`, formattedPropertyValue).then((res) => res.data);
}

export function formatValueForWebRequest (propertyName, propertyValue) {
    switch (propertyName) {
        case 'extensionDomainPatterns':
            return { extensionDomainPatterns: propertyValue };
        default:
            return propertyValue;
    }
}

export function updateApp (app) {
    return http.put(`/api/s/_SID_/application/${app.id}`, app).then((res) => res.data);
}

export function updateAppFlag (id, flag, enabled) {
    return http.put(`/api/s/_SID_/application/${id}/flag/${flag}`, { set: enabled }).then((res) => res.data);
}

export function fetchExtensionPlatformTypes () {
    return http.get('/api/s/_SID_/daextension/schema/types').then((res) => res.data);
}

export function filterEntitiesByAppKeys (entityList = [], appIdFilter = []) {
    return filterEntities(entityList, appIdFilter, FILTER_TYPES.APP_IDS);
}

export function filterEntitiesByAppIds (entityList = [], appIdFilter = []) {
    return filterEntities(entityList, appIdFilter, FILTER_TYPES.APP_ID);
}

export function filterEntities (entityList = [], appIdFilter = [], type) {
    if (filterIsAllApps(appIdFilter)) return entityList;

    const byAppId = (entity) => appIdFilter.includes(entity.appId);
    const byAppIds = (entity) => !!intersection(appIdFilter, entity.appIds).length;

    switch (type) {
        case FILTER_TYPES.APP_ID:
            return entityList.filter(byAppId);
        case FILTER_TYPES.APP_IDS:
            return entityList.filter(byAppIds);
        default:
            return entityList;
    }
}

export function addAppToEntityList (entityList = [], appMap = []) {
    return entityList.map((entity) => {
        const entityClone = cloneDeep(entity);
        const defaultAppKeys = entityClone.appIds || [entityClone.appId];
        entityClone.app = entityClone.appIds ? appMap[entityClone.appIds[0]] : appMap[entityClone.appId];
        entityClone.apps = defaultAppKeys.map((appId) => appMap[appId]);

        return entityClone;
    });
}

export function filterSegmentsByAppId (segmentList, appId) {
    const isMultiApp = store.getters['subscriptions/usesMultiApp'];

    return segmentList.filter((segment) => {
        const [filter] = segment.definition.filters;
        let selectedId;

        if (get(filter, 'or')) {
            // lines 110, 117-121 & 123-126 temp fix for non multiapp view subs
            // remove as part of work in https://pendo-io.atlassian.net/browse/APP-77562
            if (!isMultiApp && get(filter, 'or[0].workflowId')) {
                const step = get(store, `state.workflows.map[${filter.or[0].workflowId}].workflowSteps[0]`);
                selectedId = findAppInWorkflowStep(step);
            } else {
                selectedId = get(filter, 'or[0].selectedApp.id');
            }
        } else if (!isMultiApp && get(filter, 'workflowId')) {
            const step = get(store, `state.workflows.map[${filter.workflowId}].workflowSteps[0]`);
            selectedId = findAppInWorkflowStep(step);
        } else {
            selectedId = get(filter, 'selectedApp.id');
        }

        return selectedId === appId;
    });
}

export function getDesignerAppName (adoptDADesigner, type = 'guide') {
    return type === 'guide' && adoptDADesigner ? PENDO_APP_NAMES.ADOPT_STUDIO : PENDO_APP_NAMES.VDS;
}

export function commaSeparatedAppList (apps) {
    return apps.map(getDisplayName).join(', ');
}

export function formatHtmlAttributesConsentOptions (platform) {
    return {
        ...(get(platform, 'htmlAttributes.length', false) && {
            htmlAttributes: {
                ...DATA_COLLECTION_CONSENT_OPTIONS.htmlAttributes,
                subConsentOptions: platform.htmlAttributes.reduce((subConsentOptions, htmlAttribute) => {
                    subConsentOptions[htmlAttribute] = {
                        label: htmlAttribute,
                        enabled: true
                    };

                    return subConsentOptions;
                }, {})
            }
        })
    };
}

export function formatPageEPConsentOptions (platform) {
    return {
        ...(get(platform, 'applicationFlags.pageBasedEventProperties', false) &&
            get(platform, 'eventPropertyConfigurations', []).some((ep) => ep.name === 'document_title') && {
            pageBasedEventProperties: {
                ...DATA_COLLECTION_CONSENT_OPTIONS.pageBasedEventProperties,
                subConsentOptions: platform.eventPropertyConfigurations.reduce((subConsentOptions, ep) => {
                    subConsentOptions[ep.name] = {
                        ...ep,
                        label: ep.name,
                        enabled: true
                    };

                    return subConsentOptions;
                }, {})
            }
        })
    };
}

export function formatSiblingSelectorsConsentOptions (platform) {
    return {
        ...(get(platform, 'applicationFlags.siblingSelectors', false) && {
            siblingSelectors: {
                ...DATA_COLLECTION_CONSENT_OPTIONS.siblingSelectors
            }
        })
    };
}

export function formatDisableExtensionTextCaptureConsentOptions (platform) {
    return {
        ...(!get(platform, 'applicationFlags.disableExtensionTextCapture', true) && {
            disableExtensionTextCapture: {
                ...DATA_COLLECTION_CONSENT_OPTIONS.disableExtensionTextCapture
            }
        })
    };
}

export function getAppChartColor (app) {
    return get(app, 'color', crossAppChartColors[app.createdAt % crossAppChartColors.length]);
}

// Temp helper function for non multiapp subs
// remove as part of work in https://pendo-io.atlassian.net/browse/APP-77562
export function findAppInWorkflowStep (step) {
    if (!step) return;
    const { countableId, countableKind } = step;

    return get(store, `state.${lowerCase(countableKind)}s.map[${countableId}].appId`);
}

export function fetchApp (id) {
    return http.get(`/api/s/_SID_/application/${id}`).then((res) => res.data);
}

export function fetchFullSubscriptionApplications (subId) {
    return http.get(`/api/super/s/${subId}/application`).then((res) => res.data);
}

export function getApplicationRolesBySub (subId, type = '') {
    return http.get(`/api/s/${subId}/applicationrole?type=${type}`).then((res) => res.data);
}

export function setUniqueAppIds (applications = []) {
    return applications.map((app) => {
        app.uid = generateAppUid(app.subscriptionId, app.id);

        return app;
    });
}
