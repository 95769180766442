import moment from 'moment-timezone';

export default moment;

export const convertToSubscriptionTimezone = (activeTimezone, ...args) => moment(...args).tz(activeTimezone);

export const DATE_FORMAT = {
    iso: 'YYYY-MM-DD',
    short: 'MMM D, YYYY',
    time: 'h:mma',
    fullTime: 'HH:mm:ss z',
    full: 'MMM D, YYYY h:mma z',
    isoDateTime: 'YYYY-MM-DD hh:mm:ss',
    isoDateTime24: 'YYYY-MM-DD HH:mm:ss'
};

export function formatTimeRangeForTable (timeInMs) {
    const momentTime = moment.duration(timeInMs);

    const days = momentTime.days();
    const hours = momentTime.hours();
    const minutes = momentTime.minutes();
    const seconds = momentTime.seconds();

    let formattedString = '';

    if (seconds) formattedString = `${seconds}s ${formattedString}`;
    if (minutes) formattedString = `${minutes}m ${formattedString}`;
    if (hours) formattedString = `${hours}h ${formattedString}`;
    if (days) formattedString = `${days}d ${formattedString}`;

    return formattedString.trim();
}

export const splitTimeParts = (time) => {
    if (!time || time === '-') {
        return { hours: 0, minutes: 0, amPm: 'am' };
    }

    const reAmPm = /am|pm/;
    const [amPm] = reAmPm.exec(time);
    let [hours, minutes] = time.replace(reAmPm, '').split(':');

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (hours === 12) {
        hours = 0;
    }

    if (amPm === 'pm') {
        hours += 12;
    }

    return {
        hours,
        minutes,
        amPm
    };
};
