<template>
    <div>
        <component :is="component">
            <template #logo>
                <div
                    class="brand"
                    :class="{
                        whitelabel: !!brandName
                    }">
                    <adopt-logo
                        v-if="!brandLogoUrl"
                        class="brand--pendo-adopt-logo" />
                    <img
                        v-if="brandLogoUrl"
                        :src="brandLogoUrl"
                        class="brand--logo">
                    <svg
                        v-if="brandName"
                        class="divider">
                        <path d="M0 0v22" />
                    </svg>
                    <div
                        v-if="brandName"
                        class="brand--product-name">
                        {{ brandName }}
                    </div>
                </div>
            </template>
            <template #nightLogo>
                <div class="brand">
                    <adopt-logo
                        class="brand--pendo-adopt-logo"
                        text-color="light" />
                </div>
            </template>
        </component>

        <powered-by
            v-if="showAdoptPoweredBy"
            class="powered-by" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import get from 'lodash/get';
import PoweredBy from '@/components/PoweredBy';
import AdoptLogo from '@/components/icons/AdoptLogo';
import ForgotPassword from '@/components/register/unified-login/ForgotPasswordForm';
import ExpiredLinkPage from '@/components/register/unified-login/ExpiredLinkPage';
import LoginForm from '@/components/register/unified-login/LoginForm';
import NoActiveSubscriptions from '@/components/register/unified-login/NoActiveSubscriptions';
import ResetPassword from '@/components/register/unified-login/ResetPassword';
import CompleteUser from '@/components/register/unified-login/CompleteUser';
import Confirmation from '@/components/register/unified-login/Confirmation';
import InitiateLogin from '@/components/InitiateLogin';

export default {
    name: 'PublicView2',
    components: {
        ForgotPassword,
        ExpiredLinkPage,
        CompleteUser,
        ResetPassword,
        LoginForm,
        PoweredBy,
        AdoptLogo,
        NoActiveSubscriptions,
        Confirmation,
        InitiateLogin
    },
    computed: {
        ...mapState({
            component: (state) => state.route.meta.component,
            unauthenticatedTrainingAttributes: (state) => state.subscriptions.unauthenticatedTrainingAttributes
        }),
        brandLogoUrl () {
            return get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings.logoUrl');
        },
        brandName () {
            return get(this.unauthenticatedTrainingAttributes, 'productName');
        },
        showAdoptPoweredBy () {
            return get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings');
        }
    },
    watch: {
        unauthenticatedTrainingAttributes () {
            this.formatDocumentTitleByRoute();
        }
    },
    methods: {
        ...mapActions({
            formatDocumentTitleByRoute: 'router/formatDocumentTitleByRoute'
        })
    }
};
</script>

<style lang="scss" scoped>
.whitelabel {
    display: flex;
    align-items: center;
}

.brand {
    z-index: 1;

    @media only screen and (max-width: 767px) {
        align-self: center;
        position: relative;
        top: auto;
        left: auto;
    }

    .brand--logo {
        max-width: 7rem;
        max-height: 3rem;
        vertical-align: middle;
    }

    .brand--pendo-adopt-logo {
        width: 198px;
        height: 33px;
    }

    .divider {
        height: 22px;
        width: 1px;
        margin-left: 0.5rem;

        path {
            stroke: $gray-lighter-5;
            stroke-width: 2;
        }
    }

    .brand--product-name {
        color: $gray-lighter-2;
        font-size: 0.9rem;
        font-weight: 600;
        padding-left: 1rem;
        line-height: 1.25rem;
    }
}

.powered-by {
    position: absolute;
    bottom: 31px;
    height: 30px;
}
</style>
