export const USER_ROLES = {
    PUBLIC: 1,
    USER: 2,
    ADMIN: 4,
    SUPER: 8
};

export const SUBSCRIPTION_ADMIN_ROLE = 'predefined_sub_1';

export const SUBSCRIPTION_USER_ROLE = 'predefined_sub_2';

export const SUPER_ROLE = 'super';

export const OPS_ROLE = 'ops';

// The roles in this array are not retrieved or sent to the backend
// take special care to filter these out prior to updates, or to add them to the BE results when retrieving roles
export const SUPPLEMENTAL_ROLES = [SUBSCRIPTION_USER_ROLE, SUPER_ROLE, OPS_ROLE];

export const SHARE_SEGMENTS_ROLE_ID = 'predefined_sub_3';

export const SHARE_REPORTS_ROLE_ID = 'predefined_sub_4';

export const VIEWER = 'predefined_1';

export const GUIDE_PUBLISHER = 'predefined_2';

export const GUIDE_CREATOR = 'predefined_3';

export const ANALYST = 'predefined_4';

export const GUIDE_CONTENT_EDITOR = 'predefined_5';
