import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import { formatValueForSchema, largestRemainderRound } from '@/utils/formatters';
import store from '@/state/store';
import { BuildingBlockActions } from '@pendo/services/BuildingBlocks';
import { getUiElementType } from '@/stateless-components/utils/guide-metrics';
const { actionTypes } = BuildingBlockActions;

export function getElementsTableColumns ({ grouped, elementActionColumnWidth, csv = false, isCrossApp }) {
    const includeGuideStepName = !grouped || csv;
    const includePercentClicksOnStep = grouped || csv;
    const includeApplication = includeGuideStepName && isCrossApp;

    return [
        {
            prop: 'uiElementText',
            label: 'Name',
            minWidth: 260,
            sortable: true
        },
        csv && {
            prop: 'uiElementId',
            label: 'Element Id'
        },
        {
            prop: 'uiElementType',
            label: 'Element Type',
            width: 180,
            sortable: true
        },
        csv && {
            prop: 'guideStepId',
            label: 'Step Id'
        },
        includeGuideStepName && {
            prop: 'guideStepName',
            label: 'Step Name',
            sortable: true
        },
        includeApplication && {
            prop: 'application',
            label: 'Application',
            sortable: true
        },
        {
            prop: 'uiElementActions',
            label: 'Action',
            width: elementActionColumnWidth,
            formatter: (row) => row.uiElementActions.map((action) => action.action).join(' & ')
        },
        {
            prop: 'numClicks',
            type: 'link',
            label: 'Number of Clicks',
            sortable: true
        },
        includePercentClicksOnStep && {
            prop: 'percentClicksOnStep',
            label: '% of Clicks (Step)',
            sortable: true,
            formatter: (row) => formatValueForSchema(row.percentClicksOnStep, 'percentage')
        },
        csv && {
            prop: 'uiElementStatus',
            label: 'Element Status',
            formatter: (row) => {
                if (row.isDeleted) {
                    return 'Deleted';
                }

                return 'Active';
            }
        }
    ].filter(Boolean);
}

export function getStepName (stepId, guide) {
    const step = guide.steps.find((step) => step.id === stepId);

    return get(step, 'name');
}

export function getStepIndex (stepId, guide) {
    return guide.steps.findIndex((step) => step.id === stepId);
}

export function formatUiElementTextForDisplay (uiElementText, uiElementId) {
    if (!uiElementText) {
        return uiElementId || 'Unknown';
    }

    if (uiElementText === '×') {
        return 'Close Button';
    }

    return uiElementText;
}

export function formatStepNameForDisplay (stepNumber, stepName) {
    if (stepNumber === 0) {
        return 'Deleted Step';
    }

    if (!stepName) {
        return `Step ${stepNumber} - Unnamed`;
    }

    return stepName;
}

export function formatPercentClicksOnStep (rows, showDeletedElements = false) {
    const totalClicksByStep = {};
    rows.forEach((row) => {
        if (!showDeletedElements && row.isDeleted) return;
        if (totalClicksByStep[row.guideStepId]) {
            totalClicksByStep[row.guideStepId] += row.numClicks;
        } else {
            totalClicksByStep[row.guideStepId] = row.numClicks;
        }
    });
    rows.forEach((row) => {
        row.percentClicksOnStep = row.numClicks > 0 ? (row.numClicks / totalClicksByStep[row.guideStepId]) * 100 : 0;
    });
    const stepUiElementsMap = rows.reduce((acc, row) => {
        if (!showDeletedElements && row.isDeleted) return acc;
        const { guideStepId, uiElementId, percentClicksOnStep } = row;
        if (acc[guideStepId]) {
            acc[guideStepId][uiElementId] = percentClicksOnStep;
        } else {
            acc[guideStepId] = { [uiElementId]: percentClicksOnStep };
        }

        return acc;
    }, {});
    Object.keys(stepUiElementsMap).forEach((guideStepId) => {
        let formattedPercentClicksOnStep = Object.values(stepUiElementsMap[guideStepId]);
        formattedPercentClicksOnStep = largestRemainderRound(formattedPercentClicksOnStep, 100, 1);
        Object.keys(stepUiElementsMap[guideStepId]).forEach((uiElementId, i) => {
            stepUiElementsMap[guideStepId][uiElementId] = formattedPercentClicksOnStep[i];
        });
    });
    rows.forEach((row) => {
        if (!showDeletedElements && row.isDeleted) return;
        row.percentClicksOnStep = stepUiElementsMap[row.guideStepId][row.uiElementId];
    });

    return rows;
}

export function processGuideElementsAggResponse (
    rows,
    guide,
    currentElementData,
    isCrossApp,
    steps,
    guideList,
    showDeleted = false
) {
    rows = formatPercentClicksOnStep(rows, showDeleted);
    const mergedData = merge(keyBy(rows, 'uiElementId'), keyBy(currentElementData, 'uiElementId'));
    const data = Object.values(mergedData).map((row) => {
        const stepName = row.guideStepName || getStepName(row.guideStepId, guide);
        const stepIndex = row.guideStepIndex || getStepIndex(row.guideStepId, guide);
        const stepNumber = stepIndex + 1;
        const uiElementType = row.isDeleted ? getUiElementType(row.uiElementId, row.uiElementType) : row.uiElementType;

        if (isCrossApp) {
            const appId = getAppIdForStep(stepIndex, steps);

            row = {
                ...row,
                appId,
                application: store.getters['apps/appNameFromId'](appId.replace(/-\d+/g, ''))
            };
        }

        return {
            ...row,
            numClicks: row.numClicks || 0,
            percentClicksOnStep: row.percentClicksOnStep || 0,
            uiElementType,
            uiElementTypeIcon: getUiElementTypeIcon(uiElementType),
            guideStepName: formatStepNameForDisplay(stepNumber, stepName),
            guideStepIndex: stepIndex,
            uiElementText: formatUiElementTextForDisplay(row.uiElementText),
            uiElementActions: getUiElementActions(row, guide, guideList)
        };
    });

    return sortBy(data, 'guideStepIndex');
}

export function getUiElementTypeIcon (elementType) {
    return {
        'Button': 'rectangle',
        'Close Button': 'x',
        'Dismiss Button': 'x',
        'Link': 'link',
        'Task Item': 'list-checks'
    }[elementType];
}

export function parseActions (actions) {
    try {
        return JSON.parse(actions);
    } catch (error) {
        return actions;
    }
}

export function getUiElementActions (row, guide, guideList) {
    const actions = parseActions(row.uiElementActions || '[]');

    if (actions && actions.length) {
        return actions.reduce((acc, action) => {
            switch (action.action) {
                case actionTypes.submitPoll:
                    acc.push(
                        processElementAction({ action: actionTypes.submitPoll }, guide),
                        processElementAction({ action: actionTypes.advanceGuide }, guide)
                    );
                    break;
                case actionTypes.submitPollAndGoToStep:
                    acc.push(
                        processElementAction({ action: actionTypes.submitPoll }, guide),
                        processElementAction({ ...action, action: actionTypes.goToStep }, guide)
                    );
                    break;
                case actionTypes.confirmation:
                    acc.push(
                        processElementAction({ action: actionTypes.confirmation }, guide),
                        processElementAction({ action: actionTypes.advanceGuide }, guide)
                    );
                    break;
                default:
                    acc.push(processElementAction(action, guide, guideList));
            }

            return acc;
        }, []);
    }

    return [{ action: 'Unknown' }];
}

export function processElementAction (action, guide, guideList) {
    switch (action.action) {
        case actionTypes.openLinkWithoutClosing:
        case actionTypes.openLink:
            return { action: 'External URL', icon: 'external-link' };
        case actionTypes.goToStep: {
            const stepId = action.guideStepId
                ? action.guideStepId
                : get(
                    action.parameters.find((param) => param.name === 'goToStepId'),
                    'value'
                );

            const stepNumber = getStepIndex(stepId, guide) + 1 || 'Deleted Step';

            return { action: `Go to Step (${stepNumber})`, icon: 'fast-forward' };
        }
        case actionTypes.guideSnoozed:
            return { action: 'Snooze Guide', icon: 'clock' };
        case 'showGuide':
        case actionTypes.launchGuide: {
            let actionText = 'Launch Guide';
            const guideId = action.guideId || action.parameters[0]?.value;
            if (guideId) {
                const launchGuide = guideList.find((guide) => guide.id === guideId);
                actionText += ` – ${launchGuide ? launchGuide.name : '[DELETED GUIDE]'}`;
            }

            return { action: actionText, icon: 'guide' };
        }
        case actionTypes.advanceGuide:
            return { action: 'Next Step', icon: 'skip-forward' };
        case actionTypes.confirmation:
            return { action: 'Confirm Action', icon: 'confirmation' };
        case actionTypes.previousStep:
            return { action: 'Previous Step', icon: 'rewind' };
        case actionTypes.submitPoll:
            return { action: 'Submit Poll', icon: 'metrics' };
        case actionTypes.dismissGuide:
            return { action: 'Dismiss Guide', icon: 'x-circle' };
        case actionTypes.openRatingDialog:
            return { action: 'Mobile Rating', icon: 'rating-scale' };
        case actionTypes.automation:
            return { action: 'Automation', icon: 'automation' };
        default:
            return { action: 'Unknown' };
    }
}

export function getAppIdForStep (stepIndex, steps) {
    return get(steps[stepIndex], 'appId', 'deleted');
}
