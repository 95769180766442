export const GUIDE_STATUS_COLORS = {
    public: '#00aa62',
    staged: '#b08300',
    scheduled: '#f05b00',
    draft: '#07699b',
    disabled: '#6a6c75'
};

export const GUIDE_STATUS_LABEL = {
    public: 'Public',
    staged: 'Staged',
    scheduled: 'Scheduled',
    draft: 'Draft',
    disabled: 'Disabled'
};

export const GUIDE_STATUS_KEYS = ['public', 'staged', 'scheduled', 'draft', 'disabled'];

export const GUIDE_STATUS_MODAL_CONFIG = {
    public: {
        title: 'Set guide to Public?',
        bodyComponent: 'PublicStatusModalBody',
        confirm: 'Set to Public'
    },
    staged: {
        title: 'Set guide to Staged?',
        body:
            'This guide will become visible to all users with access to the staged environment upon changing status to staged.',
        confirm: 'Set to Staged'
    },
    scheduled: {
        title: 'Schedule Guide',
        bodyComponent: 'ScheduledStatusModalBody',
        confirm: 'Schedule Guide'
    },
    draft: {
        title: 'Set guide to Draft?',
        body: 'This guide will not appear to any users while in this state and can still be edited at any time.',
        confirm: 'Set to Draft'
    },
    disabled: {
        title: 'Set guide to Disabled?',
        body: 'This guide will not appear to any users while in this state and can still be edited at any time.',
        confirm: 'Set to Disabled'
    },
    mobileTooltipNoActivation: {
        title: 'You haven’t selected a page to activate your guide',
        body: '',
        confirm: 'Manage Guide In My App'
    }
};
