var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[(_vm.insight.key === _vm.INSIGHT_TYPES.LONGEST_STEPS)?[_vm._m(0),_c('br'),_c('div',[_vm._v("Not what you expected?")]),_c('ol',[_vm._m(1),_c('li',[_c('b',[_vm._v("Are there bottlenecks?")]),_vm._v(" Revisit the process in "+_vm._s(_vm.appNames)+".")]),_vm._m(2)])]:_vm._e(),(_vm.insight.key === _vm.INSIGHT_TYPES.REPEATED_SEQUENCES)?[_c('div',[_vm._v(" Visitors "),_c('b',[_vm._v("completed this sequence of steps "+_vm._s(_vm.repeatedSequencePhrase))]),_vm._v(" in the most common journey. ")]),_c('br'),_vm._m(3)]:_vm._e(),(_vm.insight.key === _vm.INSIGHT_TYPES.REPEATED_STEPS)?[_c('div',[_vm._v(" Visitors "),_c('b',[_vm._v("navigated to "+_vm._s(_vm.repeatedStepsPhrase.stepName)+" "+_vm._s(_vm.repeatedStepsPhrase.duplicateCount))]),_vm._v(" in the most common journey as steps "+_vm._s(_vm.repeatedStepsPhrase.indexList)+". ")]),_c('br'),_vm._m(4)]:_vm._e(),(_vm.insight.key === _vm.INSIGHT_TYPES.QUICKEST_V_MOST_COMMON)?[_c('div',[_vm._v(" The quickest journey is "),_c('b',[_vm._v(_vm._s(_vm.percentDiffPhrase))]),_vm._v(" the most common journey. ")]),_c('br'),_vm._m(5)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Visitors "),_c('b',[_vm._v("spent the most time navigating between these two steps")]),_vm._v(" in the most common journey. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Are important steps missing from this journey?")]),_vm._v(" Tag additional Pages or Features and add them to this journey. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('b',[_vm._v("Need feedback on how to improve the process?")]),_vm._v(" Create a guide to get feedback.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_vm._v("Want to understand why?")]),_vm._v(" Create a guide to get feedback.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_vm._v("Want to understand why?")]),_vm._v(" Create a guide to get feedback.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_vm._v("Want everyone to follow this faster journey?")]),_vm._v(" Create a walkthrough guide on these steps.")])
}]

export { render, staticRenderFns }