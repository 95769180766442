var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-audit-step-details"},_vm._l((_vm.stepsToDisplay),function(step,index){return _c('pendo-collapse',{key:`step-card-${index}`,staticClass:"step-card",attrs:{"data-cy":"guide-audit-step","body-min-height":"auto"}},[_c('pendo-collapse-item',{staticClass:"body",attrs:{"title":step.name,"expanded":step.expanded}},[_c('pendo-collapse',{staticClass:"step-changes",attrs:{"body-min-height":"auto"}},[_c('pendo-collapse-item',{key:`visual-changes-${_vm.selectedRevision.lastUpdatedAt}`,staticClass:"visual-changes",attrs:{"title":"Visual changes","name":`visual-changes-${index}`,"expanded":step.expanded && step.hasVisualChanges},on:{"click":function($event){return _vm.toggleStepView(index)}}},[_c('differences-display',{attrs:{"show-previous-revision":_vm.showPreviousRevision,"is-removed":step.isRemoved,"is-added":step.isAdded,"selected-revision-subtitle":_vm.selectedRevisionSubtitle,"previous-revision-subtitle":_vm.previousRevisionSubtitle},scopedSlots:_vm._u([(step.selected)?{key:"selected",fn:function(){return [(_vm.stepsToDisplay[index].expanded)?_c('guide-step-preview',{key:`preview-selected-${step.selected.lastUpdatedAt}-${step.selected.id}`,attrs:{"dom":step.selected.dom,"step":step.selected,"guides":[
                                    ..._vm.allGuidesButSelected,
                                    { ..._vm.selectedRevision, id: step.selected.guideId }
                                ],"resource-centers":[],"debounce-time":2}}):_vm._e()]},proxy:true}:null,(step.previous)?{key:"previous",fn:function(){return [(_vm.stepsToDisplay[index].expanded)?_c('guide-step-preview',{key:`preview-previous-${step.previous.lastUpdatedAt}-${step.previous.id}`,attrs:{"dom":step.previous.dom,"step":step.previous,"guides":[
                                    ..._vm.allGuidesButSelected,
                                    { ..._vm.previousRevision, id: step.previous.guideId }
                                ],"resource-centers":[],"debounce-time":2}}):_vm._e()]},proxy:true}:null],null,true)})],1),(!step.isRemoved && step.nonVisualChanges.length > 0)?_c('pendo-collapse-item',{staticClass:"other-changes",attrs:{"title":"Other changes","name":`other-changes-${index}`,"expanded":step.expanded && step.nonVisualChanges.length > 0}},[_c('differences-display',{attrs:{"is-removed":step.isRemoved,"is-added":step.isAdded,"show-tags":false,"show-previous-revision":_vm.showPreviousRevision,"selected-revision-subtitle":_vm.selectedRevisionSubtitle,"previous-revision-subtitle":_vm.previousRevisionSubtitle,"differences":step.nonVisualChanges}})],1):_vm._e()],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }