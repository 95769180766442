<template>
    <div
        class="workflow-totals-chart"
        data-cy="workflow-totals-chart">
        <pendo-card
            data-cy="workflow-totals-chart-title"
            body-min-height="200px"
            :title="title"
            width="100%">
            <template #title>
                <div
                    v-if="isRecurring"
                    class="workflow-totals-chart--dropdown"
                    data-cy="total-chart-data-source-dropdown">
                    <pendo-multiselect
                        :allow-empty="false"
                        :min-menu-width="180"
                        :options="dataSourceOptions"
                        :value="dataSourceOption"
                        @select="onDataSourceSelected($event.id)">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
                {{ title }}
            </template>
            <pendo-loading-indicator
                v-if="loading"
                class="workflow-totals-chart--loading"
                type="material"
                size="large"
                :spinner-props="{
                    background: '#ffffff'
                }" />
            <single-horizontal-bar-chart :config="chartConfig" />
            <template #headerRight>
                <slot name="addToDashboard" />
            </template>
        </pendo-card>
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoCard, PendoDataSourceTrigger, PendoMultiselect, PendoLoadingIndicator } from '@pendo/components';

import SingleHorizontalBarChart from '@/components/common/SingleHorizontalBarChart';

export default {
    name: 'WorkflowTotalsChart',
    components: {
        PendoCard,
        PendoDataSourceTrigger,
        PendoMultiselect,
        SingleHorizontalBarChart,
        PendoLoadingIndicator
    },
    props: {
        savedWorkflowSettings: {
            type: Object,
            default: null
        },
        dateRangeLabel: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        totalsAggData: {
            type: Object,
            default: null
        },
        isRecurring: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            dataSourceOptions: [
                {
                    id: 'workflow-attempts',
                    label: 'Workflow attempts'
                },
                {
                    id: 'visitor-completion',
                    label: 'Visitor completion'
                }
            ]
        };
    },
    computed: {
        chartConfig () {
            const viewingWorkflowAttempts = this.isRecurring && this.dataSourceOption.id === 'workflow-attempts';

            return [
                {
                    description: this.getDescription(viewingWorkflowAttempts ? 'completedAttempts' : 'completedCount'),
                    color: '#FF4994',
                    count: this.getStat(viewingWorkflowAttempts ? 'completedAttempts' : 'completedCount'),
                    cypressID: 'completed',
                    icon: 'check-circle',
                    label: 'Completed'
                },
                {
                    description: this.getDescription(
                        viewingWorkflowAttempts ? 'incompleteAttempts' : 'incompleteCount'
                    ),
                    color: '#1967D2',
                    count: this.getStat(viewingWorkflowAttempts ? 'incompleteAttempts' : 'incompleteCount'),
                    cypressID: 'incomplete',
                    icon: 'clock',
                    label: 'Incomplete'
                },
                !viewingWorkflowAttempts && {
                    description: this.getDescription('neverStartedCount'),
                    color: '#FFA600',
                    count: this.getStat('neverStartedCount'),
                    cypressID: 'not-started',
                    icon: 'alert-triangle',
                    label: 'Not started'
                }
            ].filter(Boolean);
        },
        dataSourceOption () {
            const setting = get(this.savedWorkflowSettings, 'totalChartDataSource', null);

            return this.dataSourceOptions.find((option) => option.id === setting) || this.dataSourceOptions[0];
        },
        title () {
            const titlePrefix = this.isRecurring ? '' : 'Visitor completion rate ';
            const total =
                this.isRecurring && this.dataSourceOption.id === 'workflow-attempts'
                    ? `${this.getStat('totalAttempts').toLocaleString()} attempts`
                    : `${this.getStat('totalVisitors').toLocaleString()} active visitors`;

            const title = `${titlePrefix}over ${this.dateRangeLabel}`;

            if (!this.loading) return `${title} (${total})`;

            return title;
        }
    },
    methods: {
        getDescription (key) {
            const viewingWorkflowAttempts = this.isRecurring && this.dataSourceOption.id === 'workflow-attempts';

            const denominatorKey = viewingWorkflowAttempts ? 'totalAttempts' : 'totalVisitors';
            const numerator = this.getStat(key).toLocaleString();
            const denominator = this.getStat(denominatorKey).toLocaleString();

            return `(${numerator} out of ${denominator} ${viewingWorkflowAttempts ? 'attempts' : 'active visitors'})`;
        },
        getStat (key) {
            return get(this.totalsAggData, key, 0);
        },
        onDataSourceSelected (value) {
            this.$emit('updateTotalsDataSource', value);
        }
    }
};
</script>

<style lang="scss" scoped>
.workflow-totals-chart {
    &--dropdown {
        display: inline;
    }

    &--tooltip {
        &-description {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            margin: 0;
        }

        &-percentage {
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            margin: 0;
        }
    }

    ::v-deep .pendo-card__body {
        position: relative;
    }

    &--loading {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}
</style>
