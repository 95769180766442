<template>
    <div
        v-pendo-affix="{
            fixedStyles: {
                left: 0,
                right: 0,
                padding: '0 0 0 80px'
            }
        }"
        class="workflows-filter-bar">
        <pendo-filter-bar>
            <div class="workflows-filter-bar--top">
                <h4 class="workflows-filter-bar--top-title">
                    Filter
                </h4>
                <div class="workflows-filter-bar--top-filters">
                    <div class="workflows-filter-bar--top-filters-item">
                        <segment-chooser
                            full-width
                            class="workflows-filter-bar--segment-chooser"
                            data-cy="workflows-segment-filter"
                            :disabled="!canEditWorkflow"
                            :guide-targeting="false"
                            :value="activeSegmentId"
                            @input="updateActiveSegmentId" />
                    </div>

                    <div class="workflows-filter-bar--top-filters-item">
                        <date-range-picker
                            full-width
                            class="workflows-filter-bar--date-range-picker"
                            data-cy="workflows-date-range-filter"
                            :first-visit="appFirstVisit"
                            :disabled="!canEditWorkflow"
                            :range-limiter="90"
                            :value="dateRange"
                            @change="updateDateRange" />
                    </div>
                </div>
            </div>

            <template #bottom>
                <div class="workflows-filter-bar--bottom">
                    <div
                        class="workflows-filter-bar--bottom-header"
                        data-cy="workflows-filter-bar-details"
                        role="button"
                        @click="onExpandCollapseClicked">
                        <h4 class="workflows-filter-bar--bottom-header-title">
                            Details
                        </h4>

                        <div class="workflows-filter-bar--bottom-header-query-viewer">
                            <workflow-query-viewer
                                :start-label="startLabel"
                                :end-label="endLabel"
                                :saved-workflow="savedWorkflow" />
                        </div>

                        <div class="workflows-filter-bar--bottom-header-actions">
                            <div
                                class="workflows-filter-bar--bottom-header-actions-collapse-expand"
                                tabindex="0"
                                role="button"
                                @keyup.enter="onExpandCollapseClicked">
                                <pendo-icon
                                    :type="icon"
                                    size="16" />
                            </div>
                        </div>
                    </div>

                    <div class="workflows-filter-bar--bottom-body">
                        <div
                            :class="collapserClasses"
                            @transitionend="onCollapserTransitionEnded">
                            <workflows-query-builder
                                class="workflows-filter-bar--query-builder"
                                @saveClicked="onSaveClicked"
                                @cancel="onExpandCollapseClicked" />
                        </div>
                    </div>
                </div>
            </template>
        </pendo-filter-bar>
    </div>
</template>

<script>
import { PendoAffix, PendoFilterBar, PendoIcon, PendoNotification } from '@pendo/components';
import WorkflowsQueryBuilder from './WorkflowsQueryBuilder';
import WorkflowQueryViewer from '@/stateless-components/workflows/details/WorkflowQueryViewer';
import DateRangePicker from '@/stateless-components/common/DateRangePicker';
import SegmentChooser from '@/components/segments/SegmentChooser';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { convertValueToDateRangeConfig, fixedDateRanges } from '@/utils/time-series';
import { hasInvalidWorkflowSteps } from '@/utils/workflows';
import moment, { DATE_FORMAT } from '@/utils/moment.js';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import startCase from 'lodash/startCase';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'WorkflowsFilterBar',
    components: {
        DateRangePicker,
        PendoFilterBar,
        PendoIcon,
        SegmentChooser,
        WorkflowsQueryBuilder,
        WorkflowQueryViewer
    },
    directives: {
        PendoAffix
    },
    data () {
        return {
            collapsed: true,
            hidden: true,
            readySubscription: null
        };
    },
    computed: {
        ...mapGetters({
            pageList: 'pages/list',
            featureList: 'features/list',
            savedWorkflow: 'workflows/savedWorkflow',
            isLoading: 'workflows/isLoading',
            appFirstVisit: 'apps/firstVisit',
            appById: 'apps/appById',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        ...mapState({
            workingWorkflow: (state) => state.workflows.workingWorkflow,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            dateRange: (state) => state.filters.dateRange,
            isFetching: (state) => state.workflows.isFetching,
            isSaving: (state) => state.workflows.isSaving,
            route: (state) => state.route,
            features: (state) => state.features.map,
            pages: (state) => state.pages.map
        }),
        pageListMap () {
            return keyBy(this.pageList, 'id');
        },
        featureListMap () {
            return keyBy(this.featureList, 'id');
        },
        canEditWorkflow () {
            return !this.isFetching && !this.isSaving;
        },
        collapserClasses () {
            return {
                'workflows-filter-bar--bottom-body-collapser': true,
                'is-expanded': !this.collapsed,
                'is-hidden': this.hidden
            };
        },
        icon () {
            return this.collapsed ? 'chevron-down' : 'chevron-up';
        },
        hasInvalidWorkflowSteps () {
            const pagesMap = this.pageListMap;
            const featuresMap = this.featureListMap;

            return hasInvalidWorkflowSteps(this.workingWorkflow.workflowSteps, { featuresMap, pagesMap });
        },
        endLabel () {
            return this.getCountableLabel('end');
        },
        startLabel () {
            return this.getCountableLabel('start');
        }
    },
    created () {
        if (this.dateRange.count > 90) {
            this.defaultGlobalDateRangeForWorkflows();
        }
        if (this.savedWorkflow) {
            this.hidden = true;
            this.collapsed = true;
            const workflow = cloneDeep(this.savedWorkflow);
            this.setWorkingWorkflow({ workflow });
            if (this.hasInvalidWorkflowSteps) {
                this.onExpandCollapseClicked();
            }
        } else {
            this.hidden = false;
            this.collapsed = false;
            const now = new Date();
            const defaultInitialState = {
                classification: 'nonrecurring',
                id: null,
                maxDuration: 2592000,
                maxInterval: 2592000,
                name: `New Workflow - ${moment(now).format(DATE_FORMAT.short)} @ ${moment(now).format(
                    DATE_FORMAT.time
                )}`,
                subscriptionSharing: {
                    permission: 'edit'
                },
                workflowSteps: [],
                journey: {
                    featureIds: [],
                    pageIds: []
                }
            };
            this.setWorkingWorkflow({ workflow: defaultInitialState });
        }
    },
    methods: {
        ...mapActions({
            createWorkflow: 'workflows/create',
            updateActiveSegmentId: 'filters/updateActiveSegmentId',
            updateDateRange: 'filters/updateDateRange',
            updateGlobalFilterDateRange: 'filters/updateDateRange',
            updateWorkflow: 'workflows/update'
        }),
        ...mapMutations({
            setSavedWorkflowId: 'workflows/setSavedWorkflowId',
            setWorkingWorkflow: 'workflows/setWorkingWorkflow',
            setWorkingWorkflowField: 'workflows/setWorkingWorkflowField',
            removeWorkflowFromState: 'workflows/deleteMapKey',
            setMapAtKey: 'workflows/setMapAtKey'
        }),
        async defaultGlobalDateRangeForWorkflows () {
            const dateRange = convertValueToDateRangeConfig(
                fixedDateRanges().find((dr) => dr.count === 90),
                this.appFirstVisit
            );
            await this.updateDateRange({ dateRange });
            PendoNotification({
                type: 'info',
                title: 'Date range filter reset to last 90 days',
                message: 'Date ranges on Workflows must be 90 days or less',
                duration: 5000
            });
        },
        handleSavingError (error) {
            const status = get(error, 'response.status', 500);
            if (this.savedWorkflow && status === 404) {
                this.removeWorkflowFromState({
                    id: this.savedWorkflow.id
                });

                this.$router.replace({
                    name: 'workflowsList'
                });

                PendoNotification({
                    type: 'error',
                    message: `${this.workingWorkflow.name} has been deleted.`
                });
            } else {
                PendoNotification({
                    type: 'error',
                    message: `There was an error saving ${this.workingWorkflow.name}. Please try again.`
                });
            }
        },
        onCollapserTransitionEnded () {
            this.hidden = this.collapsed;
        },
        onDateRangeUpdated (event) {
            this.updateDateRange(event);
        },
        onExpandCollapseClicked () {
            if (this.collapsed) {
                this.hidden = false;
            }

            this.collapsed = !this.collapsed;
        },
        addAllPagesAndFeaturesToJourney (steps) {
            const journey = cloneDeep(get(this.workingWorkflow, 'journey', { pageIds: [], featureIds: [] }));

            let previousAppIds = [];
            if (journey.pageIds.length || journey.featureIds.length) {
                // A previous journey has been saved to the workflow
                const pageAppIds = journey.pageIds.map((pageId) => get(this.pageListMap, `${pageId}.appId`));
                const featureAppIds = journey.featureIds.map((featureId) =>
                    get(this.featureListMap, `${featureId}.appId`)
                );
                previousAppIds = uniq([...pageAppIds, ...featureAppIds]);
            }
            const stepAppIds = steps.map((step) => {
                return step.countableKind === 'Page'
                    ? get(this.pageListMap, `${step.countableId}.appId`)
                    : get(this.featureListMap, `${step.countableId}.appId`);
            });
            const savedAppIds = uniq(stepAppIds);

            const newAppIds = savedAppIds.filter((id) => !previousAppIds.includes(id));
            const removedAppIds = previousAppIds.filter((id) => !savedAppIds.includes(id));

            if (removedAppIds.length) {
                const pageIdsToRemove = Object.values(this.pageListMap)
                    .filter((page) => removedAppIds.includes(page.appId))
                    .map(({ id }) => id);
                const featureIdsToRemove = Object.values(this.featureListMap)
                    .filter((page) => removedAppIds.includes(page.appId))
                    .map(({ id }) => id);
                const pageIds = journey.pageIds.filter((id) => !pageIdsToRemove.includes(id));
                const featureIds = journey.featureIds.filter((id) => !featureIdsToRemove.includes(id));
                journey.pageIds = pageIds;
                journey.featureIds = featureIds;
            }

            if (newAppIds.length) {
                const pageIdsToAdd = Object.values(this.pageListMap)
                    .filter((page) => newAppIds.includes(page.appId))
                    .map(({ id }) => id);
                const featureIdsToAdd = Object.values(this.featureListMap)
                    .filter((feature) => newAppIds.includes(feature.appId))
                    .map(({ id }) => id);
                journey.pageIds.push(...pageIdsToAdd);
                journey.featureIds.push(...featureIdsToAdd);
            }

            const firstAndLastStepCountableIds = steps.map((step) => step.countableId);
            const pageIdsExcludingFirstAndLastStepIds = journey.pageIds.filter(
                (pageId) => !firstAndLastStepCountableIds.includes(pageId)
            );
            const featureIdsExcludingFirstAndLastStepIds = journey.featureIds.filter(
                (featureId) => !firstAndLastStepCountableIds.includes(featureId)
            );
            journey.pageIds = pageIdsExcludingFirstAndLastStepIds;
            journey.featureIds = featureIdsExcludingFirstAndLastStepIds;

            this.setWorkingWorkflowField({ field: 'journey', value: journey });
        },
        async onSaveClicked () {
            const { workflowSteps } = this.workingWorkflow;
            this.addAllPagesAndFeaturesToJourney(workflowSteps);

            try {
                if (!this.workingWorkflow.id) {
                    const id = await this.createWorkflow({
                        workflow: this.workingWorkflow
                    });
                    this.setWorkingWorkflowField({ field: 'id', value: id });
                    this.setSavedWorkflowId({ id });

                    this.$router
                        .push({
                            name: 'workflowsDetailsHome',
                            params: { id }
                        })
                        .catch(() => {});
                } else {
                    await this.updateWorkflow({
                        workflow: this.workingWorkflow
                    });

                    this.$emit('runWorkflowAggs');
                }

                PendoNotification({
                    type: 'success',
                    message: `${this.workingWorkflow.name} saved.`
                });
                this.collapsed = true;
            } catch (error) {
                this.handleSavingError(error);
            }
        },
        getCountableLabel (type) {
            // state.features.map and state.pages.map include countables that may have been unshared by the Adopt parent sub.
            // pagesList and featuresList are filtered to remove these unshared resources. So these checks are not redundant.
            const workflowsSteps = get(this.savedWorkflow, 'workflowSteps', null);
            if (!workflowsSteps) return '';

            const step = workflowsSteps.find((step) => step.type === type);
            if (!step) return '';

            if (step.countableKind === 'Page' && !this.pageList.find((page) => page.id === step.countableId)) return '';

            if (
                step.countableKind === 'Feature' &&
                !this.featureList.find((feature) => feature.id === step.countableId)
            ) {
                return '';
            }

            const countable = this[`${step.countableKind.toLowerCase()}s`][step.countableId];
            if (!countable) return '';

            const app = this.appById(countable.appId);
            if (!app) return '';

            return app ? `${startCase(type)}: ${app.displayName} => ${countable.displayName || countable.name}` : '';
        }
    }
};
</script>

<style lang="scss">
.workflows-filter-bar {
    z-index: 2;

    .pendo-filter-bar {
        padding: 0;
    }

    &.is-sticky .pendo-filter-bar {
        box-shadow: 0px 2px 6px $modal-box-shadow;
        border-radius: 0;
        border: 0;
    }

    &--top {
        align-items: center;
        display: flex;
        padding-left: 16px;
        width: 100%;

        &-title {
            flex: 0 0 60px;
            font-size: 18px;
            font-weight: normal;
            margin-right: 16px;
        }

        &-filters {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            flex: 1 1 auto;

            &-item {
                margin-right: 1em;
                font-size: 0.875em;
                line-height: 1.25em;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &--bottom {
        min-height: 58px;

        &-header {
            align-items: center;
            cursor: pointer;
            display: flex;
            min-height: 58px;
            padding-left: 16px;
            padding-top: 4px;
            padding-bottom: 4px;

            &-title {
                flex: 0 0 60px;
                font-size: 18px;
                font-weight: normal;
                margin: 0;
                margin-right: 16px;
            }

            &-query-viewer {
                flex: 1 1 auto;
            }

            &-actions {
                border-left: 1px solid $gray-lighter-5;
                flex: 0 0 auto;
                height: 28px;

                &-collapse-expand {
                    margin: 0 14px;
                    padding: 6px;
                }
            }
        }

        &-body {
            background: $gray-lighter-8;

            &-collapser {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease-in-out;

                &.is-hidden {
                    visibility: hidden;
                }

                &.is-expanded {
                    max-height: 650px;
                }
            }
        }
    }

    &--query-builder {
        padding: 24px;
    }
}
</style>
