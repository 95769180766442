<template>
    <div class="diff-version-header">
        <div>
            <h2>{{ version }} version</h2>
            <div
                v-if="subtitle != ''"
                role="doc-subtitle">
                {{ subtitle }}
            </div>
        </div>
        <slot
            v-if="$slots.tag"
            name="tag" />
    </div>
</template>
<script>
export default {
    name: 'DifferencesDisplayHeader',
    props: {
        isPrevious: {
            type: Boolean,
            default: false
        },
        subtitle: {
            type: String,
            default: ''
        }
    },
    computed: {
        version () {
            return this.isPrevious ? 'Previous' : 'Selected';
        }
    }
};
</script>

<style scoped lang="scss">
.diff-version-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;

    h2 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        color: $color-gray-80;
        margin: 0;
    }

    [role='doc-subtitle'] {
        line-height: 18px;
    }
}
</style>
