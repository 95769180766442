<template>
    <pendo-tag
        :label="activeSegmentName"
        type="filter" />
</template>

<script>
import { PendoTag } from '@pendo/components';

export default {
    name: 'ActiveSegmentTag',
    components: {
        PendoTag
    },
    props: {
        activeSegment: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        activeSegmentName () {
            return this.activeSegment?.name;
        }
    }
};
</script>
