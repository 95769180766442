<template>
    <div class="extension-install-snippet">
        <div>
            The Pendo Launcher is available for
            <a
                href="https://chromewebstore.google.com/detail/pendo-launcher/epnhoepnmfjdbjjfanpjklemanhkjgil"
                target="_blank">
                Chrome</a>, <a
                href="https://addons.mozilla.org/en-US/firefox/addon/pendo-launcher/"
                target="_blank"> Firefox</a>,
            and
            <a
                href="https://microsoftedge.microsoft.com/addons/detail/pendo-launcher/lgpofjmgggolmabddgdmbgipcnblpnbm"
                target="_blank">
                Edge</a>. <br><br>
            To install on third-party applications using the Pendo Launcher, use the following API key value. For
            information, see our
            <a
                href="https://support.pendo.io/hc/en-us/categories/21163846991131-Extension-implementation"
                target="_blank">Pendo Launcher documentation.</a>
        </div>
        <div class="extension-api-key">
            Your extension API key:
            <code>
                {{ extensionApiKey }}
            </code>
            <pendo-button
                v-pendo-clipboard="extensionApiKey"
                theme="app"
                type="link"
                prefix-icon="copy" />
        </div>
    </div>
</template>

<script>
import { PendoButton, PendoClipboard } from '@pendo/components';

export default {
    name: 'ExtensionInstallSnippet',
    components: {
        PendoButton
    },
    directives: {
        PendoClipboard
    },
    props: {
        subscription: {
            type: Object,
            required: true
        }
    },
    computed: {
        extensionApiKey () {
            return this.subscription.extensionApiKey;
        }
    }
};
</script>

<style scoped lang="scss">
.extension-install-snippet {
    padding: 16px;
}

.extension-api-key {
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;

    code {
        font-weight: 600;
        padding: 2px 4px;
        font-size: 90%;
        color: $pink-darker-1;
        background-color: #f9f2f4;
        border-radius: 4px;
    }
}
</style>
