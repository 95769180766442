import { $can } from '@pendo/permissions';
import get from 'lodash/get';

export function canDeleteResourceCenter (resourceCenter = null) {
    let furthestState = 'draft';
    if (get(resourceCenter, 'public', false)) {
        furthestState = 'public';
    }

    return (
        resourceCenter &&
        $can(resourceCenter.draft.homeView.appId)
            .entity('resourceCenter')
            .action('remove')
            .source(furthestState)
            .target(furthestState)
    );
}

export function canAppViewRC ({ appId, action }) {
    return $can(appId)
        .entity('resourceCenter')
        .action(action)
        .source('ANY')
        .target('ANY');
}

export function canViewResourceCenter (apps) {
    if (!apps.length) return false;
    const actions = ['create', 'edit', 'publish', 'remove'];

    return apps.some((app) => {
        return actions.some((action) => canAppViewRC({ appId: app.id, action }));
    });
}

export function canPublishResourceCenter (appId) {
    return $can(appId)
        .entity('resourceCenter')
        .action('publish')
        .source('public')
        .target('public');
}

export function canEditResourceCenter ({ appId, field }) {
    return $can(appId)
        .entity('resourceCenter', { field })
        .action('edit')
        .source('draft')
        .target('draft');
}

export function canCreateResourceCenter (apps) {
    return apps.some((app) => {
        return $can(app.id)
            .entity('resourceCenter')
            .action('create')
            .source('ANY')
            .target('ANY');
    });
}

export function canEditRCContent (appId) {
    if (!appId) return false;

    return canEditResourceCenter({ appId, field: 'content' });
}
