<template>
    <div class="image-upload">
        <upload-wrapper
            ref="uploader"
            data-cy="image-upload"
            remove-type="replace"
            upload-text="Click to upload image."
            :http-request="buildUploadRequest"
            :file-list="files"
            :loading-config="loadingConfig"
            @error="onError"
            @success="onUpload">
            <template #trigger>
                <pendo-icon v-bind="uploadIcon" />
                <div class="el-upload__helper-text">
                    Click to upload an image<br>
                    Under 10 MB for best performance
                </div>
            </template>
        </upload-wrapper>
    </div>
</template>

<script>
import { PendoIcon } from '@pendo/components';
import UploadWrapper from '@/components/common/UploadWrapper';
import { upload } from '@/utils/media';
import { origin } from '@pendo/services/Environments';

export default {
    name: 'ImageUpload',
    components: {
        UploadWrapper,
        PendoIcon
    },
    props: {
        guideId: {
            type: String,
            default: ''
        },
        appId: {
            type: String,
            default: ''
        },
        files: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            fileList: [],
            tempDownloadUrl: '',
            loading: false,
            loadingConfig: {
                type: 'feather',
                text: 'Loading...',
                loading: this.loading,
                spinnerProps: {
                    size: 48
                }
            },
            uploadIcon: {
                type: 'image',
                stroke: '#9A9CA5',
                size: 36,
                strokeWidth: 1.5
            }
        };
    },
    watch: {
        files (newFiles) {
            const isLoading = this.loading || this.loadingConfig.loading;
            if (isLoading || !newFiles.length || this.isPendoImage(newFiles[0].url)) return;
            this.loadingConfig.loading = true;
            setTimeout(() => {
                this.loadingConfig.loading = false;
                clearTimeout();
            }, 250);
        }
    },
    methods: {
        clearFiles () {
            this.onFilesUpdated([]);
        },
        replaceFile () {
            this.$refs.uploader.handlePreview(this.files[0]);
        },
        async buildUploadRequest (formData) {
            this.loading = true;
            this.$emit('loading', this.loading);
            this.tempDownloadUrl = await upload(
                { data: formData.file },
                this.guideId,
                origin(),
                this.appId,
                'guide',
                false,
                false
            );
            this.loading = false;
            this.$emit('loading', this.loading);
        },
        onUpload (event) {
            const { file } = event;
            this.onFilesUpdated([{ ...file, url: this.tempDownloadUrl }]);
            this.reset();
        },
        reset () {
            this.tempDownloadUrl = '';
        },
        onError () {
            const errorMessage = 'Upload failed. Please try again.';
            this.$emit('uploadError', { message: errorMessage });
            this.reset();
        },
        onFilesUpdated (files) {
            this.$emit('filesChanged', { files });
        },
        isPendoImage (url) {
            return url.includes('pendo-clp-static');
        }
    }
};
</script>

<style lang="scss">
.upload-wrapper {
    border: $gray-lighter-5 solid 1px;
    height: 150px;
    padding: 0;

    .el-upload__helper-text {
        font-weight: 500;
        color: $gray-lighter-2;
    }

    .el-upload__tip-text {
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        line-height: 14px;
        color: $gray-lighter-2;
        margin: 6px 0 16px;
    }

    &.is-showing-preview {
        border: none;

        .el-upload__tip-text {
            display: none;
        }
    }

    &.pendo-loading-parent--relative {
        height: 120px;
    }
}

.image-size-warning {
    margin-top: 6px;
    color: $yellow-lighter;
    font-size: 14px;
}
</style>
