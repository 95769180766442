import { ref } from 'vue';
import kebabCase from 'lodash/kebabCase';
import { PendoNotification } from '@pendo/components';
import { fetchAndDownloadFile } from '@/stateless-components/utils/file';

export default function useXliffDownload (guideId, guideName, authoredLanguage) {
    const isExportingXliff = ref(false);

    async function exportXliff (langCode) {
        const filePath =
            langCode === authoredLanguage?.value
                ? `/api/s/_SID_/guide/${guideId.value}/localization/export`
                : `/api/s/_SID_/guide/${guideId.value}/localization/export/${langCode}`;
        const fileName = `pendo_${kebabCase(guideName.value)}_${langCode}.xliff`;

        isExportingXliff.value = true;

        try {
            await fetchAndDownloadFile(filePath, fileName);
        } catch (err) {
            PendoNotification({
                type: 'error',
                message: 'There was an error downloading the file. Please try again',
                duration: 0
            });
            throw err;
        } finally {
            isExportingXliff.value = false;
        }
    }

    return {
        isExportingXliff,
        exportXliff
    };
}
