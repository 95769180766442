/* eslint-disable prefer-destructuring,no-param-reassign */
import moment, { DATE_FORMAT } from '@/utils/moment';
import { pluralize } from '@/utils/formatters';

export function getTimeSeriesForCustomRange ({ count, value }) {
    const period = count < 2 ? 'dayRange' : 'daily';
    // value.start and value.end are iso formatted values e.g. 2020-03-20
    // backend handles all timezone calculations when passed in this format

    return {
        period,
        first: `date("${value.start}")`,
        count: count === 0 ? 1 : count
    };
}

export function getTimeSeriesForFixedRange ({ count }) {
    const period = count < 2 ? 'dayRange' : 'daily';

    if (count === 0) {
        return {
            period,
            first: 'now()',
            last: 'now()'
        };
    }

    return {
        period,
        first: `dateAdd(startOfPeriod("${period}", now()), -${count}, "days")`,
        last: `dateAdd(startOfPeriod("${period}", now()), -1, "days")`
    };
}

export function getFormattedDateRange ({ count, value }) {
    const { start, end } = value;
    const formattedStart = start.format(DATE_FORMAT.short);
    const formattedEnd = end.format(DATE_FORMAT.short);

    if (count < 2) {
        return formattedStart;
    }

    return `${formattedStart} to ${formattedEnd}`;
}

export function getStartDateForCount (count) {
    return moment()
        .subtract(count, 'days')
        .startOf('day')
        .toDate();
}

export function getStartDateForTimestamp (timestamp) {
    return moment(timestamp)
        .startOf('day')
        .toDate();
}

export function fixedDateRanges () {
    return [
        {
            id: 'today',
            label: 'Today',
            count: 0,
            value: {
                start: moment()
                    .startOf('day')
                    .toDate(),
                end: moment()
                    .startOf('day')
                    .toDate()
            }
        },
        {
            id: 'yesterday',
            label: 'Yesterday',
            count: 1,
            value: {
                start: getStartDateForCount(1),
                end: getStartDateForCount(1)
            }
        },
        {
            id: 'last7days',
            label: 'Last 7 Days',
            count: 7,
            value: {
                start: getStartDateForCount(7),
                end: getStartDateForCount(1)
            }
        },
        {
            id: 'last14days',
            label: 'Last 14 Days',
            count: 14,
            value: {
                start: getStartDateForCount(14),
                end: getStartDateForCount(1)
            }
        },
        {
            id: 'last30days',
            label: 'Last 30 Days',
            count: 30,
            value: {
                start: getStartDateForCount(30),
                end: getStartDateForCount(1)
            }
        },
        {
            id: 'last60days',
            label: 'Last 60 Days',
            count: 60,
            value: {
                start: getStartDateForCount(60),
                end: getStartDateForCount(1)
            }
        },
        {
            id: 'last90days',
            label: 'Last 90 Days',
            count: 90,
            value: {
                start: getStartDateForCount(90),
                end: getStartDateForCount(1)
            }
        }
    ];
}

export function findMatchingFixedDateRange ({ start, end, id }) {
    const ranges = fixedDateRanges();

    return ranges.find(({ value, id: rangeId }) => {
        if (id === rangeId) {
            return true;
        }

        const startDatesMatch = start.format(DATE_FORMAT.iso) === moment(value.start).format(DATE_FORMAT.iso);
        const endDatesMatch = end.format(DATE_FORMAT.iso) === moment(value.end).format(DATE_FORMAT.iso);

        return startDatesMatch && endDatesMatch;
    });
}

export function convertValueToDateRangeConfig ({ value, id }, appFirstVisitTimestamp) {
    const appFirstVisit = moment(appFirstVisitTimestamp);

    let start = moment(value.start);
    let end = moment(value.end);
    if (start.isBefore(appFirstVisit)) {
        start = moment(appFirstVisit);
    }

    if (end.isBefore(start)) {
        // set as 'today' to be safe
        end = moment();
    }

    const count = end.diff(start, 'days') + 1;

    // check if custom date range is same as one of lastNDays fixed type
    const fixedDateRange = findMatchingFixedDateRange({ start, end, id });
    if (fixedDateRange) {
        start = moment(fixedDateRange.value.start);
        end = moment(fixedDateRange.value.end);

        return {
            ...fixedDateRange,
            aside: `(${getFormattedDateRange({ count, value: { start, end } })})`,
            value: {
                start: start.format(DATE_FORMAT.iso),
                end: end.format(DATE_FORMAT.iso)
            }
        };
    }

    return {
        id: 'custom',
        label: getFormattedDateRange({ count, value: { start, end } }),
        count,
        value: {
            start: start.format(DATE_FORMAT.iso),
            end: end.format(DATE_FORMAT.iso)
        }
    };
}

export function validPeriodsForCount (count) {
    if (count < 2) {
        return ['hourly'];
    }

    if (count === 2) {
        return ['hourly', 'daily'];
    }

    if (count > 2 && count < 14) {
        return ['daily'];
    }

    if (count >= 14 && count < 56) {
        return ['daily', 'weekly'];
    }

    if (count >= 56 && count < 90) {
        return ['daily', 'weekly', 'monthly'];
    }

    return ['weekly', 'monthly'];
}

export function convertPeriodStringToDateMathString (period) {
    switch (period) {
        case 'hourly':
            return 'hours';
        case 'daily':
            return 'days';
        case 'weekly':
            return 'weeks';
        case 'monthly':
            return 'months';
    }
}

export function getTimeSeries (dateRange) {
    let { count, value } = dateRange;
    const { start, end } = value;
    let period;
    let first;

    if (count < 2) {
        count = 24;
        period = 'hourly';
        first = `startOfPeriod("daily", date("${start}"))`;
    } else if (count <= 30) {
        period = 'daily';
        first = `startOfPeriod("daily", date("${start}"))`;
    } else {
        count = Math.ceil(count / 7);
        if (moment(start).day() - moment(end).day() > 0) {
            count++;
        }

        period = 'weekly';
        first = `startOfPeriod("weekly", date("${start}"))`;
    }

    return {
        count,
        period,
        first
    };
}

export function getTimeSeriesByPeriod (period, dateRange) {
    let { count, value } = dateRange;
    const { start, end } = value;

    if (period === 'hourly') {
        if (count === 0) count = 24;
        else count *= 24;
    } else if (period === 'weekly') {
        count = Math.ceil(count / 7);
        if (moment(start).day() - moment(end).day() > 0) {
            count++;
        }
    } else if (period === 'monthly') {
        count = getMonthlyCount(value);
    }

    return {
        period,
        first: `startOfPeriod("${period}", date("${start}"))`,
        count
    };
}

export function getYears (start, end) {
    const startYear = moment(start).year();
    const endYear = moment(end).year();

    return { startYear, endYear };
}

export function getMonthlyCount ({ start, end }) {
    const { startYear, endYear } = getYears(start, end);
    const MONTHS_IN_YEAR = 12;
    const startMonth = moment(start).month();
    const endMonth = moment(end).month();

    if (endYear === startYear) {
        return endMonth - startMonth + 1;
    }

    let months = MONTHS_IN_YEAR - startMonth + (endMonth + 1);
    months += MONTHS_IN_YEAR * (endYear - startYear - 1);

    return months;
}

export function iterativeToAggregativePeriod (iterativePeriod) {
    const translation = {
        hourRange: 'hourRange',
        hourly: 'hourRange',
        dayRange: 'dayRange',
        daily: 'dayRange',
        weekRange: 'weekRange',
        weekly: 'weekRange',
        monthRange: 'monthRange',
        monthly: 'monthRange'
    };

    return translation[iterativePeriod];
}

export function pageFeatureUsageTooltipFormatter (xDateFormat, yMetric, formatFunction) {
    const pointLabels = this.points.map((chartPoint) => {
        let metricLabel = yMetric.label;
        if (yMetric.id === 'views') {
            if (chartPoint.point.entityType === 'page') {
                metricLabel = 'Views';
            } else {
                metricLabel = 'Clicks';
            }
        }

        const coloredDot = `<span style="color:${chartPoint.series.color}">\u25CF</span>`;
        const pluralizedLabel = pluralize(metricLabel, chartPoint.y);
        const formattedLabel = `<b>${chartPoint.y} ${pluralizedLabel}</b>`;

        return `${coloredDot} ${chartPoint.series.name}: ${formattedLabel}`;
    });

    const joinedLabels = pointLabels.join('<br/>');

    return `${formatFunction(xDateFormat, this.x)}<br>${joinedLabels}`;
}

export function timestampFormatter ({ timestamp, period, upperCase, dayOfWeek }) {
    let formattedTimestamp;
    const momentifiedX = moment(timestamp);
    switch (period) {
        case 'hourly':
            formattedTimestamp = momentifiedX.format('MMMM D, h:mm A');
            break;
        case 'daily':
            formattedTimestamp = dayOfWeek
                ? momentifiedX.format('ddd, MMMM D, YYYY')
                : momentifiedX.format('MMMM D, YYYY');
            break;
        case 'weekly':
            // eslint-disable-next-line no-case-declarations
            const nextWeek = moment(timestamp).add(6, 'd');
            formattedTimestamp = `${momentifiedX.format('MMMM D')} - ${nextWeek.format('MMMM D, YYYY')}`;
            break;
        case 'monthly':
            formattedTimestamp = momentifiedX.format('MMMM YYYY');
    }
    if (upperCase) formattedTimestamp = formattedTimestamp.toUpperCase();

    return formattedTimestamp;
}
