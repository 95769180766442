/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';
import { parseSegmentIdForAggregation, identifiedState, PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '../utils/aggregations';

export function getGuideStepVisitorCount ({ appIds, timeSeries, guideId, guideStepId, segmentId }) {
    const spec = buildGuideStepVisitorSpec({ appIds, timeSeries, guideId, guideStepId, segmentId });

    return request(spec);
}

export function buildGuideStepVisitorSpec ({ appIds, timeSeries, guideId, guideStepId, segmentId }) {
    const { operators: o, common: c } = Agg2;

    const internalTimeSeries = {
        ...timeSeries,
        period: 'dayRange'
    };

    return o.aggregation(
        {
            name: 'guide-step-visitor-count',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, timeSeries: internalTimeSeries, guideId, guideStepId }),
            o.filter('type=="guideSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.reduce(
                ...c.groupFields({
                    count: o.count('visitorId')
                })
            )
        )
    );
}

export function getGuideViews ({ appIds, timeSeries, segmentId }) {
    const spec = buildGuideViewsSpec({ appIds, timeSeries, segmentId });

    return request(spec);
}

export function buildGuideViewsSpec ({ appIds, timeSeries, segmentId }) {
    const { operators: o, common: c } = Agg2;

    return o.aggregation(
        {
            name: 'guide-views',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, timeSeries }),
            o.filter('type=="guideSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(
                'guideId',
                ...c.groupFields({
                    numViews: o.count(null),
                    numVisitors: o.count('visitorId')
                })
            )
        )
    );
}

export function getGuideTotalViewsByPeriod ({ appIds, guideId, segmentId, metric, timeSeries }) {
    const spec = buildGuideTotalViewsByPeriodSpec({ appIds, guideId, segmentId, timeSeries, metric });

    return request(spec, { rowsOnly: false });
}

export function buildGuideTotalViewsByPeriodSpec ({ appIds, guideId, segmentId, timeSeries, metric }) {
    const { operators: o } = Agg2;
    const spec = o.aggregation(
        {
            name: 'guide-total-views-by-period',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, guideId, timeSeries }),
            o.filter('type == "guideSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.reduce({
                count: o.count(metric === 'visitor' ? 'visitorId' : null)
            })
        )
    );

    return spec;
}

export function getGuideFirstTimeViews ({ appIds, guideId, createdAt, timeSeries, segmentId }) {
    const spec = buildGuideFirstTimeViewsSpec({ appIds, guideId, createdAt, timeSeries, segmentId });

    return request(spec);
}

export function buildGuideFirstTimeViewsSpec ({ appIds, guideId, createdAt, timeSeries, segmentId }) {
    const { operators: o } = Agg2;
    const guideTimeSeries = {
        period: 'dayRange',
        first: createdAt,
        last: 'now()'
    };

    let period;
    if (timeSeries.period === 'hourly') {
        period = 'hours';
    } else if (timeSeries.period === 'weekly') {
        period = 'weeks';
    } else if (timeSeries.period === 'monthly') {
        period = 'months';
    } else {
        period = 'days';
    }

    const spec = o.aggregation(
        {
            name: 'guide-first-time-views',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.guideEvents({
                appId: appIds,
                guideId,
                timeSeries: guideTimeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.filter('type == "guideSeen"'),
            o.evaluate({
                timePeriodStart: `startOfPeriod("${timeSeries.period}", browserTime)`
            }),
            o.group(['visitorId'], o.groupField('firstTime', { min: 'timePeriodStart' })),
            o.group(['firstTime'], o.groupField('count', o.count(null))),
            o.filter(
                `firstTime >= ${timeSeries.first} && firstTime < dateAdd(startOfPeriod("${timeSeries.period}", ${timeSeries.first}), ${timeSeries.count}, "${period}")`
            )
        )
    );

    return spec;
}
