var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-panel",attrs:{"tabindex":"0"}},[_vm._m(0),_c('div',{staticClass:"subscriptions-list"},_vm._l((_vm.sortedSubs),function(sub,index){return _c('div',{key:sub.name,attrs:{"tabindex":"0"}},[(sub.id === _vm.activeSub.id)?_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                    content: sub.displayName,
                    delay: { show: 300, hide: 0 },
                    placement: 'right',
                    container: false
                }),expression:"{\n                    content: sub.displayName,\n                    delay: { show: 300, hide: 0 },\n                    placement: 'right',\n                    container: false\n                }"}],staticClass:"subscription subscription--active"},[_c('pendo-icon',{staticClass:"active-sub-check",attrs:{"stroke":"#E6416A","stroke-width":"3","type":"check","size":"20"}}),_c('span',{ref:'subDisplayName' + index,refInFor:true,class:_vm.isImpersonating ? 'active-sub-impersonating' : 'active-sub'},[_vm._v(_vm._s(sub.displayName))])],1):_c('div',{staticClass:"subscription",on:{"click":function($event){$event.preventDefault();return _vm.onSubClick(sub)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSubClick(sub)}}},[_c('span',{ref:'subDisplayName' + index,refInFor:true,staticClass:"inactive-sub"},[_vm._v(_vm._s(sub.displayName))])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrapper"},[_c('h2',{staticClass:"header"},[_vm._v(" Subscriptions ")])])
}]

export { render, staticRenderFns }