export const state = getInitialState();

export const mutations = {
    setDesignerUrl (state, url) {
        state.url = url;
    },
    setLastAppUrlMap (state, lastAppUrlMap) {
        state.lastAppUrlMap = lastAppUrlMap;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    updateDesignerUrl ({ commit, dispatch, state }, { url, appId }) {
        const newLastUrlMap = {
            ...state.lastAppUrlMap,
            [appId]: url
        };

        commit('setDesignerUrl', url);
        commit('setLastAppUrlMap', newLastUrlMap);
        dispatch(
            'userSettings/updateAppNamespaceSetting',
            { name: 'lastDesignerUrl', value: url, appId },
            { root: true }
        );
    }
};

export function getInitialState () {
    return {
        url: '',
        lastAppUrlMap: {}
    };
}

export const getters = {
    lastUrlByAppId: (state) => (id) => {
        const urlMap = state.lastAppUrlMap;

        return urlMap[id];
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
