import { pluralize } from '@/utils/formatters';
import { prettyTime } from '@pendo/services/Formatters';
import get from 'lodash/get';
import unfilledInfoSvg from '@/img/tooltip-icons/unfilled-info.svg';

export const CHART_COLORS = {
    green100: 'rgba(34, 153, 143, 1.0)',
    green50: 'rgba(34, 153, 143, 0.5)',
    green10: 'rgba(34, 153, 143, 0.1)'
};

export const chartColors = [
    '#229CA8',
    '#FF4876',
    '#8DDDB6',
    '#0B9AD3',
    '#A973F2',
    '#EFBE53',
    '#07699B',
    '#65D036',
    '#E14CD3',
    '#FF9237'
];

export const crossAppChartColors = [
    '#0B9AD3',
    '#FF9237',
    '#65D036',
    '#A973F2',
    '#E14CD3',
    '#EFBE53',
    '#07699B',
    '#8DDDB6',
    '#FF4876',
    '#229CA8'
];

export const CHART_BASE = {
    chart: {
        style: {
            fontFamily: 'Inter'
        },
        spacing: [32, 0, 0, 0]
    },
    credits: {
        enabled: false
    },
    title: {
        text: null
    },
    tooltip: {
        backgroundColor: '#FFFFFF',
        enabled: true,
        padding: 16,
        headerFormat: '<span style="font-size: 12px">{point.key}</span><br/>',
        style: {
            fontSize: '12px',
            color: '#2a2c35',
            fill: '#2a2c35',
            lineHeight: '19px'
        },
        crosshairs: true,
        hideDelay: 100
    },
    xAxis: {
        crosshair: {
            color: '#DADCE5'
        },
        gridLineColor: '#DADCE5',
        lineColor: '#DADCE5',
        labels: {
            overflow: 'justify',
            y: 32,
            style: {
                color: '#6A6C75',
                fontSize: '12px',
                padding: '16px'
            }
        },
        minPadding: 0,
        maxPadding: 0,
        tickLength: 0, // hide ticks by default
        title: {
            text: null
        }
    },
    yAxis: {
        gridLineColor: '#DADCE5',
        min: 0,
        title: {
            text: null
        },
        labels: {
            align: 'left',
            x: 0,
            y: -8
        },
        minPadding: 0,
        tickPixelInterval: 92,
        allowDecimals: false,
        softMax: 1
    },
    plotOptions: {
        column: {
            pointPadding: 0
        }
    },
    colors: [CHART_COLORS.green100, CHART_COLORS.green50, CHART_COLORS.green10]
};

export const SCATTER_CHART_BASE = {
    series: [],
    chart: {
        zoomType: 'xy',
        type: 'scatter'
    },
    credits: {
        enabled: false
    },
    title: {
        text: null
    },
    tooltip: {
        headerFormat: '',
        formatter: null,
        padding: 10,
        crosshairs: false
    },
    yAxis: [
        {
            min: 0,
            crosshair: {
                width: 0
            },
            title: {},
            tickPixelInterval: 72
        }
    ],
    xAxis: [
        {
            title: {},
            crosshair: {
                width: 0
            },
            endOnTick: false,
            maxPadding: 0.05,
            type: 'linear',
            tickLength: 10,
            tickPixelInterval: 100,
            min: 0,
            allowDecimals: false
        }
    ]
};

export const LINE_CHART_BASE = {
    series: [],
    chart: {
        zoomType: 'xy',
        type: 'line'
    },
    credits: {
        enabled: false
    },
    title: {
        text: null
    },
    tooltip: {
        headerFormat: '',
        formatter: null,
        padding: 10
    },
    yAxis: [
        {
            allowDecimals: false,
            min: 0,
            title: {}
        }
    ],
    xAxis: [
        {
            startOfWeek: 0,
            dateTimeLabelFormats: {
                day: '%b %d',
                week: '%b %e',
                month: '%b %Y'
            },
            type: 'datetime',
            labels: {
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none'
                }
            }
        }
    ]
};

export function reusableTooltipFormatter ({
    headerTitle = '',
    headerDescription = '',
    bodyMetric = '',
    bodyDescription = '',
    color = '##FFFFFF',
    showFooter = false,
    classes = []
} = {}) {
    const infoIcon = `<img src="${unfilledInfoSvg}"/>`;
    const circleIcon = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <circle cx="5" cy="5" r="5" fill="${color}"/>
                        </svg>`;
    let footer = '';
    if (showFooter) {
        footer = `<div  style="border: none; display:flex; flex-direction:row; padding: 16px 0 0 0;">
                    ${infoIcon}
                    <span style="margin-left: 8.5px"> Click chart to inspect</span>
                </div>`;
    }

    return `
        <div class="chart-tooltip-content ${classes.join(' ')}">
            <div class="chart-tooltip-header">
                <div class="chart-tooltip-header--title">
                    ${circleIcon}
                    <span class="header-title-text">${headerTitle}</span>
                </div>
                <span class="chart-tooltip-header--description">${headerDescription}</span>
            </div>
            <div class="chart-tooltip-body">
                <div class="chart-tooltip-body--metric">${bodyMetric}</div>
                <div class="chart-tooltip-body--description">${bodyDescription}</div>
                ${footer}
            </div>
        </div> `;
}

export function dataLabelFormatter () {
    const { y, selectedField } = this.point;
    const timeInMs = y * 60 * 1000;
    const formattedTimeValue = prettyTime(timeInMs);
    let metric;
    switch (selectedField) {
        case 'averageDailyTimeOnApp':
            metric = `${formattedTimeValue}`;
            break;
        case 'summedMinutes':
            metric = `${formattedTimeValue}`;
            break;
        case 'visitors':
        default:
            metric = `${y}`;
    }

    return metric;
}

export function tooltipPointFormatter () {
    const { y, dateRangeLabel, selectedField } = this;

    const timeInMs = y * 60 * 1000;
    const formattedTimeValue = prettyTime(timeInMs);

    let metric;
    switch (selectedField) {
        case 'averageDailyTimeOnApp':
            metric = `Avg. Daily Time on Application: <b>${formattedTimeValue}</b>`;
            break;
        case 'summedMinutes':
            metric = `Time Spent: <b>${formattedTimeValue}</b>`;
            break;
        case 'visitors':
        default:
            metric = `<b>${y}</b> ${pluralize('visitor', +y)}`;
    }

    return `${dateRangeLabel}<br/>${metric}`;
}

export function tooltipPositioner (chart, box, point, target, options = {}) {
    /**
     * Place the tooltip in a chart without spilling over and not covering the point it self.
     * https://stackoverflow.com/questions/11966286/highcharts-tooltip-always-on-right-side-of-cursor
     */
    const { plotLeft, plotTop, plotWidth, plotHeight } = chart;
    const { boxWidth, boxHeight } = box;
    let { plotX, plotY } = point;
    plotY += plotTop;
    const { yBuffer = 10, xBuffer = 10, arrowBuffer = 10 } = options;
    let { x, y } = target;
    let alignedRight;

    // It is too far to the left, adjust it
    if (x < xBuffer) {
        x = plotLeft + plotX + xBuffer;
    }

    // It is too far to the right, move it back to be inside and then up to not cover the point
    if (x + boxWidth > plotLeft + plotWidth - xBuffer) {
        x -= x + boxWidth - (plotLeft + plotWidth - xBuffer);
        y = plotY - (boxHeight + arrowBuffer);
        alignedRight = true;
    }

    // If it is now above the plot area, align it to the top of the plot area
    if (y < yBuffer) {
        y = yBuffer;

        // If the tooltip is still covering the point, move it below instead
        if (alignedRight && plotY >= y && plotY <= y + boxHeight) {
            y = plotY + arrowBuffer;
        }
    }

    // Now if the tooltip is below the chart, move it up. It's better to cover the point than to disappear outside the chart
    if (y + boxHeight > plotTop + plotHeight) {
        y = Math.max(plotTop, plotTop + plotHeight - boxHeight - yBuffer);
    }

    return { x, y };
}

export function positionTooltipRightOfColumn (chart, box, point) {
    const columnWidth = get(chart, 'series[0].points[0].pointWidth', 20);
    const axisOffset = get(chart, 'axisOffset', [0, 0, 0, 0]);
    const midPoint = point.plotX + axisOffset[3];
    const rightColumnEdge = midPoint + columnWidth / 2;
    const topColumnEdge = point.plotY + chart.plotTop;

    const position = tooltipPositioner(chart, box, point, {
        x: rightColumnEdge + 10,
        y: point.plotY
    });

    // If tooltip is next to bar but bar is too low for the tooltip to show an arrow, move above the bar
    if (position.x > rightColumnEdge) {
        const boxBottomEdge = position.y + box.boxHeight;
        if (boxBottomEdge < topColumnEdge + 10 && boxBottomEdge > topColumnEdge - 10) {
            position.y = topColumnEdge - box.boxHeight - 10;
        }
    }

    // If tooltip ends up inside the bar or above the bar, make sure it is far enough left to cover the middle so the arrow can display
    if (position.x > midPoint - 10 && position.x < rightColumnEdge) {
        position.x = midPoint - columnWidth / 2;
    }
    if (position.y + box.boxHeight <= topColumnEdge && position.x > midPoint - 10) {
        position.x = midPoint - columnWidth / 2;
    }

    return position;
}
