<template>
    <div
        v-if="isFetching"
        class="fetching-empty-state" />
    <pendo-environment-settings
        v-else
        :staging="null"
        :production="production"
        :is-pendo-enabled="isPendoEnabled"
        :pendo-disabled-text="pendoDisabledText"
        :is-confirmation-modal-visible="isConfirmationModalVisible"
        :is-confirm-loading="isConfirmLoading"
        :is-agent-settings-modal-visible="isAgentSettingsModalVisible"
        :is-save-loading="isSaveLoading"
        :agent-versions="formattedAgentVersions"
        @togglePendo="() => (isConfirmationModalVisible = true)"
        @openAgentSettingsModal="() => (isAgentSettingsModalVisible = true)"
        @downloadAgent="downloadAgent"
        @closeSettings="() => (isAgentSettingsModalVisible = false)"
        @save="saveSettings"
        @confirmChange="confirmChange"
        @cancelChange="() => (isConfirmationModalVisible = false)" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PendoEnvironmentSettings, PendoNotification } from '@pendo/components';
import semver from 'semver';

import cloneDeep from 'lodash/cloneDeep';
import {
    environmentBucket,
    getAgentVersions,
    fetchEnv,
    patchEnv,
    getEnvSettings,
    formatLabel,
    supportsJWTIfEnabled
} from '@/utils/environment';

export default {
    name: 'EnvironmentSettings',
    components: {
        PendoEnvironmentSettings
    },
    props: {
        app: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            staging: null,
            productionEnv: null,
            agentVersions: {},
            isFetching: false,
            pendoDisabledText: '',
            lightningAgentVersionForDownload: '',
            isAwaitingAgentLightningDowload: false,
            isConfirmLoading: false,
            isSaveLoading: false,
            isResetLoading: false,
            agentUpdatePolicy: '',
            agentDescription: '',
            agentType: '',
            confirmButtonConfig: {},
            confirmTitle: null,
            confirmMessage: '',
            isAgentSettingsModalVisible: false,
            isConfirmationModalVisible: false
        };
    },
    computed: {
        ...mapGetters({
            activeSub: 'subscriptions/active',
            activeSubHasFlag: 'subscriptions/activeSubHasFlag',
            getActiveTimezone: 'subscriptions/getTimezone'
        }),
        isPendoEnabled () {
            return !this.app.applicationFlags.disablePendo;
        },
        formattedAgentVersions () {
            return Object.entries(cloneDeep(this.agentVersions))
                .filter(
                    ([versionNum, version]) =>
                        semver.valid(versionNum) &&
                        !(versionNum.startsWith('$') || versionNum === 'toJSON') &&
                        !version.deprecated &&
                        supportsJWTIfEnabled(versionNum, this.app)
                )
                .map(([versionNum, version]) => {
                    return {
                        id: versionNum,
                        label: formatLabel(
                            this.getActiveTimezone,
                            version,
                            versionNum,
                            version.released,
                            versionNum === this.agentVersions.latest.version
                        ),
                        value: versionNum,
                        parsedDate: Date.parse(version.released) // for sorting
                    };
                })
                .sort((a, b) => b.parsedDate - a.parsedDate);
        },
        production () {
            const agentSupportsKillSwitch = semver.satisfies(this.productionEnv.agent.agentVersion, '>=2.175.0');
            const hasGuideValidation = this.activeSubHasFlag('guideValidation');

            return {
                ...getEnvSettings({ ...this.productionEnv, activeTimezone: this.getActiveTimezone }),
                agentSupportsKillSwitch,
                hasGuideValidation,
                agentVersionIsDeprecated: !!this.agentVersions[this.productionEnv.agent.agentVersion].deprecated
            };
        }
    },
    async created () {
        this.isFetching = true;
        this.agentVersions = await getAgentVersions(environmentBucket());
        this.productionEnv = await fetchEnv({ env: 'production', appId: this.app.id });
        this.isFetching = false;
    },
    methods: {
        ...mapActions({
            updateAppFlag: 'apps/updateFlag'
        }),
        downloadAgent ({ agentType, description }) {
            const downloadURL = `/download/s/${this.activeSub.id}/environment/${description}/agent/body/${agentType}?appId=${this.app.id}`;
            window.open(downloadURL, '_self');
        },
        async saveSettings ({ agentPolicy, agentVersion, agentType, description }) {
            this.isSaveLoading = true;
            const agentUpdatePolicy = ['beta', 'stable'].includes(agentPolicy) ? agentPolicy : `=${agentVersion}`;
            try {
                await patchEnv({ appId: this.app.id, description, agentType, agentUpdatePolicy });
                if (description === 'production') {
                    this.productionEnv = await fetchEnv({ env: 'production', appId: this.app.id });
                }
            } catch (err) {
                PendoNotification({
                    type: 'error',
                    message:
                        'Something went wrong. Try reloading the page or contacting support if this issue continues.',
                    duration: 5000
                });
            } finally {
                this.isSaveLoading = false;
                this.isAgentSettingsModalVisible = false;
            }
        },
        async confirmChange () {
            this.isConfirmLoading = true;
            try {
                await this.updateAppFlag({ appId: this.app.id, flag: 'disablePendo', enabled: this.isPendoEnabled });
            } catch (err) {
                PendoNotification({
                    type: 'error',
                    message:
                        'Something went wrong. Try reloading the page or contacting support if this issue continues.',
                    duration: 5000
                });
            } finally {
                this.isConfirmLoading = false;
                this.isConfirmationModalVisible = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.fetching-empty-state {
    height: 100%;
    width: 100%;
}
</style>
