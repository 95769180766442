<template>
    <pendo-table
        v-stateless-pendo-table-user-settings-watcher="{
            userSettings: tableUserSettings
        }"
        csv-download
        :columns="columns"
        :data="tableData"
        row-key="id"
        manage-columns
        :height="600"
        :filters="filters"
        :tree-config="{ children: 'guides' }"
        :csv-config="csvConfig"
        @tableSettingsUpdate="handleTableSettingsUpdate"
        @table-data-change="checkEmptyTable"
        @link-click="onMetricClick">
        <template #headerLeft>
            Task Lists
        </template>
        <template #headerActions>
            <search
                v-model="searchInput"
                placeholder="Search" />
        </template>
        <template #status>
            <pendo-status-overlay :state="taskListTableStatus" />
        </template>
        <template #name="{ row }">
            <a
                v-if="row.parentId"
                rel="noopener"
                :href="getGuideLink(row, guideRouteIsQueryParam)"
                target="_blank">
                {{ row.name }}
            </a>
            {{ !row.parentId ? row.name : null }}
        </template>
        <template #state="{ row }">
            <pendo-guide-status-cell
                v-if="row.parentId"
                :row="row"
                :interactive="false" />
        </template>
    </pendo-table>
</template>

<script>
import { PendoTable, PendoStatusOverlay, PendoGuideStatusCell } from '@pendo/components';
import Search from '@/components/Search.vue';
import { LOADING_STATES } from '@pendo/agg-connect';
import { TASK_LIST_COLUMNS, taskListTableUserSettingsName } from '@/stateless-components/utils/task-list';
import { getGuideLink } from '@/stateless-components/utils/guides';
import { getSegmentName } from '@/utils/segments';
import { get, keyBy } from 'lodash';
import StatelessPendoTableUserSettingsWatcher from '@/stateless-components/directives/StatelessPendoTableUserSettingsWatcher';
import { mergeDefaultColumnsWithUserSettings } from '@/stateless-components/utils/table-user-settings';

export default {
    name: 'TaskListMetricsTable',
    components: {
        PendoTable,
        Search,
        PendoStatusOverlay,
        PendoGuideStatusCell
    },
    directives: {
        StatelessPendoTableUserSettingsWatcher
    },
    props: {
        aggStream: {
            type: Object,
            default: null
        },
        taskListData: {
            type: Array,
            default: null
        },
        segmentsMap: {
            type: Object,
            default: null
        },
        tableUserSettings: {
            type: Object,
            default: () => ({})
        },
        guideRouteIsQueryParam: {
            type: Boolean,
            default: false
        },
        csvConfig: {
            type: Object,
            default: () => ({
                fileName: 'Guide Task List'
            })
        }
    },
    data () {
        return {
            tableData: [],
            searchInput: '',
            taskListTableStatus: LOADING_STATES.LOADING
        };
    },
    computed: {
        filters () {
            return [
                {
                    prop: ['name', 'displayName'],
                    type: 'includes',
                    value: this.searchInput
                }
            ];
        },
        taskListItems () {
            return keyBy(
                this.taskListData.flatMap((taskList) => taskList.templateChildren),
                'id'
            );
        },
        columns () {
            const mergedColumns = mergeDefaultColumnsWithUserSettings(TASK_LIST_COLUMNS, this.tableUserSettings);
            mergedColumns.unshift({
                type: 'tree',
                visible: true,
                key: 'tree-column'
            });

            return mergedColumns;
        }
    },
    watch: {
        aggStream: {
            handler (stream) {
                if (!stream) return;

                stream.subscribe(({ status, value }) => {
                    const { LOADING, RESOLVED, REJECTED, EMPTY } = LOADING_STATES;

                    switch (status) {
                        case RESOLVED:
                            this.tableData = this.processTaskListMetricsTableAggResponse(value);
                            break;

                        case LOADING:
                            this.taskListTableStatus = this.taskListData.length ? EMPTY : LOADING;

                            break;

                        case REJECTED:
                            this.taskListTableStatus = this.taskListData.length ? EMPTY : REJECTED;
                    }
                });
            },
            immediate: true
        }
    },
    methods: {
        getGuideLink,
        checkEmptyTable ({ data }) {
            const { RESOLVED, EMPTY, REJECTED, LOADING } = LOADING_STATES;

            if (this.taskListTableStatus === REJECTED || this.taskListTableStatus === LOADING) return;

            if (!data.length) {
                this.taskListTableStatus = EMPTY;
            } else {
                this.taskListTableStatus = RESOLVED;
            }
        },
        handleTableSettingsUpdate (newSettings) {
            this.$emit('tableSettingsUpdate', taskListTableUserSettingsName, newSettings);
        },
        onMetricClick (event) {
            const { row } = event;
            this.$emit('onMetricClick', row);
        },
        processTaskListMetricsTableAggResponse (aggResponse) {
            const processedAggResponse = this.taskListData.map((taskList) => {
                const row = aggResponse.find((row) => row.guideStepId === taskList.stepId) || {};

                const processedGuides = this.processTaskListGuides(row);

                return {
                    id: row.guideStepId || taskList.stepId,
                    name: `Step ${taskList.stepIndex + 1}: ${taskList.header}`,
                    views: row.stepViews || 0,
                    visitors: row.uniqueStepVisitors || 0,
                    guides: processedGuides
                };
            });

            return processedAggResponse;
        },
        processTaskListGuides (row) {
            const processedGuides = [];

            const {
                guideIds,
                guideNames,
                guideViews,
                uniqueGuideVisitors,
                guideClicks,
                guideStatuses,
                guideSegments
            } = row;

            if (!guideIds) {
                return;
            }

            while (guideIds.length) {
                const guideId = guideIds.shift();
                const guideSegment = guideSegments.shift();
                const segmentId = get(guideSegment, 'filters[0].segmentId');
                const segment = getSegmentName(segmentId, this.segmentsMap);
                const displayName = this.taskListItems[guideId].title;
                const state = guideStatuses ? guideStatuses.shift() : '--';
                const clicks = guideClicks ? guideClicks.shift() : '--';

                processedGuides.push({
                    id: guideId,
                    parentId: row.guideStepId,
                    name: guideNames.shift(),
                    displayName,
                    state,
                    views: guideViews.shift(),
                    visitors: uniqueGuideVisitors.shift(),
                    segment,
                    clicks
                });
            }

            return processedGuides;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .pendo-table__column:not(.pendo-table__column--actions) {
    .pendo-table__cell:empty::after {
        content: '';
    }
}
</style>
