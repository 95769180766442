<template>
    <pendo-card>
        <pendo-exclude-include-staging-list-detail
            type="staging"
            server-host-domain-label="Staging Websites"
            staging-modal-alert-link="https://adoptsupport.pendo.io/hc/en-us/articles/16761782922395"
            sub-has-extension-app
            :account-uses-include-list="() => false"
            :matches="matches"
            :fetch-my-ip-address="fetchMyIpAddress"
            :fetch-hosts-for-type="fetchHostsForType"
            :update-host="updateHost"
            :fetch-exclude-list-for-type="fetchExcludeListForType"
            :create-exclude-list-item="createExcludeListItem"
            :remove-exclude-list-item="removeExcludeListItem" />
    </pendo-card>
</template>

<script>
import { PendoCard, PendoExcludeIncludeStagingListDetail } from '@pendo/components';
import {
    fetchHostsForType,
    updateHost,
    fetchExcludeListForType,
    createExcludeListItem,
    removeExcludeListItem,
    fetchMyIpAddress
} from '@/utils/staging.js';
import isUndefined from 'lodash/isUndefined';
import isObject from 'lodash/isObject';
import invoke from 'lodash/invoke';
import { minimatch } from 'minimatch';

const matches = (name, type, item) => {
    if (isUndefined(item) && isObject(type)) {
        item = type;

        type = invoke(item, 'type.replace', /Glob$/, '');
    }

    if (!item) {
        return false;
    }
    if (item.type === type) {
        return name === item.name;
    }
    if (item.type === `${type}Glob`) {
        return minimatch(name, item.name) && !/\r/.exec(item.name);
    }

    return false;
};

export default {
    name: 'Staging',
    components: {
        PendoCard,
        PendoExcludeIncludeStagingListDetail
    },
    data () {
        return {
            matches,
            fetchHostsForType,
            updateHost,
            fetchExcludeListForType,
            createExcludeListItem,
            removeExcludeListItem,
            fetchMyIpAddress
        };
    }
};
</script>
