import { http } from '@pendo/http';
const serverAPI = '/api/s/_SID_/servername';
const excludeListAPI = '/api/s/_SID_/blacklist';

export const RESULT_LIMIT = 1000;

export function fetchHostsForType (type, invertSearch, prefixSearch = '') {
    invertSearch = invertSearch ? '~' : '';

    return http.get(`${serverAPI}/flag/${invertSearch}${type}?limit=${RESULT_LIMIT}&prefix=${prefixSearch}`);
}

export function updateHost (host) {
    return http.put(`${serverAPI}/${host.name}`, host);
}

export function fetchExcludeListForType (type, prefixSearch = '') {
    return http.get(`${excludeListAPI}/type/${type}?limit=${RESULT_LIMIT}&prefix=${prefixSearch}`);
}

export function createExcludeListItem (item) {
    return http.post(`${excludeListAPI}`, item);
}

export function removeExcludeListItem (item) {
    return http.delete(`${excludeListAPI}/${item.id}`, item);
}

export function fetchMyIpAddress () {
    return http.get('/api/s/_SID_/helper/whatismyip');
}
