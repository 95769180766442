<template>
    <div class="app-details">
        <pendo-app-details
            staging-modal-alert-link="https://adoptsupport.pendo.io/hc/en-us/articles/16761782922395"
            :app="app"
            :subscription="subscription"
            :consuming-platform="'adopt'"
            :favicon-url="faviconUrl"
            :updating-app-flag="updatingAppFlag || ''"
            :updating-app="updatingApp"
            :saving-changes="saving"
            :submission-error="submissionError"
            @fetch-url="getFaviconByUrl"
            @update-app-flag="handleFlagChange"
            @update-app="handleUpdateApp"
            @update-app-name="onDisplayNameChange"
            @update-websites="handleDomainUpdate"
            @update-suggest-page-tags="onSuggestPageTagsChange" />
        <custom-html-attributes
            :app="app"
            @error="displayErrorToast" />
    </div>
</template>

<script>
import { PendoNotification, PendoAppDetails } from '@pendo/components';
import { mapActions, mapState } from 'vuex';
import { getFavicon } from '@/utils/favicon';
import startCase from 'lodash/startCase';
import CustomHtmlAttributes from '@/components/settings/app-settings/CustomHtmlAttributes';
import get from 'lodash/get';

export default {
    name: 'AppDetails',
    components: {
        PendoAppDetails,
        CustomHtmlAttributes
    },
    directives: {
        PendoNotification
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        subscription: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            faviconUrl: {},
            submissionError: null,
            saving: false
        };
    },
    computed: {
        ...mapState({
            updatingAppFlag: (state) => state.apps.updatingFlag,
            updatingApp: (state) => state.apps.updatingApp
        })
    },
    methods: {
        ...mapActions({
            updateApp: 'apps/updateApp',
            updateAppFlag: 'apps/updateFlag',
            updateExtensionDomainPatterns: 'apps/updateExtensionDomainPatterns',
            pagesHydrate: 'pages/hydrate',
            fetchUserPrefs: 'userSettings/fetch'
        }),
        async onDisplayNameChange (updates) {
            try {
                await this.updateApp(updates);
            } catch (error) {
                this.displayErrorToast('setting display name');
            }
        },
        async handleFlagChange (updatedFlagObject) {
            try {
                await this.updateAppFlag(updatedFlagObject);
            } catch (error) {
                this.displayErrorToast('changing setting');
            }
        },
        async handleUpdateApp (updates) {
            try {
                await this.updateApp(updates);
            } catch (error) {
                this.displayErrorToast('changing setting');
            }
        },
        async handleDomainUpdate (updatedDomainPatterns) {
            this.submissionError = null;
            this.saving = true;
            try {
                await this.updateExtensionDomainPatterns({
                    id: this.app.id,
                    propertyName: 'extensionDomainPatterns',
                    propertyValue: updatedDomainPatterns
                });
            } catch (error) {
                this.submissionError = {};
                this.submissionError.errorMessage = get(error, 'data.error', 'Unable to update websites at this time.');
                this.submissionError.duplicatePatternErrors = get(error, 'data.duplicates', null);
                this.submissionError.invalidPatternErrors = get(error, 'data.invalidPatterns', null);
            } finally {
                this.saving = false;
            }
        },
        async getFaviconByUrl (url) {
            const tempFavicon = await getFavicon(url);
            this.faviconUrl = { [url]: tempFavicon || `invalid url: ${url}` };
        },
        displayErrorToast (actionCausingError) {
            PendoNotification({
                type: 'error',
                title: `Error ${startCase(actionCausingError)}`,
                message: 'Pendo encountered an error. Please refresh your page and try again.',
                duration: 7000
            });
        },
        async onSuggestPageTagsChange (event) {
            try {
                await this.handleFlagChange(event);
                await this.fetchUserPrefs();
                await this.pagesHydrate();
            } catch (error) {
                this.displayErrorToast('updating Suggested Page Tags');
            }
        }
    }
};
</script>
<style scoped lang="scss">
.app-details {
    display: grid;
    grid-gap: 32px;
}
</style>
