<template>
    <div class="license-utilization">
        <pendo-table
            v-pendo-loading:feather="isFetchingLicenseDataMap"
            :data="filteredApps"
            :columns="columns"
            row-key="appId"
            :filters="filters"
            auto-height
            :resizable="true"
            :default-sort="{ prop: 'name', order: 'ascending' }"
            title="License Summary">
            <template #headerActions>
                <pendo-multiselect
                    v-model="licenseFilterSelected"
                    class="license-utilization__table-filter"
                    placeholder="Select one"
                    :options="licenseFilterOptions"
                    :allow-empty="false" />
                <div class="license-utilization__table-search">
                    <search v-model="searchInput" />
                </div>
                <div
                    v-if="isAdmin"
                    class="license-utilization__csv-upload">
                    <license-csv-upload
                        :template-data="apps"
                        @uploaded="loadLicenseDataMap" />
                </div>
            </template>
            <template #name="{ row }">
                <pendo-app-display :apps="row" />
            </template>
            <template #appCategories="{ row }">
                <pendo-multiselect
                    :value="row.appCategories"
                    group-label-key="label"
                    group-options-key="categories"
                    :options="licenseCategoriesDisplayGrouping"
                    placeholder="None"
                    @input="onUpdateCategory(row, $event)">
                    <template #optionGroup="{ option }">
                        <div class="category-header-label">
                            {{ option.label }} ({{ option.categories.length }})
                        </div>
                    </template>
                </pendo-multiselect>
            </template>
            <template #quantity="{ row }">
                <pendo-editable-content
                    :key="row.appId"
                    :value="formatQuantity(row.quantity)"
                    type="text"
                    empty-text="Not set"
                    allow-empty
                    :before-exit="onUpdateQuantity.bind(null, row)"
                    :validation-rules="validationRules">
                    <template #append="{ pending, confirm, cancel }">
                        <pendo-button
                            theme="app"
                            icon="check"
                            data-cy="confirm"
                            :loading="pending"
                            type="primary"
                            @click="confirm" />
                        <pendo-button
                            theme="app"
                            icon="x"
                            data-cy="cancel"
                            type="secondary"
                            @click="cancel" />
                    </template>
                </pendo-editable-content>
            </template>
            <template #empty>
                <div class="license-utilization__table-empty">
                    <pendo-empty-state
                        title="Data not found"
                        description="Try changing filters or search criteria">
                        <template #icon>
                            <data-not-found-image />
                        </template>
                    </pendo-empty-state>
                </div>
            </template>
        </pendo-table>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
    PendoLoading,
    PendoTable,
    PendoMultiselect,
    PendoEditableContent,
    PendoButton,
    PendoNotification,
    PendoAppDisplay,
    PendoEmptyState
} from '@pendo/components';
import LicenseCsvUpload from '@/stateless-components/analytics/licenses/LicenseCsvUpload';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import Search from '@/components/Search';
import DataNotFoundImage from '@/components/svgs/DataNotFoundImage';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isInteger from 'lodash/isInteger';
import kebabCase from 'lodash/kebabCase';

export default {
    name: 'ManageLicenses',
    components: {
        PendoTable,
        PendoMultiselect,
        PendoEditableContent,
        PendoButton,
        PendoEmptyState,
        Search,
        LicenseCsvUpload,
        DataNotFoundImage,
        PendoAppDisplay
    },
    directives: {
        PendoLoading,
        PendoNotification
    },
    data () {
        return {
            searchInput: '',
            rules: [{ type: 'integer', min: 0, trigger: ['blur'] }],
            licenseFilterSelected: 'All',
            licenseFilterOptions: ['All', 'With Licenses', 'Without Licenses'],
            columns: [
                {
                    prop: 'name',
                    label: 'Application Name',
                    sortable: true
                },
                {
                    prop: 'appCategories',
                    label: 'Category',
                    sortable: true,
                    width: 300
                },
                {
                    prop: 'quantity',
                    label: '# of Licenses',
                    sortable: true,
                    width: 330
                },
                {
                    prop: 'lastUpdatedBy',
                    label: 'Last Updated By',
                    sortable: true
                },
                {
                    prop: 'lastUpdatedDate',
                    label: 'Last Updated Date',
                    formatter: (row) => this.formatDate(row.lastUpdatedDate),
                    sortable: true
                }
            ],
            validationRules: [
                {
                    type: 'any',
                    validator: (rule, value, callback) => {
                        const int = parseInt(value);
                        const pattern = /^\d+$/;

                        if ((!!value && (!isInteger(int) || int < 0)) || (!!value && !pattern.test(value))) {
                            return callback(new Error('Please enter a number greater than or equal to 0'));
                        }

                        callback();
                    },
                    trigger: ['input']
                }
            ]
        };
    },
    computed: {
        ...mapState({
            licenseDataMap: (state) => state.licenses.licenseDataMap,
            isFetchingLicenseDataMap: (state) => state.licenses.isFetchingLicenseDataMap,
            licenseCategories: (state) => state.licenses.licenseCategories
        }),
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            activeSubscription: 'subscriptions/active',
            getActiveTimezone: 'subscriptions/getTimezone'
        }),
        apps () {
            return this.activeSubscription.applications.map((app) => ({
                id: get(this.licenseDataMap, `${app.id}.id`, null),
                appId: app.id,
                name: app.displayName,
                displayName: app.displayName,
                lastUpdatedBy: get(this.licenseDataMap, `${app.id}.lastUpdatedByUser.username`, '--'),
                lastUpdatedDate: get(this.licenseDataMap, `${app.id}.lastUpdatedAt`, '--'),
                appCategories: get(this.licenseDataMap, `${app.id}.appCategories`, null),
                quantity: get(this.licenseDataMap, `${app.id}.quantity`, ''),
                faviconB64: get(app, 'faviconB64', null),
                color: get(app, 'color', null),
                platform: app.platform
            }));
        },
        filteredApps () {
            if (this.licenseFilterSelected === 'All') {
                return this.apps;
            }

            return this.apps.filter((app) => {
                if (this.licenseFilterSelected === 'With Licenses') {
                    return app.quantity !== null && app.quantity !== '';
                }

                return app.quantity === null || app.quantity === '';
            });
        },
        filters () {
            return [
                {
                    prop: ['name', 'appCategories', 'lastUpdatedBy', 'lastUpdatedDate'],
                    value: this.searchInput
                }
            ];
        },
        licenseCategoriesDisplayGrouping () {
            // moves "Other" to the end of the list and groups values
            const copyOfCategories = [...this.licenseCategories];
            const otherIndex = copyOfCategories.indexOf('Other');
            if (otherIndex > -1) {
                copyOfCategories.push(...copyOfCategories.splice(otherIndex, 1));
            }
            const categoryOptionsArray = copyOfCategories.map((cat) => ({ label: cat, id: kebabCase(cat) }));

            return [
                {
                    id: 'default',
                    label: 'Default',
                    categories: [{ label: 'None', id: 'none' }]
                },
                {
                    id: 'all',
                    label: 'All Categories',
                    categories: categoryOptionsArray
                }
            ];
        }
    },
    created () {
        this.loadLicenseDataMap();
    },
    methods: {
        ...mapActions({
            loadLicenseDataMap: 'licenses/loadLicenseDataMap',
            updateLicense: 'licenses/updateLicense',
            createLicense: 'licenses/createLicense'
        }),
        formatQuantity (quantity) {
            if (isNil(quantity)) {
                return '';
            }

            return quantity.toString();
        },
        formatDate (timestamp) {
            if (timestamp === '--') {
                return '--';
            }

            return convertToSubscriptionTimezone(this.getActiveTimezone, timestamp).format(DATE_FORMAT.full);
        },
        showErrorModal () {
            PendoNotification({
                type: 'error',
                title: 'Something went wrong. Please try again later.'
            });
        },
        async onUpdateCategory (row, category) {
            if (category === null) {
                return;
            }
            const license = {
                ...row,
                appCategories: category.label === 'None' ? [] : [category.label],
                quantity: row.quantity === '' ? null : row.quantity
            };
            try {
                if (license.id) {
                    await this.updateLicense({ license });
                } else {
                    await this.createLicense({ license });
                }
            } catch (error) {
                this.showErrorModal();
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        async onUpdateQuantity (row, count) {
            const license = {
                ...row,
                quantity: parseInt(count)
            };

            try {
                if (license.id) {
                    await this.updateLicense({ license });
                } else {
                    await this.createLicense({ license });
                }
            } catch (error) {
                this.showErrorModal();
                // eslint-disable-next-line no-console
                console.error(error);

                return Promise.reject(error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.license-utilization {
    &__table-filter {
        margin-right: 15px;
    }

    &__csv-upload {
        margin-left: 15px;
    }

    &__table-empty {
        height: 350px;
    }
}

::v-deep .pendo-table__content {
    .pendo-multiselect__placeholder {
        color: $gray-lighter-3;
    }
}

.category-header-label {
    font-size: 10px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
}
</style>
