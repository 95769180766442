<template>
    <div>
        <pendo-multiselect
            v-if="isDropdown"
            :options="utilityOptions"
            :popper-options="{ class: 'guide-utilities-panel', placement: 'bottom-end' }"
            :min-menu-width="180"
            class="guide-utilities"
            stateless
            value-key="action"
            @select="handleNextAction"
            @close="$emit('close')">
            <template #trigger="{ toggleMenu }">
                <slot :toggle-menu="toggleMenu" />
            </template>
            <template #option="{ option }">
                <div class="guide-utilities--option">
                    <pendo-icon-option :option="option" />
                    <pendo-tag
                        v-if="option.showTag"
                        label="NEW"
                        color="#8029E9"
                        size="mini"
                        round
                        subtle />
                </div>
            </template>
        </pendo-multiselect>
        <pendo-actions-cell
            v-if="!isDropdown"
            v-pendo-loading:material="{
                loading: copying,
                spinnerProps: {
                    size: 14,
                    background: '#f8f8f9'
                }
            }"
            :row="guide"
            :actions="tableRowActions"
            @clone="cloneGuide"
            @copyToAccount="openGuideCopyDrawer"
            @clear="confirmClearGuideData"
            @preview="previewGuide"
            @delete="confirmDeleteGuide" />
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import {
    PendoNotification,
    PendoActionsCell,
    PendoLoading,
    PendoMultiselect,
    PendoIconOption,
    PendoTag,
    PendoTooltip
} from '@pendo/components';
import { getSegmentsUsingGuide } from '@/utils/guides';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import { isMobile } from '@/stateless-components/utils/apps';
import get from 'lodash/get';
import {
    canCreateFromLayout,
    canCreateFromScratch,
    canEditGuide,
    canDeleteGuide,
    canCopyGuide
} from '@/utils/guide-permissions';

export default {
    name: 'GuideUtilities',
    components: {
        PendoActionsCell,
        PendoIconOption,
        PendoMultiselect,
        PendoTag
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        isDropdown: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            copying: false,
            errorMessages: Object.freeze({
                GENERIC: `Failed to remove guide, "${this.guide.name}".\nPlease try again. If the problem persists, please contact <a href="mailto:help@pendo.io">help@pendo.io.</a>`,
                RC_ATTACHED: `The guide "${this.guide.name}" is associated with one or more modules in the Resource Center. To delete it, you first need to remove it from these modules.`
            })
        };
    },
    computed: {
        ...mapState({
            subId: (state) => get(state, 'subscriptions.activeId'),
            designerUrl: (state) => state.designer.url,
            canPreviewPublicGuide: (state) => state.guides.canPreviewPublicGuide,
            canPreviewNonPublicGuide: (state) => state.guides.canPreviewNonPublicGuide,
            previewLanguage: (state) => state.guides.previewLanguage,
            activeGuideId: (state) => state.guides.activeId,
            isFetching: (state) => state.guides.isFetching,
            guideError: (state) => state.guides.error
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup',
            lastUrlByAppId: 'designer/lastUrlByAppId',
            getAppFromGuide: 'apps/appFromGuide',
            watermark: 'guides/watermark',
            isGuideValidationEnabled: 'subscriptions/isGuideValidationEnabled',
            canCopyGuidesAcrossSubs: 'subscriptions/canCopyGuidesAcrossSubs',
            hasProductLineMetadataEnabled: 'subscriptions/hasProductLineMetadataEnabled',
            activeGuidePreviewConfig: 'guides/activeGuidePreviewConfig'
        }),
        launchUrl () {
            return this.lastUrlByAppId(this.guide.appId) || this.designerUrl;
        },
        guideApp () {
            return this.getAppFromGuide(this.guide);
        },
        localizationEnabled () {
            return get(this.guideApp, 'applicationFlags.guideLocalization', false);
        },
        canCopyGuide () {
            return this.canCopyGuidesAcrossSubs && !isCrossApp(this.guide) && canCopyGuide(this.guide);
        },
        canPreview () {
            if (isMobile(this.guideApp)) return false;

            if (!get(this.guide, 'steps.length')) return false;

            if (this.guide.state === 'public') return this.canPreviewPublicGuide;

            return this.canPreviewNonPublicGuide;
        },
        canDeleteGuide () {
            return canDeleteGuide(this.guide);
        },
        canEditGuide () {
            return canEditGuide({ guide: this.guide });
        },
        canCloneGuide () {
            return canCreateFromScratch(this.guide.appId) || canCreateFromLayout(this.guide.appId);
        },
        canClearGuideData () {
            return (
                (this.guide.state === 'draft' || this.guide.state === 'disabled' || this.guide.state === 'staged') &&
                this.canEditGuide
            );
        },
        affectedSegments () {
            return getSegmentsUsingGuide(this.guide.id, this.segmentsListByGroup.customList);
        },
        tableRowActions () {
            const actions = [];

            if (this.canDeleteGuide) {
                actions.push({
                    type: 'delete',
                    icon: 'trash-2',
                    tooltip: 'Delete'
                });
            }

            if (this.canPreview) {
                actions.push({
                    type: 'preview',
                    icon: 'play-circle',
                    tooltip: 'Preview'
                });
            }

            if (this.canCloneGuide) {
                actions.splice(0, 0, {
                    type: 'clone',
                    icon: 'copy',
                    tooltip: 'Clone'
                });
            }

            if (this.canCopyGuide) {
                actions.splice(0, 0, {
                    type: 'copyToAccount',
                    icon: 'layers',
                    tooltip: 'Copy to Subscription'
                });
            }

            return actions;
        },
        utilityOptions () {
            const guideContentValidationOption = this.isGuideValidationEnabled
                ? {
                      label: 'Validation content',
                      action: 'validate',
                      icon: {
                          type: 'check'
                      }
                  }
                : null;

            const guideHistoryOption = this.hasProductLineMetadataEnabled('guides', 'historyEnabled')
                ? {
                      label: 'View edit history',
                      action: 'openHistoryDrawer',
                      showTag: true,
                      icon: {
                          type: 'book-open'
                      }
                  }
                : null;

            return [
                this.canCopyGuide && {
                    label: 'Copy guide to subscription',
                    action: 'copyToAccount',
                    icon: {
                        type: 'layers'
                    }
                },
                this.canCloneGuide && {
                    label: 'Clone guide',
                    action: 'copy',
                    icon: {
                        type: 'copy'
                    }
                },
                this.canClearGuideData && {
                    label: 'Clear guide data',
                    action: 'clear',
                    icon: {
                        type: 'eye-off'
                    }
                },
                this.canDeleteGuide && {
                    label: 'Delete guide',
                    action: 'delete',
                    icon: {
                        type: 'trash-2'
                    }
                },
                this.canPreview && {
                    label: 'Preview guide',
                    action: 'preview',
                    icon: {
                        type: 'play-circle'
                    }
                },
                guideContentValidationOption,
                guideHistoryOption
            ].filter(Boolean);
        }
    },
    methods: {
        ...mapActions({
            _deleteGuide: 'guides/delete',
            _clearGuide: 'guides/clearViewData',
            _clone: 'guides/clone',
            updateGuide: 'guides/update',
            getBuildingBlocks: 'guides/getBuildingBlocks',
            updateActiveSegmentId: 'filters/updateActiveSegmentId'
        }),
        ...mapMutations({
            setActiveGuideById: 'guides/setActiveById',
            addGuideToStore: 'guides/setUpdate'
        }),
        handleNextAction ({ action }) {
            return {
                copy: this.cloneGuide,
                clear: this.confirmClearGuideData,
                delete: this.confirmDeleteGuide,
                preview: this.previewGuide,
                validate: this.showValidationContent,
                copyToAccount: this.openGuideCopyDrawer,
                openHistoryDrawer: this.openHistoryDrawer
            }[action]();
        },
        async cloneGuide () {
            try {
                this.copying = true;
                if (!this.isDropdown) await this.getBuildingBlocks({ guide: this.guide });

                await this._clone({ guide: this.guide });

                this.$router.push({ name: 'guideSettings', params: { id: this.activeGuideId } }).catch(() => {});
                this.copying = false;
            } catch (error) {
                this.copying = false;
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Please try again or contact support if the issue persists.',
                    duration: 3000
                });
            }
        },
        confirmClearGuideData () {
            const { clearGuideData } = this;

            this.$modal.show('via-modal', {
                title: 'Clear & Reset Guide Data?',
                component: 'ClearGuideDataModal',
                componentProps: {
                    clearFn: clearGuideData
                }
            });
        },
        confirmDeleteGuide () {
            const { affectedSegments, deleteGuide } = this;
            const title = affectedSegments.length ? 'Segment Dependency Detected' : 'Delete Guide?';

            this.$modal.show('via-modal', {
                title,
                component: 'ItemDeleteWarning',
                componentProps: {
                    kind: 'guide',
                    impactedKind: 'segment',
                    impactedItems: affectedSegments,
                    deleteFn: deleteGuide
                }
            });
        },
        previewGuide () {
            this.$modal.show('via-modal', {
                title: 'Preview Guide',
                component: 'LaunchPreviewModal',
                componentProps: {
                    guide: this.guide,
                    launchUrl: this.launchUrl,
                    showAdvancedSettings: true,
                    previewConfig: this.activeGuidePreviewConfig
                }
            });
        },
        showValidationContent () {
            const { guide } = this;

            this.$modal.show('via-modal', {
                title: 'Guide Content Validation',
                component: 'GuideContentValidation',
                componentProps: { guide }
            });
        },
        async clearGuideData () {
            try {
                await this._clearGuide({ guide: this.guide });
                PendoNotification({
                    type: 'success',
                    title: 'Guide Data Successfully Reset',
                    duration: 5000
                });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Please try again or contact support if the issue persists.',
                    duration: 5000
                });
            }
        },
        async deleteGuide (deleteBreaksSegment) {
            let keys;
            let errData;
            let errStatus;
            let rcKeyCheck;

            await this._deleteGuide({ guide: this.guide });

            this.$modal.hide('via-modal');
            this.$router.push('/guides').catch(() => {});

            if (this.guideError) {
                keys = ['moduleId', 'originId', 'name'];
                errData = get(this, 'guideError.response.data', undefined);
                errStatus = get(this, 'guideError.response.status', undefined);
                rcKeyCheck = Array.isArray(errData)
                    ? errData.some((obj) => keys.some((key) => Object.keys(obj).includes(key)))
                    : false;

                const rcAttachedLink = this.getRCLink();

                PendoNotification({
                    type: 'error',
                    title: 'Unable to delete guide',
                    duration: 0,
                    dangerouslyUseHTMLString: true,
                    message:
                        errStatus === 409 && rcKeyCheck
                            ? this.errorMessages.RC_ATTACHED + rcAttachedLink
                            : this.errorMessages.GENERIC
                });
            }

            if (deleteBreaksSegment) {
                this.updateActiveSegmentId({ id: 'everyone' });
            }
        },
        getRCLink () {
            const errData = get(this, 'guideError.response.data', {});
            const originId = get(errData, '[0].originId', '');
            const guideIds = (originId || '').split('@');

            return guideIds.length > 1
                ? `<p class="toast-padding-delete-guide-rc-dep"><a href="/resource-center/${guideIds[1]}/draft">Go to the Resource Center</a></p>`
                : '';
        },
        openGuideCopyDrawer () {
            this.$emit('openGuideCopyDrawer', this.guide);
        },
        openHistoryDrawer () {
            this.$emit('openHistoryDrawer');
        }
    }
};
</script>

<style lang="scss">
.guide-utilities {
    &--buttons {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8px;
    }

    &--option {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
</style>
