import BlackHistoryMonthChevron from '@/components/icons/chevrons/BlackHistoryMonthChevron.vue';
import DefaultChevron from '@/components/icons/chevrons/DefaultChevron.vue';
import InternationalWomensDayChevron from '@/components/icons/chevrons/InternationalWomensDayChevron.vue';
import PrideMonthChevron from '@/components/icons/chevrons/PrideMonthChevron.vue';
import moment from '@/utils/moment';

export const CHEVRONS = {
    BlackHistoryMonthChevron,
    DefaultChevron,
    InternationalWomensDayChevron,
    PrideMonthChevron
};

export const activeChevronMixin = {
    computed: {
        activeChevron () {
            const currentTime = moment(Date.now());
            const activeChevron = this.logoDates.find((chevron) => {
                const start = moment(chevron.timeStart, 'YYYY-MM-DD HH:mm:ss');
                const end = moment(chevron.timeEnd, 'YYYY-MM-DD HH:mm:ss');

                return currentTime.isBetween(start, end);
            });

            return activeChevron ? activeChevron.name : 'defaultChevron';
        },
        logoDates () {
            return [
                {
                    name: 'blackHistoryMonthChevron',
                    timeStart: '2022-02-01 00:00:00',
                    timeEnd: '2022-02-08 00:00:00'
                },
                {
                    name: 'internationalWomensDayChevron',
                    timeStart: '2022-03-08 00:00:00',
                    timeEnd: '2022-03-09 00:00:00'
                },
                {
                    name: 'prideMonthChevron',
                    timeStart: '2022-06-24 00:00:00',
                    timeEnd: '2022-07-01 00:00:00'
                }
                // Need to get logo.
                // {
                //     name: 'pendosBirthdayChevron',
                //     timeStart: '2020-10-01 00:00:00',
                //     timeEnd: '2020-10-02 00:00:00'
                // }
            ];
        }
    }
};
