import { http } from '@pendo/http';

export async function updateSuggestedPageRulesHandledList ({ selections, action }) {
    const payload = {
        selections,
        type: 'page',
        action
    };

    return http.post('/api/s/_SID_/suggestedruleselection', payload).then((res) => res.data);
}
