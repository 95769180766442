<template>
    <div class="resource-center">
        <resource-center-header
            :loading="loading"
            :show-back-button="usesMultiApp && showResourceCenterDetails"
            :disable-create-button="disableCreateButton"
            :resource-center="resourceCenter"
            :show-disabled-app-banner="showResourceCenterDetails"
            @backToRcList="onBackToRcList"
            @createResourceCenter="onCreateResourceCenter"
            @showErrorToast="showErrorToast" />
        <pendo-page-content>
            <resource-center-details
                v-if="showResourceCenterDetails"
                :loading="loading"
                :resource-center="resourceCenter"
                :active-state="activeState"
                @onShowModuleChooser="displayModuleChooser" />
            <pendo-card
                v-if="showEmptyState"
                v-pendo-loading:feather="loading"
                height="468px"
                body-min-height="0">
                <template #body>
                    <pendo-empty-state title="No Resource Center">
                        <template #description>
                            A resource center is a powerful and<br>
                            highly-customizable way to bring information to your<br>
                            users on the fly.
                        </template>
                    </pendo-empty-state>
                </template>
            </pendo-card>
            <resource-center-multi-app-list
                v-if="showMultiAppList"
                :app-options="appMap"
                :furthest-promoted-rc-per-app="furthestPromotedRcPerApp"
                :loading="loading"
                @selectExistingResourceCenter="selectExistingResourceCenter" />
            <resource-center-module-chooser
                :is-visible="showModuleChooser"
                :is-adding-modules="isAddingModules"
                :is-create-mode="isCreateMode"
                @addModules="onAddModules"
                @createWithModules="onCreateWithModules"
                @onCancel="cancelModuleChooser" />
        </pendo-page-content>
    </div>
</template>

<script>
import { PendoPageContent, PendoLoading, PendoCard, PendoEmptyState, PendoNotification } from '@pendo/components';
import ResourceCenterHeader from '@/components/resource-center/ResourceCenterHeader';
import ResourceCenterDetails from '@/components/resource-center/ResourceCenterDetails';
import ResourceCenterModuleChooser from '@/components/resource-center/module-chooser/ResourceCenterModuleChooser';
import ResourceCenterMultiAppList from '@/components/resource-center/ResourceCenterMultiAppList';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import isUndefined from 'lodash/isUndefined';
import { canCreateResourceCenter } from '@/utils/rc-permissions';

export default {
    name: 'ResourceCenter',
    components: {
        PendoCard,
        PendoPageContent,
        ResourceCenterHeader,
        ResourceCenterDetails,
        PendoEmptyState,
        ResourceCenterModuleChooser,
        ResourceCenterMultiAppList
    },
    directives: {
        PendoLoading,
        PendoNotification
    },
    props: {
        activeState: {
            type: String,
            default: null
        },
        originId: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            showModuleChooser: false,
            isCreateMode: false
        };
    },
    computed: {
        ...mapState({
            error: (state) => state.resourceCenter.error,
            isFetchingResourceCenter: (state) => state.resourceCenter.isFetching,
            isFetchingGuides: (state) => state.guides.isFetching,
            isUpdating: (state) => state.resourceCenter.isUpdating,
            isAddingModules: (state) => state.resourceCenter.isAddingModules,
            resourceCenter: (state) => state.resourceCenter.active,
            resourceCentersList: (state) => state.resourceCenter.list,
            activeEditingAppId: (state) => state.resourceCenter.activeEditingAppId
        }),
        ...mapGetters({
            activeHasResourceCenter: 'subscriptions/activeHasResourceCenter',
            appMap: 'apps/appMapForActiveSubscription',
            draftResourceCenterMap: 'resourceCenter/draftResourceCenterMap',
            usesMultiApp: 'subscriptions/usesMultiApp',
            listAllWithRCAccessForActiveSubscription: 'apps/listAllWithRCAccessForActiveSubscription',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription',
            activeApp: 'apps/active'
        }),
        loading () {
            return this.isFetchingResourceCenter || this.isFetchingGuides || this.isUpdating || this.isAddingModules;
        },
        resourceCenterId () {
            return get(this.resourceCenter, 'id');
        },
        showResourceCenterDetails () {
            return !!(this.resourceCenter && this.activeState);
        },
        showEmptyState () {
            return (
                (!this.usesMultiApp && !this.showResourceCenterDetails) ||
                (this.usesMultiApp && !this.resourceCentersList.length)
            );
        },
        showMultiAppList () {
            return (
                this.resourceCentersForActiveSubHaveApps &&
                this.usesMultiApp &&
                !this.showResourceCenterDetails &&
                !this.showEmptyState
            );
        },
        // When a subscription is changed, the RCs will no longer align with apps in the new subscription
        // until the page gets reloaded. Adding this check to showMultiAppList prevents errors from appearing
        // in the console.
        resourceCentersForActiveSubHaveApps () {
            return this.furthestPromotedRcPerApp.every((rc) => {
                const { appId } = rc.homeView;
                const rcAppInfo = this.appMap[appId];

                return rcAppInfo;
            });
        },
        furthestPromotedRcPerApp () {
            if (this.resourceCentersList.length === 0) {
                return [];
            }

            // get the homeView for the latest stage of deployment
            return this.resourceCentersList.map((rc) => {
                if (rc.public && rc.public.homeView) {
                    return {
                        homeView: rc.public.homeView,
                        isDisabled: rc.public.isDisabled
                    };
                }

                return {
                    homeView: rc.draft.homeView,
                    isDisabled: rc.draft.isDisabled
                };
            });
        },
        appRcMap () {
            return keyBy(this.furthestPromotedRcPerApp, 'homeView.appId');
        },
        disableCreateButton () {
            if (this.activeIsTrainingSubscription) {
                return !canCreateResourceCenter([this.activeApp]);
            }

            // if all extension apps have RC, we should disable the create button
            return this.listAllWithRCAccessForActiveSubscription
                .filter((app) => canCreateResourceCenter([app]))
                .every((app) => this.appRcMap[app.id]);
        }
    },
    watch: {
        resourceCenterId () {
            this.updateRoute();
        },
        async originId () {
            await this.getResourceCenter();
        },
        activeState (newVal, oldVal) {
            // prevent false empty message when clicking RC nav link from RC page
            if (oldVal && !newVal) this.updateRoute();
        }
    },
    async created () {
        if (this.usesMultiApp) {
            await this.updateActiveEditingAppByOriginId({ originId: this.originId });
        }
        await this.getResourceCenter();
        await this.getGuides();
        this.updateRoute();
    },
    methods: {
        ...mapActions({
            createResourceCenter: 'resourceCenter/create',
            createResourceCenterModules: 'resourceCenter/createResourceCenterModules',
            getResourceCenter: 'resourceCenter/get',
            getGuides: 'guides/fetch',
            fetchAllResourceCenters: 'resourceCenter/fetchAll',
            updateActiveEditingAppByOriginId: 'resourceCenter/updateActiveEditingAppByOriginId'
        }),
        ...mapMutations({
            setActiveEditingAppId: 'resourceCenter/setActiveEditingAppId'
        }),
        updateRoute () {
            const { originId, activeState: currentState } = this;
            const activeState = get(this, '$router.currentRoute.params.activeState', 'draft');
            let updateRoute = false;

            if (!this.resourceCenterId || !originId || !['draft', 'public'].includes(currentState)) {
                if (!this.resourceCenter || !this.resourceCenter.id) {
                    this.$router.replace({ name: 'resourceCenter' });

                    return;
                }

                updateRoute = true;
            }

            if (!updateRoute) {
                return;
            }

            const rcid = this.resourceCenter.id;

            this.$router.replace({
                name: 'resourceCenter',
                params: {
                    originId: rcid,
                    activeState
                }
            });
        },
        async onAddModules ({ modules }) {
            this.showModuleChooser = false;

            await this.createResourceCenterModules({
                modulesToAdd: modules,
                resourceCenterId: this.resourceCenter.id
            });

            if (this.error) {
                this.showErrorToast();
            }

            this.routeToDraftView(this.resourceCenter.id);
        },
        async onCreateWithModules ({ appId, modules }) {
            const existingResourceCenter = !isUndefined(this.draftResourceCenterMap[appId]);

            if (existingResourceCenter) {
                this.showErrorToast();
                throw new Error('Resource center already exists');
            }

            this.showModuleChooser = false;

            await this.createResourceCenter({ appId });
            if (this.error) {
                return this.showErrorToast();
            }
            await this.fetchAllResourceCenters({ noCache: true });

            await this.createResourceCenterModules({
                modulesToAdd: modules,
                resourceCenterId: this.resourceCenterId
            });
            if (this.error) {
                this.showErrorToast();
            }

            this.routeToDraftView(this.resourceCenterId);
        },
        onCreateResourceCenter () {
            if (!this.usesMultiApp) {
                return this.createResourceCenterAndReroute();
            }

            this.displayCreateResourceCenterModal();
        },
        async createResourceCenterAndReroute () {
            await this.createResourceCenter();
            if (this.error) {
                this.showErrorToast();

                return;
            }
            await this.fetchAllResourceCenters({ noCache: true });

            this.routeToDraftView(this.resourceCenterId);
        },
        routeToDraftView (resourceCenterId) {
            this.$router.replace({
                name: 'resourceCenter',
                params: {
                    originId: resourceCenterId,
                    activeState: this.activeState
                }
            });
        },
        displayModuleChooser () {
            this.isCreateMode = false;
            this.showModuleChooser = true;
        },
        displayCreateResourceCenterModal () {
            this.isCreateMode = true;
            this.showModuleChooser = true;
        },
        cancelModuleChooser () {
            this.isCreateMode = false;
            this.showModuleChooser = false;
        },
        selectExistingResourceCenter (resourceCenter) {
            this.setActiveEditingAppId({ activeEditingAppId: resourceCenter.appId });
            this.getResourceCenter();
        },
        async onBackToRcList () {
            this.setActiveEditingAppId({ activeEditingAppId: null });
            await this.getResourceCenter();
            this.updateRoute();
        },
        showErrorToast () {
            PendoNotification({
                type: 'error',
                title: 'Something went wrong!',
                message: 'Please try again or contact support if the issue persists.',
                duration: 7000
            });
        }
    }
};
</script>
