<template>
    <csv-upload
        :title="title"
        :description="description"
        is-icon
        upload-type="metadata"
        :entity-kind="metadataKind"
        :has-error="hasError"
        :has-warning="hasWarning"
        :file-format-help="fileFormatHelp"
        :has-exclude-header-check="false"
        @error="onError"
        @warning="onWarning"
        @reset="onReset">
        <template #error-message>
            <div
                v-if="hasError"
                class="error-message">
                <strong>
                    {{ errorHeading }}
                </strong>
                <div class="capitalize">
                    {{ errorMessage }}
                </div>
            </div>
        </template>
        <template #warning-message>
            <div v-if="someItemsNotUpdated">
                Some {{ textFormattedKind.toLowerCase() }}s did not update successfully
                <strong>({{ successCount }}/{{ totalCount }})</strong>
                <div
                    v-if="errorMessage"
                    class="error-message">
                    {{ errorMessage }}
                </div>
                <div
                    v-if="errorRows.length"
                    class="error-rows">
                    {{ errorRowsMessage }}: {{ errorRows.join(', ') }}
                </div>
            </div>
        </template>
        <template #success-message>
            <div>
                It can take approximately 15 minutes for the updated metadata values to process and display in the UI.
            </div>
        </template>
    </csv-upload>
</template>

<script>
import CsvUpload from '@/components/segments/builder/rule-components/CsvUpload.vue';
import capitalize from 'lodash/capitalize';

export default {
    name: 'MetadataCsvUpload',
    components: {
        CsvUpload
    },
    props: {
        metadataKind: {
            type: String,
            default: 'visitor'
        },
        docsUrl: {
            type: String,
            default: 'https://adoptsupport.pendo.io/hc/en-us/articles/4406529611035-Metadata-Overview'
        }
    },
    data () {
        return {
            errorMessage: '',
            errorHeading: '',
            errorRows: [],
            errorRowsMessage: '',
            someItemsNotUpdated: false,
            totalCount: 0,
            successCount: 0,
            hasError: false,
            hasWarning: false
        };
    },
    computed: {
        title () {
            return `Upload ${this.textFormattedKind.toLowerCase()} metadata CSV`;
        },
        description () {
            return this.helperDescription || this.defaultDescription;
        },
        defaultDescription () {
            return `Select and upload a CSV file to update ${this.textFormattedKind.toLowerCase()}s with custom metadata. ${
                this.textFormattedKind
            }s that didn’t previously exist will be created.`;
        },
        textFormattedKind () {
            if (this.metadataKind === 'parentAccount') return 'Parent account';

            return capitalize(this.metadataKind);
        },
        fileFormatHelp () {
            return {
                docsUrl: this.docsUrl,
                urlText: 'Update custom metadata fields.'
            };
        }
    },
    methods: {
        onError (error) {
            this.hasError = true;

            if (error.status === 409) {
                this.errorHeading = 'Upload in progress';
                this.errorMessage = error.error;

                return;
            }

            switch (error.errorCode) {
                case 'ERR_INVALID_COLUMN_HEADER':
                    this.errorMessage =
                        'Invalid metadata name used as column header. Metadata names may only contain letters, digits, and underscores, and may not begin with pendo or lc.';
                    this.errorHeading = 'Invalid Metadata Column Header';
                    break;
                case 'ERR_EXCEED_MAX_FIELDS':
                    this.errorMessage =
                        'The CSV file contains more than 24 metadata fields. Please ensure that the CSV file contains no more than 24 metadata fields.';
                    this.errorHeading = 'Too many metadata fields';
                    break;
                case 'ERR_MISSING_KIND_COLUMN_HEADER':
                    this.errorMessage = `The CSV file must start with the ${this.metadataKind}Id column header.`;
                    this.errorHeading = `Missing ${this.metadataKind}Id column header`;
                    break;
                case 'ERR_MISSING_FIELD':
                    this.errorMessage = 'The CSV file was empty.';
                    this.errorHeading = 'Empty CSV file';
                    break;
                case 'ERR_EMPTY_HEADER':
                    this.errorMessage = 'The CSV file did not contain any column headers.';
                    this.errorHeading = 'No column headers';
                    break;
                default:
                    this.errorMessage = error.error;
                    break;
            }

            if (error.errorRows) {
                this.errorRows = error.errorRows;
            }
        },
        onWarning (message) {
            const hasUpdateCounts = !isNaN(message.success) && !isNaN(message.total);
            const someItemsNotUpdated = message.success < message.total;

            if (hasUpdateCounts && someItemsNotUpdated) {
                this.hasWarning = true;
                this.someItemsNotUpdated = true;
                this.successCount = message.success;
                this.totalCount = message.total;
            }
            switch (message.errorCode) {
                case 'ERR_BAD_ROW_FORMATTING':
                    this.errorMessage =
                        'Some rows were incorrectly formatted. Check your CSV file to ensure all rows have the same number of columns.';
                    this.errorRowsMessage = 'The following rows are incorrectly formatted';
                    break;
                case 'ERR_DUPLICATE_KIND_IDS':
                    this.errorMessage = `Some ${this.textFormattedKind.toLowerCase()} IDs were duplicated in the CSV file. Please ensure that each ${this.textFormattedKind.toLowerCase()} ID is unique.`;
                    this.errorRowsMessage = `The following rows have duplicate ${this.textFormattedKind.toLowerCase()} IDs`;
                    break;
            }

            if (message.errorRows) {
                this.errorRows = message.errorRows;
            }
        },
        onReset () {
            this.hasError = false;
            this.hasWarning = false;

            this.someItemsNotUpdated = false;
            this.successCount = 0;
            this.totalCount = 0;

            this.errorMessage = '';
            this.errorHeading = '';
            this.errorRows = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.error-message {
    margin-top: 5px;
}

.error-rows {
    margin-top: 15px;
}

.capitalize::first-letter {
    text-transform: capitalize;
}
</style>
