import * as guideUtil from '@/utils/guides';
import * as statelessGuideUtil from '@/stateless-components/utils/guides';

import { updateUIBuildingBlockByWidgetId } from '@/stateless-components/utils/resource-center';
import {
    BuildingBlock,
    BuildingBlockLayouts,
    BuildingBlockProperties,
    BuildingBlockUtils
} from '@pendo/services/BuildingBlocks';
import sharedServicesPackage from '@pendo/services/package.json';

import { info, logo, star, help } from '@/constants/svgs';
import { http } from '@pendo/http';
import cloneDeep from 'lodash/cloneDeep';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import set from 'lodash/set';
import findIndex from 'lodash/findIndex';

import store from '@/state/store';

const {
    createBaseBuildingBlocksForLayout,
    createResourceCenterBuildingBlocksForLayout,
    moduleIds
} = BuildingBlockLayouts;
const { version: sharedServiceVersion } = sharedServicesPackage;
const grayPrimary = '#2A2C35';

export const stepFieldsToPatch = [
    'buildingBlocks',
    'content',
    'contentCss',
    'contentJs',
    'dom',
    'elementPathRule',
    'elementSelectionType',
    'pageId',
    'attributes'
];

export const STATES = Object.freeze({
    PUBLIC: 'public',
    DRAFT: 'draft',
    DISABLED: 'disabled'
});

export const guideFieldsToPatch = ['attributes', 'launchMethod'];

export const BADGE_POSITIONS = Object.freeze({
    'bottom': 'Bottom',
    'top': 'Top',
    'left': 'Left',
    'right': 'Right',
    'bottom-right': 'Bottom Right',
    'bottom-left': 'Bottom Left',
    'top-right': 'Top Right',
    'top-left': 'Top Left'
});

export function createDefaultBadgeObject (resourceCenterId, badgeAndFrameColor = grayPrimary) {
    const badgeSrc = createResourceCenterBadge(badgeAndFrameColor);
    const badgeBuildingBlocks = createBaseBuildingBlocksForLayout('badgeResourceCenter', resourceCenterId, {
        src: badgeSrc.url
    });
    const badgeDom = BuildingBlock.buildingBlocksToDom(badgeBuildingBlocks);

    const badgeObject = {
        bbJson: badgeBuildingBlocks,
        color: badgeAndFrameColor,
        domJson: badgeDom,
        isOnlyShowOnce: false,
        name: 'logo',
        showBadgeOnlyOnElementHover: false,
        showGuideOnBadgeHover: false
    };

    // Adopt DA RC badges use absolute positioning to take advantage of enabling shadow root for specific apps.
    // Adopt AEU RC badges use inline positioning, which is the default for Engage RC badges.
    if (!store.getters['subscriptions/activeIsDigitalAdoption']) {
        badgeObject.position = 'inline';
    }

    return badgeObject;
}

export async function createDraftResourceCenter ({ appId, isTraining, launchMethod, badgeAndFrameColor = grayPrimary }) {
    const name = 'Resource Center';

    const resourceCenterBuildingBlocks = createResourceCenterBuildingBlocksForLayout(
        moduleIds.homeView,
        'placeholder-step-id',
        {
            resourceCenterTitle: name,
            frameColor: badgeAndFrameColor
        }
    );

    let resourceCenter = await guideUtil.create({
        buildingBlocks: resourceCenterBuildingBlocks,
        appId,
        isTraining
    });

    resourceCenter.attributes.badge = createDefaultBadgeObject(resourceCenter.id, badgeAndFrameColor);

    resourceCenter.attributes.resourceCenter = {
        children: [],
        isTopLevel: true,
        moduleId: moduleIds.homeView,
        originId: resourceCenter.id
    };

    resourceCenter.attributes.sharedServiceVersion = sharedServiceVersion;
    resourceCenter.name = name;
    resourceCenter.launchMethod = launchMethod;
    resourceCenter.steps[0].elementPathRule = 'body';

    if (launchMethod === 'extensionIcon') {
        resourceCenter = updateBadgePosition(resourceCenter, 'top-right');
    }

    return resourceCenter;
}

export async function getResourceCenterGuideStepContentByState (resourceCenterState) {
    const { homeView, modules } = resourceCenterState;
    const getHomeViewStepsContent = statelessGuideUtil.getStepsWithBuildingBlocks(homeView);
    const getModulesContent = Promise.all(
        modules.map((module) => statelessGuideUtil.getStepsWithBuildingBlocks(module))
    );
    const [homeViewStepsContent, modulesStepsContent] = await Promise.all([getHomeViewStepsContent, getModulesContent]);

    resourceCenterState.homeView.steps = homeViewStepsContent;
    resourceCenterState.modules.forEach((module, i) => {
        module.steps = modulesStepsContent[i];
    });

    return resourceCenterState;
}

export function getResourceCenterGuideStepContentForAllStates (resourceCenter) {
    return Promise.all(
        ['draft', 'public'].map((state) => {
            if (!resourceCenter[state]) {
                return Promise.resolve(null);
            }

            return getResourceCenterGuideStepContentByState(resourceCenter[state]);
        })
    );
}

export function getModuleURLParam (moduleId) {
    const moduleIdDict = {
        GuideListModule: 'guide-list-module'
    };

    return moduleIdDict[moduleId] || 'module';
}

export function getModuleTitleBlock (buildingBlocks) {
    const bb = parsedBuildingBlockString(buildingBlocks);

    return BuildingBlock.findBlockByWidgetId(bb, BuildingBlockLayouts.widgetIds.h2Block);
}

export function findTitlePropertyIndex (buildingBlocks, propertyName, propertyValue) {
    const h2Block = getModuleTitleBlock(buildingBlocks);
    if (typeof h2Block === 'object' && 'properties' in h2Block) {
        return findIndex(h2Block.properties, [propertyName, propertyValue]);
    }

    return -1;
}

export function getModuleTitleProperty (buildingBlocks) {
    const h2Block = getModuleTitleBlock(buildingBlocks);
    const i = findTitlePropertyIndex(buildingBlocks, 'name', 'text');

    return i < 0 ? '' : h2Block.properties[i].value;
}

export function getModuleSubtitleProperty (homeView, moduleId) {
    const uiBlock = getHomeviewTemplateChildrenBlock(homeView);
    if (!uiBlock) return;

    const child = uiBlock.templateChildren.find((children) => children.id === moduleId);
    if (!child) return;

    return get(child, 'subtitle', '');
}

export function updateModuleTitle (guide, name) {
    const clonedGuide = cloneDeep(guide);
    const buildingBlocks = get(clonedGuide, 'steps[0].buildingBlocks', {});
    const modifiedTitleBuildBlock = getModuleTitleBlock(buildingBlocks);
    const i = findTitlePropertyIndex(buildingBlocks, 'name', 'text');

    if (i < 0) return clonedGuide;

    modifiedTitleBuildBlock.properties[i].value = name;
    const parsedBuildingBlocks = JSON.parse(clonedGuide.steps[0].buildingBlocks);
    const modifiedBuildingBlocks = updateUIBuildingBlockByWidgetId(
        parsedBuildingBlocks,
        modifiedTitleBuildBlock,
        BuildingBlockLayouts.widgetIds.h2Block
    );
    const modifiedBuildingBlocksString = cloneDeep(modifiedBuildingBlocks);
    clonedGuide.steps[0].buildingBlocks = modifiedBuildingBlocksString;

    return clonedGuide;
}

export function updateModuleSubtitle (homeview, module, subtitle) {
    const clonedHomeview = cloneDeep(homeview);
    const templateChildrenBlock = getHomeviewTemplateChildrenBlock(homeview);
    const { templateChildren } = templateChildrenBlock;
    const moduleChild = templateChildren.find((child) => child.id === module.id);
    moduleChild.subtitle = subtitle;

    const updatedHomeview = updateHomeviewTemplateChildren(clonedHomeview, null, templateChildren);

    return updatedHomeview;
}

export function getHomeviewTemplateChildrenBlock (Homeview) {
    const { buildingBlocks } = Homeview.steps[0];
    const allBlocks = parsedBuildingBlockString(buildingBlocks);
    const uiBlock = BuildingBlock.findBlock(
        allBlocks,
        (block) => block.id === 'pendo_resource_center_module_list_item'
    );

    return uiBlock;
}

export function updateHomeviewTemplateChildren (Homeview, ChildrenGuides, templateChildren = null) {
    const { buildingBlocks } = Homeview.steps[0];
    const allBlocks = parsedBuildingBlockString(buildingBlocks);
    const uiBlock = getHomeviewTemplateChildrenBlock(Homeview);

    if (templateChildren) {
        uiBlock.templateChildren = templateChildren;
    } else {
        uiBlock.templateChildren = ChildrenGuides.map((guide) => {
            const { id, steps } = guide;
            const bb = steps[0].buildingBlocks;
            const title = getModuleTitleProperty(bb);
            const subtitle = getModuleSubtitleProperty(Homeview, id);

            return {
                id,
                moduleName: '',
                title,
                subtitle,
                keywords: null
            };
        });
    }

    BuildingBlock.replaceBlock(allBlocks, uiBlock, (block) => block.id === 'pendo_resource_center_module_list_item');
    Homeview.steps[0].buildingBlocks = allBlocks;

    return Homeview;
}

export function setResourceCenterModulePositions (module, badgePositions, modulePositions) {
    const defaultProp = {
        type: 'dimen',
        value: 'auto'
    };

    Object.keys(modulePositions).forEach((key) => {
        if (['top', 'bottom', 'left', 'right'].indexOf(key) < 0) return;
        const isJSON = typeof module.steps[0].buildingBlocks === 'string';
        const buildingBlocks = isJSON ? JSON.parse(module.steps[0].buildingBlocks) : module.steps[0].buildingBlocks;
        BuildingBlockProperties.updateOrCreateProperty(
            buildingBlocks,
            `layoutAbsolute_${key}`,
            'dimen',
            modulePositions[key]
        );
        module.steps[0].buildingBlocks = JSON.stringify(buildingBlocks);

        let index = module.attributes.badge.bbJson.properties.findIndex(({ name }) => name === `layout_${key}`);
        if (index < 0) {
            module.attributes.badge.bbJson.properties.push({ name: `layout_${key}`, ...defaultProp });
            index = module.attributes.badge.bbJson.properties.length - 1;
        }
        module.attributes.badge.bbJson.properties[index].value = badgePositions[key];
    });

    return module;
}

export function getBadgeWidth (badgeBbJSON) {
    const imageObject = BuildingBlock.findBlockByDomId(badgeBbJSON, 'pendo-image-badge');
    if (typeof imageObject !== 'object' || !('properties' in imageObject)) return 50;

    const width = BuildingBlockProperties.getPropertyByName(imageObject.properties, 'imgWidth');
    const badge = { ...{ value: 50 }, ...width };

    return badge.value;
}

export function calculateBadgeOffsetBySize (badgeBbJSON, extraOffset = 0) {
    const badgeWidth = getBadgeWidth(badgeBbJSON);
    const badgeWidthInDp = BuildingBlockUtils.pxToDp(badgeWidth);
    const defaultSpacingBetweenRCAndBadge = 22;
    const calculatedResult = parseInt(badgeWidthInDp) + defaultSpacingBetweenRCAndBadge + parseInt(extraOffset);

    return `${calculatedResult}dp`;
}

export function calculateBadgeCenterPosition (badgeBbJSON, selectedPosition) {
    const isLeftOrRight = selectedPosition === BADGE_POSITIONS.left || selectedPosition === BADGE_POSITIONS.right;
    const min = isLeftOrRight ? 31.5 : 39.5;
    const max = isLeftOrRight ? 47 : 48;
    const badgeWidth = getBadgeWidth(badgeBbJSON);
    const goldenRatio = (max - min) / 250;

    const result = min + (300 - parseInt(badgeWidth)) * goldenRatio;
    const nearestHundredth = Math.round((result + Number.EPSILON) * 100) / 100;

    return `${nearestHundredth}%`;
}

export function getBadgeBbJSON (homeView) {
    const { bbJson } = homeView.attributes.badge;
    if (!bbJson) {
        throw new Error(`bbJson not found within module attributes for id ${homeView.id}`);
    }

    return bbJson;
}

export function updateBadgeColor (homeView, color) {
    return set(homeView, 'attributes.badge.color', color);
}

export function updateFrameColor (module, color) {
    const clonedModule = cloneDeep(module);
    const isJSON = typeof clonedModule.steps[0].buildingBlocks === 'string';
    const buildingBlocks = isJSON ? JSON.parse(module.steps[0].buildingBlocks) : module.steps[0].buildingBlocks;
    const titleContainer = BuildingBlock.findBlockByDomId(
        buildingBlocks,
        'pendo-resource-center-module-title-container'
    );
    const frameColor = titleContainer.properties.find((property) => property.name === 'frameColor');
    frameColor.value = color;
    clonedModule.steps[0].buildingBlocks = JSON.stringify(buildingBlocks);

    return clonedModule;
}

export function getBadgeColor (homeView) {
    return get(homeView, 'attributes.badge.color', '#080808');
}

export function getPropertyIndex (homeView, Bb, path, propertyName) {
    const properties = get(Bb, path, []);

    return properties.findIndex((prop) => prop.name === propertyName);
}

export function updateBadgeImage (homeView, newImageSrc) {
    const path = 'views[0].properties';
    let badgeBbJSON = getBadgeBbJSON(homeView);
    const index = getPropertyIndex(homeView, badgeBbJSON, path, 'src');

    if (index < 0) {
        return homeView;
    }

    const clonedHomeView = cloneDeep(homeView);
    badgeBbJSON = set(badgeBbJSON, `${path}[${index}].value`, newImageSrc);
    clonedHomeView.attributes.badge.bbJson = badgeBbJSON;
    clonedHomeView.attributes.badge.domJson = BuildingBlock.buildingBlocksToDom(badgeBbJSON);

    return clonedHomeView;
}

export function getBadgeImage (homeView) {
    const path = 'views[0].properties';
    const badgeBbJSON = getBadgeBbJSON(homeView);
    const index = getPropertyIndex(homeView, badgeBbJSON, path, 'src');

    return get(badgeBbJSON, `${path}[${index}].value`, `data:image/svg+xml;base64,${window.btoa(logo)}`);
}

export function updateBadgeIconName (homeView, name) {
    return set(homeView, 'attributes.badge.name', name);
}

export function getBadgeIconName (homeView) {
    return get(homeView, 'attributes.badge.name', 'logo');
}

export function getBadgePosition (homeView) {
    const badgeBbJSON = getBadgeBbJSON(homeView);
    const defaultProp = { value: 'auto' };

    const top = BuildingBlockProperties.getPropertyByName(badgeBbJSON.properties, 'layout_top') || defaultProp;
    const bottom = BuildingBlockProperties.getPropertyByName(badgeBbJSON.properties, 'layout_bottom') || defaultProp;
    const left = BuildingBlockProperties.getPropertyByName(badgeBbJSON.properties, 'layout_left') || defaultProp;
    const right = BuildingBlockProperties.getPropertyByName(badgeBbJSON.properties, 'layout_right') || defaultProp;

    if (bottom.value !== 'auto' && right.value.includes('%')) {
        return BADGE_POSITIONS.bottom;
    }
    if (top.value !== 'auto' && right.value.includes('%')) {
        return BADGE_POSITIONS.top;
    }
    if (right.value !== 'auto' && top.value.includes('%')) {
        return BADGE_POSITIONS.right;
    }
    if (left.value !== 'auto' && top.value.includes('%')) {
        return BADGE_POSITIONS.left;
    }
    if (bottom.value !== 'auto' && right.value !== 'auto') {
        return BADGE_POSITIONS['bottom-right'];
    }
    if (bottom.value !== 'auto' && left.value !== 'auto') {
        return BADGE_POSITIONS['bottom-left'];
    }
    if (top.value !== 'auto' && right.value !== 'auto') {
        return BADGE_POSITIONS['top-right'];
    }
    if (top.value !== 'auto' && left.value !== 'auto') {
        return BADGE_POSITIONS['top-left'];
    }

    return BADGE_POSITIONS['bottom-right'];
}

export function updateBadgePosition (homeView, selectedPosition) {
    const badgeBbJSON = getBadgeBbJSON(homeView);

    const modulePosition = {
        bottom: 'auto',
        right: 'auto',
        left: 'auto',
        top: 'auto'
    };

    const badgePosition = cloneDeep(modulePosition);
    const calculateRCModuleOffset = calculateBadgeOffsetBySize(badgeBbJSON);
    const centerBadgePosition = calculateBadgeCenterPosition(badgeBbJSON, selectedPosition);
    switch (BADGE_POSITIONS[selectedPosition]) {
        case BADGE_POSITIONS.bottom:
            badgePosition.bottom = '11dp';
            badgePosition.right = centerBadgePosition;
            modulePosition.bottom = calculateRCModuleOffset;
            modulePosition.right = centerBadgePosition;
            break;
        case BADGE_POSITIONS.top:
            badgePosition.right = centerBadgePosition;
            badgePosition.top = '11dp';
            modulePosition.right = centerBadgePosition;
            modulePosition.top = calculateRCModuleOffset;
            break;
        case BADGE_POSITIONS.left:
            badgePosition.top = centerBadgePosition;
            badgePosition.left = '11dp';
            modulePosition.top = '85dp';
            modulePosition.left = centerBadgePosition;
            break;
        case BADGE_POSITIONS.right:
            badgePosition.top = centerBadgePosition;
            badgePosition.right = '11dp';
            modulePosition.top = '85dp';
            modulePosition.right = '11dp';
            break;
        case BADGE_POSITIONS['bottom-right']:
            badgePosition.bottom = '11dp';
            badgePosition.right = '11dp';
            modulePosition.bottom = calculateRCModuleOffset;
            modulePosition.right = '11dp';
            break;
        case BADGE_POSITIONS['bottom-left']:
            badgePosition.bottom = '11dp';
            badgePosition.left = '11dp';
            modulePosition.bottom = calculateRCModuleOffset;
            modulePosition.left = '11dp';
            break;
        case BADGE_POSITIONS['top-right']:
            badgePosition.right = '11dp';
            badgePosition.top = '11dp';
            modulePosition.right = '11dp';
            modulePosition.top = calculateRCModuleOffset;
            break;
        case BADGE_POSITIONS['top-left']:
            badgePosition.left = '11dp';
            badgePosition.top = '11dp';
            modulePosition.left = '11dp';
            modulePosition.top = calculateRCModuleOffset;
            break;
        default:
            return homeView;
    }

    let clonedHomeView = cloneDeep(homeView);
    clonedHomeView = setResourceCenterModulePositions(clonedHomeView, badgePosition, modulePosition);
    const newBadgeBbJSON = getBadgeBbJSON(clonedHomeView);
    clonedHomeView.attributes.badge.domJson = BuildingBlock.buildingBlocksToDom(newBadgeBbJSON);

    return clonedHomeView;
}

export function changeSVGColor (svg, newColor) {
    const colorRegex = /(circle.*)(fill=".*")/im;
    const newSVG = svg.replace(colorRegex, `$1fill="${newColor}"`);

    return `data:image/svg+xml;base64,${window.btoa(newSVG)}`;
}

export function getDefaultBadgesSVGs () {
    return [
        { name: 'info', src: info },
        { name: 'logo', src: logo },
        { name: 'help', src: help },
        { name: 'star', src: star },
        { name: 'custom', src: '' }
    ];
}

export function createResourceCenterBadge (color = grayPrimary) {
    const url = changeSVGColor(logo, color);

    return { url };
}

export function synchronizeModulesWithHomeView (homeView, modules) {
    // RC's that have never turned this flag on will not have this key, after it has been set once it will be a Boolean.
    const doNotResume = get(homeView, 'attributes.doNotResume');

    // Don't bother setting the value if its not a Boolean
    if (!isBoolean(doNotResume)) return;

    modules.forEach((module) => {
        module.attributes.doNotResume = doNotResume;
    });
}

export function parsedBuildingBlockString (buildingBlocks) {
    return typeof buildingBlocks === 'string' ? JSON.parse(buildingBlocks) : buildingBlocks;
}

export function getResourceCenterByAppId (appId) {
    return http.get(`/api/s/_SID_/resourcecenter?appId=${appId}`).then((res) => res.data[0]);
}

export function deleteResourceCenter (id) {
    return http.delete(`/api/s/_SID_/resourcecenter/${id}`);
}

export async function createStepId () {
    const response = await http.get('/api/s/_SID_/object/makeid');

    return response.data.id;
}

export async function createModuleId (homeViewId) {
    const response = await http.get(`/api/s/_SID_/resourcecenter/${homeViewId}/module/makeid`);

    return response.data.id;
}

export async function createResourceCenterModule (homeViewId, module) {
    // can also be used to update RC module
    const moduleId = module.id;
    const response = await http.put(`/api/s/_SID_/resourcecenter/${homeViewId}/module/${moduleId}`, module);

    return response.data;
}

export async function addModuleToResourceCenter (homeViewId, module) {
    const response = await http.post(`/api/s/_SID_/resourcecenter/${homeViewId}/module`, module);

    return response.data;
}

export async function updateModule (homeViewId, module) {
    const response = await http.put(`/api/s/_SID_/resourcecenter/${homeViewId}/module/${module.id}`, module);

    return response.data;
}

export async function disableProductionResourceCenter (homeViewId, isDisabled) {
    const endpoint = `/api/s/_SID_/resourcecenter/${homeViewId}/setdisabled`;
    const response = await http.post(endpoint, { isDisabled });

    return response.data;
}

export async function promoteResourceCenter (homeViewId, toState) {
    const endpoint = `/api/s/_SID_/resourcecenter/${homeViewId}/promote/${toState}`;
    const response = await http.post(endpoint, { toState });

    return response.data;
}

export async function saveModuleGuideList (homeViewId, moduleId, guideList) {
    const endpoint = `/api/s/_SID_/resourcecenter/${homeViewId}/module/${moduleId}/guides`;
    const response = await http.put(endpoint, guideList);
    const resourceCenter = response.data;

    await getResourceCenterGuideStepContentForAllStates(resourceCenter);

    return resourceCenter;
}

export async function updateModuleSegment (homeViewId, payload) {
    const { guideId: moduleId, props } = payload;
    const endpoint = `/api/s/_SID_/resourcecenter/${homeViewId}/module/${moduleId}/segment`;
    const response = await http.put(endpoint, props);

    return response.data;
}

export const updateResourceCenterAuthoredLanguage = async (homeViewId, authoredLanguage) => {
    const endpoint = `/api/s/_SID_/resourcecenter/${homeViewId}/authoredlanguage`;
    const payload = { authoredLanguage };

    const { data: resourceCenter } = await http.put(endpoint, payload);
    await getResourceCenterGuideStepContentByState(resourceCenter.draft);

    return resourceCenter;
};

export function getAppIdFromOriginId (originId, rcList) {
    if (!originId) return null;

    const resourceCenter = rcList.find((rc) => {
        return get(rc, 'draft.homeView.originId') === originId;
    });

    return get(resourceCenter, 'draft.homeView.appId');
}
