import { render, staticRenderFns } from "./SuggestedPageTagsWrapper.vue?vue&type=template&id=6cb97020&"
import script from "./SuggestedPageTagsWrapper.vue?vue&type=script&lang=js&"
export * from "./SuggestedPageTagsWrapper.vue?vue&type=script&lang=js&"
import style0 from "./SuggestedPageTagsWrapper.vue?vue&type=style&index=0&id=6cb97020&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports