<template>
    <summary-chart-card
        :is-fetching="isFetching"
        spinner-type="feather"
        class="time-on-step-chart"
        title="Average Time per Step">
        <template #filters>
            <pendo-button
                v-if="drilldownChart"
                theme="app"
                type="link"
                class="exit-drilldown"
                prefix-icon="chevron-left"
                label="Back to All Steps"
                @click="exitDrilldown" />
            <template v-if="isCrossApp && !drilldownChart">
                <app-icon-tag
                    v-for="appId in appIds"
                    :key="appId"
                    :app="appMap[appId]" />
            </template>
        </template>
        <template #chart>
            <div
                v-show="!drilldownChart"
                ref="time-on-step"
                :class="{ empty: totalTime <= 0 }"
                class="pendo-highcharts-container" />
            <div
                v-show="drilldownChart"
                ref="time-on-step-drilldown"
                class="pendo-highcharts-container" />
        </template>
        <template #summary>
            <div
                v-if="drilldownStep"
                class="step-summary-header">
                Step {{ drilldownStepNumber }} Details
            </div>
            <div class="step-summary-content">
                <div class="summary-chart-card__metric">
                    {{ averageDuration }}
                </div>
                <div class="summary-chart-card__label">
                    Average Time on {{ drilldownStep ? 'Step' : 'Guide' }}
                </div>
            </div>
        </template>
    </summary-chart-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AppIconTag from '@/components/filters/AppIconTag';
import { PendoButton } from '@pendo/components';
import SummaryChartCard from '@/components/common/SummaryChartCard';
import { getStepDisplayName } from '@/utils/guides';
import { getAppChartColor } from '@/utils/apps';
import { getTimeOnGuide, getTimeOnStep } from '@/aggregations/time-on-guide';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';
import times from 'lodash/times';
import prettyMilliseconds from 'pretty-ms';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { isCrossApp } from '@pendo/services/CrossAppGuides';

export default {
    name: 'TimeOnStepChart',
    components: {
        SummaryChartCard,
        PendoButton,
        AppIconTag
    },
    data () {
        return {
            chart: null,
            drilldownChart: null,
            drilldownStep: null,
            drilldownColor: null,
            isFetching: false,
            timeOnGuide: [],
            timeOnStep: []
        };
    },
    computed: {
        ...mapState({
            activeDateRange: (state) => state.filters.dateRange,
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            guide: 'guides/active',
            appMap: 'apps/appMapForActiveSubscription'
        }),
        appIds () {
            return get(this.guide, 'appIds', '');
        },
        isCrossApp () {
            return isCrossApp(this.guide);
        },
        ranges () {
            const length = 11;
            const oneSecond = 1000;

            return times(length, (index) => {
                const start = index * 2;
                const end = start + 2;

                return {
                    duration: `${start}-${end} secs`,
                    start: start * oneSecond,
                    end: end * oneSecond
                };
            }).concat({
                duration: `${length * 2}+ secs`,
                start: length * oneSecond * 2
            });
        },
        series () {
            const lookup = keyBy(
                this.timeOnGuide.filter((point) => isNumber(point.averageDuration)),
                'guideStepId'
            );
            let index = 1;

            return [
                {
                    name: 'Average Time on Step',
                    data: this.guide.steps.map((step) => {
                        const name = getStepDisplayName(step.id, this.guide);
                        const appId = get(this.appIds, '[0]', '');
                        const stepAppId = get(step, 'appIds[0]', appId);

                        return {
                            name,
                            id: step.id,
                            y: Math.round((lookup[step.id] ? lookup[step.id].averageDuration : 0) / 1000),
                            totalViews: lookup[step.id] ? lookup[step.id].total : 0,
                            color: getAppChartColor(this.appMap[stepAppId]),
                            className: `step-${index++}`
                        };
                    })
                }
            ];
        },
        totalTime () {
            return this.series[0].data.reduce((sum, point) => sum + point.y, 0);
        },
        averageDuration () {
            return this.formatTimeField('averageDuration');
        },
        drilldownStepNumber () {
            if (!this.drilldownStep) return '--';

            return findIndex(this.guide.steps, { id: this.drilldownStep }) + 1;
        }
    },
    watch: {
        isFetchingGuides () {
            this.refreshChart();
        },
        series () {
            if (!this.chart) return;
            this.chart.update({
                series: this.series
            });
        }
    },
    created () {
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.refreshChart();
        });
    },
    mounted () {
        this.initChart();
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async initChart () {
            await this.refreshChart();
            const chartConfig = this.getChartConfig();
            if (this.$refs['time-on-step']) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['time-on-step'], chartConfig);
            }
        },
        async refreshChart () {
            this.isFetching = true;
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
            const isMultiApp = isCrossApp(this.guide);
            [this.timeOnGuide, this.timeOnStep] = await Promise.all([
                getTimeOnGuide({
                    timeSeries,
                    appIds: this.appIds,
                    guideId: this.guide.id,
                    isMultiStep: true,
                    segmentId: this.activeSegmentId,
                    isMultiApp
                }),
                getTimeOnStep({
                    timeSeries,
                    appIds: this.appIds,
                    guideId: this.guide.id,
                    ranges: this.ranges,
                    segmentId: this.activeSegmentId,
                    isMultiApp
                })
            ]);

            if (this.drilldownStep && this.totalTime > 0) {
                this.drilldown(this.drilldownStep);
            } else {
                this.exitDrilldown();
            }

            this.isFetching = false;
        },
        formatTimeField (field) {
            const point = this.timeOnGuide.find(
                (point) =>
                    isNumber(point[field]) &&
                    (this.drilldownStep ? point.guideStepId === this.drilldownStep : point.guideId === this.guide.id)
            );

            if (!point) return '--';

            return prettyMilliseconds(point[field], { secondsDecimalDigits: 0 });
        },
        getChartConfig () {
            return {
                series: this.series,
                chart: {
                    type: 'column'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        events: {
                            click: (event) => {
                                this.drilldownColor = event.point.color;

                                return this.drilldown(event.point.id);
                            }
                        },
                        cursor: 'pointer'
                    }
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    categories: this.series[0].data.map((step) => step.name),
                    crosshair: false
                },
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        style: {
                            color: '#BABCC5',
                            fontWeight: 'normal',
                            textOutline: null
                        },
                        formatter () {
                            return this.total !== 0 ? `${this.total}s` : '';
                        }
                    },
                    labels: {
                        align: 'center'
                    },
                    title: {
                        text: 'Seconds'
                    },
                    min: 0,
                    allowDecimals: false
                },
                tooltip: {
                    pointFormat: '<b>Avg</b>: {point.y} seconds<br/><b>Total Views</b>: {point.totalViews}<br/>',
                    footerFormat: 'Click for step breakdown',
                    useHTML: true
                }
            };
        },
        getDrilldownSeries (guideStepId) {
            const points = this.timeOnStep.filter((point) => point.duration && point.guideStepId === guideStepId);
            const lookup = keyBy(points, 'duration');
            const stepNumber = findIndex(this.guide.steps, { id: guideStepId }) + 1;

            return [
                {
                    name: `Step ${stepNumber}`,
                    data: this.ranges.map((range) => {
                        return {
                            y: lookup[range.duration] ? lookup[range.duration].total : 0,
                            className: 'drilldown-step'
                        };
                    })
                }
            ];
        },
        getDrilldownChartConfig (guideStepId) {
            return {
                series: this.getDrilldownSeries(guideStepId),
                chart: {
                    type: 'column'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    categories: this.ranges.map((range) => range.duration),
                    crosshair: false
                },
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        style: {
                            color: '#BABCC5',
                            fontWeight: 'normal',
                            textOutline: null
                        },
                        formatter () {
                            return this.total !== 0 ? this.total : '';
                        }
                    },
                    labels: {
                        align: 'center'
                    },
                    title: {
                        text: 'Views'
                    },
                    min: 0,
                    allowDecimals: false
                },
                tooltip: {
                    headerFormat: '<span>{series.name}</span><br><b>{point.x}</b><br>',
                    pointFormat: '<b>Total views</b>: {point.y}<br/>',
                    useHTML: true
                },
                colors: [this.drilldownColor]
            };
        },
        drilldown (guideStepId) {
            this.drilldownStep = guideStepId;
            const chartConfig = this.getDrilldownChartConfig(guideStepId);
            if (this.$refs['time-on-step-drilldown']) {
                this.drilldownChart = this.$pendo.highcharts.chart(this.$refs['time-on-step-drilldown'], chartConfig);
            }
        },
        exitDrilldown () {
            this.drilldownChart = null;
            this.drilldownStep = null;
        }
    }
};
</script>

<style lang="scss">
.time-on-step-chart {
    .exit-drilldown {
        justify-self: end;
        line-height: 1.625rem;
        height: 26px;
    }

    .summary-chart-card__summary {
        grid-template-rows: min-content;
    }

    .step-summary-header {
        grid-row: 1/1;
        grid-column: 1/1;
        padding: 13px;
        font-weight: bold;
        border-bottom: 1px solid $gray-lighter-5;
        background: $gray-lighter-7;
    }

    .step-summary-content {
        grid-row: 1 / span 2;
        grid-column: 1/1;
        display: grid;
        align-content: center;
    }
}
</style>
