import { http } from '@pendo/http';
import store from '@/state/store';
import get from 'lodash/get';

export async function copyGuides ({ guides, pageMappings, featureMappings, targetSubId, targetAppId }, getProgress) {
    const total = guides.length;
    const successes = [];
    const failures = [];
    let forbidden = false;

    const copyRequests = guides.map((guide) => {
        const { id } = guide;
        const { pageMap, featureMap, triggerFeatureMap } = generateCountablesMappingsForCopyRequest(
            guide,
            pageMappings,
            featureMappings
        );

        return copyGuide({ id, targetSubId, targetAppId, pageMap, featureMap, triggerFeatureMap })
            .then((guide) => {
                successes.push(guide);
            })
            .catch(({ response }) => {
                if (response.status === 403) {
                    forbidden = true;

                    return;
                }

                failures.push(guide);
            })
            .finally(() => {
                getProgress({ total, successes: successes.length, failed: failures.length, forbidden });
            });
    });

    await Promise.all(copyRequests);

    return {
        successes,
        failures
    };
}

export function generateCountablesMappingsForCopyRequest (guide, pageMappings, featureMappings) {
    const guideCopyMappings = {
        pageMap: {},
        featureMap: {},
        triggerFeatureMap: {}
    };

    return guide.steps.reduce((guideCopyMappings, step) => {
        const pageMapEntry = pageMappings.find((mapping) => mapping.sourceCountable.id === step.pageId);
        const featureMapEntry = featureMappings.find((mapping) => mapping.sourceCountable.id === step.featureId);
        const triggerFeatureMapEntry = featureMappings.find(
            (mapping) => mapping.sourceCountable.id === step.triggerFeatureId
        );

        // Right now we only support copying a step with a page, feature, OR triggerFeature, not all.
        // Whenever we support all, this can just become a normal if/if
        if (pageMapEntry) {
            guideCopyMappings.pageMap[step.id] = {
                action: 'use',
                pageId: pageMapEntry.targetCountable.id
            };
        } else if (step.featureId && featureMapEntry) {
            guideCopyMappings.featureMap[step.id] = {
                action: 'use',
                featureId: featureMapEntry.targetCountable.id
            };
        } else if (step.triggerFeatureId && triggerFeatureMapEntry) {
            guideCopyMappings.triggerFeatureMap[step.id] = {
                action: 'use',
                triggerFeatureId: triggerFeatureMapEntry.targetCountable.id
            };
        }

        return guideCopyMappings;
    }, guideCopyMappings);
}

export async function copyGuide ({ id, targetSubId, targetAppId, pageMap, featureMap, triggerFeatureMap }) {
    return http
        .post(`/api/s/_SID_/guide/${id}/adopt/copy`, {
            targetSubId,
            targetAppId,
            pageMap,
            featureMap,
            triggerFeatureMap
        })
        .then((res) => res.data);
}

export async function loadTargetAppPages ({ targetSubId, targetAppId }) {
    return http.get(`/api/s/${targetSubId}/page?appId=${targetAppId}`);
}

export async function loadTargetAppFeatures ({ targetSubId, targetAppId }) {
    return http.get(`/api/s/${targetSubId}/feature?appId=${targetAppId}`);
}

export async function loadSourceCountables ({ pages, features }) {
    const countablesRequests = [];

    if (pages) {
        countablesRequests.push(store.dispatch['pages/fetch']);
    }

    if (features) {
        countablesRequests.push(store.dispatch['features/fetch']);
    }

    await Promise.all(countablesRequests);
}

export function eligibleSubscriptions () {
    const activeSubId = store.getters['subscriptions/active'].id;
    const subs = store.getters['subscriptions/listAll'];

    return subs.reduce((total, sub) => {
        if (sub.id === activeSubId) return total;
        if (!get(sub, 'applications.length', 0)) return total;
        if (store.getters['subscriptions/isActiveDASubAndHasMoreDASubs'] && !get(sub, 'isDigitalAdoption', false)) {
            return total;
        }
        if (
            store.getters['subscriptions/isActiveAEUSubAndHasMoreAEUSubs'] &&
            !get(sub, 'isTrainingSubscription', false)
        ) {
            return total;
        }

        return total.concat({
            ...sub,
            name: sub.displayName,
            icon: { type: 'layers' }
        });
    }, []);
}
