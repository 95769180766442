<template>
    <pendo-drawer
        :title="cardTitle"
        :visible="visible"
        size="fullscreen"
        @close="handleClose(false)">
        <div class="cross-app-guide-drawer-body">
            <pendo-card
                v-if="!guideToEdit"
                data-cy="cross-app-guide-name"
                class="cross-app-guide-name"
                :title="cardTitle">
                <pendo-input
                    v-model="name"
                    :invalid="!!isNameInvalid"
                    maxlength="48"
                    placeholder=""
                    label="Name Your Guide"
                    @input="checkIfNameIsValid" />
                <div
                    v-if="isNameInvalid"
                    class="cross-app-guide__error">
                    Name is required
                </div>
            </pendo-card>
            <pendo-alert type="warning">
                <slot v-if="!guideToEdit">
                    All Guides below will be set to Draft state. If needed, go back to the Guide List to copy Guides
                    before using them to create a Cross-App Guide.
                </slot>
                <slot v-if="guideToEdit">
                    Any newly added guides will be set to Draft state.
                </slot>
            </pendo-alert>
            <div class="cross-app-guide-order-table-wrapper">
                <pendo-table
                    ref="crossAppGuideOrderTable"
                    :data="rows"
                    :columns="columns"
                    :loading="isUpdating"
                    :row-class-name="getRowClassName"
                    :scroll-config="scrollConfig"
                    draggable
                    :title="tableTitle"
                    row-key="id"
                    auto-height
                    :auto-height-offset="136"
                    class="cross-app-guide-order-table"
                    @table-data-change="handleDataChange">
                    <template #urlHeader>
                        <div
                            class="guide-url-column-header"
                            data-cy="cross-app-guide-create--guide-url-tooltip">
                            <span class="guide-url-header--text">
                                Guide Start URL
                            </span>
                            <pendo-icon
                                v-pendo-tooltip="{
                                    arrow: true,
                                    multiline: true,
                                    // eslint-disable-next-line @pendo/tooltip
                                    html: true,
                                    trigger: 'click',
                                    content: urlTooltipContent
                                }"
                                class="guide-url-column-header--icon"
                                size="14"
                                type="info" />
                        </div>
                    </template>
                    <template #name="{ row }">
                        {{ row.name }}
                    </template>
                    <template #apps="{ row }">
                        <pendo-app-display :apps="row.app" />
                    </template>
                    <template #url="{ row }">
                        <pendo-input
                            class="cross-app-guide-url"
                            :invalid="isInvalidURL(row.url)"
                            pendo-draggable-ignore
                            :value="getValidURL(row)"
                            @input="(data) => updateValidUrl(data, row)">
                            <template #prepend>
                                <pendo-icon
                                    type="link"
                                    size="16" />
                            </template>
                        </pendo-input>
                        <div
                            v-if="isInvalidURL(row.url)"
                            class="cross-app-guide__error">
                            Please input a valid URL
                        </div>
                    </template>
                    <template #state="{ row }">
                        <div class="cross-app-guide-order-table--guide-status">
                            <pendo-icon
                                type="circle"
                                size="10"
                                :fill="getStatusOption(row).color"
                                :stroke="getStatusOption(row).color" />
                            <span>
                                {{ getStatusOption(row).label }}
                            </span>
                        </div>
                    </template>
                    <template #actions="{ row }">
                        <div pendo-draggable-ignore>
                            <pendo-actions-cell
                                :row="row"
                                :actions="actions"
                                @delete="handleRemoveGuide" />
                        </div>
                    </template>
                </pendo-table>
                <div
                    v-if="!isUpdating"
                    ref="crossAppGuideSelection"
                    class="cross-app-guide-table-input">
                    <div class="index-column">
                        {{ lastIndex }}
                    </div>
                    <pendo-multiselect
                        :disabled="!selectableGuides.length"
                        :clear-on-select="true"
                        :options="selectableGuides"
                        :searchable="true"
                        :allow-empty="true"
                        label-key="name"
                        value-key="id"
                        full-width
                        class="cross-app-added-guide-select"
                        placeholder="Add Existing Guide"
                        :popper-options="popperOptions"
                        @select="handleGuideSelection" />
                </div>
            </div>
        </div>
        <template #footer>
            <div class="cross-app-guide-footer">
                <pendo-button
                    data-cy="cross-app-guide--cancel-cross-app-guide-builder-button"
                    class="cancel-cross-app-guide-button"
                    label="Cancel"
                    type="secondary"
                    theme="app"
                    @click="handleClose(false)" />
                <pendo-button
                    v-if="!guideToEdit"
                    :disabled="!isValidForm"
                    :loading="isUpdating"
                    data-cy="cross-app-guide--launch-guide-builder-button"
                    class="cross-app-guide-button"
                    label="Create"
                    type="primary"
                    theme="app"
                    @click="createCrossAppGuide" />
                <pendo-button
                    v-if="guideToEdit"
                    :loading="isUpdating"
                    :disabled="updateDisabled"
                    data-cy="cross-app-guide-save"
                    class="cross-app-guide-button"
                    label="Save"
                    type="primary"
                    theme="app"
                    @click="updateCrossAppGuide" />
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { isValidUrl } from '@pendo/services/Formatters';
import { isBadgeGuide, isEditedByAdopt } from '@/stateless-components/utils/guides';
import { getDisplayName } from '@pendo/services/Apps';
import { isCrossApp, getAppGroups, getUrlForGroup } from '@pendo/services/CrossAppGuides';
import {
    PendoAppDisplay,
    PendoCard,
    PendoIcon,
    PendoInput,
    PendoAlert,
    PendoTable,
    PendoDrawer,
    PendoButton,
    PendoMultiselect,
    PendoActionsCell,
    PendoNotification,
    PendoTooltip
} from '@pendo/components';

export function displayErrorNotification (error, message) {
    if (!error) return;

    // eslint-disable-next-line no-console
    console.error(error);
    PendoNotification({
        type: 'error',
        title: 'Something went wrong!',
        message
    });
}

export default {
    name: 'CrossAppGuideDrawer',
    components: {
        PendoAppDisplay,
        PendoIcon,
        PendoCard,
        PendoInput,
        PendoAlert,
        PendoTable,
        PendoDrawer,
        PendoButton,
        PendoMultiselect,
        PendoActionsCell
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        selectedGuides: {
            type: Array,
            default: () => []
        },
        allGuides: {
            type: Array,
            default: () => []
        },
        appsMap: {
            type: Object,
            default: () => ({})
        },
        appUrlsMap: {
            type: Object,
            default: () => ({})
        },
        guideToEdit: {
            type: Object,
            default: null
        },
        errorOnUpdateGuideStatuses: {
            type: Error,
            default: null
        },
        errorOnCreate: {
            type: Error,
            default: null
        },
        errorOnUpdate: {
            type: Error,
            default: null
        },
        supportArticleUrl: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            minRequiredServiceVersion: '3.1.224',
            name: '',
            isNameInvalid: false,
            isUpdating: false,
            rows: [],
            originalRowsOnCreation: [],
            prevUrls: [],
            popperOptions: {
                onUpdate: () => {}
            },
            scrollConfig: {
                enabled: false
            },
            actions: [
                {
                    type: 'delete',
                    icon: 'trash-2',
                    tooltip: 'Delete'
                }
            ],
            tempGuideToEdit: null,
            columns: [
                {
                    label: 'Order',
                    type: 'index',
                    width: 50
                },
                {
                    prop: 'name',
                    label: 'Guide Name',
                    width: 300,
                    showOverflowTooltip: true
                },
                {
                    prop: 'apps',
                    label: 'Application',
                    showOverflowTooltip: true
                },
                {
                    prop: 'state',
                    label: 'Status',
                    width: 120
                },
                {
                    prop: 'steps.length',
                    label: '# of Steps',
                    width: 110
                },
                {
                    prop: 'url',
                    label: 'Guide Start URL',
                    formatter: (row) => this.getValidURL(row)
                },
                {
                    type: 'actions',
                    width: 120
                }
            ]
        };
    },
    computed: {
        urlTooltipContent () {
            let content = 'Enter the URL for the first step of each app in your Cross-App Guide.';
            if (!this.supportArticleUrl) return content;

            content += ` <a href="${this.supportArticleUrl}" target="_BLANK" >Learn More.</a>`;

            return content;
        },
        lastIndex () {
            return this.rows.length + 1;
        },
        selectableGuides () {
            const { existsInRows } = this;

            return this.allGuides.reduce((list, guide) => {
                const hasSteps = !!get(guide, 'steps.length', 0);
                const isCrossAppGuide = isCrossApp(guide);
                const isPartOfCurrentSelection = existsInRows(guide);
                const hasBadge = isBadgeGuide(guide);
                const isGuideEditedByAdopt = isEditedByAdopt(guide);

                if (!isCrossAppGuide && !isPartOfCurrentSelection && !hasBadge && hasSteps && isGuideEditedByAdopt) {
                    list.push({
                        ...guide,
                        url: this.getValidURL(guide)
                    });
                }

                return list;
            }, []);
        },
        getAllURLs () {
            return this.rows.map(({ url }) => url);
        },
        isNameEmpty () {
            return isEmpty(this.name.trim());
        },
        isValidForm () {
            return !this.hasAnyInvalidURLs && !this.isNameEmpty;
        },
        hasAnyInvalidURLs () {
            return !!this.getAllURLs.filter((url) => this.isInvalidURL(url)).length;
        },
        updateDisabled () {
            return !this.rows.length || isEqual(this.rows, this.originalRowsOnCreation) || this.hasAnyInvalidURLs;
        },
        tableTitle () {
            if (this.guideToEdit) return get(this.guideToEdit, 'name', 'Guide');

            return 'Single App Guides';
        },
        cardTitle () {
            if (this.guideToEdit) return 'Edit Cross-App Guide';

            return 'Create Cross-App Guide';
        }
    },
    watch: {
        visible (nowVisible) {
            this.name = '';
            this.tempGuideToEdit = null;
            this.isUpdating = false;

            if (nowVisible) {
                this.tempGuideToEdit = cloneDeep(this.guideToEdit);
            }

            this.assignRows();
        },
        errorOnUpdateGuideStatuses (error) {
            displayErrorNotification(error, 'One or more of your guides could not be set to draft');
        },
        errorOnCreate (error) {
            displayErrorNotification(error, 'Failed to create cross app guide');
            this.isUpdating = false;
        },
        errorOnUpdate (error) {
            displayErrorNotification(error, 'Failed to update cross app guide');
            this.isUpdating = false;
        }
    },
    methods: {
        existsInRows (guide) {
            return !!this.rows.find(({ id }) => id === guide.id);
        },
        assignRows () {
            if (this.selectedGuides.length) {
                this.rows = this.selectedGuides.map((guide) => ({ ...guide, url: this.getValidURL(guide) }));

                return;
            }

            if (!this.tempGuideToEdit) return;

            const appGroups = getAppGroups(this.tempGuideToEdit);
            this.rows = appGroups.reduce((acc, appGroup, index) => {
                const url =
                    getUrlForGroup({ currGroup: appGroups[index], prevGroup: appGroups[index - 1] }) ||
                    this.getUrlFromAppGroupStep(appGroups[0][0]);
                const appId = appGroup[0].appIds[0];
                const row = {
                    id: `${appGroup[0].appIds[0]}-${index}`,
                    name: '--',
                    app: this.appsMap[appId] || null,
                    steps: appGroup,
                    url,
                    lastStepBuildingBlocks: JSON.parse(appGroups[index][appGroups[index].length - 1].buildingBlocks)
                };

                acc.push(row);

                return acc;
            }, []);

            this.prevUrls = this.getAllURLs;
            this.originalRowsOnCreation = cloneDeep(this.rows);
        },
        isInvalidURL (url) {
            const isEmptyURL = url === '';
            const isInvalidURL = !isValidUrl(url);

            return isEmptyURL || isInvalidURL;
        },
        getRowClassName ({ row }) {
            return `${getDisplayName(row.app).replace(/,\s*$/, '-')}-row`;
        },
        getStatusOption (row) {
            const defaultStatus = get(this.guideToEdit, 'state', 'disabled');
            const state = get(row, 'state', defaultStatus);
            const color = Object.freeze({
                public: '#00aa62',
                scheduled: '#f05b00',
                staged: '#b08300',
                draft: '#07699b',
                disabled: '#6a6c75',
                archived: '#6a6c75',
                deleted: '#db1111'
            })[state];

            return {
                label: state,
                status: state,
                color
            };
        },
        getUrlFromAppGroupStep (step) {
            return this.appUrlsMap[step.appIds[0]];
        },
        getValidURL (row) {
            if (row.url) return row.url;

            const guideAppId = get(row, 'appId', false);
            const appId = get(row, 'steps[0].appId', guideAppId);

            if (!appId) return '';

            return this.appUrlsMap[appId];
        },
        updateValidUrl (url, row) {
            this.$refs.crossAppGuideOrderTable.updateRow({
                ...row,
                url
            });
        },
        handleClose (clearSelected) {
            this.$emit('close', clearSelected);
        },
        createCrossAppGuide () {
            if (this.hasIncompatibleSharedServiceVersions()) return;
            this.isUpdating = true;
            this.$emit('create', {
                name: this.name,
                rows: this.rows,
                urls: this.getAllURLs
            });
        },
        updateCrossAppGuide () {
            if (this.hasIncompatibleSharedServiceVersions()) return;
            this.isUpdating = true;
            this.$emit('update', {
                guide: this.tempGuideToEdit,
                rows: this.rows,
                urls: this.getAllURLs,
                prevUrls: this.prevUrls
            });
        },
        handleDataChange ({ data }) {
            if (!isEqual(this.rows, data)) this.rows = data;
        },
        async handleGuideSelection (guide) {
            if (this.existsInRows(guide)) return;
            this.rows.push(guide);

            await this.$nextTick();
            this.$refs.crossAppGuideSelection.scrollIntoView();
        },
        handleRemoveGuide (guide) {
            if (!this.existsInRows(guide)) return;
            const i = this.rows.findIndex(({ id }) => id === guide.id);
            if (i < 0) return;
            const selectedRows = cloneDeep(this.rows);
            selectedRows.splice(i, 1);
            this.rows = selectedRows;
        },
        checkIfNameIsValid () {
            this.isNameInvalid = this.isNameEmpty;
        },
        getRowIndex (row) {
            return this.rows.findIndex(({ id }) => id === row.id);
        },
        hasIncompatibleSharedServiceVersions () {
            const ssvErrorGuides = [];
            this.rows.forEach((guide) => {
                if (guide.attributes && guide.attributes.sharedServiceVersion < this.minRequiredServiceVersion) {
                    ssvErrorGuides.push({ id: guide.id, name: guide.name });
                }
            });
            const hasIncompatibleSharedServiceVersions = !!ssvErrorGuides.length;

            if (hasIncompatibleSharedServiceVersions) {
                this.notifyVersion(ssvErrorGuides);
            }

            return hasIncompatibleSharedServiceVersions;
        },
        notifyVersion (ssvErrorGuides) {
            const guideLinks = ssvErrorGuides.map(
                (guide) => `<a href="${window.location.origin}/guides/${guide.id}"> ${guide.name}</a>`
            );
            PendoNotification({
                type: 'error',
                title: 'Cross-App Guide update failed',
                dangerouslyUseHTMLString: true,
                duration: 0,
                message: `The following guides must be opened and saved in Adopt Studio to be used for Cross-App Guides:${[
                    guideLinks
                ]}`
            });
        }
    }
};
</script>

<style scoped lang="scss">
.cross-app-guide-footer {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: end;
}

.cross-app-guide-drawer-body {
    padding: 32px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 90vh;

    @media screen and (max-height: 1200px) {
        height: 85vh;
    }

    @media screen and (max-height: 800px) {
        height: 80vh;
    }

    .cross-app-guide__error {
        position: relative;
        color: #db1111; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        font-size: 12px;
        line-height: 17px;
        margin-top: 4px;
    }
}

.cross-app-guide-order-table-wrapper {
    overflow: auto;
    border-bottom: solid thin $gray-lighter-5;

    .cross-app-guide-order-table {
        max-height: fit-content !important; /* stylelint-disable-line declaration-no-important */

        ::v-deep .pendo-table__body {
            max-height: none !important; /* stylelint-disable-line declaration-no-important */
            overflow-y: hidden;
            overscroll-behavior-x: none;

            > div {
                height: auto;
            }
        }

        .guide-url-column-header {
            display: flex;
            align-items: center;
            gap: 5px;

            .guide-url-column-header--icon {
                display: inline-block;
                z-index: 100;
                cursor: pointer;
            }
        }
    }
}

.cross-app-guide-table-input {
    border: solid $gray-lighter-5;
    border-width: 0px 1px 1px;
    background-color: $white;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px 0px;

    .index-column {
        width: 50px;
        text-align: center;
        color: $gray-lighter-1;
        font-size: 14px;
    }
}

.cross-app-added-guide-select {
    min-width: 350px;
}

.cross-app-guide-url {
    ::v-deep .pendo-input__prepend {
        background-color: $white;
    }

    ::v-deep .pendo-input__field {
        border-left: 0;
    }

    &:hover,
    &:focus-within {
        ::v-deep .pendo-input__prepend {
            border-color: #2a2c35;
        }

        ::v-deep .pendo-input__field {
            border-color: #2a2c35;
        }
    }
}

.cross-app-guide-order-table--guide-status {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    text-transform: capitalize;
}

.cross-app-guide-name {
    min-height: 160px;

    ::v-deep .pendo-card__body {
        min-height: auto !important; /* stylelint-disable-line declaration-no-important */
    }
}
</style>
