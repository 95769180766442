<template>
    <div>
        <pendo-card body-min-height="145px">
            <template #body>
                <div class="call-to-action">
                    <div class="call-to-action__body">
                        <img
                            v-if="noPagesOrFeatures"
                            class="call-to-action__body--image"
                            src="../../../img/empty-states/double-empty-state.svg"
                            alt="action icon">
                        <img
                            v-if="noFeatures && !noPagesOrFeatures"
                            class="call-to-action__body--image"
                            src="../../../img/empty-states/feature-list-empty-state.svg"
                            alt="action icon">
                        <img
                            v-if="noPages && !noPagesOrFeatures"
                            class="call-to-action__body--image"
                            src="../../../img/empty-states/pages-list-empty-state.svg"
                            alt="action icon">
                        <div class="call-to-action__body--text">
                            <div class="call-to-action__body--text__header">
                                {{ ctaHeader }}
                            </div>
                            <div class="call-to-action__body--text__description">
                                {{ ctaDescription }}
                            </div>
                        </div>
                    </div>
                    <div class="call-to-action__buttons">
                        <pendo-button
                            v-if="noPagesOrFeatures || noPages"
                            label="Tag first page"
                            type="secondary"
                            theme="app"
                            @click="$emit('launchTagPagesModal')" />
                        <pendo-button
                            v-if="noPagesOrFeatures || noFeatures"
                            label="Tag first feature"
                            theme="app"
                            @click="$emit('launchTagFeaturesModal')" />
                    </div>
                </div>
            </template>
        </pendo-card>
    </div>
</template>

<script>
import { PendoButton, PendoCard } from '@pendo/components';

export default {
    name: 'CallToActionCard',
    components: {
        PendoButton,
        PendoCard
    },
    props: {
        activeSubHasAnyFeatures: {
            type: Boolean,
            default: false
        },
        activeSubHasAnyPages: {
            type: Boolean,
            default: false
        },
        appsWithoutPages: {
            type: Array,
            default: () => []
        },
        appsWithoutFeatures: {
            type: Array,
            default: () => []
        },
        filteredApps: {
            type: Array,
            default: () => []
        },
        isAppIdsFilterInUse: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ctaHeader () {
            const featureAppCount = this.appsWithoutFeatures.length;
            const pageAppCount = this.appsWithoutPages.length;

            let header = 'No pages and features tagged for this subscription';

            if (!this.activeSubHasAnyPages && this.activeSubHasAnyFeatures) {
                header = 'No pages tagged for this subscription';
            }

            if (!this.activeSubHasAnyFeatures && this.activeSubHasAnyPages) {
                header = 'No features tagged for this subscription';
            }

            if (this.isAppIdsFilterInUse) {
                if (this.filteredApps.length === 1) {
                    if (pageAppCount && !featureAppCount) {
                        header = 'No pages tagged for this app';
                    } else if (!pageAppCount && featureAppCount) {
                        header = 'No features tagged for this app';
                    } else if (pageAppCount && featureAppCount) {
                        header = 'No pages and features tagged for this app';
                    }
                }
            }

            return header;
        },
        ctaDescription () {
            let description =
                'Tag a page or a feature on the page, like a button, to begin analyzing visitor behavior within your applications.';
            if (this.noPages) {
                description =
                    'Tagging pages allows you to view page traffic and understand the behavior of your users.';
            }
            if (this.noFeatures) {
                description = 'Feature analytics tells you what users are interacting with while using an application.';
            }

            return description;
        },
        noPagesOrFeatures () {
            return !!(
                (!this.activeSubHasAnyPages && !this.activeSubHasAnyFeatures) ||
                (this.appsWithoutPages.length &&
                    this.appsWithoutFeatures.length &&
                    this.isAppIdsFilterInUse && this.filteredApps.length === 1)
            );
        },
        noPages () {
            return !!(
                (!this.activeSubHasAnyPages && this.activeSubHasAnyFeatures) ||
                (this.appsWithoutPages.length &&
                    !this.appsWithoutFeatures.length &&
                    this.isAppIdsFilterInUse && this.filteredApps.length === 1)
            );
        },
        noFeatures () {
            return !!(
                (this.activeSubHasAnyPages && !this.activeSubHasAnyFeatures) ||
                (!this.appsWithoutPages.length &&
                    this.appsWithoutFeatures.length &&
                    this.isAppIdsFilterInUse && this.filteredApps.length === 1)
            );
        }
    }
};
</script>

<style lang="scss">
.call-to-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__body {
        display: flex;
        flex-direction: row;
        row-gap: 10px;

        &--image {
            width: 128px;
            height: 110px;
        }

        &--text {
            margin: 5px 15px;
            margin-bottom: 8px;

            &__header {
                font-size: 22px;
                line-height: 37px;
                font-weight: 700;
            }

            &__description {
                font-size: 16px;
                line-height: 27px;
                font-weight: 400;
            }
        }
    }

    &__buttons {
        column-gap: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;

        .pendo-button {
            > span {
                min-width: 110px;
            }

            margin: 0 !important; /* stylelint-disable-line */
        }
        align-self: center;
    }
}
</style>
