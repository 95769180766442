<template>
    <div class="view--settings">
        <settings-header />
        <settings-content />
    </div>
</template>

<script>
import SettingsHeader from '@/components/settings/SettingsHeader';
import SettingsContent from '@/components/settings/SettingsContent';

export default {
    name: 'SettingsView',
    components: {
        SettingsHeader,
        SettingsContent
    }
};
</script>
