import { v4 as uuid } from 'uuid';
import { http } from '@pendo/http';
import has from 'lodash/has';
import store from '@/state/store';
import { inDevEnvironment } from '@/utils/utils';
import { isDefaultSegment } from '@/utils/segments';
import { GUIDES_PRODUCT_AREAS, GUIDES_SUB_PRODUCT_AREAS } from '@pendo/services/Constants';

export const MAX_RETRIES = 5;

export const MAX_CSV_RETRIES = 600;

export const aggregationsEndpoint = '/api/aggregation/s/_SID_/?all=true';
const requestsByNameTimestamps = {};

export async function requestCsv (spec, fields) {
    spec.requestId = uuid();
    const payload = {
        requests: [spec],
        response: {
            location: 'gcs',
            mimeType: 'text/csv',
            fields,
            name: `${spec.name}-${spec.requestId}.csv`
        }
    };

    if (inDevEnvironment) {
        recordAggDebugData(spec.name);
    }

    const axiosConfig = { retry: MAX_RETRIES };

    await http.post(`${aggregationsEndpoint}&name=${spec.name}`, payload, axiosConfig);

    const response = await fetchUrlOfCompletedCsv(spec.name, spec.requestId);

    return response;
}

export async function getLongRunningAggregationStatus (jobId) {
    return http.get(`/api/aggregation/s/_SID_/${jobId}/status`);
}

export async function request (spec, { rowsOnly = true, signal, location = 'request', name } = {}) {
    spec.requestId = uuid();
    const payload = {
        requests: [spec],
        response: {
            name,
            location,
            mimeType: 'application/json'
        }
    };

    if (inDevEnvironment) {
        recordAggDebugData(spec.name);
    }

    const axiosConfig = { retry: MAX_RETRIES };
    if (signal) axiosConfig.signal = signal;

    const { data } = await http.post(`${aggregationsEndpoint}&name=${spec.name}`, payload, axiosConfig);

    if (data && data.aggregationJobId) {
        // this is a long-running aggregation
        return data;
    }
    // when response exceeds a certain size, backend stores it in GCS
    const gcsUrlPromises = [];
    const messagesHasUrl = data.messages.some((message) => message.url);
    if (messagesHasUrl) {
        for (const message of data.messages) {
            if (has(message, 'url')) {
                gcsUrlPromises.push(http.get(message.url, { withCredentials: false }));
            } else {
                gcsUrlPromises.push({ data: message.rows });
            }
        }
        const responses = await Promise.all(gcsUrlPromises);
        for (const message of data.messages) {
            const { data } = responses.shift();
            message.rows = data;
        }
    }

    return rowsOnly ? data.messages[0].rows : data;
}

export function parseSegmentIdForAggregation (segmentId) {
    return isDefaultSegment(segmentId) ? null : segmentId;
}

export function parseAppIdForAggregation (appId) {
    return appId.toString().replace('-', '_');
}

// fetches an ever-changing url for the request, checks gcs for presence of data
// if the url's request resolves, then it resolves with its url so the user can download the file.
// yes, this will intentionally 404 until the file is present in GCS
export async function fetchUrlOfCompletedCsv (name, requestId, attempts = 1) {
    try {
        const data = await http.get(`/api/s/_SID_/object/${name}-${requestId}.csv/url`);

        return data;
    } catch (error) {
        if (attempts < MAX_CSV_RETRIES) {
            await waitWithProgressiveBackoff(attempts);

            return fetchUrlOfCompletedCsv(name, requestId, attempts + 1);
        }

        throw error;
    }
}

// since adhoc CSVs hammer endpoints, we slowly make the time btwn requests longer
export function waitWithProgressiveBackoff (attempts) {
    let timeout = 1000;
    if (attempts > 10 && attempts < 30) {
        timeout *= 2.5;
    } else if (attempts > 31 && attempts < 60) {
        timeout *= 5;
    } else if (attempts > 61) {
        timeout *= 10;
    }

    return wait(timeout);
}

function wait (time) {
    return new Promise((resolve) => {
        setTimeout(resolve, time);
    });
}

function recordAggDebugData (aggName) {
    if (!requestsByNameTimestamps[aggName]) requestsByNameTimestamps[aggName] = [];

    const recordForAggType = requestsByNameTimestamps[aggName];

    const timestamp = Date.now();
    recordForAggType.push(timestamp);

    if (recordForAggType.length === 1) return;
    if (recordForAggType.length > 10) recordForAggType.shift();

    const lastRequestTimestamp = recordForAggType[recordForAggType.length - 2];
    const twoSeconds = 1000 * 2;

    if (timestamp - lastRequestTimestamp < twoSeconds) {
        // eslint-disable-next-line no-console
        console.warn(
            `[Dev-Only Warning]: Potential duplicate agg detected for agg with name "${aggName}". See stack trace for caller`
        );
    }
}

export function identifiedState (segmentId) {
    const { canShowAnonOptions } = store.state.filters;
    const identifiedAnonOptions = ['everyoneExcludingAnonymous', 'anonymousVisitorsOnly'];
    const isEveryoneSegment = !segmentId || segmentId === 'everyone';
    if (!canShowAnonOptions) {
        return 'visitorId';
    }
    // If segment id is set to 'everyone' or is a custom segment, let custom segment control visitor type
    if (isEveryoneSegment || !identifiedAnonOptions.includes(segmentId)) {
        return null;
    }

    return segmentId === 'everyoneExcludingAnonymous' ? 'visitorId' : '!visitorId';
}

export const PRODUCT_AREAS = {
    TAGGING_AND_SETUP: 'TaggingAndSetup',
    ANALYTICS: 'Analytics',
    ...GUIDES_PRODUCT_AREAS
};

export const SUB_PRODUCT_AREAS = {
    SUGGESTED_PAGES: 'SuggestedPages',
    PATHS: 'Paths',
    PAGE_LIST_DETAILS: 'PageListAndDetails',
    FEATURE_LIST_DETAILS: 'FeatureListAndDetails',
    TRACK_EVENT_LIST_DETAILS: 'TrackEventListAndDetails',
    WORKFLOWS_JOURNEYS: 'WorkflowsAndJourneys',
    VISITOR_LIST_DETAILS: 'VisitorListAndDetails',
    APPLICATION_USAGE: 'ApplicationUsage',
    LICENSE_UTILIZATION: 'LicenseUtilization',
    PORTFOLIO_OVERVIEW: 'PortfolioOverview',
    ...GUIDES_SUB_PRODUCT_AREAS
};
