import { ALL_APPS_AGG_ID, ALL_EXTENSION_APPS_AGG_ID, ALL_EXTENSION_WEB_APPS_AGG_ID } from '@/constants/apps';
import { GUIDE_STATUS_COLORS } from '@/constants/guide-statuses';

export function filterIsAllApps (appIdsFilter = []) {
    return [ALL_APPS_AGG_ID, ALL_EXTENSION_APPS_AGG_ID, ALL_EXTENSION_WEB_APPS_AGG_ID].includes(appIdsFilter[0]);
}

export const USER_SETTINGS_NAMES = {
    guidesListColumns: 'guide-list',
    guideListExpansionFilters: 'guide-list-expansion-filters'
};

export const GUIDE_EXPANSION_FILTER_OPTIONS = {
    statuses: [
        {
            id: 'public',
            name: 'Public',
            icon: {
                type: 'circle',
                size: '8',
                fill: GUIDE_STATUS_COLORS.public,
                stroke: GUIDE_STATUS_COLORS.public,
                class: 'status-circle'
            }
        },
        {
            id: 'staged',
            name: 'Staged',
            icon: {
                type: 'circle',
                size: '8',
                fill: GUIDE_STATUS_COLORS.staged,
                stroke: GUIDE_STATUS_COLORS.staged,
                class: 'status-circle'
            }
        },
        {
            id: 'scheduled',
            name: 'Scheduled',
            icon: {
                type: 'circle',
                size: '8',
                fill: GUIDE_STATUS_COLORS.scheduled,
                stroke: GUIDE_STATUS_COLORS.scheduled,
                class: 'status-circle'
            }
        },
        {
            id: 'draft',
            name: 'Draft',
            icon: {
                type: 'circle',
                size: '8',
                fill: GUIDE_STATUS_COLORS.draft,
                stroke: GUIDE_STATUS_COLORS.draft,
                class: 'status-circle'
            }
        },
        {
            id: 'disabled',
            name: 'Disabled',
            icon: {
                type: 'circle',
                size: '8',
                fill: GUIDE_STATUS_COLORS.disabled,
                stroke: GUIDE_STATUS_COLORS.disabled,
                class: 'status-circle'
            }
        }
    ],
    activations: [
        'App Launch',
        'Automatically',
        'Badge',
        'Confirmation',
        'Page View',
        'Resource Center',
        'Target Element',
        'No Activation'
    ],
    expirations: ['Active', 'Expired']
};
