import { render, staticRenderFns } from "./SubscriptionSettings.vue?vue&type=template&id=6519959d&scoped=true&"
import script from "./SubscriptionSettings.vue?vue&type=script&lang=js&"
export * from "./SubscriptionSettings.vue?vue&type=script&lang=js&"
import style0 from "./SubscriptionSettings.vue?vue&type=style&index=0&id=6519959d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6519959d",
  null
  
)

export default component.exports