import { render, staticRenderFns } from "./GuideCreate.vue?vue&type=template&id=6b164252&scoped=true&"
import script from "./GuideCreate.vue?vue&type=script&lang=js&"
export * from "./GuideCreate.vue?vue&type=script&lang=js&"
import style0 from "./GuideCreate.vue?vue&type=style&index=0&id=6b164252&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b164252",
  null
  
)

export default component.exports