<template>
    <div class="element-localizer-input">
        <translation-input-row :warning="warnings[transUnitId]">
            <pendo-input
                data-cy="element-localizer"
                :value="value"
                :disabled="disabled || isPrimaryDisabled"
                @input="$emit('change', { transUnitId, value: $event })">
                <template #prepend>
                    <pendo-icon
                        stroke="#2A2C35"
                        :size="16"
                        :type="icon" />
                </template>
                <template #suffix>
                    <span
                        v-if="valueHasChanged"
                        class="element-localizer-edited-marker">
                        (Edited)
                    </span>
                </template>
            </pendo-input>
        </translation-input-row>
        <translation-input-row
            v-if="hasTranslatableAction"
            :is-child="true"
            :warning="warnings[transUnitAttr.id]">
            <pendo-input
                data-cy="element-localizer-action"
                :label="transUnitAction.label"
                :value="transUnitActionValue"
                :disabled="disabled"
                @input="$emit('change', { transUnitId: transUnitAction.id, value: $event })">
                <template #suffix>
                    <span
                        v-if="actionValueHasChanged"
                        class="element-localizer-edited-marker">
                        (Edited)
                    </span>
                </template>
            </pendo-input>
        </translation-input-row>
        <translation-input-row
            v-if="hasTranslatableAttribute"
            :is-child="true"
            :warning="warnings[transUnitAttr.id]">
            <pendo-input
                data-cy="element-localizer-alt"
                :label="transUnitAttr.label"
                :value="transUnitAttrValue"
                :disabled="disabled"
                @input="$emit('change', { transUnitId: transUnitAttr.id, value: $event })">
                <template #suffix>
                    <span
                        v-if="attrValueHasChanged"
                        class="element-localizer-edited-marker">
                        (Edited)
                    </span>
                </template>
            </pendo-input>
        </translation-input-row>
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoInput, PendoIcon } from '@pendo/components';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';
import TranslationInputRow from './TranslationInputRow';

// A single building block can contain multiple translatable items. The first is it's primary value
// which is the value of the element the building block represents (e.g., button text). The others
// are attributes or actions related to that element, which can also be translatable
// (e.g., aria-label, go to URL button action, alt text, etc.). Every translatable item for a given
// building block shares the same translation ID with an identifier appended to the end to
// differentiate non-primary values (e.g. 6104c457-0afd-4304-9ca1-a278a1013248|ariaLabel).
//
// In the context of this component, these non-primary values are referred to as secondary values.
const SECONDARY_VALUE_SUFFIX = {
    ARIA: 'ariaLabel',
    ALT: 'alt',
    VIDEO_TITLE: 'videoTitle',
    BUTTON_LINK: 'url'
};
const SECONDARY_VALUE_SUFFIXES = Object.values(SECONDARY_VALUE_SUFFIX);

export default {
    name: 'ElementLocalizer',
    components: {
        TranslationInputRow,
        PendoInput,
        PendoIcon
    },
    props: {
        transUnitGroup: {
            type: Object,
            required: true
        },
        transUnitMap: {
            type: Object,
            required: true
        },
        initialTransUnitValues: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        warnings: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['change'],
    computed: {
        isPrimaryDisabled () {
            return !get(this.transUnitMap, `${this.transUnitId}.source`);
        },
        hasTranslatableAttribute () {
            return !!(this.transUnitAttr && get(this.transUnitMap, `${this.transUnitAttr.id}.source`));
        },
        hasTranslatableAction () {
            const { BUTTON_LINK } = SECONDARY_VALUE_SUFFIX;

            return !!get(this.transUnitMap, `${this.transUnitGroup.id}|${BUTTON_LINK}.source`);
        },
        transUnitId () {
            const { blocks, type } = this.transUnitGroup;

            // The guide dismiss button is a special case where it is like a button but it only
            // ever has an aria label.
            if (BuildingBlockLayouts.widgetIds.closeButtonBlock === type) {
                return get(blocks, '[0].id');
            }

            // Find the first block that doesn't end with a secondary value suffix. Some
            // transUnits without additional attributes end in valid suffixes like |md.
            const block = blocks.find(({ id }) => {
                return SECONDARY_VALUE_SUFFIXES.every((suffix) => !id.endsWith(suffix));
            });

            return get(block, 'id', '');
        },
        transUnitAttrValue () {
            return get(this.transUnitMap, `${this.transUnitAttr?.id}.${this.valueKey}`);
        },
        transUnitActionValue () {
            return get(this.transUnitMap, `${this.transUnitAction?.id}.${this.valueKey}`);
        },
        transUnitAction () {
            const { id, type } = this.transUnitGroup;
            const { BUTTON_LINK } = SECONDARY_VALUE_SUFFIX;

            if (BuildingBlockLayouts.widgetIds.buttonBlock === type) {
                return {
                    id: `${id}|${BUTTON_LINK}`,
                    label: 'URL'
                };
            }

            return null;
        },
        transUnitAttr () {
            const { id, type } = this.transUnitGroup;
            const { ARIA, ALT, VIDEO_TITLE } = SECONDARY_VALUE_SUFFIX;

            switch (type) {
                case BuildingBlockLayouts.widgetIds.buttonBlock:
                    return {
                        id: `${id}|${ARIA}`,
                        label: 'ARIA label - Accessible name'
                    };
                case BuildingBlockLayouts.widgetIds.imageBlock:
                    return {
                        id: `${id}|${ALT}`,
                        label: 'Alt text'
                    };
                case BuildingBlockLayouts.widgetIds.videoBlock:
                    return {
                        id: `${id}|${VIDEO_TITLE}`,
                        label: 'Title'
                    };
                default:
                    return null;
            }
        },
        icon () {
            switch (this.transUnitGroup.type) {
                case BuildingBlockLayouts.widgetIds.closeButtonBlock:
                    return 'x';
                case BuildingBlockLayouts.widgetIds.buttonBlock:
                    return 'rectangle';
                case BuildingBlockLayouts.widgetIds.imageBlock:
                    return 'image';
                case BuildingBlockLayouts.widgetIds.videoBlock:
                    return 'film';
                default:
                    return 'align-center';
            }
        },
        value () {
            return get(this.transUnitMap, `${this.transUnitId}.${this.valueKey}`);
        },
        valueHasChanged () {
            return this.hasValueChanged(this.transUnitId, this.value);
        },
        actionValueHasChanged () {
            return this.hasValueChanged(this.transUnitAction.id, this.transUnitActionValue);
        },
        attrValueHasChanged () {
            return this.hasValueChanged(this.transUnitAttr.id, this.transUnitAttrValue);
        }
    },
    methods: {
        hasValueChanged (translationId, value) {
            if (this.valueKey === 'target') {
                const initialTranslation = this.initialTransUnitValues.find(
                    ({ transUnitId }) => transUnitId === translationId
                ).target;

                return value !== initialTranslation;
            }

            return false;
        }
    }
};
</script>
