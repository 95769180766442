<template>
    <div class="journey-header">
        <div
            v-if="journeyNum > 0"
            class="journey-count-container">
            <p class="journey-count">
                {{ journeyNum }}
            </p>
            <pendo-divider
                :stroke-width="1"
                width="10px"
                height="22px"
                stroke="#DADCE5"
                class="journey-divider"
                direction="vertical" />
        </div>
        <div :class="heading ? 'journey-info-container with-heading' : 'journey-info-container'">
            <p
                v-if="heading"
                class="heading">
                {{ heading }}
            </p>
            <p class="top-line-info">
                {{ topLineComputed }}
            </p>
            <p class="subtitle-info">
                {{ subtitleContent }}
            </p>
        </div>
    </div>
</template>
<script>
import { pluralize } from '@/utils/formatters';
import { PendoDivider } from '@pendo/components';

export default {
    name: 'JourneyHeader',
    components: {
        PendoDivider
    },
    props: {
        heading: {
            type: String,
            default: null
        },
        numOfAttempts: {
            type: Number,
            default: 0
        },
        abbreviatedTimeTaken: {
            type: String,
            default: ''
        },
        stepCount: {
            type: Number,
            default: 0
        },
        journeyNum: {
            type: Number,
            default: 0
        },
        totalNumOfAttempts: {
            type: Number,
            default: 0
        }
    },
    computed: {
        attemptPercentage () {
            // Valid values: 98%, 12%, 8.7%, 1.3%, 0.2%, <0.1%
            const percentValue = Math.floor((this.numOfAttempts / this.totalNumOfAttempts) * 1000) / 10;
            if (percentValue === 0) return '<0.1';
            if (percentValue >= 10) return Math.floor(percentValue).toString();

            return percentValue.toString();
        },
        topLineComputed () {
            return `${this.numOfAttempts} ${pluralize('Attempt', this.numOfAttempts)} (${this.attemptPercentage}%)`;
        },
        subtitleContent () {
            return `${this.stepCount} ${pluralize('step', this.stepCount)} ∙ ${this.abbreviatedTimeTaken}`;
        }
    }
};
</script>
<style lang="scss" scoped>
.journey-header {
    display: flex;
    max-height: 75px;
    margin-bottom: 16px;

    .journey-count-container {
        display: flex;
        align-content: center;
        align-items: center;
        margin-right: 4px;

        .journey-count {
            margin-top: 3px;
            font-size: 14px;
            color: $gray-lighter-2;
        }
    }

    .journey-info-container {
        margin-top: 20px;

        &.with-heading {
            margin-top: 5px;
        }

        .heading {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            margin-bottom: 4px;
        }

        .top-line-info {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-top: 0;
        }

        .subtitle-info {
            color: $gray-lighter-2;
            font-size: 12px;
            line-height: 18px;
            margin-top: 0px;
        }
    }
}
</style>
