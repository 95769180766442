import filter from 'lodash/filter';
import find from 'lodash/find';
import isString from 'lodash/isString';
import first from 'lodash/first';
import last from 'lodash/last';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import moment from '@/utils/moment';
import { http } from '@pendo/http';
import {
    BuildingBlock,
    BuildingBlockLayouts,
    StepCopy,
    BuildingBlockPropertiesService,
    BuildingBlockMigrations,
    BuildingBlockEditing,
    SdkTools
} from '@pendo/services/BuildingBlocks';
import sharedServicesPackage from '@pendo/services/package.json';
import { updateGuideAndStepPolls } from '@/stateless-components/utils/guides';
// NOTE: This dependency is creating a transitive dependency on Vuex.
// Stateless components cannot use utilities in this file, unless this dependency is refactored.
import { meetsMinimumAgentVersion } from '@/utils/apps';
import { iconInformationSymbol } from './icon-information-symbol';
import { EDITOR_TYPES } from '@pendo/services/Constants';

const { version: sharedServiceVersion } = sharedServicesPackage;
const { createBuildingBlocksProperties } = BuildingBlockPropertiesService;
const { getMinSdkVersionForGuide } = SdkTools;

export const STEP_RANK_INCREMENT = 5000000;

export async function create ({
    buildingBlocks,
    name,
    appId,
    isTraining,
    priority,
    themeId,
    editorType,
    platform,
    type,
    isAppFlutterCodeless,
    isMobile,
    carouselId
}) {
    const buildingBlocksArray = [].concat(buildingBlocks);
    const guide = await createEmptyGuide({
        appId,
        name,
        isTraining,
        priority,
        editorType,
        platform,
        type,
        isAppFlutterCodeless,
        isMobile,
        carouselId
    });
    guide.isMultiStep = buildingBlocksArray.length > 1;
    guide.steps = await Promise.all(
        buildingBlocksArray.map((buildingBlocks, i) => {
            const rank = STEP_RANK_INCREMENT * (i + 1);

            return createStep({ guideId: guide.id, buildingBlocks, rank, appId, themeId, carouselId });
        })
    );

    updateGuideAndStepPolls(guide);

    return guide;
}

export function addBadgeToGuide (guide, badgeConfig) {
    const { isConfirmation } = badgeConfig;
    const layoutType = isConfirmation ? 'badgeConfirmation' : 'badgeBlank';
    const layoutOptions = isConfirmation
        ? null
        : {
            src: iconInformationSymbol,
            type: 'image'
        };
    const badgeBuildingBlocks = BuildingBlockLayouts.createBaseBuildingBlocksForLayout(
        layoutType,
        guide.id,
        layoutOptions
    );
    const badgeDom = BuildingBlock.buildingBlocksToDom(badgeBuildingBlocks);
    const defaultBadge = {
        bbJson: badgeBuildingBlocks,
        color: '#000000',
        domJson: badgeDom,
        isOnlyShowOnce: false,
        position: 'inline',
        showBadgeOnlyOnElementHover: false,
        showGuideOnBadgeHover: false,
        isConfirmation: false
    };

    const badgeProperties = {
        isMultiStep: false,
        launchMethod: 'badge',
        attributes: {
            badge: { ...defaultBadge, ...badgeConfig }
        }
    };

    return merge(guide, badgeProperties);
}

export async function createEmptyGuide ({
    appId,
    isTraining,
    name,
    priority,
    editorType,
    badge,
    confirmation = false,
    isMultiApp,
    platform,
    type,
    isAppFlutterCodeless,
    isMobile
} = {}) {
    const id = await makeid();
    if (!name) {
        name = `Unnamed ${moment().format('M/D [@] h:mm A')}`;
    }

    const guide = {
        appId,
        id,
        isTraining,
        name,
        state: 'draft',
        launchMethod: 'auto',
        isMultiApp,
        audienceUiHint: {
            filters: []
        },
        editorType: editorType || EDITOR_TYPES.ADOPT_UI,
        attributes: {
            type: 'building-block',
            device: {
                type: 'desktop'
            },
            sharedServiceVersion,
            priority,
            overrideAutoThrottling: true
        },
        steps: []
    };

    if (badge) {
        const badgeConfig = {};
        if (confirmation) {
            badgeConfig.isConfirmation = true;
            badgeConfig.position = 'over';
            badgeConfig.showGuideOnBadgeHover = false;
        }

        return addBadgeToGuide(guide, badgeConfig);
    }

    if (isMobile) {
        updateMobileGuideVersion({ guide, platform, isAppFlutterCodeless });
        const eventData = {
            guideId: guide.id,
            type
        };
        window.pendo.track('mobile_guide_created', eventData);
    }

    return guide;
}

export function updateMobileGuideVersion ({ guide, platform, isAppFlutterCodeless }) {
    guide.minSdkVersion = getMinSdkVersionForGuide(
        {
            ...guide,
            steps: guide.steps.map(({ buildingBlocks }) => ({ buildingBlocks: JSON.parse(buildingBlocks) }))
        },
        undefined,
        platform,
        undefined,
        { isAppFlutterCodeless }
    );
}

export function copyFromLayout (buildingBlocks, containerId) {
    const parsedBuildingBlocks = isString(buildingBlocks) ? JSON.parse(buildingBlocks) : buildingBlocks;
    const ids = StepCopy.getAllIds(parsedBuildingBlocks, ['CONTAINER_ID_HERE', 'giveMeTheGuideId']);
    const existingContainerId = find(ids.domIds, (id) => /^pendo-g-/.test(id)) || 'CONTAINER_ID_HERE';
    const guideContainerId = `pendo-g-${containerId}`;
    ids.domIds = filter(ids.domIds, (id) => !/^pendo-g-/.test(id));
    const copy = StepCopy.copyBuildingBlocks(
        parsedBuildingBlocks,
        ids,
        {
            [existingContainerId]: guideContainerId
        },
        false
    );
    const dom = JSON.stringify(BuildingBlock.buildingBlocksToDom(JSON.parse(copy.buildingBlocks)));

    return {
        buildingBlocks: copy.buildingBlocks,
        dom
    };
}

export async function createStep ({ guideId, carouselId, buildingBlocks, rank, appId, themeId }) {
    const [containerUsesStepId, stepId] = await Promise.all([
        meetsMinimumAgentVersion('2.15.11', appId), //
        makeid()
    ]);
    const containerId = containerUsesStepId ? stepId : guideId;
    const attributes = {
        css: ''
    };
    if (themeId) attributes.themeId = themeId;

    return {
        id: stepId,
        type: '',
        guideId,
        rank,
        advanceMethod: 'button',
        attributes,
        carouselId,
        ...copyFromLayout(buildingBlocks, containerId)
    };
}

export function makeid () {
    return http.get('/api/s/_SID_/object/makeid').then((res) => res.data.id);
}

export function getSegmentsUsingGuide (guideId, segmentsList) {
    if (!guideId) {
        return [];
    }

    return segmentsList.reduce((list, segment) => {
        const { filters } = segment.definition;
        const usedInSegment = filters.some((filter) => {
            if (filter.or) {
                return filter.or.some((f) => f.guideId === guideId); // eslint-disable-line id-length
            }

            return filter.guideId === guideId;
        });

        if (usedInSegment) {
            list.push(segment);
        }

        return list;
    }, []);
}

export function getGuidesUsingSegment (segmentId, guidesList) {
    if (!segmentId) {
        return [];
    }

    return guidesList.reduce((list, guide) => {
        if (!guide || !guide.audienceUiHint) return list;

        const { filters } = guide.audienceUiHint;
        const isUsingSegment = filters.some((filter) => filter.segmentId === segmentId);

        if (isUsingSegment) {
            list.push(guide);
        }

        return list;
    }, []);
}

export function getBuildingBlockGuideSignature (guide) {
    return guide.steps.map((step) => getBuildingBlockStepSignature(step));
}

export function getBuildingBlockStepSignature (step) {
    return filter(
        [
            ['content', getHashFromContentUrl(step.contentUrl)],
            ['contentCss', getHashFromContentUrl(step.contentUrlCss)],
            ['contentJs', getHashFromContentUrl(step.contentUrlJs)],
            ['dom', getHashFromContentUrl(step.domUrl)],
            ['domJsonp', getHashFromContentUrl(step.domJsonpUrl)]
        ],
        (tuple) => tuple[1]
    );
}

export function getHashFromContentUrl (url) {
    if (!isString(url)) {
        return '';
    }

    const filename = last(url.split('/'));

    return first(filename.split('.'));
}

export function findModuleGuideListBuildingBlock (buildingBlocks) {
    const { findBlock } = BuildingBlock;
    const { widgetIds } = BuildingBlockLayouts;

    return findBlock(buildingBlocks, (block) => {
        return block.widget === widgetIds.unorderedListItem && block.templateChildren;
    });
}

export function buildSegmentFromGuide (guide, subscriptionSegments, defaultSegments) {
    const ruleCount = get(guide, 'audienceUiHint.filters.length');
    const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId');
    const customSegment = {
        filters: [],
        pipeline: []
    };

    if (segmentId) {
        return find(subscriptionSegments.concat(defaultSegments), { id: segmentId });
    }

    if (ruleCount) {
        customSegment.filters = get(guide, 'audienceUiHint.filters', []);
        customSegment.pipeline = guide.audience;
        customSegment.name = 'Custom Segment';

        return customSegment;
    }

    return find(defaultSegments, { id: 'everyone' });
}

export function launchMethodToString (launchMethod) {
    if (!launchMethod) return '';

    const launchMethodNames = launchMethod.split('-').map((method) => {
        if (method === 'api') {
            return 'Programmatically';
        }
        if (method === 'auto') {
            return 'Automatically';
        }
        if (method === 'badge') {
            return 'Badge';
        }
        if (method === 'launcher') {
            return 'Resource Center';
        }
        if (method === 'dom') {
            return 'Element Click';
        }
        if (method === 'appLaunch') {
            return 'App Launch';
        }
        if (method === 'feature') {
            return 'Element Click';
        }
        if (method === 'page') {
            return 'Page View';
        }

        return '';
    });

    return launchMethodNames.join(', ');
}

export function getStepName (stepId, guide) {
    const step = guide.steps.find((step) => step.id === stepId);

    return get(step, 'name');
}

export function getStepNumber (stepId, guide) {
    return guide.steps.findIndex((step) => step.id === stepId) + 1;
}

export function getStepDisplayName (stepId, guide) {
    const stepNumber = getStepNumber(stepId, guide);
    const stepName = getStepName(stepId, guide);

    if (stepName) {
        return `Step ${stepNumber} - ${stepName}`;
    }

    return `Step ${stepNumber}`;
}

export function getGuideContent (guide, options = {}) {
    const { fetchDomJson, fetchBuildingBlocks } = options;

    const promises = guide.steps.map((step) => {
        return Promise.all([
            fetchBuildingBlocks && http.get(step.buildingBlocksUrl, { withCredentials: false }),
            step.contentUrl && http.get(step.contentUrl),
            step.contentUrlJs && http.get(step.contentUrlJs),
            step.contentUrlCss && http.get(step.contentUrlCss),
            fetchDomJson && http.get(step.domUrl)
        ]);
    });

    return Promise.all(promises);
}

export async function fetchAndMigrateGuide (guideId, options = {}) {
    const guideDetailsResponse = await http.get(`/api/s/_SID_/guide/${guideId}`);
    const guide = guideDetailsResponse.data;
    options.fetchBuildingBlocks = true;
    const guideContentPromises = await getGuideContent(guide, options);
    const { platform } = options;

    guideContentPromises.forEach((response, index) => {
        const [buildingBlocksResponse, content, contentJs, contentCss, domJsonResponse] = response;
        const buildingBlocks = buildingBlocksResponse && buildingBlocksResponse.data;
        const domJson = domJsonResponse && domJsonResponse.data;
        // Heals old guides without code blocks that were created with the incorrect container id
        // (i.e. pendo-g-${guideId} instead of pendo-g-${stepId})
        if (!content && !contentJs && !contentCss) {
            const containerBlock = BuildingBlock.findBlockByDomId(buildingBlocks, 'pendo-g-');
            const containerId = get(containerBlock, 'web.domId', '');

            if (containerId.includes(guide.steps[index].guideId)) {
                const containerBlockCopy = cloneDeep(containerBlock);
                containerBlockCopy.web.domId = `pendo-g-${guide.steps[index].id}`;
                BuildingBlockEditing.findAndReplaceBuildingBlock(buildingBlocks, containerBlockCopy);
            }
        }

        Object.assign(guide.steps[index], {
            buildingBlocks,
            domJson,
            content: get(content, 'data'),
            contentJs: get(contentJs, 'data'),
            contentCss: get(contentCss, 'data')
        });
    });
    BuildingBlockMigrations.migrate(guide);
    BuildingBlockMigrations.migrate(guide, 'guidesUnversioned');

    if (platform) {
        guide.steps.forEach((step) => {
            step.buildingBlocks = createBuildingBlocksProperties({ buildingBlocks: step.buildingBlocks, platform });
        });
    }
    guide.attributes.sharedServiceVersion = sharedServiceVersion;

    return guide;
}

export function getHistory (guideId, params) {
    return http.get(`/api/s/_SID_/guide/${guideId}/history`, { params }).then((res) => res.data);
}

export function getDeviceLabel (value) {
    switch (value) {
        case 'desktop':
        case 'Desktop':
            return 'Desktop & tablets only';
        case 'Mobile':
        case 'mobile':
            return 'Mobile only';
        default:
            return 'All devices';
    }
}
