<template>
    <svg
        class="nav-icon"
        xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="size"
        viewBox="0 0 24 24"
        :stroke="stroke"
        :style="{ color: stroke }"
        :fill="filled ? stroke : 'none'">
        <g
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2">
            <path
                :fill="filled ? '#2a2c35' : 'none'"
                d="M12 2 2 12h10v10l10-10V2H12Z">
                <animate
                    v-if="animation"
                    attributeType="XML"
                    attributeName="fill"
                    values="#FF4876;#f4f4f7;#f4f4f7;#FF4876"
                    dur="2s"
                    repeatCount="indefinite" />
            </path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'PendoToolsIcon',
    props: {
        filled: {
            type: Boolean,
            default: false
        },
        animation: {
            type: Boolean,
            default: false
        },
        size: {
            type: [Number, String],
            default: 20
        },
        stroke: {
            type: String,
            default: 'currentColor'
        }
    }
};
</script>
