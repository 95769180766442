/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    identifiedState,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/utils/aggregations';

export function getFeatureList ({ appId }) {
    const spec = buildFeatureListSpec({ appId });

    return request(spec);
}

export function buildFeatureListSpec ({ appId }) {
    const { operators: o, generators: g } = Agg2;

    return o.aggregation(
        {
            name: 'feature-list',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.FEATURE_LIST_DETAILS
        },
        o.pipeline(
            o.sources.features({ appId }),
            o.evalExpression({ featureId: 'id' }),
            g.appColumn({ primaryKeys: ['appId'] })
        )
    );
}

export function getFeatureActivity ({ appId, segmentId, timeSeries, signal }) {
    const spec = buildFeatureActivitySpec({ appId, timeSeries, segmentId });

    return request(spec, { signal });
}

export function buildFeatureActivitySpec (listParams) {
    const { timeSeries, appId, segmentId } = listParams;
    const { operators: o, common: c } = Agg2;

    return o.aggregation(
        {
            name: 'feature-activity',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.FEATURE_LIST_DETAILS
        },
        o.pipeline(
            buildFeatureListSpec({ appId }).pipeline,
            o.merge(
                ['featureId'],
                o.mappings({
                    numVisitors: 'numVisitors',
                    numEvents: 'numEvents'
                }),
                o.pipeline(
                    o.spawn(
                        o.pipeline(
                            o.sources.featureEvents({
                                timeSeries,
                                appId
                            }),
                            o.identified(identifiedState(segmentId)),
                            o.segment(parseSegmentIdForAggregation(segmentId)),
                            o.group(
                                'featureId',
                                ...c.groupFields({
                                    numVisitors: o.count('visitorId'),
                                    numEvents: o.sum('numEvents')
                                })
                            )
                        )
                    ),
                    o.join('featureId')
                )
            ),
            o.evalExpression({
                numVisitors: 'if(isNil(numVisitors), 0, numVisitors)',
                numEvents: 'if(isNil(numEvents), 0, numEvents)'
            })
        )
    );
}
