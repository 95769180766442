<template>
    <pendo-card>
        <div class="no-journeys-found-empty-state--container">
            <img
                alt="no-journeys-found"
                class="no-journeys-found-empty-state--image"
                src="@/img/empty-states/workflows-list-empty-state.svg">

            <h3 class="no-journeys-found-empty-state--title">
                <template v-if="savedJourneyHasTooManySelectedPagesFeatures">
                    There are too many Pages and Features selected
                </template>
                <template v-else-if="noCompletions">
                    No workflow completions
                </template>
                <template v-else>
                    No journeys with all must include steps
                </template>
            </h3>

            <p class="no-journeys-found-empty-state--body">
                <template v-if="savedJourneyHasTooManySelectedPagesFeatures">
                    <div>
                        To view journeys, please select 500 or fewer Pages and Features. If you need to select more
                        events, consider selecting apps instead of individual Pages and Features, which automatically
                        includes all tagged Pages and Features within each app.
                    </div>

                    <pendo-button
                        theme="app"
                        label="Edit Pages and Features"
                        @click="$emit('showSelectPagesFeaturesBlade')" />
                </template>
                <template v-else-if="noCompletions">
                    No journeys exist because there are no completions for this workflow. Consider updating the workflow
                    query and verifying that the starting and ending events don’t have overlapping rules.
                </template>
                <template v-else>
                    Try removing must include steps to view journeys.
                </template>
            </p>
        </div>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoButton } from '@pendo/components';

export default {
    name: 'NoJourneysFoundEmptyState',
    components: {
        PendoCard,
        PendoButton
    },
    props: {
        noCompletions: {
            type: Boolean,
            required: true
        },
        savedJourneyHasTooManySelectedPagesFeatures: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.no-journeys-found-empty-state {
    &--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 0px;
    }

    &--image {
        margin: 0 25px;
        width: 250px;
    }

    &--title {
        font-size: 22px;
        font-weight: 600;
        line-height: 130%;
        margin: 24px 0 12px;
    }

    &--body {
        font-size: 16px;
        line-height: 25px;
        margin: 5px;
        max-width: 580px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
}
</style>
