<template>
    <page-header
        title="Workflows"
        class="workflows-list-header"
        data-cy="workflows-list-header">
        <template #actions>
            <pendo-button
                v-if="!isReadOnly"
                data-cy="workflows-list-new-button"
                icon="plus"
                label="Create New Workflow"
                theme="app"
                type="primary"
                @click="onNewClicked" />

            <pendo-button
                v-if="isReadOnly"
                data-cy="workflows-list-run-button"
                label="Run a Workflow"
                theme="app"
                type="primary"
                @click="onNewClicked" />
        </template>
    </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { PendoButton } from '@pendo/components';
import { mapGetters } from 'vuex';

export default {
    name: 'WorkflowsListHeader',
    components: {
        PageHeader,
        PendoButton
    },
    computed: {
        ...mapGetters({
            isReadOnly: 'auth/isReadOnly'
        })
    },
    methods: {
        onNewClicked () {
            this.$router.push({ name: 'workflowsDetailsHome', params: { id: 'new' } }).catch(() => {});
        }
    }
};
</script>
