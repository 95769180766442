<template>
    <pendo-card
        class="guide_details_card"
        :collapsible="true"
        body-min-height="0"
        title="Guide details">
        <template #body>
            <div
                v-pendo-loading:material="loadingConfig"
                :class="`guide_details_card_container ${loading ? 'loading' : ''}`">
                <differences-display
                    key="guide_details_card"
                    :selected-revision-subtitle="selectedRevisionSubtitle"
                    :previous-revision-subtitle="previousRevisionSubtitle"
                    :show-previous-revision="showPreviousRevision"
                    :differences="revisionsData" />
            </div>
        </template>
    </pendo-card>
</template>

<script>
import isEqual from 'lodash/isEqual';
import DifferencesDisplay from '@/stateless-components/guides/audit/differences-display/DifferencesDisplay';
import { getSegmentName } from '@/utils/segments';
import { PendoCard, PendoLoading } from '@pendo/components';
import { GuideLocalization } from '@pendo/services/BuildingBlocks';
import {
    GUIDE_AUDIT_PROPS_TO_CHECK,
    getPropValue,
    formatSelectedLocalizations,
    formatRedisplay,
    formatFrequency,
    formatMobileDisplay
} from './guide-audit-utils';

export default {
    name: 'GuideAuditDetails',
    components: {
        PendoCard,
        DifferencesDisplay
    },
    directives: {
        PendoLoading
    },
    props: {
        selectedRevision: {
            type: Object,
            default: () => ({})
        },
        previousRevision: {
            type: Object,
            default: () => ({})
        },
        segments: {
            type: Object,
            default: () => ({})
        },
        isMobileApp: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        previousRevisionSubtitle: {
            type: String,
            default: ''
        },
        selectedRevisionSubtitle: {
            type: String,
            default: ''
        },
        showPreviousRevision: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            propsToReview: Object.keys(GUIDE_AUDIT_PROPS_TO_CHECK),
            revisionsData: [],
            STATE_HEADER: this.getPropHeader(GUIDE_AUDIT_PROPS_TO_CHECK.STATE),
            LOCALIZATION_HEADER: this.getPropHeader(GUIDE_AUDIT_PROPS_TO_CHECK.LOCALIZATION),
            EMAIL_CONFIG_HEADER: this.getPropHeader(GUIDE_AUDIT_PROPS_TO_CHECK.EMAIL_CONFIG),
            languageMapping: GuideLocalization.getAllLanguagesMapping()
        };
    },
    computed: {
        loadingConfig () {
            return {
                loading: this.loading,
                spinnerProps: {
                    color: '#3a3c45',
                    background: '#FFFFFF',
                    size: 32
                }
            };
        }
    },
    watch: {
        selectedRevision () {
            this.parseRevisions();
        }
    },
    methods: {
        getSegmentName (segmentId) {
            return getSegmentName(segmentId, this.segments) || 'Everyone';
        },
        parseRevisions () {
            const values = Object.keys(GUIDE_AUDIT_PROPS_TO_CHECK).reduce((revisedData, key) => {
                const prop = GUIDE_AUDIT_PROPS_TO_CHECK[key];
                const label = this.getPropHeader(prop);
                const values = this.getPropValue(prop);
                const { selected, previous } = values;

                let selectedToCompare = selected;
                let previousToCompare = previous;
                if (prop === GUIDE_AUDIT_PROPS_TO_CHECK.STATE) {
                    selectedToCompare = selected.state;
                    previousToCompare = previous.state;
                }

                if (isEqual(selectedToCompare, previousToCompare)) return revisedData;

                revisedData.push({
                    previous,
                    selected,
                    label,
                    key
                });

                return revisedData;
            }, []);

            this.revisionsData = values;
        },
        getPropValueFromRevision (revision, prop) {
            const { isMobileApp, activeTimezone, languageMapping } = this;

            return getPropValue(revision, prop, { isMobileApp, activeTimezone, languageMapping });
        },
        getPropHeader (prop) {
            switch (prop) {
                case GUIDE_AUDIT_PROPS_TO_CHECK.NAME:
                    return 'Guide name';
                case GUIDE_AUDIT_PROPS_TO_CHECK.LAUNCH_METHOD:
                    return 'Activation';
                case GUIDE_AUDIT_PROPS_TO_CHECK.REDISPLAY:
                    return 'Redisplay';
                case GUIDE_AUDIT_PROPS_TO_CHECK.DELIVERY_FREQUENCY:
                    return 'Delivery frequency';
                case GUIDE_AUDIT_PROPS_TO_CHECK.AUDIENCE:
                    return 'Segment';
                case GUIDE_AUDIT_PROPS_TO_CHECK.LOCALIZATION:
                    return 'Localization';
                case GUIDE_AUDIT_PROPS_TO_CHECK.SHOWS_AFTER:
                case GUIDE_AUDIT_PROPS_TO_CHECK.EXPIRES_AFTER:
                    return 'Scheduling';
                case GUIDE_AUDIT_PROPS_TO_CHECK.STATE:
                    return 'Status';
                case GUIDE_AUDIT_PROPS_TO_CHECK.DESCRIPTION:
                    return 'Description';
                case GUIDE_AUDIT_PROPS_TO_CHECK.DEVICE_TYPE:
                    return 'Show guide on';
                case GUIDE_AUDIT_PROPS_TO_CHECK.OVERRIDE_AUTO_THROTTLING:
                    return 'Throttle';
                case GUIDE_AUDIT_PROPS_TO_CHECK.EMAIL_CONFIG:
                    return 'Email configuration';
                case GUIDE_AUDIT_PROPS_TO_CHECK.MOBILE_DISPLAY:
                    return 'Mobile display settings';
                case GUIDE_AUDIT_PROPS_TO_CHECK.SELECTOR:
                    return 'Selector';
                case GUIDE_AUDIT_PROPS_TO_CHECK.POSITION_IN_ELEMENT:
                    return 'Position in element';
            }
        },
        getPropValue (prop) {
            let selected = this.getPropValueFromRevision(this.selectedRevision, prop);
            let previous = this.getPropValueFromRevision(this.previousRevision, prop);

            switch (prop) {
                case GUIDE_AUDIT_PROPS_TO_CHECK.AUDIENCE:
                    previous = this.getSegmentName(previous);
                    selected = this.getSegmentName(selected);
                    break;
                case GUIDE_AUDIT_PROPS_TO_CHECK.LOCALIZATION:
                    selected = formatSelectedLocalizations(previous, selected);
                    previous = { all: previous };
                    break;
                case GUIDE_AUDIT_PROPS_TO_CHECK.REDISPLAY:
                    [previous, selected] = formatRedisplay(previous, selected);
                    break;
                case GUIDE_AUDIT_PROPS_TO_CHECK.DELIVERY_FREQUENCY:
                    [previous, selected] = formatFrequency(previous, selected);
                    break;
                case GUIDE_AUDIT_PROPS_TO_CHECK.MOBILE_DISPLAY:
                    [previous, selected] = formatMobileDisplay(previous, selected);
                    break;
            }

            return {
                previous,
                selected
            };
        }
    }
};
</script>
<style scoped lang="scss">
.email-config-section > * {
    margin-bottom: 5px;
}

.guide_details_card {
    flex: 3 1;

    .guide_details_card_container {
        display: flex;
        justify-content: center;
        padding: 16px 0px;
    }
}
</style>
