import keyBy from 'lodash/keyBy';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';

// eslint-disable-next-line camelcase
export const _PENDO_DEFLYT_MobileCarouselSecondStep = '_PENDO_DEFLYT_MobileCarouselSecondStep';

// eslint-disable-next-line camelcase
export const _PENDO_DEFLYT_MobileCarouselThirdStep = '_PENDO_DEFLYT_MobileCarouselThirdStep';

export default function getDefaultLayouts (theme, selectedAppPlatform, platformType) {
    const layoutsToKeep = keyBy([
        'Lightbox: Start From Scratch',
        'Banner: Start From Scratch',
        'Tooltip: Start From Scratch'
    ]);

    const defaultLayouts = BuildingBlockLayouts.PrebuiltLayouts.DefaultLayouts(theme, {
        platform: selectedAppPlatform
    });

    return defaultLayouts.filter((layout) => layoutsToKeep[layout.name] && layout.supportedPlatforms[platformType]);
}
