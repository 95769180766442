export function applyXliffTags (text) {
    if (text.includes('<%=')) {
        text = text.replace(/<%= /gm, '<ph equiv-text="');
        text = text.replace(/ %>/gm, '"/>');

        return text;
    }

    return text;
}

export function applyTemplateTags (text) {
    if (text.includes('<ph')) {
        text = text.replace(/<ph equiv-text="/gm, '<%= ');
        text = text.replace(/"\s*\/>/gm, ' %>');

        return text;
    }

    return text;
}
