<template>
    <div class="browser-ingest-row">
        <div class="settings-row">
            <div class="cell checkbox-cell">
                <pendo-checkbox
                    :value="browserIngestEnabled"
                    :disabled="savingSubscriptionSettings"
                    @change="toggleBrowserIngest" />
            </div>
            <div class="cell">
                <div>Set visitor metadata through browser scripting</div>
                <small class="aside">
                    This setting requires you to implement custom JavaScript to identify visitors directly in the
                    browser.<br>
                    For more information, consult the
                    <a
                        href="https://support.pendo.io/hc/en-us/articles/22764466082715"
                        target="_blank">
                        documentation for browser scripting.
                    </a>
                </small>
            </div>
        </div>
        <div
            v-if="browserIngestEnabled"
            class="browser-ingest-settings">
            <div class="heading">
                Code snippet
            </div>
            <div class="snippet-header">
                Add the JavaScript code to the application that you're using to identify your visitors.
            </div>
            <pendo-copy-code-block
                language="javascript"
                :code-to-copy="formattedSnippet"
                :button-options="{ label: 'Copy code', position: 'top' }" />
            <pendo-divider
                stroke="#DADCE5"
                height="60px" />
            <div class="heading">
                Identification app URL
            </div>
            <div class="banner-explainer">
                After inserting the modified JavaScript code into your application, you can optionally configure the
                browser extension to automatically send visitors to that application. The URL you provide here is
                launched as a pop-up modal, which can then use the JavaScript code snippet to identify visitors.
            </div>
            <pendo-form
                v-model="isFormValid"
                :model="browserIngestForm">
                <pendo-form-item
                    :rules="browserIngestFormRules.authRedirectUrl"
                    prop="authRedirectUrl">
                    <pendo-input
                        v-model="browserIngestForm.authRedirectUrl"
                        placeholder="Where to send visitors when the modal is launched" />
                </pendo-form-item>
                <div class="toggle-row">
                    <div class="toggle-cell">
                        <strong>Show privacy awareness banner</strong>
                        <small class="aside">
                            Add a banner to the bottom of the pop-up modal to help inform visitors why they're seeing
                            it.
                        </small>
                    </div>
                    <div class="toggle-cell">
                        <pendo-form-item prop="shouldInjectBanner">
                            <pendo-toggle v-model="browserIngestForm.shouldInjectBanner" />
                        </pendo-form-item>
                    </div>
                </div>
                <div
                    v-if="browserIngestForm.shouldInjectBanner"
                    class="settings-block">
                    <div class="left-settings">
                        <div class="sub-row">
                            <pendo-form-item
                                label="Headline"
                                prop="preIdentifyTitleText">
                                <pendo-input
                                    v-model="browserIngestForm.preIdentifyTitleText"
                                    :disabled="!browserIngestForm.shouldInjectBanner"
                                    :placeholder="defaults.preIdentifyTitleText" />
                            </pendo-form-item>
                        </div>
                        <div class="sub-row">
                            <pendo-form-item
                                label="Body text"
                                prop="preIdentifySubtext">
                                <pendo-input
                                    v-model="browserIngestForm.preIdentifySubtext"
                                    :disabled="!browserIngestForm.shouldInjectBanner"
                                    :placeholder="defaults.preIdentifySubtext"
                                    type="textarea"
                                    :rows="4" />
                            </pendo-form-item>
                        </div>
                        <div class="sub-row">
                            <pendo-form-item
                                :rules="browserIngestFormRules.logoUrl"
                                label="Logo URL"
                                prop="logoUrl">
                                <pendo-input
                                    v-model="browserIngestForm.logoUrl"
                                    :disabled="!browserIngestForm.shouldInjectBanner" />
                            </pendo-form-item>
                        </div>
                        <div class="sub-row">
                            <pendo-form-item
                                :rules="browserIngestFormRules.backgroundColor"
                                label="Background color"
                                prop="backgroundColor">
                                <pendo-color-picker
                                    :value="bgColor"
                                    :disabled="!browserIngestForm.shouldInjectBanner"
                                    @change="updateBackgroundColor" />
                            </pendo-form-item>
                        </div>
                        <div class="sub-row">
                            <pendo-form-item
                                label="Learn more text"
                                prop="learnMoreText">
                                <pendo-input
                                    v-model="browserIngestForm.learnMoreText"
                                    :disabled="!browserIngestForm.shouldInjectBanner"
                                    :placeholder="defaults.learnMoreText" />
                            </pendo-form-item>
                        </div>
                        <div class="sub-row">
                            <pendo-form-item
                                :rules="browserIngestFormRules.learnMoreLink"
                                label="Learn more URL"
                                prop="learnMoreLink">
                                <pendo-input
                                    v-model="browserIngestForm.learnMoreLink"
                                    :disabled="!browserIngestForm.shouldInjectBanner" />
                            </pendo-form-item>
                        </div>
                    </div>
                    <div class="right-preview">
                        <div class="heading">
                            Banner preview
                        </div>
                        <div class="fake-browser-window">
                            <div class="fake-top-bar" />
                            <div class="fake-content">
                                Your authentication page
                            </div>
                            <div
                                v-if="browserIngestForm.shouldInjectBanner"
                                class="pendo-extension-browser-ingest-banner"
                                :style="{ 'background-color': bgColor }">
                                <div
                                    v-if="browserIngestForm.logoUrl"
                                    class="pendo-extension-browser-ingest-banner__img-container">
                                    <img
                                        :src="browserIngestForm.logoUrl"
                                        alt="Preview of the banner's logo"
                                        class="pendo-extension-browser-ingest-banner__logo-img">
                                </div>
                                <div class="pendo-extension-browser-ingest-banner__text-container">
                                    <div class="pendo-extension-browser-ingest-banner__title">
                                        {{ titleText }}
                                    </div>
                                    <div class="pendo-extension-browser-ingest-banner__subtext">
                                        {{ subText }}
                                    </div>
                                    <div
                                        v-if="browserIngestForm.learnMoreLink"
                                        class="pendo-extension-browser-ingest-banner__learn-more">
                                        <sup>* </sup>
                                        <a
                                            :href="browserIngestForm.learnMoreLink"
                                            target="_blank"
                                            class="pendo-extension-browser-ingest-banner__learn-more-link">
                                            {{ learnText }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-bar">
                    <pendo-button
                        class="update-browser-ingest-settings"
                        theme="app"
                        type="primary"
                        :loading="savingSubscriptionSettings"
                        :disabled="!canSaveBrowserIngestSettings"
                        label="Save configuration"
                        @click="saveBrowserIngestSettings" />
                </div>
            </pendo-form>
        </div>
    </div>
</template>
<script>
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import {
    PendoInput,
    PendoColorPicker,
    PendoToggle,
    PendoCheckbox,
    PendoDivider,
    PendoButton,
    PendoForm,
    PendoFormItem,
    PendoCopyCodeBlock
} from '@pendo/components';
import { pendoIdentifySnippet } from './BrowserIngestSettings.util';

const browserIngestFormStructure = {
    authRedirectUrl: '',
    shouldInjectBanner: false,
    preIdentifyTitleText: '',
    preIdentifySubtext: '',
    logoUrl: '',
    backgroundColor: '',
    learnMoreLink: '',
    learnMoreText: ''
};

export default {
    name: 'BrowserIngestSettings',
    components: {
        PendoInput,
        PendoColorPicker,
        PendoToggle,
        PendoCheckbox,
        PendoDivider,
        PendoButton,
        PendoCopyCodeBlock,
        PendoForm,
        PendoFormItem
    },
    props: {
        subscription: {
            type: Object,
            required: true
        },
        savingSubscriptionSettings: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            formattedSnippet: pendoIdentifySnippet,
            browserIngestForm: { ...browserIngestFormStructure },
            defaults: {
                preIdentifyTitleText: 'Please log in',
                preIdentifySubtext:
                    'There are training and onboarding guides available for this application, but we need to know who you are first.',
                backgroundColor: '#FFFFFFFF',
                learnMoreText: 'Learn more about your privacy'
            },
            updatingBrowserIngest: false,
            saving: false,
            isFormValid: false,
            browserIngestFormRules: {
                authRedirectUrl: [
                    {
                        validator: this.validateUrl,
                        trigger: 'blur'
                    }
                ],
                logoUrl: [{ validator: this.validateUrl, trigger: 'blur' }],
                learnMoreLink: [{ validator: this.validateUrl, trigger: 'blur' }],
                backgroundColor: []
            }
        };
    },
    computed: {
        browserIngestEnabled () {
            return !!this.subscription.featureFlags.enableExtensionBrowserIngest;
        },
        existingBrowserIngestSettings () {
            const settings = get(this.subscription, 'extensionAttributes.browserIngestSettings', {});

            return {
                ...browserIngestFormStructure,
                ...settings
            };
        },
        haveBrowserIngestSettingsChanged () {
            return !isEqual(this.existingBrowserIngestSettings, this.browserIngestForm);
        },
        canSaveBrowserIngestSettings () {
            return this.isFormValid && this.haveBrowserIngestSettingsChanged;
        },
        titleText () {
            if (this.browserIngestForm.preIdentifyTitleText) return this.browserIngestForm.preIdentifyTitleText;

            return this.defaults.preIdentifyTitleText;
        },
        subText () {
            if (this.browserIngestForm.preIdentifySubtext) return this.browserIngestForm.preIdentifySubtext;

            return this.defaults.preIdentifySubtext;
        },
        learnText () {
            if (this.browserIngestForm.learnMoreText) return this.browserIngestForm.learnMoreText;

            return this.defaults.learnMoreText;
        },
        bgColor () {
            if (this.browserIngestForm.backgroundColor) return this.browserIngestForm.backgroundColor;

            return this.defaults.backgroundColor;
        }
    },
    created () {
        const browserIngestFormValues = pick(this.existingBrowserIngestSettings, [
            'authRedirectUrl',
            'shouldInjectBanner',
            'preIdentifyTitleText',
            'preIdentifySubtext',
            'logoUrl',
            'backgroundColor',
            'learnMoreLink',
            'learnMoreText'
        ]);

        this.browserIngestForm = browserIngestFormValues;
    },
    methods: {
        toggleBrowserIngest (isEnabled) {
            // `value` and `enabled` are duplicated to make it easy to use this emit across both adopt and engage
            this.$emit('updateSubscriptionFlag', {
                flag: 'enableExtensionBrowserIngest',
                enabled: isEnabled,
                value: isEnabled
            });
        },
        validateUrl (rule, value, callback) {
            if (!value) return callback();

            const validUrlRegex = /^https?:\/\//;
            const isValidUrl = validUrlRegex.test(value);
            if (isValidUrl) return callback();

            return callback(new Error('URL must start with http:// or https://'));
        },
        updateBackgroundColor ($event) {
            this.browserIngestForm.backgroundColor = $event.hex8;
        },
        saveBrowserIngestSettings () {
            this.$emit('update-extension-attributes', {
                extensionAttributes: { browserIngestSettings: this.browserIngestForm }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.settings-row {
    padding: 10px;
    display: flex;
}

.cell {
    margin: 8px;
}

.checkbox-cell {
    display: flex;
    align-items: center;
}

.aside {
    display: block;
    color: $gray-lighter-2;
    margin-top: 5px;
}

.pendo-copy-code-block {
    margin-top: -30px;
}

.browser-ingest-settings {
    background-color: $gray-lighter-8;
    padding: 24px 40px;

    .browser-ingest-snippet {
        margin-top: -25px;
    }

    .banner-explainer {
        line-height: 20px;
        margin-bottom: 20px;
    }

    .toggle-row {
        display: flex;
        padding: 10px 0;
    }

    .toggle-cell {
        flex: 1;
    }

    .pendo-input {
        width: 400px !important; // stylelint-disable-line declaration-no-important
    }

    .pendo-color-picker__trigger {
        width: 150px !important; // stylelint-disable-line declaration-no-important
    }

    .heading {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .settings-block {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        .left-settings {
            margin-right: 100px;
        }

        .right-preview {
            flex-grow: 1;
        }
    }

    .sub-row {
        width: 100%;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .fake-browser-window {
        border-radius: 8px;
        border: 1px solid $gray-lighter-5;
        height: 400px;
        overflow: hidden;
        position: relative;
        max-width: 1000px;

        .fake-top-bar {
            height: 24px;
            width: 100%;
            background-color: $gray-lighter-7;
        }

        .fake-content {
            background-color: white;
            display: flex;
            justify-content: center;
            font-weight: bold;
            font-size: 22px;
            width: 100%;
            height: 100%;
            padding-top: 100px;
            color: $gray-lighter-2;
        }
    }

    .button-bar {
        display: flex;
        justify-content: flex-start;

        .update-browser-ingest-settings {
            margin-top: 20px;
        }
    }
}

// These styles mirror the styles set in the browser extension
.pendo-extension-browser-ingest-banner {
    position: absolute;
    bottom: 0px;
    width: 100%;
    min-height: 100px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    text-align: left;
    color: $black;
    box-shadow: 0px -2px 10px -7px $black;

    &__img-container {
        margin-right: 20px;
        display: flex;
        align-items: center;
    }

    &__logo-img {
        width: 50px;
    }

    &__title {
        font-weight: bold;
        font-size: 16px;
    }

    &__subtext {
        font-size: 14px;
    }

    &__learn-more {
        font-size: 14px;
        margin-top: 20px;
    }
}
</style>
