import { http } from '@pendo/http';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import set from 'lodash/set';
import Vue from 'vue';

let fetchPromise;

function getInitialState () {
    fetchPromise = null;

    return {
        isFetching: false,
        isRemoving: false,
        isSaving: false,
        map: {},
        workflowsSettings: {},
        includedSteps: {},
        savedWorkflowId: null,
        workingWorkflow: {},
        insightsShowing: false
    };
}

const defaultWorkflowSettings = Object.freeze({
    dataSource: '',
    timePeriod: '',
    totalChartDataSource: '',
    guideMonitorOverTimeChart: '',
    timeToCompleteChartDataType: '',
    timeToCompleteChartTimePeriod: '',
    viewBy: {},
    visitorList: {
        order: [],
        sizes: {},
        sort: {},
        visible: {}
    }
});

export const state = getInitialState();

export const mutations = {
    deleteMapKey (state, { id }) {
        Vue.delete(state.map, id);
    },
    reset (state) {
        Object.assign(state, getInitialState());
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setMap (state, { map }) {
        Vue.set(state, 'map', map);
    },
    setMapAtKey (state, { id, workflow }) {
        Vue.set(state.map, id, workflow);
    },
    setRemoving (state, { isRemoving }) {
        state.isRemoving = isRemoving;
    },
    setSaving (state, { isSaving }) {
        state.isSaving = isSaving;
    },
    setWorkflowsSettings (state, { workflowsSettings }) {
        state.workflowsSettings = workflowsSettings;
    },
    setIncludedSteps (state, { updatedSteps }) {
        state.includedSteps = cloneDeep(updatedSteps);
    },
    setSavedWorkflowId (state, { id }) {
        state.savedWorkflowId = id;
    },
    setWorkingWorkflow (state, { workflow }) {
        state.workingWorkflow = workflow;
    },
    setWorkingWorkflowField (state, { field, value }) {
        // Vue.set is not reactive for nested fields, e.g. workingWorkflow.subscriptionSharing.permission
        const workingWorkflow = cloneDeep(state.workingWorkflow);
        set(workingWorkflow, field, value);
        state.workingWorkflow = workingWorkflow;
    },
    setLoadingMap (state, { key, value }) {
        state.isLoadingMap[key] = value;
    },
    showInsights (state) {
        state.insightsShowing = true;
    },
    hideInsights (state) {
        state.insightsShowing = false;
    },
    toggleInsights (state) {
        state.insightsShowing = !state.insightsShowing;
    }
};

export const actions = {
    async fetch ({ commit, dispatch, state, rootGetters }, { noCache = false } = {}) {
        if (state.isFetching) {
            await fetchPromise;

            return;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });

        fetchPromise = fetchAllWorkflowsForSubscription(rootGetters['auth/isSuper']);

        const [list] = await Promise.all([
            fetchPromise,
            dispatch('pages/fetch', { noCache: true }, { root: true }),
            dispatch('features/fetch', { noCache: true }, { root: true })
        ]);

        commit('setMap', { map: keyBy(list, 'id') });
        commit('setFetching', { isFetching: false });
    },
    async create ({ commit, rootGetters }, { workflow } = {}) {
        commit('setSaving', { isSaving: true });
        try {
            const newWorkflow = await createWorkflow(rootGetters['auth/isSuper'], mapPermission(workflow));
            commit('setMapAtKey', {
                id: newWorkflow.id,
                workflow: newWorkflow
            });
            commit('setSaving', { isSaving: false });

            return newWorkflow.id;
        } catch (error) {
            commit('setSaving', { isSaving: false });
            throw error;
        }
    },
    async update ({ commit, rootGetters, state }, { workflow, applyToAggregation }) {
        commit('setSaving', { isSaving: true });
        try {
            const updatedWorkflow = await updateWorkflow(
                rootGetters['auth/isSuper'],
                state.map[workflow.id],
                mapPermission(workflow),
                applyToAggregation
            );
            commit('setMapAtKey', {
                id: updatedWorkflow.id,
                workflow: updatedWorkflow
            });
            commit('setSaving', { isSaving: false });
        } catch (error) {
            commit('setSaving', { isSaving: false });
            throw error;
        }
    },
    async remove ({ commit, rootGetters }, { id }) {
        commit('setRemoving', { isRemoving: true });
        try {
            await deleteWorkflow(rootGetters['auth/isSuper'], id);
            commit('deleteMapKey', { id });
            commit('setRemoving', { isRemoving: false });
        } catch (error) {
            commit('setRemoving', { isRemoving: false });
            throw error;
        }
    },
    updateWorkflowSetting ({ commit, dispatch, state }, { workflowId, name, value }) {
        const workflowsSettings = cloneDeep(state.workflowsSettings);

        set(workflowsSettings, `${workflowId}.${name}`, value);

        dispatch(
            'userSettings/updateSubNamespaceSetting',
            {
                name: 'workflowsSettings',
                value: JSON.stringify(workflowsSettings)
            },
            { root: true }
        );

        commit('setWorkflowsSettings', { workflowsSettings });
    },
    toggleIncludedStep ({ commit, state }, step) {
        const updatedSteps = cloneDeep(state.includedSteps);
        if (step.stepId) {
            step.id = step.stepId;
            step.label = step.name;
            step.type = step.featureId ? 'featureId' : 'pageId';
        }

        if (updatedSteps[step.id]) {
            delete updatedSteps[step.id];
        } else {
            // Only save the fields we care about
            updatedSteps[step.id] = {
                id: step.id,
                label: step.name,
                type: step.type
            };
        }

        commit('setIncludedSteps', { updatedSteps });
    }
};

export const getters = {
    list: (state) => Object.values(state.map),
    workflowById: (state) => (id) => get(state.map, id, null),
    savedWorkflow: (state, getters) => getters.workflowById(state.savedWorkflowId),
    savedWorkflowSettings: (state) => {
        if (state.savedWorkflowId === 'new') return defaultWorkflowSettings;

        return get(state.workflowsSettings, state.savedWorkflowId, {});
    },
    isLoading: (state) => state.isSaving || state.isFetching
};

// Util functions
export async function createWorkflow (isSuper, workflow) {
    const url = isSuper ? '/api/super/ss/_SNAME_/workflow' : '/api/s/_SID_/workflow';
    const response = await http.post(url, workflow);

    try {
        await http.post(`${url}/${response.data.id}/apply`, {});
    } catch (error) {
        // Don't worry about errors from the apply endpoint. No need to bubble that up to the user.
    }

    return response.data;
}

export function deleteWorkflow (isSuper, id) {
    return http.delete(isSuper ? `/api/super/ss/_SNAME_/workflow/${id}` : `/api/s/_SID_/workflow/${id}`);
}

export function fetchAllWorkflowsForSubscription (isSuper) {
    return http.get(isSuper ? '/api/super/ss/_SNAME_/workflow' : '/api/s/_SID_/workflow').then((res) => res.data);
}

export function mapPermission (workflow) {
    const result = cloneDeep(workflow);
    result.permission = result.subscriptionSharing.permission;
    delete result.subscriptionSharing;

    return result;
}

export async function updateWorkflow (isSuper, savedWorkflow, updatedWorkflow, applyToAggregation) {
    const url = isSuper
        ? `/api/super/ss/_SNAME_/workflow/${updatedWorkflow.id}`
        : `/api/s/_SID_/workflow/${updatedWorkflow.id}`;
    const response = await http.put(url, updatedWorkflow);
    if (applyToAggregation === false) {
        return response.data;
    }
    try {
        // This only needs to be called when the agg params change
        await http.post(`${url}/apply`, {});
    } catch (error) {
        // Don't worry about errors from the apply endpoint. No need to bubble that up to the user.
    }

    return response.data;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
