import head from 'lodash/head';
import keys from 'lodash/keys';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { http } from '@pendo/http';
import { LOCALSTORAGE_KEYS } from '@/constants/localstorage-keys';
import store from '@/state/store';
import { fixedDateRanges, convertValueToDateRangeConfig } from '@/utils/time-series';
import { filterIsAllApps } from '@/utils/filters';

// normalize user settings so we have one place to worry about global vs namespaced settings
export function reduceUserSettingsForAppAndSub (appUniqueId, subId, userSettings) {
    const baseUserSettings = {
        applicationSettings: {}
    };

    return userSettings.reduceRight((settings, { name, value }) => {
        const [namespaceKey, valueKey] = name.split('--');

        if (!valueKey) {
            settings[namespaceKey] = value;

            return settings;
        }

        if (namespaceKey === appUniqueId || namespaceKey === subId) {
            settings[valueKey] = value;
        }

        const [namespaceSubId, namespaceAppId] = namespaceKey.split(':');

        if (namespaceSubId === subId && namespaceAppId) {
            settings.applicationSettings[namespaceAppId] = settings.applicationSettings[namespaceAppId] || {};

            settings.applicationSettings[namespaceAppId][valueKey] = value;
        }

        return settings;
    }, baseUserSettings);
}

export async function updateUserState (subid, accountid) {
    const activeSubscription = `${subid}`;
    const accountRestriction = `${accountid}`;

    await http.put(`/api/s/${activeSubscription}/user/_UID_/activeuserstate`, {
        activeSubscription,
        accountRestriction
    });
}

export function updateAdoptV2UserState (subid) {
    return http
        .put(`/api/user/_UID_/activeuserstate/${subid}`, {}, { headers: { 'x-adopt-v2': true } })
        .then((res) => res.data);
}

export function getAccountFromSubscriptionState (state, subid, accountid) {
    const { accounts: accountMap } = state.map[subid];
    let activeAccountId = accountid || localStorage.getItem(LOCALSTORAGE_KEYS.accountId);

    if (!has(accountMap, activeAccountId)) {
        activeAccountId = null;
    }
    if (!activeAccountId) {
        activeAccountId = head(keys(accountMap));
    }
    if (!activeAccountId) {
        throw new Error('could not determine account restriction');
    }

    return activeAccountId;
}

export function currentJsonWebTokenIds () {
    let subId = localStorage.getItem(LOCALSTORAGE_KEYS.subscriptionId);
    if (subId) subId = parseInt(subId);
    const appId = localStorage.getItem(LOCALSTORAGE_KEYS.appId);
    const accountId = localStorage.getItem(LOCALSTORAGE_KEYS.accountId);

    return { subId, appId, accountId };
}

export function parseDateRangeUserSetting ({ dateRange, numDaysBeforeLast = -30 } = {}, appFirstVisitTimestamp) {
    let range;
    try {
        range = JSON.parse(dateRange);
    } catch (err) {
        range = fixedDateRanges().find((range) => range.count === Number(numDaysBeforeLast * -1));
    }

    return convertValueToDateRangeConfig(range, appFirstVisitTimestamp);
}

export function parseAppIdsFilter (stringifiedAppIds) {
    let appIds;
    try {
        appIds = JSON.parse(stringifiedAppIds);
    } catch (err) {
        appIds = [];
    }

    if (isEmpty(appIds) || filterIsAllApps(appIds)) {
        appIds = [store.getters['filters/allAdoptAppsAggId']];
    }

    return appIds;
}

export function parseViewByMetadataField (metadata) {
    if (!metadata || !JSON.parse(metadata)) {
        return null;
    }

    return JSON.parse(metadata);
}

export function parseValue ({ value }) {
    try {
        return JSON.parse(value);
    } catch (err) {
        return value;
    }
}
