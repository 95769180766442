import { GuideLocalization } from '@pendo/services/BuildingBlocks';
import get from 'lodash/get';
import invert from 'lodash/invert';
import isUndefined from 'lodash/isUndefined';
import { http } from '@pendo/http';

const { languageMapping } = GuideLocalization;

export const GUIDE_TRANSLATION_COMPLETE = 'COMPLETE';

export const GUIDE_TRANSLATION_PARTIAL = 'PARTIAL';

export const GUIDE_TRANSLATION_UNTRANSLATED = 'UNTRANSLATED';

export const XLIFF_IMPORT_ERROR_MESSAGE = {
    10101: 'The translation file is an unsupported xliff version.',
    10102: 'The translation file contains an incorrect target language.  Please check that all target-language fields are set to the value: "%code"',
    10103: 'The translation file contains malformed XML.',
    10104: 'The translation file you are importing does not match the current guide or resource center.'
};

export function isTranslated (translationState) {
    return [GUIDE_TRANSLATION_COMPLETE, GUIDE_TRANSLATION_PARTIAL].includes(translationState);
}

export function exportGuideLocalizationFile (guideId) {
    if (!guideId) return Promise.reject(TypeError(`Expected guide ID, but received: ${guideId}`));

    return http.get(`/api/s/_SID_/guide/${guideId}/localization/export`);
}

export function exportResourceCenterLocalizationFile (resourceCenterId) {
    if (!resourceCenterId) {
        return Promise.reject(TypeError(`Expected resource center ID, but received: ${resourceCenterId}`));
    }

    return http.get(`/api/s/_SID_/resourcecenter/${resourceCenterId}/localization/export`);
}

export function importResourceCenterLocalizationFile (resourceCenterId, langCode, fileData) {
    if (!resourceCenterId) {
        return Promise.reject(TypeError(`Expected resource center ID, but received: ${resourceCenterId}`));
    }
    if (!langCode) return Promise.reject(TypeError(`Expected language code, but received: ${langCode}`));
    if (!(fileData instanceof File)) return Promise.reject(Error('fileData must be an instance of File'));

    return new Promise((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            const parser = new DOMParser();
            const fileData = parser.parseFromString(fileReader.result, 'application/xml').documentElement.outerHTML;
            const config = {
                headers: {
                    'Content-Type': 'application/xml; charset=UTF-8;'
                }
            };

            return resolve(
                http
                    .post(
                        `/api/s/_SID_/resourcecenter/${resourceCenterId}/localization/import/${langCode}`,
                        fileData,
                        config
                    )
                    .then((resp) => resp.data)
            );
        };

        fileReader.readAsText(fileData);
    });
}

export function importGuideLocalizationFile (guideId, langCode, fileData) {
    if (!guideId) return Promise.reject(TypeError(`Expected guide ID, but received: ${guideId}`));
    if (!langCode) return Promise.reject(TypeError(`Expected language code, but received: ${langCode}`));
    if (!(fileData instanceof File)) return Promise.reject(Error('fileData must be an instance of File'));

    return new Promise((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            const parser = new DOMParser();
            const fileData = parser.parseFromString(fileReader.result, 'application/xml').documentElement.outerHTML;
            const config = {
                headers: {
                    'Content-Type': 'application/xml; charset=UTF-8;'
                }
            };

            return resolve(
                http
                    .post(`/api/s/_SID_/guide/${guideId}/localization/import/${langCode}`, fileData, config)
                    .then((resp) => resp.data)
            );
        };

        fileReader.readAsText(fileData);
    });
}

export function fetchAppLocalizationData (appId) {
    return fetchLocalizationPublicObject(appId).then((resp) => resp.data);
}

export function formatEnabledLanguages (localizationPublicObject, includeUnmappedLanguages = false) {
    const { defaultLanguage, languageMappings: appEnabledLanguages } = localizationPublicObject;

    if (!appEnabledLanguages) return [];

    return Object.entries(appEnabledLanguages).reduce((formattedLanguages, [metadata, code]) => {
        const sharedServiceLanguageMap = get(languageMapping, `${code}`);
        if (!isUndefined(sharedServiceLanguageMap) || includeUnmappedLanguages) {
            formattedLanguages.push({
                code,
                // coercing to Bool since isDeprecated can be undefined
                isDeprecated: !!get(sharedServiceLanguageMap, 'isDeprecated'),
                name: get(sharedServiceLanguageMap, 'name'),
                nativeName: get(sharedServiceLanguageMap, 'nativeName'),
                isDefaultLanguage: code === defaultLanguage,
                metadataValue: metadata
            });
        }

        return formattedLanguages;
    }, []);
}

export function formatLanguages (appLocalizationData, guideTranslationStates) {
    return sortLanguagesByName(
        formatEnabledLanguages(appLocalizationData).map((language) => {
            language.translatedState = get(guideTranslationStates, `${language.code}.state`, null);

            return language;
        })
    );
}

export function sortLanguagesByName (languages, isDefaultLangFirst = false) {
    let sortedLanguages = [].concat(languages);
    sortedLanguages = sortedLanguages.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
    });

    if (!isDefaultLangFirst) return sortedLanguages;

    const defaultLang = sortedLanguages.find((lang) => lang.isDefaultLanguage);
    const defaultLangIndex = sortedLanguages.indexOf(defaultLang);
    sortedLanguages.splice(defaultLangIndex, 1);
    sortedLanguages.splice(0, 0, defaultLang);

    return sortedLanguages;
}

export function clearGuideTranslationStrings (guideId, langCode) {
    if (!guideId) return Promise.reject(TypeError(`Expected guide ID, but received: ${guideId}`));
    if (!langCode) return Promise.reject(TypeError(`Expected language code, but received: ${langCode}`));

    return deleteTranslatedStrings(guideId, langCode);
}

export function fetchLocalizationPublicObject (appId) {
    return http.get(`/api/s/_SID_/localization/${appId}`);
}

export function deleteTranslatedStrings (guideId, langCode) {
    return http.delete(`/api/s/_SID_/guide/${guideId}/localization/${langCode}`);
}

export function getTranslatedGuideSteps (guideId, languageCode) {
    return http
        .get(`/api/s/_SID_/guide/${guideId}/step`, { params: { lang: languageCode } })
        .then((translatedSteps) => translatedSteps.data);
}

export function clearTranslatedStrings (homeViewId, langCode) {
    return http
        .post(`/api/s/_SID_/resourcecenter/${homeViewId}/localization/clear/${langCode}`)
        .then((resp) => resp.data);
}

export function deleteLanguage (appId, languageCode) {
    return http.delete(`/api/s/_SID_/localization/${appId}/${languageCode}`);
}

export async function updateConfigProperty (appId, newConfig) {
    if (!appId) return Promise.reject(Error('Expected parameter {appId} but it was empty'));
    newConfig.id = `${appId}`;

    return http.put(`/api/s/_SID_/localization/${appId}`, newConfig).then((response) => response.data);
}

export async function updateLanguageMetadataValue (appId, { code, metadataValue }) {
    if (!appId) return Promise.reject(Error('Expected parameter {appId} but it was empty'));
    if (!code) return Promise.reject(Error('Expected parameter {code} but it was empty'));
    if (!metadataValue) return Promise.reject(Error('Expected parameter {metadataValue} but it was empty'));

    return fetchLocalizationPublicObject(appId).then(({ data: localizationConfig }) => {
        updateLanguageMappings(localizationConfig, code, metadataValue);
        localizationConfig.id = `${appId}`;

        return http.put(`/api/s/_SID_/localization/${appId}`, localizationConfig).then((response) => response.data);
    });
}

export function updateLanguageMappings (localizationConfig, code, metadataValue) {
    const codeAsKeyMap = invert(localizationConfig.languageMappings);
    codeAsKeyMap[code] = metadataValue;
    localizationConfig.languageMappings = invert(codeAsKeyMap);
}
