export const AdoptMapSvg = `<svg width="56" height="56" viewBox="0 0 56 56" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="28" fill="currentColor"></circle>
    <polygon points="17 22 17 38 24 34 32 38 39 33 39 18 32 22 24 18 17 22"></polygon>
    <line x1="24" y1="18" x2="24" y2="34"></line><line x1="32" y1="22" x2="32" y2="38"></line>
</svg>`;

export const info = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="black"/>
    <path d="M8.46973 8.60352V10.1553H10.5752V14.4551H8.46973V16H14.499V14.4551H12.4961V8.60352H8.46973ZM10.4385 6.70996C10.4385 6.85579 10.4635 6.99251 10.5137 7.12012C10.5684 7.24316 10.6436 7.34798 10.7393 7.43457C10.835 7.52572 10.9489 7.59635 11.0811 7.64648C11.2178 7.69661 11.3682 7.72168 11.5322 7.72168C11.8695 7.72168 12.1361 7.62826 12.332 7.44141C12.5326 7.25 12.6328 7.00618 12.6328 6.70996C12.6328 6.41374 12.5326 6.1722 12.332 5.98535C12.1361 5.79395 11.8695 5.69824 11.5322 5.69824C11.3682 5.69824 11.2178 5.72331 11.0811 5.77344C10.9489 5.82357 10.835 5.89193 10.7393 5.97852C10.6436 6.06966 10.5684 6.17904 10.5137 6.30664C10.4635 6.42969 10.4385 6.56413 10.4385 6.70996Z" fill="white"/>
</svg>`;

export const help = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="black"/>
    <path d="M9.47656 13.7422C9.48177 13.2318 9.53385 12.8203 9.63281 12.5078C9.73177 12.1953 9.90625 11.9115 10.1562 11.6562C10.4115 11.3958 10.763 11.099 11.2109 10.7656C11.5182 10.5365 11.776 10.2552 11.9844 9.92188C12.1927 9.58333 12.2969 9.22396 12.2969 8.84375C12.2969 8.45833 12.1875 8.15365 11.9688 7.92969C11.7552 7.70573 11.4557 7.59375 11.0703 7.59375C10.7526 7.59375 10.4792 7.68229 10.25 7.85938C10.0208 8.03646 9.90365 8.3099 9.89844 8.67969H7.21875L7.20312 8.63281C7.19271 7.94531 7.34896 7.36719 7.67188 6.89844C7.99479 6.42448 8.44792 6.06771 9.03125 5.82812C9.61458 5.58333 10.2891 5.46094 11.0547 5.46094C12.2995 5.46094 13.2734 5.76042 13.9766 6.35938C14.6849 6.95833 15.0391 7.76042 15.0391 8.76562C15.0391 9.45833 14.8359 10.0755 14.4297 10.6172C14.0234 11.1589 13.5417 11.6302 12.9844 12.0312C12.6719 12.2552 12.4635 12.4922 12.3594 12.7422C12.2604 12.9922 12.2109 13.3255 12.2109 13.7422H9.47656ZM9.47656 17V14.7656H12.1797V17H9.47656Z" fill="white"/>
</svg>`;

export const logo = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="black"/>
    <path d="M9.45513 12.5449H4.54649C4.06059 12.5449 3.81725 11.9574 4.16084 11.6138L9.61489 6.15975C9.66813 6.1065 9.72966 6.06655 9.79531 6.03993C9.8535 6.01526 9.92523 6 10.0005 6C10.1401 6 10.2797 6.05326 10.3862 6.15975C10.5992 6.37275 10.5992 6.71806 10.3862 6.93106L10.2264 7.09081H10.0005C9.69932 7.09081 9.45513 6.84662 9.45513 6.54541C9.45513 6.31949 9.59247 6.12566 9.78822 6.04286C9.86094 6.01331 9.93075 6 10.0005 6H15.4546C15.7558 6 16 6.24419 16 6.54541V11.9995C16 12.1441 15.9425 12.2828 15.8402 12.3851L10.3862 17.8392C10.0426 18.1827 9.45513 17.9394 9.45513 17.4535V12.5449Z" fill="white"/>
</svg>`;

export const star = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11" cy="11" r="11" fill="black"/>
<path d="M11 5L12.854 8.6204L17 9.20452L14 12.021L14.708 16L11 14.1204L7.292 16L8 12.021L5 9.20452L9.146 8.6204L11 5Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const navigationPrevArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" display="block">
    <polyline points="15 18 9 12 15 6"></polyline>
</svg>`;

export const navigationNextArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" display="block">
    <polyline points="9 18 15 12 9 6"></polyline>
</svg>`;
