<template>
    <div class="settings--user-settings">
        <pendo-card
            title="Information"
            class="settings--user-settings-information">
            <pendo-editable-content
                v-if="!_activeUsesV2Adopt"
                :value="email"
                :validation-rules="rules.email"
                :before-exit="updateEmail"
                label="Email">
                <template #append="{ confirm, cancel, pending }">
                    <pendo-button
                        :loading="pending"
                        theme="app"
                        icon="check"
                        type="primary"
                        @click="confirm" />
                    <pendo-button
                        theme="app"
                        type="tertiary"
                        label="Cancel"
                        @click="cancel" />
                </template>
            </pendo-editable-content>
            <div
                v-if="_activeUsesV2Adopt"
                class="pendo-editable-content">
                <div class="pendo-editable-content__label pendo-editable-content__label--top">
                    Email
                </div>
                <div>
                    {{ email }}
                </div>
            </div>
            <pendo-editable-content
                :value="firstname"
                :before-exit="updateFirstName"
                label="First Name">
                <template #append="{ confirm, cancel, pending }">
                    <pendo-button
                        :loading="pending"
                        theme="app"
                        icon="check"
                        type="primary"
                        @click="confirm" />
                    <pendo-button
                        theme="app"
                        type="tertiary"
                        label="Cancel"
                        @click="cancel" />
                </template>
            </pendo-editable-content>
            <pendo-editable-content
                :value="lastname"
                :before-exit="updateLastName"
                label="Last Name">
                <template #append="{ confirm, cancel, pending }">
                    <pendo-button
                        :loading="pending"
                        theme="app"
                        icon="check"
                        type="primary"
                        @click="confirm" />
                    <pendo-button
                        theme="app"
                        type="tertiary"
                        label="Cancel"
                        @click="cancel" />
                </template>
            </pendo-editable-content>
            <div class="pendo-editable-content">
                <div class="pendo-editable-content__label pendo-editable-content__label--top">
                    Password
                </div>
                <div
                    class="pendo-editable-content__text"
                    @click="openChangePasswordModal">
                    <span>Click to Change</span>
                </div>
            </div>
        </pendo-card>
        <pendo-card
            class="permission-card"
            title="Permission Levels"
            body-min-height="0"
            footer-min-height="0">
            <user-permission-levels
                :is-loading="isLoading"
                :user="originalUser"
                :apps="apps"
                :sub-id="activeSub.id" />
        </pendo-card>
        <pendo-user-preferences
            v-if="showUserPreferences"
            class="settings--user-settings-preferences"
            :user-preferences="userPreferences"
            @toggle-change="handlePreferenceToggle" />
    </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapState, mapGetters } from 'vuex';
import { PendoCard, PendoEditableContent, PendoButton, PendoUserPreferences } from '@pendo/components';
import { UserPermissionLevels } from '@pendo/user-management-ui';

export default {
    name: 'UserProfile',
    components: {
        PendoCard,
        PendoEditableContent,
        PendoButton,
        PendoUserPreferences,
        UserPermissionLevels
    },
    data () {
        return {
            updateSettingsError: 'Unable to update setting.',
            rules: {
                email: [
                    { type: 'string', trigger: ['blur', 'change'] },
                    { type: 'email', message: 'Please input a valid email address', trigger: ['blur'] }
                ]
            },
            originalUser: {},
            isLoading: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            hasUpdateSettingsError: (state) => state.auth.hasUpdateSettingsError,
            userPreferences: (state) => state.userSettings.userPreferences
        }),
        ...mapGetters({
            _activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            appsWithSuggestedPages: 'apps/appsWithSuggestedPages',
            activeSub: 'subscriptions/active',
            apps: 'apps/listAllForActiveSubscription',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            userById: 'users/userById',
            isAdmin: 'auth/isAdmin',
            isImpersonating: 'auth/isImpersonating'
        }),
        email () {
            return get(this.user, 'email', '');
        },
        firstname () {
            return get(this.user, 'firstname', 'Not Set');
        },
        lastname () {
            return get(this.user, 'lastname', 'Not Set');
        },
        showUserPreferences () {
            return !isEmpty(this.userPreferences);
        }
    },
    async created () {
        const userSettings = [this.fetchUserPrefs()];

        if (this.isAdmin || this.isImpersonating) {
            userSettings.push(this.fetchUserList());
        } else {
            userSettings.push(this.fetchUser({ id: this.user.id }));
        }

        this.isLoading = true;
        await Promise.all(userSettings);
        this.originalUser = this.userById(this.user.id);
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            _updateEmail: 'auth/updateEmail',
            _updateFirstName: 'auth/updateFirstName',
            _updateLastName: 'auth/updateLastName',
            updateCrossUINamespaceSetting: 'userSettings/updateCrossUINamespaceSetting',
            updateUserPreferences: 'userSettings/updateUserPreferences',
            fetchUserPrefs: 'userSettings/fetch',
            hydrateSuggestedPages: 'pages/hydrate',
            fetchUser: 'users/fetchUser',
            fetchUserList: 'users/fetchUserList'
        }),
        async updateEmail (value) {
            await this._updateEmail({ email: value });
            if (this.hasUpdateSettingsError) {
                return Promise.reject(this.updateSettingsError);
            }

            return Promise.resolve();
        },
        async updateFirstName (value) {
            await this._updateFirstName({ firstname: value });
            if (this.hasUpdateSettingsError) {
                return Promise.reject(this.updateSettingsError);
            }

            return Promise.resolve();
        },
        async updateLastName (value) {
            await this._updateLastName({ lastname: value });
            if (this.hasUpdateSettingsError) {
                return Promise.reject(this.updateSettingsError);
            }

            return Promise.resolve();
        },
        openChangePasswordModal () {
            // modal is closed from within SettingsChangePassword.vue
            this.$modal.show('via-modal', {
                title: 'Change Password',
                component: 'SettingsChangePasswordModal'
            });
        },
        async handlePreferenceToggle (event) {
            await this.updateCrossUINamespaceSetting({ name: 'userPreferences', value: event });
            this.updateUserPreferences({ userPreferences: event });
            this.hydrateSuggestedPages();
        }
    }
};
</script>

<style lang="scss">
.settings--user-settings {
    display: grid;
    grid-gap: 30px;

    .pendo-card__body {
        display: grid;
        grid-gap: 24px;
    }

    .permission-card {
        .pendo-card__body {
            padding: 0;

            .section-header {
                margin-top: 0px;
            }

            .scrollable-list {
                height: 475px;
            }
        }
    }

    .settings--user-settings-preferences {
        .pendo-card__body {
            display: flex;
            flex-direction: column;
            grid-gap: 0px;
        }
    }
}
</style>
