import kebabCase from 'lodash/kebabCase';

export function applyTheme (theme) {
    for (const property in theme) {
        const value = theme[property];
        const variable = kebabCase(property);

        // apply styles to document
        document.documentElement.style.setProperty(`--via-theme--${variable}`, value);
    }
}

export function getContrastingTextColor (hex) {
    if (hex.startsWith('#')) {
        hex = hex.slice(1, hex.length); // eslint-disable-line no-param-reassign
    }
    // Convert hex to rgb
    const red = parseInt(hex.substr(0, 2), 16);
    const green = parseInt(hex.substr(2, 2), 16);
    const blue = parseInt(hex.substr(4, 2), 16);

    // Get YIQ ratio
    const yiq = (red * 299 + green * 587 + blue * 114) / 1000;

    // Check contrast
    if (yiq >= 128) {
        // light background, use dark text
        return '#2A2C35';
    }

    // dark background, use light (white) text
    return '#FFFFFF';
}
