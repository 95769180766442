import Vue from 'vue';
import { initializeHttpWrapper } from '@/utils/http';
import { sync } from 'vuex-router-sync';
import VueJsModal from 'vue-js-modal/dist/ssr.nocss.js';
import ResizeObserver from 'resize-observer-polyfill';
import App from '@/App.vue';
import router from '@/router';
import store from '@/state/store';
import get from 'lodash/get';
import { setupPendoSnippet } from '@/state/plugins/pendo';
import { startDesignerBridge } from '@/state/designer-bridge';
import './registerServiceWorker';
import VueClipboard from 'vue-clipboard2';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import sanitizeHTML from 'sanitize-html';
import { http } from '@pendo/http';
import { initializeAggConnect } from '@pendo/agg-connect';
import { BuildingBlock } from '@pendo/services/BuildingBlocks';
import { origin, baseUrl, gcsBucket } from '@pendo/services/Environments';
import pendoGlobalsPlugin from './app-wide-dependency-plugin';
Vue.prototype.$sanitize = sanitizeHTML;

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

window.__via_info__ = {
    env: get(__PROCESS__, 'PENDO_ENV'),
    gitHash: get(__PROCESS__, 'hash'),
    isLocal: get(__PROCESS__, 'isLocal')
};

initializeHttpWrapper();
initializeAggConnect({ http, env: window.__via_info__.env === 'prod' ? 'production' : window.__via_info__.env });
window[Symbol.for('Aggregation')].enableNamesOnRequestUrls();
setupPendoSnippet();
startDesignerBridge();

sync(store, router);

store.dispatch('subscriptions/fetchUnauthenticatedTrainingAttributes');
store.dispatch('auth/init');

BuildingBlock.setDependencies(origin(), gcsBucket(), baseUrl());

Vue.config.productionTip = false;
Vue.use(VueJsModal);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(pendoGlobalsPlugin);

hljs.registerLanguage('javascript', javascript);

const app = new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');

if (window.Cypress) {
    window.__app__ = app;
    window.__pendoPermissions__ = require('@pendo/permissions');
}
