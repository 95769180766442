<template>
    <div class="analytics-dashboard">
        <global-filters />
        <div class="analytics-dashboard--grid">
            <template v-if="showNoFeaturesCTA || showNoPagesCTA">
                <div class="analytics-dashboard--row cta-row">
                    <call-to-action-card
                        :active-sub-has-any-features="activeSubHasAnyFeatures"
                        :active-sub-has-any-pages="activeSubHasAnyPages"
                        :apps-without-features="appsWithoutFeatures"
                        :apps-without-pages="appsWithoutPages"
                        :filtered-apps="filteredApps"
                        :is-app-ids-filter-in-use="isAppIdsFilterInUse"
                        @launchTagPagesModal="onLaunchTagPagesModal"
                        @launchTagFeaturesModal="onLaunchTagFeaturesModal" />
                </div>
            </template>
            <div class="analytics-dashboard--row">
                <top-pages
                    class="top-pages"
                    :is-loading="isFetchingPageList"
                    :show-app-usage="showAppUsage"
                    :top-pages="topPages"
                    :top-pages-list-user-settings="topPagesUserSettings"
                    :active-timezone="activeTimezone"
                    @routeToPages="onRouteToPages"
                    @tableSettingsUpdate="updateTableUserSettings" />
                <top-features
                    :is-loading="isFetchingFeatureList"
                    :show-app-usage="showAppUsage"
                    :top-features="topFeatures"
                    :active-timezone="activeTimezone"
                    :top-features-list-user-settings="topFeaturesUserSettings"
                    @routeToFeatures="onRouteToFeatures"
                    @tableSettingsUpdate="updateTableUserSettings" />
            </div>
            <div class="analytics-dashboard--row">
                <visitors-over-time
                    class="analytics-dashboard--visitors-over-time"
                    :active-subscription-utc-offset="activeSubscriptionUtcOffset"
                    :active-date-range="activeDateRange"
                    :active-segment="activeSegment"
                    :is-fetching="isFetchingVisitorsOverTime"
                    :visitors-over-time="visitorsOverTime" />
            </div>
            <div class="analytics-dashboard--row">
                <page-feature-usage-over-time
                    v-if="!updatingActiveApp"
                    :page-list="pageList"
                    :feature-list="featureList"
                    :is-app-ids-filter-in-use="isAppIdsFilterInUse"
                    :active-subscription-utc-offset="activeSubscriptionUtcOffset"
                    :active-segment-id="activeSegmentId"
                    :app-ids-filter="appIdsFilter"
                    :is-fetching-feature-list="isFetchingFeatureList"
                    :is-fetching-page-list="isFetchingPageList"
                    :active-date-range="activeDateRange"
                    :page-usage-chart="pageUsageChart"
                    :usage-over-time="usageOverTime"
                    :is-fetching-page-feature-usage-over-time="isFetchingPageFeatureUsageOverTime"
                    @updateSubNamespaceSetting="updateSubNamespaceSetting"
                    @setPageUsageChart="setPageUsageChart"
                    @configChange="getPageFeatureUsageOverTimeData" />
            </div>
            <div class="analytics-dashboard--row">
                <countable-use-by-visitor
                    class="page-use-by-visitor"
                    :all-apps="allApps"
                    countable-type="page"
                    :countable-list="pageListWithAnalytics"
                    :is-fetching-countable-list="isFetchingPageList"
                    :show-app-usage="showAppUsage"
                    :active-segment="activeSegment"
                    :active-date-range="activeDateRange" />
                <countable-use-by-visitor
                    class="feature-use-by-visitor"
                    :all-apps="allApps"
                    countable-type="feature"
                    :countable-list="featureListWithAnalytics"
                    :is-fetching-countable-list="isFetchingFeatureList"
                    :show-app-usage="showAppUsage"
                    :active-segment="activeSegment"
                    :active-date-range="activeDateRange" />
            </div>
            <div class="analytics-dashboard--row">
                <analytics-visitor-list
                    class="analytics-dashboard--visitor-list"
                    :is-fetching-visitor-list="isFetchingVisitorList"
                    :schema-list="schemaList"
                    :visitor-list-user-settings="visitorListUserSettings"
                    :active-timezone="activeTimezone"
                    :app-map="appMap"
                    :visitor-list="visitorList"
                    :columns="visitorListColumns"
                    @fetchVisitorList="fetchVisitorList"
                    @tableSettingsUpdate="updateTableUserSettings"
                    @routeToVisitorDetails="onRouteToVisitorDetails" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { isCancel } from 'axios';
import { getVisitorsOverTime } from '@/aggregations/visitors-over-time';
import { getVisitorList } from '@/aggregations/visitor-list';
import AnalyticsVisitorList from '@/stateless-components/analytics/application-usage/AnalyticsVisitorList';
import TopPages from '@/stateless-components/analytics/application-usage/TopPages.vue';
import TopFeatures from '@/stateless-components/analytics/application-usage/TopFeatures.vue';
import CountableUseByVisitor from '@/stateless-components/analytics/application-usage/CountableUseByVisitor';
import PageFeatureUsageOverTime from '@/stateless-components/analytics/application-usage/PageFeatureUsageOverTime';
import VisitorsOverTime from '@/stateless-components/analytics/application-usage/VisitorsOverTime';
import GlobalFilters from '@/components/filters/GlobalFilters';
import { getVisitorListColumns } from '@/stateless-components/utils/analytics-visitor-list';
import { PendoLoading } from '@pendo/components';
import CallToActionCard from '@/stateless-components/analytics/application-usage/CallToActionCard';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import { getPageUsageOverTime } from '@/aggregations/page-usage-over-time';
import { getFeatureUsageOverTime } from '@/aggregations/feature-usage-over-time';
import { getTimeSeriesByPeriod } from '@/utils/time-series';
import { encodeIdForUri } from '@/utils/utils';

export default {
    name: 'AnalyticsDashboard',
    components: {
        AnalyticsVisitorList,
        TopPages,
        TopFeatures,
        PageFeatureUsageOverTime,
        CountableUseByVisitor,
        VisitorsOverTime,
        GlobalFilters,
        CallToActionCard
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            isFetchingVisitorsOverTime: false,
            aggCancel: null,
            visitorsOverTime: [],
            isFetchingVisitorList: false,
            visitorListAggCancel: null,
            visitorList: [],
            usageOverTimeAggCancel: null,
            isFetchingPageFeatureUsageOverTime: false,
            usageOverTime: {
                pageUsageOverTime: [],
                featureUsageOverTime: []
            }
        };
    },
    computed: {
        ...mapGetters({
            activeSegment: 'filters/activeSegment',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeSubscriptionUtcOffset: 'subscriptions/activeSubscriptionUtcOffset',
            allApps: 'apps/appMapForActiveSubscription',
            filteredApps: 'filters/appsForAppIdFilter',
            pageList: 'pages/list',
            featureList: 'features/list',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            pageListWithAnalytics: 'pages/listWithAnalytics',
            featureListWithAnalytics: 'features/listWithAnalytics',
            showAppUsage: 'apps/showAppUsage',
            canCreatePage: 'pages/canCreatePage',
            canCreateFeature: 'features/canCreateFeature',
            activeTimeSeries: 'filters/activeTimeSeries',
            schemaList: 'filters/schemaList',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName',
            activeTimezone: 'subscriptions/getTimezone',
            appMap: 'apps/appMapForActiveSubscription'
        }),
        ...mapState({
            activeDateRange: (state) => state.filters.dateRange,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            updatingActiveApp: (state) => state.apps.updatingActive,
            activeSubHasAnyFeatures: (state) => state.features.activeSubHasAnyFeatures,
            activeSubHasAnyPages: (state) => state.pages.activeSubHasAnyPages,
            isFetchingPageList: (state) => state.pages.isFetchingWithAnalytics,
            isFetchingFeatureList: (state) => state.features.isFetchingWithAnalytics,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            pageUsageChart: (state) => state.analytics.pageUsageChart
        }),
        appsWithoutPages () {
            let apps = [];
            if (this.isAppIdsFilterInUse) {
                const appsWithTaggedPages = this.pageList.map((pages) => {
                    return pages.app.id;
                });
                apps = this.filteredApps.filter(
                    (app) => !appsWithTaggedPages.find((pageAppId) => pageAppId === app.id)
                );
            }

            return apps;
        },
        appsWithoutFeatures () {
            let apps = [];
            if (this.isAppIdsFilterInUse) {
                const appsWithTaggedFeatures = this.featureList.map((features) => {
                    return features.app.id;
                });

                apps = this.filteredApps.filter(
                    (app) => !appsWithTaggedFeatures.find((featureAppId) => featureAppId === app.id)
                );
            }

            return apps;
        },
        showNoFeaturesCTA () {
            return (
                this.canCreateFeature({ filtered: true }) &&
                ((this.appsWithoutFeatures.length === 1 && this.filteredApps.length === 1) ||
                    !this.activeSubHasAnyFeatures) &&
                this.activeIsDigitalAdoption
            );
        },
        showNoPagesCTA () {
            return (
                this.canCreatePage({ filtered: true }) &&
                ((this.appsWithoutPages.length === 1 && this.filteredApps.length === 1) ||
                    !this.activeSubHasAnyPages) &&
                this.activeIsDigitalAdoption
            );
        },
        topPages () {
            const pages = cloneDeep(this.pageListWithAnalytics);

            return pages.sort((a, b) => b.pageLoads - a.pageLoads).slice(0, 10);
        },
        topFeatures () {
            const features = cloneDeep(this.featureListWithAnalytics);

            return features.sort((a, b) => b.numEvents - a.numEvents).slice(0, 10);
        },
        visitorListUserSettings () {
            return this.getTableUserSettingValueByName('visitorList');
        },
        visitorListColumns () {
            return getVisitorListColumns(this.visitorListUserSettings, this.schemaList, this.activeTimezone);
        },
        topFeaturesUserSettings () {
            return this.getTableUserSettingValueByName('topFeatures');
        },
        topFeaturesColumns () {
            return getVisitorListColumns(this.topFeaturesUserSettings, this.schemaList, this.activeTimezone);
        },
        topPagesUserSettings () {
            return this.getTableUserSettingValueByName('topPages');
        },
        topPagesColumns () {
            return getVisitorListColumns(this.visitorListUserSettings, this.schemaList, this.activeTimezone);
        }
    },
    watch: {
        activeDateRange: ['fetchVisitorsOverTime', 'fetchVisitorList'],
        activeSegmentId: ['fetchVisitorsOverTime', 'fetchVisitorList'],
        appIdsFilter: ['fetchVisitorsOverTime', 'fetchVisitorList']
    },
    async created () {
        await this.fetchPages({ noCache: true });
        await this.fetchFeatures({ noCache: true });
        this.fetchPagesWithAnalytics();
        this.fetchFeaturesWithAnalytics();
        this.fetchVisitorsOverTime();
        this.fetchVisitorList();
    },
    methods: {
        ...mapActions({
            fetchPages: 'pages/fetch',
            fetchFeatures: 'features/fetch',
            fetchPagesWithAnalytics: 'pages/fetchWithAnalytics',
            fetchFeaturesWithAnalytics: 'features/fetchWithAnalytics',
            updateUserSettingByName: 'userSettings/updateUserSettingByName',
            updateSubNamespaceSetting: 'userSettings/updateSubNamespaceSetting'
        }),
        ...mapMutations({
            setPageUsageChart: 'analytics/setPageUsageChart'
        }),
        async fetchVisitorsOverTime () {
            this.isFetchingVisitorsOverTime = true;

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            const appIdParam = this.appIdsFilter;

            try {
                this.visitorsOverTime = await getVisitorsOverTime({
                    segmentId: this.activeSegmentId,
                    appId: appIdParam,
                    signal: this.aggCancel.signal,
                    dateRange: this.activeDateRange
                });

                this.isFetchingVisitorsOverTime = false;
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetchingVisitorsOverTime = false;
                }
            }
        },
        onLaunchTagPagesModal () {
            this.$router.push({ name: 'pagesDashboard', hash: '#autoLaunchPagesModal' });
        },
        onLaunchTagFeaturesModal () {
            this.$router.push({ name: 'featuresDashboard', hash: '#autoLaunchFeaturesModal' });
        },
        async fetchVisitorList () {
            this.isFetchingVisitorList = true;

            if (this.visitorListAggCancel) {
                this.visitorListAggCancel.abort();
            }

            this.visitorListAggCancel = new AbortController();

            try {
                const timeSeries = {
                    ...this.activeTimeSeries,
                    period: 'dayRange'
                };

                const appId = this.appIdsFilter;
                const appList = this.filteredApps;

                const visitorList = await getVisitorList({
                    timeSeries,
                    segmentId: this.activeSegmentId,
                    appId,
                    appList,
                    columns: this.visitorListColumns,
                    signal: this.visitorListAggCancel.signal,
                    filterNoEvents: true,
                    addLastVisitByApp: true
                });

                this.isFetchingVisitorList = false;

                if (visitorList) {
                    const appFilterMap = keyBy(this.filteredApps, 'id');
                    this.visitorList = visitorList.map((row) => {
                        const noAppDisplayName = !row.lastVisitAppId || !appFilterMap[row.lastVisitAppId];
                        if (noAppDisplayName) {
                            row.lastVisitAppDisplayName = '';

                            return row;
                        }

                        row.lastVisitAppDisplayName = appFilterMap[row.lastVisitAppId].displayName;

                        return row;
                    });
                }
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetchingVisitorList = false;
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
            }
        },
        updateTableUserSettings (settingsName, newUserSettings) {
            this.updateUserSettingByName({
                settingsName,
                value: newUserSettings
            });
        },
        async getPageFeatureUsageOverTimeData ({ chartPages, chartFeatures, usageMetric, timePeriod }) {
            const dateRange = this.activeDateRange;
            const segmentId = this.activeSegmentId;
            const noEntitiesToRequest = !chartPages.length && !chartFeatures.length;
            if (noEntitiesToRequest) {
                return;
            }

            if (this.usageOverTimeAggCancel) {
                this.usageOverTimeAggCancel.abort();
            }

            this.usageOverTimeAggCancel = new AbortController();
            this.isFetchingPageFeatureUsageOverTime = true;

            const period = timePeriod;

            try {
                const pagePromises = chartPages.map((page) => {
                    return getPageUsageOverTime({
                        appId: page.appId,
                        pageId: page.id,
                        timeSeries: getTimeSeriesByPeriod(period, dateRange),
                        metric: usageMetric.replace(/s$/, ''),
                        segmentId,
                        signal: this.usageOverTimeAggCancel.signal
                    });
                });

                const featurePromises = chartFeatures.map((feature) => {
                    return getFeatureUsageOverTime({
                        appId: feature.appId,
                        featureId: feature.id,
                        timeSeries: getTimeSeriesByPeriod(period, dateRange),
                        metric: usageMetric.replace(/s$/, ''),
                        segmentId,
                        signal: this.usageOverTimeAggCancel.signal
                    });
                });

                const [pageUsageOverTime, featureUsageOverTime] = await Promise.all([
                    Promise.all(pagePromises),
                    Promise.all(featurePromises)
                ]);
                this.usageOverTime = {
                    ...this.usageOverTime,
                    pageUsageOverTime,
                    featureUsageOverTime
                };
                this.isFetchingPageFeatureUsageOverTime = false;
                this.usageOverTimeAggCancel = null;
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetchingPageFeatureUsageOverTime = false;
                }
            }
        },
        onRouteToPages () {
            this.$router.push({ name: 'pagesDashboard' });
        },
        onRouteToFeatures () {
            this.$router.push({ name: 'featuresDashboard' });
        },
        onRouteToVisitorDetails (row) {
            this.$router.push({ name: 'visitorDetails', params: { visitorId: encodeIdForUri(row.visitorId) } });
        }
    }
};
</script>

<style lang="scss">
.analytics-dashboard {
    .analytics-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .analytics-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));

        &:not(:last-child) {
            min-height: 448px;
        }

        &.half-size-row {
            min-height: 224px;
        }

        &.cta-row {
            min-height: 150px;
        }
    }

    .analytics-dashboard--column {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));
        min-height: 440px;
    }
}
</style>
