import { v4 as uuid } from 'uuid';

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

const BASE_HEIGHT = 52;

const BASE_GROUP_HEIGHT = 30;

export const SPACING_HEIGHT = 7;

export const DIVIDER_LINE_HEIGHT = 1;

export const OPTION_HEIGHT = BASE_HEIGHT;

export const GROUP_OPTION_HEIGHT = BASE_GROUP_HEIGHT;

const DIVIDER_HEIGHT = SPACING_HEIGHT + DIVIDER_LINE_HEIGHT;

const GROUP_HEIGHT = SPACING_HEIGHT + BASE_GROUP_HEIGHT;

function includes (str, query) {
    if (str === undefined) {
        str = 'undefined';
    }
    if (str === null) {
        str = 'null';
    }
    if (str === false) {
        str = 'false';
    }

    const text = str.toString().toLowerCase();

    return text.includes(query.trim().toLowerCase());
}

function filterOption (option, { prop, value }) {
    return Array.isArray(value) ? value.includes(option[prop]) : value === option[prop];
}

function elementHasOverflowY ({ clientHeight, scrollHeight }) {
    return scrollHeight > clientHeight;
}

function elementHasOverflowX ({ clientWidth, scrollWidth }) {
    return scrollWidth > clientWidth;
}

export function elementHasOverflow (element) {
    return elementHasOverflowY(element) || elementHasOverflowX(element);
}

export function addOptionMetadata (options) {
    return options.map((option) => ({
        ...option,
        type: 'option',
        size: OPTION_HEIGHT
    }));
}

export function addGroupMetadata (group, isCollapsed) {
    const groupArray = [
        {
            ...group,
            type: 'group',
            name: group.section,
            size: GROUP_HEIGHT
        }
    ];

    return isCollapsed ? groupArray : groupArray.concat(addOptionMetadata(group.targets));
}

export function addDivider (options) {
    options.push({
        type: 'divider',
        size: DIVIDER_HEIGHT,
        id: uuid()
    });

    return options;
}

/**
 * Transforms a nested list of groups and options into a flat list of groups, options, and dividers.
 * Adds type and size (height, in pixels) data to every item in the output array.
 *
 * @returns A one-dimensional array of items to render in an option list.
 */
export function flattenOptions (groups) {
    const reducedOptions = groups.reduce((options, group) => {
        if (group.targets.length) {
            // Add a divider only if we have previous options to separate from
            if (options.length) {
                options = addDivider(options);
            }

            return options.concat(addGroupMetadata(group));
        }

        return options;
    }, []);

    return reducedOptions;
}

export function filterOptions (options, search, filters = []) {
    return options.filter((option) => {
        const matchesFilters = filters.every((filter) => filterOption(option, filter));

        return matchesFilters && includes(option.name, search);
    });
}

export function filterGroups (groups, search, filters = []) {
    return groups.reduce((filteredGroups, group) => {
        const result = searchGroup(
            {
                ...group,
                targets: group.targets.filter((target) => {
                    return filters.every((filter) => filterOption(target, filter));
                })
            },
            search
        );

        if (result) filteredGroups.push(result);

        return filteredGroups;
    }, []);
}

export function searchGroup (group, search) {
    const groupOptions = includes(group.section, search) ? group.targets : filterOptions(group.targets, search);

    return groupOptions.length
        ? {
            ...group,
            targets: groupOptions
        }
        : null;
}

export function sortData (sortBy) {
    return (a, b) => collator.compare(a[sortBy], b[sortBy]);
}

export function getEmptyStateStrings (noOptions, noSearchResults, targetLabel) {
    let emptyStateSuggestion = null;
    let noSearchResultsMessage = null;
    const targetHasProductArea = ['pages', 'features', 'track events'].includes(targetLabel);
    const orProductAreasStr = targetHasProductArea ? ' or product areas' : '';
    if (noOptions) {
        emptyStateSuggestion = 'Please select an app.';
    } else if (noSearchResults) {
        emptyStateSuggestion = 'Please try adjusting your search.';
        noSearchResultsMessage = `No ${targetLabel}${orProductAreasStr} match your search.`;
    }

    return {
        header: `No ${targetLabel}${orProductAreasStr} found.`,
        noSearchResultsMsg: noSearchResultsMessage,
        suggestion: emptyStateSuggestion
    };
}
