<template>
    <div class="manage-users-list">
        <user-management
            ref="userManagement"
            product-type="Adopt"
            :active-user="activeUser"
            :apps="apps"
            :loading-users="isLoading"
            :product-lines="productLines"
            :roles="roles"
            :role-name-map="roleNameMap"
            :subscription="activeSub"
            :subscription-config="subscriptionConfig"
            :user-list="userList"
            @deleted-user="removeDeletedUser"
            @update-user="updateUser"
            @load-users="loadUsers" />
    </div>
</template>

<script>
import { UserManagement, Utils } from '@pendo/user-management-ui';
const { buildRoleMapWithRoles, removeNonExistentCustomRoles } = Utils;
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getApplicationRolesBySub } from '@/utils/apps';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

export default {
    name: 'ManageUsers',
    components: {
        UserManagement
    },
    data () {
        return {
            isLoading: false,
            userList: [],
            roleNameMap: {},
            roles: []
        };
    },
    computed: {
        ...mapState({
            activeUser: (state) => state.auth.user,
            userOverlayVisible: (state) => state.users.userOverlayVisible
        }),
        ...mapGetters({
            users: 'users/listUsers',
            subscription: 'subscriptions/active',
            productLines: 'subscriptions/activeProductLines',
            apps: 'apps/listAllForActiveSubscription',
            activeSubHasFlag: 'subscriptions/activeSubHasFlag',
            useAdoptParityCustomRolesEnabled: 'subscriptions/useAdoptParityCustomRolesEnabled',
            requireSSO: 'subscriptions/requireSSO',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            aeuAttributes: 'subscriptions/aeuAttributes'
        }),
        subscriptionConfig () {
            return {
                enableCustomUserRoles: this.activeSubHasFlag('enableCustomUserRoles'),
                useAdoptParityCustomRolesEnabled: this.useAdoptParityCustomRolesEnabled,
                isAdopt: get(this.productLines, 'adopt.purchased', false),
                isAEU: get(this.productLines, 'adoptEndUser.purchased', false),
                hasResourceCenterAccess:
                    this.activeIsDigitalAdoption || get(this.aeuAttributes, 'resourceCenterAccess', false),
                enableViewerPermission: this.activeSubHasFlag('enableViewerPermission'),
                requireSSO: this.requireSSO
            };
        },
        activeSub () {
            const sub = cloneDeep(this.subscription);
            sub.timeZone = sub.timezone;

            return sub;
        }
    },
    watch: {
        userOverlayVisible (isVisible) {
            if (isVisible) {
                this.$refs.userManagement.openUserModal({});
                this.setUserOverlayVisible({ isVisible: false });
            }
        }
    },
    created () {
        this.fetchUserList();
    },
    methods: {
        ...mapActions({
            fetchUserList: 'users/fetchUserList'
        }),
        ...mapMutations({
            setUserOverlayVisible: 'users/setUserOverlayVisible',
            setUpdate: 'users/setUpdate',
            setRemove: 'users/setRemove'
        }),
        async loadUsers ({ reFetch = false }) {
            this.isLoading = true;

            const results = await Promise.all([
                getApplicationRolesBySub(this.subscription.id),
                this.fetchUserList({ noCache: reFetch })
            ]);
            this.roles = results[0];
            this.roleNameMap = buildRoleMapWithRoles(this.roles);

            if (this.users) {
                this.userList = cloneDeep(this.users);
                removeNonExistentCustomRoles(this.userList, this.roleNameMap);
            }

            this.isLoading = false;
        },
        removeDeletedUser (row) {
            this.setRemove({ user: row });
            this.loadUsers({ reFetch: true });
        },
        updateUser (user) {
            this.setUpdate({ user });
            this.loadUsers({ reFetch: true });
        }
    }
};
</script>
