<template>
    <pendo-multiselect
        ref="viewBy"
        v-pendo-loading:feather="loading"
        :placeholder="placeholderText"
        :loading="isFetchingMetadata"
        :options="visitorMetadataViewByOptions"
        label-key="displayName"
        @select="onMetadataFieldSelection">
        <template #header>
            <div class="view-by__header">
                <div
                    class="pendo-multiselect__option"
                    @click="onMetadataFieldSelection()">
                    <span class="pendo-multiselect__element">
                        View by Total
                    </span>
                </div>
            </div>
        </template>
    </pendo-multiselect>
</template>

<script>
import { PendoMultiselect, PendoLoading } from '@pendo/components';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'ViewBy',
    components: {
        PendoMultiselect
    },
    directives: {
        PendoLoading
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        viewByMetadataField: {
            type: Object,
            default: () => ({})
        },
        visitorMetadataOptions: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            isFetchingMetadata: false
        };
    },
    computed: {
        placeholderText () {
            if (isEmpty(this.viewByMetadataField)) {
                return 'View by Total';
            }

            const { displayName } = this.viewByMetadataField;

            return displayName;
        },
        visitorMetadataViewByOptions () {
            return sortBy(
                this.visitorMetadataOptions.map(({ displayName, ...rest }) => ({
                    ...rest,
                    displayName: `View by ${displayName}`
                })),
                [({ displayName }) => displayName.toLowerCase()]
            );
        }
    },
    methods: {
        onMetadataFieldSelection (metadata = {}) {
            const { group: currentGroup, field: currentField, kind } = this.viewByMetadataField || {};
            const { group: selectedGroup, field: selectedField } = metadata;
            if (currentGroup === selectedGroup && currentField === selectedField && kind === 'visitor') {
                this.$refs.viewBy.toggleMenu();

                return;
            }
            this.$emit('metadata-select', { metadata });
            this.$refs.viewBy.toggleMenu();
        }
    }
};
</script>

<style lang="scss">
.view-by__header {
    padding: 8px 0;
    border-bottom: 1px solid $gray-lighter-6;
    overscroll-behavior: none;

    .pendo-multiselect__element,
    .pendo-multiselect__option {
        height: 26px;

        &:hover {
            background-color: $gray-lighter-7;
        }
    }
}
</style>
