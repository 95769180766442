var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isDropdown)?_c('pendo-multiselect',{staticClass:"guide-utilities",attrs:{"options":_vm.utilityOptions,"popper-options":{ class: 'guide-utilities-panel', placement: 'bottom-end' },"min-menu-width":180,"stateless":"","value-key":"action"},on:{"select":_vm.handleNextAction,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"trigger",fn:function({ toggleMenu }){return [_vm._t("default",null,{"toggleMenu":toggleMenu})]}},{key:"option",fn:function({ option }){return [_c('div',{staticClass:"guide-utilities--option"},[_c('pendo-icon-option',{attrs:{"option":option}}),(option.showTag)?_c('pendo-tag',{attrs:{"label":"NEW","color":"#8029E9","size":"mini","round":"","subtle":""}}):_vm._e()],1)]}}],null,true)}):_vm._e(),(!_vm.isDropdown)?_c('pendo-actions-cell',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:material",value:({
            loading: _vm.copying,
            spinnerProps: {
                size: 14,
                background: '#f8f8f9'
            }
        }),expression:"{\n            loading: copying,\n            spinnerProps: {\n                size: 14,\n                background: '#f8f8f9'\n            }\n        }",arg:"material"}],attrs:{"row":_vm.guide,"actions":_vm.tableRowActions},on:{"clone":_vm.cloneGuide,"copyToAccount":_vm.openGuideCopyDrawer,"clear":_vm.confirmClearGuideData,"preview":_vm.previewGuide,"delete":_vm.confirmDeleteGuide}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }