import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { getGuideViews, getGuideTotalViewsByPeriod, getGuideFirstTimeViews } from '@/aggregations/guide-views';
import { getTimeSeriesByPeriod } from '@/utils/time-series';
import { isCrossApp } from '@pendo/services/CrossAppGuides';

export function getInitialState () {
    return {
        isFetchingViews: false,
        views: {},
        isFetchingViewsByPeriod: false,
        viewsByPeriod: [],
        timePeriod: 'daily',
        metric: 'views',
        guideActivity: [],
        guideActivityTableUserSettings: {}
    };
}

export const state = getInitialState();

export const mutations = {
    setMetric (state, { metric }) {
        state.metric = metric;
    },
    setTimePeriod (state, { timePeriod }) {
        state.timePeriod = timePeriod;
    },
    setViews (state, { views }) {
        state.views = views;
    },
    setViewsByPeriod (state, { viewsByPeriod }) {
        state.viewsByPeriod = viewsByPeriod;
    },
    setFetchingViews (state, { isFetchingViews }) {
        state.isFetchingViews = isFetchingViews;
    },
    setFetchingViewsByPeriod (state, { isFetchingViewsByPeriod }) {
        state.isFetchingViewsByPeriod = isFetchingViewsByPeriod;
    },
    setGuideActivityTableUserSettings (state, { guideActivityTableUserSettings }) {
        state.guideActivityTableUserSettings = guideActivityTableUserSettings;
    },
    setGuideActivityTableColumns (state, { type, guideActivityTableColumns }) {
        const newSettings = cloneDeep(state.guideActivityTableUserSettings);
        set(newSettings, `${type}.columns`, guideActivityTableColumns);
        state.guideActivityTableUserSettings = newSettings;
    },
    setGuideActivityTableSort (state, { type, guideActivityTableSort }) {
        const newSettings = cloneDeep(state.guideActivityTableUserSettings);
        set(newSettings, `${type}.sort`, guideActivityTableSort);
        state.guideActivityTableUserSettings = newSettings;
    },
    reset (state) {
        Object.assign(state, getInitialState(), {});
    }
};

export const actions = {
    async fetchGuideViews ({ commit, rootState, rootGetters }) {
        commit('setFetchingViews', { isFetchingViews: true });
        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const appIds = rootGetters['subscriptions/usesMultiApp']
            ? rootState.filters.appIdsFilter
            : [rootGetters['apps/activeId']];
        const views = await getGuideViews({
            appIds,
            segmentId: rootState.filters.activeSegmentId,
            timeSeries
        });
        commit('setFetchingViews', { isFetchingViews: false });
        commit('setViews', { views: keyBy(views, 'guideId') });
    },
    async fetchGuideViewsByPeriod ({ state, commit, rootGetters, rootState }, { guideId, createdAt }) {
        commit('setViewsByPeriod', { viewsByPeriod: [] });
        commit('setFetchingViewsByPeriod', { isFetchingViewsByPeriod: true });
        const guide = rootGetters['guides/getGuideById'](guideId);
        const { appId } = guide;
        const appIds = isCrossApp(guide) ? get(guide, 'appIds', []) : [appId];
        const period = state.timePeriod;
        const timeSeries = getTimeSeriesByPeriod(period, rootState.filters.dateRange);
        const { activeSegmentId } = rootState.filters;

        const [viewsByPeriod, firstTimeViews] = await Promise.all([
            getGuideTotalViewsByPeriod({
                appIds,
                guideId,
                metric: state.metric.replace(/s$/, ''),
                segmentId: activeSegmentId,
                timeSeries
            }),
            getGuideFirstTimeViews({
                appIds,
                guideId,
                createdAt,
                timeSeries,
                segmentId: activeSegmentId
            })
        ]);
        commit('setFetchingViewsByPeriod', { isFetchingViewsByPeriod: false });

        const firstTimeViewsLookup = keyBy(firstTimeViews, 'firstTime');

        const formattedViews = viewsByPeriod.messages.map((message) => {
            const firstTime = firstTimeViewsLookup[message.time] || { count: 0 };

            return {
                time: message.time,
                total: message.rows[0].count,
                firstTime: firstTime.count
            };
        });

        commit('setViewsByPeriod', { viewsByPeriod: formattedViews });
    }
};

export const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
