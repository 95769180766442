<template>
    <pendo-card
        title="Guide details"
        class="guide-details-card">
        <template #body>
            <div
                class="details-body"
                :model="model">
                <pendo-input
                    maxlength="100"
                    label="Guide name"
                    placeholder="Unnamed [timestamp]"
                    @input="onGuideNameUpdate" />
                <pendo-multiselect
                    full-width
                    value-key="id"
                    label-key="name"
                    :disabled="themes.length === 0"
                    :value="selectedTheme"
                    :options="themes"
                    :allow-empty="false"
                    :labels="{ top: 'Theme' }"
                    @select="onThemeSelect" />
                <pendo-multiselect
                    v-if="categories.length"
                    full-width
                    group-options-key="items"
                    group-label-key="name"
                    value-key="id"
                    label-key="name"
                    :options="categories"
                    :value="model.selectedCategory"
                    :allow-empty="false"
                    :labels="{ top: 'Category' }"
                    @select="onCategorySelect">
                    <template #header="{ select }">
                        <div class="pendo-layout-chooser__guide-category-sticky-header">
                            <div
                                :class="[
                                    'pendo-multiselect__option',
                                    'pendo-layout-chooser__guide-category-sticky-header--option',
                                    {
                                        'is-selected': model.selectedCategory.name === NO_GUIDE_CATEGORY_OPTION.name
                                    }
                                ]"
                                @click="select(NO_GUIDE_CATEGORY_OPTION)">
                                {{ NO_GUIDE_CATEGORY_OPTION.name }}
                            </div>
                        </div>
                    </template>
                </pendo-multiselect>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoInput, PendoMultiselect } from '@pendo/components';
const NO_GUIDE_CATEGORY_OPTION = { name: 'No Guide Category' };

export default {
    name: 'GuideDetailsWithAI',
    components: {
        PendoCard,
        PendoInput,
        PendoMultiselect
    },
    props: {
        appId: {
            type: Number,
            default: () => null
        },
        selectedTheme: {
            type: Object,
            default: () => null
        },
        categories: {
            type: Array,
            default: () => []
        },
        themes: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            NO_GUIDE_CATEGORY_OPTION,
            model: {
                selectedCategory: NO_GUIDE_CATEGORY_OPTION
            }
        };
    },
    watch: {
        appId: {
            immediate: true,
            handler (newVal) {
                if (newVal) {
                    const defaultThemeForSelectedApp = this.themes.find(
                        (theme) => theme.tags && theme.tags.includes('default')
                    );
                    this.setTheme(defaultThemeForSelectedApp);
                }
            }
        }
    },
    created () {
        if (!this.categories || this.categories.length <= 0) return;
    },
    methods: {
        setCategory (category) {
            this.model.selectedCategory = category;
            this.$emit('categorySelected', category);
        },
        setTheme (theme) {
            this.$emit('themeSelected', theme);
        },
        onGuideNameUpdate (name) {
            this.$emit('guideNameUpdated', name);
        },
        onThemeSelect (theme) {
            this.setTheme(theme);
        },
        onCategorySelect (category) {
            this.setCategory(category);
        }
    }
};
</script>

<style>
.details-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>
