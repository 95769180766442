<template>
    <div class="raw-events">
        <div v-if="!isPermanentlyRecording && activeIsDigitalAdoption && !loadingSubscription">
            <pendo-alert
                v-if="isTemporarilyRecording"
                type="info">
                <div class="raw-event-alert-content">
                    Raw events are being recorded for viewing until {{ recordUntilDate }}. Click Reload to see new raw
                    events.
                    <pendo-button
                        theme="app"
                        label="Reload"
                        :loading="loadingRawEvents"
                        @click="reload" />
                </div>
            </pendo-alert>
            <pendo-alert
                v-else
                type="warning">
                <div class="raw-event-alert-content">
                    Due to the quantity of events, raw events have stopped recording automatically. This affects only
                    this page, and has no effect on data collection in the rest of the app.
                    <pendo-button
                        theme="app"
                        label="Start Recording"
                        @click="startRecording" />
                </div>
            </pendo-alert>
        </div>
        <pendo-alert
            v-if="hasError && errorText"
            type="error"
            :description="errorText" />
        <pendo-table
            ref="rawEventsTable"
            :auto-height="true"
            :columns="columns"
            :data="decodedEvents"
            row-key="displayId"
            :loading="loadingRawEvents"
            @link-click="handleEventNameClick">
            <template #headerLeft>
                <div class="raw-events-table-header">
                    <span>Events</span>
                </div>
            </template>
            <template #headerRight>
                <div class="raw-events-table-header">
                    <pendo-button
                        theme="app"
                        icon="chevrons-left"
                        label="Newer"
                        type="link"
                        :disabled="!prevLink"
                        @click="loadNewerEvents" />
                    <pendo-divider
                        height="10px"
                        width="32px"
                        direction="vertical" />
                    <pendo-button
                        theme="app"
                        suffix-icon="chevrons-right"
                        label="Older"
                        type="link"
                        :disabled="!hasMore"
                        @click="loadOlderEvents" />
                </div>
            </template>
            <template #app="{ row }">
                <pendo-app-cell
                    v-if="!!apps[row.appId]"
                    :row="{ app: apps[row.appId] }" />
            </template>
        </pendo-table>
        <pendo-modal
            :visible="showEventModal && !!decodedEvent"
            title="Event Info"
            height="80%"
            width="50%"
            @close="handleEventModalClose">
            <template #body>
                <div>
                    <pre>{{ decodedEvent }}</pre>
                </div>
            </template>
            <template #footer>
                <div>
                    <pendo-button
                        label="Close"
                        theme="app"
                        type="secondary"
                        @click="handleEventModalClose" />
                </div>
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import { PendoAlert, PendoTable, PendoDivider, PendoButton, PendoAppCell, PendoModal } from '@pendo/components';
import moment from '@/utils/moment.js';
import UAParser from 'ua-parser-js';
import { mapActions, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import {
    getRawEvents,
    decodeRawEvents,
    sortRawEvents,
    filterRawEvents,
    deleteTraitsFromDecodedEvent
} from '@/utils/raw-events.js';
import get from 'lodash/get';
import { fetchFullSubscription } from '@/utils/subscriptions';
/* We only record events if:
 * 1. we have less than 1M events, or
 * 2. we have previously activated recording.
 *
 * We can determine this by looking at the eventCount and recordUntil
 * values in the subscription.
 */
const EVENT_COUNT = 1000000;

export default {
    name: 'RawEvents',
    components: {
        PendoAlert,
        PendoTable,
        PendoButton,
        PendoDivider,
        PendoAppCell,
        PendoModal
    },
    props: {
        app: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data () {
        return {
            loadingRawEvents: false,
            hasError: false,
            errorText: null,
            decodedEvents: [],
            prevBreadCrumbs: [],
            hasMore: false,
            nextLink: undefined,
            prevLink: false,
            nextKey: undefined,
            showEventModal: false,
            decodedEvent: null,
            subscription: {},
            loadingSubscription: true,
            columns: [
                {
                    label: 'Browser Time',
                    prop: 'displayBrowserTime',
                    minWidth: '275',
                    formatter: (row) => {
                        return `${moment(row.displayBrowserTime).format('ll LTS')} ${this.subscription.timezone}`;
                    }
                },
                {
                    label: 'Agent Version',
                    prop: 'version'
                },
                {
                    label: 'Visitor',
                    minWidth: '150',
                    prop: 'displayVisitor'
                },
                {
                    label: 'Account',
                    minWidth: '150',
                    prop: 'displayAccount'
                },
                {
                    label: 'Event',
                    type: 'link',
                    minWidth: '160',
                    prop: 'type',
                    formatter: (row) => row.type || row.event
                },
                {
                    label: 'Remote IP',
                    minWidth: '125',
                    prop: 'remoteIp',
                    formatter: (row) => row.remoteIp || 'N/A'
                },
                {
                    label: 'Browser / OS',
                    minWidth: '250',
                    prop: 'userAgent',
                    formatter: (row) => {
                        if (row.displayOtherAgent) {
                            return row.displayOtherAgent;
                        }
                        const result = this.userAgentParser.setUA(row.userAgent).getResult();
                        if (result.browser.name) {
                            return `${result.browser.name} ${result.browser.version} on ${result.os.name}`;
                        }

                        return '';
                    }
                },
                {
                    label: 'URL',
                    minWidth: '300',
                    formatter: (row) => {
                        if (row.url) return row.url;
                        if (has(row, 'context.page.url')) return row.context.page.url;
                        if (has(row, 'context.url')) return row.context.url;

                        return 'N/A';
                    }
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            apps: 'apps/getList',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        recordUntilDate () {
            return moment(this.subscription.recordUntil).format('LTS');
        },
        isTemporarilyRecording () {
            return this.subscription.eventCount >= EVENT_COUNT && new Date().getTime() < this.subscription.recordUntil;
        },
        isPermanentlyRecording () {
            return (
                get(this.subscription.featureFlags, 'alwaysRecordRawevent') ||
                this.subscription.eventCount < EVENT_COUNT
            );
        }
    },
    async created () {
        this.subscription = await fetchFullSubscription();
        this.loadingSubscription = false;
        // UAParse is a global in our app. see app.js
        this.userAgentParser = new UAParser();
        if (this.isMultiApp) {
            this.columns.push({
                prop: 'app',
                label: 'App',
                minWidth: '150'
            });
        }
        const params = new URLSearchParams(window.location.search);
        const afterKey = params.get('afterKey');
        await this.loadRawEvents(afterKey ? { afterKey } : {});
    },
    methods: {
        ...mapActions({
            startRecordingRawEvents: 'subscriptions/startRecordingRawEvents'
        }),
        async loadRawEvents (args) {
            this.hasError = false;
            this.errorText = null;
            this.loadingRawEvents = true;
            if (args && !isEmpty(args)) {
                const url = new URL(window.location.href);
                const params = new URLSearchParams(url.search);
                params.set('afterKey', args.afterKey);
                window.history.replaceState({}, '', `${url.pathname}?${params}`);
            }
            try {
                args = { appId: this.app.id, ...args };
                const { data } = await getRawEvents(args);
                data.results = data.results.filter((event) => {
                    return event.processedTime > 0;
                });
                this.hasMore = data.hasMore;
                this.nextLink = this.hasMore;
                this.nextKey = data.afterKey;
                this.decodedEvents = sortRawEvents(filterRawEvents(decodeRawEvents(data.results)));
            } catch (error) {
                this.hasError = true;
                this.errorText = 'There was an error loading raw events.';
            } finally {
                this.loadingRawEvents = false;
            }
            // reset the scroll position to the top of the page
            this.$refs.rawEventsTable.clearScroll();
        },
        handleEventNameClick (row) {
            this.showEventModal = true;
            this.decodedEvent = deleteTraitsFromDecodedEvent(row.row);
        },
        handleEventModalClose () {
            this.showEventModal = false;
            this.decodedEvent = null;
        },
        loadOlderEvents () {
            if (this.nextLink) {
                this.prevLink = true;
                this.prevBreadCrumbs.unshift(this.nextKey);
                this.loadRawEvents({ afterKey: this.nextKey });
            }
        },
        loadNewerEvents () {
            const prevKey = this.prevBreadCrumbs.pop();
            if (this.prevBreadCrumbs.length === 0) {
                this.prevLink = false;
                this.loadRawEvents({});
            } else {
                this.loadRawEvents({ afterKey: prevKey });
            }
        },
        async startRecording () {
            this.hasError = false;
            this.errorText = null;
            try {
                await this.startRecordingRawEvents();
                this.subscription = await fetchFullSubscription();
            } catch (error) {
                this.hasError = true;
                this.errorText = 'There was an error recording raw events.';
            }
        },
        reload () {
            this.prevBreadCrumbs = [];
            this.prevLink = false;
            this.loadRawEvents({});
        }
    }
};
</script>

<style scoped lang="scss">
.pendo-alert {
    margin-bottom: 16px;

    .raw-event-alert-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
}

.raw-events-table-header {
    align-items: center;
    display: flex;
}
</style>
