<template>
    <svg
        width="391"
        height="152"
        viewBox="0 0 391 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.836227 54.7539C0.374392 50.3599 3.5621 46.4234 7.95618 45.9615L123.321 33.8362C127.715 33.3744 131.651 36.5621 132.113 40.9562L139.876 114.813C140.338 119.207 137.15 123.143 132.756 123.605L96.1077 127.457C94.2947 127.647 92.6012 128.451 91.3075 129.736L81.1774 139.793C78.5123 142.439 74.3653 142.875 71.2082 140.841L59.2084 133.109C57.676 132.122 55.8523 131.688 54.0393 131.878L17.3913 135.73C12.9972 136.192 9.06068 133.004 8.59884 128.61L0.836227 54.7539Z"
            fill="#EAECF1" />
        <path
            d="M12.9132 61.7923C12.7977 60.6938 13.5946 59.7097 14.6931 59.5942L113.757 49.1822C114.856 49.0667 115.84 49.8636 115.955 50.9622L117.359 64.3191C117.475 65.4177 116.678 66.4018 115.579 66.5172L16.5151 76.9293C15.4166 77.0447 14.4325 76.2478 14.317 75.1493L12.9132 61.7923Z"
            fill="#A973F2" />
        <g opacity="0.3">
            <rect
                x="16.0078"
                y="91.1855"
                width="107.336"
                height="10.0914"
                rx="2"
                transform="rotate(-5.99999 16.0078 91.1855)"
                fill="#A973F2" />
            <rect
                x="18.0215"
                y="110.345"
                width="77.0614"
                height="10.0914"
                rx="2"
                transform="rotate(-6 18.0215 110.345)"
                fill="#A973F2" />
        </g>
        <path
            d="M258.391 40.9562C258.853 36.5621 262.79 33.3744 267.184 33.8362L382.548 45.9615C386.942 46.4234 390.13 50.3599 389.668 54.7539L381.906 128.61C381.444 133.004 377.507 136.192 373.113 135.73L336.465 131.878C334.652 131.688 332.828 132.122 331.296 133.109L319.296 140.841C316.139 142.875 311.992 142.439 309.327 139.793L299.197 129.736C297.903 128.451 296.21 127.647 294.397 127.457L257.749 123.605C253.355 123.143 250.167 119.207 250.629 114.813L258.391 40.9562Z"
            fill="#EAECF1" />
        <path
            d="M269.812 50.9622C269.928 49.8637 270.912 49.0667 272.011 49.1822L363.832 58.833C364.93 58.9484 365.727 59.9326 365.611 61.0311L364.208 74.3881C364.092 75.4866 363.108 76.2835 362.01 76.1681L270.189 66.5173C269.09 66.4018 268.293 65.4177 268.409 64.3192L269.812 50.9622Z"
            fill="#FF4876" />
        <g opacity="0.3">
            <rect
                x="267.328"
                y="79.9658"
                width="107.336"
                height="10.0914"
                rx="2"
                transform="rotate(6 267.328 79.9658)"
                fill="#FF4876" />
            <rect
                x="265.314"
                y="99.1255"
                width="77.0614"
                height="10.0914"
                rx="2"
                transform="rotate(6.00001 265.314 99.1255)"
                fill="#FF4876" />
        </g>
        <path
            d="M111.252 8C111.252 4.13401 114.386 1 118.252 1H272.252C276.118 1 279.252 4.13401 279.252 8V107.694C279.252 111.56 276.118 114.694 272.252 114.694H221.669C219.626 114.694 217.643 115.39 216.047 116.667L199.625 129.804C197.068 131.85 193.436 131.85 190.879 129.804L174.457 116.667C172.861 115.39 170.878 114.694 168.835 114.694H118.252C114.386 114.694 111.252 111.56 111.252 107.694V8Z"
            fill="#F8F8F9"
            stroke="#EAECF1"
            stroke-width="2" />
        <path
            d="M126.779 20.8892C126.779 19.7846 127.675 18.8892 128.779 18.8892H248.738C249.842 18.8892 250.738 19.7846 250.738 20.8892V39.3197C250.738 40.4243 249.842 41.3197 248.738 41.3197H128.779C127.675 41.3197 126.779 40.4243 126.779 39.3197V20.8892Z"
            fill="#128297" />
        <g opacity="0.3">
            <rect
                x="126.78"
                y="59.0278"
                width="138.125"
                height="12.9861"
                rx="2"
                fill="#128297" />
            <rect
                x="126.78"
                y="83.8193"
                width="99.1667"
                height="12.9861"
                rx="2"
                fill="#128297" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'TooltipGuidesImage'
};
</script>
