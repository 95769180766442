<template>
    <div class="paths-list">
        <div class="paths-list__filter-bar">
            <global-filters
                :show-segment-picker="false"
                :show-date-picker="false"
                class="paths-dashboard__filter-bar">
                <template #additional-filters>
                    <pendo-multiselect
                        class="paths-list__created-by-filter"
                        :class="{ 'left-spacing': usesMultiApp }"
                        :value="selectedCreatedByFilter"
                        :allow-empty="false"
                        :options="createdByFilterOptions"
                        @select="onSelectCreatedByFilter" />
                </template>
                <template #right>
                    <search
                        v-model="searchValue"
                        class="paths-dashboard__search-input"
                        placeholder="Search" />
                </template>
            </global-filters>
        </div>
        <div v-if="!Boolean(tableData.length) && !isLoading">
            <path-list-empty-state />
        </div>
        <pendo-table
            v-if="Boolean(tableData.length) || isLoading"
            v-pendo-table-user-settings-watcher="{
                settingsName: pathsListUserSettingsName
            }"
            class="paths-list__table"
            title="Paths"
            empty-text="No Paths found."
            :loading="isLoading"
            :data="tableData"
            :columns="columns"
            auto-height
            :scroll-config="{ enabled: true, pageMode: true }"
            :default-sort="sort"
            :filters="tableFilters"
            resizable>
            <template #actions="{ row }">
                <pendo-actions-cell
                    :row="row"
                    :actions="tableRowActions"
                    @delete="showDeletePathModal"
                    @copy="duplicatePath" />
            </template>
            <template #appDisplayName="{ row }">
                <pendo-app-display :apps="row.app" />
            </template>
        </pendo-table>
        <path-delete-modal
            :report="pendingPathToDelete"
            :is-visible="isPathDeleteModalVisible"
            @close="onPathDeleteModalClose"
            @deleted="onPathDeleteModalClose" />
    </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapState, mapGetters, mapActions } from 'vuex';
import { PendoMultiselect, PendoActionsCell, PendoAffix, PendoTable, PendoAppDisplay } from '@pendo/components';
import GlobalFilters from '@/components/filters/GlobalFilters';
import PathDeleteModal from '@/components/paths/PathDeleteModal';
import PathListEmptyState from '@/components/paths/PathListEmptyState.vue';
import moment, { DATE_FORMAT } from '@/utils/moment';
import Search from '@/components/Search';
import PendoTableUserSettingsWatcher from '@/directives/PendoTableUserSettingsWatcher';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings
} from '@/stateless-components/utils/table-user-settings';

export default {
    name: 'PathsList',
    components: {
        PendoTable,
        PathDeleteModal,
        PendoMultiselect,
        Search,
        GlobalFilters,
        PendoActionsCell,
        PathListEmptyState,
        PendoAppDisplay
    },
    directives: {
        PendoAffix,
        PendoTableUserSettingsWatcher
    },
    data () {
        return {
            isLoading: false,
            searchValue: '',
            createdByFilterOptions: [
                {
                    id: 'anyone',
                    label: 'Created by anyone'
                },
                {
                    id: 'me',
                    label: 'Created by me'
                }
            ],
            isLoadingReportTypeFilter: false,
            selectedCreatedByFilter: {},
            pendingPathToDelete: {},
            isPathDeleteModalVisible: false,
            pathsListUserSettingsName: 'pathsList',
            defaultSort: {
                prop: 'name',
                order: 'descending'
            }
        };
    },
    computed: {
        ...mapState({
            pathsListCreatedByFilter: (state) => state.reports.pathsListCreatedByFilter,
            user: (state) => state.auth.user,
            appIdsFilter: (state) => state.filters.appIdsFilter
        }),
        ...mapGetters({
            listByType: 'reports/listByType',
            activeReport: 'reports/activeReport',
            usesMultiApp: 'subscriptions/usesMultiApp',
            appMapForActiveSubscription: 'apps/appMapForActiveSubscription',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName'
        }),
        pathsListTableSettings () {
            return this.getTableUserSettingValueByName(this.pathsListUserSettingsName);
        },
        columns () {
            const defaultColumns = [
                {
                    label: 'Name',
                    prop: 'name',
                    type: 'link',
                    to: (row) => ({ name: 'path', params: { id: row.id } }),
                    sortable: true
                }
            ];

            if (this.usesMultiApp) {
                defaultColumns.push({
                    label: 'Application',
                    prop: 'appDisplayName',
                    sortBy: (row) => row.app.displayName,
                    sortable: true
                });
            }

            defaultColumns.push(
                {
                    label: 'Created By',
                    prop: 'createdByUser.username',
                    sortable: true
                },
                {
                    label: 'Last Updated By',
                    prop: 'lastUpdatedByUser.username',
                    sortable: true
                },
                {
                    label: 'Last Updated Date',
                    prop: 'lastUpdatedAt',
                    sortable: true,
                    formatter: (row) => moment(row.lastUpdatedAt).format(DATE_FORMAT.full)
                },
                {
                    type: 'actions',
                    width: 80
                }
            );

            return mergeDefaultColumnsWithUserSettings(defaultColumns, this.pathsListTableSettings);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.pathsListTableSettings);
        },
        tableFilters () {
            return [
                {
                    prop: ['createdByUser.username'],
                    type: 'exact',
                    value: this.selectedCreatedByFilter.id === 'me' ? this.user.email : ''
                },
                {
                    prop: ['name', 'createdByUser.username', 'lastUpdatedByUser.username', 'app.displayName'],
                    value: this.searchValue
                }
            ];
        },
        tableData () {
            const pathList = this.listByType('Path');

            if (!this.usesMultiApp) return pathList;

            const pathsWithApps = pathList.reduce((result, path) => {
                const appId = get(path, 'definition.config.appId');

                path.app = this.appMapForActiveSubscription[appId];

                return path.app ? result.concat(path) : result;
            }, []);

            if (!this.isAppIdsFilterInUse) return pathsWithApps;

            return pathsWithApps.filter((path) => {
                const appId = get(path, 'definition.config.appId');

                return this.appIdsFilter.includes(appId);
            });
        },
        tableRowActions () {
            return [
                {
                    type: 'copy',
                    icon: 'copy',
                    tooltip: 'Copy'
                },
                this.canDelete && {
                    type: 'delete',
                    icon: 'trash-2',
                    tooltip: 'Delete'
                }
            ].filter(Boolean);
        },
        canShare () {
            return this.user.subscriptionAccessControl.some((permission) => {
                const { entity, action, source, target } = permission;

                return entity === 'report' && action === 'share' && source === '*' && target === '*';
            });
        },
        canDelete () {
            return (
                this.isOwnReport ||
                this.user.subscriptionAccessControl.some((permission) => {
                    const { entity, action, source, target } = permission;

                    return entity === 'report' && action === 'remove' && source === '*' && target === 'shared';
                })
            );
        }
    },
    watch: {
        pathsListCreatedByFilter: {
            handler () {
                this.selectedCreatedByFilter = this.createdByFilterOptions.find(
                    (option) => option.id === this.pathsListCreatedByFilter
                );
            },
            immediate: true
        }
    },
    async created () {
        this.isLoading = true;
        await this.loadAllReports();
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            loadAllReports: 'reports/loadAll',
            createReport: 'reports/create',
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        async duplicatePath (path) {
            const newReport = cloneDeep(path);
            delete newReport.id;
            newReport.name = `Copy Of ${newReport.name}`;

            if (!this.canShare) {
                newReport.shared = false;
            }

            await this.createReport({ report: newReport });

            this.$router
                .push({
                    name: 'path',
                    params: {
                        id: this.activeReport.id
                    }
                })
                .catch(() => {});
        },
        showDeletePathModal (path) {
            this.pendingPathToDelete = path;
            this.isPathDeleteModalVisible = true;
        },
        onPathDeleteModalClose () {
            this.isPathDeleteModalVisible = false;
            this.pendingPathToDelete = {};
        },
        onSelectCreatedByFilter (option) {
            this.selectedCreatedByFilter = option;
            this.updateUserSetting({
                name: 'pathsListCreatedByFilter',
                value: this.selectedCreatedByFilter.id
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.paths-dashboard__filter-bar {
    .paths-dashboard__search-input {
        margin-left: auto;
    }
}
</style>
