<template>
    <pendo-table
        v-pendo-loading:feather="loading"
        :data="countablesMappings"
        :columns="columns"
        :max-height="2000"
        row-key="sourceCountable"
        :empty-text="`All ${countableTitle}s have been mapped`"
        :class="`select-${kind}-mappings`"
        class="select-countable-mappings"
        :title="`${countableTitle} Mapping`">
        <template #header>
            <div>
                {{ countableTitle }} Mapping
                <pendo-icon
                    v-pendo-tooltip="countableMappingTooltip"
                    display="inline"
                    type="info"
                    size="14" />
            </div>
        </template>
        <template #hasArrow="{ row }">
            <pendo-icon
                v-if="row.targetCountables"
                type="arrow-right" />
            <div v-else />
        </template>
        <template #targetCountable="{ row }">
            <pendo-multiselect
                v-model="row.targetCountable"
                v-pendo-tooltip="automaticallyCreateNewCountablesTooltip"
                :class="`select-${kind}-dropdown`"
                :group-options-key="kind === 'feature' ? 'countables' : null"
                :group-label-key="kind === 'feature' ? 'label' : null"
                :loading="loading"
                :options="row.targetCountables"
                :min-trigger-width="220"
                :max-trigger-width="220"
                :placeholder="`-- Choose ${countableTitle} --`"
                :format-option-label="handleCountableOptions"
                @input="updateMappings($event, row)">
                <template #header="{ select }">
                    <div class="step-countable-selector__header">
                        <div
                            class="step-countable-selector__option"
                            @click="select(createCountableOnTargetAppOption())">
                            <span v-pendo-tooltip="createNewCountableDescriptionTooltip">
                                Create New {{ countableTitle }} (from Existing {{ countableTitle }} Rule)
                            </span>
                        </div>
                    </div>
                </template>
            </pendo-multiselect>
        </template>
    </pendo-table>
</template>

<script>
import { PendoMultiselect, PendoIcon, PendoTooltip, PendoTable, PendoLoading } from '@pendo/components';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';

const TOOLTIP_CONFIG = {
    multiline: true,
    trigger: 'hover',
    placement: 'right',
    arrow: true
};

export default {
    name: 'SelectCountableMappings',
    components: {
        PendoMultiselect,
        PendoIcon,
        PendoTable
    },
    directives: {
        PendoTooltip,
        PendoLoading
    },
    props: {
        targetApplication: {
            type: Object,
            default: undefined
        },
        countablesMappings: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        kind: {
            type: String,
            required: true
        }
    },
    computed: {
        columns () {
            return [
                {
                    sortable: true,
                    prop: 'sourceCountable.displayName',
                    label: `Source ${this.countableTitle}`,
                    allowResize: true,
                    minWidth: 200
                },
                {
                    prop: 'hasArrow',
                    slot: 'hasArrow',
                    sortable: false,
                    visible: true,
                    allowResize: false,
                    allowReorder: false,
                    fixed: true,
                    width: 50
                },
                {
                    sortable: true,
                    prop: 'targetCountable',
                    slot: 'targetCountable',
                    label: `Target ${this.countableTitle}`,
                    allowResize: true,
                    minWidth: 200
                }
            ];
        },
        countableMappingTooltip () {
            return {
                ...TOOLTIP_CONFIG,
                content: `${this.countableTitle}s in the "Source ${this.countableTitle}" column don't exist in your target application. Map these ${this.kind}s to new or existing ${this.kind}s in your target application.`
            };
        },
        createNewCountableDescriptionTooltip () {
            let content = `A new ${this.kind} will be created in ${this.targetApplication.displayName} to match the ${this.kind} in your source application. If the relevant ${this.kind} already exists, please select it from the list.`;
            if (this.kind === 'feature') {
                content += ` If the feature is specific to a non-existing page, we will automatically create that page in ${this.targetApplication.displayName} and link it to the feature.`;
            }

            return {
                ...TOOLTIP_CONFIG,
                content
            };
        },
        automaticallyCreateNewCountablesTooltip () {
            if (this.countablesMappings.length) return { content: '' };

            return {
                ...TOOLTIP_CONFIG,
                content: `The target application does not have any ${this.kind}s. New ${this.kind}(s) will be created in ${this.targetApplication.displayName} based on the source ${this.kind}(s)' rules.`
            };
        },
        createNewCountableTooltip () {
            return {
                ...TOOLTIP_CONFIG,
                content: `Create New ${this.countableTitle} (from Existing ${this.countableTitle} Rule)`
            };
        },
        countableTitle () {
            return capitalize(this.kind);
        }
    },
    methods: {
        createCountableOnTargetAppOption () {
            return {
                createNew: true,
                name: `Create New ${this.countableTitle} (from Existing ${this.countableTitle} Rule)`
            };
        },
        updateMappings () {
            this.$emit('mappingUpdate', this.countablesMappings);
        },
        handleCountableOptions (option) {
            return get(option, 'trainingSettings.displayName', option.name);
        }
    }
};
</script>

<style scoped lang="scss">
.step-countable-selector__header {
    height: 40px;
    border-bottom: 1px solid $gray-lighter-5;
    display: flex;
    align-items: center;
    justify-content: center;

    .step-countable-selector__option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        height: 26px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background-color: $gray-lighter-7;
        }

        &.is-selected {
            background-color: $gray-lighter-6;
        }
    }
}
</style>
