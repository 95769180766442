import { sendPendoTrackEvent } from '@/utils/utils';

const MESSAGE_TIMEOUT = 30000;

export const previewScreenTypes = Object.freeze({
    SAME_TAB: 'sameTab',
    NEW_TAB: 'newTab',
    NEW_WINDOW: 'newWindow'
});

export function getAppWindowCallback (screenType, { height, width } = { height: 600, width: 800 }) {
    switch (screenType) {
        case previewScreenTypes.NEW_WINDOW:
            return function (launchUrl) {
                return window.open(launchUrl, '_blank', `height=${height},width=${width},popup=1,resizable=1`);
            };
        case previewScreenTypes.SAME_TAB:
            return function (launchUrl) {
                return window.open(launchUrl, '_self');
            };
        case previewScreenTypes.NEW_TAB:
        default:
            return function (launchUrl) {
                return window.open(launchUrl, '_blank');
            };
    }
}

export function launchPreview (
    launchUrl,
    agentConfig,
    { screenType = previewScreenTypes.NEW_TAB, height = 600, width = 800 } = {}
) {
    const getAppWindow = getAppWindowCallback(screenType, { height, width });
    const appWindow = getAppWindow(launchUrl);

    if (previewScreenTypes.NEW_WINDOW !== screenType) {
        width = window.innerWidth;
        height = window.innerHeight;
    }

    const eventBody = {
        trackEventKey: 'launchPreview',
        properties: { ux: 'One UI', windowType: screenType, width, height },
        adoptSpecific: false
    };

    const message = {
        type: 'pendo-preview::launch',
        config: agentConfig
    };

    return new Promise((resolve, reject) => {
        window.addEventListener('message', listener);

        const failureTimeout = setTimeout(() => {
            window.removeEventListener('message', listener);
            reject(new Error('Did not receive response from app window'));
        }, MESSAGE_TIMEOUT);

        function listener (msg) {
            if (new URL(msg.origin).hostname !== new URL(launchUrl).hostname) return;

            if (msg.data.type === 'pendo::ready') {
                appWindow.postMessage(message, launchUrl);
            } else if (msg.data.type === 'pendo-preview::ready') {
                sendPendoTrackEvent(eventBody);
                clearTimeout(failureTimeout);
                window.removeEventListener('message', listener);
                resolve(msg.data);
            }
        }
    });
}
