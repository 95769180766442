<template>
    <div
        class="workflows-tag-row"
        data-cy="workflows-query-tags">
        <workflow-query-viewer-tag
            v-if="shouldShow.start && startLabel !== ''"
            data-cy="workflows-start-tag"
            icon="log-out"
            :value="startLabel" />

        <workflow-query-viewer-tag
            v-if="shouldShow.end && endLabel !== ''"
            data-cy="workflows-end-tag"
            icon="log-in"
            :value="endLabel" />

        <workflow-query-viewer-tag
            v-if="shouldShow.maxDuration && maxDurationLabel !== ''"
            data-cy="workflows-maxDuration-tag"
            icon="clock"
            :value="maxDurationLabel" />

        <workflow-query-viewer-tag
            v-if="shouldShow.recurring && recurringLabel !== ''"
            data-cy="workflows-recurring-tag"
            :icon="recurringIcon"
            :value="recurringLabel" />

        <workflow-query-viewer-tag
            v-if="shouldShow.timeSeries && timeSeriesLabel !== ''"
            data-cy="workflows-time-series-tag"
            icon="calendar"
            :value="timeSeriesLabel" />

        <workflow-query-viewer-tag
            v-if="shouldShow.segment && segmentLabel !== ''"
            data-cy="workflows-segment-tag"
            icon="users"
            :value="segmentLabel" />
    </div>
</template>

<script>
import { maxDurationOptions } from '@/utils/workflows';
import WorkflowQueryViewerTag from './WorkflowQueryViewerTag';
import get from 'lodash/get';

export default {
    name: 'WorkflowQueryViewer',
    components: {
        WorkflowQueryViewerTag
    },
    props: {
        savedWorkflow: {
            type: Object,
            default: () => ({})
        },
        startLabel: {
            type: String,
            required: true
        },
        endLabel: {
            type: String,
            required: true
        },
        showConfig: {
            type: Object,
            default: () => ({})
        },
        segmentLabel: {
            type: String,
            default: ''
        },
        timeSeriesLabel: {
            type: String,
            default: ''
        }
    },
    computed: {
        shouldShow () {
            return {
                start: true,
                end: true,
                maxDuration: true,
                recurring: true,
                timeSeries: false,
                segment: false,
                ...this.showConfig
            };
        },
        maxDurationLabel () {
            const maxDuration = get(this.savedWorkflow, 'maxDuration', null);
            if (!maxDuration) return '';

            const durationOptions = maxDurationOptions.flatMap((opt) => opt.options);
            const duration = durationOptions.find((option) => option.id === maxDuration);

            return duration ? duration.label : '';
        },
        recurringLabel () {
            const classification = get(this.savedWorkflow, 'classification', null);
            if (!classification) return '';

            return classification === 'recurring' ? 'Recurring process' : 'Not recurring';
        },
        recurringIcon () {
            return get(this.savedWorkflow, 'classification', 'non-recurring') === 'recurring' ? 'repeat' : 'no-repeat';
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-tag-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    row-gap: 8px;
}
</style>
