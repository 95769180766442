import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import { http } from '@pendo/http';
import { getFeatureList, getFeatureActivity } from '@/aggregations/feature-activity';
import { filterUnsharedResources } from '@/utils/modules';
import { addAppToEntityList } from '@/utils/apps';
import { isCancel } from 'axios';
import { canCreateCountable, canAppCreateCountable } from '@/utils/countable-permissions';
import { entityIdToNameMap } from '@/stateless-components/utils/guides';

let fetchPromise;

export function getInitialState () {
    return {
        isFetching: false,
        isFetchingWithAnalytics: false,
        map: {},
        mapWithAnalytics: {},
        activeSubHasAnyFeatures: false,
        isDeletingFeature: false,
        error: null,
        aggCancel: null,
        isFeatureDetailsSidebarOpen: false
    };
}

export const state = getInitialState();

export const mutations = {
    setMap (state, { map }) {
        state.map = map;
    },
    setMapWithAnalytics (state, { mapWithAnalytics }) {
        state.mapWithAnalytics = mapWithAnalytics;
    },
    setActiveSubHasAnyFeatures (state, { activeSubHasAnyFeatures }) {
        state.activeSubHasAnyFeatures = activeSubHasAnyFeatures;
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setFetchingWithAnalytics (state, { isFetchingWithAnalytics }) {
        state.isFetchingWithAnalytics = isFetchingWithAnalytics;
    },
    setDeletingFeature (state, { isDeletingFeature }) {
        state.isDeletingFeature = isDeletingFeature;
    },
    setError (state, { error }) {
        state.error = error;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    },
    setAggCancel (state, { aggCancel }) {
        state.aggCancel = aggCancel;
    },
    setFeatureDetailsSidebarOpen (state, { isFeatureDetailsSidebarOpen }) {
        state.isFeatureDetailsSidebarOpen = isFeatureDetailsSidebarOpen;
    }
};

export const actions = {
    async fetch ({ commit, state, rootGetters }, { noCache = false } = {}) {
        if (state.isFetching) {
            await fetchPromise;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });

        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];

        if (usesMultiApp) {
            fetchPromise = fetchAllFeaturesForSubscription();
        } else {
            fetchPromise = getFeatureList({
                appId: rootGetters['apps/activeId']
            });
        }

        const list = await fetchPromise;

        // to determine empty state CTA, need to know if any features have been tagged even outside the filter
        commit('setActiveSubHasAnyFeatures', { activeSubHasAnyFeatures: !!list.length });

        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const featuresForAvailableApps = list.slice().filter((feature) => !!appMapForActiveSubscription[feature.appId]);

        const displayNameFormattedList = featuresForAvailableApps.map((feature) => {
            const displayName = get(feature, 'trainingSettings.displayName', feature.name);
            feature.displayName = displayName;

            return feature;
        });

        commit('setMap', { map: keyBy(displayNameFormattedList, 'id') });
        commit('setFetching', { isFetching: false });
    },

    async fetchWithAnalytics ({ commit, state, rootState, rootGetters }, { noCache = false } = {}) {
        if (rootState.filters.isFetchingFeaturesFromChartClick) {
            return;
        }

        if (state.aggCancel) {
            state.aggCancel.abort();
        }

        if (!isEmpty(state.mapWithAnalytics) && !noCache) {
            return;
        }

        commit('setAggCancel', { aggCancel: new AbortController() });
        commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: true });

        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const { activeSegmentId, appIdsFilter } = rootState.filters;
        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        const appId = usesMultiApp ? appIdsFilter : rootGetters['apps/activeId'];

        let list = [];

        try {
            list = await getFeatureActivity({
                appId,
                timeSeries,
                segmentId: activeSegmentId,
                signal: get(state, 'aggCancel.signal')
            });
            commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
        } catch (err) {
            if (!isCancel(err)) {
                commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
            }
        }

        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const featuresForAvailableApps = list.filter((feature) => !!appMapForActiveSubscription[feature.appId]);
        const displayNameFormattedList = featuresForAvailableApps.map((feature) => {
            const displayName = get(feature, 'trainingSettings.displayName', feature.name);
            const description = get(feature, 'trainingSettings.description');
            feature.displayName = displayName;
            feature.description = description;

            return feature;
        });

        commit('setMapWithAnalytics', { mapWithAnalytics: keyBy(displayNameFormattedList, 'id') });
        commit('filters/setIsFetchingFeaturesFromChartClick', false, { root: true });
    },
    fetchUpdatesForFilterChange ({ state, dispatch, rootGetters }) {
        // Don't bother attempting to fetch and reload data if the sub has no features (or has not yet loaded its features)
        if (!state.activeSubHasAnyFeatures) return;

        const clearFeaturesCache = !rootGetters['subscriptions/usesMultiApp'];
        dispatch('fetch', { noCache: clearFeaturesCache });
        dispatch('fetchWithAnalytics', { noCache: true });
    },
    async updateCustomFeature ({ dispatch, commit }, { customFeature }) {
        commit('setError', { error: null });

        try {
            await updateCustomFeature(customFeature);
            dispatch('fetch', { noCache: true });
            dispatch('fetchWithAnalytics', { noCache: true });
        } catch (error) {
            commit('setError', { error });
        }
    },
    async deleteCustomFeature ({ dispatch, commit }, { customFeature }) {
        commit('setError', { err: null });
        commit('setDeletingFeature', { isDeletingFeature: true });

        try {
            await deleteCustomFeature(customFeature);
            dispatch('fetch', { noCache: true });
            dispatch('fetchWithAnalytics', { noCache: true });
        } catch (error) {
            commit('setError', { error });
        }

        commit('setDeletingFeature', { isDeletingFeature: false });
    }
};

export const getters = {
    featureById: (state) => (id) => get(state, `map['${id}']`, null),
    list (state, getters, rootState, rootGetters) {
        const filteredResources = filterUnsharedResources(state.map);

        return addAppToEntityList(filteredResources, rootGetters['apps/appMapForActiveSubscription']);
    },
    listWithAnalytics (state, getters, rootState, rootGetters) {
        const filteredResources = filterUnsharedResources(state.mapWithAnalytics);

        return addAppToEntityList(filteredResources, rootGetters['apps/appMapForActiveSubscription']);
    },
    listAllWithAnalytics (state, getters, rootState, rootGetters) {
        return addAppToEntityList(
            Object.values(state.mapWithAnalytics),
            rootGetters['apps/appMapForActiveSubscription']
        );
    },
    canCreateFeature: (state, getters, rootState, rootGetters) => ({ filtered = true }) => {
        const apps = filtered ? rootGetters['filters/appsForAppIdFilter'] : rootGetters['apps/getListActiveApps'];
        if (rootGetters['subscriptions/activeIsDigitalAdoption']) {
            return canCreateCountable({ apps, countableKind: 'feature' });
        }

        return canAppCreateCountable(rootGetters['apps/activeId'], 'feature');
    },
    namesById (state, getters) {
        return entityIdToNameMap(getters.list);
    }
};

export function fetchAllFeaturesForSubscription () {
    return http.get('/api/s/_SID_/feature?expand=*').then((res) => res.data);
}

export function createCustomFeature (feature, subId = null) {
    const { name, elementPathRules, appId, pageId } = feature;

    if (!subId) {
        return http.post('/api/s/_SID_/feature', { name, elementPathRules, appId, pageId }).then((res) => res.data);
    }

    return http.post(`/api/s/${subId}/feature`, { name, elementPathRules, appId, pageId }).then((res) => res.data);
}

function updateCustomFeature (feature) {
    return http.put(`/api/s/_SID_/feature/${feature.id}`, feature).then((res) => res.data);
}

function deleteCustomFeature (customFeature) {
    const { featureId, appId } = customFeature;

    return http.delete(`/api/s/_SID_/feature/${featureId}`, { appId });
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
