var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"metadata-list"},[_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.loading),expression:"loading",arg:"feather"}],staticClass:"metadata-list--table",attrs:{"data":_vm.data,"columns":_vm.columns,"max-height":10000,"title":_vm.title,"row-key":"name"},scopedSlots:_vm._u([(_vm.canEdit)?{key:"value",fn:function({ row }){return [(_vm.isSchemaFloatOrString(row.schema))?_c('pendo-editable-content',{staticClass:"editable-input",attrs:{"allow-empty":"","value":_vm.convertValueToString(row.value),"validation-rules":_vm.rules[row.schema],"empty-text":"Click to Edit"},on:{"confirm":function($event){return _vm.updateCustomValue($event, row.field)}},scopedSlots:_vm._u([{key:"append",fn:function({ pending, confirm, cancel }){return [_c('pendo-button',{attrs:{"theme":"app","icon":"check","data-cy":"confirm","loading":pending,"type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"data-cy":"cancel","theme":"app","icon":"x","type":"secondary"},on:{"click":cancel}})]}}],null,true)}):_vm._e(),(row.schema === 'integer')?_c('editable-number-input',{attrs:{"value":row.value},on:{"confirm":function($event){return _vm.updateCustomValue($event, row.field)}}}):_vm._e(),(row.schema === 'boolean')?_c('pendo-checkbox',{on:{"change":function($event){return _vm.updateCustomValue($event, row.field)}},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}):_vm._e(),(row.schema === 'time')?_c('pendo-date-picker',{on:{"select-date":function($event){return _vm.updateCustomValue($event, row.field)}},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}):_vm._e(),(!row.schema)?_c('div',{staticClass:"metadata-table-cell--text"},[_vm._v(" "+_vm._s(row.value)+" "),_c('pendo-icon',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                        arrow: true,
                        content: _vm.metadataNoTypeHelpText
                    }),expression:"{\n                        arrow: true,\n                        content: metadataNoTypeHelpText\n                    }"}],attrs:{"fill":"#dba211","size":"18","stroke":"#fff","type":"alert-circle"}})],1):_vm._e()]}}:(_vm.isAdmin)?{key:"value",fn:function({ row }){return [(row.field === 'lastVisitAppId')?_c('pendo-app-display',{attrs:{"apps":_vm.appById(row.value)}}):_c('div',{staticClass:"metadata-table-cell--text"},[_vm._v(" "+_vm._s(_vm.formatRowValue(row))+" "),(!row.schema)?_c('pendo-icon',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                        arrow: true,
                        content: _vm.metadataNoTypeHelpText
                    }),expression:"{\n                        arrow: true,\n                        content: metadataNoTypeHelpText\n                    }"}],attrs:{"fill":"#dba211","size":"18","stroke":"#fff","type":"alert-circle"}}):_vm._e()],1)]}}:null,{key:"empty",fn:function(){return [_c('div',{staticClass:"metadata-list--table--empty"},[_c('pendo-icon',{staticClass:"empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"empty-text"},[_vm._v(" No data found. ")])],1)]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }