<template>
    <div class="edit-authored-lang">
        <pendo-button
            theme="app"
            type="link"
            label="Edit"
            size="mini"
            @click="showModal = true" />
        <pendo-modal
            :click-to-close="!isSaving"
            :height="modalHeight"
            :visible="showModal"
            :show-close="false"
            title="Edit Authored Language"
            @close="closeModal">
            <template #body>
                <pendo-alert
                    v-if="serverErrorMessage"
                    type="error"
                    :title="serverErrorMessage" />
                <pendo-alert
                    v-if="hasTranslatedLanguage"
                    type="warning"
                    :title="hasTranslatedLanguageAlertTitle" />
                <span class="edit-info">
                    {{ modalWarningMessage }}
                </span>
                <pendo-multiselect
                    v-model="selectedAuthoredLanguage"
                    class="edit-authored-lang__dropdown"
                    :popper-options="{ class: 'edit-authored-lang__dropdown-panel' }"
                    :options="languageOptions"
                    value-key="code"
                    label-key="name"
                    preselect-first
                    :allow-empty="false"
                    :labels="{ top: 'Authored language' }" />
            </template>
            <template #footer>
                <pendo-button
                    type="secondary"
                    theme="app"
                    label="Cancel"
                    @click="closeModal" />
                <pendo-button
                    type="primary"
                    theme="app"
                    :loading="isSaving"
                    label="Save Changes"
                    @click="updateAuthoredLanguage" />
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PendoAlert, PendoMultiselect, PendoModal, PendoButton } from '@pendo/components';
import get from 'lodash/get';

export default {
    name: 'EditAuthoredLanguage',
    components: {
        PendoMultiselect,
        PendoAlert,
        PendoButton,
        PendoModal
    },
    props: {
        languages: {
            type: Array,
            required: true
        },
        authoredLanguage: {
            type: Object,
            required: true
        },
        guide: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            showModal: false,
            serverErrorMessage: null,
            isSaving: false,
            selectedAuthoredLanguage: this.authoredLanguage
        };
    },
    computed: {
        ...mapState({
            errorOnResourceCenterUpdate: (state) => state.resourceCenter.error
        }),
        hasTranslatedLanguage () {
            return this.languages
                .filter((language) => language.code !== this.authoredLanguage.code)
                .some((language) => language.translatedState);
        },
        isResourceCenter () {
            return (
                get(this, 'guide.draft.homeView.attributes.resourceCenter.isTopLevel', false) &&
                get(this, 'guide.draft.homeView.attributes.type') === 'building-block'
            );
        },
        languageOptions () {
            const sortedLanguages = [];
            for (const language of this.languages) {
                if (language.code === this.authoredLanguage.code) {
                    sortedLanguages.unshift(language);
                } else {
                    sortedLanguages.push(language);
                }
            }

            return sortedLanguages;
        },
        modalHeight () {
            return `${310 + (this.hasTranslatedLanguage ? 70 : 0) + (this.serverErrorMessage ? 50 : 0)}px`;
        },
        modalWarningMessage () {
            return `Changing the authored language will only affect this ${this.guideType}.`;
        },
        hasTranslatedLanguageAlertTitle () {
            return `Changing the authored language will remove all the imported translations for this ${this.guideType}`;
        },
        guideType () {
            return this.isResourceCenter ? 'resource center' : 'guide';
        }
    },
    methods: {
        ...mapActions({
            patchGuide: 'guides/patch',
            patchResourceCenter: 'resourceCenter/updateResourceCenterAuthoredLanguage'
        }),
        closeModal () {
            if (!this.isSaving) {
                this.showModal = false;
            }
        },
        async updateAuthoredLanguage () {
            this.isSaving = true;
            let isFailure = false;
            this.serverErrorMessage = null;

            if (this.isResourceCenter) {
                await this.patchResourceCenter({
                    homeViewId: this.guide.draft.homeView.id,
                    authoredLanguage: this.selectedAuthoredLanguage.code
                });
                if (this.errorOnResourceCenterUpdate) isFailure = true;

                this.$nextTick();
            } else {
                await this.patchGuide({
                    guideId: this.guide.id,
                    props: { authoredLanguage: this.selectedAuthoredLanguage.code }
                }).catch(() => {
                    isFailure = true;
                });
            }

            if (isFailure) this.serverErrorMessage = 'There was a server error. Please try again.';
            this.isSaving = false;
            this.closeModal();
        }
    }
};
</script>

<style scoped lang="scss">
.edit-authored-lang {
    display: inline-block;
    font-weight: normal;
}

.edit-info {
    display: block;
    margin-bottom: 16px;
}

::v-deep .pendo-alert {
    margin-bottom: 14px;
    padding: 10px;
}
</style>
