<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 675.5 112"
        style="enable-background:new 0 0 675.5 112;"
        xml:space="preserve">
        <path
            :fill="fill"
            d="M415.1,14.9l-21.7,65.7h14.8l5.2-16.3h27.5l5.3,16.3h15.3l-22.5-65.7H415.1z M417.2,52.1l8-25.1h3.3l8.2,25.1
            H417.2z" />
        <path
            :fill="fill"
            d="M500.6,38.1c-1.4-2.3-3.4-4.2-5.7-5.6c-3.1-1.8-6.7-2.7-10.3-2.6c-3.3,0-6.5,0.6-9.5,1.9
            c-2.8,1.3-5.2,3.1-7.2,5.4c-2,2.3-3.6,5.1-4.6,8c-1.1,3.2-1.6,6.5-1.6,9.9v2.1c0,3.4,0.5,6.7,1.7,9.9c1,3,2.7,5.7,4.8,8
            c2.1,2.2,4.6,4,7.4,5.3c3,1.3,6.2,1.9,9.5,1.9c3.6,0.1,7.2-0.8,10.4-2.6c3.1-1.8,5.5-4.4,7.2-7.5c0.3-0.7,0.7-1.3,0.9-2v10.5H515
            V14.9h-14.5V38.1z M500.9,57.4c0.1,2.4-0.5,4.8-1.7,6.9c-1,1.8-2.6,3.4-4.4,4.4c-1.9,1-4,1.5-6.1,1.5c-2.3,0-4.5-0.6-6.5-1.7
            c-1.9-1.2-3.5-2.8-4.5-4.9c-1.1-2.3-1.7-4.9-1.6-7.5c-0.1-2.6,0.5-5.1,1.6-7.4c1-2,2.6-3.8,4.5-5c1.9-1.2,4.2-1.9,6.4-1.8
            c2.1,0,4.1,0.5,6,1.4c1.8,0.9,3.4,2.4,4.5,4.1c1.2,2,1.8,4.3,1.7,6.7L500.9,57.4z" />
        <path
            :fill="fill"
            d="M568.7,37.2c-2.4-2.4-5.3-4.3-8.5-5.5c-7.3-2.7-15.3-2.7-22.6,0c-3.2,1.2-6.1,3.1-8.6,5.5
            c-2.3,2.3-4.2,5.1-5.4,8.1c-1.2,3.1-1.9,6.4-1.8,9.8v2.1c0,3.3,0.6,6.5,1.8,9.6c1.2,3,3,5.8,5.3,8.1c2.4,2.4,5.3,4.3,8.5,5.5
            c7.5,2.7,15.7,2.7,23.1,0c3.2-1.2,6.1-3.1,8.5-5.5c2.3-2.3,4.1-5,5.3-8.1c1.2-3.1,1.8-6.3,1.8-9.6v-2.1c0-3.3-0.6-6.7-1.9-9.8
            C572.8,42.3,571,39.5,568.7,37.2z M560.1,63.3c-0.9,2-2.4,3.8-4.3,5c-4.4,2.4-9.6,2.4-14,0c-1.9-1.2-3.4-2.9-4.3-5
            c-1-2.3-1.5-4.7-1.4-7.2c-0.1-2.6,0.5-5.1,1.5-7.4c0.9-2.1,2.5-3.8,4.4-5.1c4.2-2.5,9.5-2.5,13.7,0c1.9,1.3,3.4,3,4.4,5.1
            c1.1,2.3,1.6,4.9,1.5,7.4C561.6,58.6,561.1,61.1,560.1,63.3z" />
        <path
            :fill="fill"
            d="M629.8,37c-2-2.2-4.4-4-7.2-5.3c-2.9-1.3-6.1-1.9-9.3-1.9c-3.6-0.1-7.2,0.8-10.3,2.6c-3.1,1.8-5.7,4.4-7.3,7.7
            c-0.6,1.2-1.1,2.4-1.5,3.6V31.3h-11.4v67.3h14.4V74.5c1.6,2.3,3.7,4.2,6.2,5.5c3.1,1.6,6.5,2.3,10,2.3c3.3,0.1,6.6-0.6,9.6-1.9
            c2.8-1.2,5.2-3.1,7.2-5.3c2-2.4,3.5-5.1,4.5-8c1-3.2,1.5-6.6,1.5-10v-2.1c0-3.4-0.5-6.7-1.6-9.9C633.4,42.1,631.8,39.3,629.8,37z
            M619.9,63.3c-0.9,2-2.4,3.8-4.3,5c-1.9,1.2-4.2,1.9-6.4,1.8c-2.1,0-4.1-0.5-5.9-1.4c-1.9-1-3.6-2.4-4.7-4.2
            c-1.2-1.9-1.9-4.2-1.8-6.5v-3.4c-0.1-2.4,0.5-4.8,1.8-6.9c1.1-1.9,2.7-3.4,4.6-4.4c1.9-1,4-1.5,6.1-1.5c2.3-0.1,4.5,0.5,6.5,1.8
            c1.8,1.2,3.3,2.9,4.2,4.9c1.1,2.3,1.6,4.8,1.5,7.4C621.5,58.5,621,61,619.9,63.3L619.9,63.3z" />
        <path
            :fill="fill"
            d="M671.8,41.8V31.3h-14.4V18H644v13.3h-7.5v10.5h7.5v19.5c0,5,0.7,8.9,2.1,11.8c1.3,2.9,3.8,5.1,6.7,6.2
            c3.9,1.4,8,2,12.1,1.8h6.9V69.1h-7.3c-1.9,0.1-3.8-0.5-5.3-1.8c-1.3-1.5-2-3.4-1.8-5.3V41.8H671.8z" />
        <path
            :fill="fill"
            d="M119.4,31.4c-6.2,0-12.1,2.3-16.6,6.5v-6.6h-7.9v65.8h7.9V73.8c9.9,9.2,25.4,8.6,34.6-1.3s8.6-25.4-1.3-34.6
            C131.5,33.7,125.5,31.4,119.4,31.4z M136,55.8c0,9.2-7.4,16.6-16.6,16.6s-16.6-7.4-16.6-16.6c0-9.2,7.4-16.6,16.6-16.6c0,0,0,0,0,0
            C128.6,39.3,136,46.7,136,55.8z" />
        <path
            :fill="fill"
            d="M174.3,31.3c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5c7.6,0,14.5-2.8,19-7.8l2.4-2.6l0.3-0.3l-0.3-0.3
            l-5.1-4.7l-0.3-0.3l-0.3,0.3l-2.4,2.6c-3,3.3-8,5.2-13.2,5.2c-7.6,0-14.3-5.2-16.1-12.7h39.9v-3.9C198.2,42.1,187.7,31.3,174.3,31.3
            z M158.2,51.9c1.8-7.4,8.5-12.6,16.1-12.7c7.5,0,14,5.3,15.5,12.7H158.2z" />
        <path
            :fill="fill"
            d="M227.9,31.3c-5.6,0-11.1,2.2-15.2,6v-6h-7.9v49.3h7.9V55.5c0-9,6.8-16.3,15.2-16.3s15.2,7.3,15.2,16.3v25.2h7.9
            V55.5C250.9,42.2,240.6,31.3,227.9,31.3z" />
        <path
            :fill="fill"
            d="M299.1,14.9h-0.5v23.3c-9.9-9.2-25.4-8.6-34.6,1.3c-9.2,9.9-8.6,25.4,1.3,34.6c9.4,8.7,23.9,8.7,33.2,0v6.6h7.9
            V14.9H299.1L299.1,14.9z M298.6,56.1c0,9.2-7.4,16.6-16.6,16.6c-9.2,0-16.6-7.4-16.6-16.6c0-9.2,7.4-16.6,16.6-16.6
            C291.2,39.5,298.6,46.9,298.6,56.1C298.6,56.1,298.6,56.1,298.6,56.1L298.6,56.1z" />
        <path
            :fill="fill"
            d="M337.8,31.7c-13.5,0-24.5,10.9-24.5,24.5c0,13.5,10.9,24.5,24.4,24.5c13.5,0,24.5-10.9,24.5-24.5c0,0,0,0,0,0
            C362.2,42.6,351.3,31.7,337.8,31.7z M354.4,56.1c0,9.2-7.4,16.6-16.6,16.6s-16.6-7.4-16.6-16.6s7.4-16.6,16.6-16.6c0,0,0,0,0,0
            C346.9,39.5,354.3,47,354.4,56.1z" />
        <component
            :is="activeChevron"
            :x="3"
            :y="23"
            :width="74"
            :height="74" />
    </svg>
</template>

<script>
import { CHEVRONS, activeChevronMixin } from './ActiveChevronMixin';

export default {
    components: {
        ...CHEVRONS
    },
    mixins: [activeChevronMixin],
    props: {
        textColor: {
            type: String,
            default: 'dark',
            validator: (textColor) => ['light', 'dark'].includes(textColor)
        }
    },
    computed: {
        fill () {
            if (this.textColor === 'light') {
                return '#FFFFFF';
            }

            return '#0F0D1A';
        }
    }
};
</script>
