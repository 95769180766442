var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"pendo-affix",rawName:"v-pendo-affix",value:({
        fixedStyles: {
            'left': 0,
            'right': 0,
            'padding': '0 0 0 80px',
            'z-index': 4
        }
    }),expression:"{\n        fixedStyles: {\n            'left': 0,\n            'right': 0,\n            'padding': '0 0 0 80px',\n            'z-index': 4\n        }\n    }"}],staticClass:"journeys-filter-bar"},[_c('pendo-filter-bar',{scopedSlots:_vm._u([{key:"bottom",fn:function(){return [(!_vm.hasJourneyData)?_c('div',{staticClass:"journeys-filter-bar--bottom"},[_c('pendo-multiselect',{attrs:{"id":"must-include-select","multiple":"","searchable":false,"clear-on-select":false,"close-on-select":false,"min-menu-width":300,"max-menu-height":368,"options":_vm.formattedPageFeatureOptions,"group-options-key":"contents","group-label-key":"appName","value-key":"id"},scopedSlots:_vm._u([{key:"header",fn:function({ updateInputValue, inputValue }){return [_c('div',{staticClass:"must-include-search-container"},[_c('pendo-multiselect-input',{staticClass:"must-include-search",attrs:{"input-value":inputValue,"is-search-icon-visible":true,"computed-placeholder":"Search Pages & Features...","update-input-value":updateInputValue}})],1)]}},(_vm.includedStepsList.length)?{key:"beforeList",fn:function(){return [_c('div',{staticClass:"selected-header"},[_c('p',{staticClass:"must-include-header"},[_vm._v(" Must include ("+_vm._s(_vm.includedStepsList.length)+") ")]),_c('pendo-button',{attrs:{"type":"link","label":"Clear","size":"mini"},on:{"click":_vm.clearIncludeSteps}})],1),_c('div',{staticClass:"selected-options"},_vm._l((_vm.includedStepsList),function(option,idx){return _c('pendo-checkbox-option',{key:idx,attrs:{"option":option},on:{"change":function($event){return _vm.removeIncludedStep(option)}}})}),1),_c('pendo-divider',{attrs:{"height":"12px"}})]},proxy:true}:null,{key:"trigger",fn:function({ toggleMenu, isOpen }){return [_c('div',{class:[
                                'must-include-trigger',
                                isOpen ? 'must-include-open' : '',
                                _vm.loading ? 'disabled-dropdown' : ''
                            ],on:{"click":function($event){!_vm.loading ? toggleMenu() : null}}},[_c('pendo-icon',{staticClass:"must-star-icon",attrs:{"size":"14","fill":"white","stroke":_vm.loading ? '#6A6C75' : '#2A2C35',"type":"check-circle-2"}}),_c('p',{staticClass:"must-include-text"},[_vm._v(" Must include ("+_vm._s(_vm.includedStepsList.length)+") ")]),(!isOpen)?_c('pendo-icon',{attrs:{"size":"9","type":"chevron-down"}}):_vm._e(),(isOpen)?_c('pendo-icon',{attrs:{"size":"9","type":"chevron-up"}}):_vm._e()],1)]}},{key:"option",fn:function({ option }){return [_c('pendo-checkbox-option',{attrs:{"option":option}})]}}],null,true),model:{value:(_vm.includedStepsList),callback:function ($$v) {_vm.includedStepsList=$$v},expression:"includedStepsList"}}),_c('p',{staticClass:"filtered-content-dialogue"},[_vm._v(" "+_vm._s(_vm.filteredPercentage)+" ")])],1):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"journeys-filter-bar--top"},[_c('div',{staticClass:"journeys-filter-bar--top-filters"},[_c('div',{staticClass:"journeys-filter-bar--top-filters-item"},[_c('segment-chooser',{staticClass:"journeys-filter-bar--segment-chooser",attrs:{"full-width":"","data-cy":"journeys-segment-filter","guide-targeting":false,"disabled":false,"value":_vm.activeSegmentId},on:{"input":_vm.onSegmentUpdated}})],1),_c('div',{staticClass:"journeys-filter-bar--top-filters-item"},[_c('date-range-picker',{staticClass:"journeys-filter-bar--date-range-picker",attrs:{"full-width":"","data-cy":"journeys-date-range-filter","first-visit":_vm.firstVisit,"range-limiter":90,"value":_vm.dateRange},on:{"change":_vm.onDateRangeUpdated}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }