<template>
    <div>
        <div
            v-pendo-tooltip="!!duplicateTags && duplicateTagsTooltip"
            :class="{
                'journey-node': true,
                'highlighted': highlight
            }"
            :style="{ borderColor, backgroundColor }"
            @mouseover="hover = !isTerminalNode"
            @mouseleave="hover = false">
            <div class="step-count">
                <div
                    v-if="hover || isNonTerminalStarredNode"
                    class="star-step">
                    <pendo-icon
                        class="star-icon"
                        size="16"
                        :fill="mustIncludeIconOptions.fill"
                        :stroke="mustIncludeIconOptions.stroke"
                        :type="mustIncludeIconOptions.type"
                        @click="$emit('includeStep', nodeId, isPage ? 'pageId' : 'featureId')" />
                </div>
                <div
                    v-if="!hover && !isNonTerminalStarredNode"
                    class="step-number"
                    :style="{
                        backgroundColor: isTerminalNode ? defaultTerminalStepColor : accessibleColorMap.medium
                    }">
                    <div v-if="!isTerminalNode">
                        {{ stepCount }}
                    </div>
                    <pendo-icon
                        v-if="!isFinalNode && stepCount === 0"
                        type="log-out"
                        size="16"
                        class="step-icon" />
                    <pendo-icon
                        v-if="isFinalNode"
                        type="log-in"
                        size="16"
                        class="step-icon" />
                </div>
            </div>
            <div class="page-feature-icons">
                <pendo-icon
                    v-if="isPage"
                    size="14"
                    type="file" />
                <pendo-icon
                    v-if="!isPage"
                    size="14"
                    type="mouse-pointer" />
            </div>
            <div class="content">
                <p class="event-name">
                    <!-- eslint-disable vue/no-v-html -->
                    <span v-html="$pendo.sanitize(nodeDisplayName)" />
                </p>

                <div
                    v-pendo-tooltip="{ html: true, content: $pendo.sanitize(appName) }"
                    class="favicon">
                    <img
                        v-if="favicon"
                        class="img"
                        :src="favicon"
                        :alt="appName">
                    <pendo-icon
                        v-if="!favicon && showDefaultIcon"
                        class="icon"
                        size="16"
                        :fill="color"
                        :stroke="color"
                        :type="platformIcon" />
                </div>
            </div>
        </div>

        <div
            v-if="duplicateTags"
            :class="{
                'stacked-middle': !!duplicateTags
            }"
            :style="{
                borderColor,
                backgroundColor
            }" />
        <div
            v-if="duplicateTags"
            :class="{
                'stacked-bottom': !!duplicateTags
            }"
            :style="{
                borderColor,
                backgroundColor
            }" />
    </div>
</template>

<script>
import { PendoIcon, PendoTooltip } from '@pendo/components';
import Color from 'color';
import { accessibleColorMap } from '@/utils/colors';

export default {
    name: 'JourneyNode',
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        stepCount: {
            type: Number,
            required: true
        },
        appName: {
            type: String,
            required: true
        },
        nodeName: {
            type: String,
            required: true
        },
        favicon: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: '#DBA211'
        },
        highlight: {
            type: Boolean,
            default: false
        },
        nodeId: {
            type: String,
            required: true
        },
        isFinalNode: {
            type: Boolean,
            required: true
        },
        isPage: {
            type: Boolean,
            required: true
        },
        isStarred: {
            type: Boolean,
            required: true
        },
        duplicateTags: {
            type: Array,
            default: null
        },
        showDefaultIcon: {
            type: Boolean,
            default: true
        },
        platform: {
            type: String,
            default: 'extension'
        },
        isEngage: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hover: false,
            defaultTerminalStepColor: '#FAE4B8',
            defaultTerminalBackgroundColor: '#F8F8F9',
            defaultTerminalBorderColor: Color('#F8F8F9').darken(0.1),
            highlightedTerminalNodeBackgroundColor: '#EAECF1',
            highlightedTerminalNodeBorderColor: '#6A6C75',
            defaultNodeBackgroundColor: '#FFFFFF',
            defaultColor: '#DBA211'
        };
    },
    computed: {
        isTerminalNode () {
            return this.isFinalNode || this.stepCount === 0;
        },
        isNonTerminalStarredNode () {
            return this.isStarred && !this.isTerminalNode;
        },
        accessibleColorMap () {
            const color = this.isEngage ? this.defaultColor : this.color;

            return accessibleColorMap(color);
        },
        borderColor () {
            if (this.isTerminalNode && this.highlight) {
                return this.highlightedTerminalNodeBorderColor;
            }

            return this.isTerminalNode ? this.defaultTerminalBorderColor : this.accessibleColorMap.dark;
        },
        backgroundColor () {
            if (this.isTerminalNode && this.highlight) {
                return this.highlightedTerminalNodeBackgroundColor;
            }

            if (this.hover || this.isNonTerminalStarredNode || this.highlight) {
                return this.accessibleColorMap.light;
            }

            return this.isTerminalNode ? this.defaultTerminalBackgroundColor : this.defaultNodeBackgroundColor;
        },
        duplicateTagsTooltip () {
            const names = this.duplicateTags.map(
                (step) => `<p style="margin-top:2px;">${this.$pendo.sanitize(step.name)}</p>`
            );

            if (this.isTerminalNode) names.reverse();

            return {
                arrow: true,
                html: true,
                multiline: true,
                show: this.hover,
                content: `<p style="margin:0; font-weight: bold;"> Matches ${
                    this.duplicateTags.length
                } tags:</p> ${names.join('')}`
            };
        },
        nodeDisplayName () {
            const duplicate = this.duplicateTags;
            if (duplicate && this.isTerminalNode) {
                return duplicate[duplicate.length - 1].name;
            }

            return this.nodeName;
        },
        platformIcon () {
            let icon;
            switch (this.platform) {
                case 'web':
                    icon = 'monitor';
                    break;
                case 'extension':
                    icon = 'puzzle-piece';
                    break;
            }

            return icon;
        },
        mustIncludeIconOptions () {
            const { isStarred, accessibleColorMap } = this;

            return {
                fill: isStarred ? accessibleColorMap.dark : 'white',
                stroke: isStarred ? 'white' : accessibleColorMap.dark,
                type: isStarred ? 'check-circle-2-solid' : 'check-circle-2'
            };
        }
    }
};
</script>
<style lang="scss" scoped>
.journey-node {
    display: flex;
    width: 280px;
    min-height: 60px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    padding: 10px 8px;
    font-size: 13px;

    &.highlighted {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);
    }

    .content {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;
        width: 215px;

        .event-name {
            margin: 0;
            max-width: 195px;
            line-height: 150%;
            overflow-wrap: break-word;
        }

        .favicon {
            height: 16px;
            width: 16px;

            .img {
                height: 16px;
                width: auto;
            }
        }
    }
}

.page-feature-icons {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin-right: 4px;

    .step-number {
        display: inherit;
        justify-content: inherit;
        align-items: inherit;
        border-radius: 50%;
        font-weight: 400;
        font-size: 13px;
        background: #fae4b8;
        height: inherit;
        width: inherit;
    }
}

.must-include {
    background: #fdefd5;
}

.start-end-node {
    border: 1px solid #dadce5;
    background: #f8f8f9;
}

.start-end-node:hover {
    background: #eaecf1;
}

.star-icon {
    cursor: pointer;
}

.stacked-middle {
    width: 267.5px;
    height: 4px;
    margin-left: 6.25px;
    border-radius: 0px 0px 4px 4px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
}

.stacked-bottom {
    width: 255px;
    height: 4px;
    margin-left: 12.5px;
    border-radius: 0px 0px 4px 4px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
}
</style>
