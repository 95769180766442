<template>
    <pendo-drawer
        class="copy-guides-drawer"
        :visible="visible"
        size="640px"
        :title="drawerTitle"
        :close-on-mask-click="false"
        @close="handleClose"
        @closed="onDrawerClose"
        @open="onDrawerOpen">
        <template #body>
            <template v-if="isDefaultState">
                <div class="warning-message">
                    <pendo-alert
                        v-if="hasGuidesWithAutomations"
                        type="warning">
                        Guides with automation button actions cannot be copied across subscriptions.<br>
                        <b> Automation guides:</b> {{ guidesNamesWithAutomation.join(', ') }}
                    </pendo-alert>
                    <pendo-alert
                        v-else-if="confirmationGuideNames.length"
                        type="warning">
                        Confirmation guides cannot be copied across subscriptions <br>
                        <b> Confirmation guides:</b> {{ confirmationGuideNames }}
                    </pendo-alert>
                    <pendo-alert
                        v-else-if="ineligibleGuides.length"
                        type="warning">
                        The selected guide(s) have custom segments that must be modified to copy. This means the
                        guide(s) will be visible to <b>Everyone</b> and set to <b>Draft</b> state when copied.
                        <a
                            :href="HELP_ARTICLE"
                            target="_blank">Learn more</a>.
                    </pendo-alert>
                    <pendo-alert
                        v-else
                        type="warning">
                        The selected guide(s) will be set to <b>Draft</b> state when copied.
                        <a
                            :href="HELP_ARTICLE"
                            target="_blank">Learn more</a>.
                    </pendo-alert>
                </div>
                <select-target-location
                    :has-multiple-guides="guides.length > 1"
                    :eligible-subscriptions="eligibleSubscriptions"
                    :active-is-digital-adoption="activeIsDigitalAdoption"
                    :active-is-training-subscription="activeIsTrainingSubscription"
                    @targetsSelected="handleTarget" />
                <select-countable-mappings
                    v-if="showPageMappingsTable"
                    kind="page"
                    :countables-mappings="pageMappings"
                    :target-application="targetApplication"
                    :loading="loading"
                    @mappingUpdate="pageMappings = $event" />
                <select-countable-mappings
                    v-if="showFeatureMappingsTable"
                    kind="feature"
                    :countables-mappings="featureMappings"
                    :target-application="targetApplication"
                    :loading="loading"
                    @mappingUpdate="featureMappings = $event" />
            </template>
            <template v-if="isProgressState">
                <div class="guide-copy-drawer__progress">
                    <pendo-progress-bar
                        ref="copyGuidesProgressBar"
                        class="copy-progress-bar"
                        :colors="progressBarColors"
                        :value="copyStatus.progress"
                        :width="400"
                        :height="8"
                        trickle />
                    <div class="guide-copy-drawer__progress-status">
                        {{ copyStatus.success }} of {{ copyStatus.total }}
                        {{ copyStatus.total > 1 ? 'Guides' : 'Guide' }} copied
                    </div>
                </div>
            </template>
            <template v-if="isResultState">
                <div class="guide-copy-drawer__status-container">
                    <template v-if="isForbidden">
                        <pendo-alert type="error">
                            <div class="guide-copy-drawer__forbidden-alert">
                                {{ forbiddenAlertText }}
                            </div>
                        </pendo-alert>
                    </template>
                    <template v-else>
                        <pendo-alert
                            v-if="hasFailures"
                            type="error">
                            <div class="guide-copy-drawer__failure-alert">
                                {{ failedAlertText }}
                            </div>
                        </pendo-alert>
                        <pendo-alert
                            v-if="!hasFailures"
                            type="success">
                            <div class="guide-copy-drawer__success-alert">
                                {{ copyStatus.success }} {{ copyStatus.success > 1 ? 'Guides' : 'Guide' }} copied to
                                <strong>{{ targetApplication.displayName }}</strong> in
                                <strong>{{ targetSubscription.displayName }}</strong>.
                            </div>
                        </pendo-alert>
                        <div
                            v-if="hasFailures"
                            class="guide-copy-drawer__failure-list">
                            <div>
                                The following {{ failedGuideList.length > 1 ? 'guides' : 'guide' }} failed to copy:
                            </div>
                            <div
                                v-for="guide in failedGuideList"
                                :key="guide.id"
                                class="guide-copy-drawer__failed-guide">
                                {{ guide.name }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>
        <template #footer>
            <div class="copy-guides-drawer-footer">
                <div
                    v-if="isDefaultState"
                    class="copy-guide-drawer__footer">
                    <pendo-button
                        class="cancel-guide-copy"
                        label="Cancel"
                        type="secondary"
                        theme="app"
                        @click="handleClose" />
                    <pendo-button
                        :disabled="!canCopy"
                        :loading="isCopying"
                        :label="`Copy ${guidesText}`"
                        type="primary"
                        theme="app"
                        @click="copyGuides(undefined)" />
                </div>
                <div
                    v-if="isResultState"
                    class="copy-guide-drawer__footer">
                    <pendo-button
                        label="Close"
                        data-cy="copy-guide-drawer--close"
                        theme="app"
                        type="secondary"
                        @click="handleClose" />
                    <pendo-button
                        v-if="hasFailures"
                        label="Retry Failures"
                        data-cy="copy-guide-drawer--retry"
                        theme="app"
                        type="primary"
                        @click="retryFailedCopies" />
                </div>
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import { PendoDrawer, PendoButton, PendoAlert, PendoProgressBar } from '@pendo/components';
import SelectTargetLocation from '@/components/guides/list/bulk-guide-copy/SelectTargetLocation';
import SelectCountableMappings from '@/components/guides/list/bulk-guide-copy/SelectCountableMappings';
import { copyGuides as utilsCopyGuides } from '@/utils/copy-guides';
import { createCustomPage } from '@/state/modules/pages.module';
import { createCustomFeature } from '@/state/modules/features.module';
import { isInactiveCountable } from '@pendo/tagging';
import { loadTargetAppPages, loadTargetAppFeatures } from '@/utils/copy-guides';
import { PROGRESS_COLORS } from '@/constants/progress';
import { isConfirmationGuide } from '@/stateless-components/utils/guides';

const DRAWER_STATES = Object.freeze({
    DEFAULT: 'default',
    PROGRESS: 'progress',
    RESULT: 'result'
});

const HELP_ARTICLE = 'https://adoptpartners.pendo.io/hc/en-us/articles/4416566040475';

function generateComponentDefaultData () {
    return {
        targetSubscription: null,
        targetApplication: null,
        isCopying: false,
        loadingTargetCountables: false,
        pageMappings: [],
        featureMappings: [],
        guidePageIds: [],
        guideFeatureIds: [],
        targetAppPages: [],
        targetAppFeatures: [],
        failedGuideList: [],
        HELP_ARTICLE,
        copyStatus: {
            failed: [],
            total: 0,
            percent: 0,
            success: 0,
            forbidden: false
        }
    };
}

export default {
    name: 'BulkGuideCopyDrawer',
    components: {
        PendoDrawer,
        PendoButton,
        PendoProgressBar,
        PendoAlert,
        SelectTargetLocation,
        SelectCountableMappings
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        guides: {
            type: Array,
            required: true
        },
        loadingSourceCountables: {
            type: Boolean,
            default: false
        },
        activeIsDigitalAdoption: {
            type: Boolean,
            default: false
        },
        activeIsTrainingSubscription: {
            type: Boolean,
            default: false
        },
        eligibleSubscriptions: {
            type: Array,
            required: true
        }
    },
    data: generateComponentDefaultData,
    computed: {
        ...mapGetters({
            pageById: 'pages/pageById',
            featureById: 'features/featureById'
        }),
        loading () {
            return this.loadingSourceCountables || this.loadingTargetCountables;
        },
        isDefaultState () {
            return this.drawerState === DRAWER_STATES.DEFAULT;
        },
        isProgressState () {
            return this.drawerState === DRAWER_STATES.PROGRESS;
        },
        isResultState () {
            return this.drawerState === DRAWER_STATES.RESULT;
        },
        drawerState () {
            if (this.isCopying) {
                return DRAWER_STATES.PROGRESS;
            }

            if (this.copyStatus.percent === 100) {
                return DRAWER_STATES.RESULT;
            }

            return DRAWER_STATES.DEFAULT;
        },
        guidesText () {
            return this.guides.length > 1 ? 'Guides' : 'Guide';
        },
        drawerTitle () {
            return {
                default: `Copy ${this.guidesText} To Subscription`,
                progress: `Copying ${this.guidesText}`,
                result: `Copying ${this.guidesText} Complete`
            }[this.drawerState];
        },
        progressBarColors () {
            // default -> success
            let complete = PROGRESS_COLORS.SUCCESS;

            // warning -> some requests passed, some failed
            if (this.copyStatus.success !== this.copyStatus.total) {
                complete = PROGRESS_COLORS.WARNING;
            }

            // error -> all requests failed
            if (!this.copyStatus.success) {
                complete = PROGRESS_COLORS.ERROR;
            }

            return {
                default: PROGRESS_COLORS.DEFAULT,
                progress: PROGRESS_COLORS.PROGRESS,
                complete
            };
        },
        guidePages () {
            return this.guidePageIds.map((pageId) => this.pageById(pageId));
        },
        guidesNamesWithAutomation () {
            return this.guides
                .filter((guide) => guide.automations && guide.automations.length > 0)
                .map((guide) => guide.name);
        },
        hasGuidesWithAutomations () {
            return this.guidesNamesWithAutomation.length > 0;
        },
        guideFeatures () {
            return this.guideFeatureIds.map((featureId) => this.featureById(featureId));
        },
        pagesThatNeedMappings () {
            return this.guidePages.filter((page) => {
                return !this.findMatchingCountableInTargetApp(page, this.targetAppPages, 'page');
            });
        },
        featuresThatNeedMappings () {
            return this.guideFeatures.filter((feature) => {
                return !this.findMatchingCountableInTargetApp(feature, this.targetAppFeatures, 'feature');
            });
        },
        targetsSelected () {
            return !!this.targetApplication && !!this.targetSubscription;
        },
        showPageMappingsTable () {
            const showPageMappingsTable =
                this.targetsSelected && this.pagesThatNeedMappings.length && this.targetAppPages.length;

            return !!showPageMappingsTable;
        },
        showFeatureMappingsTable () {
            const showFeatureMappingsTable =
                this.targetsSelected && this.featuresThatNeedMappings.length && this.targetAppFeatures.length;

            return !!showFeatureMappingsTable;
        },
        arePagesMapped () {
            if (!this.showPageMappingsTable) return true;

            return this.pageMappings.every((singleMap) => singleMap.targetCountable);
        },
        areFeaturesMapped () {
            if (!this.showFeatureMappingsTable) return true;

            return this.featureMappings.every((singleMap) => singleMap.targetCountable);
        },
        failedAlertText () {
            const failedCount = this.copyStatus.failed;
            const guidesLabel = failedCount > 1 ? 'Guides' : 'Guide';

            return `${failedCount} ${guidesLabel} failed to copy to ${this.targetApplication.displayName} in ${this.targetSubscription.displayName}.`;
        },
        confirmationGuideNames () {
            return this.guides
                .reduce((confirmationGuideNames, guide) => {
                    if (isConfirmationGuide(guide)) {
                        confirmationGuideNames.push(guide.name);
                    }

                    return confirmationGuideNames;
                }, [])
                .join(', ');
        },
        ineligibleGuides () {
            return this.guides.filter((guide) => {
                const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId', null);

                return segmentId && segmentId !== 'everyone';
            });
        },
        canCopy () {
            return (
                !this.loading &&
                this.targetsSelected &&
                this.arePagesMapped &&
                this.areFeaturesMapped &&
                this.confirmationGuideNames.length === 0 &&
                !this.hasGuidesWithAutomations
            );
        },
        hasFailures () {
            return this.copyStatus.failed > 0;
        },
        isForbidden () {
            return this.copyStatus.forbidden;
        },
        forbiddenAlertText () {
            const { total } = this.copyStatus;
            const guidesLabel = total > 1 ? 'guides' : 'guide';

            return `The ${guidesLabel} could not be copied to ${this.targetApplication.displayName} in ${this.targetSubscription.displayName} due to this action being forbidden.`;
        }
    },
    created () {
        if (this.visible) {
            this.setCountableIdsFromGuides();
        }
    },
    methods: {
        handleClose () {
            this.$emit('close');
        },
        onDrawerOpen () {
            this.setCountableIdsFromGuides();
            this.$emit('open', { pages: !!this.guidePageIds.length, features: !!this.guideFeatureIds.length });
        },
        setCountableIdsFromGuides () {
            const pageIds = [];
            const featureIds = [];

            this.guides.forEach((guide) => {
                guide.steps.forEach((step) => {
                    // Right now we only support copying a step with a page, feature, OR triggerFeature, not all.
                    // Whenever we support all, this can just become a normal if/if
                    if (step.pageId) {
                        pageIds.push(step.pageId);
                    } else if (step.featureId) {
                        featureIds.push(step.featureId);
                    } else if (step.triggerFeatureId) {
                        featureIds.push(step.triggerFeatureId);
                    }
                });
            });

            this.guidePageIds = uniq(pageIds);
            this.guideFeatureIds = uniq(featureIds);
        },
        async handleTarget (payload) {
            this.targetSubscription = payload.targetSub;
            this.targetApplication = payload.targetApp;

            if (!this.targetsSelected) return;

            await this.loadTargetCountables();
            this.createMappingsForCountables();
        },
        createMappingsForCountables () {
            this.pageMappings = this.createMappingsForPages();
            this.featureMappings = this.createMappingsForFeatures();
        },
        createMappingsForPages () {
            const createNewCountableOption = { createNew: true };

            return this.guidePages.map((page) => {
                const targetSubHasNoPages = !this.targetAppPages.length;
                const matchingCountableInTarget = this.findMatchingCountableInTargetApp(
                    page,
                    this.targetAppPages,
                    'page'
                );
                const targetCountable = targetSubHasNoPages ? createNewCountableOption : matchingCountableInTarget;

                return {
                    sourceCountable: page,
                    targetCountable,
                    kind: 'page',
                    targetCountables: this.targetAppPages
                };
            });
        },
        createMappingsForFeatures () {
            const createNewCountableOption = { createNew: true };

            return this.guideFeatures.map((feature) => {
                const targetSubHasNoFeatures = !this.targetAppFeatures.length;
                const matchingCountableInTarget = this.findMatchingCountableInTargetApp(
                    feature,
                    this.targetAppFeatures,
                    'feature'
                );
                const targetCountable = targetSubHasNoFeatures ? createNewCountableOption : matchingCountableInTarget;

                // Formatted for multiple dropdown sections, aka sitewide vs page-specific
                const targetFeatures = [
                    {
                        id: 'sitewide',
                        label: 'Sitewide Features',
                        countables: this.targetAppFeatures.filter((feature) => !feature.pageId)
                    }
                ];

                if (feature.pageId) {
                    targetFeatures.push({
                        id: 'page-specific',
                        label: 'Page-Specific Features',
                        countables: this.targetAppFeatures.filter((feature) => !!feature.pageId)
                    });
                }

                return {
                    sourceCountable: feature,
                    targetCountable,
                    kind: 'feature',
                    targetCountables: targetFeatures
                };
            });
        },
        findMatchingCountableInTargetApp (sourceCountable, targetCountables, kind) {
            return targetCountables.find((targetCountable) => {
                const nameMatch = targetCountable.name === sourceCountable.name;

                if (kind === 'page') {
                    return nameMatch && isEqual(targetCountable.rules, sourceCountable.rules);
                }

                if (kind === 'feature') {
                    const rulesAreEqual = isEqual(targetCountable.elementPathRules, sourceCountable.elementPathRules);
                    const areBothFeaturesSitewideOrPageSpecifc = !!targetCountable.pageId === !!sourceCountable.pageId;

                    return nameMatch && rulesAreEqual && areBothFeaturesSitewideOrPageSpecifc;
                }

                return false;
            });
        },
        async loadTargetCountables () {
            if (!this.targetsSelected) return;

            this.loadingTargetCountables = true;
            const countablesRequests = [];

            if (this.guidePageIds.length) {
                countablesRequests.push(this.fetchTargetAppPages());
            }

            if (this.guideFeatureIds.length) {
                countablesRequests.push(this.fetchTargetAppFeatures());
            }

            await Promise.all(countablesRequests);
            this.loadingTargetCountables = false;
        },
        async fetchTargetAppPages () {
            try {
                const { data } = await loadTargetAppPages({
                    targetSubId: this.targetSubscription.id,
                    targetAppId: this.targetApplication.id
                });

                this.targetAppPages = data.filter((page) => !isInactiveCountable(page));
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                this.targetAppPages = [];
            }
        },
        async fetchTargetAppFeatures () {
            try {
                const { data } = await loadTargetAppFeatures({
                    targetSubId: this.targetSubscription.id,
                    targetAppId: this.targetApplication.id
                });

                this.targetAppFeatures = data.filter((feature) => !isInactiveCountable(feature));
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                this.targetAppFeatures = [];
            }
        },
        async createNewCountables () {
            await this.createNewPages();
            await this.createNewFeatures();
        },
        async createNewPages () {
            const requests = this.pageMappings.map((pageMap) => {
                if (pageMap.targetCountable.id) return null;

                return this.createPage(pageMap.sourceCountable);
            });

            const results = await Promise.all(requests);

            this.mergeNewCountablesWithMap(this.pageMappings, results);
        },
        async createPagesForNewFeatures () {
            const pageRequests = {};
            this.featureMappings.forEach(async (featureMap) => {
                if (featureMap.targetCountable.id) return null;

                const { pageId: sourcePageId } = featureMap.sourceCountable;
                if (sourcePageId && !pageRequests[sourcePageId]) {
                    const sourcePageIsMapped = this.pageMappings.find((map) => map.sourceCountable.id === sourcePageId);
                    if (sourcePageIsMapped) {
                        pageRequests[sourcePageId] = sourcePageIsMapped.targetCountable;

                        return;
                    }
                    const page = this.pageById(sourcePageId);
                    const matchingPage = this.findMatchingCountableInTargetApp(page, this.targetAppPages, 'page');
                    pageRequests[sourcePageId] = matchingPage || this.createPage(page);
                }
            });

            return pageRequests;
        },
        async createNewFeatures () {
            const pages = await this.createPagesForNewFeatures();

            const featureRequests = this.featureMappings.map(async (featureMap) => {
                if (featureMap.targetCountable.id) return null;

                const { name, elementPathRules, pageId: sourcePageId } = featureMap.sourceCountable;
                const customFeature = {
                    name,
                    elementPathRules,
                    appId: this.targetApplication.id
                };

                const page = await pages[sourcePageId];
                if (page) customFeature.pageId = page.id;

                return createCustomFeature(customFeature, this.targetSubscription.id).catch(() => false);
            });

            const results = await Promise.all(featureRequests);
            this.mergeNewCountablesWithMap(this.featureMappings, results);
        },
        async createPage (page) {
            const { name, rules } = page;
            const customPage = {
                name,
                rules,
                appId: this.targetApplication.id
            };

            const createdPage = await createCustomPage(customPage, this.targetSubscription.id).catch(() => false);

            return createdPage;
        },
        mergeNewCountablesWithMap (mappings, newCountables) {
            return mergeWith(mappings, newCountables, (mappingRow, newCountable) => {
                if (!newCountable) return mappingRow;

                this.$set(mappingRow, 'targetCountable', newCountable);

                return mappingRow;
            });
        },
        async copyGuides (guides = this.guides) {
            this.failedGuideList = [];
            this.copyStatus.total = guides.length;

            this.isCopying = true;
            await this.createNewCountables();
            await this.$nextTick();
            this.$refs.copyGuidesProgressBar.start();

            const { failures } = await utilsCopyGuides(
                {
                    guides,
                    featureMappings: this.featureMappings,
                    pageMappings: this.pageMappings,
                    targetSubId: parseInt(this.targetSubscription.id),
                    targetAppId: parseInt(this.targetApplication.id)
                },
                this.isInProgress
            );

            this.failedGuideList = failures;
        },
        retryFailedCopies () {
            const guideIds = this.failedGuideList.map(({ id }) => id);
            this.copyStatus = {
                failed: [],
                forbidden: false,
                total: guideIds.length,
                percent: 0,
                success: 0
            };
            this.copyGuides(guideIds);
        },
        isInProgress ({ total, successes, failed, forbidden }) {
            const completed = forbidden ? total : successes + failed;
            const copyStatus = {
                ...this.copyStatus,
                failed,
                total,
                success: successes,
                forbidden
            };

            copyStatus.percent = (completed / total) * 100;
            this.copyStatus = copyStatus;

            if (this.copyStatus.percent < 100) return;
            this.$refs.copyGuidesProgressBar.done();
            setTimeout(() => {
                this.isCopying = false;
            }, 1000);
        },
        onDrawerClose () {
            this.$emit('closed');
            this.reset();
        },
        reset () {
            Object.assign(this, generateComponentDefaultData());
        }
    }
};
</script>

<style scoped lang="scss">
.copy-guide-drawer__footer {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: end;
}

.warning-message,
.target-selectors {
    margin: 20px;
    margin-bottom: 20px;
}

.select-countable-mappings {
    margin: 0 20px;
    margin-bottom: 20px;
}

.guide-copy-drawer__failed-guide {
    font-weight: 400;
}

.guide-copy-drawer__progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.copy-progress-bar {
    margin: 0 auto;
}

.guide-copy-drawer__progress-status {
    color: $gray-lighter-2;
    font-weight: 600;
    text-align: center;
}

.guide-copy-drawer__success-alert,
.guide-copy-drawer__failure-alert {
    font-weight: 700;
}

.guide-copy-drawer__status-container {
    margin: 30px;
    display: grid;
    grid-gap: 16px;
}

.guide-copy-drawer__failure-list {
    font-weight: bold;
    display: grid;
    grid-gap: 4px;
}
</style>
