<template>
    <summary-chart-card
        class="time-on-guide-chart"
        title="Time on Guide">
        <template
            #chart
            v-pendo-loading:feather="{
                loading: isFetching,
                spinnerProps: {
                    background: '#ffffff'
                }
            }">
            <div
                ref="time-on-guide"
                :class="{ empty: totalViews <= 0 }"
                class="pendo-highcharts-container" />
        </template>
        <template
            #summary
            v-pendo-loading:feather="isFetching">
            <div>
                <div class="summary-chart-card__metric">
                    {{ averageDuration }}
                </div>
                <div class="summary-chart-card__label">
                    Average Time on Guide
                </div>
            </div>
            <div>
                <div class="summary-chart-card__metric">
                    {{ medianDuration }}
                </div>
                <div class="summary-chart-card__label">
                    Median Time on Guide
                </div>
            </div>
        </template>
    </summary-chart-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PendoLoading } from '@pendo/components';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import SummaryChartCard from '@/components/common/SummaryChartCard';
import { getTimeOnStep } from '@/aggregations/time-on-guide';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';
import times from 'lodash/times';
import prettyMilliseconds from 'pretty-ms';
import get from 'lodash/get';

export default {
    name: 'TimeOnGuideChart',
    components: {
        SummaryChartCard
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            chart: null,
            chartConfig: null,
            isFetching: false,
            timeOnGuide: []
        };
    },
    computed: {
        ...mapState({
            activeDateRange: (state) => state.filters.dateRange,
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            guide: 'guides/active'
        }),
        appIds () {
            return get(this.guide, 'appIds', '');
        },
        ranges () {
            const length = 11;
            const oneSecond = 1000;

            return times(length, (index) => {
                const start = index * 2;
                const end = start + 2;

                return {
                    duration: `${start}-${end} secs`,
                    start: start * oneSecond,
                    end: end * oneSecond
                };
            }).concat({
                duration: `${length * 2}+ secs`,
                start: length * oneSecond * 2
            });
        },
        series () {
            const lookup = keyBy(this.timeOnGuide, 'duration');

            return [
                {
                    name: 'Total Views',
                    data: this.ranges.map((range) => {
                        return lookup[range.duration] ? lookup[range.duration].total : 0;
                    })
                }
            ];
        },
        totalViews () {
            return this.series[0].data.reduce((sum, views) => sum + views, 0);
        },
        averageDuration () {
            return this.formatTimeField('averageDuration');
        },
        medianDuration () {
            return this.formatTimeField('medianDuration');
        }
    },
    watch: {
        series () {
            if (!this.chart) return;
            this.chart.update({
                series: this.series
            });
        }
    },
    created () {
        this.initChart();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.refreshChart();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async initChart () {
            await this.refreshChart();
            this.chartConfig = this.getChartConfig();
            if (this.$refs['time-on-guide']) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['time-on-guide'], this.chartConfig);
            }
        },
        async refreshChart () {
            this.isFetching = true;
            this.timeOnGuide = await getTimeOnStep({
                timeSeries: {
                    ...this.activeTimeSeries,
                    period: 'dayRange'
                },
                appId: this.appIds,
                guideId: this.guide.id,
                ranges: this.ranges,
                segmentId: this.activeSegmentId,
                isMultiApp: isCrossApp(this.guide)
            });

            this.isFetching = false;
        },
        formatTimeField (field) {
            const point = this.timeOnGuide.find((point) => isNumber(point[field]));

            if (!point) return '--';

            return prettyMilliseconds(point[field], { secondsDecimalDigits: 0 });
        },
        getChartConfig () {
            return {
                series: this.series,
                chart: {
                    type: 'column'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    categories: this.ranges.map((range) => range.duration),
                    crosshair: false
                },
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        style: {
                            color: '#BABCC5',
                            fontWeight: 'normal',
                            textOutline: null
                        },
                        formatter () {
                            return this.total !== 0 ? this.total : '';
                        }
                    },
                    labels: {
                        align: 'center'
                    },
                    title: {
                        text: 'Views'
                    },
                    min: 0,
                    allowDecimals: false
                },
                tooltip: {
                    pointFormat: '<b>{series.name}</b>: {point.y}<br/>',
                    useHTML: true
                },
                colors: ['#229CA8']
            };
        }
    }
};
</script>

<style lang="scss">
.time-on-guide-chart {
    .summary-chart-card__summary {
        align-content: space-evenly;
    }
}
</style>
