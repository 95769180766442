<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="app"
            class="settings-tabs">
            <template #userProfile>
                <user-profile />
            </template>
            <template #manageUsers>
                <manage-users />
            </template>
            <template #manageMetadata>
                <manage-metadata />
            </template>
            <template #manageLicenses>
                <manage-licenses />
            </template>
            <template #integrations>
                <integrations @error="displayErrorToast" />
            </template>
            <template #subscriptionSettings>
                <subscription-settings />
            </template>
            <template #applications>
                <app-list />
            </template>
            <template #staging>
                <staging />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { PendoPageContent, PendoTabs, PendoNotification } from '@pendo/components';
import UserProfile from '@/components/settings/UserProfile';
import ManageUsers from '@/components/settings/ManageUsers';
import ManageMetadata from '@/components/settings/ManageMetadata';
import ManageLicenses from '@/components/settings/ManageLicenses';
import Integrations from '@/components/settings/Integrations';
import SubscriptionSettings from '@/components/settings/SubscriptionSettings';
import AppList from '@/components/settings/AppList';
import Staging from '@/components/settings/Staging';
import startCase from 'lodash/startCase';

export default {
    name: 'SettingsContent',
    components: {
        UserProfile,
        Integrations,
        ManageUsers,
        ManageMetadata,
        ManageLicenses,
        PendoPageContent,
        PendoTabs,
        Staging,
        SubscriptionSettings,
        AppList
    },
    directives: {
        PendoNotification
    },
    computed: {
        ...mapState({
            route: (state) => state.route,
            user: (state) => state.auth.user
        }),
        ...mapGetters({
            isImpersonating: 'auth/isImpersonating',
            isAdmin: 'auth/isAdmin',
            activeAccount: 'subscriptions/activeAccount',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeSub: 'subscriptions/active',
            activeSubHasFlag: 'subscriptions/activeSubHasFlag'
        }),
        activeTab: {
            get () {
                return this.route.name || 'userProfile';
            },
            set (tab) {
                this.$router.push({ name: tab }).catch(() => {});
            }
        },
        tabs () {
            const tabs = [
                {
                    prop: 'userProfile',
                    label: 'Profile',
                    lazy: true
                }
            ];

            if (this.isAdmin) {
                tabs.push({
                    prop: 'manageUsers',
                    label: 'Users',
                    lazy: true
                });

                if (this.activeIsDigitalAdoption) {
                    tabs.push(
                        {
                            prop: 'subscriptionSettings',
                            label: 'Subscription',
                            lazy: true
                        },
                        {
                            prop: 'applications',
                            label: 'Applications',
                            lazy: true
                        },
                        {
                            prop: 'staging',
                            label: 'Staging',
                            lazy: true
                        },
                        {
                            prop: 'manageMetadata',
                            label: 'Metadata',
                            lazy: true
                        },
                        {
                            prop: 'manageLicenses',
                            label: 'Licenses',
                            lazy: true
                        }
                    );
                }

                if (this.activeSubHasFlag('allowIntegrationKey')) {
                    tabs.push({
                        prop: 'integrations',
                        label: 'Integrations',
                        lazy: true
                    });
                }
            }

            return tabs;
        }
    },
    methods: {
        displayErrorToast (actionCausingError) {
            PendoNotification({
                type: 'error',
                title: `Error ${startCase(actionCausingError)}`,
                message: 'Pendo encountered an error. Please refresh your page and try again.',
                duration: 7000
            });
        }
    }
};
</script>
