<template>
    <div
        class="path-details-empty-state--container"
        data-cy="path-details-empty-state--data">
        <div class="paths-details-empty-state">
            <img
                class="path-details-empty-state--image"
                src="@/img/empty-states/path-details-empty-state.svg">
            <p class="path-details-empty-state--title">
                No Data found
            </p>
            <p class="path-details-empty-state--body">
                There are no Paths found matching the current filters. Consider adjusting any applied filters.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PathDetailsEmptyState'
};
</script>

<style lang="scss" scoped>
.path-details-empty-state {
    &--container {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        margin: 56px auto;
        text-align: center;
        width: 300px;
    }

    &--image {
        margin: 0 25px;
        width: 250px;
    }

    &--title {
        font-size: 24px;
        color: $black;
        font-weight: 700;
        line-height: 120%;
        margin: 24px 55px 12px;
    }

    &--body {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
        text-align: left;
    }
}
</style>
