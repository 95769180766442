<template>
    <div>
        <pendo-table
            ref="table"
            v-stateless-pendo-table-user-settings-watcher="{
                userSettings: tableUserSettings
            }"
            class="poll-response-list__table"
            header-cell-class-name="poll-response-list__header-cell"
            title="Poll Response List"
            :style="{ '--height': height }"
            :data="responseList"
            :max-height="400"
            :columns="columns"
            :default-sort="sort"
            :manage-columns="!useResourceColumnManager"
            resizable
            csv-download
            :csv-config="csvConfig"
            borderless
            row-key="uniqueKey"
            @sort-change="$emit('sort-change', $event)"
            @column-change="$emit('column-change', $event)"
            @column-resize="$emit('column-resize', $event)"
            @tableSettingsUpdate="$emit('tableSettingsUpdate', $event)">
            <template #status>
                <pendo-status-overlay :state="aggState" />
            </template>
            <template #headerLeft>
                <div>
                    <!-- Intentionally empty to hide title + row count in header -->
                </div>
            </template>
            <template #headerActions>
                <pendo-icon-button
                    v-if="useResourceColumnManager"
                    tooltip="Manage Columns"
                    icon="columns"
                    icon-size="16"
                    @click="showResourceColumnsManager = true" />
            </template>
            <template #sendToFeedback="{ row }">
                <pendo-button
                    v-if="!hasResponseAlreadyBecomeFeedbackItem(row) && !isSendToFeedbackInProgress(row)"
                    v-pendo-tooltip="{ content: 'Create a Feedback item', delay: { show: 500 } }"
                    theme="app"
                    type="link"
                    prefix-icon="send"
                    label="Send to Listen"
                    @click="createFeedbackItem(row)" />
                <pendo-button
                    v-if="hasResponseAlreadyBecomeFeedbackItem(row) && !isSendToFeedbackInProgress(row)"
                    v-pendo-tooltip="{ content: 'Open Feedback item in a new tab', delay: { show: 500 } }"
                    theme="app"
                    type="link"
                    prefix-icon="external-link"
                    label="Open"
                    @click="openFeedbackItem(row)" />
                <div
                    v-if="isSendToFeedbackInProgress(row)"
                    class="feedback-pending">
                    <pendo-loading-indicator size="small" />
                </div>
            </template>
        </pendo-table>
        <pendo-resource-column-chooser
            v-if="useResourceColumnManager"
            :visible="showResourceColumnsManager"
            :columns="columns"
            :saving="savingColumnChooser"
            :features-list="featuresList"
            :guides-list="guidesList"
            :pages-list="pagesList"
            :is-loading-resources="isLoadingResources"
            :is-loading-metadata="isLoadingMetadata"
            :metadata-list="metadata"
            @fetch-resources="$emit('fetchResources', $event)"
            @cancel="showResourceColumnsManager = false"
            @close="showResourceColumnsManager = false"
            @confirm="onConfirmResourceColumns" />
    </div>
</template>

<script>
import {
    PendoButton,
    PendoIconButton,
    PendoLoadingIndicator,
    PendoResourceColumnChooser,
    PendoStatusOverlay,
    PendoTable,
    PendoTooltip
} from '@pendo/components';
import { LOADING_STATES } from '@pendo/services/Constants';
import { getColumnWidth, rowFormatter } from '@/utils/table-formatters';
import { formatValueForSchema } from '@/utils/formatters';
import { DATE_FORMAT } from '@/utils/moment';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import StatelessPendoTableUserSettingsWatcher from '@/stateless-components/directives/StatelessPendoTableUserSettingsWatcher';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { getPollResponseId, hasResponseAlreadyBecomeFeedbackItem, isSendToFeedbackInProgress } from './utils';

export default {
    name: 'PollResponseList',
    components: {
        PendoButton,
        PendoIconButton,
        PendoLoadingIndicator,
        PendoResourceColumnChooser,
        PendoStatusOverlay,
        PendoTable
    },
    directives: {
        PendoTooltip,
        StatelessPendoTableUserSettingsWatcher
    },
    props: {
        aggState: {
            type: String,
            default: LOADING_STATES.LOADING
        },
        height: {
            type: String,
            default: 'auto'
        },
        responseList: {
            type: Array,
            default: () => []
        },
        metadata: {
            type: Array,
            default: () => []
        },
        timezone: {
            type: String,
            default: null
        },
        tableUserSettings: {
            type: Object,
            default: () => null
        },
        useResourceColumnManager: {
            type: Boolean,
            default: false
        },
        savingColumnChooser: {
            type: Boolean,
            default: false
        },
        isLoadingResources: {
            type: Boolean,
            default: false
        },
        isLoadingMetadata: {
            type: Boolean,
            default: false
        },
        featuresList: {
            type: Array,
            default: () => []
        },
        guidesList: {
            type: Array,
            default: () => []
        },
        pagesList: {
            type: Array,
            default: () => []
        },
        alreadyFeedbackLinkedResponses: {
            type: Map,
            default: () => {}
        },
        getInitFeedbackInProgress: {
            type: Boolean,
            default: false
        },
        creatingFeedbackInProgress: {
            type: Array,
            default: () => []
        },
        poll: {
            type: Object,
            default: () => {}
        },
        guide: {
            type: Object,
            default: () => {}
        },
        showFeebackColumn: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            pollResponsesUserSettingsName: 'pollResponses',
            defaultSort: {
                prop: 'browserTime',
                order: 'descending'
            },
            showResourceColumnsManager: false
        };
    },
    computed: {
        defaultColumns () {
            const defaultColumns = [
                {
                    prop: 'visitorId',
                    label: 'Visitor ID',
                    functionName: 'visitorIdColumn',
                    immutable: true,
                    schema: 'string',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'browserTime',
                    label: 'Date',
                    functionName: 'visitorIdColumn',
                    immutable: true,
                    schema: 'time',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'pollResponse',
                    label: 'Response',
                    functionName: 'pollResponse',
                    immutable: true,
                    schema: 'string',
                    sortable: true,
                    visible: true
                }
            ];

            if (this.showFeebackColumn) {
                defaultColumns.push({
                    prop: 'sendToFeedback',
                    label: 'Feedback Item',
                    immutable: true,
                    visible: true,
                    sortable: true,
                    schema: 'poll',
                    functionName: 'pollResponse',
                    columnName: 'sendToFeedback',
                    columnLabel: 'Feedback Item'
                });
            }

            defaultColumns.push(...this.metadataColumns);
            defaultColumns.forEach((column) => {
                column.formatter = (row) => rowFormatter(row, column, this.timezone);
                column.width = getColumnWidth(column);
            });

            return defaultColumns;
        },
        metadataColumns () {
            return this.metadata.reduce((acc, metadata) => {
                const column = {
                    ...metadata,
                    prop: metadata.field,
                    label: metadata.name,
                    schema: metadata.schema,
                    sortable: true,
                    visible: false
                };

                acc.push(column);

                return acc;
            }, []);
        },
        columns () {
            const columns = mergeDefaultColumnsWithUserSettings(this.defaultColumns, this.tableUserSettings);
            if (this.useResourceColumnManager) return columns.filter((column) => column.visible);

            return columns;
        },
        columnProps () {
            return this.columns.map(({ prop }) => prop);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.tableUserSettings);
        },
        csvConfig () {
            return {
                columns: this.columns.reduce((newColumns, column) => {
                    if (!column.visible) return newColumns;

                    const { schema, prop } = column;
                    let newColumn = column;

                    if (['time', 'date'].includes(schema)) {
                        const { moment: momentPackage } = this.$pendo;
                        newColumn = {
                            ...column,
                            formatter (row) {
                                const value = get(row, prop);
                                const options = {
                                    dateFormat: DATE_FORMAT.isoDateTime24,
                                    momentPackage
                                };

                                return formatValueForSchema(value, schema, options);
                            }
                        };
                    }

                    newColumns.push(newColumn);

                    return newColumns;
                }, [])
            };
        }
    },
    watch: {
        columnProps: {
            immediate: true,
            handler (newColumnProps, oldColumnProps) {
                if (!difference(newColumnProps, oldColumnProps).length) return;
                const tableUserSettingsColumns = this.tableUserSettings?.columns;
                if (tableUserSettingsColumns && tableUserSettingsColumns.length !== newColumnProps.length) return;
                this.$emit('columnsUpdate', this.columns);
            }
        }
    },
    methods: {
        onConfirmResourceColumns (confirmedColumns) {
            this.showResourceColumnsManager = false;

            const columns = this.columns
                .filter((column) => column.immutable)
                .concat(confirmedColumns)
                .map((column) => ({ ...column, visible: true }));

            const columnPropsMatch = isEqual(
                columns.map(({ prop }) => prop),
                this.columnProps
            );
            // prevent event emission when columns are the same, only rearranged
            if (columnPropsMatch) return;

            // trigger settings to be saved via stateless-pendo-table-user-settings-watcher
            this.$refs.table.$emit('column-change', { columns });
        },
        generateResponseId (row) {
            return getPollResponseId(row, this.guide.id, this.poll.id);
        },
        hasResponseAlreadyBecomeFeedbackItem (row) {
            const pollResponseId = this.generateResponseId(row);

            return hasResponseAlreadyBecomeFeedbackItem(pollResponseId, this.alreadyFeedbackLinkedResponses);
        },
        isSendToFeedbackInProgress (row) {
            const pollResponseId = this.generateResponseId(row);

            return isSendToFeedbackInProgress(
                pollResponseId,
                this.getInitFeedbackInProgress,
                this.creatingFeedbackInProgress
            );
        },
        createFeedbackItem (row) {
            const pollResponseId = this.generateResponseId(row);
            this.$emit('createFeedbackItem', { row, pollResponseId });
        },
        openFeedbackItem (row) {
            const pollResponseId = this.generateResponseId(row);
            this.$emit('openFeedbackItem', pollResponseId);
        }
    }
};
</script>

<style lang="scss">
.poll-response-list__table {
    .poll-response-list__header-cell {
        &.pendo-table__column {
            background-color: $white;
        }
    }

    .pendo-table__content {
        min-height: var(--height);
    }
}

.feedback-pending {
    display: flex;
    align-items: flex-start;
}
</style>
