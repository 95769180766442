import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

export function isEveryoneSegment (segmentId) {
    return ['everyone', 'everyoneExcludingAnonymous', 'anonymousOnly'].includes(segmentId) || !segmentId;
}

export function getSegmentName (segmentId, segmentsMap) {
    const segment = isEveryoneSegment(segmentId) ? 'everyone' : segmentId;

    return get(segmentsMap, `${segment}.name`, segmentId);
}

export function getSegmentsUsingSegment (segmentId, segmentsList = []) {
    if (segmentId) {
        return segmentsList.filter((segment) => segment.compound?.includes(segmentId)) || [];
    }

    return [];
}

export function isSegmentAttachedToGuide (segment, guideMap) {
    if (!segment) {
        return false;
    }

    return Object.values(guideMap).some((guide) => {
        const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId');

        return segmentId && segment.id === segmentId;
    });
}

export function isValidSegmentInContext (segment, context = {}) {
    const { guideMap = {}, assigningToGuide = false } = context;

    if (isSegmentGuideEligible(segment)) {
        return true;
    }

    // segment is not guide eligible, but is being assigned to a guide
    if (assigningToGuide) {
        return false;
    }

    const alreadyAttachedToGuide = isSegmentAttachedToGuide(segment, guideMap);

    return !alreadyAttachedToGuide;
}

export function filterBlacklistedSegments (segmentList, blacklist) {
    const blacklistMap = keyBy(blacklist);

    return segmentList.filter((segment) => {
        const filters = get(segment, 'definition.filters', null);

        // everyone
        if (!filters) {
            return true;
        }

        return !filters.some((filter) => isBlacklistedFilter(filter, blacklistMap));
    });
}

export function isBlacklistedFilter (filter, blacklistMap) {
    if (filter.or) {
        return filter.or.some((f) => isBlacklistedFilter(f, blacklistMap)); // eslint-disable-line id-length
    }

    return !!blacklistMap[filter.type];
}

export function isSegmentBlacklisted (segment, blacklist) {
    return !filterBlacklistedSegments([segment], blacklist).length;
}

export function isDefaultSegment (segment) {
    return ['everyone', 'everyoneExcludingAnonymous', 'anonymousVisitorsOnly', 'blacklistOnly'].includes(segment);
}

export function segmentUsesDeletedGuide (segment, guideMap) {
    const filters = get(segment, 'definition.filters');

    if (!filters) {
        return false;
    }

    return filters.some((filter) => filterUsesDeletedGuide(filter, guideMap));
}

export function filterUsesDeletedGuide (filter, guideMap) {
    if (filter.or) {
        return filter.or.some((f) => filterUsesDeletedGuide(f, guideMap)); // eslint-disable-line id-length
    }

    if (filter.type !== 'guide') {
        return false;
    }

    return !guideMap[filter.guideId];
}

export function isSegmentGuideEligible (segment) {
    const filters = get(segment, 'definition.filters');

    if (!filters) {
        return true;
    }

    return !filters.some(filterContainsLastVisitType);
}

export function filterContainsLastVisitType (filter) {
    if (filter.or) {
        return filter.or.some(filterContainsLastVisitType);
    }

    return filter.type === 'metadata.auto.lastvisit';
}
