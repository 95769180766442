var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.cssVars)},[(_vm.type === 'divider')?_c('hr',{staticClass:"multiselect-blade-option__group-divider"}):_vm._e(),(_vm.type === 'group')?_c('div',{staticClass:"multiselect-blade-option__group-row",class:{ 'is-selected': _vm.value || _vm.indeterminate }},[_c('span',{staticClass:"multiselect-blade-option__group-collapse-icon"},[_c('pendo-icon',{attrs:{"type":_vm.expandIcon,"size":"16"},on:{"click":_vm.onExpandedToggle}})],1),_c('span',{staticClass:"multiselect-blade-option__group-label multiselect-blade-option__row",class:{ 'is-disabled': _vm.disabled },on:{"click":_vm.handleToggle,"mouseenter":_vm.onOptionMouseenter,"mouseleave":_vm.onOptionMouseleave}},[_c('pendo-checkbox',_vm._b({on:{"change":_vm.handleToggle}},'pendo-checkbox',{ value: _vm.value, disabled: _vm.disabled, indeterminate: _vm.indeterminate },false)),(_vm.item)?_c('div',[_c('pendo-app-display',{attrs:{"apps":_vm.item}})],1):_c('div',[_c('span',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                        content: _vm.label,
                        multiline: true,
                        trigger: 'manual',
                        offset: 0,
                        show: _vm.showOverflowTooltip
                    }),expression:"{\n                        content: label,\n                        multiline: true,\n                        trigger: 'manual',\n                        offset: 0,\n                        show: showOverflowTooltip\n                    }"}],staticClass:"multiselect-blade-option__group-name"},[_vm._v(" "+_vm._s(_vm.label)+" ")])])],1)]):_vm._e(),(_vm.type === 'option')?_c('div',{staticClass:"multiselect-blade-option__row multiselect-blade-option__option-row",class:{
            'is-selected': _vm.value,
            'is-disabled': _vm.disabled
        },attrs:{"data-cy":"blade-option"},on:{"click":_vm.handleToggle,"mouseenter":_vm.onOptionMouseenter,"mouseleave":_vm.onOptionMouseleave}},[_c('pendo-checkbox',_vm._b({on:{"change":_vm.handleToggle}},'pendo-checkbox',{ value: _vm.value, disabled: _vm.disabled },false)),_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                content: `${_vm.label}\n\n${_vm.sublabel}`,
                multiline: true,
                trigger: 'manual',
                offset: 0,
                show: _vm.showOverflowTooltip
            }),expression:"{\n                content: `${label}\\n\\n${sublabel}`,\n                multiline: true,\n                trigger: 'manual',\n                offset: 0,\n                show: showOverflowTooltip\n            }"}],staticClass:"multiselect-blade-option-label"},[_c('pendo-icon',{attrs:{"type":_vm.optionIcon,"size":14}}),_c('div',{staticClass:"multiselect-blade-option-label-content"},[_c('div',{staticClass:"multiselect-blade-option-label-text"},[_vm._v(" "+_vm._s(_vm.label || '(No name)')+" ")]),_c('div',{staticClass:"multiselect-blade-option-label-subtext"},[_vm._v(" "+_vm._s(_vm.sublabel)+" ")])])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }