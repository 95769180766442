<template>
    <pendo-date-picker
        :value="dateRange"
        :columns="2"
        :disabled="disabled"
        :loading="loading"
        :shortcuts="shortcuts"
        :available-dates="availableDates"
        :full-width="fullWidth"
        class="date-range-picker"
        data-cy="date-filter"
        type="range"
        @select-shortcut="onDateUpdate"
        @select-date="onDateUpdate"
        @drag="onDragValueChange">
        <template #value>
            <div class="date-range-picker__value">
                <span data-cy="date-filter--value">
                    {{ value.label }}
                </span>
                <span
                    v-if="value.aside"
                    data-cy="date-filter--aside"
                    class="date-range-picker__aside">
                    {{ value.aside }}
                </span>
            </div>
        </template>
    </pendo-date-picker>
</template>

<script>
import { PendoDatePicker } from '@pendo/components';
import { convertValueToDateRangeConfig, fixedDateRanges } from '@/utils/time-series';
import moment from '@/utils/moment';

export default {
    name: 'DateRangePicker',
    components: {
        PendoDatePicker
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        rangeLimiter: {
            type: Number,
            default: 0
        },
        customShortcuts: {
            type: Array,
            default: null
        },
        firstVisit: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            dragValue: null
        };
    },
    computed: {
        appFirstVisit () {
            return moment(this.firstVisit)
                .startOf('day')
                .toDate();
        },
        dateRange () {
            if (!this.value.value) {
                return {};
            }

            return {
                start: moment(this.value.value.start).toDate(),
                end: moment(this.value.value.end).toDate()
            };
        },
        loading () {
            return !this.value.value;
        },
        shortcuts () {
            const shortcuts = this.customShortcuts || fixedDateRanges();
            if (!this.rangeLimiter) {
                return shortcuts;
            }

            return shortcuts.filter((shortcut) => {
                return shortcut.count <= this.rangeLimiter;
            });
        },
        availableDates () {
            if (!this.dragValue || !this.rangeLimiter) {
                return {
                    start: this.appFirstVisit
                };
            }

            // rangeLimiterInclusive is one less than the rangeLimiter since setting the rangeLimiter to 7
            // should only allow us to choose a 7 day window (including the start date) not 7 days away from
            // the start date which would equal 8 days total.
            const rangeLimiterInclusive = this.rangeLimiter - 1;

            const start = moment(this.dragValue.start)
                .add(rangeLimiterInclusive * -1, 'days')
                .toDate();
            const end = moment(this.dragValue.start)
                .add(rangeLimiterInclusive, 'days')
                .toDate();

            if (moment(start).isBefore(this.appFirstVisit)) {
                return {
                    start: this.appFirstVisit,
                    end
                };
            }

            return {
                start,
                end
            };
        }
    },
    methods: {
        onDragValueChange (value) {
            if (this.rangeLimiter) {
                this.dragValue = value;
            }
        },
        onDateUpdate (date) {
            const dateRange = convertValueToDateRangeConfig(date, this.firstVisit);
            this.$emit('change', { dateRange });
        }
    }
};
</script>
<style lang="scss">
.date-range-picker {
    .date-range-picker__aside {
        font-size: 0.875em;
        color: $gray-lighter-2;
    }
}
</style>
