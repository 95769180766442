import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import {
    getTimeSeriesForCustomRange,
    getTimeSeriesForFixedRange,
    fixedDateRanges,
    convertValueToDateRangeConfig
} from '@/utils/time-series';
import { isDefaultSegment } from '@/utils/segments';
import { buildPathSpec } from '@/aggregations/paths';
import { identifiedState } from './aggregations';
import store from '@/state/store';

export class PathError extends Error {
    constructor ({ message = 'We were unable to run your path.', retriable = true } = {}) {
        super(message);
        this.message = message;
        this.retriable = retriable;
    }
}

export function getDefaultUnsavedPath () {
    const defaultTimeSeries = fixedDateRanges().find((dateRange) => dateRange.id === 'last30days');

    return {
        name: 'New Path',
        shared: true,
        type: 'Path',
        definition: {
            config: {
                maxInterval: 3600,
                predecessors: false,
                omitPages: false,
                features: true,
                trackEvents: false,
                collapseDups: true,
                uniqueVisitors: false,
                maxLength: 10,
                segmentId: 'everyone',
                acrossApps: false
            },
            minimum: 0,
            timeSeries: defaultTimeSeries.value,
            history: []
        }
    };
}

export function generatePathTimeSeries (dateRange) {
    const type = startCase(dateRange.id);

    return {
        ...dateRange.value,
        type
    };
}

export function convertEngageTimeSeriesTypeToId (dateRange) {
    const id = camelCase(dateRange.type).toLowerCase();

    return {
        ...dateRange,
        id
    };
}

export function buildPathAggregationTimeSeries (timeSeries) {
    const fullDateRange = convertValueToDateRangeConfig({ value: { ...timeSeries } }, store.getters['apps/firstVisit']);
    if (fullDateRange.id === 'custom') {
        return {
            ...getTimeSeriesForCustomRange(fullDateRange),
            period: 'dayRange'
        };
    }

    return {
        ...getTimeSeriesForFixedRange(fullDateRange),
        period: 'dayRange'
    };
}

export function buildPathAggregationParams (path) {
    const { definition } = path;
    const { config } = definition;
    const timeSeries = buildPathAggregationTimeSeries(definition.timeSeries);

    return {
        config: {
            blacklist: 'apply',
            includeAnonymous: identifiedState(config.segmentId),
            ...config,
            segmentId: isDefaultSegment(config.segmentId) ? null : config.segmentId
        },
        timeSeries
    };
}

export function compilePathAggregation (path) {
    const params = buildPathAggregationParams(path);

    return {
        pipeline: buildPathSpec(params)
    };
}

export const progressCircleColors = {
    center: { fill: '#ffffff', stroke: '#ffffff' },
    default: { fill: '#ffffff', stroke: '#ffffff' },
    progress: { fill: '#128297', stroke: '#128297' },
    complete: { fill: '#128297', stroke: '#128297' }
};
