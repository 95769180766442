<template>
    <div class="journeys-empty-state">
        <div class="journeys-empty-state-text">
            <div class="journeys-empty-state-text__title">
                {{ title }}
            </div>
            <div class="journeys-empty-state-text__description">
                {{ description }}
            </div>
            <template v-if="hasSavedWorkflow">
                <div class="journeys-empty-state-text__description">
                    {{ selectPagesAndFeaturesText }}
                </div>
                <pendo-button
                    v-if="hasSavedWorkflow"
                    data-cy="empty-state-select"
                    :loading="isFetchingOrSaving"
                    theme="app"
                    type="primary"
                    label="Select Pages &amp; Features"
                    @click="$emit('showBlade')" />
            </template>
            <template v-else>
                {{ saveAWorkflowText }}
            </template>
        </div>
        <img
            class="journeys-empty-state-image"
            src="@/img/empty-states/journeys-empty-state.svg"
            alt="journeys-empty-state-image">
    </div>
</template>

<script>
import { PendoButton } from '@pendo/components';

export default {
    name: 'JourneysEmptyState',
    components: {
        PendoButton
    },
    props: {
        hasSavedWorkflow: {
            type: Boolean,
            default: false
        },
        isFetching: {
            type: Boolean,
            default: false
        },
        isSaving: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            title: 'Workflow journeys',
            description:
                'Journeys surface behavioral insights of your most productive users to help you provide personalized guidance to the visitors who need it most.',
            selectPagesAndFeaturesText:
                'Start by selecting the pages & features you expect visitors take to complete the workflow.',
            saveAWorkflowText: 'You’ll need to save a workflow before you can generate journeys.'
        };
    },
    computed: {
        isFetchingOrSaving () {
            return this.isFetching || this.isSaving;
        }
    },
    methods: {
        showBlade () {
            this.$emit('showBlade');
        }
    }
};
</script>

<style lang="scss" scoped>
.journeys-empty-state {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 5%;
}

.journeys-empty-state-text {
    flex: 0 1 400px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__title {
        font-weight: 600;
        font-size: 36px;
        padding-bottom: 20px;
    }

    &__description {
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 20px;
    }
}

.journeys-empty-state-image {
    width: 737px;
    height: 601px;
}
</style>
