export const state = {
    isFetchingChart: false,
    isFetchingList: false,
    appUsageListUserSettings: null,
    metadataFieldTotal: 'Total'
};

export const mutations = {
    setAppUsageListUserSettings (state, { appUsageListUserSettings }) {
        state.appUsageListUserSettings = appUsageListUserSettings;
    },
    setFetchingChart (state, { isFetchingChart }) {
        state.isFetchingChart = isFetchingChart;
    },
    setFetchingList (state, { isFetchingList }) {
        state.isFetchingList = isFetchingList;
    }
};

export const actions = {
    updateAppUsageListUserSettings ({ commit, dispatch }, { appUsageListUserSettings }) {
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            {
                name: 'appUsageListUserSettings',
                value: JSON.stringify(appUsageListUserSettings)
            },
            {
                root: true
            }
        );
        commit('setAppUsageListUserSettings', { appUsageListUserSettings });
    }
};

export const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
