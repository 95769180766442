<template>
    <div
        v-if="showSuggestedPagesTable"
        class="suggested-page-tags-wrapper">
        <suggested-pages-table
            title="Suggested Pages"
            :is-empty="isEmpty"
            :pages="suggestedPageRulesFilteredData"
            :filters="filters"
            theme="app"
            :is-deleting="isDeleting"
            :is-accepting="isAccepting"
            :is-default-collapsed="isDefaultCollapsed"
            :sample-time-message="sampleTimeMessage"
            @accept-one="acceptOne"
            @accept-bulk="acceptBulk"
            @delete-one="deleteOne"
            @delete-bulk="deleteBulk"
            @reset-filters="resetFilters"
            @collapse-change="setCollapsedSetting">
            <template #tooltipText>
                Suggested Pages allows you to accept or delete pages on applications. To hide the Suggested Pages table
                and its notifications, go to the
                <router-link
                    class="suggested-pages-tooltip-link"
                    :to="userProfileLink">
                    User Preferences section on your User Profile
                </router-link>.
            </template>
        </suggested-pages-table>
    </div>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SuggestedPagesTable } from '@pendo/tagging';
import { PendoNotification } from '@pendo/components';
import { updateSuggestedPageRulesHandledList } from './suggested-page-tags.util';

export default {
    name: 'SuggestedPageTagsWrapper',
    components: {
        SuggestedPagesTable
    },
    props: {
        searchString: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            isDeleting: false,
            isAccepting: false,
            userProfileLink: '/settings/profile'
        };
    },
    computed: {
        ...mapState({
            suggestedPageRules: (state) => state.pages.suggestedPageRules,
            suggestedPageRulesFilteredData: (state) => state.pages.suggestedPageRulesFilteredData,
            userPreferencesState: (state) => state.userSettings.userPreferences
        }),
        ...mapGetters({
            getCrossUISettingByName: 'userSettings/getCrossUISettingByName',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            allAdoptAppsAggId: 'filters/allAdoptAppsAggId'
        }),
        sampleTimeMessage () {
            const eventRate = get(this.subscription, 'eventRate', 1);
            const MAX_EVENTS_PER_HOUR = 2500000;
            const MAX_HOUR_COUNT = 164;
            const count = Math.min(MAX_HOUR_COUNT, Math.max(Math.floor(MAX_EVENTS_PER_HOUR / eventRate), 1));

            return `${count} hour${count > 1 ? 's' : ''}`;
        },
        showSuggestedPagesTable () {
            return get(this.userPreferencesState, 'suggestedPagesTable.value', false);
        },
        isEmpty () {
            return !this.suggestedPageRules.length;
        },
        isDefaultCollapsed () {
            return get(this.getCrossUISettingByName('suggestedPagesCollapse'), 'value', true);
        },
        filters () {
            return [
                {
                    prop: ['name', 'rule'],
                    value: this.searchString
                }
            ];
        }
    },
    methods: {
        ...mapActions({
            updateAppFilter: 'filters/updateAppFilter',
            createCustomPage: 'pages/createCustomPage',
            fetchSuggestedPageRulesHandledList: 'pages/fetchSuggestedPageRulesHandledList',
            updateCrossUINamespaceSetting: 'userSettings/updateCrossUINamespaceSetting'
        }),
        async acceptOne (event) {
            const response = await this.createCustomPage({
                customPage: {
                    name: event.name,
                    appId: event.appId,
                    rules: [{ rule: event.rule }],
                    suggestedTagRules: [event.rule]
                }
            });
            const action = 'accept';
            if (response) {
                await updateSuggestedPageRulesHandledList({
                    selections: [{ appId: event.appId, rule: event.rule }],
                    action
                });
                await this.fetchSuggestedPageRulesHandledList();
                PendoNotification({
                    type: 'success',
                    title: 'Page suggestions accepted!'
                });
            } else {
                PendoNotification({
                    type: 'error',
                    title: 'Page suggestions not accepted!'
                });
            }
        },
        async acceptBulk (event) {
            this.isAccepting = true;
            const selections = [];
            await Promise.all(
                event.map(async (row) => {
                    const customPage = {
                        name: row.name,
                        appId: row.appId,
                        rules: [{ rule: row.rule }],
                        suggestedTagRules: [row.rule]
                    };
                    const response = await this.createCustomPage({ customPage });
                    if (response) {
                        selections.push({ appId: response.appId, rule: response.rules[0].rule });
                    }
                })
            );
            const action = 'accept';
            if (selections.length > 0) {
                await updateSuggestedPageRulesHandledList({ selections, action });
                await this.fetchSuggestedPageRulesHandledList();
                PendoNotification({
                    type: 'success',
                    title: selections.length === 1 ? 'Page suggestion accepted!' : 'Page suggestions accepted!'
                });
            } else {
                PendoNotification({
                    type: 'error',
                    title: 'Page suggestions not accepted!'
                });
            }
            this.isAccepting = false;
        },
        async deleteOne (event) {
            this.isDeleting = true;
            const action = 'decline';
            await updateSuggestedPageRulesHandledList({
                selections: [{ appId: event.appId, rule: event.rule }],
                action
            });
            await this.fetchSuggestedPageRulesHandledList();
            this.isDeleting = false;
            PendoNotification({
                type: 'success',
                title: 'Page suggestion removed!'
            });
        },
        async deleteBulk (event) {
            this.isDeleting = true;
            const selections = event.map((row) => ({ appId: row.appId, rule: row.rule }));
            const action = 'decline';
            await updateSuggestedPageRulesHandledList({ selections, action });
            await this.fetchSuggestedPageRulesHandledList();
            this.isDeleting = false;
            PendoNotification({
                type: 'success',
                title: selections.length === 1 ? 'Page suggestion removed!' : 'Page suggestions removed!'
            });
        },
        resetFilters () {
            return this.updateAppFilter({ appIds: [this.allAdoptAppsAggId] });
        },
        async setCollapsedSetting (event) {
            await this.updateCrossUINamespaceSetting({
                name: 'suggestedPagesCollapse',
                value: JSON.stringify(event.collapsed)
            });
        }
    }
};
</script>

<style lang="scss">
.suggested-page-tags-wrapper {
    max-height: 420px;
}

.suggested-pages-tooltip-link {
    text-decoration: underline;
}
</style>
