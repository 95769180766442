<template>
    <div
        class="editable-text-field"
        :class="{ 'editable-text-field--boxed': boxed }">
        <div
            class="editable-text-field__input"
            :class="{
                'editable-text-field__input--fit-content': fitContent,
                'editable-text-field__input--errors': validationError && showValidation,
                'editable-text-field__input--disabled': disabled
            }">
            <!-- Hidden element that gives the right height to the textarea ~~~>
            <~~~ the space in v-text is intentional and needed to handle newlines -->
            <div
                class="editable-text-field__input__mask"
                :class="{ 'editable-text-field__input__mask--not-expanded': !isExpanded }"
                v-text="`${value} `" />
            <textarea
                ref="textarea"
                :class="{ 'editable-text-field__input__mask--not-expanded': !isExpanded }"
                data-cy="editable-text-field-not-expanded"
                :value="value"
                spellcheck="false"
                rows="1"
                cols="1"
                :placeholder="placeholder"
                :disabled="disabled"
                :tabindex="disabled ? -1 : 0"
                @blur="onTextAreaFocus(false)"
                @focus="onTextAreaFocus(true)"
                @keydown.enter="onEnter"
                @keydown.esc="onReset"
                @input="onInput" />
            <pendo-icon
                type="edit-2"
                size="14"
                class="editable-text-field__input__pen" />
        </div>
        <div
            class="editable-text-field__validation"
            :class="{
                'editable-text-field__validation--errors': validationError && showValidation
            }"
            v-text="validationError" />
    </div>
</template>

<script>
import { PendoIcon } from '@pendo/components';
import DOMPurify from 'dompurify';

export default {
    components: {
        PendoIcon
    },
    props: {
        value: {
            type: String,
            required: true
        },
        validation: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        multiline: {
            type: Boolean,
            default: true
        },
        fitContent: {
            type: Boolean,
            default: false
        },
        boxed: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        showValidation: {
            type: Boolean,
            default: true
        },
        sanitize: {
            type: Boolean,
            default: true
        },
        isExpanded: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            hasFocus: false
        };
    },
    computed: {
        validationError () {
            let error = null;

            for (const { validate, message } of this.validation) {
                if (!validate(this.value)) {
                    error = message;
                    break;
                }
            }

            return error;
        }
    },
    watch: {
        validationError: {
            handler (error) {
                this.$emit('validation-error', error);
            },
            immediate: true
        }
    },
    mounted () {
        if (this.autofocus) {
            this.$refs.textarea.focus();
        }
    },
    methods: {
        onEnter (event) {
            if (!this.multiline) {
                event.stopPropagation();
                event.preventDefault();
            }
        },
        onReset () {
            this.$emit('input', '');
            this.$emit('reset');
        },
        onInput ({ target: { value } }) {
            // replace newlines with spaces if not in multiline mode (used when copy/pasting)
            if (!this.multiline) {
                value = value.replace(/\n/gi, ' ');
            }
            if (this.sanitize) {
                value = DOMPurify.sanitize(value);
            }
            this.$emit('input', value);
        },
        onTextAreaFocus (hasFocus) {
            if (!hasFocus) {
                this.$emit('blur');
            } else {
                this.$emit('focus');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.editable-text-field {
    width: 100%;

    .editable-text-field__input {
        display: grid;
        position: relative;

        &.editable-text-field__input--fit-content {
            width: -moz-fit-content;
            width: fit-content;
        }

        .editable-text-field__input__mask {
            visibility: hidden;
        }

        textarea {
            resize: none;
            overflow: hidden;
            border: 0;
            outline: 0;
            padding: 0;
            display: block;
            border-bottom: 2px dashed transparent;

            &:focus,
            &:hover {
                border-bottom-color: #9a9ca5;
            }

            &:hover:not(:focus) {
                color: $gray-lighter-2;
            }
        }

        .editable-text-field__input__pen {
            position: absolute;
            right: -16px;
            bottom: 0;
            color: $gray-lighter-2;
            visibility: hidden;
        }

        textarea:hover:not(:focus) + .editable-text-field__input__pen {
            visibility: visible;
        }

        /*Textarea has a fixed height and does not grow when its content does.*/
        /*Below is the trick to fix this:*/
        /*Textarea and mask occupy the same position in the grid (first row).*/
        /*The input value is fed to the mask, which automatically grows/shrinks.*/
        /*Then the textarea has the same width - ensuring all rows are displayed.*/
        textarea,
        .editable-text-field__input__mask {
            grid-row: 1;
            grid-column: 1;
            font: inherit;
            white-space: pre-wrap;
            word-break: break-word;
            padding-bottom: 4px;
        }

        .editable-text-field__input__mask--not-expanded {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 20;
            -webkit-box-orient: vertical;
            text-overflow: ellipis;
        }
    }

    .editable-text-field__input--errors {
        textarea {
            &,
            &:focus,
            &:hover {
                border-bottom-color: $color-red-60;
            }
        }

        .editable-text-field__input__pen {
            color: $color-red-60;
        }
    }

    .editable-text-field__input--disabled {
        textarea {
            background: none;

            &,
            &:focus,
            &:hover {
                border-bottom-color: transparent;
            }

            &:hover {
                cursor: not-allowed;
                color: $color-gray-60;
            }
        }

        textarea:hover:not(:focus) + .editable-text-field__input__pen {
            visibility: hidden;
        }
    }

    .editable-text-field__validation {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        transition: 0.2s;
        color: transparent;
        height: 0;
        margin-top: 0;

        &--errors {
            color: $color-red-60;
            height: 20px;
            margin-top: 4px;
        }
    }

    &.editable-text-field--boxed {
        .editable-text-field__input {
            width: 100%;

            &:hover,
            &:focus-within {
                border-radius: 3px;
                padding: 1px;
                margin: -2px;
                width: calc(100% + 8px);
            }

            &:hover {
                border: 2px solid #dadce5;
            }

            &:focus-within {
                border: 2px solid #dadce5;
            }

            textarea,
            .editable-text-field__input__mask {
                padding-bottom: 0;
                border-bottom: 0;
                word-break: break-word;
            }

            .editable-text-field__input__pen {
                visibility: hidden;
            }

            &--disabled {
                &:hover,
                &:focus-within {
                    padding: 0;
                    margin: 0;
                    width: auto;
                    border: 0;
                }
            }

            &--errors {
                &:hover,
                &:focus-within {
                    border: 2px solid $color-red-60;
                }
            }
        }
    }
}
</style>
