var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workflows-highlightsAggData-card",attrs:{"data-cy":"workflows-highlightsAggData-card"}},[_c('pendo-card',{attrs:{"body-min-height":"108px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title"},[_vm._v(" Highlights ")]),_c('workflow-baseline-config',{attrs:{"saved-date-range":_vm.baselineDateRange,"earliest-app-first-visit":_vm.earliestAppFirstVisit,"disabled":!_vm.allowBaselineChange},on:{"save":_vm.handleBaselineDateRangeChange}})]},proxy:true}])},[_c('div',{staticClass:"stats"},[_c('div',{staticClass:"stat",attrs:{"data-cy":"completion-rate"}},[_c('div',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading",value:({
                        loading: _vm.loadingHighlights,
                        skeletonProps: { height: '36', width: '128' }
                    }),expression:"{\n                        loading: loadingHighlights,\n                        skeletonProps: { height: '36', width: '128' }\n                    }"}],staticClass:"number"},[_vm._v(" "+_vm._s(_vm.completionRate)+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.isRecurring ? 'Workflow completion rate' : 'Visitor completion rate')+" ")])]),_c('div',{staticClass:"stat",attrs:{"data-cy":"median-time-to-complete"}},[_c('div',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading",value:({
                        loading: _vm.loadingHighlights,
                        skeletonProps: { height: '36', width: '128' }
                    }),expression:"{\n                        loading: loadingHighlights,\n                        skeletonProps: { height: '36', width: '128' }\n                    }"}],staticClass:"number"},[_vm._v(" "+_vm._s(_vm.medianTimeToComplete)+" ")]),_c('div',{staticClass:"description"},[_vm._v(" Median time to complete ")])]),_c('div',{staticClass:"stat",attrs:{"data-cy":"visitor-completions"}},[_c('div',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading",value:({
                        loading: _vm.loadingHighlights,
                        skeletonProps: { height: '36', width: '128' }
                    }),expression:"{\n                        loading: loadingHighlights,\n                        skeletonProps: { height: '36', width: '128' }\n                    }"}],staticClass:"number"},[_vm._v(" "+_vm._s(_vm.visitorCompletions)+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.isRecurring ? 'Completed attempts' : 'Visitor completions')+" ")])])]),(_vm.baselineDateRange)?_c('div',{staticClass:"comparison",attrs:{"data-cy":"baseline-comparison"}},[_c('metric-comparison',{attrs:{"recurring":_vm.isRecurring,"loading":_vm.loadingHighlights || _vm.loadingBaseline,"baseline":_vm.baselineAggData,"current":_vm.highlightsAggData}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }