export const JOURNEY_TYPES = {
    FEWEST_STEPS: 'Fewest Steps',
    MOST_COMMON: 'Most Common',
    QUICKEST: 'Quickest'
};

export const INSIGHT_TYPES = {
    LONGEST_STEPS: 'longest-time-between-steps',
    REPEATED_SEQUENCES: 'repeated-sequences',
    REPEATED_STEPS: 'repeated-steps',
    QUICKEST_V_MOST_COMMON: 'quickest-vs-most-common'
};
