var render = function render(){var _vm=this,_c=_vm._self._c;return _c('pendo-modal',{staticClass:"add-custom-page-modal",attrs:{"visible":_vm.visible,"height":"auto","title":"Add Custom Pages"},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"add-custom-page-modal--description"},[_vm._v(" The page list is a great way to keep track of visitor usage. If a page is not represented in your current list, input the URL of the page you’d like to add. ")]),(_vm.customTaggingDescription)?_c('p',{staticClass:"add-custom-page-modal--description--custom"},[_vm._v(" "+_vm._s(_vm.customTaggingDescription)+" ")]):_vm._e(),_c('pendo-divider',{attrs:{"stroke":"#dadce5"}}),_c('pendo-form',{attrs:{"call-validate":_vm.callValidate,"model":_vm.model},on:{"formValidated":_vm.handleValidForm,"invalidForm":_vm.handleInvalidForm}},[_c('pendo-form-item',{staticClass:"app-selector-form-item",attrs:{"rules":[
                    {
                        required: true,
                        message: 'No selection made',
                        trigger: 'blur'
                    }
                ],"prop":"selectedApp"}},[_c('pendo-multiselect',{attrs:{"value":_vm.model.selectedApp,"allow-empty":false,"labels":{ left: 'Application' },"options":_vm.formattedAppOptions,"label-key":"name","placeholder":"- None Selected -"},on:{"select":_vm.onAppSelection}})],1),_c('pendo-form-item',{attrs:{"rules":_vm.rules.customUrl,"label":"Custom URL*","prop":"customUrl"}},[_c('pendo-input',{attrs:{"data-cy":"customurl","maxlength":"1000","placeholder":"https://"},model:{value:(_vm.model.customUrl),callback:function ($$v) {_vm.$set(_vm.model, "customUrl", $$v)},expression:"model.customUrl"}})],1)],1),_c('div',{staticClass:"add-custom-page-modal--body"},[_c('svg',{staticClass:"url-bracket",attrs:{"width":"9","height":"40","viewBox":"0 0 9 40","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M1 0H0V39V40H9V39H1V0Z","fill":"#DADCE5"}})]),_c('pendo-input',{attrs:{"data-cy":"displayname","maxlength":"1000","placeholder":"Page Name","label":"Page Display Name*"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),(_vm.addCustomPageError)?_c('pendo-alert',{staticClass:"add-custom-page-error",attrs:{"type":"error"}},[_vm._v(" Something went wrong. Try again - if the problem persists contact Pendo support. ")]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('pendo-button',{attrs:{"theme":"app","type":"secondary","label":"Cancel"},on:{"click":_vm.closeModal}}),_c('pendo-button',{attrs:{"disabled":_vm.disableAddPageButton,"theme":"app","type":"primary","label":"Add Page"},on:{"click":_vm.validateForm}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }