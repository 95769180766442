<template>
    <div class="difference-display">
        <div class="header previous">
            <Header
                v-if="showPreviousRevision"
                :is-previous="true"
                :subtitle="previousRevisionSubtitle" />
        </div>
        <div class="header selected">
            <Header :subtitle="selectedRevisionSubtitle">
                <template
                    v-if="isAdded && showTags"
                    #tag>
                    <pendo-tag
                        label="Step added"
                        color="#009855" />
                </template>
            </Header>
        </div>
        <template
            v-if="showDifferences"
            class="body">
            <template v-for="({ label, previous, selected, key }, index) in differencesOrPreviews">
                <div
                    :key="`difference-group-previous-${key}-${index}`"
                    class="difference-content previous">
                    <div
                        v-if="showPreviousRevisionInfo && label.length > 0 && !isAdded"
                        class="header">
                        {{ label }}
                    </div>
                    <Value
                        v-if="showPreviousRevisionInfo && !isAdded"
                        :difference="previous"
                        :is-status="STATE_KEY === key"
                        :is-language="LANGUAGE_KEY === key"
                        :is-email-config="EMAIL_CONFIG_KEY === key">
                        <template #preview>
                            <slot
                                v-if="$slots.previous"
                                name="previous" />
                        </template>
                    </Value>
                </div>
                <div
                    :key="`difference-group-selected-${key}-${index}`"
                    class="difference-content selected">
                    <div
                        v-if="label.length > 0"
                        class="header">
                        {{ label }}
                    </div>
                    <Value
                        :is-removed="isRemoved && key === 'preview'"
                        :difference="selected"
                        :is-language="LANGUAGE_KEY === key"
                        :is-status="STATE_KEY === key"
                        :is-email-config="EMAIL_CONFIG_KEY === key">
                        <template
                            #preview
                            v-if="$slots.selected">
                            <slot name="selected" />
                        </template>
                        <template #remove-tag>
                            <pendo-tag
                                label="Step removed"
                                color="#BD0000" />
                        </template>
                    </Value>
                </div>
            </template>
        </template>
    </div>
</template>
<script>
import { PendoTag } from '@pendo/components';
import { isValidDifferencesArray, GUIDE_AUDIT_PROPS_TO_CHECK } from '../guide-audit-utils';
import Header from './Header';
import Value from './Value';

export default {
    name: 'DifferencesDisplay',
    components: {
        Header,
        Value,
        PendoTag
    },
    props: {
        showPreviousRevision: {
            type: Boolean,
            default: false
        },
        isPrevious: {
            type: Boolean,
            default: false
        },
        isRemoved: {
            type: Boolean,
            default: false
        },
        showTags: {
            type: Boolean,
            default: true
        },
        isAdded: {
            type: Boolean,
            default: false
        },
        previousRevisionSubtitle: {
            type: String,
            default: ''
        },
        selectedRevisionSubtitle: {
            type: String,
            default: ''
        },
        differences: {
            type: Array,
            default: () => [],
            validator (differences) {
                return isValidDifferencesArray(differences);
            }
        }
    },
    data () {
        return {
            STATE_KEY: GUIDE_AUDIT_PROPS_TO_CHECK.STATE,
            EMAIL_CONFIG_KEY: GUIDE_AUDIT_PROPS_TO_CHECK.EMAIL_CONFIG,
            LANGUAGE_KEY: GUIDE_AUDIT_PROPS_TO_CHECK.LOCALIZATION
        };
    },
    computed: {
        version () {
            return this.isPrevious ? 'Previous' : 'Selected';
        },
        showPreviousRevisionInfo () {
            return this.showPreviousRevision || this.$slots.previousContent;
        },
        showDifferences () {
            return this.differencesOrPreviews.length > 0;
        },
        showPreviews () {
            return !!this.$slots.selected || !!this.$slots.previous;
        },
        differencesOrPreviews () {
            let differences = [];

            if (this.showPreviews) {
                differences.push({
                    label: '',
                    previous: '',
                    selected: '',
                    key: 'preview'
                });
            } else {
                differences = this.differences;
            }

            return differences;
        }
    }
};
</script>

<style scoped lang="scss">
.difference-display {
    width: 957px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 64px;
    grid-row-gap: 24px;

    thead,
    tbody,
    td,
    tr {
        display: block;
    }

    .difference-content {
        min-height: 50px;

        > .header {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
        }
    }
}
</style>
