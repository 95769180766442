<template>
    <div class="query-viewer-tag">
        <pendo-icon
            v-if="!!icon"
            size="12"
            stroke="#2a2c35"
            :type="icon" />

        <p class="query-viewer-tag--label">
            {{ value }}
        </p>
    </div>
</template>

<script>
import { PendoIcon } from '@pendo/components';

export default {
    name: 'WorkflowQueryViewerTag',
    components: {
        PendoIcon
    },
    props: {
        value: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
.query-viewer-tag {
    background: $gray-lighter-7;
    border: 1px solid $gray-lighter-5;
    border-radius: 15px;
    box-sizing: border-box;
    display: block;
    height: 30px;
    padding: 3px 16px;

    * {
        display: inline-block;
        vertical-align: middle;
    }

    &--label {
        color: $gray-primary;
        font-size: 12px;
        margin: 0 0 0 6px;
    }
}
</style>
