<template>
    <pendo-modal
        :key="`${guide.id}-status-change-confirmation-modal`"
        :title="settings.title"
        :message="settings.body"
        :esc-to-close="true"
        :click-to-close="true"
        :visible="isVisible"
        type="confirmation">
        <public-status-modal-body
            v-if="isVisible && status === 'public' && !isMobileTooltipNoActivation"
            :is-app-disabled="settings.props.isAppDisabled"
            :activation-type="settings.props.activationType"
            :show-segment-info="settings.props.showSegmentInfo"
            :segment-name="settings.props.segmentName"
            :visitor-count="settings.props.visitorCount" />
        <scheduled-status-modal-body
            v-if="isVisible && status === 'scheduled' && !isMobileTooltipNoActivation"
            :shows-after="settings.props.showsAfter"
            :expires-after="settings.props.expiresAfter" />
        <template #footer>
            <pendo-button
                type="tertiary"
                theme="app"
                label="Cancel"
                @click.stop="cancel" />
            <pendo-button
                :label="settings.confirmationLabel"
                theme="app"
                type="primary"
                @click.stop="isMobileTooltipNoActivation ? launchDesignerInSameTab() : confirm()" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoModal, PendoButton } from '@pendo/components';
import { GUIDE_STATUS_MODAL_CONFIG } from '@/constants/guide-statuses';
import { launchMethodToString } from '@/utils/guides';
import { isEditedByAdopt, isMobileTooltipGuide } from '@/stateless-components/utils/guides';
import { isMobile } from '@/stateless-components/utils/apps';
import { manageMobileDesignerLaunch } from '@/stateless-components/utils/designer';
import { DATE_FORMAT, convertToSubscriptionTimezone } from '@/utils/moment';
import { getVisitorTotal } from '@/aggregations/visitor-counts';
import { sendPendoTrackEvent } from '@/utils/utils';
import { getSegmentName } from '@/utils/segments';
import { mapState, mapGetters } from 'vuex';
import ScheduledStatusModalBody from '@/components/guides/common/ScheduledStatusModalBody';
import PublicStatusModalBody from '@/components/guides/common/PublicStatusModalBody';
import get from 'lodash/get';

export default {
    name: 'GuideStatusChangeConfirmationModal',
    components: {
        PendoModal,
        PendoButton,
        ScheduledStatusModalBody,
        PublicStatusModalBody
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        status: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            settings: {},
            isVisible: false
        };
    },
    computed: {
        ...mapState({
            segmentsMap: (state) => state.filters.segmentsMap
        }),
        ...mapGetters({
            appFromGuide: 'apps/appFromGuide',
            getActiveTimezone: 'subscriptions/getTimezone'
        }),
        segmentId () {
            return get(this, 'guide.audienceUiHint.filters[0].segmentId', null);
        },
        isMobileTooltip () {
            if (!this.isMobileGuide) return;

            const buildingBlocks = get(this.guide, 'steps[0].buildingBlocks');

            return isMobileTooltipGuide(buildingBlocks);
        },
        hasMobileTooltipActivation () {
            return this.guide.launchMethod === 'page-auto' || this.guide.launchMethod === 'api';
        },
        isMobileGuide () {
            return isMobile(this.appFromGuide(this.guide));
        },
        isMobileTooltipNoActivation () {
            return this.isMobileTooltip && !this.hasMobileTooltipActivation;
        }
    },
    async created () {
        if (!this.status) return;

        const status =
            this.isMobileTooltipNoActivation && this.status === 'public' ? 'mobileTooltipNoActivation' : this.status;

        const { title, body, confirm: confirmationLabel, bodyComponent: component } = GUIDE_STATUS_MODAL_CONFIG[status];

        const componentProps = await this.getStatusUpdateModalConfigComponentProps();
        this.settings = {
            title,
            body,
            component,
            props: componentProps,
            confirmationLabel
        };
        this.isVisible = true;
    },
    beforeDestroy () {
        this.settings = {};
        this.isVisible = false;
    },
    methods: {
        confirm () {
            const { guide, status } = this;
            this.trackGuideIfPublished();
            this.$emit('submit', { guide, status });
        },
        cancel () {
            const { guide, status } = this;
            this.$emit('cancel', { guide, status });
        },
        getAppState () {
            const { appFromGuide, guide } = this;
            const app = appFromGuide(guide);

            return get(app, 'applicationFlags.disableExtensionInjection', false);
        },
        getSegmentName () {
            return getSegmentName(this.segmentId, this.segmentsMap);
        },
        async getStatusUpdateModalConfigComponentProps () {
            const { guide, segmentId, status } = this;
            const { showsAfter: showsAfterTimezone, expiresAfter: expiresAfterTimezone } = guide;
            const showsAfter = convertToSubscriptionTimezone(this.getActiveTimezone, showsAfterTimezone).format(
                DATE_FORMAT.full
            );
            const expiresAfter =
                expiresAfterTimezone &&
                convertToSubscriptionTimezone(this.getActiveTimezone, expiresAfterTimezone).format(DATE_FORMAT.full);

            const isAppDisabled = this.getAppState();
            const segmentName = this.getSegmentName();
            const showSegmentInfo = status === 'public' || status === 'staged';
            const visitorCount = await getVisitorTotal({ segmentId });
            const activationType = launchMethodToString(guide.launchMethod);

            return {
                showsAfter,
                expiresAfter,
                isAppDisabled,
                showSegmentInfo,
                visitorCount,
                segmentName,
                activationType
            };
        },
        async trackGuideIfPublished () {
            const { guide, segmentId, status } = this;
            if (status !== 'public' || !isEditedByAdopt(guide)) return;

            const usersInSegment = await getVisitorTotal({ segmentId });
            const usersInSubscription = await getVisitorTotal();
            sendPendoTrackEvent({
                trackEventKey: 'guidePublished',
                properties: {
                    appId: get(guide, 'appIds[0]'),
                    guideId: guide.id,
                    usersInSegment,
                    usersInSubscription
                },
                adoptSpecific: true
            });
        },
        launchDesignerInSameTab () {
            const stepId = get(this.guide, 'steps[0].id', null);

            const whiteLabelToken = {
                ...this.appWhiteLabelSettings,
                productName: this.productName
            };

            manageMobileDesignerLaunch(stepId, this.guide, whiteLabelToken);
            this.isVisible = false;
        }
    }
};
</script>

<style lang="scss" scoped />
