var render = function render(){var _vm=this,_c=_vm._self._c;return _c('pendo-card',{staticClass:"guide-details-card",attrs:{"title":"Guide details"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"details-body",attrs:{"model":_vm.model}},[_c('pendo-input',{attrs:{"maxlength":"100","label":"Guide name","placeholder":"Unnamed [timestamp]"},on:{"input":_vm.onGuideNameUpdate}}),_c('pendo-multiselect',{attrs:{"full-width":"","value-key":"id","label-key":"name","disabled":_vm.themes.length === 0,"value":_vm.selectedTheme,"options":_vm.themes,"allow-empty":false,"labels":{ top: 'Theme' }},on:{"select":_vm.onThemeSelect}}),(_vm.categories.length)?_c('pendo-multiselect',{attrs:{"full-width":"","group-options-key":"items","group-label-key":"name","value-key":"id","label-key":"name","options":_vm.categories,"value":_vm.model.selectedCategory,"allow-empty":false,"labels":{ top: 'Category' }},on:{"select":_vm.onCategorySelect},scopedSlots:_vm._u([{key:"header",fn:function({ select }){return [_c('div',{staticClass:"pendo-layout-chooser__guide-category-sticky-header"},[_c('div',{class:[
                                'pendo-multiselect__option',
                                'pendo-layout-chooser__guide-category-sticky-header--option',
                                {
                                    'is-selected': _vm.model.selectedCategory.name === _vm.NO_GUIDE_CATEGORY_OPTION.name
                                }
                            ],on:{"click":function($event){return select(_vm.NO_GUIDE_CATEGORY_OPTION)}}},[_vm._v(" "+_vm._s(_vm.NO_GUIDE_CATEGORY_OPTION.name)+" ")])])]}}],null,false,1735961053)}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }