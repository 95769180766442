import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import { prettyTime } from '@pendo/services/Formatters';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

export const DATE_COLUMN_WIDTH = 200;

export function getColumnWidth (column) {
    let width;
    const schemaType = get(column, 'schema');
    if (schemaType === 'time') width = DATE_COLUMN_WIDTH;

    return width;
}

export function formatDataByType (type, value, timezone) {
    switch (type) {
        case 'boolean':
            return value && String(value);
        case 'list':
            return value && `[ ${value.join(', ')} ]`;
        case 'object':
            return value && `[ ${value.join(', ')} ]`;
        case 'string':
            return value;
        case 'number':
            return value;
        case 'integer':
            return (
                value &&
                `${value.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                })}`
            );
        case 'steps':
            return value && value.length;
        case 'time':
        case 'date':
            if (value === 0) value = null;

            return value && calculateDate(value, timezone);
        case 'prettyTime':
            if (isNumber(value) && value === 0) {
                return '--';
            }

            return prettyTime(value);
    }

    return value;
}

export function rowFormatter (row, column, timezone) {
    const columnVal = get(row, column.prop);
    const schema = get(column, 'schema');

    return formatDataByType(schema, columnVal, timezone);
}

export function calculateDate (date, timezone) {
    let dateInput = date;

    if (typeof date === 'string') {
        const dateInNumber = parseInt(date);
        const stringDate = `${date}`;
        if (stringDate && stringDate.length === 10) {
            dateInput = dateInNumber * 1000;
        } else if (stringDate && stringDate.length === 13) {
            dateInput = dateInNumber;
        }
    }

    if (typeof date === 'number') {
        const stringDate = `${date}`;
        const dateInMs = stringDate.length === 13 ? date : date * 1000;
        dateInput = dateInMs;
    }
    let formattedDate = convertToSubscriptionTimezone(timezone, dateInput).format(DATE_FORMAT.full);
    if (formattedDate === 'Invalid date') {
        try {
            const parsedDate = JSON.parse(dateInput);
            formattedDate = convertToSubscriptionTimezone(timezone, parsedDate).format(DATE_FORMAT.full);
        } catch (err) {
            return 'Invalid date';
        }
    }

    return formattedDate;
}
