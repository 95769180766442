import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import { http } from '@pendo/http';
import { addAppToEntityList } from '@/utils/apps';
import { getPageList, getPageActivity, getSinglePageActivity } from '@/aggregations/page-activity';
import { filterUnsharedResources } from '@/utils/modules';
import { isCancel } from 'axios';
import {
    fetchPageLoadEvents,
    fetchSubscriptionPageRules,
    fetchSuggestedPageRulesHandledList,
    convertSubscriptionPageRules,
    generateSuggestedPageRules
} from '@/utils/pages';
import { v4 as uuid } from 'uuid';
import { canAppCreateCountable, canCreateCountable } from '@/utils/countable-permissions';
import { entityIdToNameMap } from '@/stateless-components/utils/guides';

let fetchPromise;

export function getInitialState () {
    return {
        isFetching: false,
        isCreatingPage: false,
        isUpdatingPage: false,
        isDeletingPage: false,
        isFetchingWithAnalytics: false,
        map: {},
        activeSubHasAnyPages: false,
        error: null,
        suggestedPagesError: null,
        mapWithAnalytics: {},
        aggCancel: null,
        pageLoadEvents: [],
        subscriptionPageRules: [],
        suggestedPageRulesHandledList: [],
        suggestedPageRules: [],
        suggestedPageRulesFilteredData: [],
        isPageDetailsSidebarOpen: false
    };
}

export const state = getInitialState();

export const mutations = {
    setMap (state, { map }) {
        Vue.set(state, 'map', map);
    },
    setMapAtKey (state, { key }) {
        Vue.set(state.map, key.id, key);
    },
    deleteMapAtKey (state, { key }) {
        Vue.delete(state.map, key.id);
    },
    setMapWithAnalytics (state, { mapWithAnalytics }) {
        state.mapWithAnalytics = mapWithAnalytics;
    },
    setMapWithAnalyticsAtKey (state, { key }) {
        Vue.set(state.mapWithAnalytics, key.id, key);
    },
    setActiveSubHasAnyPages (state, { activeSubHasAnyPages }) {
        state.activeSubHasAnyPages = activeSubHasAnyPages;
    },
    deleteMapWithAnalyticsAtKey (state, { key }) {
        Vue.delete(state.mapWithAnalytics, key.id);
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setCreatingPage (state, { isCreatingPage }) {
        state.isCreatingPage = isCreatingPage;
    },
    setUpdatingPage (state, { isUpdatingPage }) {
        state.isUpdatingPage = isUpdatingPage;
    },
    setDeletingPage (state, { isDeletingPage }) {
        state.isDeletingPage = isDeletingPage;
    },
    setFetchingWithAnalytics (state, { isFetchingWithAnalytics }) {
        state.isFetchingWithAnalytics = isFetchingWithAnalytics;
    },
    setError (state, { error }) {
        state.error = error;
    },
    setSuggestedPagesError (state, { error }) {
        state.suggestedPagesError = error;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    },
    setAggCancel (state, { aggCancel }) {
        state.aggCancel = aggCancel;
    },
    setPageLoadEvents (state, { pageLoadEvents }) {
        state.pageLoadEvents = pageLoadEvents;
    },
    setSubscriptionPageRules (state, { subscriptionPageRules }) {
        state.subscriptionPageRules = subscriptionPageRules;
    },
    setSuggestedPageRulesHandledList (state, { suggestedPageRulesHandledList }) {
        state.suggestedPageRulesHandledList = suggestedPageRulesHandledList;
    },
    setSuggestedPageRules (state, { suggestedPageRules }) {
        state.suggestedPageRules = suggestedPageRules;
    },
    setSuggestedPageRulesFilteredData (state, { suggestedPageRulesFilteredData }) {
        state.suggestedPageRulesFilteredData = suggestedPageRulesFilteredData;
    },
    setPageDetailsSidebarOpen (state, { isPageDetailsSidebarOpen }) {
        state.isPageDetailsSidebarOpen = isPageDetailsSidebarOpen;
    }
};

export const actions = {
    async hydrate ({ dispatch }) {
        await dispatch('fetchPageLoadEvents');
        await dispatch('fetchSubscriptionPageRules');
        await dispatch('fetchSuggestedPageRulesHandledList');
    },
    async fetch ({ commit, state, rootGetters }, { noCache = false } = {}) {
        if (state.isFetching) {
            await fetchPromise;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });

        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        if (usesMultiApp) {
            fetchPromise = fetchAllPagesForSubscription();
        } else {
            fetchPromise = getPageList({
                appId: rootGetters['apps/activeId']
            });
        }

        const list = await fetchPromise;
        // Used to determine empty state CTA visibility
        commit('setActiveSubHasAnyPages', { activeSubHasAnyPages: !!list.length });
        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const pagesForAvailableApps = list.filter((page) => !!appMapForActiveSubscription[page.appId]);

        const displayNameFormattedList = pagesForAvailableApps.map((page) => {
            const displayName = get(page, 'trainingSettings.displayName', page.name);
            page.displayName = displayName;

            return page;
        });

        commit('setMap', { map: keyBy(displayNameFormattedList, 'id') });
        commit('setFetching', { isFetching: false });
    },
    async fetchWithAnalytics ({ commit, state, rootState, rootGetters }, { noCache = false } = {}) {
        if (rootState.filters.isFetchingPagesFromChartClick) {
            return;
        }

        if (state.aggCancel) {
            state.aggCancel.abort();
        }

        if (!isEmpty(state.mapWithAnalytics) && !noCache) {
            return;
        }

        commit('setAggCancel', { aggCancel: new AbortController() });
        commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: true });

        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const { activeSegmentId, appIdsFilter } = rootState.filters;
        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        const appId = usesMultiApp ? appIdsFilter : rootGetters['apps/activeId'];

        let list = [];

        try {
            list = await getPageActivity({
                appId,
                timeSeries,
                segmentId: activeSegmentId,
                signal: get(state, 'aggCancel.signal')
            });
            commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
        } catch (err) {
            if (!isCancel(err)) {
                commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
            }
        }
        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const pagesForAvailableApps = list.slice().filter((page) => !!appMapForActiveSubscription[page.appId]);
        const displayNameFormattedList = pagesForAvailableApps.map((page) => {
            const displayName = get(page, 'trainingSettings.displayName', page.name);
            page.displayName = displayName;

            return page;
        });

        commit('setMapWithAnalytics', { mapWithAnalytics: keyBy(displayNameFormattedList, 'id') });
        commit('filters/setIsFetchingPagesFromChartClick', false, { root: true });
    },

    async fetchSinglePageWithAnalytics ({ commit, rootState, rootGetters }, { page }) {
        const { pageId } = page;
        const displayName = page.displayName || get(page, 'trainingSettings.displayName', page.name);
        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const { activeSegmentId } = rootState.filters;
        const rows = await getSinglePageActivity({
            pageId,
            appId: rootGetters['apps/activeId'],
            timeSeries,
            segmentId: activeSegmentId
        });

        const pageWithAnalytics = { id: pageId, displayName, ...page, ...rows[0] };

        commit('setMapWithAnalyticsAtKey', { key: pageWithAnalytics });
        commit('setMapAtKey', { key: pageWithAnalytics });
    },

    fetchUpdatesForFilterChange ({ state, dispatch, rootGetters }) {
        // Don't bother attempting to fetch and reload data if the sub has no pages (or has not yet loaded its pages)
        if (!state.activeSubHasAnyPages) return;

        const clearPagesCache = !rootGetters['subscriptions/usesMultiApp'];
        dispatch('fetch', { noCache: clearPagesCache });
        dispatch('fetchWithAnalytics', { noCache: true });
    },

    async deleteCustomChildPage ({ commit }, { customPage }) {
        try {
            await deleteCustomChildPage(customPage.id);
            commit('deleteMapAtKey', { key: customPage });
            commit('deleteMapWithAnalyticsAtKey', { key: customPage });
        } catch (err) {
            throw err;
        }
    },

    async createCustomPage ({ dispatch, commit }, { customPage }) {
        commit('setError', { err: null });
        commit('setCreatingPage', { isCreatingPage: true });

        let response;

        try {
            response = await createCustomPage(customPage);
            dispatch('fetch', { noCache: true });
            dispatch('fetchWithAnalytics', { noCache: true });
        } catch (error) {
            commit('setError', { error });
        }

        commit('setCreatingPage', { isCreatingPage: false });
        dispatch('fetchSubscriptionPageRules');

        return response;
    },

    async updateCustomPage ({ dispatch, commit }, { customPage }) {
        commit('setError', { err: null });
        commit('setUpdatingPage', { isUpdatingPage: true });

        try {
            await updateCustomPage(customPage);
            dispatch('fetch', { noCache: true });
            dispatch('fetchWithAnalytics', { noCache: true });
        } catch (error) {
            commit('setError', { error });
        }

        commit('setUpdatingPage', { isUpdatingPage: false });
        dispatch('fetchSubscriptionPageRules');
    },

    async deleteCustomPage ({ dispatch, commit }, { customPage }) {
        commit('setError', { err: null });
        commit('setDeletingPage', { isDeletingPage: true });

        try {
            await deleteCustomPage(customPage);
            dispatch('fetch', { noCache: true });
            dispatch('fetchWithAnalytics', { noCache: true });
        } catch (error) {
            commit('setError', { error: error.message });
        }

        commit('setDeletingPage', { isDeletingPage: false });
        dispatch('fetchSubscriptionPageRules');
    },

    async fetchPageLoadEvents ({ commit, rootState }) {
        commit('setSuggestedPagesError', { err: null });
        const showSuggestedPagesTable = get(rootState, 'userSettings.userPreferences.suggestedPagesTable.value', false);
        let pageLoadEvents = [];
        if (!showSuggestedPagesTable) {
            commit('setPageLoadEvents', { pageLoadEvents: [] });

            return [];
        }

        const activeSubscriptionId = get(rootState, 'subscriptions.activeId', null);
        if (isNil(activeSubscriptionId)) {
            commit('setPageLoadEvents', { pageLoadEvents: [] });

            return [];
        }
        const activeSubscription = get(rootState, `subscriptions.map[${activeSubscriptionId}]`, null);
        if (isNil(activeSubscription)) {
            commit('setPageLoadEvents', { pageLoadEvents: [] });

            return [];
        }
        if (isNil(get(activeSubscription, 'applications', []))) {
            commit('setPageLoadEvents', { pageLoadEvents: [] });

            return [];
        }
        const activeSubscriptionApps = get(activeSubscription, 'applications', []);
        const eventFileShards = get(activeSubscription, 'eventFileShards', 1);

        let sampleDenominator = Math.max(eventFileShards, 1);
        const appId = activeSubscriptionApps.reduce((acc, app) => {
            // only get pageLoadEvents from apps with suggestedPageTags flag and ability to create pages
            if (get(app, 'applicationFlags.suggestPageTags', null) && canAppCreateCountable(app.id, 'page')) {
                acc.push(app.id);
                const eventFilePseudoShardCount = get(app, 'eventFilePseudoShardCount', 1);
                sampleDenominator = Math.max(sampleDenominator, eventFilePseudoShardCount);
            }

            return acc;
        }, []);
        if (isEmpty(appId)) {
            commit('setPageLoadEvents', { pageLoadEvents: [] });

            return [];
        }
        const eventRate = get(activeSubscription, 'eventRate', 1);
        const MAX_EVENTS_PER_HOUR = 2500000;
        const MAX_HOUR_COUNT = 164;
        const count = Math.min(MAX_HOUR_COUNT, Math.max(Math.floor(MAX_EVENTS_PER_HOUR / eventRate), 1)) * -1;

        const aggParams = {
            appId,
            blacklist: 'apply',
            timeSeries: {
                first: 'now()',
                period: 'hourRange',
                count
            },
            sampleDenominator
        };

        try {
            pageLoadEvents = await fetchPageLoadEvents(aggParams);
        } catch (error) {
            commit('setSuggestedPagesError', { error });
        }
        commit('setPageLoadEvents', { pageLoadEvents });

        return pageLoadEvents;
    },

    async fetchSubscriptionPageRules ({ commit, rootState }) {
        commit('setSuggestedPagesError', { err: null });
        let subscriptionPageRules = [];
        const showSuggestedPagesTable = get(rootState, 'userSettings.userPreferences.suggestedPagesTable.value', false);
        if (!showSuggestedPagesTable) {
            commit('setSubscriptionPageRules', { subscriptionPageRules });

            return subscriptionPageRules;
        }

        try {
            subscriptionPageRules = await fetchSubscriptionPageRules();
        } catch (error) {
            commit('setSuggestedPagesError', { error });
        }
        subscriptionPageRules = convertSubscriptionPageRules(subscriptionPageRules);
        commit('setSubscriptionPageRules', { subscriptionPageRules });

        return subscriptionPageRules;
    },

    async fetchSuggestedPageRulesHandledList ({ commit, rootState }) {
        commit('setSuggestedPagesError', { err: null });
        let suggestedRulesHandledList = [];
        const showSuggestedPagesTable = get(rootState, 'userSettings.userPreferences.suggestedPagesTable.value', false);
        if (!showSuggestedPagesTable) {
            commit('setSuggestedPageRulesHandledList', { suggestedPageRulesHandledList: [] });

            return suggestedRulesHandledList;
        }

        try {
            suggestedRulesHandledList = await fetchSuggestedPageRulesHandledList();
        } catch (error) {
            commit('setSuggestedPagesError', { error });
        }

        const suggestedPageRulesHandledList = suggestedRulesHandledList.filter((rule) => rule.type === 'page');
        commit('setSuggestedPageRulesHandledList', { suggestedPageRulesHandledList });

        return suggestedRulesHandledList;
    },

    async createSuggestedPageRules ({ state, commit, rootState, dispatch }) {
        const subId = get(rootState, 'subscriptions.activeId', null);
        if (isNil(subId)) {
            commit('setSuggestedPageRules', { suggestedPageRules: [] });
            commit('setSuggestedPageRulesFilteredData', { suggestedPageRulesFilteredData: [] });

            return [];
        }
        const subscription = get(rootState, `subscriptions.map[${subId}]`, null);
        if (isNil(subscription)) {
            commit('setSuggestedPageRules', { suggestedPageRules: [] });
            commit('setSuggestedPageRulesFilteredData', { suggestedPageRulesFilteredData: [] });

            return [];
        }
        const applications = get(subscription, 'applications', []);
        if (isNil(applications) || isEmpty(applications)) {
            commit('setSuggestedPageRules', { suggestedPageRules: [] });
            commit('setSuggestedPageRulesFilteredData', { suggestedPageRulesFilteredData: [] });

            return [];
        }
        const suggestedPageRules = get(state, 'pageLoadEvents', []).reduce((acc, event) => {
            const { rule, name, sampleCount } = generateSuggestedPageRules(event);

            const application = applications.find((app) => app.id === event.appId);
            if (isNil(application)) {
                return acc;
            }
            const displayName = get(application, 'displayName', '');

            event = {
                ...event,
                id: uuid(),
                rule,
                samples: event.samples.slice(0, sampleCount),
                name,
                application: displayName
            };

            const ruleHandled = get(state, 'suggestedPageRulesHandledList', []).find(
                (app) => app.appId === event.appId && app.rule === event.rule
            );
            if (ruleHandled) return acc;

            acc.push(event);

            return acc;
        }, []);

        commit('setSuggestedPageRules', { suggestedPageRules });
        dispatch('createSuggestedPageRulesFilteredData');

        return suggestedPageRules;
    },
    createSuggestedPageRulesFilteredData ({ state, commit, rootState }) {
        const activeSubscriptionId = get(rootState, 'subscriptions.activeId', null);
        if (isNil(activeSubscriptionId)) {
            commit('setSuggestedPageRulesFilteredData', { suggestedPageRulesFilteredData: [] });

            return [];
        }
        const activeSubscription = get(rootState, `subscriptions.map[${activeSubscriptionId}]`, null);
        if (isNil(activeSubscription)) {
            commit('setSuggestedPageRulesFilteredData', { suggestedPageRulesFilteredData: [] });

            return [];
        }
        const applications = get(activeSubscription, 'applications', []);
        if (isNil(applications) || isEmpty(applications)) {
            commit('setSuggestedPageRulesFilteredData', { suggestedPageRulesFilteredData: [] });

            return [];
        }
        const appIdsWithSuggestedPageFlag = applications.reduce((acc, app) => {
            if (get(app, 'applicationFlags.suggestPageTags', false)) {
                acc.push(app.id);
            }

            return acc;
        }, []);
        const appIdsFilter = get(rootState, 'filters.appIdsFilter', []);
        const appIdsFilterWithFlag =
            appIdsFilter.length === 1 && isString(appIdsFilter[0]) && appIdsFilter[0].includes('expandAppIds')
                ? appIdsWithSuggestedPageFlag
                : appIdsFilter.filter((appId) => appIdsWithSuggestedPageFlag.includes(appId));
        const suggestedPageRulesFilteredData = get(state, 'suggestedPageRules', []).filter((suggestedRule) =>
            appIdsFilterWithFlag.includes(suggestedRule.appId)
        );
        commit('setSuggestedPageRulesFilteredData', { suggestedPageRulesFilteredData });

        return suggestedPageRulesFilteredData;
    }
};

export const getters = {
    pageById: (state) => (id) => get(state, `map['${id}']`, null),
    list (state, getters, rootState, rootGetters) {
        const filteredResources = filterUnsharedResources(state.map);

        return addAppToEntityList(filteredResources, rootGetters['apps/appMapForActiveSubscription']);
    },
    listWithAnalytics (state, getters, rootState, rootGetters) {
        const filteredResources = filterUnsharedResources(state.mapWithAnalytics);

        return addAppToEntityList(filteredResources, rootGetters['apps/appMapForActiveSubscription']);
    },
    listAll (state, getters, rootState, rootGetters) {
        return addAppToEntityList(Object.values(state.map), rootGetters['apps/appMapForActiveSubscription']);
    },
    listAllWithAnalytics (state, getters, rootState, rootGetters) {
        return addAppToEntityList(
            Object.values(state.mapWithAnalytics),
            rootGetters['apps/appMapForActiveSubscription']
        );
    },
    listContainsParentPage (state, getters) {
        return getters.list.some((page) => {
            return page.allowChildPages;
        });
    },
    canCreatePage: (state, getters, rootState, rootGetters) => ({ filtered = true }) => {
        const apps = filtered ? rootGetters['filters/appsForAppIdFilter'] : rootGetters['apps/getListActiveApps'];
        if (rootGetters['subscriptions/activeIsDigitalAdoption']) {
            return canCreateCountable({ apps, countableKind: 'page' });
        }

        return canAppCreateCountable(rootGetters['apps/activeId'], 'page');
    },
    namesById (state, getters) {
        return entityIdToNameMap(getters.listAll);
    }
};

export function createCustomPage (customPage, subId = null) {
    const { name, appId, rules, suggestedTagRules } = customPage;

    if (!subId) {
        return http.post('/api/s/_SID_/page', { name, rules, appId, suggestedTagRules }).then((res) => res.data);
    }

    return http.post(`/api/s/${subId}/page`, { name, rules, appId, suggestedTagRules }).then((res) => res.data);
}

async function deleteCustomPage (customPage) {
    const { pageId, appId } = customPage;

    return http.delete(`/api/s/_SID_/page/${pageId}`, { appId });
}

function updateCustomPage (customPage) {
    const { appId, pageId, name, rules, excludeRules } = customPage;

    return http
        .put(`/api/s/_SID_/page/${pageId}`, {
            appId,
            rules,
            excludeRules,
            ...(name && { name })
        })
        .then((res) => res.data);
}

export function createCustomChildPage (customPage) {
    const { parentPageId, parameter, displayName } = customPage;

    return http
        .post('/api/s/_SID_/childpage', {
            parentPageId,
            parameter,
            displayName
        })
        .then((res) => res.data);
}

export function editCustomPageDisplayName (customPageId, displayName) {
    return http
        .put(`/api/s/_SID_/childpage/${customPageId}`, {
            displayName
        })
        .then((res) => res.data);
}

export function deleteCustomChildPage (customPageId) {
    return http.delete(`/api/s/_SID_/childpage/${customPageId}`);
}

export async function fetchAllPagesForSubscription () {
    return http.get('/api/s/_SID_/page?expand=*').then((res) => res.data);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
