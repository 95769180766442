<template>
    <div
        :id="drawerId"
        class="pendo-dropdown-button-split--guide-create">
        <pendo-multiselect
            :min-menu-width="100"
            :options="formattedOptions"
            @select="select">
            <template #trigger>
                <pendo-split-button-trigger
                    theme="app"
                    type="primary"
                    class="create-guide"
                    :label="buttonOption.label"
                    @click="
                        () => {
                            select(buttonOption);
                        }
                    " />
            </template>
        </pendo-multiselect>
    </div>
</template>

<script>
import { PendoMultiselect, PendoSplitButtonTrigger } from '@pendo/components';
import has from 'lodash/has';

export default {
    name: 'DropdownButtonSplit',
    components: {
        PendoMultiselect,
        PendoSplitButtonTrigger
    },
    props: {
        buttonOptionId: {
            type: String,
            required: true
        },
        dropdownOptions: {
            type: Array,
            required: true,
            validator (arrayOfOptionObjects) {
                if (arrayOfOptionObjects.length === 0) return true;

                const optionsWithMissingValues = arrayOfOptionObjects.filter(
                    (option) => !has(option, 'id') || !has(option, 'label')
                );

                return optionsWithMissingValues.length <= 0;
            }
        }
    },
    computed: {
        formattedOptions () {
            return this.dropdownOptions.map((option) => ({
                id: option.id,
                label: option.label
            }));
        },
        buttonOption () {
            const { buttonOptionId } = this;
            const buttonOption = this.formattedOptions.find((option) => option.id === buttonOptionId);

            return buttonOption;
        },
        drawerId () {
            return `drawer-${this._uid}`;
        }
    },
    methods: {
        select (option) {
            this.$emit('select', option);
        }
    }
};
</script>

<style>
.dropdown-button-split__drawer {
    position: absolute;
}
</style>
