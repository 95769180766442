<template>
    <div class="guide-expansion-filter-container">
        <div class="guide-expansion-filters">
            <pendo-multiselect-filter
                v-model="statuses"
                :options="statusesFilter"
                label-key="name"
                entity-name="Statuses"
                @input="updateExpansionFilter(statuses, 'statuses')" />
            <pendo-multiselect-filter
                v-model="activations"
                :options="activationsFilter"
                entity-name="Activations"
                @input="updateExpansionFilter(activations, 'activations')" />
            <pendo-multiselect-filter
                v-model="expirations"
                :options="GUIDE_EXPANSION_FILTER_OPTIONS.expirations"
                entity-name="Expirations"
                @input="updateExpansionFilter(expirations, 'expirations')" />
            <pendo-multiselect-filter
                v-model="creators"
                :show-all-label="false"
                :show-search="true"
                :options="createdByOptions"
                entity-name="Created By"
                empty-label="Users"
                search-placeholder="Search"
                @input="updateExpansionFilter(creators, 'creators')" />
        </div>
        <div class="clear-filters">
            <pendo-button
                v-if="areFiltersSet"
                theme="app"
                type="link"
                label="Clear Filters"
                @click="clearExpansionFilters" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoButton, PendoMultiselectFilter } from '@pendo/components';
import { GUIDE_EXPANSION_FILTER_OPTIONS } from '@/utils/filters';
import PendoTableUserSettingsWatcher from '@/directives/PendoTableUserSettingsWatcher';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

export default {
    name: 'GuideListExpansionFilters',
    components: {
        PendoButton,
        PendoMultiselectFilter
    },
    directives: {
        PendoTableUserSettingsWatcher
    },
    props: {
        activeFilters: {
            type: Array,
            default: () => []
        },
        showMobileApps: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            GUIDE_EXPANSION_FILTER_OPTIONS,
            guideTableExpansionFiltersSettingsName: 'guide-table-expansion-filters',
            statuses: [],
            activations: [],
            expirations: [],
            creators: []
        };
    },
    computed: {
        ...mapGetters({
            guides: 'guides/list',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        ...mapState({
            guidesAreFetching: (state) => state.guides.isFetching,
            user: (state) => state.auth.user
        }),
        statusesFilter () {
            if (!this.activeIsDigitalAdoption) {
                return GUIDE_EXPANSION_FILTER_OPTIONS.statuses.filter((status) => status.id !== 'staged');
            }

            return GUIDE_EXPANSION_FILTER_OPTIONS.statuses;
        },
        activationsFilter () {
            let filters = GUIDE_EXPANSION_FILTER_OPTIONS.activations;

            if (!this.activeIsDigitalAdoption) {
                filters = without(filters, 'Confirmation');
            }

            if (!this.showMobileApps) {
                filters = without(filters, 'App Launch', 'Page View');
            }

            return filters;
        },
        areFiltersSet () {
            return (
                !!this.statuses.length ||
                !!this.activations.length ||
                !!this.expirations.length ||
                !!this.creators.length
            );
        },
        createdByMeOption () {
            return {
                id: this.user.email,
                label: 'Created By Me',
                className: this.hasUsers ? 'border-bottom' : ''
            };
        },
        uniqueUsernames () {
            const usernames = this.guides.map((guide) => guide.createdByUser.username);

            return uniq(usernames).sort();
        },
        hasUsers () {
            return !isEmpty(this.uniqueUsernames);
        },
        createdByOptions () {
            return [this.createdByMeOption, ...this.uniqueUsernames];
        }
    },
    created () {
        this.setLocalFilters();
    },
    methods: {
        ...mapActions({
            updateUserSettingByName: 'userSettings/updateUserSettingByName'
        }),
        setLocalFilters () {
            this.activeFilters.forEach((filter) => {
                if (filter.type === 'statuses' && !isEmpty(filter.value)) {
                    this.buildSelectedStatusOptions(filter.value);

                    return;
                }

                this[filter.type] = filter.value;
            });
        },
        buildSelectedStatusOptions (selectedStatuses) {
            const statusFiltersMap = keyBy(GUIDE_EXPANSION_FILTER_OPTIONS.statuses, 'name');
            this.statuses = selectedStatuses.reduce((acc, value) => {
                const option = statusFiltersMap[value];

                if (option) acc.push(option);

                return acc;
            }, []);
        },
        async updateExpansionFilter (config, type) {
            const existingFilter = this.activeFilters.find((filter) => filter.type === type);
            const value = type === 'statuses' ? config.map((val) => val.name) : config;

            Object.assign(existingFilter, { value });

            await this.updateUserSettingByName({
                settingsName: this.guideTableExpansionFiltersSettingsName,
                value: this.activeFilters
            });
        },
        async clearExpansionFilters () {
            this.statuses = [];
            this.activations = [];
            this.expirations = [];
            this.creators = [];

            const clearedFilters = this.activeFilters.map((filter) => {
                return {
                    ...filter,
                    value: []
                };
            });

            await this.updateUserSettingByName({
                settingsName: this.guideTableExpansionFiltersSettingsName,
                value: clearedFilters
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.guide-expansion-filter-container {
    display: flex;
    width: 100%;

    .guide-expansion-filters {
        display: flex;
        width: 100%;
        margin: 4px auto;
        gap: 16px;
    }

    .clear-filters {
        margin-left: auto;
        margin-right: 4px;
    }
}
</style>
