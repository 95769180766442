<template>
    <div>
        <page-header
            :editable="!isCrossApp && canEditGuideName"
            :title="name"
            :show-banner="true"
            :specific-app="app"
            :input-props="{ maxlength: 72 }"
            :before-exit="confirmGuideName"
            class="guide-details-header">
            <template #top>
                <div class="guide-details-header--back">
                    <router-link to="/guides">
                        <pendo-button
                            theme="app"
                            type="link"
                            size="mini"
                            prefix-icon="chevron-left"
                            label="Back to Guide List" />
                    </router-link>
                </div>
            </template>
            <template #bottom>
                <div class="guide-details-header--details">
                    <pendo-app-display
                        data-cy="guide-details-header--apps"
                        class="guide-details-header--apps"
                        :apps="appsFromGuide(guide)"
                        :show-cross-app-display="isCrossApp" />
                    <span
                        v-if="guideTypeIcon"
                        class="details-spacer">
                        |
                    </span>
                    <pendo-icon
                        v-if="guideTypeIcon"
                        class="guide-details-header__guide-icon"
                        size="1.25em"
                        :type="guideTypeIcon" />
                    <div
                        v-if="createdBy"
                        class="created-by">
                        <span>Created by {{ createdBy }}</span>
                        <span
                            v-if="createdBy && lastUpdatedBy"
                            class="details-spacer">
                            |
                        </span>
                    </div>
                    <div
                        v-if="lastUpdatedBy"
                        class="last-updated-by">
                        Last updated by {{ lastUpdatedBy }}
                    </div>
                </div>
            </template>
            <template #actions>
                <guide-status-changer
                    :guide="guide"
                    class="guide-status-changer"
                    :hide-staged-option="isStagedOptionHidden"
                    guide-type="organization"
                    @select="showStatusUpdateConfirmationModal" />
                <guide-utilities
                    :guide="guide"
                    @openGuideCopyDrawer="openGuideCopyDrawer"
                    @openHistoryDrawer="openHistoryDrawer">
                    <template #default="{ toggleMenu }">
                        <pendo-button
                            theme="app"
                            type="secondary"
                            icon="more-horizontal"
                            icon-size="18"
                            @click="toggleMenu" />
                    </template>
                </guide-utilities>
            </template>
        </page-header>
        <guide-status-change-confirmation-modal
            v-if="selectedStatus != ''"
            :guide="guide"
            :status="selectedStatus"
            @cancel="hideStatusUpdateConfirmationModal"
            @submit="confirmGuideStatus" />
    </div>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { PendoAppDisplay, PendoButton, PendoIcon } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import GuideStatusChanger from '@/components/guides/common/GuideStatusChanger';
import GuideUtilities from '@/components/guides/common/GuideUtilities';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import { canEditGuide } from '@/utils/guide-permissions';
import { isBadgeGuide, isConfirmationGuide, isEditedByAdopt } from '@/stateless-components/utils/guides';
import GuideStatusChangeConfirmationModal from '@/components/guides/common/GuideStatusChangeConfirmationModal';

export default {
    name: 'GuideDetailsHeader',
    components: {
        PendoAppDisplay,
        PendoIcon,
        PageHeader,
        PendoButton,
        GuideStatusChanger,
        GuideUtilities,
        GuideStatusChangeConfirmationModal
    },
    data () {
        return {
            selectedStatus: ''
        };
    },
    computed: {
        ...mapGetters({
            guide: 'guides/active',
            appFromGuide: 'apps/appFromGuide',
            appsFromGuide: 'apps/appsFromGuide',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        name () {
            return get(this.guide, 'name');
        },
        createdBy () {
            return get(this.guide, 'createdByUser.username', null);
        },
        lastUpdatedBy () {
            return get(this.guide, 'lastUpdatedByUser.username', null);
        },
        isCrossApp () {
            return isCrossApp(this.guide);
        },
        app () {
            return this.appFromGuide(this.guide);
        },
        canEditGuideName () {
            return canEditGuide({ guide: this.guide, field: 'name' });
        },
        canShowIcon () {
            return isEditedByAdopt(this.guide);
        },
        guideTypeIcon () {
            if (!this.canShowIcon) return '';

            if (isConfirmationGuide(this.guide)) return 'confirmation';

            if (isBadgeGuide(this.guide)) return 'info';

            return 'half-loader';
        },
        isStagedOptionHidden () {
            return !this.activeIsDigitalAdoption || this.isCrossApp;
        }
    },
    methods: {
        ...mapMutations({
            setStatusConfirmationOpen: 'guides/setStatusConfirmationOpen',
            setPendingStatus: 'guides/setPendingStatus'
        }),
        ...mapActions({
            patchGuide: 'guides/patch'
        }),
        showStatusUpdateConfirmationModal ({ status }) {
            this.selectedStatus = status;
        },
        hideStatusUpdateConfirmationModal () {
            this.selectedStatus = '';
        },
        async confirmGuideName (name) {
            if (name === this.guide.name) {
                return;
            }

            await this.updateGuide({ name });
        },
        async confirmGuideStatus ({ status }) {
            const state = status === 'scheduled' ? 'public' : status;
            await this.updateGuide({ state });
            this.hideStatusUpdateConfirmationModal();
        },
        async updateGuide (props) {
            await this.patchGuide({ guideId: this.guide.id, props });
        },
        openGuideCopyDrawer () {
            this.$emit('openGuideCopyDrawer');
        },
        openHistoryDrawer () {
            this.$emit('openHistoryDrawer');
        }
    }
};
</script>

<style lang="scss">
.guide-details-header {
    &--button-group {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 8px;
        align-items: center;
        justify-content: end;
    }

    &--details {
        color: $gray-lighter-2;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        min-height: 36px;
        font-size: 0.875em;
        @include ellipsis;

        .details-spacer {
            margin-left: 0.5em;
            margin-right: 0.5em;
        }
    }

    &--apps {
        color: $gray-primary;
    }

    .created-by {
        margin-left: 2em;
    }
}
</style>
