export function formatSampleMetadata (sample = '') {
    if (Array.isArray(sample)) {
        const hasComma = sample.some((value) => typeof value === 'string' && value.indexOf(',') > -1);
        const delimiter = hasComma ? '; ' : ', ';

        return sample.join(delimiter);
    }

    return String(sample);
}
