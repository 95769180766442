<template>
    <time-on-step-chart v-if="isMultistep" />
    <time-on-guide-chart v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import TimeOnGuideChart from '@/components/guides/metrics/TimeOnGuideChart';
import TimeOnStepChart from '@/components/guides/metrics/TimeOnStepChart';

export default {
    name: 'TimeOnGuide',
    components: {
        TimeOnGuideChart,
        TimeOnStepChart
    },
    computed: {
        ...mapGetters({
            guide: 'guides/active'
        }),
        isMultistep () {
            return get(this.guide, 'steps.length') > 1;
        }
    }
};
</script>
