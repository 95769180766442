<template>
    <div class="guide-details-metrics">
        <global-filters :show-app-picker="false" />
        <div class="guide-details-metrics--content">
            <div class="guide-details-metrics--row">
                <guide-activity-chart class="guide-details-metrics--activity-chart" />
            </div>
            <div
                v-if="polls.length"
                data-cy="polls-table"
                class="guide-details-metrics--row">
                <poll-metrics-table class="guide-details-metrics--poll-metrics-table" />
            </div>
            <div
                v-if="isMultistep"
                class="guide-details-metrics--row">
                <visitors-per-step-chart
                    :csv-download-agg-id="visitorPerStepCsvAggId"
                    :is-panel-visible="sidePanelState.visible"
                    class="guide-details-metrics--visitors-per-step-chart"
                    @update-current-step="updateCurrentStep"
                    @update-steps-seen-visitors-and-accounts="updateStepsSeenVisitorsAndAccounts"
                    @open-side-panel="openSidePanel" />
            </div>
            <div class="guide-details-metrics--row">
                <time-on-guide />
            </div>
            <div class="guide-details-metrics--row">
                <guide-elements-activity-table
                    :csv-download-agg-id="guideElementsCsvAggId"
                    class="guide-details-metrics--elements-table"
                    @update-ui-element="updateSelectedRow"
                    @update-guide-elements-clicks="updateGuideElementsClicks"
                    @open-side-panel="openSidePanel" />
            </div>
            <guide-task-list-metrics
                :csv-download-agg-id="taskListClicksCsvAggId"
                :guide="guide"
                :active-time-series="activeTimeSeries"
                @updateSelectedRow="updateSelectedRow"
                @updateGuideElementsClicks="updateGuideElementsClicks"
                @openSidePanel="openSidePanel" />
            <div class="guide-details-metrics--row">
                <guide-activity-table class="guide-details-metrics--activity-table" />
            </div>
            <pendo-side-panel-drawer
                v-bind="sidePanelState"
                @download-csv="downloadSidePanelCsv"
                @create-segment="createSegment"
                @close="closeDrawer" />
        </div>
    </div>
</template>

<script>
import GuideElementsActivityTable from '@/components/guides/metrics/GuideElementsActivityTable';
import GuideActivityChart from '@/components/guides/metrics/GuideActivityChart';
import PollMetricsTable from '@/components/guides/metrics/PollMetricsTable';
import TimeOnGuide from '@/components/guides/metrics/TimeOnGuide';
import GlobalFilters from '@/components/filters/GlobalFilters';
import VisitorsPerStepChart from '@/components/guides/metrics/VisitorsPerStepChart';
import GuideActivityTable from '@/components/guides/metrics/GuideActivityTable';
import {
    PendoSidePanelDrawer,
    PendoGuideElementsDrawerHeader,
    PendoGuideMetricsDrawerContent
} from '@pendo/components';
import { getStepsSeenVisitorsAndAccountsCsv } from '@/aggregations/visitors-per-step';
import { getGuideElementsClicksCsv } from '@/aggregations/guide-elements-activity';
import { getTaskListItemClicksCsv } from '@/aggregations/task-list-click-metrics';
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import GuideTaskListMetrics from './GuideTaskListMetrics.vue';
import { VISITOR_URL } from '@/utils/utils';

export default {
    name: 'GuideDetailsMetrics',
    components: {
        GlobalFilters,
        GuideActivityChart,
        PollMetricsTable,
        TimeOnGuide,
        VisitorsPerStepChart,
        GuideElementsActivityTable,
        PendoSidePanelDrawer,
        GuideActivityTable,
        GuideTaskListMetrics
    },
    data () {
        return {
            sidePanelState: { visible: false },
            visitorPerStepCsvAggId: 'visitor-per-step-chart', // visitors-per-step-chart
            guideElementsCsvAggId: 'guide-elements', // guide-elements
            taskListClicksCsvAggId: 'task-list-metrics', // task-list-clicks
            currentStep: null, // visitors-per-step-chart
            stepsSeenVisitorsAndAccounts: {}, // visitors-per-step-chart
            selectedRow: null, // guide-elements
            guideElementsClicks: {} // guide-elements
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            guide: 'guides/active',
            polls: 'guides/activePolls',
            activeTimeSeries: 'filters/activeTimeSeries',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        appIds () {
            return get(this.guide, 'appIds', '');
        },
        isMultistep () {
            return get(this.guide, 'steps.length') > 1;
        },
        isCrossApp () {
            return isCrossApp(this.guide);
        },
        timeSeries () {
            return {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
        }
    },
    methods: {
        updateCurrentStep (point) {
            this.currentStep = point;
        },
        updateStepsSeenVisitorsAndAccounts (data) {
            this.stepsSeenVisitorsAndAccounts = data;
        },
        updateSelectedRow (row) {
            this.selectedRow = row;
        },
        updateGuideElementsClicks (data) {
            this.guideElementsClicks = data;
        },
        openSidePanel (state) {
            this.sidePanelState = { ...state };
        },
        closeDrawer () {
            this.sidePanelState.visible = false;
        },
        createSegment () {
            this.closeDrawer();
        },
        async downloadSidePanelCsv ({ activeTab, csvDownloadAggId }) {
            let downloadCsvFunc;
            switch (csvDownloadAggId) {
                case this.visitorPerStepCsvAggId:
                    downloadCsvFunc = this.downloadVisitorsPerStepCsv;
                    break;
                case this.guideElementsCsvAggId:
                    downloadCsvFunc = this.downloadGuideElementsCsv;
                    break;
                case this.taskListClicksCsvAggId:
                    downloadCsvFunc = this.downloadTaskListItemClicksCsv;
                    break;
                default:
                    return;
            }

            await downloadCsvFunc(activeTab);
        },
        async downloadVisitorsPerStepCsv (activeTab) {
            try {
                const {
                    data: { url }
                } = await getStepsSeenVisitorsAndAccountsCsv({
                    appIds: this.appIds,
                    guideId: this.guide.id,
                    guideStepId: this.currentStep.id,
                    timeSeries: this.timeSeries,
                    segmentId: this.activeSegmentId,
                    kind: activeTab
                });
                this.openSidePanel({
                    header: {
                        props: {
                            title: this.currentStep.name
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            data: this.stepsSeenVisitorsAndAccounts,
                            loading: false,
                            csvModalStatus: 'resolved',
                            csvUrl: url,
                            visitorUrl: VISITOR_URL,
                            showAccountsTab: false,
                            theme: 'app'
                        }
                    },
                    visible: true
                });
            } catch (error) {
                this.openSidePanel({
                    header: {
                        props: {
                            title: this.currentStep
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            loading: false,
                            csvModalStatus: 'rejected',
                            csvUrl: ''
                        }
                    },
                    visible: true
                });
            }
        },
        async downloadGuideElementsCsv (activeTab) {
            try {
                const {
                    data: { url }
                } = await getGuideElementsClicksCsv({
                    appIds: this.appIds,
                    guideId: this.guide.id,
                    timeSeries: this.timeSeries,
                    segmentId: this.activeSegmentId,
                    uiElementId: this.selectedRow.uiElementId,
                    kind: activeTab
                });
                this.openSidePanel({
                    header: {
                        component: PendoGuideElementsDrawerHeader,
                        props: {
                            uiElement: this.selectedRow
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            data: this.guideElementsClicks,
                            loading: false,
                            csvModalStatus: 'resolved',
                            csvUrl: url,
                            visitorUrl: VISITOR_URL,
                            showAccountsTab: false,
                            showClicksColumn: true,
                            theme: 'app'
                        }
                    },
                    visible: true
                });
            } catch (error) {
                this.openSidePanel({
                    header: {
                        props: {
                            uiElement: this.selectedRow
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            loading: false,
                            csvModalStatus: 'rejected',
                            csvUrl: ''
                        }
                    },
                    visible: true
                });
            }
        },
        async downloadTaskListItemClicksCsv (activeTab) {
            const { id, parentId, name } = this.selectedRow;
            const header = {
                component: PendoGuideElementsDrawerHeader,
                props: {
                    uiElement: {
                        uiElementText: name,
                        uiElementType: 'Guide'
                    },
                    class: 'task-list-metric-header'
                }
            };

            const sidePanelProps = {
                data: this.guideElementsClicks,
                loading: false,
                csvModalStatus: 'resolved',
                csvUrl: '',
                visitorUrl: VISITOR_URL,
                showAccountsTab: false,
                showClicksColumn: true,
                theme: 'app'
            };

            try {
                const {
                    data: { url }
                } = await getTaskListItemClicksCsv({
                    appIds: this.appIds,
                    guideId: this.guide.id,
                    timeSeries: this.timeSeries,
                    segmentId: this.activeSegmentId,
                    childGuideId: id,
                    stepId: parentId,
                    kind: activeTab
                });
                sidePanelProps.csvUrl = url;
            } catch (error) {
                sidePanelProps.csvModalStatus = 'rejected';
            }

            this.openSidePanel({
                header,
                body: {
                    component: PendoGuideMetricsDrawerContent,
                    props: sidePanelProps
                },
                visible: true
            });
        }
    }
};
</script>

<style lang="scss">
.guide-details-metrics--content {
    display: grid;
    grid-gap: 32px;
}

.guide-details-metrics--row {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
}

.guide-details-metrics--activity-chart {
    .pendo-card__body {
        display: grid;
    }
}
</style>
