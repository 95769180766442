import { render, staticRenderFns } from "./JourneysPage.vue?vue&type=template&id=8fa25afc&scoped=true&"
import script from "./JourneysPage.vue?vue&type=script&lang=js&"
export * from "./JourneysPage.vue?vue&type=script&lang=js&"
import style0 from "./JourneysPage.vue?vue&type=style&index=0&id=8fa25afc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fa25afc",
  null
  
)

export default component.exports