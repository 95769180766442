/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    identifiedState,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/utils/aggregations';
import get from 'lodash/get';

export function getVisitorCounts ({ appId, segmentId }) {
    const timeSeriesMap = {
        todayCount: {
            period: 'dayRange',
            first: 'startOfPeriod("daily", now())',
            count: 1
        },
        yesterdayCount: {
            period: 'dayRange',
            first: 'dateAdd(startOfPeriod("daily", now()), -1, "days")',
            count: 1
        },
        last7Count: {
            period: 'last7days',
            first: -1,
            count: -1
        },
        last30Count: {
            period: 'last30days',
            first: -1,
            count: -1
        }
    };

    const spec = buildVisitorCountsSpec({ appId, timeSeriesMap, segmentId });

    // `then` instead of `await` so we dont block a `Promise.all` anywhere
    return request(spec).then((result) => result[0]);
}

export function buildVisitorCountsSpec ({ appId, timeSeriesMap, segmentId }) {
    const { operators: o } = Agg2;
    const spec = o.aggregation(
        {
            name: 'visitor-counts',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.spawn(
                ...Object.entries(timeSeriesMap).map(([name, timeSeries]) => {
                    return o.pipeline(
                        o.sources.events({ timeSeries, appId: [appId] }),
                        o.identified(identifiedState(segmentId)),
                        o.segment(parseSegmentIdForAggregation(segmentId)),
                        o.reduce({
                            [name]: o.count('visitorId')
                        }),
                        o.select({
                            [name]: name
                        })
                    );
                })
            ),
            o.join(),
            o.select({
                today: 'todayCount',
                yesterday: 'yesterdayCount',
                last7: 'last7Count',
                last30: 'last30Count'
            })
        )
    );

    return spec;
}

export function getVisitorTotal ({ segmentId } = {}) {
    const spec = buildVisitorTotalSpec({ segmentId: parseSegmentIdForAggregation(segmentId) });

    return request(spec).then((result) => get(result, '[0].total', 0));
}

export function buildVisitorTotalSpec ({ segmentId }) {
    const { operators: o } = Agg2;
    const aggName = segmentId ? 'segment-visitor-total-adopt' : 'everyone-visitor-total-adopt';

    const spec = o.aggregation(
        {
            name: aggName,
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.visitors(),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.reduce({
                total: o.count(null)
            })
        )
    );

    return spec;
}
