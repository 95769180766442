import Vue from 'vue';

export function getInitialState () {
    return {
        visitorCounts: {},
        visitorsOverTime: [],
        pageUsageChart: null,
        pageUsageOverTime: [],
        featureUsageOverTime: []
    };
}

export const state = getInitialState();

export const mutations = {
    setPageUsageChart (state, { pageUsageChart }) {
        state.pageUsageChart = pageUsageChart;
    },
    setVisitorCounts (state, { today, yesterday, last7, last30 }) {
        Vue.set(state.visitorCounts, 'today', today);
        Vue.set(state.visitorCounts, 'yesterday', yesterday);
        Vue.set(state.visitorCounts, 'last7', last7);
        Vue.set(state.visitorCounts, 'last30', last30);
    },
    setVisitorsOverTime (state, { visitorsOverTime }) {
        state.visitorsOverTime = visitorsOverTime;
    },
    setPageUsageOverTime (state, { pageUsageOverTime }) {
        state.pageUsageOverTime = pageUsageOverTime;
    },
    setFeatureUsageOverTime (state, { featureUsageOverTime }) {
        state.featureUsageOverTime = featureUsageOverTime;
    },
    reset (state) {
        Object.assign(state, getInitialState(), {
            // preserve this stuff across resets
            // reset gets called whenever analytics refresh, which blows away
            // some state that we'd rather keep. The downside is that this state
            // does not reset on logout, but since the settings should be re-fetched
            // on a new login anyway, it shouldn't bleed state between sessions.
            pageUsageChart: state.pageUsageChart
        });
    }
};

export const actions = {};

export const getters = {
    areAggregationsInFlight (state) {
        const inflightAggregations = Object.values(state.isFetchingMap).filter(Boolean);

        return !!inflightAggregations.length;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
