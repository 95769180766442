<template>
    <div class="text-localizer-input">
        <translation-input-row :warning="warnings[transUnitId]">
            <pendo-input
                ref="textarea"
                :value="sanitizedValue"
                type="textarea"
                :rows="1"
                :autosize="false"
                :disabled="disabled"
                @input="onInput">
                <template #prepend>
                    <div class="text-localizer-input__prepend">
                        <pendo-icon
                            :size="16"
                            stroke="#2A2C35"
                            type="align-center" />
                    </div>
                </template>
                <template #suffix>
                    <span
                        v-if="hasChanged"
                        class="text-localizer-edited-marker">
                        (Edited)
                    </span>
                </template>
                <template
                    v-if="showHelperText"
                    #helper-text>
                    <slot
                        v-if="$slots['helper-text']"
                        name="helper-text" />
                    <div v-if="isFallbackBlock || isProgressBarTextBlock">
                        {{ helperText }}
                    </div>
                </template>
            </pendo-input>
        </translation-input-row>
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoInput, PendoIcon } from '@pendo/components';
import TranslationInputRow from './TranslationInputRow';
import { applyTemplateTags } from './../../../utils/localization';

export default {
    name: 'TextLocalizer',
    components: {
        TranslationInputRow,
        PendoInput,
        PendoIcon
    },
    props: {
        transUnitGroup: {
            type: Object,
            required: true
        },
        transUnitMap: {
            type: Object,
            required: true
        },
        transUnitHeights: {
            type: Object,
            default: () => ({})
        },
        initialTransUnitValues: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        warnings: {
            type: Object,
            default: () => ({})
        },
        isProgressBarTextBlock: {
            type: Boolean,
            default: false
        },
        isFallbackBlock: {
            type: Boolean,
            default: false
        },
        blockIndex: {
            type: Number,
            default: 0
        }
    },
    emits: ['change', 'changeHeight'],
    computed: {
        transUnitId () {
            return get(this.transUnitGroup, `blocks[${this.blockIndex}].id`);
        },
        value () {
            return get(this.transUnitMap, `${this.transUnitId}.${this.valueKey}`);
        },
        sanitizedValue () {
            return applyTemplateTags(this.value);
        },
        height () {
            return this.transUnitHeights[this.transUnitId]?.height;
        },
        minHeight () {
            return this.transUnitHeights[this.transUnitId]?.minHeight;
        },
        initialTranslation () {
            const transUnit = this.initialTransUnitValues.find(({ transUnitId }) => transUnitId === this.transUnitId);

            return transUnit.target;
        },
        hasChanged () {
            if (this.valueKey === 'target') {
                return this.value !== this.initialTranslation;
            }

            return false;
        },
        showHelperText () {
            return this.$slots['helper-text'] || this.isProgressBarTextBlock || this.isFallbackBlock;
        },
        helperText () {
            if (this.isFallbackBlock) {
                return 'This text displays if task list is empty, or the user is not eligible for any task list guide.';
            }

            if (this.isProgressBarTextBlock) {
                return "Available template variables: '<%= totalPercentComplete %>'";
            }

            return '';
        }
    },
    watch: {
        height (newHeight, oldHeight) {
            if (newHeight !== oldHeight) {
                this.resizeTextarea(newHeight);
            }
        }
    },
    async mounted () {
        await this.$nextTick();
        this.$emit('changeHeight', { transUnitId: this.transUnitId, height: this.resizeTextarea(), type: 'mount' });
    },
    methods: {
        resizeTextarea (height = null) {
            const textarea = this.getInputElement();

            // Attempt to autosize but never shrink below the original mount height.
            if (!height) {
                textarea.style.height = 'auto';
                height = textarea.scrollHeight < this.minHeight ? this.minHeight : textarea.scrollHeight;
            }
            textarea.style.height = `${height + 2}px`;

            return height;
        },
        getInputElement () {
            return this.$refs.textarea.$el.querySelector('textarea');
        },
        onInput (value) {
            this.$emit('changeHeight', { transUnitId: this.transUnitId, height: this.resizeTextarea(), type: 'input' });
            this.$emit('change', { transUnitId: this.transUnitId, value });
        }
    }
};
</script>

<style lang="scss" scoped>
.text-localizer-input {
    ::v-deep .pendo-input--textarea .pendo-input__field {
        min-height: inherit;

        .pendo-input__suffix {
            align-self: start;
            padding: 8px 8px;
        }
    }

    ::v-deep .pendo-input__helper-text {
        margin-left: 5px;
    }

    &__prepend {
        padding-top: 10px;
        height: 100%;
    }
}
</style>
