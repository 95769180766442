import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { isCancel } from 'axios';
import keyBy from 'lodash/keyBy';
import { getTrackEventList } from '@/aggregations/track-event-list';
import { addAppToEntityList } from '@/utils/apps';
import { fetchAllTrackEventsForSubscription } from '@/utils/track-events';
import { filterUnsharedResources } from '@/utils/modules';

let fetchPromise;

export function getInitialState () {
    return {
        map: {},
        mapWithAnalytics: {},
        isFetching: false,
        isFetchingWithAnalytics: false,
        aggCancel: null
    };
}

export const state = getInitialState();

export const mutations = {
    setMap (state, { map }) {
        state.map = map;
    },
    setMapWithAnalytics (state, { mapWithAnalytics }) {
        state.mapWithAnalytics = mapWithAnalytics;
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setFetchingWithAnalytics (state, { isFetchingWithAnalytics }) {
        state.isFetchingWithAnalytics = isFetchingWithAnalytics;
    },
    setAggCancel (state, { aggCancel }) {
        state.aggCancel = aggCancel;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const getters = {
    trackEventById: (state) => (id) => get(state, `map['${id}']`, null),
    list (state, getters, rootState, rootGetters) {
        const filteredResources = filterUnsharedResources(state.map);

        return addAppToEntityList(filteredResources, rootGetters['apps/appMapForActiveSubscription']);
    },
    listAllWithAnalytics (state, getters, rootState, rootGetters) {
        return addAppToEntityList(
            Object.values(state.mapWithAnalytics),
            rootGetters['apps/appMapForActiveSubscription']
        );
    }
};

export const actions = {
    async fetch ({ commit, state, rootGetters }, { noCache = false } = {}) {
        if (state.isFetching) {
            await fetchPromise;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });

        fetchPromise = fetchAllTrackEventsForSubscription();
        const list = await fetchPromise;

        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const trackEventsForAvailableApps = list.filter(
            (trackEvent) => !!appMapForActiveSubscription[trackEvent.appId]
        );

        const displayNameFormattedList = trackEventsForAvailableApps.map((trackEvent) => {
            const displayName = get(trackEvent, 'trainingSettings.displayName', trackEvent.name);
            trackEvent.displayName = displayName;

            return trackEvent;
        });

        commit('setMap', { map: keyBy(displayNameFormattedList, 'id') });
        commit('setFetching', { isFetching: false });
    },
    async fetchWithAnalytics ({ commit, state, rootState, rootGetters }, { noCache = false } = {}) {
        if (state.aggCancel) {
            state.aggCancel.abort();
        }

        if (!isEmpty(state.mapWithAnalytics) && !noCache) {
            return;
        }

        commit('setAggCancel', { aggCancel: new AbortController() });
        commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: true });

        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const { activeSegmentId, appIdsFilter } = rootState.filters;

        let list = [];

        try {
            list = await getTrackEventList({
                appId: appIdsFilter,
                timeSeries,
                segmentId: activeSegmentId,
                signal: get(state, 'aggCancel.signal')
            });
            commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
        } catch (err) {
            if (!isCancel(err)) {
                commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
            }
        }

        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const trackEventsForAvailableApps = list
            .slice()
            .filter((trackEvent) => !!appMapForActiveSubscription[trackEvent.appId]);

        const displayNameFormattedList = trackEventsForAvailableApps.map((trackEvent) => {
            const displayName = get(trackEvent, 'trainingSettings.displayName', trackEvent.name);
            const description = get(trackEvent, 'trainingSettings.description', '---');
            trackEvent.displayName = displayName;
            trackEvent.description = description;

            return trackEvent;
        });

        commit('setMapWithAnalytics', { mapWithAnalytics: keyBy(displayNameFormattedList, 'id') });
    },
    fetchUpdatesForFilterChange ({ dispatch }) {
        dispatch('fetchWithAnalytics', { noCache: true });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
