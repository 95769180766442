var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editable-text-field",class:{ 'editable-text-field--boxed': _vm.boxed }},[_c('div',{staticClass:"editable-text-field__input",class:{
            'editable-text-field__input--fit-content': _vm.fitContent,
            'editable-text-field__input--errors': _vm.validationError && _vm.showValidation,
            'editable-text-field__input--disabled': _vm.disabled
        }},[_c('div',{staticClass:"editable-text-field__input__mask",class:{ 'editable-text-field__input__mask--not-expanded': !_vm.isExpanded },domProps:{"textContent":_vm._s(`${_vm.value} `)}}),_c('textarea',{ref:"textarea",class:{ 'editable-text-field__input__mask--not-expanded': !_vm.isExpanded },attrs:{"data-cy":"editable-text-field-not-expanded","spellcheck":"false","rows":"1","cols":"1","placeholder":_vm.placeholder,"disabled":_vm.disabled,"tabindex":_vm.disabled ? -1 : 0},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.onTextAreaFocus(false)},"focus":function($event){return _vm.onTextAreaFocus(true)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onReset.apply(null, arguments)}],"input":_vm.onInput}}),_vm._v(" "),_c('pendo-icon',{staticClass:"editable-text-field__input__pen",attrs:{"type":"edit-2","size":"14"}})],1),_c('div',{staticClass:"editable-text-field__validation",class:{
            'editable-text-field__validation--errors': _vm.validationError && _vm.showValidation
        },domProps:{"textContent":_vm._s(_vm.validationError)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }