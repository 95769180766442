<template>
    <div
        v-pendo-loading="loadingConfig"
        class="iframe-content-container"
        data-cy="iframe-content-container">
        <app-card-overlay
            :is-editing="isEditingIframeContent"
            :confirmation-modal-active="!!activeModal">
            <template #card>
                <pendo-card title="Content">
                    <template #headerRight>
                        <div class="iframe-content-save-cancel-buttons">
                            <pendo-button
                                v-if="isEditingIframeContent"
                                theme="app"
                                type="tertiary"
                                label="Cancel"
                                size="mini"
                                @click="onModalCancel" />
                            <pendo-button
                                v-if="isEditingIframeContent"
                                :disabled="!userHasChanges"
                                theme="app"
                                type="primary"
                                label="Save"
                                size="mini"
                                @click="toggleModal(MODALS.CONFIRM_SAVE)" />
                        </div>
                    </template>
                    <template #body>
                        <div
                            v-if="!editedIframeSrc && !isModuleLoading"
                            class="no-iframe-url-msg-wrapper">
                            <div
                                v-if="!isModuleLoading"
                                class="no-iframe-url-msg">
                                <div class="title">
                                    Nothing has been added yet.
                                </div>
                                <div
                                    v-if="canEditRCContent"
                                    class="subtitle">
                                    Add a URL to see a preview here.
                                </div>
                                <pendo-form
                                    v-if="canEditRCContent"
                                    :model="emptyStateForm"
                                    :call-validate="callValidateEmptyStateForm"
                                    @formValidated="handleValidEmptyStateForm"
                                    @invalidForm="handleInvalidEmptyStateForm">
                                    <pendo-form-item
                                        :rules="validationRules"
                                        prop="url">
                                        <pendo-input
                                            v-model="emptyStateForm.url"
                                            class="iframe-url-empty-input"
                                            :debounce="350"
                                            autofocus
                                            placeholder="https://example.com/"
                                            @input="validateEmptyStateForm"
                                            @keydown="emptyStateInputKeyDown">
                                            <template #append>
                                                <pendo-button
                                                    type="primary"
                                                    theme="app"
                                                    label="Preview URL"
                                                    :disabled="disableEmptyStateSubmitButton"
                                                    @click="submitEmptyStateForm" />
                                            </template>
                                        </pendo-input>
                                    </pendo-form-item>
                                </pendo-form>
                            </div>
                        </div>
                        <div
                            v-if="!!editedIframeSrc"
                            class="edit-iframe-content">
                            <div class="iframe-url-edit-wrapper">
                                <span class="iframe-content-label">
                                    External Content URL
                                </span>
                                <pendo-editable-content
                                    v-if="canEditRCContent"
                                    class="iframe-url-edit"
                                    :value="editedIframeSrc"
                                    allow-empty
                                    :validation-rules="validationRules"
                                    @confirm="updateIframeSrc">
                                    <template #append="{ pending, confirm, cancel }">
                                        <pendo-button
                                            theme="app"
                                            icon="check"
                                            :loading="pending"
                                            type="primary"
                                            @click="confirm" />
                                        <pendo-button
                                            theme="app"
                                            label="Cancel"
                                            type="tertiary"
                                            @click="cancel" />
                                    </template>
                                </pendo-editable-content>
                                <span
                                    v-if="!canEditRCContent"
                                    class="iframe-url-edit">
                                    {{ editedIframeSrc }}
                                </span>
                            </div>
                            <div class="iframe-preview-content">
                                <div class="iframe-preview-wrapper">
                                    <iframe
                                        class="iframe-preview"
                                        title="Iframe Preview"
                                        width="350"
                                        height="342"
                                        :src="editedIframeSrc" />
                                </div>
                            </div>
                        </div>
                    </template>
                </pendo-card>
            </template>
        </app-card-overlay>

        <pendo-modal
            :visible="activeModal === MODALS.CONFIRM_SAVE"
            :confirm-button-config="{ type: 'primary', theme: 'app', label: 'Save Module' }"
            message="This module will be saved to the draft version of your Resource Center. To make your changes public please publish your updates."
            type="confirmation"
            title="Save Module?"
            width="400px"
            @confirm="confirmSave"
            @cancel="toggleModal" />
        <pendo-modal
            :visible="activeModal === MODALS.CONFIRM_CANCEL"
            :confirm-button-config="{ type: 'primary', theme: 'app', label: 'Don\'t Save Changes' }"
            :cancel-button-config="{ type: 'secondary', theme: 'app', label: 'Continue Editing' }"
            title="You have unsaved changes!"
            type="confirmation"
            message="Any entered URL will be forgotten. Are you sure you don’t want to save changes?"
            width="400px"
            @confirm="doNotSave"
            @cancel="toggleModal" />
    </div>
</template>

<script>
import {
    PendoButton,
    PendoCard,
    PendoModal,
    PendoTooltip,
    PendoLoading,
    PendoEditableContent,
    PendoInput,
    PendoForm,
    PendoFormItem
} from '@pendo/components';
import AppCardOverlay from '@/stateless-components/common/AppCardOverlay';

const MODALS = Object.freeze({
    CONFIRM_SAVE: 'confirmSave',
    CONFIRM_CANCEL: 'confirmCancel',
    DO_NOT_SAVE: 'doNotSave'
});

export default {
    name: 'IframeContent',
    components: {
        PendoButton,
        PendoCard,
        PendoModal,
        AppCardOverlay,
        PendoEditableContent,
        PendoInput,
        PendoForm,
        PendoFormItem
    },
    directives: {
        PendoTooltip,
        PendoLoading
    },
    props: {
        originalIframeSrc: {
            type: String,
            required: true
        },
        canEditRCContent: {
            type: Boolean,
            required: true
        },
        isModuleLoading: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            editedIframeSrc: '',
            loading: false,
            isEditingIframeContent: false,
            activeModal: null,
            MODALS,
            validationRules: [
                {
                    type: 'url',
                    message: 'Please input a valid URL starting with http:// or https://',
                    trigger: ['change']
                }
            ],
            emptyStateForm: {
                url: ''
            },
            isEmptyStateFormValid: false,
            callValidateEmptyStateForm: false
        };
    },
    computed: {
        userHasChanges () {
            return this.editedIframeSrc !== this.originalIframeSrc;
        },
        loadingConfig () {
            return {
                loading: this.isModuleLoading,
                type: 'feather',
                spinnerProps: {
                    background: 'rgba(255, 255, 255, 0.5)'
                }
            };
        },
        isEmptyStateFormEmpty () {
            return !this.emptyStateForm.url;
        },
        disableEmptyStateSubmitButton () {
            return this.isEmptyStateFormEmpty || !this.isEmptyStateFormValid;
        }
    },
    watch: {
        editedIframeSrc (newValue) {
            this.emptyStateForm.url = newValue;
        },
        isModuleLoading: {
            handler (newVal) {
                if (newVal === false) {
                    this.editedIframeSrc = this.originalIframeSrc;
                }
            },
            immediate: true
        }
    },
    methods: {
        updateIframeSrc (value) {
            this.editedIframeSrc = value;
            this.isEditingIframeContent = true;
        },
        validateEmptyStateForm () {
            this.callValidateEmptyStateForm = true;
        },
        handleValidEmptyStateForm () {
            this.isEmptyStateFormValid = true;
            this.callValidateEmptyStateForm = false;
        },
        handleInvalidEmptyStateForm () {
            this.isEmptyStateFormValid = false;
            this.callValidateEmptyStateForm = false;
        },
        async submitEmptyStateForm () {
            if (this.isEmptyStateFormValid) {
                this.updateIframeSrc(this.emptyStateForm.url);
            }
        },
        emptyStateInputKeyDown (event) {
            if (event.keyCode === 13 && this.isEmptyStateFormValid) {
                this.submitEmptyStateForm();
            }
        },
        toggleModal (modalName = null) {
            this.activeModal = modalName;
        },
        onModalCancel () {
            if (!this.userHasChanges) {
                this.doNotSave();

                return;
            }

            this.toggleModal(MODALS.CONFIRM_CANCEL);
        },
        async confirmSave () {
            this.toggleModal();
            this.isEditingIframeContent = false;
            this.$emit('update', this.editedIframeSrc);
        },
        confirmCancel () {
            this.toggleModal();
        },
        doNotSave () {
            this.editedIframeSrc = this.originalIframeSrc;
            this.isEditingIframeContent = false;
            this.toggleModal();
        }
    }
};
</script>

<style scoped lang="scss">
.loading-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edit-iframe-content {
    margin-top: 24px;
}

.iframe-url-edit-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0 24px;

    .iframe-url-edit {
        margin-left: 100px;

        input {
            width: 350px;
        }
    }
}

.iframe-content-label {
    font-weight: 600;
}

.iframe-preview-content {
    background-color: $gray-lighter-7;
    margin-top: 24px;

    .iframe-preview-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 451px;
        justify-content: center;
    }

    .iframe-preview {
        border: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.no-iframe-url-msg-wrapper {
    align-items: top;
    display: flex;
    flex-direction: row;
    height: 537px;
    justify-content: center;
    padding-top: 150px;
    text-align: center;

    .title {
        font-weight: 300;
        font-size: 36px;
    }

    .subtitle {
        margin-top: 24px;
    }

    .iframe-url-empty-input {
        margin-top: 24px;
    }

    ::v-deep .pendo-input__field {
        border-radius: 3px;
        width: 350px;
    }

    ::v-deep .pendo-input__append {
        background: unset;
        border: 0;
    }
}
</style>
