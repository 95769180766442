import { http } from '@pendo/http';

export async function fetchAndDownloadFile (url, fileName) {
    const { data, headers } = await http.get(url);
    const contentType = headers['content-type'];

    downloadFile(data, fileName, contentType);
}

export function downloadFile (fileData, fileName = 'pendo-download.txt', mimeType = 'text/plain') {
    const blob = new Blob([fileData], { type: mimeType });
    // Create a temporary anchor to use for the file download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    // add the anchor tag to the DOM and trigger a click to initiate the download
    document.body.appendChild(a);
    a.click();
    // remove the temporary anchor and clean up the objectUrl
    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
    }, 100);
}
