<template>
    <div class="path-details-content">
        <path-not-exist v-if="resourceLoadError" />
        <template v-if="!resourceLoadError">
            <path-details-header
                :report="savedPath"
                :valid="valid"
                :changed="!isLoading && changed"
                :can-edit="canEdit"
                :can-share="canShare"
                :can-delete="canDelete"
                :loading="isResourceLoading"
                :editable="canEditPathName"
                @update="updatePath"
                @save="savePath" />
            <pendo-page-content>
                <path-error-banner
                    v-if="pathState.error || resourceSaveError || !isSavedPathValid"
                    :error="pathState.error || resourceSaveError || pathValidationError"
                    @retry="debouncedUpdateOrForcedRun" />
                <path-query-builder
                    v-if="unsavedPath"
                    :app-first-visit="appFirstVisit"
                    :path-resource="unsavedPath"
                    :path-state="pathState"
                    :runnable="valid"
                    :changed="changed"
                    :can-edit="canEdit"
                    :can-share="canShare"
                    :globally-disabled="globallyDisabled"
                    :is-multi-app="usesMultiApp"
                    @change="onPathChange"
                    @run="debouncedUpdateOrForcedRun"
                    @save="savePath" />
                <path-chart-card
                    v-if="savedPath && !isResourceLoading && isSavedPathValid"
                    ref="pathChartCard"
                    :path-resource="savedPath"
                    :path-state="pathState"
                    :labeler="labeler"
                    @select="onSelect" />
                <path-visitor-table
                    v-if="savedPath && pathState.selectedStep && isSavedPathValid"
                    :path-resource="savedPath"
                    :path-state="pathState"
                    :labeler="labeler"
                    :active-timezone="getActiveTimezone" />
            </pendo-page-content>
        </template>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { mapGetters, mapActions, mapState } from 'vuex';
import { PendoPageContent } from '@pendo/components';
import { LOADING_STATES } from '@pendo/services/Constants';
import { Labeler } from '@pendo/services/Paths';

import PathQueryBuilder from '@/components/paths/PathQueryBuilder';
import PathDetailsHeader from '@/components/paths/PathDetailsHeader';
import PathErrorBanner from '@/components/paths/PathErrorBanner';
import PathChartCard from '@/components/paths/PathChartCard';
import PathVisitorTable from '@/components/paths/PathVisitorTable';
import PathNotExist from '@/components/paths/PathNotExist';
import { PathError, getDefaultUnsavedPath, compilePathAggregation } from '@/utils/paths';
import { getPath } from '@/aggregations/paths';

export default {
    name: 'PathDetailsContent',
    components: {
        PathErrorBanner,
        PathDetailsHeader,
        PendoPageContent,
        PathQueryBuilder,
        PathChartCard,
        PathVisitorTable,
        PathNotExist
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            isResourceLoading: true,
            resourceLoadError: undefined,
            resourceSaveError: undefined,
            labeler: undefined,
            pathState: {},
            unsavedPath: undefined,
            aggregation: undefined,
            pathValidationError: {
                message:
                    'We are unable to run your path because we could not find the page, or feature you specified. Please edit the path and correct the missing page, or feature, or delete the path if you no longer need it.',
                retriable: false
            }
        };
    },
    computed: {
        ...mapGetters({
            activeReport: 'reports/activeReport',
            getFeatureById: 'features/featureById',
            getPageById: 'pages/pageById',
            getTrackEventById: 'trackEvents/trackEventById',
            getReportById: 'reports/reportById',
            usesMultiApp: 'subscriptions/usesMultiApp',
            getActiveTimezone: 'subscriptions/getTimezone',
            appFirstVisit: 'apps/firstVisit',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        ...mapState({
            features: (state) => state.features.map,
            pages: (state) => state.pages.map,
            trackEvents: (state) => state.trackEvents.map,
            apps: (state) => state.apps.map,
            user: (state) => state.auth.user
        }),
        appsById () {
            return Object.values(this.apps).reduce((apps, app) => {
                apps[app.id] = app;

                return apps;
            }, {});
        },
        canEditPathName () {
            return this.canEdit && Boolean(get(this.savedPath, 'name'));
        },
        canEdit () {
            return (
                this.isOwnReport ||
                this.user.subscriptionAccessControl.some((permission) => {
                    const { entity, action, source, target } = permission;

                    return entity === 'report' && action === 'edit' && source === '*' && target === 'shared';
                })
            );
        },
        canShare () {
            return this.user.subscriptionAccessControl.some((permission) => {
                const { entity, action, source, target } = permission;

                return entity === 'report' && action === 'share' && source === '*' && target === '*';
            });
        },
        canDelete () {
            return (
                this.isOwnReport ||
                this.user.subscriptionAccessControl.some((permission) => {
                    const { entity, action, source, target } = permission;

                    return entity === 'report' && action === 'remove' && source === '*' && target === 'shared';
                })
            );
        },
        isLoading () {
            return this.pathState.status === LOADING_STATES.LOADING;
        },
        isResolved () {
            return this.pathState.status === LOADING_STATES.RESOLVED;
        },
        isSavedPathValid () {
            if (!this.savedPath) return true;

            const pageId = get(this.savedPath, 'definition.config.pageId', null);
            const featureId = get(this.savedPath, 'definition.config.featureId', null);
            const trackTypeId = get(this.savedPath, 'definition.config.trackTypeId', null);

            if (pageId === null && featureId === null && trackTypeId === null) return false;

            const page = this.getPageById(pageId);
            const feature = this.getFeatureById(featureId);
            const trackEvent = this.getTrackEventById(trackTypeId);

            if (page === null && feature === null && trackEvent === null) return false;

            return true;
        },
        savedPath () {
            return this.getReportById(this.id);
        },
        valid () {
            if (!this.unsavedPath) return false;

            const { definition } = this.unsavedPath;
            const { config } = definition;
            const hasIncludedResources = !!(!config.omitPages || config.features || config.trackEvents);
            const hasRootResource = !!(config.pageId || config.featureId || config.trackTypeId);
            const hasTimeSeries = !!definition.timeSeries;
            const resourcesExists =
                hasRootResource &&
                (!!this.getPageById(config.pageId) ||
                    !!this.getFeatureById(config.featureId) ||
                    !!this.getTrackEventById(config.trackTypeId));

            return hasRootResource && hasTimeSeries && hasIncludedResources && resourcesExists;
        },
        changed () {
            if (!this.unsavedPath) return false;

            const savedDefinition = get(this.savedPath, 'definition');
            const unsavedDefinition = get(this.unsavedPath, 'definition');

            return this.id ? !isEqual(savedDefinition, unsavedDefinition) : true;
        },
        isOwnReport () {
            if (!this.savedPath) return true;
            const createdByUserId = get(this.savedPath, 'createdByUser.id');

            return createdByUserId === this.user.id;
        },
        globallyDisabled () {
            return this.isLoading || !this.canEdit;
        }
    },
    watch: {
        id (newId, oldId) {
            if (oldId) {
                this.initPath();
            }
        }
    },
    async created () {
        if (this.hasSegmentFlag('adoptTrackEvent')) {
            this.pathValidationError.message =
                'We are unable to run your path because we could not find the page, feature, or track event you specified. Please edit the path and correct the missing page, feature, or track event, or delete the path if you no longer need it.';
        }

        this.debouncedUpdateOrForcedRun = debounce(this.updateOrForceRun, 200, {
            leading: true,
            trailing: false
        });

        await Promise.all([this.loadAllPages(), this.loadAllFeatures(), this.loadAllTrackEvents()]);
        this.initLabeler();
        this.initPath();
    },
    methods: {
        ...mapActions({
            loadAllPages: 'pages/fetch',
            loadAllFeatures: 'features/fetch',
            loadAllTrackEvents: 'trackEvents/fetch',
            fetchReport: 'reports/loadOne',
            updateReport: 'reports/update',
            createReport: 'reports/create'
        }),
        onSelect (selectedStep) {
            this.pathState = {
                ...this.pathState,
                selectedStep
            };
        },
        onPathChange (changedPathResource) {
            this.unsavedPath = changedPathResource;
            this.aggregation = compilePathAggregation(this.unsavedPath);
        },
        async updatePath () {
            this.resourceSaveError = undefined;

            const oldDefinition = this.savedPath.definition;
            const newDefinition = this.unsavedPath.definition;
            const forceRunPath =
                !isEqual(oldDefinition.config, newDefinition.config) ||
                !isEqual(oldDefinition.timeSeries, newDefinition.timeSeries);
            const reloadPath = oldDefinition.minimum !== newDefinition.minimum;
            const aggregation = this.aggregation || this.savedPath.aggregation;

            try {
                await this.updateReport({
                    report: {
                        ...this.savedPath,
                        definition: this.unsavedPath.definition,
                        aggregation
                    }
                });
                if (forceRunPath || reloadPath) {
                    this.runPath();
                }
            } catch (error) {
                this.resourceSaveError = new PathError({
                    message: 'We were unable to update your path.',
                    retriable: false
                });
                this.pathState = {
                    status: LOADING_STATES.REJECTED,
                    error: this.resourceSaveError
                };
            }
        },
        async savePath ({ name, visibility }) {
            this.resourceSaveError = undefined;

            const report = {
                ...this.unsavedPath,
                name,
                shared: visibility,
                aggregation: this.aggregation
            };

            try {
                await this.createReport({ report });

                this.$router
                    .push({
                        params: {
                            id: this.activeReport.id
                        }
                    })
                    .catch(() => {});
            } catch (error) {
                this.resourceSaveError = new PathError({
                    message: 'We were unable to save your path.',
                    retriable: false
                });
                this.pathState = {
                    status: LOADING_STATES.REJECTED,
                    error: this.resourceSaveError
                };
            }
        },
        updateOrForceRun () {
            this.pathState = {
                status: LOADING_STATES.LOADING,
                percentComplete: 0
            };

            if (this.changed) {
                this.updatePath();
            } else {
                this.runPath();
            }
        },
        initLabeler () {
            this.labeler = new Labeler({
                pages: this.pages,
                features: this.features,
                apps: this.appsById,
                trackTypes: this.trackEvents
            });
        },
        async initPath () {
            this.isResourceLoading = true;
            this.unsavedPath = undefined;
            this.resourceLoadError = undefined;
            this.pathState = {};

            if (this.id !== 'new') {
                try {
                    await this.fetchReport({
                        id: this.id,
                        noCache: true
                    });
                    this.unsavedPath = cloneDeep(this.savedPath);
                    if (this.isSavedPathValid) {
                        this.runPath();
                    }
                } catch (error) {
                    this.resourceLoadError = error;
                }
            } else {
                this.unsavedPath = getDefaultUnsavedPath();
            }

            this.isResourceLoading = false;
        },
        async runPath () {
            this.pathState = {
                status: LOADING_STATES.LOADING,
                percentComplete: 1
            };
            const aggregation = compilePathAggregation(this.unsavedPath).pipeline;
            const pathData = await getPath({ path: this.unsavedPath, aggregation });
            this.unsavedPath = cloneDeep(this.savedPath);

            this.pathState = {
                status: LOADING_STATES.RESOLVED,
                percentComplete: 100,
                frozenPathData: Object.freeze(pathData.root),
                frozenVisitors: Object.freeze(pathData.paths)
            };
        },
        async scrollChartIntoView () {
            await this.$nextTick();
            const el = get(this.$refs, 'pathChartCard.$el');
            if (el) {
                el.scrollIntoView({
                    alignToTop: true,
                    behavior: 'smooth'
                });
            }
        }
    }
};
</script>

<style lang="scss">
.path-chart-card,
.path-visitor-table {
    margin-top: 32px;
}

.path-details-content {
    height: 100%;
}
</style>
