<template>
    <div class="view--analytics">
        <analytics-header />
        <analytics-content />
    </div>
</template>

<script>
import AnalyticsContent from '@/components/analytics/AnalyticsContent';
import AnalyticsHeader from '@/components/analytics/AnalyticsHeader';

export default {
    name: 'AnalyticsView',
    components: {
        AnalyticsContent,
        AnalyticsHeader
    }
};
</script>
