import { identifiedState } from '@/utils/aggregations';
import { GUIDES_PRODUCT_AREAS, GUIDES_SUB_PRODUCT_AREAS } from '@pendo/services/Constants';
import { operators as o } from '@pendo/aggregations';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'TaskListMetrics',
    productArea: GUIDES_PRODUCT_AREAS.GUIDES,
    subProductArea: GUIDES_SUB_PRODUCT_AREAS.GUIDE_METRICS,
    responseFormat: 'rows',
    build: (aggParams) => {
        const {
            segmentId,
            timeSeries,
            parentGuideId,
            childGuidesMapByStepId,
            includeAnonymous = identifiedState(segmentId),
            blacklist = 'apply'
        } = aggParams;

        return o.pipeline(
            o.spawn(
                ...Object.keys(childGuidesMapByStepId).map((id) => {
                    const guides = childGuidesMapByStepId[id];
                    const { appId } = guides[0];
                    let guideViewsFilter = '';
                    let guideViewsFilterByStep = '';
                    if (guides.length) {
                        guideViewsFilter = `guideId == "${guides[0].id}"`;
                        for (let idx = 1; idx < guides.length; idx++) {
                            guideViewsFilter += ` || guideId == "${guides[idx].id}"`;
                        }

                        const firstStepIdsForChildGuides = guides.map((guide) => guide.steps[0]?.id);

                        if (firstStepIdsForChildGuides.length) {
                            guideViewsFilterByStep = firstStepIdsForChildGuides.reduce((acc, firstStepId, index) => {
                                if (index === firstStepIdsForChildGuides.length - 1) {
                                    acc += `guideStepId == "${firstStepId}"`;
                                } else {
                                    acc += `guideStepId == "${firstStepId}" || `;
                                }

                                return acc;
                            }, '');
                        }
                    }

                    return o.pipeline(
                        o.spawn(
                            o.pipeline(
                                o.sources.guideEvents({
                                    guideId: parentGuideId,
                                    guideStepId: id,
                                    timeSeries,
                                    blacklist
                                }),
                                o.filter('type == "guideSeen"'),
                                o.filter(`guideStepId == "${id}"`),
                                o.identified(includeAnonymous),
                                o.segment(segmentId),
                                { set: { guideStepId: id, stepViews: 0, uniqueStepVisitors: 0 } },
                                o.group(
                                    ['guideStepId'],
                                    o.groupField('stepViews', o.count(null)),
                                    o.groupField('uniqueStepVisitors', o.count('visitorId'))
                                ),
                                o.select({
                                    guideStepId: 'guideStepId',
                                    stepViews: 'stepViews',
                                    uniqueStepVisitors: 'uniqueStepVisitors'
                                })
                            ),

                            guides.length
                                ? o.pipeline(
                                    o.sources.guides({ appId }),
                                    o.select({
                                        guideId: 'id',
                                        guideName: 'name',
                                        guideStatus: 'state',
                                        guideSegment: 'audienceUiHint'
                                    }),
                                    { set: { guideViews: 0, uniqueGuideVisitors: 0, guideClicks: 0 } },
                                    o.filter(guideViewsFilter),
                                    o.evaluate({
                                        guideIds: 'list(guideId)',
                                        guideNames: 'list(guideName)',
                                        guideStatuses: 'list(guideStatus)',
                                        guideSegments: 'list(guideSegment)',
                                        guideViews: 'list(guideViews)',
                                        uniqueGuideVisitors: 'list(uniqueGuideVisitors)',
                                        guideClicks: 'list(guideClicks)'
                                    }),
                                    o.merge(
                                        ['guideId'],
                                        o.mappings({
                                            guideIds: 'guideIds',
                                            guideViews: 'guideViews',
                                            uniqueGuideVisitors: 'uniqueGuideVisitors'
                                        }),
                                        o.pipeline(
                                            o.sources.guideEvents({ timeSeries, blacklist, appId }),
                                            o.filter('type == "guideSeen"'),
                                            o.filter(guideViewsFilter),
                                            !isEmpty(guideViewsFilterByStep) && o.filter(guideViewsFilterByStep),
                                            o.identified(includeAnonymous),
                                            o.segment(segmentId),
                                            o.group(
                                                ['guideId'],
                                                o.groupField('guideViews', o.count(null)),
                                                o.groupField('uniqueGuideVisitors', o.count('visitorId'))
                                            ), // one row per guide
                                            o.evaluate({
                                                guideIds: 'list(guideId)',
                                                guideViews: 'list(guideViews)',
                                                uniqueGuideVisitors: 'list(uniqueGuideVisitors)'
                                            })
                                        )
                                    ),
                                    o.merge(
                                        ['guideId'],
                                        o.mappings({
                                            guideClicks: 'guideClicks'
                                        }),
                                        o.pipeline(
                                            o.sources.guideEvents({
                                                timeSeries,
                                                blacklist,
                                                guideId: parentGuideId,
                                                guideStepId: id
                                            }),
                                            o.filter('eventSubType(type) == "guideActivity"'),
                                            o.identified(includeAnonymous),
                                            o.segment(segmentId),
                                            { unmarshal: { action: 'uiElementActions' } },
                                            o.unwind('action'),
                                            o.filter('action.action == "showGuide"'),
                                            o.evaluate({
                                                taskGuideId: 'action.guideId'
                                            }),
                                            o.group(['taskGuideId'], o.groupField('guideClicks', o.count(null))),
                                            o.evaluate({
                                                guideClicks: 'list(guideClicks)'
                                            }),
                                            o.select({
                                                guideId: 'taskGuideId',
                                                guideClicks: 'guideClicks'
                                            })
                                        )
                                    ),
                                    { set: { guideStepId: id } },
                                    o.group(
                                        ['guideStepId'],
                                        o.groupField('guideIds', { concat: 'guideIds' }),
                                        o.groupField('guideNames', { concat: 'guideNames' }),
                                        o.groupField('guideStatuses', { concat: 'guideStatuses' }),
                                        o.groupField('guideSegments', { concat: 'guideSegments' }),
                                        o.groupField('guideViews', { concat: 'guideViews' }),
                                        o.groupField('uniqueGuideVisitors', { concat: 'uniqueGuideVisitors' }),
                                        o.groupField('guideClicks', { concat: 'guideClicks' })
                                    ),
                                    o.select({
                                        guideStepId: 'guideStepId',
                                        guideIds: 'guideIds',
                                        guideNames: 'guideNames',
                                        guideStatuses: 'guideStatuses',
                                        guideSegments: 'guideSegments',
                                        guideViews: 'guideViews',
                                        uniqueGuideVisitors: 'uniqueGuideVisitors',
                                        guideClicks: 'guideClicks'
                                    })
                                )
                                : []
                        ),
                        o.join(['guideStepId'])
                    );
                })
            )
        );
    }
};
