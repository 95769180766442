import { http } from '@pendo/http';

export function fetchDisplayAlerts () {
    return http.get('/api/s/_SID_/displayalert/guide?expand=*&kind=guide').then((res) => res.data);
}

export function updateDisplayAlert (displayalertId, displayAlert) {
    return http.put(`/api/s/_SID_/displayalert/guide/${displayalertId}`, displayAlert);
}

export function createDisplayAlert (displayAlert) {
    return http.post('/api/s/_SID_/displayalert/guide', displayAlert);
}

export function deleteNote (displayAlertId, noteId) {
    return http.delete(`/api/s/_SID_/displayalert/guide/${displayAlertId}/comment/${noteId}`);
}

export function fetchNotes (displayAlertId) {
    return http.get(`/api/s/_SID_/displayalert/guide/${displayAlertId}/comment`).then((res) => res.data);
}

export function createNote (displayAlertId, text) {
    return http.post(`/api/s/_SID_/displayalert/guide/${displayAlertId}/comment`, { text });
}

export function updateNote (displayalertId, noteId, text) {
    return http.put(`/api/s/_SID_/displayalert/guide/${displayalertId}/comment/${noteId}`, { text });
}
