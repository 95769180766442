<template>
    <pendo-install-settings
        :app="app"
        :keys="keys"
        :is-mobile-app="false"
        :subscription="subscription"
        :has-parent-accounts="hasParentAccounts"
        :has-allow-signed-metadata-flag="hasAllowSignedMetadataFlag"
        :host="host"
        :shared-tokens-loading="sharedTokensLoading"
        :loading-selections="loadingSelections"
        :require-signed-metadata-flag="requireSignedMetadataFlag"
        :enable-signed-metadata-flag="enableSignedMetadataFlag"
        :agent-version-less-than-required="agentVersionLessThanRequired"
        :disable-jwt-enable-button="disableJwtEnableButton"
        :is-adopt="true"
        @createSharedToken="createSharedToken"
        @updateSharedToken="updateSharedToken"
        @revokeSharedToken="updateSharedToken"
        @updateAppFlag="handleUpdateAppFlag" />
</template>

<script>
import { PendoInstallSettings, PendoNotification } from '@pendo/components';
import { mapGetters, mapActions, mapState } from 'vuex';
import { contentHost, environmentBucket } from '@/utils/environment';
import get from 'lodash/get';
import semver from 'semver';

export default {
    name: 'InstallSettings',
    components: {
        PendoInstallSettings
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        subscription: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            keys: [],
            updatingAppFlag: false,
            loadingSelections: {
                jwtEnable: false,
                disableRequireJwt: false,
                jwtModal: false,
                appType: false
            }
        };
    },
    computed: {
        ...mapState({
            sharedTokensLoading: (state) => state.sharedTokens.fetching
        }),
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            allTokens: 'sharedTokens/list',
            sharedTokensByAppId: 'sharedTokens/sharedTokensByAppId',
            getAppById: 'apps/appById',
            getFeatureFlag: 'subscriptions/activeSubHasFlag'
        }),
        host () {
            if (this.isAuthenticated) {
                return contentHost(this.app);
            }

            return environmentBucket(true);
        },
        disableJwtEnableButton () {
            return !this.isMobileApp && this.agentVersionLessThanRequired;
        },
        enableSignedMetadataFlag () {
            return get(this.app.applicationFlags, 'enableSignedMetadata', false);
        },
        requireSignedMetadataFlag () {
            return get(this.app.applicationFlags, 'requireSignedMetadata', false);
        },
        agentVersionLessThanRequired () {
            if (!this.isMobileApp) {
                return (
                    this.checkAgentVersion(get(this.app, 'agentPolicyProd')) ||
                    this.checkAgentVersion(get(this.app, 'agentPolicyStaging'))
                );
            }

            return true;
        },
        hasAllowSignedMetadataFlag () {
            return this.getFeatureFlag('allowSignedMetadata');
        },
        hasParentAccounts () {
            return this.getFeatureFlag('usesSubaccounts');
        }
    },
    watch: {
        allTokens () {
            this.keys = this.sharedTokensByAppId(this.app.id).map((key) => {
                key.showSecret = false;

                return key;
            });
        }
    },
    async created () {
        await this.loadAllTokens({ kind: 'jwt', reFetch: true });
    },
    methods: {
        ...mapActions({
            updateAppFlag: 'apps/updateFlag',
            loadOne: 'apps/loadOne',
            loadAllTokens: 'sharedTokens/loadAll',
            createSharedToken: 'sharedTokens/create',
            updateSharedToken: 'sharedTokens/update'
        }),
        async handleUpdateAppFlag (options) {
            const { appOptions, loading } = options;
            try {
                this.loadingSelections[loading.type] = true;
                await this.updateAppFlag(appOptions);
            } catch (err) {
                const helpArticleLink =
                    '<a href="https://adoptpartners.pendo.io/hc/en-us/articles/4409682286875">Learn more.</a>';
                const infoMessage = 'Signed metadata cannot be enabled because this application uses Pendo Adopt. ';
                if (appOptions.flag === 'enableSignedMetadata') {
                    PendoNotification({
                        type: 'error',
                        title: 'Cannot enable signed metadata',
                        dangerouslyUseHTMLString: true,
                        message: `${infoMessage}${helpArticleLink}`,
                        duration: 0
                    });
                }
            }
            this.loadingSelections[loading.type] = false;
        },
        checkAgentVersion (version) {
            if (!version) return true;

            if (version === 'stable' || version === 'beta' || version === 'latest') return false;

            return semver.satisfies(version.replace(/^=/, ''), '<2.56.0');
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .code-snippets-settings {
    margin-top: 5px;
}
</style>
