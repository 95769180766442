<template>
    <div
        class="screenshot-outer-container-vue"
        data-cy="mobile-screenshot-outline">
        <div class="screenshot-inner-container-vue">
            <pendo-loading-indicator
                v-if="!image"
                data-cy="screenshot-loading"
                class="loading-spinner"
                size="medium" />
            <img
                v-if="image"
                :src="image">
        </div>
        <div
            v-if="image"
            class="phone-home-button-vue" />
    </div>
</template>

<script>
import { PendoLoadingIndicator } from '@pendo/components';
import { http } from '@pendo/http';
import get from 'lodash/get';

export default {
    name: 'MobileScreenshot',
    components: {
        PendoLoadingIndicator
    },
    props: {
        page: {
            type: Object,
            default: () => {}
        },
        subscription: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            image: undefined
        };
    },
    watch: {
        page: {
            handler () {
                this.setImage();
            },
            immediate: true
        }
    },
    methods: {
        async setImage () {
            this.image = null;
            const response = await this.fetchScreenshotFromSelectedPage();
            if (response.data) {
                this.image = `data:image/jpeg;base64,${response.data}`;
            }
        },
        async fetchScreenshotFromSelectedPage () {
            const lastVersionIndex = this.page.mobileRules.length - 1;
            const screenshotMediaName = get(
                this.page.mobileRules[lastVersionIndex],
                'metadata.screenshotMediaName',
                null
            );
            if (!screenshotMediaName) {
                return {};
            }
            const response = await this.retrieveTempSecureMediaUrl(screenshotMediaName);

            if (!response?.data?.url) {
                return {};
            }

            return http.get(response.data.url, { withCredentials: false });
        },
        retrieveTempSecureMediaUrl (mediaName) {
            return http.get(
                `${origin}/api/s/${this.subscription.id}/media/${mediaName}?applicationId=${this.page.appId}`
            );
        }
    }
};
</script>
<style lang="scss" scoped>
.screenshot-outer-container-vue {
    border: 1px solid $gray-lighter-5;
    border-radius: 12px;
    text-align: center;
    padding: 12px;
    margin: 0 10px;
    background-color: white;

    .spinner-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    .screenshot-inner-container-vue {
        border: $gray-lighter-5 1px solid;
        text-align: center;
        align-content: center;

        img {
            z-index: 12;
            width: 100%;
        }
    }

    .phone-home-button-vue {
        width: 22px;
        height: 22px;
        border: 1px solid #dadce5;
        border-radius: 12px;
        margin: 7px auto;
    }
}
</style>
