import { $can } from '@pendo/permissions';

export const canEditNote = (appId) =>
    $can(appId)
        .entity('comment')
        .action('edit');

export const canRemoveNote = (appId) =>
    $can(appId)
        .entity('comment')
        .action('remove');

export const canCreateNote = (appId) =>
    $can(appId)
        .entity('comment')
        .action('create');
