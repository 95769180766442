<template>
    <div
        v-if="isChild"
        class="translation-input-row">
        <div class="input-bracket">
            <bracket />
            <translation-warning-icon
                v-if="warning"
                :warning="warning" />
        </div>
        <div class="translation-input">
            <slot />
        </div>
    </div>
    <div
        v-else
        class="translation-input-row">
        <translation-warning-icon
            v-if="warning"
            :warning="warning" />
        <slot />
    </div>
</template>

<script>
import TranslationWarningIcon from './TranslationWarningIcon';
import Bracket from '@/stateless-components/icons/Bracket';

export default {
    name: 'TranslationInputRow',
    components: {
        Bracket,
        TranslationWarningIcon
    },
    props: {
        isChild: {
            type: Boolean,
            default: false
        },
        warning: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">
.translation-input-row {
    display: flex;
    gap: 12px;
    align-items: flex-start;

    .input-bracket {
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        gap: 15px;
    }

    .translation-input {
        margin-top: 8px;
        flex-grow: 1;
    }
}
</style>
