import { render, staticRenderFns } from "./CustomHtmlAttributes.vue?vue&type=template&id=13ea1170&scoped=true&"
import script from "./CustomHtmlAttributes.vue?vue&type=script&lang=js&"
export * from "./CustomHtmlAttributes.vue?vue&type=script&lang=js&"
import style0 from "./CustomHtmlAttributes.vue?vue&type=style&index=0&id=13ea1170&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ea1170",
  null
  
)

export default component.exports