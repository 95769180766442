import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import store from '@/state/store';
import { setupPendoSnippetStateless } from './utils';

function setupPendoSnippet () {
    const { env, isLocal } = window.__via_info__;

    return setupPendoSnippetStateless(env, store, isLocal);
}

function identify ({ visitor, account }) {
    if (!isFunction(window.pendo.identify)) return;
    window.pendo.identify({
        visitor: {
            ...visitor
        },
        account: {
            ...account
        }
    });
}

function formatVisitorMetadata (visitor) {
    const { email, firstname, lastname, id } = visitor;

    return {
        id: email,
        name: `${firstname} ${lastname}`,
        uid: id
    };
}

function formatAccountMetadata (subscription) {
    const { name, id, partnerSubId } = subscription;

    const featureFlags = get(subscription, 'featureFlags', {});
    const applications = get(subscription, 'applications', []);

    const subscriptionFlags = generateSubscriptionFlags(featureFlags);
    const applicationCounts = generateApplicationCounts(applications);

    const account = {
        id: name,
        subscriptionFlags,
        sid: id,
        ...applicationCounts
    };

    if (partnerSubId) account.partnerSubId = partnerSubId;

    return account;
}

// named `subscriptionFlags` to be homogenous with how engage identifies sub flags
function generateSubscriptionFlags (featureFlags = {}) {
    const subscriptionFlags = Object.keys(featureFlags).reduce((acc, flagName) => {
        if (featureFlags[flagName]) {
            acc.push(flagName);
        }

        return acc;
    }, []);

    return subscriptionFlags;
}

function generateApplicationCounts (applications = []) {
    const applicationCounts = applications.reduce((acc, app) => {
        const { platform } = app;
        const platformKey = `${platform}AppCount`;

        if (!acc[platformKey]) acc[platformKey] = 0;

        acc[platformKey]++;

        return acc;
    }, {});

    return applicationCounts;
}

function pendolytics (store) {
    store.subscribe(({ type }, state) => {
        const subscription = store.getters['subscriptions/active'];
        const { user } = state.auth;

        // app isn't hydrated, bail early to avoid bad data
        if (isEmpty(subscription) || isEmpty(user)) {
            return;
        }

        const visitor = formatVisitorMetadata(user);
        const account = formatAccountMetadata(subscription);

        switch (type) {
            // any store mutations that update the user/sub can be observed here
            case 'auth/setUser':
            case 'subscriptions/setActiveById':
                // identify visitor with available metadata
                identify({
                    visitor,
                    account
                });
                break;
        }
    });
}

function refreshSegmentFlags () {
    window.pendo.loadGuides();
}

export { setupPendoSnippet, pendolytics, refreshSegmentFlags };
