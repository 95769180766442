var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(!!_vm.duplicateTags && _vm.duplicateTagsTooltip),expression:"!!duplicateTags && duplicateTagsTooltip"}],class:{
            'journey-node': true,
            'highlighted': _vm.highlight
        },style:({ borderColor: _vm.borderColor, backgroundColor: _vm.backgroundColor }),on:{"mouseover":function($event){_vm.hover = !_vm.isTerminalNode},"mouseleave":function($event){_vm.hover = false}}},[_c('div',{staticClass:"step-count"},[(_vm.hover || _vm.isNonTerminalStarredNode)?_c('div',{staticClass:"star-step"},[_c('pendo-icon',{staticClass:"star-icon",attrs:{"size":"16","fill":_vm.mustIncludeIconOptions.fill,"stroke":_vm.mustIncludeIconOptions.stroke,"type":_vm.mustIncludeIconOptions.type},on:{"click":function($event){return _vm.$emit('includeStep', _vm.nodeId, _vm.isPage ? 'pageId' : 'featureId')}}})],1):_vm._e(),(!_vm.hover && !_vm.isNonTerminalStarredNode)?_c('div',{staticClass:"step-number",style:({
                    backgroundColor: _vm.isTerminalNode ? _vm.defaultTerminalStepColor : _vm.accessibleColorMap.medium
                })},[(!_vm.isTerminalNode)?_c('div',[_vm._v(" "+_vm._s(_vm.stepCount)+" ")]):_vm._e(),(!_vm.isFinalNode && _vm.stepCount === 0)?_c('pendo-icon',{staticClass:"step-icon",attrs:{"type":"log-out","size":"16"}}):_vm._e(),(_vm.isFinalNode)?_c('pendo-icon',{staticClass:"step-icon",attrs:{"type":"log-in","size":"16"}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"page-feature-icons"},[(_vm.isPage)?_c('pendo-icon',{attrs:{"size":"14","type":"file"}}):_vm._e(),(!_vm.isPage)?_c('pendo-icon',{attrs:{"size":"14","type":"mouse-pointer"}}):_vm._e()],1),_c('div',{staticClass:"content"},[_c('p',{staticClass:"event-name"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$pendo.sanitize(_vm.nodeDisplayName))}})]),_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({ html: true, content: _vm.$pendo.sanitize(_vm.appName) }),expression:"{ html: true, content: $pendo.sanitize(appName) }"}],staticClass:"favicon"},[(_vm.favicon)?_c('img',{staticClass:"img",attrs:{"src":_vm.favicon,"alt":_vm.appName}}):_vm._e(),(!_vm.favicon && _vm.showDefaultIcon)?_c('pendo-icon',{staticClass:"icon",attrs:{"size":"16","fill":_vm.color,"stroke":_vm.color,"type":_vm.platformIcon}}):_vm._e()],1)])]),(_vm.duplicateTags)?_c('div',{class:{
            'stacked-middle': !!_vm.duplicateTags
        },style:({
            borderColor: _vm.borderColor,
            backgroundColor: _vm.backgroundColor
        })}):_vm._e(),(_vm.duplicateTags)?_c('div',{class:{
            'stacked-bottom': !!_vm.duplicateTags
        },style:({
            borderColor: _vm.borderColor,
            backgroundColor: _vm.backgroundColor
        })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }