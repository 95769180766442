<template>
    <pendo-form
        ref="form"
        :model="model">
        <launch-preview-modal-body
            class="pendo-modal__body"
            :launched="launched"
            :error="error"
            :url="model.url"
            :is-resource-center="isResourceCenter"
            :show-advanced-settings="showAdvancedSettings"
            @updateUrl="updateUrl"
            @updatePreviewOptions="updatePreviewOptions" />
        <launch-preview-modal-footer
            class="pendo-modal__footer"
            :launched="launched"
            :url="model.url"
            :snippet-installed="true"
            @close="closeModal"
            @launchPreview="validateForm" />
    </pendo-form>
</template>

<script>
import LaunchPreviewModalBody from './LaunchPreviewModalBody';
import LaunchPreviewModalFooter from './LaunchPreviewModalFooter';
import { PendoForm } from '@pendo/components';
import { launchPreview } from '@/stateless-components/utils/preview';
import get from 'lodash/get';

export default {
    name: 'LaunchPreviewModal',
    components: {
        LaunchPreviewModalBody,
        LaunchPreviewModalFooter,
        PendoForm
    },
    props: {
        launchUrl: {
            type: String,
            default: ''
        },
        guide: {
            type: Object,
            required: true
        },
        isResourceCenter: {
            type: Boolean,
            default: false
        },
        showAdvancedSettings: {
            type: Boolean,
            default: false
        },
        previewConfig: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            model: {
                url: this.launchUrl
            },
            selectedPreviewOptions: {
                screenType: 'newTab',
                width: 350,
                height: 800
            },
            launched: false,
            error: null
        };
    },
    computed: {
        appId () {
            return get(this.guide, 'appId');
        }
    },
    methods: {
        closeModal () {
            this.$emit('close');
        },
        updateUrl (url) {
            this.model.url = url;
        },
        updatePreviewOptions (event) {
            const { selectedWindowOption, height, width } = event;
            this.selectedPreviewOptions = {
                screenType: selectedWindowOption,
                height,
                width
            };
        },
        async launchPreview () {
            this.$emit('updateDesignerUrl', { url: this.model.url, appId: this.appId });

            try {
                this.error = false;
                this.launched = true;
                await launchPreview(this.model.url, this.previewConfig, this.selectedPreviewOptions);
            } catch (error) {
                this.error = true;
            }
        },
        validateForm () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.launchPreview();
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.launch-preview-modal--message {
    margin-bottom: 16px;
}
</style>
