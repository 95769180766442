<template>
    <create-password :is-white-label="isWhiteLabel">
        <template #logo>
            <slot name="logo" />
        </template>
        <template #nightLogo>
            <slot name="nightLogo" />
        </template>
    </create-password>
</template>

<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { CreatePassword } from '@pendo/login';

export default {
    name: 'ResetPassword',
    components: {
        CreatePassword
    },
    computed: {
        ...mapState({
            unauthenticatedTrainingAttributes: (state) => state.subscriptions.unauthenticatedTrainingAttributes
        }),
        isWhiteLabel () {
            return !!get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings');
        }
    }
};
</script>
