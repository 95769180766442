<template>
    <div class="view--segment-list">
        <segment-list-header />
        <segment-list-content />
    </div>
</template>

<script>
import SegmentListHeader from '@/components/segments/list/SegmentListHeader';
import SegmentListContent from '@/components/segments/list/SegmentListContent';

export default {
    name: 'GuideListView',
    components: {
        SegmentListHeader,
        SegmentListContent
    }
};
</script>
