import uniq from 'lodash/uniq';
import { getTimeSeriesByPeriod } from '@/utils/time-series';
import { parseSegmentIdForAggregation, identifiedState, PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '../utils/aggregations';
import { operators as o } from '@pendo/aggregations';
import { formatStartAndEndStepsForAgg } from './utils';

export default {
    name: 'WorkflowOverTime',
    productArea: PRODUCT_AREAS?.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS?.WORKFLOWS_JOURNEYS,
    build: ({ workflow, segmentId, dateRange, period }) => {
        if (workflow.classification === 'nonrecurring') {
            return workflowOverTimeForNotRecurringProcessAgg(workflow, segmentId, dateRange, period);
        }

        return workflowsOverTimeForRecurringProcessAgg(workflow, segmentId, dateRange, period);
    }
};

function workflowOverTimeForNotRecurringProcessAgg ({ maxDuration, workflowSteps }, segmentId, dateRange, period) {
    const aggTimeSeries = {
        period: period === 'hourly' ? 'hourRange' : 'dayRange',
        first: `date("${dateRange.value.start}")`,
        last:
            period === 'hourly'
                ? `dateAdd(date("${dateRange.value.end}"), 1, "days")`
                : `date("${dateRange.value.end}")`
    };

    const steps = formatStartAndEndStepsForAgg(workflowSteps);

    const appIds = uniq([steps.start.appId, steps.end.appId]);

    return o.pipeline(
        o.sources.singleEvents({ appId: appIds, timeSeries: aggTimeSeries }),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        o.group('visitorId', o.groupField('funnel', o.funnel([steps.start, steps.end], maxDuration, maxDuration))),
        o.unwind('funnel', { keepEmpty: true }),
        o.fork(
            o.pipeline(
                o.filter('funnel.steps == 1'),
                o.group(
                    'visitorId',
                    o.groupField('firstIncomplete', { min: `startOfPeriod("${period}", funnel.start)` })
                )
            ),
            o.pipeline(
                o.filter('funnel.steps == 2'),
                o.group('visitorId', o.groupField('firstComplete', { min: `startOfPeriod("${period}", funnel.start)` }))
            )
        ),
        o.join('visitorId'),
        o.evalExpression({
            firstIncomplete:
                'if(isNull(firstComplete), firstIncomplete, if(firstIncomplete < firstComplete, firstIncomplete))'
        }),
        o.fork(
            o.pipeline(
                o.filter('!isNull(firstIncomplete)'),
                o.group('firstIncomplete', o.groupField('incompleteCount', o.count(null))),
                o.select({
                    period: 'firstIncomplete',
                    incompleteCount: 'incompleteCount'
                })
            ),
            o.pipeline(
                o.filter('!isNull(firstComplete)'),
                o.group('firstComplete', o.groupField('completeCount', o.count(null))),
                o.select({
                    period: 'firstComplete',
                    completeCount: 'completeCount'
                })
            ),
            o.pipeline(
                o.filter('!isNull(firstIncomplete) && !isNull(firstComplete)'),
                o.group('firstComplete', o.groupField('decrement', o.count(null))),
                o.select({
                    period: 'firstComplete',
                    decrement: 'decrement'
                })
            )
        ),
        o.join('period'),
        o.select({
            period: 'period',
            incompleteCount: 'if(isNull(incompleteCount), 0, incompleteCount) - if(isNull(decrement), 0, decrement)',
            completeCount: 'if(isNull(completeCount), 0, completeCount)'
        }),
        o.sort('period')
    );
}

function workflowsOverTimeForRecurringProcessAgg ({ maxDuration, workflowSteps }, segmentId, dateRange, period) {
    const aggTimeSeries = getTimeSeriesByPeriod(period, dateRange);
    const steps = formatStartAndEndStepsForAgg(workflowSteps);
    const appIds = uniq([steps.start.appId, steps.end.appId]);

    return o.pipeline(
        o.sources.singleEvents({ appId: appIds, timeSeries: aggTimeSeries }),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        o.group('visitorId', o.groupField('funnel', o.funnel([steps.start, steps.end], maxDuration, maxDuration))),
        o.unwind('funnel'),
        o.reduce(
            o.groupField('totalVisitorsWithAttempts', o.countIf('visitorId', 'funnel.steps > 0')),
            o.groupField('totalCompleteAttempts', o.countIf(undefined, 'funnel.steps == 2')),
            o.groupField('totalIncompleteAttempts', o.countIf(undefined, 'funnel.steps == 1')),
            o.groupField('totalCompleteVisitors', o.countIf('visitorId', 'funnel.steps == 2'))
        ),
        o.evalExpression('totalIncompleteVisitors', 'totalVisitorsWithAttempts-totalCompleteVisitors')
    );
}
