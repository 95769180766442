<template>
    <div class="view--404">
        <h1>404</h1>
        <h3>The page you are looking for has been moved, deleted, or does not exist. Please try again.</h3>
        <div>
            <router-link to="/">
                <pendo-button
                    type="secondary"
                    theme="app"
                    class="btn--home">
                    Go Home
                </pendo-button>
            </router-link>
        </div>
    </div>
</template>

<script>
import { PendoButton } from '@pendo/components';

export default {
    name: 'NotFound',
    components: {
        PendoButton
    }
};
</script>

<style lang="scss">
.view--404 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;

    h1 {
        font-size: 80px;
        font-weight: 900;
        color: $pink-primary;
        margin: 0 auto;
        line-height: 1.2em;
    }

    h3 {
        margin: 0 auto 1em;
        font-size: 24px;
        line-height: 1.3em;
    }

    p {
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 1.3em;
    }

    .btn--home {
        margin-bottom: 1.1em;
    }
}

.view--404 + .powered-by {
    position: absolute;
    bottom: 31px;
    height: 30px;
    padding: 2rem 0 2rem 2rem;
}
</style>
