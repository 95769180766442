<template>
    <select-target
        class="target-selector"
        :has-multiple-guides="hasMultipleGuides"
        :eligible-subscriptions="eligibleSubscriptions"
        :active-is-digital-adoption="activeIsDigitalAdoption"
        :active-is-training-subscription="activeIsTrainingSubscription"
        :prior-selected-target="{ targetSubscription, targetApplication }"
        @targetsSelected="handleTarget" />
</template>

<script>
import SelectTarget from '@/components/guides/list/bulk-guide-copy/SelectTarget';

export default {
    name: 'SelectTargetLocation',
    components: {
        SelectTarget
    },
    props: {
        hasMultipleGuides: {
            type: Boolean,
            default: true
        },
        activeIsDigitalAdoption: {
            type: Boolean,
            default: false
        },
        activeIsTrainingSubscription: {
            type: Boolean,
            default: false
        },
        eligibleSubscriptions: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            targetSubscription: null,
            targetApplication: null
        };
    },
    methods: {
        handleTarget (payload) {
            this.targetSubscription = payload.targetSub;
            this.targetApplication = payload.targetApp;
            this.$emit('targetsSelected', payload);
        }
    }
};
</script>
