<template>
    <div>
        <div
            v-pendo-tooltip="{ arrow: true, html: true, trigger: 'manual', show: true, content: tooltipMsg }"
            class="rect-tooltip"
            :style="tooltipTargetStyle" />
        <div
            ref="chart"
            class="path-chart" />
    </div>
</template>
<script>
/* eslint-disable id-length */
import {
    updateChart,
    scaleAndAddLabelsToNodes,
    chartHeight,
    fullBlockWidth,
    transitionDuration,
    isZoomedIn,
    addPredecessorClass,
    getChartWidth,
    buildVisibleNodes,
    createPartitionLayout,
    expandChildren,
    initChart,
    recolorPathNodes,
    toggleZoomedClass,
    BrowserText
} from '@pendo/services/Paths';
import d3 from 'd3';
import { PendoTooltip } from '@pendo/components';

export default {
    name: 'PathChart',
    directives: {
        PendoTooltip
    },
    props: {
        frozenPathData: {
            required: true,
            type: Object
        },
        pathResource: {
            required: true,
            type: Object
        },
        colorBy: {
            required: true,
            type: String
        },
        labeler: {
            required: true,
            type: Object
        }
    },
    data () {
        return {
            tooltipMsg: '',
            tooltipTargetStyle: {}
        };
    },
    watch: {
        frozenPathData () {
            // we don't want Vue to make this LARGE object reactive (performance)
            // but we do need to mutate it
            this.pathData = { ...this.frozenPathData };
            this.renderChartFromPathRoot();
        },
        colorBy (colorBy, prevColorBy) {
            if (colorBy !== prevColorBy) {
                recolorPathNodes(this.labeler, this.pathData, this.colorBy);
            }
        }
    },
    mounted () {
        this.browserText = new BrowserText();
        // we don't want/need reactivity for these props (performance reasons, etc.)
        this.partition = createPartitionLayout();
        this.yScale = d3.scale.linear().range([0, chartHeight]);
        this.baseScale = chartHeight;
        this.selectedNode = undefined;
        this.pathData = { ...this.frozenPathData };

        initChart(this.$refs.chart, this.resetZoom);
        this.renderChartFromPathRoot();

        document.addEventListener('keydown', this.onEscKeyPress);
    },
    destroyed () {
        document.removeEventListener('keydown', this.onEscKeyPress);
    },
    methods: {
        renderPathWithSelectedNodeAsRoot (newSelectedNode) {
            if (this.selectedNode && this.selectedNode.key === newSelectedNode.key) {
                return;
            }

            const el = this.$refs.chart;
            const {
                pathData,
                pathResource,
                selectedNode: prevSelectedNode,
                labeler,
                yScale,
                colorBy,
                onNodeClick
            } = this;
            const chartWidth = getChartWidth(el, fullBlockWidth, isZoomedIn(newSelectedNode));
            const newScale = chartHeight / newSelectedNode.dx;

            toggleZoomedClass(el, newSelectedNode);

            expandChildren(newSelectedNode);
            const visibleNodes = buildVisibleNodes(newSelectedNode, newScale);

            // add new nodes to chart with old selection and old scale
            // so that we can animate the transition.
            // does not add text to new nodes (we do that during the transition)
            const { updatedSelection, newSelection } = updateChart({
                el,
                pathData,
                visibleNodes,
                selectedNode: prevSelectedNode || newSelectedNode,
                labeler,
                chartWidth,
                baseScale: this.baseScale,
                yScale,
                colorBy,
                onNodeClick
            });

            // rescale for the transition
            this.baseScale = newScale;
            this.yScale.domain([newSelectedNode.x, newSelectedNode.x + newSelectedNode.dx]);

            // animates the resizing/transitioning of nodes to new location,
            // removes unneeded nodes, and adds scaled labels
            scaleAndAddLabelsToNodes({
                updatedSelection,
                newSelection,
                selectedNode: newSelectedNode,
                pathData,
                pathResource,
                labeler,
                baseScale: this.baseScale,
                chartWidth,
                yScale,
                addOrRemoveTooltip: this.addOrRemoveTooltip,
                browserText: this.browserText
            });

            this.selectedNode = newSelectedNode;
        },
        onNodeClick (d) {
            if (d.trimmed) return;

            this.renderPathWithSelectedNodeAsRoot(d);
            this.selectAfterTransition(d);
        },
        renderChartFromPathRoot () {
            addPredecessorClass(this.$refs.chart, this.pathData.predecessors);
            // mutates this.pathData
            this.partition.nodes(this.pathData);
            this.renderPathWithSelectedNodeAsRoot(this.pathData);
            this.selectAfterTransition(this.pathData);
        },
        resetZoom () {
            this.onNodeClick(this.pathData);
        },
        addOrRemoveTooltip (needsTooltip, gSelection, curTitle) {
            const curRect = gSelection.select('rect');
            if (needsTooltip) {
                const msg = curTitle.map((title) => this.$sanitize(title)).join('<br />');
                curRect
                    .on('mouseenter', () => {
                        const rectStyles = getComputedStyle(curRect.node());
                        const transform = gSelection.attr('transform').replace(/(\d+(\.\d+)?)/g, '$1px');
                        this.tooltipMsg = msg;
                        this.tooltipTargetStyle = {
                            display: 'block',
                            height: rectStyles.height,
                            width: rectStyles.width,
                            transform
                        };
                    })
                    .on('mouseout', () => {
                        this.tooltipMsg = '';
                        this.tooltipTargetStyle = { display: 'none' };
                    });
            } else {
                curRect.on('mouseenter');
                curRect.on('mouseout');
            }
        },
        selectAfterTransition (node) {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.$emit('select', node);
            }, transitionDuration);
        },
        onEscKeyPress (e) {
            if (e.key === 'Escape') {
                this.resetZoom();
            }
        }
    }
};
</script>

<style lang="scss">
.rect-tooltip {
    position: absolute;
}

.path-chart {
    min-width: 1024px;
    font-size: 11px;
    position: relative;

    button {
        opacity: 0;
        position: absolute;
        top: 10px;
        transition: opacity 750ms;
    }

    rect {
        cursor: pointer;
    }

    text {
        fill: $black;
        pointer-events: none;
    }

    .hide {
        display: none !important; /* stylelint-disable-line */
    }
}

.path-chart.forward {
    button {
        right: 10px;
    }
}

.path-chart.backward {
    button {
        left: 10px;
    }
}

.path-chart.zoomed {
    button {
        opacity: 1;
    }
}
</style>
