<template>
    <pendo-card
        title="Revisions"
        width="315px"
        class="revisions-card">
        <template #body>
            <div class="revision-list-filters-section">
                <div :class="`revision-list-filters ${filtersOpen ? '' : 'hidden'}`">
                    <multiselect-filter-with-default-trigger
                        entity-name="Updated by"
                        prefix-icon="user"
                        :options="users"
                        :selected="userFilter"
                        @selection="updateUserFilter" />
                    <date-range-picker
                        :value="dateFilter"
                        :full-width="true"
                        :custom-shortcuts="dateShortcuts"
                        :first-visit="firstVisit"
                        @change="updateDateRange" />
                </div>
                <div class="revision-list-filters-controls">
                    <pendo-button
                        type="link"
                        :label="filterButtonConfig.label"
                        :suffix-icon="filterButtonConfig.icon"
                        @click="toggleShowFilters" />
                    <pendo-button
                        type="link"
                        class="clear-filters-button"
                        :label="clearFiltersLabel"
                        @click="clearFilters" />
                </div>
            </div>
            <div
                v-pendo-loading:material="loadingConfig"
                class="revision-list">
                <template v-if="!loading">
                    <pendo-empty-state
                        v-if="!groupedRevisions.length"
                        title="No revisions found"
                        description="There were no revisions found matching the current filters. Consider adjusting applied filters.">
                        <template #icon>
                            <guide-audit-revision-list-empty-state />
                        </template>
                    </pendo-empty-state>
                    <div
                        v-if="numberOfFiltersSet && groupedRevisions.length"
                        class="revision-group-filtered-results">
                        Filtered results
                    </div>
                    <div
                        v-for="group in groupedRevisions"
                        :key="group[0]"
                        class="revision-group">
                        <div class="revision-group-date">
                            {{ group[0] }}
                        </div>
                        <div
                            v-for="revision in group[1]"
                            :key="revision.lastUpdatedAt"
                            :class="`revision-entry ${isSelectedRevision(revision) ? 'revision-entry-selected' : ''}`"
                            data-cy="guide-audit-revision-entry"
                            @click="updateSelection(revision)">
                            <div class="revision-entry-top">
                                <div class="revision-entry-time">
                                    {{ revision.lastUpdatedTimeFormatted }}
                                </div>
                                <guide-status-changer
                                    :guide="revision"
                                    :disable-status-change="true"
                                    :should-show-was-scheduled="true" />
                            </div>
                            <div class="revision-entry-bottom">
                                updated by {{ revision.lastUpdatedByUser.username }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import GuideAuditRevisionListEmptyState from './GuideAuditRevisionListEmptyState';
import { PendoButton, PendoCard, PendoEmptyState, PendoLoading } from '@pendo/components';
import DateRangePicker from '@/stateless-components/common/DateRangePicker';
import GuideStatusChanger from '@/components/guides/common/GuideStatusChanger';
import moment, { DATE_FORMAT, convertToSubscriptionTimezone } from '@/utils/moment';
import {
    areRevisionsEqual,
    filterRevisionsByDate,
    filterRevisionsByUser,
    getDefaultStartDate,
    oneDay
} from './guide-audit-utils';
import {
    convertValueToDateRangeConfig,
    fixedDateRanges,
    getStartDateForCount,
    getStartDateForTimestamp
} from '@/utils/time-series';
import MultiselectFilterWithDefaultTrigger from '@/stateless-components/common/MultiselectFilterWithDefaultTrigger';
import get from 'lodash/get';

export default {
    name: 'GuideAuditRevisionList',
    components: {
        DateRangePicker,
        GuideAuditRevisionListEmptyState,
        GuideStatusChanger,
        MultiselectFilterWithDefaultTrigger,
        PendoButton,
        PendoCard,
        PendoEmptyState
    },
    directives: {
        PendoLoading
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        revisions: {
            type: Array,
            default: () => []
        },
        activeTimezone: {
            type: String,
            required: true
        },
        selectedRevision: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        currentUser: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            dateFilter: null,
            userFilter: [],
            filtersOpen: false,
            dateShortcuts: this.getDateShortcuts()
        };
    },
    computed: {
        loadingConfig () {
            return {
                loading: this.loading,
                spinnerProps: {
                    color: '#3a3c45',
                    background: '#FFFFFF',
                    size: 32
                }
            };
        },
        firstVisit () {
            const range = [1, 7, 14, 30, 90, 180];
            const diff = Date.now() - this.guide.createdAt;
            const days = diff / oneDay;
            const nextGreater = range.find((rangeItem) => {
                return rangeItem > days;
            });

            if (nextGreater) {
                return moment()
                    .subtract(nextGreater, 'days')
                    .startOf('day')
                    .valueOf();
            }

            return this.guide.createdAt;
        },
        numberOfFiltersSet () {
            const filters = [this.dateFilterSet, this.userFilterSet];

            return filters.filter((filter) => filter).length;
        },
        clearFiltersLabel () {
            return this.numberOfFiltersSet ? `Clear filters (${this.numberOfFiltersSet})` : '';
        },
        dateFilterSet () {
            return get(this.dateFilter, 'id');
        },
        filterButtonConfig () {
            if (this.filtersOpen) {
                return {
                    label: 'Hide filters',
                    icon: 'chevron-up'
                };
            }

            return {
                label: 'Show filters',
                icon: 'chevron-down'
            };
        },
        userFilterSet () {
            return !!this.userFilter.length;
        },
        filteredRevisions () {
            const revisionsFilteredByUsers = filterRevisionsByUser(this.revisions, this.userFilter);

            return filterRevisionsByDate(
                revisionsFilteredByUsers,
                this.dateFilter.value.start,
                this.dateFilter.value.end
            );
        },
        groupedRevisions () {
            return Object.entries(
                this.filteredRevisions.reduce((acc, curr) => {
                    curr.lastUpdatedTimeFormatted = convertToSubscriptionTimezone(
                        this.activeTimezone,
                        curr.lastUpdatedAt
                    ).format(DATE_FORMAT.fullTime);
                    if (acc[curr.revisionDateFormatted]) {
                        acc[curr.revisionDateFormatted].push(curr);
                    } else {
                        acc[curr.revisionDateFormatted] = [curr];
                    }

                    return acc;
                }, {})
            );
        },
        updatedByMeOption () {
            return {
                id: this.currentUser.email || this.currentUser.username,
                label: 'Updated By Me'
            };
        },
        users () {
            return [
                this.updatedByMeOption,
                ...Object.keys(
                    this.revisions.reduce((userMap, revision) => {
                        userMap[revision.lastUpdatedByUser.username] = true;

                        return userMap;
                    }, {})
                )
            ];
        }
    },
    created () {
        const start = getDefaultStartDate(this.guide);

        this.dateFilter = convertValueToDateRangeConfig(
            { value: { start, end: this.guide.lastUpdatedAt }, id: 'custom' },
            this.guide.createdAt
        );
    },
    methods: {
        isSelectedRevision (revision) {
            if (!this.selectedRevision) return false;

            return areRevisionsEqual(this.selectedRevision, revision);
        },
        toggleShowFilters () {
            this.filtersOpen = !this.filtersOpen;
        },
        updateUserFilter (users) {
            this.userFilter = users;
        },
        getDateShortcuts () {
            const last180Days = {
                id: 'last180days',
                label: 'Last 180 Days',
                count: 180,
                value: {
                    start: getStartDateForCount(180),
                    end: getStartDateForCount(1)
                }
            };

            const sinceGuideCreation = {
                id: 'sinceGuideCreation',
                label: 'Since Guide Creation',
                value: {
                    start: getStartDateForTimestamp(this.guide.createdAt),
                    end: getStartDateForCount(0)
                }
            };
            const shortcuts = fixedDateRanges();
            shortcuts.push(last180Days);
            shortcuts.push(sinceGuideCreation);

            return shortcuts;
        },
        updateDateRange (val) {
            this.dateFilter = val.dateRange;
            this.$emit('updateDateFilter', val.dateRange);
        },
        clearFilters () {
            this.dateFilter = { label: 'Select date', value: {} };
            this.userFilter = [];
            this.$emit('updateDateFilter', {
                value: { start: this.guide.createdAt, end: this.guide.lastUpdatedAt + 1 }
            });
        },
        updateSelection (revision) {
            if (this.isSelectedRevision(revision)) return;

            this.$emit('updateSelection', revision);
        }
    }
};
</script>

<style lang="scss" scoped>
.revisions-card {
    align-self: flex-start;
    top: 32px;
    position: sticky;
    overflow: hidden;

    ::v-deep .pendo-card__body {
        padding-left: 0;
        padding-right: 0;
    }
}

.revision-list-filters-section {
    border-bottom: 1px solid $gray-lighter-5;
    padding: 0 16px 16px;
}

.revision-list-filters {
    height: 110px;
    overflow: hidden;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &.hidden {
        height: 0;
    }
}

.revision-list-filters-controls {
    display: flex;
    justify-content: space-between;
}

.clear-filters-button.pendo-button {
    color: $color-gray-100;

    &:hover {
        color: $color-gray-100;
    }
}

.revision-list {
    position: relative;
    max-height: 675px;
    overflow-y: scroll;
    min-height: 348px;
    padding-top: 8px;
}

.revision-group {
    margin: 0 16px;
}

.revision-group-filtered-results {
    font-weight: 600;
    margin: 8px 16px;
}

.revision-group-date {
    font-weight: 600;
    margin: 8px 0;
}

.revision-entry {
    background-color: $gray-lighter-8;
    border-radius: 3px;
    min-height: 56px;
    padding: 8px 8px;
    margin: 4px 0;
    border: 2px solid transparent;

    &.revision-entry-selected {
        border: 2px solid $color-teal-70;
    }

    &:hover {
        cursor: pointer;
    }
}

.revision-entry-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}

.revision-entry-bottom {
    font-size: 12px;
}

.revision-entry-time {
    font-weight: 600;
}

.pendo-date-picker {
    margin: 16px 0;
}
</style>
