import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import { Themes } from '@pendo/services/BuildingBlocks';
import { _fetchThemes, _createTheme } from '@/utils/themes';

export function getInitialState () {
    return {
        map: {},
        loading: false
    };
}

export const state = getInitialState();

export const mutations = {
    setLoading (state, { loading }) {
        state.loading = loading;
    },
    setThemes (state, { themes }) {
        state.map = keyBy(themes, 'id');
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    async fetch ({ state, commit }, { noCache = false, selectedAppId } = {}) {
        if (Object.keys(state.map).length && !noCache) {
            return;
        }
        commit('setLoading', { loading: true });
        const themes = await _fetchThemes(selectedAppId);
        let defaultTheme = themes.find(getDefaultTheme);

        if (!defaultTheme) {
            defaultTheme = Themes.createTheme({
                name: 'Default',
                type: 'default',
                platform: 'web'
            });
            defaultTheme.appId = selectedAppId;
            const theme = await _createTheme(defaultTheme);
            themes.push(theme);
        }

        commit('setThemes', { themes });
        commit('setLoading', { loading: false });
    },
    async fetchAll ({ rootGetters, state, commit }, { noCache = false } = {}) {
        if (Object.keys(state.map).length && !noCache) {
            return;
        }
        commit('setLoading', { loading: true });
        const apps = rootGetters['apps/listAllForActiveSubscription'];
        const appThemeRequests = apps.map(({ id }) => {
            return _fetchThemes(id).then(async (themes) => {
                let defaultTheme = themes.find((theme) => theme.tags.toLowerCase().includes('default'));

                if (!defaultTheme) {
                    defaultTheme = Themes.createTheme({
                        name: 'Default',
                        type: 'default',
                        platform: 'web'
                    });
                    defaultTheme.appId = id;
                    const theme = await _createTheme(defaultTheme);
                    themes.push(theme);
                }

                return themes;
            });
        });
        const allThemes = await Promise.all(appThemeRequests);
        // eslint-disable-next-line prefer-spread
        commit('setThemes', { themes: [].concat.apply([], allThemes) });
        commit('setLoading', { loading: false });
    }
};

export const getters = {
    defaultTheme (state, getters) {
        return getters.list.find(getDefaultTheme);
    },
    list (state) {
        return values(state.map) || [];
    },
    getThemeByAppId: (state, getters) => (appId) => {
        const appToThemeMap = keyBy(getters.list, 'appId');
        if (!appId || !appToThemeMap[appId]) return getters.defaultTheme;

        return appToThemeMap[appId];
    },
    getThemeById: (state, getters) => (id) => {
        if (!id || !state.map[id]) return getters.defaultTheme;

        return state.map[id];
    }
};

export function getDefaultTheme (theme) {
    return theme.tags.toLowerCase().includes('default');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
