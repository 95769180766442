import { pluralize } from '@/utils/formatters';

export const SECOND_MS = 1000;

export const MINUTE_MS = SECOND_MS * 60;

export const HOUR_MS = MINUTE_MS * 60;

export const DAY_MS = HOUR_MS * 24;

export function longPrettyTime (timeInMS, { numUnits = 2, displayZeroAs = '--' } = {}) {
    if (!timeInMS) return displayZeroAs;
    if (timeInMS < 1000) return '< 1 sec';

    const days = Math.floor(timeInMS / DAY_MS);
    const daysRemainder = timeInMS % DAY_MS;
    const hours = Math.floor(daysRemainder / HOUR_MS);
    const hoursRemainder = timeInMS % HOUR_MS;
    const minutes = Math.floor(hoursRemainder / MINUTE_MS);
    const minutesRemainder = timeInMS % MINUTE_MS;
    const sec = Math.floor(minutesRemainder / SECOND_MS);

    return [
        days ? `${days} ${pluralize('days', days)}` : null,
        hours ? `${hours} ${pluralize('hours', hours)}` : null,
        minutes ? `${minutes} ${pluralize('mins', minutes)}` : null,
        sec ? `${sec} ${pluralize('secs', sec)}` : null
    ]
        .filter(Boolean)
        .slice(0, numUnits)
        .join(', ');
}
